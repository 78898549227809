// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
import {
    CardHeader,
    CardActions,
    MobileStepper,
    Button,
    CardContent,
    CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// Sub-pages
import { EULA } from './EULA';
import { VerifyEmail } from './VerifyEmail';
import { SetPassword } from './SetPassword';
import { AccountDetails } from './AccountDetails';
import { EnterEmail } from './EnterEmail';
import { Complete } from './Complete';
import { BrandedCardContainer } from '../../components/BrandedCardContainer/BrandedCardContainer';
import { useParams, useHistory } from 'react-router-dom';
import { Thunks } from '../../actions';
import { Selectors } from '../../selectors';
import { pushAppRoute } from '../../data/domain/route-manager';
import { RegistrationActions } from '../../actions/actions/registration-actions';
import { CountryList } from '../../state/registration-state';
import { CompanyDetails } from './CompanyDetails';
import { CompanyAddress } from './CompanyAddress';
import { fromBase64 } from '../../lib/encrypt-helpers';
import { INVITE_USER_TYPE_KEY } from '../../constants';
import { INVITE_USER_TYPE } from '../enum/ENUM';
import { RegisterLogin } from './RegisterLogin';
import { isAuthenticated, useAuthState } from '../../components/AuthContextProvider';
import { RegisterForgotPassword } from './RegisterForgotPassword';
import * as PXBColors from '@brightlayer-ui/colors';
import { makeStyles } from '@mui/styles';
import { AuthenticationActions } from '../../actions/actions/authentication-actions';

const headerStyles = makeStyles({
    muiCardHeader: {
        title: {
            fontSize: 20,
            fontWeight: '600',
        }
    },
});

export const RegistrationContent: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => {
    const classes = headerStyles();
    const { t, i18n } = useTranslation();

    // ToDo - To handle user invitation details for flow navigations
    // @ts-ignore
    const { inviteUserType } = useParams();
    // @ts-ignore
    const { inviteUserEmail } = useParams();
    const loginUser = useSelector(Selectors.Authentication.login);

    //ToDo - Just for testing other adopter user flow - remove after verification
    // const invitedUserType = INVITE_USER_TYPE.TYPE_OTHER_ADOPTOR_USER;

    const invitedUserType = Number(fromBase64(inviteUserType).replace(INVITE_USER_TYPE_KEY, '')); // invitedUserType => To pass user type from login screen to process self registration
    const invitedUserEmail = ('' + inviteUserEmail).replace('%40', '@');//To handle email address from url params

    const authState = useAuthState();

    const dispatch = useDispatch();
    const registration = useSelector(Selectors.Registration.registration);

    const validateEmailLoading = useSelector(Selectors.Registration.validateEmailLoading);
    const validateEmailFail = useSelector(Selectors.Registration.validateEmailFail);
    const validateEmailSuccess = useSelector(Selectors.Registration.validateEmailSuccess);
    const [errorMessage, setErrorMessage] = useState('');

    const verificationCodeLoading = useSelector(Selectors.Registration.verificationCodeLoading);
    const verificationCodeFail = useSelector(Selectors.Registration.verificationCodeFail);
    const verificationCodeSuccess = useSelector(Selectors.Registration.verificationCodeSuccess);

    const resetCode = registration.verificationCode;

    const verifyCodeLoading = useSelector(Selectors.Registration.verifyCodeLoading);
    const verifyCodeFail = useSelector(Selectors.Registration.verifyCodeFail);
    const verifyCodeSuccess = useSelector(Selectors.Registration.verifyCodeSuccess);

    const countryListLoading = useSelector(Selectors.Registration.countryListLoading);
    const countryListSuccess = useSelector(Selectors.Registration.countryListSuccess);

    const registrationLoading = useSelector(Selectors.Registration.registrationLoading);
    const registrationFail = useSelector(Selectors.Registration.registrationFail);
    const registrationSuccess = useSelector(Selectors.Registration.registrationSuccess);

    const setPasswordSelector = useSelector(Selectors.Authentication.password);

    const { code }: any = useParams();
    const history = useHistory();

    // Stepper state
    const [currentStep, setCurrentStep] = useState(0);
    const [nextEnabled, setNextEnabled] = useState(true);
    const [nextLabel, setNextLabel] = useState<string>(t('ACTIONS.NEXT'));
    const [language, setLanguage] = useState(1);
    const backLabel = t('ACTIONS.BACK');

    // We keep the state of each screen to persist between navigation
    const [eulaScrolled, setEulaScrolled] = useState(false);
    const [eulaAgreed, setEulaAgreed] = useState(false);
    const [verifyEmail, setVerifyEmail] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [countryName, setCountryName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [companyAddress, setCompanyAddress] = useState('');
    const [companyPostalCode, setCompanyPostalCode] = useState('');
    const [companyCity, setCompanyCity] = useState('');
    const [companyPhoneNumber, setCompanyPhoneNumber] = useState('');
    const [companyCountryCode, setCompanyCountryCode] = useState('');
    const [countryList, setCountryList] = useState<CountryList>([]);
    const [userType, setUserType] = useState(INVITE_USER_TYPE.TYPE_NEW_USER);//userType => To manage user Types based on self registration flow
    const [isForgotPasswordComplete, setForgotPasswordComplete] = useState(false);
    const CREATE_ACCOUNT_SCREEN = 0;

    useEffect(() => {
        if (invitedUserType !== INVITE_USER_TYPE.TYPE_NEW_USER_SELF_REGISTER) {
            setEmail(invitedUserEmail);
            setUserType(invitedUserType);
        }
        if (invitedUserType === INVITE_USER_TYPE.TYPE_NEW_USER_INCOMPLETE_REGISTER || INVITE_USER_TYPE.TYPE_NEW_OTHER_ADOPTER_USER_INCOMPLETE_REGISTER) {
            dispatch(Thunks.Registration.countryCodeRequest());
        }
    }, [inviteUserType, invitedUserEmail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!registration.success && code !== undefined && code !== '0') {
            dispatch(Thunks.Registration.validateUserRegistrationRequest(code));
        }
    }, [dispatch, code]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if ((!validateEmailLoading && validateEmailSuccess)) {
            setUserType(registration.validateEmailUserType);
            //ToDo - Just for testing other adopter user flow - remove after verification
            //setUserType(INVITE_USER_TYPE.TYPE_OTHER_ADOPTOR_USER);
        }
        handleValidateEmailResponse();
    }, [validateEmailLoading, validateEmailSuccess, validateEmailFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        handleSendVerificationCodeResponse();
        setUserType(registration.validateVerificationUserType);
    }, [verificationCodeLoading, verificationCodeSuccess, verificationCodeFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        handleSendVerifyCodeResponse();
    }, [verifyCodeLoading, verifyCodeSuccess, verifyCodeFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        handleRegistrationResponse();
    }, [registrationLoading, registrationSuccess, registrationFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!countryListLoading && countryListSuccess) {
            setCountryList(registration.countryList);
        }
    }, [countryListLoading, countryListSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (setPasswordSelector.failure) {
            if (setPasswordSelector.errorCode === '9008') {
                setErrorMessage(t('REGISTRATION.STEPS_ERROR_MESSAGE.GENERIC_ERROR_MESSAGE'));
            }
            else {
                setErrorMessage(t('REGISTRATION.STEPS_ERROR_MESSAGE.GENERIC_ERROR_MESSAGE'));
            }
        }
    }, [setPasswordSelector.requested, setPasswordSelector.failure, setPasswordSelector.errorCode]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleRegistrationResponse = (): void => {
        if (!registrationLoading && registrationFail) {
            if (registration.errorCode === '9003')
                setErrorMessage(t('REGISTRATION.STEPS_ERROR_MESSAGE.GENERIC_ERROR_MESSAGE'));
            else if (registration.errorCode === '9019')
                setErrorMessage(t('REGISTRATION.STEPS_ERROR_MESSAGE.USER_PHONE_NUMBER_INVALID'));
            else if (registration.errorCode === '9044')
                setErrorMessage(t('REGISTRATION.ERROR_MESSAGES.9044'));
            else if (registration.errorCode === '9008')
                setErrorMessage(t('REGISTRATION.STEPS_ERROR_MESSAGE.USER_INVALID_ENTRY'));
            else
                setErrorMessage(t('REGISTRATION.STEPS_ERROR_MESSAGE.GENERIC_ERROR_MESSAGE'));
        }
    };

    const unmountRegistrationResponse = (): void => {
        dispatch(RegistrationActions.registrationUnmounted());
    }

    const handleValidateEmailResponse = (): void => {
        if (!validateEmailLoading && validateEmailSuccess && registration.validateEmailUserType === INVITE_USER_TYPE.TYPE_SAME_ADOPTOR_USER) {
            setErrorMessage(t('REGISTRATION.STEPS_ERROR_MESSAGE.USER_ALREADY_EXIST_MESSAGE'));
        }
        if (!validateEmailLoading && validateEmailFail) {
            if (registration.errorCode === '3044')
                setErrorMessage(t('REGISTRATION.STEPS_ERROR_MESSAGE.USER_ALREADY_EXIST_MESSAGE'));
            else if (registration.errorCode === '9007')
                setErrorMessage(t('REGISTRATION.STEPS_ERROR_MESSAGE.USER_EMAIL_INVALID'));
            else setErrorMessage(t('REGISTRATION.STEPS_ERROR_MESSAGE.GENERIC_ERROR_MESSAGE'));
        }
        if (!validateEmailLoading && (validateEmailSuccess || validateEmailFail)) {
            dispatch(RegistrationActions.validateEmailRegistrationUnmounted());
        }
    };

    const handleSendVerificationCodeResponse = (): void => {
        if (!verificationCodeLoading && verificationCodeFail) {
            if (registration.errorCode === '3044')
                setErrorMessage(t('REGISTRATION.STEPS_ERROR_MESSAGE.USER_ALREADY_EXIST_MESSAGE'));
            else if (registration.errorCode === '9003')
                setErrorMessage(t('REGISTRATION.STEPS_ERROR_MESSAGE.GENERIC_ERROR_MESSAGE'));
            else if (registration.errorCode === '9044')
                setErrorMessage(t('REGISTRATION.ERROR_MESSAGES.9044'));
            else if (registration.errorCode === '9007')
                setErrorMessage(t('REGISTRATION.STEPS_ERROR_MESSAGE.USER_EMAIL_INVALID'));
            else if (registration.errorCode === '9008')
                setErrorMessage(t('REGISTRATION.STEPS_ERROR_MESSAGE.USER_INVALID_ENTRY'));
            else
                setErrorMessage(t('REGISTRATION.STEPS_ERROR_MESSAGE.GENERIC_ERROR_MESSAGE'));
            //ToDo Handle Error code from Cloud API
            //setErrorMessage(t('REGISTRATION.STEPS_ERROR_MESSAGE.VERIFICATION_CODE_EXPIRED_MESSAGE'));
        }
        if (!verificationCodeLoading && (verificationCodeSuccess || verificationCodeFail)) {
            dispatch(RegistrationActions.verificationCodeUnmounted());
        }
    };

    const handleSendVerifyCodeResponse = (): void => {
        if (!verifyCodeLoading && verifyCodeFail) {
            if (registration.errorCode === '3044')
                setErrorMessage(t('REGISTRATION.STEPS_ERROR_MESSAGE.USER_ALREADY_EXIST_MESSAGE'));
            else if (registration.errorCode === '9003')
                setErrorMessage(t('REGISTRATION.STEPS_ERROR_MESSAGE.GENERIC_ERROR_MESSAGE'));
            else if (registration.errorCode === '9044')
                setErrorMessage(t('REGISTRATION.ERROR_MESSAGES.9044'));
            else if (registration.errorCode === '9007')
                setErrorMessage(t('REGISTRATION.STEPS_ERROR_MESSAGE.USER_EMAIL_INVALID'));
            else if (registration.errorCode === '9008')
                setErrorMessage(t('REGISTRATION.STEPS_ERROR_MESSAGE.USER_INVALID_ENTRY'));
            else
                setErrorMessage(t('REGISTRATION.STEPS_ERROR_MESSAGE.GENERIC_ERROR_MESSAGE'));
            //ToDo Handle Error code from Cloud API
            //setErrorMessage(t('REGISTRATION.STEPS_ERROR_MESSAGE.VERIFICATION_CODE_EXPIRED_MESSAGE'));
        }
        if (userType !== INVITE_USER_TYPE.TYPE_EATON_USER && !verifyCodeLoading && (verifyCodeSuccess || verifyCodeFail)) {
            dispatch(RegistrationActions.verifyCodeUnmounted());
        }
    };

    const enterEmailScreen = {
        title: t('REGISTRATION.STEPS.CREATE_ACCOUNT'),
        label: 'enterEmailScreen',
        content: (
            <EnterEmail
                email={email}
                errorMessage={errorMessage}
                onEmailChanged={(email: string): void => setEmail(email)}
                changeNextEnabled={(enabled: boolean): void => setNextEnabled(enabled && !validateEmailLoading)}
                changeNextLabel={(label: string): void => setNextLabel(label)}
            />
        ),
        onNext: (): void => { },
    };

    const eulaScreen = {
        title: t('REGISTRATION.STEPS.LICENSE'),
        label: 'eulaScreen',
        content: (
            <EULA
                agree={eulaAgreed}
                onEulaAccept={(val: boolean): void => setEulaAgreed(val)}
                scrolled={eulaScrolled}
                onEulaScrolled={(): void => {
                    setEulaScrolled(true);
                }}
                changeNextEnabled={(enabled: boolean): void => setNextEnabled(enabled && !verificationCodeLoading)}
                changeNextLabel={(label: string): void => setNextLabel(label)}
                onLoad={(): Function => dispatch(Thunks.Registration.loadEULA(i18n.language))}
                languageSelection={(selectedLangNumber: number): void => setLanguage(selectedLangNumber)}
            />
        ),
        onNext: (): void => { },
    };
    const verifyScreen = {
        title: t('REGISTRATION.STEPS.VERIFY_EMAIL'),
        label: 'verifyScreen',
        content: (
            <VerifyEmail
                verifyEmail={verifyEmail}
                errorMessage={errorMessage}
                resendCodeClicked={(): void => {
                    setErrorMessage('');
                    dispatch(Thunks.Registration.sendVerificatinCodeUserRegistration(email));
                }}
                changeNextEnabled={(enabled: boolean): void => setNextEnabled(enabled && !verifyCodeLoading)}
                changeNextLabel={(label: string): void => setNextLabel(label)}
                onVerifyCodeChanged={(newCode: string): void => setVerifyEmail(newCode)}
            />
        ),
        onNext: (): void => { },
    };

    const passwordScreen = {
        title: t('REGISTRATION.STEPS.PASSWORD'),
        label: 'passwordScreen',
        content: (
            <SetPassword
                password={password}
                errorMessage={errorMessage}
                changePassword={(newPass: string): void => { setPassword(newPass); setErrorMessage(''); }}
                changeNextEnabled={(enabled: boolean): void => { setNextEnabled(enabled); }}
                changeNextLabel={(label: string): void => setNextLabel(label)}
            />
        ),
        onNext: (): void => undefined,
    };

    const accountDetailsScreen = {
        title: t('REGISTRATION.STEPS.ACCOUNT_DETAILS'),
        label: 'accountDetailsScreen',
        content: (
            <AccountDetails
                first={firstName}
                last={lastName}
                phone={phoneNumber}
                countryCode={countryCode}
                countryList={countryList}
                onFirstChanged={(name: string): void => setFirstName(name)}
                onLastChanged={(name: string): void => setLastName(name)}
                onCountryCodeChanged={(countryPhoneCode: string): void => setCountryCode(countryPhoneCode)}
                onPhoneChanged={(phone: string): void => setPhoneNumber(phone)}
                changeNextEnabled={(enabled: boolean): void => setNextEnabled(enabled)}
                changeNextLabel={(label: string): void => setNextLabel(label)}
            />
        ),
        onNext: (): void => { },
    };

    const loginScreen = {
        title: t('REGISTRATION.STEPS.ACCOUNT_DETAILS'),
        label: 'loginScreen',
        content: (
            <RegisterLogin
                email={email}
                changeNextEnabled={(state: boolean): void => goToNextPage()}
                singUpClick={(): void => goToFirstPage()}
            />
        ),
        onNext: (): void => { },
    };

    const forgotPasswordScreen = {
        title: t('REGISTRATION.STEPS.FORGOT_PASSWORD'),
        label: 'forgotPasswordScreen',
        content: (
            <RegisterForgotPassword
                email={email}
                changeNextEnabled={(state: boolean): void => setForgotPasswordComplete(state)} />
        ),
        onNext: (): void => { },
    };

    const companyDetailsScreen = {
        title: t('REGISTRATION.STEPS.COMPANY_DETAILS'),
        label: 'companyDetailsScreen',
        content: (
            <CompanyDetails
                companyname={companyName}
                countryname={countryName}
                phone={companyPhoneNumber}
                countryCode={companyCountryCode}
                countryList={countryList}
                onCompanyNameChanged={(companyName: string): void => setCompanyName(companyName)}
                onCountryNameChanged={(countryName: string): void => setCountryName(countryName)}
                onCountryCodeChanged={(countryCode: string): void => setCompanyCountryCode(countryCode)}
                onPhoneChanged={(companyPhone: string): void => setCompanyPhoneNumber(companyPhone)}
                changeNextEnabled={(enabled: boolean): void => setNextEnabled(enabled)}
                changeNextLabel={(label: string): void => setNextLabel(label)}
            />
        ),
        onNext: (): void => { },
    };

    const companyAddressScreen = {
        title: t('REGISTRATION.STEPS.COMPANY_ADDRESS'),
        label: 'companyAddressScreen',
        content: (
            <CompanyAddress
                companyaddress={companyAddress}
                companypostalcode={companyPostalCode}
                companycity={companyCity}
                errorMessage={errorMessage}
                onCompanyAddressChanged={(name: string): void => setCompanyAddress(name)}
                onCompanyPostalCodeChanged={(name: string): void => setCompanyPostalCode(name)}
                onCompanyCityChanged={(name: string): void => setCompanyCity(name)}
                changeNextEnabled={(enabled: boolean): void => setNextEnabled(enabled && !registrationLoading)}
                changeNextLabel={(label: string): void => setNextLabel(label)}
            />
        ),
        onNext: (): void => { },
    };

    const completeScreen = {
        title: userType === INVITE_USER_TYPE.TYPE_OTHER_ADOPTOR_USER ? t('REGISTRATION.STEPS.COMPLETE_OTHER_ADOPTER') : t('REGISTRATION.STEPS.COMPLETE'),
        label: 'completeScreen',
        content: <Complete
            email={email}
            userType={userType} />,
        onNext: (): void => {
            pushAppRoute(history, { type: 'Login' });
        },
    };

    const screens = getRegistrationScreens(invitedUserType);

    function getRegistrationScreens(invitedUserType: number) {
        switch (invitedUserType) {
            case INVITE_USER_TYPE.TYPE_NEW_USER_SELF_REGISTER:
                if (userType === INVITE_USER_TYPE.TYPE_OTHER_ADOPTOR_USER) {
                    return [
                        enterEmailScreen,
                        eulaScreen,
                        verifyScreen,
                        loginScreen,
                        forgotPasswordScreen,
                        companyDetailsScreen,
                        companyAddressScreen,
                        completeScreen,
                    ]
                }
                if (userType === INVITE_USER_TYPE.TYPE_EATON_USER) {
                    return [
                        enterEmailScreen,
                        eulaScreen,
                        verifyScreen,
                        passwordScreen,
                        accountDetailsScreen,
                        companyDetailsScreen,
                        companyAddressScreen,
                        completeScreen,
                    ]
                } else return [
                    enterEmailScreen,
                    eulaScreen,
                    verifyScreen,
                    passwordScreen,
                    accountDetailsScreen,
                    companyDetailsScreen,
                    companyAddressScreen,
                    completeScreen,
                ]
            case INVITE_USER_TYPE.TYPE_NEW_USER:
                return [
                    eulaScreen,
                    verifyScreen,
                    passwordScreen,
                    accountDetailsScreen,
                    companyDetailsScreen,
                    companyAddressScreen,
                    completeScreen,
                ]
            case INVITE_USER_TYPE.TYPE_NEW_USER_INCOMPLETE_REGISTER:
                return [
                    accountDetailsScreen,
                    companyDetailsScreen,
                    companyAddressScreen,
                    completeScreen,
                ]
            case INVITE_USER_TYPE.TYPE_NEW_OTHER_ADOPTER_USER_INCOMPLETE_REGISTER:
                return [
                    companyDetailsScreen,
                    companyAddressScreen,
                    completeScreen,
                ]

            default: //Default Self registration
                return [
                    enterEmailScreen,
                    eulaScreen,
                    verifyScreen,
                    passwordScreen,
                    accountDetailsScreen,
                    companyDetailsScreen,
                    companyAddressScreen,
                    completeScreen,
                ]
        }
    };

    const currentScreen = screens[currentStep];


    const next = (): void => {
        switch (currentScreen.label) {
            case 'enterEmailScreen':
                if (!(!validateEmailLoading && validateEmailSuccess)) {
                    setErrorMessage('');
                    dispatch(Thunks.Registration.validateEmailRegistrationRequest(email));
                }
                break;
            case 'eulaScreen':
                if (!(!verificationCodeLoading && verificationCodeSuccess)) {
                    setErrorMessage('');
                    dispatch(Thunks.Registration.sendVerificatinCodeUserRegistration(email));
                    dispatch(Thunks.Registration.countryCodeRequest());
                }
                break;

            case 'verifyScreen':
                if (!(!verifyCodeLoading && verifyCodeSuccess) && (userType === INVITE_USER_TYPE.TYPE_OTHER_ADOPTOR_USER || userType === INVITE_USER_TYPE.TYPE_EATON_USER)) {
                    setErrorMessage('');
                    const userPassword: any = userType === INVITE_USER_TYPE.TYPE_OTHER_ADOPTOR_USER ? undefined : password;
                    dispatch(Thunks.Registration.verifyCodeUserRegistration(email, verifyEmail, userPassword, userType + ''));

                    //ToDo - Just for testing other adopter user flow - remove after verification
                    //const verifyUserType: any = userType === INVITE_USER_TYPE.TYPE_OTHER_ADOPTOR_USER ? undefined : undefined;
                    //dispatch(Thunks.Registration.verifyCodeUserRegistration(email, verifyEmail, 'Smart2000', verifyUserType));
                }
                else {
                    goToNextPage();
                }
                break;

            case 'passwordScreen':
                if (!(!verifyCodeLoading && verifyCodeSuccess) && userType !== INVITE_USER_TYPE.TYPE_EATON_USER) {
                    setErrorMessage('');
                    const verifyUserType: any = userType === INVITE_USER_TYPE.TYPE_OTHER_ADOPTOR_USER ? userType + '' : undefined;
                    dispatch(Thunks.Registration.verifyCodeUserRegistration(email, verifyEmail, password, verifyUserType));

                }
                if (userType === INVITE_USER_TYPE.TYPE_EATON_USER) {
                    dispatch(Thunks.Authentication.setPassword(resetCode, password));
                }
                break;

            case 'companyAddressScreen':
                if (!(!registrationLoading && registrationSuccess)) {
                    if (companyPhoneNumber === '') setCompanyCountryCode('')
                    setErrorMessage('');
                    dispatch(
                        Thunks.Registration.completeRegistration(
                            {
                                firstName: firstName,
                                lastName: lastName,
                                phoneNumber: phoneNumber,
                                email: email,
                                password: password,
                                countryCode: countryCode,
                                company_name: companyName,
                                company_country: countryName,
                                company_countrycode: companyCountryCode,
                                company_phonenumner: companyPhoneNumber,
                                company_address: companyAddress,
                                company_postalcode: companyPostalCode,
                                company_city: companyCity,
                                language: language,
                            },
                            invitedUserType,
                            userType
                        )
                    );
                }
                break;
            default:
                goToNextPage();
        }
    };

    const goToNextPage = (): void => {
        if (currentScreen.onNext) {
            currentScreen.onNext();
        }

        if (currentStep + 1 < screens.length) {
            setCurrentStep((currentStep + 1) % screens.length);
        }
    }

    const goToNextNextPage = (): void => {
        if (currentScreen.onNext) {
            currentScreen.onNext();
        }

        if (currentStep + 2 < screens.length) {
            setCurrentStep((currentStep + 2) % screens.length);
        }
    }

    const previous = (): void => {
        setErrorMessage('');
        setCurrentStep(Math.max(0, currentStep - 1));
    };

    const goToFirstPage = (): void => {
        setCurrentStep(0);
    };

    if (validateEmailSuccess && registration.validateEmailUserType !== INVITE_USER_TYPE.TYPE_SAME_ADOPTOR_USER) {
        if (currentScreen.label === 'enterEmailScreen') {
            goToNextPage();
        }
    }

    if (verificationCodeSuccess) {
        if (currentScreen.label === 'eulaScreen') {
            setEulaAgreed(false);
            goToNextPage();
        }
    }

    if (verifyCodeSuccess) {
        if (currentScreen.label === 'passwordScreen' && userType !== INVITE_USER_TYPE.TYPE_EATON_USER) {
            setVerifyEmail('');
            setPassword('');
            goToNextPage();
        }
    }

    if (verifyCodeFail) {
        if (currentScreen.label === 'passwordScreen') {
            setVerifyEmail('');
            setPassword('');
            previous();
        }
    }
    // from verification code successfull -> navigation it to login screen from here
    if (verifyCodeSuccess && userType === INVITE_USER_TYPE.TYPE_OTHER_ADOPTOR_USER && currentScreen.label === 'verifyScreen') {
        setVerifyEmail('');
        setPassword('');
        goToNextPage();
    }
    if (verifyCodeSuccess && userType === INVITE_USER_TYPE.TYPE_EATON_USER && currentScreen.label === 'verifyScreen') {
        setVerifyEmail('');
        setPassword('');
        goToNextPage();
    }

    if (setPasswordSelector.success && userType === INVITE_USER_TYPE.TYPE_EATON_USER) {
        if (currentScreen.label === 'passwordScreen') {
            setVerifyEmail('');
            setPassword('');
            goToNextPage();
        }
    }

    if (userType === INVITE_USER_TYPE.TYPE_OTHER_ADOPTOR_USER && isAuthenticated(authState) && currentScreen.label === 'loginScreen') {
        goToNextPage();
    }

    if (userType === INVITE_USER_TYPE.TYPE_OTHER_ADOPTOR_USER && isAuthenticated(authState) && currentScreen.label === 'forgotPasswordScreen') {
        goToNextPage();
    }

    if (userType === INVITE_USER_TYPE.TYPE_OTHER_ADOPTOR_USER && isForgotPasswordComplete && currentScreen.label === 'forgotPasswordScreen') {
        previous();
        setForgotPasswordComplete(false);
    }

    if (registrationSuccess) {
        if (currentScreen.label === 'companyAddressScreen') {
            goToNextPage();
        }
    }
    const goToLogin = (): void => {
        pushAppRoute(history, { type: 'Login' });
    };

    if (!isAuthenticated(authState) && ((loginUser.errorCode === 9137) || (loginUser.errorCode === 9161))) {
        dispatch(AuthenticationActions.loginUnmount());
        if (userType !== INVITE_USER_TYPE.TYPE_OTHER_ADOPTOR_USER) {
            goToNextPage();
        } else {
            goToNextNextPage();
        }
    }

    return (registrationSuccess && !registrationLoading) ? (
        <>
            <CardHeader primarytypographyprops={{ fontSize: 20 }} className={classes.muiCardHeader} title={currentScreen.title} titleTypographyProps={{ style: { fontWeight: 600, fontSize: 20 } }} data-testid="registration-title" />
            {currentScreen.content}
            <div style={{ padding: 16 }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={(): void => {
                        unmountRegistrationResponse()
                        pushAppRoute(history, { type: 'Login' });
                    }}
                    style={{ width: '100%', textTransform: 'none' }}
                    data-testid="register-success-button"
                >
                    <>
                        {userType === INVITE_USER_TYPE.TYPE_OTHER_ADOPTOR_USER ? t('ACTIONS.GO_TO_DASHBOARD') : t('ACTIONS.NEXT')}
                    </>
                </Button>
            </div>
        </>
    ) : (validateEmailLoading || verificationCodeLoading || verifyCodeLoading || registrationLoading) ? (
        <CardContent
            sx={{
                flex: '1 1 0px',
                overflow: 'auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <CircularProgress id={'progress-spinner'} />
        </CardContent>
    ) : userType === INVITE_USER_TYPE.TYPE_OTHER_ADOPTOR_USER && (currentScreen.label === 'loginScreen' || currentScreen.label === 'forgotPasswordScreen') ?
        <>{currentScreen.content}</>
        : (!registrationSuccess && !registrationLoading) ? (
            <>
                <CardHeader className={classes.muiCardHeader} title={currentScreen.title} primarytypographyprops={{ fontSize: 20 }} titleTypographyProps={{ style: { fontSize: 20, fontWeight: 600 } }} data-testid="registration-title" />
                {currentScreen.content}
                <CardActions style={{ padding: 8 }}>
                    <MobileStepper
                        variant="dots"
                        steps={screens.length}
                        position="static"
                        activeStep={currentStep}
                        style={{ flex: '1 1 0px', background: 'transparent' }}
                        nextButton={
                            <Button
                                style={{
                                    minWidth: 120, backgroundColor: nextEnabled ? PXBColors.blue[500] : PXBColors.blue[50],
                                    color: nextEnabled ? PXBColors.white[50] : PXBColors.blue[200], textTransform: 'none'
                                }}
                                disabled={!nextEnabled}
                                variant={'contained'}
                                color={'primary'}
                                onClick={next}
                                data-testid="register-next-button"
                            >
                                {nextLabel}
                            </Button>
                        }
                        backButton={
                            <Button
                            style={
                                currentScreen.label === "accountDetailsScreen"
                                    ? { 
                                        minWidth: 120,
                                        backgroundColor:PXBColors.blue[50],
                                        color: PXBColors.blue[200],
                                        textTransform: 'none'
                                      }
                                    : { minWidth: 120,
                                        visibility: 'visible',
                                        textTransform: 'none',
                                        border: '1',
                                        borderColor: PXBColors.blue[500] }  // Default style
                                }
                                disabled ={currentScreen.label === "accountDetailsScreen" ? true : false}
                                variant="outlined"
                                color={'primary'}
                                onClick={currentStep === CREATE_ACCOUNT_SCREEN ? goToLogin : previous}
                                data-testid="register-back-button"
                            >
                                {backLabel}
                            </Button>
                        }
                    />
                </CardActions>
            </>
        ) : (
            <CardContent
                sx={{
                    flex: '1 1 0px',
                    overflow: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <CircularProgress id={'progress-spinner'} />
            </CardContent>
        );
};

export const Registration: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => (
    <BrandedCardContainer>
        <RegistrationContent />
    </BrandedCardContainer>
);
