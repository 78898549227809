// eslint-disable-next-line
import React from 'react';
import * as PXBCore from '@brightlayer-ui/react-components/core';
import * as PXBColors from '@brightlayer-ui/colors';
import { Typography, IconButton, ListItemText, Divider, useMediaQuery, useTheme } from '@mui/material';
import * as MuiIcons from '@mui/icons-material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { AlarmStatus } from '../../lib/alarm-helpers';
import { AlarmBadgeStatus } from '../AlarmBadge/AlarmBadge';
import { SystemLogData } from '../../types/system-log';
import { AcknowledgedStatusLabel } from '../AcknowledgedStatusLabel';
import { TooltipItem } from '../TooltipItem/TooltipItem';
import { DeviceState, DEVICE_STATUS, ENTITY_TYPE, getLogbookSeverityStatus, USER_ROLE_TYPE } from '../../app/enum/ENUM';
import { vw_10 } from '../../constants/dimentions';
import { getUserRoleType, useAuthState } from '../AuthContextProvider';
import { InfoListItem } from '@brightlayer-ui/react-components/core';
import { getControlledText } from '../../app/enum/GenericFormat';

type Props = {
    value: SystemLogData;
    isLogbook?: boolean;
    OnItemSelected: Function;
};


const DateTime: React.FC<React.PropsWithChildren<React.PropsWithChildren<{ dateTime: string }>>> = (props) => {
    const languageLocale = useLanguageLocale();

    return (
        <div style={{ marginRight: '10px' }}>
            <Typography variant="caption" display="block" style={{ whiteSpace: 'pre' }}>
                {languageLocale.formatTimeShort(props.dateTime)}
            </Typography>
            <Typography variant="caption" display="block">
                {languageLocale.formatDateShort(props.dateTime)}
            </Typography>
        </div>
    );
};
export const SystemLogListItem: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const logSubDevice = (props.value.logSourceDeviceType === ENTITY_TYPE.SYSTEM && props.value.subDevice !== null) ? props.value.subDevice : '';
    const logSourcename = props.value.logSourceDeviceType !== ENTITY_TYPE.SYSTEM ? props.value.logSourceName : logSubDevice;
    const foregroundColor =  props.value.elstatus === DEVICE_STATUS.STATUS_WARNING ? '#424E54' : PXBColors.white[500];
    const logRootText = props.value.logSourceDeviceType === ENTITY_TYPE.GATEWAY ? props.value.projectName + ' > ' + props.value.buildingName + ' > ' + props.value.gatewayName : props.value.projectName + ' > ' + props.value.buildingName + ' > ' + props.value.systemName + (logSourcename !== '' ? ' > ' + logSourcename : '');
    const deviceState: DeviceState = getLogbookSeverityStatus(props.value.elstatus, props.value.isactive);
    const authState = useAuthState();
    const usertype = getUserRoleType(authState) || '';
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('lg'));

    const OnLogSelected = (logId: string, logSourceId: string): void => {
        props.OnItemSelected(logId, logSourceId);
    };

    const logDate: string = ((!(props.value.logEndDate == null || props.value.logEndDate == "") && !props.value.isactive) ? props.value.logEndDate|| '' :props.value.logStartDate   || '');
    const isDisplayStartDate: boolean = logDate.includes(' ');
    const logStartDate: string[] = isDisplayStartDate ? logDate.split(' ') : [];
   
    return (
        <div data-testid="system-log-id">
            <InfoListItem
                id="system-log-list-item"
                style={{ height: 'auto', flexFlow: 'row wrap' }}
                translate="no"
                avatar={false}
                statusColor={props.value.isactive ? deviceState.statusColor : PXBColors.white[50]}
                title=""
                backgroundColor={PXBColors.white[50]}
                icon={<img alt={'Status'} src={deviceState.statusImage} style={{width: 24,height: 24}}/>}
                onClick={() => OnLogSelected(props.value.id, props.value.logSourceId)}
                leftComponent={<>
                    <div data-testid='date-time-logs'>
                        {!isDisplayStartDate && <DateTime dateTime={((!(props.value.logEndDate == null || props.value.logEndDate == "") && !props.value.isactive) ? props.value.logEndDate :props.value.datetimeutc )} />}
                        {isDisplayStartDate &&
                            <div style={{ marginRight: vw_10 }}>
                                <Typography variant="caption" data-testid="date-time-logs-time" display="block" style={{ whiteSpace: 'pre', fontWeight: 700 }}>
                                    {logStartDate[1]}
                                </Typography>
                                <Typography variant="caption" data-testid="date-time-logs-date" display="block">
                                    {logStartDate[0]}
                                </Typography>
                            </div>}
                    </div>
                    <ListItemText>
                        <Typography data-testid="alarmDescription"
                            display="inline"
                            id="system-log-list-item-name"
                            style={{ fontWeight: 600, fontSize: 16 }}
                        >
                            {getControlledText(t(deviceState.statusTitle) + ':', 50)}
                        </Typography>{' '}
                        <Typography data-testid="logs-title"
                            variant="body1"
                            display="inline"
                            id="system-log-list-item-title"
                            style={{
                                lineHeight: 1.25,
                                fontSize: 16,
                            }}
                        >
                            {props.value.title}
                        </Typography>
                        <Typography
                            data-testid="system-log-list-item-description"
                            id="system-log-list-item-description"
                            variant="body2"
                            display="block"
                            sx={{
                                lineHeight: 1.25,
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                                wordWrap: 'break-word'
                            }}
                            style={{
                                fontSize: 14,
                                fontWeight: 400
                            }}
                        >
                            {getControlledText(props.value.description, 50)}
                        </Typography>
                        <TooltipItem
                            title={logRootText}
                            isTopPlacement={false}
                            lenghtLimit={100}
                            component={<Typography
                                data-testid="path"
                                id="system-log-list-item-log-root"
                                variant="body2"
                                display="block"
                                sx={{
                                    lineHeight: 1.25,
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: 2,
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden',
                                    wordWrap: 'break-word',
                                    width: '100%',
                                    maxWidth: md ? 400 : 200,
                                }}
                                style={{
                                    fontSize: 14,
                                    fontWeight: 300,
                                    fontFamily: 'Roboto'
                                }}
                            >
                                {getControlledText(logRootText, 100)}
                            </Typography>}>
                        </TooltipItem>
                    </ListItemText>
                </>}
                rightComponent={<div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    {props.isLogbook && props.value.isAcknowledged && (
                        <AcknowledgedStatusLabel></AcknowledgedStatusLabel>
                    )}
                    {props.value.isactive && (
                        <AlarmBadgeStatus
                            alarmStatus={AlarmStatus.Open}
                            statusColor={deviceState.statusColor}
                            foregroundColor={foregroundColor} />
                    )}
                    <IconButton
                        data-testid="next-button-logs"
                    >
                        <MuiIcons.ChevronRight id="system-log-list-item-next-icon" />
                    </IconButton>
                </div>} placeholder={undefined} />
            <Divider style={{ marginLeft: 72 }} />
        </div>
    );
};
