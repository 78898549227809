
// eslint-disable-next-line
import React, { useState } from 'react';
import { useAppData } from '../../components/AppDataProvider';
import { isDataLoaded } from '../../types/data-providers';
import { SELECTED_CUSTOMER } from '../../constants';
import { SMCustomerData } from '../../types/customer-detail';

export type ContextState = {
    customerList: SMCustomerData[];
    selectedCustomer: string;
    updateSelectedCustomer: (customerId: string) => void;
    custListLoaded: boolean;
    setTabClicked: (flag: boolean) => void;
    isTabClicked: boolean;
};
const Context = React.createContext<ContextState | null>(null);

function useCustomerList(): ContextState {
    const contextState = React.useContext(Context);
    if (contextState === null) {
        throw new Error('useCustomerList must be used within a CustomerListProvider');
    }
    return contextState;
}
const CustomerListProvider: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = (props) => {
    const appData = useAppData();
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [isTabClicked, setTabClick] = useState(true);
    const updateSelectedCustomer = (customerId: string): void => {
        sessionStorage.setItem(SELECTED_CUSTOMER, customerId);
        setSelectedCustomer(customerId);
    };
    const setTabClicked = (flag: boolean): void => {
        setTabClick(flag);
    }

    let customerList;
    customerList = {
        customerList: [],
        selectedCustomer: '',
        updateSelectedCustomer: updateSelectedCustomer,
        custListLoaded: false,
        setTabClicked: setTabClicked,
        isTabClicked: isTabClicked
    };
    if (isDataLoaded(appData) && appData.value.customers.ok) {
        const emptyCustomers: SMCustomerData[] = [];
        const customers = Object.values(appData.value.customers.value).reduce((memo, cust, i) => {
            memo[i] = {
                id: Object.values(cust)[1].id,
                name: Object.values(cust)[1].name,
                address: Object.values(cust)[1].address,
                primaryEmail: Object.values(cust)[1].primaryEmail,
                primaryName: Object.values(cust)[1].primaryName,
                primaryPhone: Object.values(cust)[1].primaryPhone,
                adminCount: Object.values(cust)[1].adminCount,
                viewerCount: Object.values(cust)[1].viewerCount,
                customerNumber: Object.values(cust)[1].customerNumber,
            };
            return memo;
        }, emptyCustomers);

        if (selectedCustomer === '2' && customers.length > 0) {
            //const value = ''
            //setSelectedCustomer(value ? value : customers[0].id);
            /*** Coverity warning fix ****/

            const custId = customers[0].id;
            setSelectedCustomer(custId !== '' ? custId : '');
        }
        customerList = {
            customerList: customers,
            selectedCustomer: selectedCustomer,
            updateSelectedCustomer: updateSelectedCustomer,
            custListLoaded: true,
            setTabClicked: setTabClicked,
            isTabClicked: isTabClicked
        };
    }

    return <Context.Provider value={customerList}>{props.children}</Context.Provider>;
};

export { CustomerListProvider, useCustomerList };
