// @ts-ignore
import * as PXBlueIcons from '@brightlayer-ui/icons-mui';
import * as MuiIcons from '@mui/icons-material';

export const Icon = {
    Apps: MuiIcons.Apps,
    ArrowBack: MuiIcons.ArrowBack,
    BarChart: MuiIcons.BarChart,
    BatteryFull: MuiIcons.BatteryFull,
    Breaker: PXBlueIcons.Breaker,
    Business: MuiIcons.Business,
    Clear: MuiIcons.Clear,
    Check: MuiIcons.Check,
    Close: MuiIcons.Close,
    CloudDone: MuiIcons.CloudDone,
    CloudOff: MuiIcons.CloudOff,
    CloudQueue: MuiIcons.CloudQueue,
    CompassCalibration: MuiIcons.CompassCalibration,
    CurrentCircled: PXBlueIcons.CurrentCircled,
    Device: PXBlueIcons.Device,
    Dashboard: MuiIcons.Dashboard, 
    Users: MuiIcons.People,
    Assets: MuiIcons.Devices,
    Report: MuiIcons.Assessment,
    AccountSettings: PXBlueIcons.AccountSettings,
    HelpandFeedback:MuiIcons.HelpOutline,
    Devices: MuiIcons.Devices,
    DeviceUnknown: MuiIcons.DeviceUnknown,
    Environment: PXBlueIcons.Environment,
    ErrorOutline: MuiIcons.ErrorOutline,
    ExitToApp: MuiIcons.ExitToApp,
    ExpandMore: MuiIcons.ExpandMore,
    Flow: PXBlueIcons.Flow,
    FormatListBulleted: MuiIcons.FormatListBulleted,
    GasCylinder: PXBlueIcons.GasCylinder,
    Gavel: MuiIcons.Gavel,
    GetApp: MuiIcons.GetApp,
    Grain: MuiIcons.Grain,
    GraphicEq: MuiIcons.GraphicEq,
    Help: MuiIcons.Help,
    Info: MuiIcons.Info,
    List: MuiIcons.List,
    Logbook: MuiIcons.ListAlt,
    LocationCity: MuiIcons.LocationCity,
    LocationOn: MuiIcons.LocationOn,
    Maintenance: PXBlueIcons.Maintenance,
    Menu: MuiIcons.Menu,
    Moisture: PXBlueIcons.Moisture,
    MoreVert: MuiIcons.MoreVert,
    Notifications: MuiIcons.Notifications,
    NotificationsActive: MuiIcons.NotificationsActive,
    NotificationsOff: MuiIcons.NotificationsOff,
    NotInterested: MuiIcons.NotInterested,
    PinDrop: MuiIcons.PinDrop,
    PowerCircled: PXBlueIcons.PowerCircled,
    PowerCircledOutline: PXBlueIcons.PowerCircledOutline,
    PowerSettingsNew: MuiIcons.PowerSettingsNew,
    Person: MuiIcons.Person,
    Refresh: MuiIcons.Refresh,
    SaveAlt: MuiIcons.SaveAlt,
    ScatterPlot: MuiIcons.ScatterPlot,
    Settings: MuiIcons.Settings,
    SettingsRemote: MuiIcons.SettingsRemote,
    ShowChart: MuiIcons.ShowChart,
    SubdirectoryArrowRight: MuiIcons.SubdirectoryArrowRight,
    Temp: PXBlueIcons.Temp,
    VoltageCircled: PXBlueIcons.VoltageCircled,
    Warning: MuiIcons.Warning,
    ZoomOut: MuiIcons.ZoomOut,
    Build: MuiIcons.Build
};


// eslint-disable-next-line
export type Icon = keyof typeof Icon; 

export const getIcon = (iconName: any, defaultIconName?: any): React.ElementType => {
    if (iconName) {
        if ((PXBlueIcons as any)[iconName]) {
            return (PXBlueIcons as any)[iconName];
        }

        if ((MuiIcons as any)[iconName]) {
            return (MuiIcons as any)[iconName];
        }
    }

    if (defaultIconName) {
        if ((PXBlueIcons as any)[defaultIconName]) {
            return (PXBlueIcons as any)[defaultIconName];
        }

        if ((MuiIcons as any)[defaultIconName]) {
            return (MuiIcons as any)[defaultIconName];
        }
    }

    return MuiIcons.CheckBoxOutlineBlank;
};
