// eslint-disable-next-line
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AppRoutes } from '../../routes';
import { CreateMaintainence } from './CreateMaintainence';

export const MaintainenceManagement: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => (
    <Switch>
        <Route exact path={AppRoutes.CreateMaintainence} component={CreateMaintainence} />
    </Switch>
);
