//eslint-disable-next-line
//TO-DO export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/i;
export const EMAIL_REGEX = /^[a-zA-Z0-9.-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const RESTRICT_SPEC_REGEX = /^[^-!@#$%^&*()_{}":><+,/;=|\s]*$/;
export const RESTRICT_SPEC_WITHOUT_SPACE_REGEX = /^[^-!@#$%^&*()_{}":><+/;=|]*$/;
export const ONLY_NUMBER = /^[0-9\b]+$/;
export const DECIMAL_NUMBER = /^[-+0-9.]+$/;
export const SPECIAL_CHAR = /[!~@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g;
export const LATITUDE_REGEX = /^-?([1-8]?[0-9]\.{1}\d{6}$|90\.{1}0{6}$)/;
export const LONGITUDE_REGEX = /^-?((([1]?[0-7][0-9]|[1-9]?[0-9])\.{1}\d{6}$)|[1]?[1-8][0]\.{1}0{6}$)/;
export const IP_ADDRESS = /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/;
export const ALPHABETICAL = /^[a-zA-Z\x7f-\xff][a-zA-Z\x7f-\xff]*$/;
export const ALPHA_NUMBERIC = /^[a-zA-Z\x7f-\xff0-9]+$/;
export const REGEX_POSTALCODE = /^[a-zA-Z\x7f-\xff0-9 ]{4,14}$/;
export const PHONE_NUMBER = /^[0-9]+$/;
export const COMPANY_NAME = /^[a-zA-Z\x7f-\xff0-9._-\s]{0,50}$/;
export const NAME_VALIDATION = /^.{1,50}$/;
export const COMPANY_ADDRESS = /^.{0,250}$/;
export const COMPANY_CITY = /^[a-zA-Z\x7f-\xff][a-zA-Z\x7f-\xff ]{0,49}$/;
export const COMPANY_POSTAL_CODE = /^[a-zA-Z\x7f-\xff0-9]{0,10}$/;
export const LOCAL_EXPIRATION = 'expires_on';
export const AUTH_TOKEN = 'auth_token';
export const AUTH_TOKEN_TYPE = 'Bearer ';
export const USER_SETTINGS = 'user_settings';
export const LOCAL_USER_DATA = 'user_data';
export const REMEMBER_ME_DATA = 'remember_me_data';
export const SELECTED_ORGANIZATION = 'selected_organization';
export const SELECTED_SM_ORGANIZATION = 'selected_sm_organization';
export const ALL_ORGANIZATION_ID = '-2';
export const ALL_ORGANIZATION_API_ID = '';
export const ADD_ORGANIZATION_ID = '-0'
export const SELECTED_CUSTOMER = 'selected_customer';
export const ALL_CUSTOMER_ID = '-2';
export const ALL_CUSTOMER_API_ID = '';
export const ADD_CUSTOMER_ID = '-0';
export const DEFAULT_ALL_ID = '-2';
export const SELECTED_PROJECT = 'selected_project';
export const EULA_FILE_PATH = '../../../EULA.pdf';
export const US_CA_COUNTRY_CODE = '+1';
export const VENDOR_NAME = 'sierra';
export const EMPTY_STRING = '';
export const FX30_ETHERNET = 'fx30';
export const FX30S_SERIAL = 'fx30s';
export const SEPERATOR_COMMA = ', ';
export const SEPERATOR_SPACE = ' ';
export const SYMBOL_PLUS = '+';
export const ACTION_TYPE_NEXT = 'NEXT';
export const ACTION_TYPE_COPY = 'COPY';
export const ACTION_TYPE_NONE = 'NONE';
export const USER_THEME = 'user-theme';
export const INVITE_USER_TYPE_KEY = 'userType';
export const ALL = 'All';
export const NONE = 'None';
export const RECORD_PER_PAGE_LIMIT_CUSTOMER_ALARM = 5;
export const RECORD_PER_PAGE_LIMIT = 10;
export const DESCRIPTION_LIMIT = 250;
export const NOTES_LIMIT = 255;
export const RECORD_PER_PAGE_ASSET_SYSTEM_DETAIL_LIMIT = 5;
export const DEFAULT_PAGE_ONE = 1;
export const TAB_ORG = "7";
export const TAB_CUSTOMER = "9";
export const CURRENT_PAGE_SESSION = "currentPage";
export const BACK_STATE_SESSION = "backStateTab";
export const DEFAULT_STATE = "0";
export const SELECTED_LANGUAGE = 'selected_language';
export const TAB_BUILDING_SUMMARY = "summary";
export const TAB_BUILDING_LIST = "list";
export const SM_BUILDING_TAB = "sm-building-tab";


//User Registration
export const NAME = /^[A-Za-z\x7f-\xff._-\s]+[A-Za-z\x7f-\xff._-\s]*$/;
export const VERIFICATION_CODE = /^[0-9]{6,8}$/;
export const PASSWORD_LENGTH = /^.{8,256}$/;
export const PHONE_NUMBER_LENGTH = /^.{8,10}$/;
export const POSTAL_CODE_LENGTH = /^.{4,10}$/;
export const SLA_NUMBER = /^.{8,16}$/;
export const PHONE_MIN_LENGTH = 8;
export const PHONE_MAX_LENGTH = 10;
export const COMPANY_POSTALCODE_MIN_LENGTH = 4;
export const COMPANY_POSTALCODE_MAX_LENGTH = 10;
export const COUNTRY_CODE_MAX_LENGTH = 4;
export const FIRST_NAME_MAX_LENGTH = 50;
export const LAST_NAME_MAX_LENGTH = 50;
export const COMPANY_NAME_MAX_LENGTH = 50;
export const COMPANY_CITY_MAX_LENGTH = 50;
export const COMPANY_REGISTRATION_ADDRESS_MAX_LENGTH = 100;
export const COMPANY_ADDRESS_MAX_LENGTH = 250;
export const ORG_EMAIL_MAX_LENGTH = 75;
export const COMPANY_ADDRESS_MAX_LENGTH_USER_DETAILS = 100;
export const HISTORY_LIMIT = 2;

//Building and Org
export const ORG_CHARACTER_LIMIT = 24;
export const CUSTOMER_NUMBER_LIMIT = 16;
export const CUSTOMER_SLA_LIMIT = 12;
export const ORG_COMMENTS_LIMIT = 255;
export const ORGANIZATTION_NAME = /^[A-Za-z\x7f-\xff0-9._-\s]*$/;//[a-zA-Z\x7f-\xff
export const RESTRICT_SPEC_CHARACTER = /^[A-Za-z\x7f-\xff0-9_-\s]*$/;
export const BUILDING_CHARACTER_LIMIT = 32;
export const BUILDING_COMMENTS_LIMIT = 255;
export const BUILDING_NAME = /^[A-Za-z\x7f-\xff0-9._-\s]*$/;
export const BUILDING_ADDRESS_LIMIT = /^.{0,50}$/;

//Logbook
export const LOGBOOK_DEFAULT_SELECTION_TWELVE_MONTHS = 1;
export const LOGBOOK_ZERO_DAYS_COUNT = 0;
export const LOGBOOK_NINTY_DAYS_COUNT = 90;
export const LOGBOOK_ONE_EIGHTY_DAYS_COUNT = 180;
export const LOGBOOK_YEAR_DAYS_COUNT = 365;

//Map
export const MAP_DEFAULT_ZOOM_COUNTRY = 6;
export const MAP_DEFAULT_ZOOM_MARKER = 10;

export const enum ActionType {
    ADD = 1,
    EDIT = 2,
}

export const EATON_USER_DOMAIN = '@eaton.com';
export const BRAND_NAME = 'ELDigital';
export const CONTACT_EMAIL = 'xxxxxx@eaton.com';
export const CONTACT_PHONENO = 'XXX-XXX-XXXX';
export const IDLE_TIMER_INACTIVE = 1000 * 15 * 60;
export const IDLE_TIMER_LOGOUT = 5 * 60;
/////////////////////////////////////////////////////////////////////////////////////
// NOTE: The following Regular expressions are used for the
//       password validation logic. It is critical that they not
//       be "global" regexes  (no 'g' after the last slash). Global
//       regexes maintain state when their .test() function is called.
//       We don't want that.
export const LENGTH_REGEX = /^.{8,16}$/;
export const NUMBERS_REGEX = /[0-9]+/;
export const UPPER_CASE_REGEX = /[A-Z]+/;
export const LOWER_CASE_REGEX = /[a-z]+/;
/////////////////////////////////////////////////////////////////////////////////////
//Platform
export const LOGIN_PLATFORM = 0;
export const LOGIN_ATTEMPTS = "0";