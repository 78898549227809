import { AppThunk } from '../actions';
import * as ApiRequests from '../../api/requests';
import { UserManagementActions } from '../actions/usermanagement-actions';
import { UserInviteEntityModel } from '../../types';
import { AuthHelper } from '../../types/auth-helper-types';
import { DocumentsDetail, DocumentsDetailAdd } from '../../types/maintenance';

export const UsermanagementThunks = {
    getTechniciansList: (
        country: string,
        status: string,
        offSet?: string,
        Limit?: string,
        searchText?: string): AppThunk => async (dispatch): Promise<void> => {
            dispatch(UserManagementActions.technicianListStarted());

            const payload = await ApiRequests.requestTechniciansList(
                {
                    country: country,
                    status: status,
                    offSet: offSet,
                    Limit: Limit,
                    searchText: searchText
                },
                { authHelper: undefined }
            );

            if (payload.ok) {
                dispatch(UserManagementActions.technicianListSucceeded(payload.response.data));
                return;
            }

            dispatch(UserManagementActions.technicianListFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
        },

    getServiceManagersList: (
        country: string,
        status: string,
        offSet?: string,
        Limit?: string,
        searchText?: string): AppThunk => async (dispatch): Promise<void> => {
            dispatch(UserManagementActions.serviceManagerListStarted());

            const payload = await ApiRequests.requestServiceManagersList(
                {
                    country: country,
                    status: status,
                    offSet: offSet,
                    Limit: Limit,
                    searchText: searchText
                },
                { authHelper: undefined }
            );

            if (payload.ok) {
                dispatch(UserManagementActions.serviceManagerListSucceeded(payload.response.data));
                return;
            }

            dispatch(UserManagementActions.serviceManagerListFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
        },

    getPlatformList: (
        searchText: string,
        status: number,
        onlyStarred: boolean,
        limit?: string,
        offset?: string
    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(UserManagementActions.platformListStarted());

        const payload = await ApiRequests.requestPlatformList(
            {
                searchText: searchText,
                status: status,
                offset: offset!,
                limit: limit!,
                onlyStarred: onlyStarred
            },
            { authHelper: undefined }
        );

        if (payload.ok) {
            dispatch(UserManagementActions.platformListSucceeded(payload.response.data));
            return;
        }

        dispatch(UserManagementActions.platformListFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
    },

    getServicePartnerCustomersList: (): AppThunk => async (dispatch): Promise<void> => {
        dispatch(UserManagementActions.servicePartnerCustomersListStarted());

        const payload = await ApiRequests.requestServicePartnerCustomerListList(
            {},
            { authHelper: undefined }
        );

        if (payload.ok) {
            dispatch(UserManagementActions.servicePartnerCustomersListSucceeded(payload.response.data));
            return;
        }

        dispatch(UserManagementActions.servicePartnerCustomersListFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
    },

    addPlatform: (
        name: string,
        id: string,
        country: string,
        customerId: string,
        serviceManagerEmailId: string,
        authHelper: AuthHelper
    ): AppThunk => async (
        dispatch
    ): Promise<void> => {
            dispatch(UserManagementActions.addPlatformStarted());

            const payload = await ApiRequests.addPlatform(
                {
                    name: name,
                    id: id,
                    country: country,
                    customerId: customerId,
                    serviceManagerEmailId: serviceManagerEmailId
                },
                { authHelper: authHelper }
            );

            if (payload.ok) {
                dispatch(
                    UserManagementActions.addPlatformSucceeded(payload.response.data.id)
                );
            } else {
                dispatch(UserManagementActions.addPlatformFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
            }
        },
    addServiceManager: (
        emailId: string,
        platformId: string,
        authHelper: AuthHelper
    ): AppThunk => async (
        dispatch
    ): Promise<void> => {
            dispatch(UserManagementActions.addServiceManagerStarted());

            const payload = await ApiRequests.addServiceManager(
                {
                    emailId: emailId,
                    platformId: platformId,

                },
                { authHelper: authHelper }
            );

            if (payload.ok) {
                dispatch(
                    UserManagementActions.addServiceManagerSucceeded()
                );
            } else {
                dispatch(UserManagementActions.addServiceManagerFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
            }
        },

    addServicePartnerCustomer: (
        customerId: string,
        companyName: string,
        customerNumber: string,
        companyAddress1: string,
        companyAddress2: string,
        postalCode: string,
        city: string,
        country: string,
        companyCountryCode: string,
        companyPhone: string,
        comments: string,
        serviceManagerEmail: string,
        serviceManagerFirstName: string,
        serviceManagerLastName: string,
        serviceManagerPrimaryCountryCode: string,
        serviceManagerPrimaryPhone: string,
        ServiceManagerCountryCode2: string,
        serviceManagerPhone2: string,
        ServiceManagerComments: string,
        primaryEmail: string,
        primaryFirstName: string,
        primaryLastName: string,
        primaryCountryCode: string,
        primaryPhone: string,
        primaryCountryCode2: string,
        primaryPhone2: string,
        primaryContactType: number,
        primaryContactComments: string,
        secondaryFirstName: string,
        secondaryLastName: string,
        secondaryCountryCode: string,
        secondaryPhone: string,
        secondaryCountryCode2: string,
        secondaryPhone2: string,
        secondaryEmail: string,
        secondaryContactType: number,
        secondaryContactComments: string,
        isInternalTeam: boolean,
        imageBase64: string,
        imageType: string,
        SLANumber: string,
        expiryDate: string
    ): AppThunk => async (
        dispatch
    ): Promise<void> => {
            dispatch(UserManagementActions.addServicePartnerCustomerStarted());

            const payload = await ApiRequests.addServicePartnerCustomer(
                {
                    customerId: customerId,
                    companyName: companyName,
                    customerNumber: customerNumber,
                    companyAddress1: companyAddress1,
                    companyAddress2: companyAddress2,
                    postalCode: postalCode,
                    city: city,
                    country: country,
                    companyCountryCode: companyCountryCode,
                    companyPhone: companyPhone,
                    comments: comments,
                    serviceManagerEmail: serviceManagerEmail,
                    serviceManagerFirstName: serviceManagerFirstName,
                    serviceManagerLastName: serviceManagerLastName,
                    serviceManagerPrimaryCountryCode: serviceManagerPrimaryCountryCode,
                    serviceManagerPrimaryPhone: serviceManagerPrimaryPhone,
                    ServiceManagerCountryCode2: ServiceManagerCountryCode2,
                    serviceManagerPhone2: serviceManagerPhone2,
                    ServiceManagerComments: ServiceManagerComments,
                    primaryEmail: primaryEmail,
                    primaryFirstName: primaryFirstName,
                    primaryLastName: primaryLastName,
                    primaryCountryCode: primaryCountryCode,
                    primaryPhone: primaryPhone,
                    primaryCountryCode2: primaryCountryCode2,
                    primaryPhone2: primaryPhone2,
                    primaryContactType: primaryContactType,
                    primaryContactComments: primaryContactComments,
                    secondaryFirstName: secondaryFirstName,
                    secondaryLastName: secondaryLastName,
                    secondaryCountryCode: secondaryCountryCode,
                    secondaryPhone: secondaryPhone,
                    secondaryCountryCode2: secondaryCountryCode2,
                    secondaryPhone2: secondaryPhone2,
                    secondaryEmail: secondaryEmail,
                    secondaryContactType: secondaryContactType,
                    secondaryContactComments: secondaryContactComments,
                    isInternalTeam: isInternalTeam,
                    imageBase64: imageBase64,
                    imageType: imageType,
                    SLANumber: SLANumber,
                    expiryDate: expiryDate
                },
                { authHelper: undefined }
            );

            if (payload.ok) {
                dispatch(
                    UserManagementActions.addServicePartnerCustomerSucceeded(payload.response.data.id)
                );
            } else {
                dispatch(UserManagementActions.addServicePartnerCustomerFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
            }
        },
    getServicePartnerCustomersDetailList: (
        id: string,
        country: string,
        searchText?: string,
        offSet?: string,
        Limit?: string,
    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(UserManagementActions.servicePartnerCustomersDetailListStarted());

        const payload = await ApiRequests.requestServicePartnerCustomerDetailList(
            {
                id: id,
                country: country,
                searchText: searchText,
                offset: offSet,
                limit: Limit,

            },
            { authHelper: undefined }
        );

        if (payload.ok) {
            dispatch(UserManagementActions.servicePartnerCustomersDetailListSucceeded(payload.response.data));
            return;
        }

        dispatch(UserManagementActions.servicePartnerCustomersDetailListFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
    },

    getServiceManagerCustomersDetailList: (
        id: string,
        country: string,
        projectId?: string,
        searchText?: string,
        offSet?: string,
        Limit?: string,
        isFavorite?: boolean
    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(UserManagementActions.serviceManagerCustomersDetailListStarted());

        const payload = await ApiRequests.requestServiceManagerCustomerDetailList(
            {
                id: id,
                country: country,
                projectId: projectId,
                searchText: searchText,
                offset: offSet,
                limit: Limit,
                isFavorite: isFavorite
            },
            { authHelper: undefined }
        );

        if (payload.ok) {
            dispatch(UserManagementActions.serviceManagerCustomersDetailListSucceeded(payload.response.data));
            return;
        }

        dispatch(UserManagementActions.serviceManagerCustomersDetailListFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
    },

    getServicePartnerDetail: (id: string): AppThunk => async (
        dispatch
    ): Promise<void> => {
        dispatch(UserManagementActions.servicePartnerDetailStarted());

        const payload = await ApiRequests.getServicePartnerDetail({ id }, { authHelper: undefined });

        if (payload.ok) {
            dispatch(
                UserManagementActions.servicePartnerDetailSucceeded(payload.response.data)
            );
        } else {
            dispatch(UserManagementActions.servicePartnerDetailFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
        }
    },
    getPlatformDetail: (platformId: string): AppThunk => async (
        dispatch
    ): Promise<void> => {
        dispatch(UserManagementActions.platformDetailStarted());

        const payload = await ApiRequests.getPlatformDetail({ platformId }, { authHelper: undefined });

        if (payload.ok) {
            dispatch(
                UserManagementActions.platformDetailSucceeded(payload.response.data)
            );
        } else {
            dispatch(UserManagementActions.platformDetailFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
        }
    },
    getPlatformSystemInfo: (platformId: string): AppThunk => async (
        dispatch
    ): Promise<void> => {
        dispatch(UserManagementActions.platformSystemInfoStarted());

        const payload = await ApiRequests.getPlatformSystemInfo({ platformId }, { authHelper: undefined });

        if (payload.ok) {
            dispatch(
                UserManagementActions.platformSystemInfoSucceeded(payload.response.data)
            );
        } else {
            dispatch(UserManagementActions.platformSystemInfoFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
        }
    },
    removeServiceUser: (userId: string, userType: number): AppThunk => async (dispatch): Promise<void> => {
        dispatch(UserManagementActions.removeServiceUserStarted());

        const payload = await ApiRequests.removeServiceUser({ userId, userType }, { authHelper: undefined });

        if (payload.ok) {
            dispatch(UserManagementActions.removeServiceUserSucceeded());

        }
        else {

            dispatch(UserManagementActions.removeServiceUserFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
        }
    },
    getMaintenanceDetail: (
        organizationId?: string,
        maintenanceId?: string,
        technicianId?: string,
        searchText?: string,
        customerId?: string,
        sLANumber?: string,
        systemId?: string,
        buildingId?: string,
        offset?: string,
        limit?: string,
        fromDate?: string,
        toDate?: string,
        ticketStatus?: number,
        serviceType?: number,
    ): AppThunk => async (
        dispatch
    ): Promise<void> => {
            dispatch(UserManagementActions.maintenanceDetailStarted());

            const payload = await ApiRequests.getMaintenanceDetail({
                organizationId: organizationId,
                maintenanceId: maintenanceId,
                technicianId: technicianId,
                searchText: searchText,
                ticketStatus: ticketStatus,
                serviceType: serviceType,
                customerId: customerId,
                sLANumber: sLANumber,
                systemId: systemId,
                buildingId: buildingId,
                offset: offset,
                limit: limit,
                fromDate: fromDate,
                toDate: toDate
            }, { authHelper: undefined });

            if (payload.ok) {
                dispatch(
                    UserManagementActions.maintenanceDetailSucceeded(payload.response.data)
                );
            } else {
                dispatch(UserManagementActions.maintenanceDetailFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
            }
        },

    createMaintainance: (
        maintenanceId: string,
        systemId: string,
        customerId: string,
        SLANumber: string,
        requestPriority: number,
        serviceType: number,
        assignedTo: string,
        serviceDate: string,
        description?: string,
        documents?: DocumentsDetailAdd,
        alarmLink?: string

    ): AppThunk => async (
        dispatch
    ): Promise<void> => {
            dispatch(UserManagementActions.createMaintainanceStarted());

            const payload = await ApiRequests.createMaintainanceRequest(
                {
                    maintenanceId: maintenanceId,
                    systemId: systemId,
                    customerId: customerId,
                    slaNumber: SLANumber,
                    requestPriority: requestPriority,
                    serviceType: serviceType,
                    assignedTo: assignedTo,
                    serviceDate: serviceDate,
                    description: description,
                    documents: documents ? documents : [],
                    alarmLink: ''
                },
                { authHelper: undefined }
            );

            if (payload.ok) {
                dispatch(
                    UserManagementActions.createMaintainanceSucceeded()
                );
            } else {
                dispatch(UserManagementActions.createMaintainanceFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
            }
        },

    getMaintenanceLogs: (
        systemId: string,
    ): AppThunk => async (
        dispatch
    ): Promise<void> => {
            dispatch(UserManagementActions.maintenanceLogsStarted());

            const payload = await ApiRequests.getMaintenanceLogs(
                {
                    systemId: systemId,
                },
                { authHelper: undefined }
            );

            if (payload.ok) {
                dispatch(UserManagementActions.maintenanceLogsSucceeded(payload.response.data));
                return;
            }

            dispatch(UserManagementActions.maintenanceLogsFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
        },

    updateMaintainance: (
        maintenanceId: string,
        description: string,
        ticketStatus: number

    ): AppThunk => async (
        dispatch
    ): Promise<void> => {
            dispatch(UserManagementActions.updateMaintenanceStarted());

            const payload = await ApiRequests.updateMaintenanceRequest(
                {
                    maintenanceId: maintenanceId,
                    description: description,
                    ticketStatus: ticketStatus
                },
                { authHelper: undefined }
            );

            if (payload.ok) {
                dispatch(
                    UserManagementActions.updateMaintenanceSucceeded()
                );
            } else {
                dispatch(UserManagementActions.updateMaintenanceFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
            }
        },

    addNotes: (
        maintenanceId: string,
        note: string
    ): AppThunk => async (
        dispatch
    ): Promise<void> => {
            dispatch(UserManagementActions.addNotesStarted());

            const payload = await ApiRequests.addNotesRequest(
                {
                    maintenanceId: maintenanceId,
                    note: note
                },
                { authHelper: undefined }
            );

            if (payload.ok) {
                dispatch(
                    UserManagementActions.addNotesSucceeded()
                );
            } else {
                dispatch(UserManagementActions.addNotesFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
            }
        }
};
