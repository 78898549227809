// AUTOGENERATED - DO NOT EDIT
/* eslint-disable */

export const Schema = {

    SignalrConnectionOkResponse: require('./alarm/signalr-connection/signalr-connection-ok-response-schema.json'),
    SignalrConnectionRequestBody: require('./alarm/signalr-connection/signalr-connection-request-body-schema.json'),
    SignalrConnectionRequestParams: require('./alarm/signalr-connection/signalr-connection-request-params-schema.json'),
    DeviceMasterDataOkResponse: require('./device/device-master-data/device-master-data-ok-response-schema.json'),
    DeviceMasterDataRequestBody: require('./device/device-master-data/device-master-data-request-body-schema.json'),
    DeviceMasterDataRequestParams: require('./device/device-master-data/device-master-data-request-params-schema.json'),
    GenericNotOkResponse: require('./generic-not-ok-response-schema.json'),
    FeatureFlagOkResponse: require('./security/feature-flag/feature-flag-ok-response-schema.json'),
    FeatureFlagRequestBody: require('./security/feature-flag/feature-flag-request-body-schema.json'),
    FeatureFlagRequestParams: require('./security/feature-flag/feature-flag-request-params-schema.json'),
    SecurityChangePasswordOkResponse: require('./security/security-change-password/security-change-password-ok-response-schema.json'),
    SecurityChangePasswordRequestBody: require('./security/security-change-password/security-change-password-request-body-schema.json'),
    SecurityLoginOkResponse: require('./security/security-login/security-login-ok-response-schema.json'),
    SecurityLoginRequestBody: require('./security/security-login/security-login-request-body-schema.json'),
    SecurityLogoffOkResponse: require('./security/security-logoff/security-logoff-ok-response-schema.json'),
    SecurityLogoffRequestBody: require('./security/security-logoff/security-logoff-request-body-schema.json'),
    SecurityLogoffRequestParams: require('./security/security-logoff/security-logoff-request-params-schema.json'),
    SecurityRefreshOkResponse: require('./security/security-refresh/security-refresh-ok-response-schema.json'),
    SecurityRefreshRequestBody: require('./security/security-refresh/security-refresh-request-body-schema.json'),
    SecurityResetPasswordRequestValidationOkResponse: require('./security/security-reset-password-request-validation/security-reset-password-request-validation-ok-response-schema.json'),
    SecurityResetPasswordRequestValidationRequestBody: require('./security/security-reset-password-request-validation/security-reset-password-request-validation-request-body-schema.json'),
    SecurityResetPasswordRequestValidationRequestParams: require('./security/security-reset-password-request-validation/security-reset-password-request-validation-request-params-schema.json'),
    SecurityResetPasswordRequestOkResponse: require('./security/security-reset-password-request/security-reset-password-request-ok-response-schema.json'),
    SecurityResetPasswordRequestRequestBody: require('./security/security-reset-password-request/security-reset-password-request-request-body-schema.json'),
    SecurityResetPasswordRequestRequestParams: require('./security/security-reset-password-request/security-reset-password-request-request-params-schema.json'),
    SecurityResetPasswordOkResponse: require('./security/security-reset-password/security-reset-password-ok-response-schema.json'),
    SecurityResetPasswordRequestBody: require('./security/security-reset-password/security-reset-password-request-body-schema.json'),
    OrganizationListOkResponse: require('./site/organization-list/organization-list-ok-response-schema.json'),
    OrganizationListRequestBody: require('./site/organization-list/organization-list-request-body-schema.json'),
    OrganizationListRequestParams: require('./site/organization-list/organization-list-request-params-schema.json'),
    UpdateUserRoleOkResponse: require('./user/update-role/update-user-role-ok-response-schema.json'),
    UpdateUserRoleRequestBody: require('./user/update-role/update-user-role-request-body-schema.json'),
    UpdateUserRoleRequestParams: require('./user/update-role/update-user-role-request-params-schema.json'),
    UserDeleteOkResponse: require('./user/user-delete/user-delete-ok-response-schema.json'),
    UserDeleteRequestBody: require('./user/user-delete/user-delete-request-body-schema.json'),
    UserDeleteRequestParams: require('./user/user-delete/user-delete-request-params-schema.json'),
    UserListOkResponse: require('./user/user-list/user-list-ok-response-schema.json'),
    UserListRequestBody: require('./user/user-list/user-list-request-body-schema.json'),
    UserListRequestParams: require('./user/user-list/user-list-request-params-schema.json'),
    UserRegistrationRequestValidationOkResponse: require('./user/user-registration-request-validation/user-registration-request-validation-ok-response-schema.json'),
    UserRegistrationRequestValidationRequestBody: require('./user/user-registration-request-validation/user-registration-request-validation-request-body-schema.json'),
    UserRegistrationRequestValidationRequestParams: require('./user/user-registration-request-validation/user-registration-request-validation-request-params-schema.json'),
    UserRegistrationRequestEmailValidationOkResponse: require('./user/user-registration-request-email-validation/user-registration-request-email-validation-ok-response-schema.json'),
    UserRegistrationRequestEmailValidationRequestBody: require('./user/user-registration-request-email-validation/user-registration-request-email-validation-request-body-schema.json'),
    UserRegistrationRequestEmailValidationRequestParams: require('./user/user-registration-request-email-validation/user-registration-request-email-validation-request-params-schema.json'),
    UserRegistrationCountryListOkResponse: require('./user/user-registration-request-country-list/user-registration-request-country-list-ok-response-schema.json'),
    UserRegistrationCountryListRequestBody: require('./user/user-registration-request-country-list/user-registration-request-country-list-request-body-schema.json'),
    UserRegistrationCountryListRequestParams: require('./user/user-registration-request-country-list/user-registration-request-country-list-request-params-schema.json'),
    UserRegistrationRequestOkResponse: require('./user/user-registration-request/user-registration-request-ok-response-schema.json'),
    UserRegistrationRequestRequestBody: require('./user/user-registration-request/user-registration-request-request-body-schema.json'),
    UserRegistrationRequestRequestParams: require('./user/user-registration-request/user-registration-request-request-params-schema.json'),
    UserRegistrationOkResponse: require('./user/user-registration/user-registration-ok-response-schema.json'),
    UserRegistrationRequestBody: require('./user/user-registration/user-registration-request-body-schema.json'),
    UserRegistrationRequestParams: require('./user/user-registration/user-registration-request-params-schema.json'),
    UserInvitationOkResponse: require('./user/user-invitation/user-invitation-ok-response-schema.json'),
    UserInvitationRequestBody: require('./user/user-invitation/user-invitation-request-body-schema.json'),
    UserInvitationRequestParams: require('./user/user-invitation/user-invitation-request-params-schema.json'),
    UserInvitationHistoryOkResponse: require('./user/user-invitation-history/user-invitation-history-ok-response-schema.json'),
    UserInvitationHistoryRequestBody: require('./user/user-invitation-history/user-invitation-history-request-body-schema.json'),
    UserInvitationHistoryRequestParams: require('./user/user-invitation-history/user-invitation-history-request-params-schema.json'),
    AdminApprovalHistoryOkResponse: require('./user/admin-approval-history/admin-approval-history-ok-response-schema.json'),
    AdminApprovalHistoryRequestBody: require('./user/admin-approval-history/admin-approval-history-request-body-schema.json'),
    AdminApprovalHistoryRequestParams: require('./user/admin-approval-history/admin-approval-history-request-params-schema.json'),
    UserInvitationAccessRequestOkResponse: require('./user/user-invitation-access-request/user-invitation-access-request-ok-response-schema.json'),
    UserInvitationAccessRequestBody: require('./user/user-invitation-access-request/user-invitation-access-request-body-schema.json'),
    UserInvitationAccessRequestParams: require('./user/user-invitation-access-request/user-invitation-access-request-params-schema.json'),
    UserAccessProfileOkResponse: require('./user/upsert-user-access-profile/upsert-user-access-profile-ok-response-schema.json'),
    UserAccessProfileRequestBody: require('./user/upsert-user-access-profile/upsert-user-access-profile-request-body-schema.json'),
    UserAccessProfileRequestParams: require('./user/upsert-user-access-profile/upsert-user-access-profile-request-params-schema.json'),
    UserSelfRegistrationOkResponse: require('./user/user-self-registration/user-self-registration-ok-response-schema.json'),
    UserSelfRegistrationRequestBody: require('./user/user-self-registration/user-self-registration-request-body-schema.json'),
    UserSelfRegistrationRequestParams: require('./user/user-self-registration/user-self-registration-request-params-schema.json'),
    UserRegistrationVerificationCodeRequestOkResponse: require('./user/user-registration-verification-code-request/user-registration-verification-code-request-request-ok-response-schema.json'),
    UserRegistrationVerificationCodeRequestRequestBody: require('./user/user-registration-verification-code-request/user-registration-verification-code-request-request-body-schema.json'),
    UserRegistrationVerificationCodeRequestRequestParams: require('./user/user-registration-verification-code-request/user-registration-verification-code-request-request-params-schema.json'),

    UserRegistrationVerifyCodeRequestOkResponse: require('./user/user-registration-verify-code-request/user-registration-verify-code-request-request-ok-response-schema.json'),
    UserRegistrationVerifyCodeRequestRequestBody: require('./user/user-registration-verify-code-request/user-registration-verify-code-request-request-body-schema.json'),
    UserRegistrationVerifyCodeRequestRequestParams: require('./user/user-registration-verify-code-request/user-registration-verify-code-request-request-params-schema.json'),
    UserRoleOperationOkResponse: require('./user/user-role-operation/user-role-operation-ok-response-schema.json'),
    UserRoleOperationRequestBody: require('./user/user-role-operation/user-role-operation-request-body-schema.json'),
    UserRoleOperationRequestParams: require('./user/user-role-operation/user-role-operation-request-params-schema.json'),
    UserRegistrationRoleOkResponse: require('./user/user-role-registration/user-registration-role-ok-response-schema.json'),
    UserRegistrationRoleRequestBody: require('./user/user-role-registration/user-registration-role-request-body-schema.json'),
    UserRegistrationRoleRequestParams: require('./user/user-role-registration/user-registration-role-request-params-schema.json'),
    UserRoleOkResponse: require('./user/user-role/user-role-ok-response-schema.json'),
    UserRoleRequestBody: require('./user/user-role/user-role-request-body-schema.json'),
    UserRoleRequestParams: require('./user/user-role/user-role-request-params-schema.json'),
    UpdateUserOkResponse: require('./user/update-user/update-user-ok-response-schema.json'),
    UpdateUserRequestBody: require('./user/update-user/update-user-request-body-schema.json'),
    UpdateUserRequestParams: require('./user/update-user/update-user-request-params-schema.json'),
    UpdateUserInvitationOkResponse: require('./user/update-user-invitation/update-user-invitation-ok-response-schema.json'),
    UpdateUserInvitationRequestBody: require('./user/update-user-invitation/update-user-invitation-request-body-schema.json'),
    UpdateUserInvitationRequestParams: require('./user/update-user-invitation/update-user-invitation-request-params-schema.json'),
    UpdateInvitationRequestOkResponse: require('./user/update-invitation-request/update-invitation-request-ok-response-schema.json'),
    UpdateInvitationRequestRequestBody: require('./user/update-invitation-request/update-invitation-request-request-body-schema.json'),
    UpdateInvitationRequestRequestParams: require('./user/update-invitation-request/update-invitation-request-request-params-schema.json'),
    UserSetSettingsOkResponse: require('./user/user-set-settings/user-set-settings-ok-response-schema.json'),
    UserSetSettingsRequestBody: require('./user/user-set-settings/user-set-settings-request-body-schema.json'),
    UserSetSettingsRequestParams: require('./user/user-set-settings/user-set-settings-request-params-schema.json'),
    UserSettingsOkResponse: require('./user/user-settings/user-settings-ok-response-schema.json'),
    UserSettingsRequestBody: require('./user/user-settings/user-settings-request-body-schema.json'),
    UserSettingsRequestParams: require('./user/user-settings/user-settings-request-params-schema.json'),
    CustomerDetailOkResponse: require('./customer/customer-detail-request/customer-detail-ok-response-schema.json'),
    CustomerDetailRequestBody: require('./customer/customer-detail-request/customer-detail-request-body-schema.json'),
    CustomerDetailRequestParams: require('./customer/customer-detail-request/customer-detail-request-params-schema.json'),
    CustomerUpdateRequestBody: require('./customer/customer-update-request/customer-update-request-body-schema.json'),
    CustomerUpdateRequestOkResponse: require('./customer/customer-update-request/customer-update-request-ok-response-schema.json'),
    CustomerUpdateRequestParams: require('./customer/customer-update-request/customer-update-request-params-schema.json'),
    ProjectBuildingListRequestOkResponse: require('./project/project-buildinglist-request/project-buildinglist-request-ok-response-schema.json'),
    ProjectBuildingListRequestBody: require('./project/project-buildinglist-request/project-buildinglist-request-body-schema.json'),
    ProjectBuildingListRequestParams: require('./project/project-buildinglist-request/project-buildinglist-request-params-schema.json'),
    OrgUpdateRequestOkResponse: require('./project/org-update-request/org-update-request-ok-response-schema.json'),
    OrgUpdateRequestBody: require('./project/org-update-request/org-update-request-body-schema.json'),
    OrgUpdateRequestParams: require('./project/org-update-request/org-update-request-params-schema.json'),
    OrgDetailRequestOkResponse: require('./project/org-detail-request/org-detail-request-ok-response-schema.json'),
    OrgDetailRequestBody: require('./project/org-detail-request/org-detail-request-body-schema.json'),
    OrgDetailRequestParams: require('./project/org-detail-request/org-detail-request-params-schema.json'),
    BuildingDetailRequestOkResponse: require('./project/building-detail-request/building-detail-request-ok-response-schema.json'),
    BuildingDetailRequestBody: require('./project/building-detail-request/building-detail-request-body-schema.json'),
    BuildingDetailRequestParams: require('./project/building-detail-request/building-detail-request-params-schema.json'),

    DeviceListRequestOkResponse: require('./device/devicelist-request/devicelist-request-ok-response-schema.json'),
    DeviceListRequestBody: require('./device/devicelist-request/devicelist-request-body-schema.json'),
    DeviceListRequestParams: require('./device/devicelist-request/devicelist-request-params-schema.json'),

    TechniciansListRequestOkResponse: require('./usermanagement/technicianslist-request/technicians-list-request-ok-response-schema.json'),
    TechniciansListRequestBody: require('./usermanagement/technicianslist-request/technicians-list-request-body-schema.json'),
    TechniciansListRequestParams: require('./usermanagement/technicianslist-request/technicians-list-request-params-schema.json'),

    ServiceManagersListRequestOkResponse: require('./usermanagement/serviceManagerslist-request/serviceManagers-list-request-ok-response-schema.json'),
    ServiceManagersListRequestBody: require('./usermanagement/serviceManagerslist-request/serviceManagers-list-request-body-schema.json'),
    ServiceManagersListRequestParams: require('./usermanagement/serviceManagerslist-request/serviceManagers-list-request-params-schema.json'),

    SLAListRequestOkResponse: require('./device/slalist-request/slalist-request-ok-response-schema.json'),
    SLAListRequestBody: require('./device/slalist-request/slalist-request-body-schema.json'),
    SLAListRequestParams: require('./device/slalist-request/slalist-request-params-schema.json'),
    SLACustomerListRequestOkResponse: require('./device/slaCustomerlist-request/sla-customer-list-request-ok-response-schema.json'),
    SLACustomerListRequestBody: require('./device/slaCustomerlist-request/sla-customer-list-request-body-schema.json'),
    SLACustomerListRequestParams: require('./device/slaCustomerlist-request/sla-customer-list-request-params-schema.json'),

    LuminaryListRequestOkResponse: require('./device/luminarylist-request/luminarylist-request-ok-response-schema.json'),
    LuminaryListRequestBody: require('./device/luminarylist-request/luminarylist-request-body-schema.json'),
    LuminaryListRequestParams: require('./device/luminarylist-request/luminarylist-request-params-schema.json'),
    GatewayDetailsListRequestOkResponse: require('./device/gatewaydetails-request/gatewaydetails-request-ok-response-schema.json'),
    GatewayDetailsListRequestBody: require('./device/gatewaydetails-request/gatewaydetails-request-body-schema.json'),
    GatewayDetailsListRequestParams: require('./device/gatewaydetails-request/gatewaydetails-request-params-schema.json'),
    ProjectListOkResponse: require('./site/project-list/project-list-ok-response-schema.json'),
    ProjectListRequestBody: require('./site/project-list/project-list-request-body-schema.json'),
    ProjectListRequestParams: require('./site/project-list/project-list-request-params-schema.json'),
    SystemLogOkResponse: require('./log/system-log/system-log-ok-response-schema.json'),
    SystemLogRequestBody: require('./log/system-log/system-log-request-body-schema.json'),
    SystemLogRequestParams: require('./log/system-log/system-log-request-params-schema.json'),
    PreviousLogOkResponse: require('./log/previous-log/previous-log-ok-response-schema.json'),
    PreviousLogRequestBody: require('./log/previous-log/previous-log-request-body-schema.json'),
    PreviousLogRequestParams: require('./log/previous-log/previous-log-request-params-schema.json'),
    StatisticSummaryRequestOkResponse: require('./project/statistic-summary-request/statistic-summary-request-ok-response-schema.json'),
    StatisticSummaryRequestBody: require('./project/statistic-summary-request/statistic-summary-request-body-schema.json'),
    StatisticSummaryRequestParams: require('./project/statistic-summary-request/statistic-summary-request-params-schema.json'),
    ProjectUserListRequestOkResponse: require('./project/project-userlist-request/project-userlist-request-ok-response-schema.json'),
    ProjectUserListRequestBody: require('./project/project-userlist-request/project-userlist-request-body-schema.json'),
    ProjectUserListRequestParams: require('./project/project-userlist-request/project-userlist-request-params-schema.json'),
    InviteUserRequestOkResponse: require('./project/invite-user-request/invite-user-request-ok-response-schema.json'),
    InviteUserRequestBody: require('./project/invite-user-request/invite-user-request-body-schema.json'),
    InviteUserRequestParams: require('./project/invite-user-request/invite-user-request-params-schema.json'),
    BuildingTransferRequestBody: require('./project/building-transfer-request/building-transfer-request-body-schema.json'),
    BuildingTransferRequestOkResponse: require('./project/building-transfer-request/building-transfer-request-ok-response-schema.json'),
    BuildingTransferRequestParams: require('./project/building-transfer-request/building-transfer-request-params-schema.json'),
    UpsertLogCommentsRequestBody: require('./log/log-comments/upsert-log-comments-request-body-schema.json'),
    UpsertLogCommentsRequestOkResponse: require('./log/log-comments/upsert-log-comments-request-ok-response-schema.json'),
    UpsertLogCommentsRequestParams: require('./log/log-comments/upsert-log-comments-request-params-schema.json'),
    LogbookDownloadRequestBody: require('./log/logbook-download-report/logbook-download-request-body-schema.json'),
    LogbookDownloadRequestOkResponse: require('./log/logbook-download-report/logbook-download-request-ok-response-schema.json'),
    LogbookDownloadRequestParams: require('./log/logbook-download-report/logbook-download-request-params-schema.json'),
    LogAcknowledgeRequestBody: require('./log/log-acknowledge/log-acknowledge-request-body-schema.json'),
    LogAcknowledgeRequestOkResponse: require('./log/log-acknowledge/log-acknowledge-request-ok-response-schema.json'),
    LogAcknowledgeRequestParams: require('./log/log-acknowledge/log-acknowledge-request-params-schema.json'),
    BuildingUpsertRequestBody: require('./project/building-upsert-request/building-upsert-request-body-schema.json'),
    BuildingUpsertRequestOkResponse: require('./project/building-upsert-request/building-upsert-request-ok-response-schema.json'),
    BuildingUpsertRequestParams: require('./project/building-upsert-request/building-upsert-request-params-schema.json'),
    MarkAsFavRequestBody: require('./project/markAs-fav-request/markAs-fav-request-body-schema.json'),
    MarkAsFavRequestOkResponse: require('./project/markAs-fav-request/markAs-fav-request-ok-response-schema.json'),
    MarkAsFavRequestParams: require('./project/markAs-fav-request/markAs-fav-request-params-schema.json'),
    HealthReportRequestBody: require('./report/health-report-request/health-report-request-body-schema.json'),
    HealthReportRequestOkResponse: require('./report/health-report-request/health-report-request-ok-response-schema.json'),
    HealthReportParams: require('./report/health-report-request/health-report-request-params-schema.json'),
    DownloadHealthReportRequestBody: require('./report/download-health-report-request/download-health-report-request-body-schema.json'),
    DownloadHealthReportRequestOkResponse: require('./report/download-health-report-request/download-health-report-request-ok-response-schema.json'),
    DownloadHealthReportRequestParams: require('./report/download-health-report-request/download-health-report-request-params-schema.json'),
    UserAdvanceAccessRequestBody: require('./user/user-advance-access-detail/user-advance-access-detail-body-schema.json'),
    UserAdvanceAccessOkResponse: require('./user/user-advance-access-detail/user-advance-access-detail-ok-response-schema.json'),
    UserAdvanceAccessRequestParams: require('./user/user-advance-access-detail/user-advance-access-detail-params-schema.json'),

    CustomerListOkResponse: require('./customer/customer-list-request/customer-list-ok-response-schema.json'),
    CustomerListRequestBody: require('./customer/customer-list-request/customer-list-request-body-schema.json'),
    CustomerListRequestParams: require('./customer/customer-list-request/customer-list-request-params-schema.json'),
    LinkCustomerRequestOkResponse: require('./customer/link-customer-request/link-customer-ok-response-schema.json'),
    LinkCustomerRequestBody: require('./customer/link-customer-request/link-customer-request-body-schema.json'),
    LinkCustomerRequestParams: require('./customer/link-customer-request/link-customer-request-params-schema.json'),
    ServiceOrganizationListOkResponse: require('./site/service-organization-list/service-organization-list-ok-response-schema.json'),
    ServiceOrganizationListRequestBody: require('./site/service-organization-list/service-organization-list-request-body-schema.json'),
    ServiceOrganizationListRequestParams: require('./site/service-organization-list/service-organization-list-request-params-schema.json'),
    AddPlatformRequestBody: require('./usermanagement/addPlatform-request/addPlatform-list-request-body-schema.json'),
    AddPlatformRequestOkResponse: require('./usermanagement/addPlatform-request/addPlatform-list-request-ok-response-schema.json'),
    AddPlatformRequestParams: require('./usermanagement/addPlatform-request/addPlatform-list-request-params-schema.json'),
    AddServiceManagerRequestBody: require('./usermanagement/addServiceManager-request/addServiceManager-request-body-schema.json'),
    AddServiceManagerRequestOkResponse: require('./usermanagement/addServiceManager-request/addServiceManager-request-ok-response-schema.json'),
    AddServiceManagerRequestParams: require('./usermanagement/addServiceManager-request/addServiceManager-request-params-schema.json'),
    AddServicePartnerCustomerRequestBody: require('./usermanagement/add-ServicePartnerCustomer-request/add-ServicePartnerCustomer-request-body-schema.json'),
    AddServicePartnerCustomerRequestOkResponse: require('./usermanagement/add-ServicePartnerCustomer-request/add-ServicePartnerCustomer-ok-response-schema.json'),
    AddServicePartnerCustomerRequestParams: require('./usermanagement/add-ServicePartnerCustomer-request/add-ServicePartnerCustomer-request-params-schema.json'),
    GetServicePartnerCustomerListRequestBody: require('./usermanagement/get-servicePartnerCustomerList-request/get-servicePartnerCustomerList-request-body-schema.json'),
    GetServicePartnerCustomerListRequestOkResponse: require('./usermanagement/get-servicePartnerCustomerList-request/get-servicePartnerCustomerList-request-body-schema.json'),
    GetServicePartnerCustomerListRequestParams: require('./usermanagement/get-servicePartnerCustomerList-request/get-servicePartnerCustomerList-request-params-schema.json'),
    PlatformListOkResponse: require('./usermanagement/getPlatfrom-request/platform-list-request-ok-response-schema.json'),
    PlatformListRequestParams: require('./usermanagement/getPlatfrom-request/platform-list-request-params-schema.json'),
    PlatformListRequestBody: require('./usermanagement/getPlatfrom-request/platfrom-list-request-body-schema.json'),
    GetServicePartnerCustomerDetailRequestParams: require('./usermanagement/get-servicePartnerCustomerDetailList-request/get-servicePartnerCustomerDetail-request-params-schema.json'),
    GetServicePartnerCustomerDetailListOkResponse: require('./usermanagement/get-servicePartnerCustomerDetailList-request/get-servicePartnerCustomerDetailList-ok-response-schema.json'),
    GetServicePartnerCustomerDetailListRequestBody: require('./usermanagement/get-servicePartnerCustomerDetailList-request/get-servicePartnerCustomerDetailList-request-body-schema.json'),
    GetServiceManagerCustomerDetailListRequestParams: require('./usermanagement/get-serviceManagerCustomerDetailList-request/get-serviceManagerCustomerDetailList-request-params-schema.json'),
    GetserviceManagerCustomerDetailListOkResponse: require('./usermanagement/get-serviceManagerCustomerDetailList-request/getserviceManagerCustomerDetailList-ok-response-schema.json'),
    GetservicemanagerCustomerDetailListRequestBody: require('./usermanagement/get-serviceManagerCustomerDetailList-request/getservicemanagerCustomerDetailList-request-body-schema.json'),
    DeleteUserOkResponse: require('./user/delete-user/delete-user-ok-response-schema.json'),
    DeleteUserRequestBody: require('./user/delete-user/delete-user-request-body-schema.json'),
    DeleteUserRequestParams: require('./user/delete-user/delete-user-request-params-schema.json'),
    CustomerListV2OkResponse: require('./customer/customer-list-v2-request/customer-list-v2-ok-response-schema.json'),
    CustomerListV2RequestBody: require('./customer/customer-list-v2-request/customer-list-v2-request-body-schema.json'),
    CustomerListV2RequestParams: require('./customer/customer-list-v2-request/customer-list-v2-request-params-schema.json'),
    ServicePartnerDetailOkResponse: require('./usermanagement/servicePartner-detail-request/servicePartner-detail-ok-response-schema.json'),
	ServicePartnerDetailRequestBody: require('./usermanagement/servicePartner-detail-request/servicePartner-detail-request-body-schema.json'),
	ServicePartnerDetailRequestParams: require('./usermanagement/servicePartner-detail-request/servicePartner-detail-request-params-schema.json'),
    RemoveAccessOkResponse: require('./user/remove-access/remove-access-ok-response-schema.json'),
    RemoveAccessRequestBody: require('./user/remove-access/remove-access-request-body-schema.json'),
    RemoveAccessRequestParams: require('./user/remove-access/remove-access-request-params-schema.json'),
    MergeBuildingAssetsOkResponse: require('./project/merge-building-asset/merge-building-asset-ok-response-schema.json'),
    MergeBuildingAssetsRequestBody: require('./project/merge-building-asset/merge-building-asset-request-body-schema.json'),
    MergeBuildingAssetsRequestParams: require('./project/merge-building-asset/merge-building-asset-request-params-schema.json'),
    GetPlatformSystemInfoOkResponse: require('./usermanagement/get-PlatformSystemInfo-request/get-platformSystemInfo-ok-response-schema.json'),
	GetPlatformSystemInfoRequestBody: require('./usermanagement/get-PlatformSystemInfo-request/get-platformSystemInfo-request-body-schema.json'),
	GetPlatformSystemInfoRequestParams: require('./usermanagement/get-PlatformSystemInfo-request/get-platformSystemInfo-request-params-schema.json'),
    PlatformDetailRequestBody: require('./usermanagement/getPlatformDetail-request/platform-detail-request-body-schema.json'),
	PlatformDetailRequestOkResponse: require('./usermanagement/getPlatformDetail-request/platform-detail-request-ok-response-schema.json'),
	PlatformDetailRequestParams: require('./usermanagement/getPlatformDetail-request/platform-detail-request-params-schema.json'),
    RemoveServiceUserRequestBody: require('./usermanagement/remove-serviceUser-request/remove-serviceUser-request-body-schema.json'),
	RemoveServiceUserRequestOkResponse: require('./usermanagement/remove-serviceUser-request/remove-serviceUser-request-ok-response-schema.json'),
	RemoveServiceUserRequestParams: require('./usermanagement/remove-serviceUser-request/remove-serviceUser-request-params-schema.json'),
    RemoveMarkAsFavRequestBody: require('./project/remove-markAsFav-request/remove-markAsFav-request-body-schema.json'),
	RemoveMarkAsFavRequestOkResponse: require('./project/remove-markAsFav-request/remove-markAsFav-request-ok-response-schema.json'),
	RemoveMarkAsFavRequestParams: require('./project/remove-markAsFav-request/remove-markAsFav-request-params-schema.json'),
    MaintenanceDetailRequestBody: require('./usermanagement/getMaintenanceDetail-request/maintenance-detail-request-body-schema.json'),
	MaintenanceDetailRequestOkResponse: require('./usermanagement/getMaintenanceDetail-request/maintenance-detail-request-ok-response-schema.json'),
	MaintenanceDetailRequestParams: require('./usermanagement/getMaintenanceDetail-request/maintenance-detail-request-params-schema.json'),
    CreateMaintainanceRequestBody: require('./usermanagement/add-maintainance-request/create-maintainance-request-body-schema.json'),
	CreateMaintainanceRequestOkResponse: require('./usermanagement/add-maintainance-request/create-maintainance-request-ok-response-schema.json'),
	CreateMaintainanceRequestParams: require('./usermanagement/add-maintainance-request/create-maintainance-request-params-schema.json'),
    UpdateMaintenanceRequestBody: require('./usermanagement/update-maintenance-request/update-maintenance-request-body-schema.json'),
	UpdateMaintenanceRequestOkResponse: require('./usermanagement/update-maintenance-request/update-maintenance-request-ok-response-schema.json'),
	UpdateMaintenanceRequestParams: require('./usermanagement/update-maintenance-request/update-maintenance-request-params-schema.json'),
    DownloadDocumentRequestBody: require('./download/document-download/download-document-request-body-schema.json'),
	DownloadDocumentOkResponse: require('./download/document-download/download-document-ok-response-schema.json'),
	DownloadDocumentRequestParams: require('./download/document-download/download-document-request-params-schema.json'),
    MaintenanceLogsRequestBody: require('./usermanagement/get-maintenance-logs-request/maintenance-logs-request-body-schema.json'),
	MaintenanceLogsRequestOkResponse: require('./usermanagement/get-maintenance-logs-request/maintenance-logs-request-ok-response-schema.json'),
	MaintenanceLogsRequestParams: require('./usermanagement/get-maintenance-logs-request/maintenance-logs-request-params-schema.json'),
    AddNotesRequestBody: require('./usermanagement/add-notes-request/add-maintainancenotes-request-body-schema.json'),
	AddNotesRequestOkResponse: require('./usermanagement/add-notes-request/add-notes-request-ok-response-schema.json'),
	AddNotesRequestParams: require('./usermanagement/add-notes-request/add-notes-request-params-schema.json'),
};