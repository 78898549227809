// eslint-disable-next-line
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Autocomplete, Box, Button, Card, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, IconButton, InputAdornment, MobileStepper, Snackbar, Table, TableBody, TablePagination, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import * as PXBColors from '@brightlayer-ui/colors';
import { Close, GetApp, Search, HelpOutline, CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material';
import { HeadCell, Order } from '../../types/table';
import { Selectors } from '../../selectors';
import { useSelector } from 'react-redux';
import { EnhancedTableHead } from '../../components/Table/EnhancedTableHead';
import { getComparator, stableSort } from '../../lib/table-helpers';
import { ArrowDropDown } from '@mui/icons-material';
import { Checkbox } from '@mui/material';
import { Actions, Thunks } from '../../actions';
import { useDispatch } from 'react-redux';
import { DropDownListComponent } from '../../components/DropDownList/DropDownListComponent';
import { ItemList } from '../../types/logbook-type';
import { EmptyState } from '@brightlayer-ui/react-components';
import { CURRENT_PAGE_SESSION, ORGANIZATTION_NAME, ORG_EMAIL_MAX_LENGTH, RECORD_PER_PAGE_LIMIT } from '../../constants';
import { ENTITY_TYPE, USER_ROLE_TYPE, deviceTypeEnum, technicianStatusEnum } from '../enum/ENUM';
import { useToasts } from '../../data/DataProviders/ToastProvider';
import * as MuiIcons from '@mui/icons-material';
import { vw_15 } from '../../constants/dimentions';
import { useHistory } from 'react-router';
import { UserAdvanceAccessDetail } from '../../types/manage-users';
import { UsersActions } from '../../actions/actions/users-actions';
import SaveIcon from '@mui/icons-material/Save';
import { useOrganizationList } from '../../data/DataProviders/OrganizationListProvider';
import { COLOR_GRAY } from '../../constants/color-codes';
import { Technician } from '@brightlayer-ui/icons-mui';
import { validEmail } from '../../lib/form-validator';
import { TechnicianDataList, TechnicianListItems } from '../../types/technicians';
import { TechnicianListItem } from '../../components/UserManagementListItem/TechnicianListItem';
import { CountryList } from '../../state/registration-state';
import { useAuthState } from '../../components/AuthContextProvider/component';
import { UserInviteEntityModel, UserInviteRequest } from '../../types';
import { UserSelectors } from '../../selectors/user-selectors';
import { user } from '../../../fake-api-server/lib/generate';
import { UserDetailsCard } from '../../components/UserManagementListItem/UserDetailsCard';
import { UserManagementActions } from '../../actions/actions/usermanagement-actions';
import { getUserRoleType } from '../../components/AuthContextProvider/component';

type Props = {
    /* ToDo */
};

export const Technicians: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const DEFAULT_ID: string = '';
    const theme = useTheme();
    const authState = useAuthState();
    const userstype = getUserRoleType(authState);
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState('userName');
    const [compnayId, setCompanyId] = React.useState(DEFAULT_ID);
    const [countryId, setCountryId] = React.useState(DEFAULT_ID);
    const [roleId, setRoleId] = React.useState(DEFAULT_ID);
    const [slaNoId, setSLAId] = React.useState(DEFAULT_ID);
    const [statusId, setStatusId] = React.useState(DEFAULT_ID);
    const [filterCompany, setFilteredCompnay] = React.useState(DEFAULT_ID);
    const [filterCountry, setFilteredCountry] = React.useState(DEFAULT_ID);
    const [filterRole, setFilteredRole] = React.useState(DEFAULT_ID);
    const [filterSLANo, setFilteredSLANo] = React.useState(DEFAULT_ID);
    const [filterStatus, setFilteredStatus] = React.useState(DEFAULT_ID);
    const [searchText, setSearchText] = useState(DEFAULT_ID);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(RECORD_PER_PAGE_LIMIT);
    const [offset, setOffset] = React.useState(1);
    const history = useHistory();
    const [openAddUserDialog, setOpenAddUserDialog] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const [technicianEmail, setTechnicianEmail] = React.useState('');
    const [nextLabel, setNextLabel] = useState<string>(t('ACTIONS.SEND'));
    const backLabel = t('ACTIONS.BACK');
    const addServiceManagerFirstPage1 = 0;
    const addServiceManagerSecondPage2 = 1;
    var disabled = (activeStep === addServiceManagerFirstPage1 ? !technicianEmail : false)
    const usersSelector = useSelector(Selectors.Users.users);
    const [userAccessDetailData, setUserAccessDetailData] = React.useState<UserAdvanceAccessDetail>();
    const [isUserAdvanceInviteCallStatus, setUserAdvanceInviteCallStatus] = useState(true);
    const [nextEnabled, setNextEnabled] = useState(true);
    const useOrganizationDataList = useOrganizationList();
    const userManagement = useSelector(Selectors.Usermanagement.usermanagement);
    const [organizationListData, setOrganizationListData] = React.useState<ItemList>([]);
    const [organizationCheckList, setOrganizationCheckList] = React.useState<ItemList>([]);
    const orgSelectAllId = '-1';
    const [techniciansList, setTechniciansList] = useState<TechnicianDataList>([]);
    const [emptyTechniciansList, setEmptyTechniciansList] = React.useState(false);
    const [techniciansDataLoaded, setTechniciansDataLoaded] = React.useState(false);
    const [selectedTechnician, setSelectedTechnician] = React.useState(DEFAULT_ID);
    const countryListLoading = useSelector(Selectors.Registration.countryListLoading);
    const countryListSuccess = useSelector(Selectors.Registration.countryListSuccess);
    const countryListFail = useSelector(Selectors.Registration.countryListFail);
    const [countryLoaded, setCountryLoaded] = React.useState(false);
    const registration = useSelector(Selectors.Registration.registration);
    const [country, setCountry] = useState<ItemList>([]);
    const [alertErrorMessage, setAlertErrorMessage] = useState<string>('');
    const [alertSuccessMessage, setAlertSuccessMessage] = useState<string>('');
    const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false);
    const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
    const [openTechnicianDetailSelectDialog, setOpenTechnicianDetailSelectDialog] = React.useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const userManagementState = useSelector(Selectors.Usermanagement.usermanagement);
    const [removeTechnicianErrorMessage, setRemoveTechnicianErrorMessage] = useState<string>('');
    const [removeTechnicianSuccessMessage, setRemoveTechnicianSuccessMessage] = useState<string>('');
    const [displayRemoveTechnicianSuccessMessage, setDisplayRemoveTechnicianSuccessMessage] = useState(false);
    const [displayRemoveTechnicianErrorMessage, setDisplayRemoveTechnicianErrorMessage] = useState(false);


    const headCells: HeadCell[] = [
        { id: 'userName', label: t('USERS.NAME'), hideSorting: false },
        { id: 'company_name', label: t('USERS.COMPANY'), hideSorting: false },
        { id: 'company_country', label: t('USERS.COUNTRY'), hideSorting: false },
        { id: 'userStatus', label: t('USERS.STATUS'), hideSorting: false }
    ];

    const getTechniciansList = (list: TechnicianDataList): TechnicianDataList => {
        return list.map((data, index) => {
            return (
                {
                    userName: data.userName,
                    id: data.id,
                    email: data.email,
                    company_name: data.company_name,
                    company_country: data.company_country,
                    userStatus: data.userStatus
                }
            );
        })
    };

    const countryListData = (list: CountryList): ItemList => {
        return list?.map((data, country) => {
            return {
                id: data.name,
                name: data.name,
            };
        });
    };

    //**** Calling filter APIs ****//
    useEffect(() => {
        window.sessionStorage.setItem(CURRENT_PAGE_SESSION, '0');
        dispatch(Thunks.Registration.countryCodeRequest());
        // eslint-disable-next-line
    }, []);

    //***** Populating country filter data ***//
    useEffect(() => {
        if (!countryListLoading && countryListSuccess) {
            setCountry(countryListData(registration.countryList));
            setCountryLoaded(true);
        } else if (!countryListLoading && countryListFail) {
            setCountry([]);
            setCountryId(DEFAULT_ID);
        }
    }, [countryListLoading, countryListSuccess, countryListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!usersSelector.inviteUserLoading && usersSelector.inviteUserSuccess) {
            setOpenAddUserDialog(false);
            setAlertSuccessMessage(t('TOAST_MSG.INVITE_TECHNICIAN_SUCCESS'));
            setDisplaySuccessMessage(true);
            setTechnicianEmail('');
            setOrganizationCheckList([]);
            setIsChecked(false);
        } else if (!usersSelector.inviteUserLoading && usersSelector.inviteUserFail) {
            handleInviteResponse();
            setDisplayErrorMessage(true);
            setOpenAddUserDialog(false);
            setTechnicianEmail('');
            setOrganizationCheckList([]);
            setIsChecked(false);
        }
        dispatch(UsersActions.inviteUserUnmount());
    }, [usersSelector.inviteUserSuccess, usersSelector.inviteUserFail]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleInviteResponse = (): void => {
        if (!usersSelector.inviteUserLoading && usersSelector.inviteUserFail) {
            if (usersSelector.inviteUsererrorCode === '9127')
                setAlertErrorMessage(t('TOAST_MSG.INVITE_TECHNICIAN_ERROR_CODE_9127'));
            else if (usersSelector.inviteUsererrorCode === '9043')
                setAlertErrorMessage(t('TOAST_MSG.INVITE_TECHNICIAN_ERROR_CODE_9043'));
            else if (usersSelector.inviteUsererrorCode === '9136')
                setAlertErrorMessage(t('TOAST_MSG.INVITE_TECHNICIAN_ERROR_CODE_9136'));
            else if (usersSelector.inviteUsererrorCode === '9130')
                setAlertErrorMessage(t('TOAST_MSG.INVITE_TECHNICIAN_ERROR_CODE_9130'));
            else if (usersSelector.inviteUsererrorCode === '9158')
                setAlertErrorMessage(t('TOAST_MSG.INVITE_TECHNICIAN_ERROR_CODE_9158'));
            else if (usersSelector.inviteUsererrorCode === '9159')
                setAlertErrorMessage(t('TOAST_MSG.INVITE_TECHNICIAN_ERROR_CODE_9159'));
            else if (usersSelector.inviteUsererrorCode === '9160')
                setAlertErrorMessage(t('TOAST_MSG.INVITE_TECHNICIAN_ERROR_CODE_9160'));
            else if (usersSelector.inviteUsererrorCode === '9190')
                setAlertErrorMessage(t('TOAST_MSG.INVITE_TECHNICIAN_ERROR_CODE_9190'));
            else if (usersSelector.inviteUsererrorCode === '9233')
                setAlertErrorMessage(t('TOAST_MSG.INVITE_TECHNICIAN_ERROR_CODE_9233'));
            else if (usersSelector.inviteUsererrorCode === '9224')
                setAlertErrorMessage(t('TOAST_MSG.INVITE_TECHNICIAN_ERROR_CODE_9224'));
            else if (usersSelector.inviteUsererrorCode === '9223')
                setAlertErrorMessage(t('TOAST_MSG.INVITE_TECHNICIAN_ERROR_CODE_9223'));
            else if (usersSelector.inviteUsererrorCode === '9234')
                setAlertErrorMessage(t('TOAST_MSG.INVITE_TECHNICIAN_ERROR_CODE_9234'));
            else if (usersSelector.inviteUsererrorCode === '9142')
                setAlertErrorMessage(t('TOAST_MSG.INVITE_TECHNICIAN_ERROR_CODE_9142'));
            else if (usersSelector.inviteUsererrorCode === '9143')
                setAlertErrorMessage(t('TOAST_MSG.INVITE_TECHNICIAN_ERROR_CODE_9143'));
            else
                setAlertErrorMessage(t('REGISTRATION.STEPS_ERROR_MESSAGE.GENERIC_ERROR_MESSAGE'));
        }

    };

    const onOkay = (): void => {
        setDisplayRemoveTechnicianSuccessMessage(false);
    }

    React.useEffect(() => {
        if (!userManagementState.removeServiceUserLoading && userManagementState.removeServiceUserSuccess) {
            setDisplayRemoveTechnicianSuccessMessage(true);
            setRemoveTechnicianSuccessMessage(t('TOAST_MSG.REMOVE_TECHNICIAN_SUCCESS'));
            setOpenTechnicianDetailSelectDialog(false);
            dispatch(Thunks.UserManagement.getTechniciansList(filterCountry, filterStatus, '1', rowsPerPage.toString(), searchText));
        }
        else if (!userManagementState.removeServiceUserLoading && userManagementState.removeServiceUserFail) {
            handleRemoveUserResponse();
            setDisplayRemoveTechnicianErrorMessage(true);
        }
        dispatch(UserManagementActions.removeServiceUserUnmounted());

    }, [userManagementState.removeServiceUserLoading, userManagementState.removeServiceUserSuccess, userManagementState.removeServiceUserFail]); // eslint-disable-line react-hooks/exhaustive-deps


    const handleRemoveUserResponse = (): void => {
        if (!userManagementState.removeServiceUserLoading && userManagementState.removeServiceUserFail) {
            if (userManagementState.removeServiceUserErrorCode === '2007')
                setRemoveTechnicianErrorMessage(t('TOAST_MSG.REMOVE_SERVICE_USER_ERROR_CODE_2007'));
            else if (userManagementState.removeServiceUserErrorCode === '9131')
                setRemoveTechnicianErrorMessage(t('TOAST_MSG.REMOVE_SERVICE_USER_ERROR_CODE_9131'));
            else if (userManagementState.removeServiceUserErrorCode === '9001')
                setRemoveTechnicianErrorMessage(t('TOAST_MSG.REMOVE_SERVICE_USER_ERROR_CODE_9001'));
            else if (userManagementState.removeServiceUserErrorCode === '3060')
                setRemoveTechnicianErrorMessage(t('TOAST_MSG.REMOVE_SERVICE_USER_ERROR_CODE_3060'));
            else if (userManagementState.removeServiceUserErrorCode === '9238')
                setRemoveTechnicianErrorMessage(t('TOAST_MSG.REMOVE_SERVICE_USER_ERROR_CODE_9238'));
            else if (userManagementState.removeServiceUserErrorCode === '9235')
                setRemoveTechnicianErrorMessage(t('TOAST_MSG.REMOVE_SERVICE_USER_ERROR_CODE_9235'));
            else if (userManagementState.removeServiceUserErrorCode === '9239')
                setRemoveTechnicianErrorMessage(t('TOAST_MSG.REMOVE_SERVICE_USER_ERROR_CODE_9239'));
            else if (userManagementState.removeServiceUserErrorCode === '9236')
                setRemoveTechnicianErrorMessage(t('TOAST_MSG.REMOVE_SERVICE_USER_ERROR_CODE_9236'));
            else if (userManagementState.removeServiceUserErrorCode === '9237')
                setRemoveTechnicianErrorMessage(t('TOAST_MSG.REMOVE_SERVICE_USER_ERROR_CODE_9237'));
            else
                setRemoveTechnicianErrorMessage(t('REGISTRATION.STEPS_ERROR_MESSAGE.GENERIC_ERROR_MESSAGE'));
        }

    };


    useEffect(() => {
        if (filterStatus !== '' || filterCountry !== '') {
            setOpenTechnicianDetailSelectDialog(false);
            dispatch(Thunks.UserManagement.getTechniciansList(filterCountry, filterStatus, '1', rowsPerPage.toString(), searchText));
            setCurrentPage(0);
        } else {
            dispatch(Thunks.UserManagement.getTechniciansList(filterCountry, filterStatus, offset.toString(), rowsPerPage.toString(), searchText));
            const currentPageAfterSearchClear: string = window.sessionStorage.getItem(CURRENT_PAGE_SESSION) || '0';
            setCurrentPage(parseInt(currentPageAfterSearchClear));
        }
        // eslint-disable-next-line
    }, [filterCountry, filterStatus, rowsPerPage, offset]);


    useEffect(() => {
        const orgList = [{ id: orgSelectAllId, name: t('MANAGE_USERS.INVITE_USER_ORGANIZATION_SELECT_ALL') }].concat(useOrganizationDataList.organizationList);
        setOrganizationListData(orgList);
    }, [useOrganizationDataList, useOrganizationDataList.organizationList]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!userManagement.technicianListLoading && userManagement.technicianListSuccess) {
            setEmptyTechniciansList(userManagement.technicianList.detail.length <= 0)
            if (userManagement.technicianList.detail.length > 0) {
                const techniciansList = getTechniciansList(userManagement.technicianList.detail);
                setTechniciansList(techniciansList);
                setTotalRecords(userManagement.technicianList.totalRecordCount)
            } else {
                setTechniciansList([]);
            }
            setTechniciansDataLoaded(true);
        }
    }, [userManagement.technicianListLoading, userManagement.technicianListSuccess, userManagement.technicianListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!usersSelector.getuserAdvanceAccessLoading && usersSelector.getuserAdvanceAccessSuccess) {
            setUserAccessDetailData(usersSelector.getuserAdvanceAccess);
            setUserAdvanceInviteCallStatus(true);
        }
        else if (usersSelector.getuserAdvanceAccessFail && !usersSelector.getuserAdvanceAccessLoading) {
            setUserAdvanceInviteCallStatus(false);
            dispatch(UsersActions.inviteUserUnmount());
        }
        else {
            setUserAdvanceInviteCallStatus(false);
            dispatch(UsersActions.inviteUserUnmount());
        }
    }, [usersSelector.getuserAdvanceAccessFail, usersSelector.getuserAdvanceAccessLoading, usersSelector.getuserAdvanceAccessSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

    // TO-Do when service manager will have roles assigned
    /*useEffect(() => {
        setNextLabel(activeStep === addServiceManagerFirstPage1 ? t('ACTIONS.NEXT') : t('ACTIONS.SAVE'));
        if (activeStep === addServiceManagerSecondPage2) {
            const selectAll = organizationCheckList.filter((org) => org.id === orgSelectAllId);
            var selectedOrganizations: string[] = [];
            if (selectAll.length > 0) {
                const allOrganizations: string[] = organizationCheckList.map((organization) => organization.id);
                selectedOrganizations = allOrganizations.filter((organization) => organization !== orgSelectAllId);
            } else {
                selectedOrganizations = organizationCheckList.map((organization) => organization.id);
            }
            dispatch(Thunks.Users.getUserAdvanceAccessDetail(technicianEmail, selectedOrganizations));
        }

    }, [activeStep]); // eslint-disable-line react-hooks/exhaustive-deps*/

    useEffect(() => {
        if (techniciansDataLoaded) {
            /* Debouncing to override user typing */
            const delayDebounceFn = setTimeout(() => {
                if (searchText !== '') {
                    dispatch(Thunks.UserManagement.getTechniciansList(filterCountry, filterStatus, '1', rowsPerPage.toString(), searchText));
                    setCurrentPage(0);
                } else {
                    dispatch(Thunks.UserManagement.getTechniciansList(filterCountry, filterStatus, offset.toString(), rowsPerPage.toString(), searchText));
                    const currentPageAfterSearchClear: string = window.sessionStorage.getItem(CURRENT_PAGE_SESSION) || '0';
                    setCurrentPage(parseInt(currentPageAfterSearchClear));
                }
            }, 3000)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [searchText]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isChecked ? validEmail(technicianEmail) : validEmail(technicianEmail) && organizationCheckList.length > 0) {
            setNextEnabled(true);
        }
        else {
            setNextEnabled(false);
        }
    }, [organizationCheckList, technicianEmail, isChecked]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        openTechnicianDetailSelectDialog === false ? setSelectedTechnician('') : ''
    }, [openTechnicianDetailSelectDialog]);

    const goToCreateCustomer = (): void => {
        //ToDo - got to add customer form
    };

    const onHelpClick = (): void => {

    }

    //**** Search text ***//
    const onChangeSearchText = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        setOpenTechnicianDetailSelectDialog(false);
        if (e.target.value === '' || ORGANIZATTION_NAME.test(e.target.value as string))
            setSearchText(e.target.value);
    };

    const resetSearch = (): void => {
        setOpenTechnicianDetailSelectDialog(false);
        setSearchText(DEFAULT_ID);
        setSelectedTechnician(DEFAULT_ID);
    };

    const handleClose = (): void => {
        setActiveStep(0);
        setOpenAddUserDialog(false);
        setOrganizationCheckList([])
        setIsChecked(false);
    };

    //*** Sorting ***//
    const handleRequestSort = (event: React.MouseEvent<unknown>, property: any): void => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        const sortedValue = stableSort(techniciansList, getComparator(order, orderBy));
        setTechniciansList(sortedValue);
    };

    //*** Pagination ***//
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setCurrentPage(newPage);
        window.sessionStorage.setItem(CURRENT_PAGE_SESSION, newPage.toString());
        setOffset(newPage + 1);
        setSelectedTechnician(DEFAULT_ID);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        window.sessionStorage.setItem(CURRENT_PAGE_SESSION, '0');
        setCurrentPage(0);
        setOffset(1);
        setSelectedTechnician(DEFAULT_ID);
        setOpenTechnicianDetailSelectDialog(false);
    };
    function getUserInviteRequest(email: string, inviteAsColleague: boolean, orgIdList: string[], entityType: number, roleType: number): UserInviteRequest {
        const entityList: UserInviteEntityModel[] = orgIdList.map((org) => {
            return {
                entityId: org,
                entityType: '' + entityType,
                grantAccessToBuilding: false,
                roleType: '' + roleType,
            }
        });

        return {
            emailId: email,
            inviteAsColleague: inviteAsColleague,
            inviteEntities: entityList
        }
    }
    var request = undefined;
    const handleNext = () => {
        const selectAll = organizationCheckList.filter((org) => org.id === orgSelectAllId);
        var selectedOrganizations: string[] = [];
        if (selectAll.length > 0) {
            const allOrganizations: string[] = organizationListData.map((organization) => organization.id);
            selectedOrganizations = allOrganizations.filter((organization) => organization !== orgSelectAllId);
        } else {
            selectedOrganizations = organizationCheckList.map((organization) => organization.id);
        }
        request = getUserInviteRequest(technicianEmail, false, selectedOrganizations, ENTITY_TYPE.ORGANIZATION, USER_ROLE_TYPE.EL_ORG_ADMIN);
        dispatch(Thunks.Users.inviteUser(technicianEmail, false, request.inviteEntities, USER_ROLE_TYPE.TECHNICIAN));
    };

    const handleSave = () => {
        //To Do
    };


    const handleBack = () => {
        setTechnicianEmail(technicianEmail);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleAddUser = () => {
        setOpenAddUserDialog(true);
        setTechnicianEmail('');
    }

    const handleDisplay = (value: {
        TechnicianData: TechnicianListItems;
    }): void => {
        setSelectedTechnician(value.TechnicianData.id);
    };

    const handleCheckbox = () => {
        setIsChecked(!isChecked);
        setOrganizationCheckList([])
    }

    const renderStepComponent = (step: number) => {
        switch (step) {
            case 0:
                return (
                    <>
                        {
                            usersSelector.inviteUserLoading && <EmptyState style={{ backgroundColor: PXBColors.gray[100], minHeight: 250, minWidth: 259 }}
                                icon={<CircularProgress id="progress-spinner" />} title={t('USERS.LOADER_HELPER_TEXT')} placeholder={undefined} />
                        }
                        {!usersSelector.inviteUserLoading && <div >
                            <IconButton style={{
                                textAlign: 'right',
                                float: 'right',
                                margin: '8px 16px'
                            }}
                                data-testid="close-advance-button"
                                onClick={handleClose}>
                                <Close style={{ width: 24, height: 24, }} fontSize="small" />
                            </IconButton>

                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                paddingLeft: '35%',
                                paddingTop: '3%'
                            }}>
                                <Technician />
                                <DialogTitle data-testid='dialogHeaderaddUser' style={{ fontFamily: 'Open Sans', fontSize: 18, fontWeight: 600, color: PXBColors.black[500], textAlign: 'center', paddingLeft: 10 }}>
                                    {t('USERS.ADD_TECHNICIAN_TITLE')}
                                </DialogTitle>
                            </div>
                            <Typography
                                id="invite-user-dialog-header-subtitle"
                                data-testid="invite-user-dialog-header-subtitle"
                                style={{ alignSelf: 'left', color: COLOR_GRAY, fontSize: 16, paddingLeft: 24, paddingBottom: 16 }} variant="body2">
                                <b>{t('USERS.STEP1')}</b>: {t('USERS.STEP1_TEXT')}
                            </Typography>
                            <Divider />
                            {<DialogContent sx={{ padding: 3 }}>
                                <div style={{ minWidth: md ? 700 : 0 }}>
                                    <Typography
                                        id="technician-user-select"
                                        data-testid="technician-user-select"
                                        style={{ alignSelf: 'left', marginBottom: 16 }} variant="subtitle1">
                                        {t('MANAGE_USERS.ADVANCED_INVITE_USER_SELECTION')}
                                    </Typography>
                                    <div style={{
                                        display: 'flex',
                                        height: 50,
                                    }}>

                                        <TextField
                                            id="invite-user-email-advance"
                                            data-testid="invite-user-email-advance-label"
                                            inputProps={{ 'data-testid': 'invite-user-email-advance', maxLength: ORG_EMAIL_MAX_LENGTH }}
                                            label={t('MANAGE_USERS.INVITE_USER_EMAIL_NAME')}
                                            variant="filled"
                                            value={technicianEmail}
                                            error={technicianEmail.length > 0 && !validEmail(technicianEmail)}
                                            helperText={technicianEmail.length > 0 && !validEmail(technicianEmail) ? (t('FORMS.INVALID_EMAIL_ERROR')) : ''}
                                            onChange={(evt: ChangeEvent<HTMLInputElement>): void => setTechnicianEmail(evt.target.value)}
                                            style={{
                                                alignSelf: 'center', width: md ? '65%' : '58%'
                                            }} />

                                    </div>
                                    <Typography
                                        id="technician-organization-select"
                                        data-testid="technician-organization-select"
                                        style={{ alignSelf: 'left', marginBottom: 16, marginTop: 16, width: '100%' }} variant="subtitle1">
                                        {t('MANAGE_USERS.ADVANCED_INVITE_USER_ORGANIZATION_SELECTION')}
                                    </Typography>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginTop: 20,
                                        //width: md ? '65%' : '58%'
                                    }}>
                                        <div style={{ width: md ? '65%' : '58%' }}>
                                            <Autocomplete
                                                multiple
                                                id="invite-user-select-org"
                                                data-testid="autocomplete-org"
                                                sx={{
                                                    "& .MuiChip-label": { fontSize: 16 }
                                                }}
                                                style={{ backgroundColor: '#f7f8f8' }}
                                                options={organizationListData}
                                                disabled={isChecked}
                                                value={isChecked ? [] : organizationCheckList}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                getOptionLabel={(option) => option.id === orgSelectAllId ? t('DASHBOARD.ALL_ORGANIZATION') : option.name}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <div data-testid="select-option" style={{ flex: '1 1 0px', overflow: 'auto', fontSize: md ? 16 : 10 }}>
                                                            <Checkbox
                                                                data-testid="checkbox-autocomplete"
                                                                icon={<CheckBoxOutlineBlank />}
                                                                checkedIcon={<CheckBox />}
                                                                style={{ marginRight: 8 }}
                                                                checked={selected}
                                                                onChange={() => { }}
                                                            />
                                                            {option.name}
                                                            {option.id === orgSelectAllId && <Divider />}
                                                        </div>
                                                    </li>
                                                )}
                                                onChange={(event, newValue, reason) => {
                                                    if (
                                                        event.type === 'keydown' &&
                                                        (event as React.KeyboardEvent).key === 'Backspace' &&
                                                        reason === 'removeOption'
                                                    ) { return; }
                                                    let selectAllOption = newValue.filter(x => x.id === orgSelectAllId);
                                                    if (newValue.includes(selectAllOption[0])) {
                                                        setOrganizationCheckList(newValue.filter(x => x.id === orgSelectAllId) as ItemList);
                                                    }
                                                    else {
                                                        setOrganizationCheckList(newValue as ItemList);
                                                    }
                                                }}

                                                renderInput={(params) => (
                                                    <TextField
                                                        data-testid="invite-user-auto-org-text"
                                                        {...params}
                                                        label={t('MANAGE_USERS.INVITE_USER_ORGANIZATION_LABEL')}
                                                        variant="filled"
                                                    />
                                                )}
                                            />
                                        </div>
                                        <Checkbox checkedIcon={<CheckBox />} style={{ marginRight: 8 }} checked={isChecked} onChange={handleCheckbox} />
                                        {t('MANAGE_USERS.INVITE_USER_DO_NOT_ASSIGN')}
                                    </div>
                                </div>
                            </DialogContent>}
                        </div>}
                    </>
                )
            //TO-DO - Add service manager second page
            // case 1:
            //     return (
            //         <AddTechnicianDialogNext userAccessDetailData={userAccessDetailData ? userAccessDetailData : {
            //             inviteByUserEntityAccessDetail: [],
            //             inviteUserEntityAccessDetail: []
            //         }}
            //             isAdvanceTreeDataAvailable={isUserAdvanceInviteCallStatus} closeClick={() => { handleClose(); }} email={technicianEmail} />
            //     )
            default:
                return null;
        }
    };

    return (
        <div id="technician-container" data-testid="technician-container">
            <div data-testid="technician-buttons" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                    id="technician-add-user"
                    data-testid="technician-add-user"
                    style={{
                        flexDirection: 'row',
                        backgroundColor: PXBColors.white[50],
                        textTransform: 'none',
                    }}
                    variant="outlined"
                    color="primary"
                    disabled={userstype === USER_ROLE_TYPE.TECHNICIAN ? true : false}
                    onClick={() => { handleAddUser() }}
                >
                    {md ? t('USERS.ADD_USER') : <MuiIcons.Add></MuiIcons.Add>}
                </Button>
                <div style={{ display: 'flex', marginTop: 'auto', marginRight: (openTechnicianDetailSelectDialog) ? '60%' : '', marginLeft: 'auto' }}>
                </div>
            </div>

            <div data-testid="technician-search" style={{ display: 'flex', }}>
                <DropDownListComponent disabled={true} data-testid="technician-company-filter" list={deviceTypeEnum} value={t('SEARCH.ALL_COMPANY')} handleSelection={setFilteredCompnay} selected={compnayId} defaultSelection={''}></DropDownListComponent>
                <DropDownListComponent disabled={false} data-testid="technician-country-filter" list={country} value={t('SEARCH.ALL_COUNTRY')} handleSelection={setFilteredCountry} selected={countryId} defaultSelection={''}></DropDownListComponent>
                <DropDownListComponent disabled={false} data-testid="technician-status-filter" list={technicianStatusEnum} value={t('SEARCH.ALL_STATUSES')} handleSelection={setFilteredStatus} selected={statusId} defaultSelection={''}></DropDownListComponent>
                <TextField
                    inputProps={{ 'data-testid': 'search', maxLength: 100 }}
                    value={searchText}
                    style={{
                        width: 'auto',
                        textAlign: 'right',
                        float: 'right',
                        border: '0px solid #727E84',
                        borderRadius: 5,
                        backgroundColor: 'white',
                        height: 32,
                        borderBlock: 0,
                        outline: 'none',
                        overflow: 'hidden',
                        marginTop: 28,
                        fontFamily: 'Open sans',
                        fontSize: 14,
                        color: '#727E84',
                        fontStyle: 'normal',
                        fontWeight: 400,
                    }}
                    placeholder={t('ORGANIZATION_MANAGE.SEARCH_BUILDINGS')}
                    onChange={(e): void => onChangeSearchText(e)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search data-testid="search-icon" style={{ color: PXBColors.gray[500] }} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                {searchText !== '' && (
                                    <IconButton onClick={resetSearch}>
                                        <Close fontSize="small" />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                />
            </div>

            <Card style={{ flex: '1 1 0px', overflow: 'auto', marginTop: '2%', width: (openTechnicianDetailSelectDialog) ? '77.5%' : '100%', transitionDelay: '0.2s', transitionDuration: '0.5s' }} id="technician-list-container" data-testid="technician-list-container">
                <Table stickyHeader size="small"
                    id="technician-list-table"
                    data-testid="technician-list-table" style={{ width: '100%' }}>
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={headCells}
                    />
                    {!emptyTechniciansList && !userManagement.technicianListLoading && <TableBody className="tableHover" style={{ minHeight: 100 }} key={'mainTable'}
                        id="technician-list-table-body"
                        data-testid="technician-list-table-body">
                        {stableSort(techniciansList, getComparator(order, orderBy)).map((row: TechnicianListItems, index: number) => (
                            <TechnicianListItem
                                key={row.id}
                                row={row}
                                selectedTechnician={selectedTechnician}
                                displayClick={handleDisplay}
                                onSuccess={(state: boolean) => setOpenTechnicianDetailSelectDialog(state)}
                            ></TechnicianListItem>
                        ))}
                    </TableBody>}
                </Table>

                {userManagement.technicianListLoading && <EmptyState style={{ flex: 1, minHeight: 400, backgroundColor: PXBColors.white[50] }}
                    icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}
                {!userManagement.technicianListLoading && emptyTechniciansList && <EmptyState style={{ flex: 1, height: 200 }}
                    title={t('USERS.ERROR_NO_TECHNICIAN_FOUND')} icon={undefined} placeholder={undefined} />}


                <Box style={{ flex: '1 1 0px', backgroundColor: PXBColors.white[50] }}>
                    {!emptyTechniciansList && <TablePagination
                        component="div"
                        data-testid="pagination-technicians"
                        count={totalRecords}
                        page={currentPage}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={t('LABELS.ITEMS_PER_PAGE')}
                        showFirstButton showLastButton
                    />}
                </Box>
                <Dialog id="invite-user-dialog" data-testid="invite-user-dialog"
                    maxWidth="md" open={openAddUserDialog}
                    sx={{ maxHeight: 900, overflow: 'hidden' }}
                >
                    {renderStepComponent(activeStep)}

                    <Divider />
                    {!usersSelector.inviteUserLoading && <MobileStepper
                        variant="dots"
                        steps={2}
                        position="static"
                        activeStep={activeStep}
                        sx={{ maxWidth: "auto", backgroundColor: PXBColors.white[50], flexGrow: 1, height: 84, padding: 3 }}
                        nextButton={
                            <Button
                                style={{
                                    minWidth: 120, backgroundColor: nextEnabled ? PXBColors.blue[500] : PXBColors.blue[50],
                                    color: nextEnabled ? PXBColors.white[50] : PXBColors.blue[200], textTransform: 'none',
                                    padding: 16,
                                    paddingTop: 6,
                                    paddingBottom: 6,
                                    borderRadius: 4,
                                }}
                                startIcon={activeStep === addServiceManagerSecondPage2 ? <SaveIcon /> : <MuiIcons.Send />}
                                disabled={!nextEnabled}
                                variant={'contained'}
                                color={'primary'}
                                onClick={handleNext}
                                data-testid="invite-user-advance-next-button"
                            >
                                {nextLabel}
                            </Button>
                        }
                        backButton={
                            <Button
                                style={{
                                    minWidth: 120,
                                    visibility: activeStep === addServiceManagerFirstPage1 ? 'hidden' : 'visible',
                                    textTransform: 'none',
                                    border: '1px solid  #727E84',
                                    borderColor: PXBColors.blue[500],
                                    borderRadius: 4,
                                }}
                                variant="outlined"
                                color={'primary'}
                                onClick={handleBack}
                                data-testid="invite-user-advance-back-button"
                            >
                                {backLabel}
                            </Button>
                        }
                    />}

                </Dialog >
            </Card>

            {
                displaySuccessMessage &&
                <Snackbar
                    open={true}
                    onClose={() => setDisplaySuccessMessage(false)}
                    autoHideDuration={5000}
                    title={''}
                    message={alertSuccessMessage}
                    action={
                        <Button color="inherit" size="small" onClick={() => setDisplaySuccessMessage(false)}>
                            {t('ACTIONS.OKAY')}
                        </Button>
                    }
                    ContentProps={{ sx: { background: PXBColors.black[900] } }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    sx={{ bottom: { xs: 90, sm: 0 } }}
                    style={{ height: 200, width: 200, flexGrow: 0 }}
                />
            }

            {
                displayErrorMessage &&
                <Snackbar
                    open={true}
                    onClose={() => setDisplayErrorMessage(false)}
                    autoHideDuration={5000}
                    title={''}
                    message={alertErrorMessage}
                    action={
                        <Button color="inherit" size="small" onClick={() => setDisplayErrorMessage(false)}>
                            {t('ACTIONS.RETRY')}
                        </Button>
                    }
                    ContentProps={{ sx: { background: PXBColors.red[500] } }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    sx={{ bottom: { xs: 90, sm: 0 } }}

                    style={{ height: 200, width: 200, flexGrow: 0 }}
                />
            }
            {
                openTechnicianDetailSelectDialog && <Box
                    display="flex"
                    style={{
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        top: 112,
                        right: 0,
                        zIndex: 100,
                        minHeight: '80%',
                        backgroundColor: PXBColors.white[500],
                        position: 'absolute',
                        visibility: openTechnicianDetailSelectDialog ? 'visible' : 'hidden',
                    }}
                    data-testid="gateway-details"
                    sx={{
                        maxWidth: '30%'
                    }}
                >
                    <UserDetailsCard userType={USER_ROLE_TYPE.TECHNICIAN} selectedId={selectedTechnician} open={openTechnicianDetailSelectDialog} onSuccess={(state: boolean) => setOpenTechnicianDetailSelectDialog(state)} ></UserDetailsCard>
                </Box>
            }

            {
                displayRemoveTechnicianSuccessMessage &&
                <Snackbar
                    open={true}
                    onClose={onOkay}
                    autoHideDuration={5000}
                    title={''}
                    message={removeTechnicianSuccessMessage}
                    action={
                        <Button color="inherit" size="small" onClick={onOkay}>
                            {t('ACTIONS.OKAY')}
                        </Button>
                    }
                    ContentProps={{ sx: { background: PXBColors.black[900] } }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    sx={{ bottom: { xs: 90, sm: 0 } }}
                    style={{ height: 200, width: 200, flexGrow: 0 }}
                />
            }

            {
                displayRemoveTechnicianErrorMessage &&
                <Snackbar
                    open={true}
                    onClose={() => setDisplayRemoveTechnicianErrorMessage(false)}
                    autoHideDuration={5000}
                    title={''}
                    message={removeTechnicianErrorMessage}
                    action={
                        <Button color="inherit" size="small" onClick={() => setDisplayRemoveTechnicianErrorMessage(false)}>
                            {t('ACTIONS.OKAY')}
                        </Button>
                    }
                    ContentProps={{ sx: { background: PXBColors.red[500] } }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    sx={{ bottom: { xs: 90, sm: 0 } }}

                    style={{ height: 200, width: 200, flexGrow: 0 }}
                />
            }

        </div >

    );
};
