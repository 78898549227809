import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { Selectors } from '../../selectors';
import { EmptyState } from '@brightlayer-ui/react-components';
import * as PXBColors from '@brightlayer-ui/colors';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { Thunks } from '../../actions';
import { OrgBuildingData } from '../../types/organization';
import { buildingDetailsDefault } from '../overview/OverviewListComponent';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { AssetListItem } from '../../components/AssetListItem/AssetListItem';
import { ACTION_TYPE_NEXT, ACTION_TYPE_NONE } from '../../constants';
import {
    Typography,
    Card,
    Divider,
    CardContent,
    Button,
    CircularProgress,
} from '@mui/material';

type Props = {
    goToDashboard: Function;
    goToEditBuilding: Function;
};

export const DeviceBuildingInfoCard: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();

    const [buildingDetails, setBuildingDetails] = useState<OrgBuildingData>(buildingDetailsDefault);
    const organization = useSelector(Selectors.Organization.organization);

    useEffect(() => {
        if (!organization.buildingListLoading && organization.buildingListSuccess) {
            if (organization.buildingList.length > 0) {
                setBuildingDetails(organization.buildingList[0]);
            }
        } else if (!organization.buildingListLoading && organization.buildingListFail) {
            setBuildingDetails(buildingDetailsDefault);
        }
    }, [organization.buildingListLoading, organization.buildingListSuccess, organization.buildingListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {organization.buildingListLoading && <EmptyState style={{ flex: 1, maxHeight: 500, backgroundColor: PXBColors.white[50] }}
                icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}

            {organization.buildingListSuccess && <Card style={{ flex: '1 1 0px', overflow: 'auto' }} id="device-buildingInfo-container" data-testid="device-buildingInfo-container">
                <CardContent style={{ flex: '1 1 0px', overflow: 'auto' }}>
                    <div style={{ display: 'flex' }}>
                        <Typography
                            variant="body2"
                            style={{
                                alignItems: 'left',
                                fontSize: 14,
                                fontWeight: 600,
                                color: PXBColors.blue[400],
                                width: '92.3%',
                            }}
                            id="building-info-titleId"
                            data-testid="building-info-title-data-testId"
                        >{t('ASSETS_DETAILS.BUILDING_INFO_HEADER')}</Typography>
                        {buildingDetails.isAdmin ? <IconButton color={'inherit'} edge={'start'} size="large"
                            style={{
                                width: '7.41%',
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                marginLeft: 'auto'
                            }}
                            onClick={(): void => props.goToEditBuilding(buildingDetails.parentProjectId)}>
                            <EditIcon style={{ color: PXBColors.gray[500] }} />
                        </IconButton> : ''}
                    </div>
                </CardContent>
                <Divider data-testid='dividerId1' style={{ borderColor: PXBColors.gray[50] }} />
                <div style={{ padding: '16px', background: PXBColors.white[100] }}>
                    <Typography
                        variant="body2"
                        style={{
                            alignItems: 'left',
                            fontSize: 16,
                            fontWeight: 600,
                            width: '92.3%',
                        }}
                        id="building-address-titleId"
                        data-testid="building-address-data-testId"
                    >{buildingDetails.address}</Typography>
                    <Typography
                        variant="body2"
                        style={{
                            alignItems: 'left',
                            fontSize: 16,
                            fontWeight: 600,
                            width: '92.3%',
                        }}
                        id="building-address2-titleId"
                        data-testid="building-address2-data-testId"
                    >{buildingDetails.address2}</Typography>
                    <Typography
                        variant="body2"
                        style={{
                            alignItems: 'left',
                            fontSize: 16,
                            fontWeight: 600,
                            width: '92.3%',
                        }}
                        id="building-postalCode-titleId"
                        data-testid="building-postalCode-data-testId"
                    >{buildingDetails.postalCode}</Typography>
                    <Typography
                        variant="body2"
                        style={{
                            alignItems: 'left',
                            fontSize: 16,
                            fontWeight: 600,
                            width: '92.3%',
                        }}
                        id="building-city-titleId"
                        data-testid="building-city-data-testId"
                    >{buildingDetails.city}</Typography>
                    <Typography
                        variant="body2"
                        style={{
                            alignItems: 'left',
                            fontSize: 16,
                            fontWeight: 600,
                            width: '92.3%',
                        }}
                        id="building-country-titleId"
                        data-testid="building-country-data-testId"
                    >{buildingDetails.country}</Typography>
                    <Typography
                        variant="body2"
                        style={{
                            alignItems: 'left',
                            fontSize: 14,
                            fontWeight: 400,
                            width: '92.3%',
                        }}
                        id="building-address3-titleId"
                        data-testid="building-address3-data-testId"
                    >{t('ASSETS_DETAILS.BUILDING_ADDRESS')}</Typography>
                </div>
                <Divider data-testid='dividerId1' style={{ borderColor: PXBColors.gray[50] }} />
                <AssetListItem
                    key={'building-details-id'}
                    data-testid='building-details-data-testid'
                    subtitle={''}
                    title={buildingDetails.description}
                    imageType={ACTION_TYPE_NONE}
                    divider={true}
                    titleBold={false}
                ></AssetListItem>
                <AssetListItem
                    key={'office-type-id'}
                    data-testid='office-type-data-testid'
                    subtitle={t('ASSETS_DETAILS.BUILDING_TYPE')}
                    title={"Office"}
                    imageType={ACTION_TYPE_NONE}
                    divider={true}
                    titleBold={true}
                ></AssetListItem>
                <AssetListItem
                    key={'other-systems-id'}
                    data-testid='other-systems-data-testid'
                    subtitle={t('ASSETS_DETAILS.ALL_SYSTEMS_IN_BUILDING')}
                    title={"Other systems"}
                    imageType={ACTION_TYPE_NEXT}
                    OnItemSelected={() => (props.goToDashboard())}
                    divider={true}
                    titleBold={true}
                ></AssetListItem>
                {/* hiding for now. maybe require later
                 <AssetListItem
                    key={'all-contacts-id'}
                    data-testid='all-contact-data-testid'
                    subtitle={t('ASSETS_DETAILS.ALL_CONTACTS_IN_BUILDING')}
                    title={"All Contacts"}
                    imageType={ACTION_TYPE_NEXT}
                    OnItemSelected={() => (void 0)}
                    divider={true}
                    titleBold={true}
                ></AssetListItem> */}
            </Card>
            }
        </>
    );
}
