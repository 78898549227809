// eslint-disable-next-line
import * as React from 'react';
import {
    TreeItemProps,
    TreeItemContentProps,
    useTreeItemState,
    TreeItem,
} from '@mui/x-tree-view/TreeItem';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import { Fade, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { COLOR_GRAY, COLOR_GRAY_ICON } from '../../constants/color-codes';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useEffect } from 'react';
import { ENTITY_TYPE, USER_BASE_ROLE_TYPE } from '../../app/enum/ENUM';
import { USER_ROLE_TYPE } from '../../app/enum/ENUM';

export type NodeDetails = {
    itemId: string;
    newRole: string;
    entityType: string;
    parentOrgId: string;
    parentBuildingId: string;
};


type CustomTreeItemProps = TreeItemContentProps & {
    bgColor?: string;
    bgColorForDarkMode?: string;
    color?: string;
    colorForDarkMode?: string;
    labelInfo?: string;
    getNodeDetails?: (data: NodeDetails) => void;
};

const CustomTreeContent = React.forwardRef(function CustomTreeContent(
    props: CustomTreeItemProps,
    ref,
) {
    const { t } = useLanguageLocale();
    const {
        classes,
        className,
        label,
        itemId,
        icon: iconProp,
        expansionIcon,
        displayIcon,
        getNodeDetails,
        tabIndex,
        onSelect
    } = props;

    const {
        disabled,
        expanded,
        selected,
        focused,
        handleExpansion,
        handleSelection,
        preventSelection,
    } = useTreeItemState(itemId);
    const [nodeName, setNodeName] = React.useState('');
    const [nodeSubTitle, setNodeSubTitle] = React.useState('');
    const [accessRole, setAccessRole] = React.useState(0);
    const [role, setRole] = React.useState('');
    const [entity, setEntity] = React.useState(0);
    const [flagDisabled, setFlagDisabled] = React.useState(false);
    const [isViewOnly, setViewOnly] = React.useState(false);

    const icon = iconProp || expansionIcon || displayIcon;

    useEffect(() => {
        if (label) {
            const nodeDataItem = label.toString().split('$')[0].split('#');
            setNodeName(nodeDataItem[0]);
            setNodeSubTitle(nodeDataItem[1]);
            setEntity(parseInt(nodeDataItem[2]));

            const userSelectedRole = parseInt(label.toString().split('$')[1]);
            if ((userSelectedRole)) {
                if (userSelectedRole === USER_ROLE_TYPE.EL_ORG_VIEWER || userSelectedRole === USER_ROLE_TYPE.EL_BLDG_VIEWER || userSelectedRole === USER_ROLE_TYPE.EF_SYSTEM_VIEWER) {
                    setAccessRole(USER_BASE_ROLE_TYPE.VIEWER);
                }
                else if (userSelectedRole === USER_ROLE_TYPE.EL_ORG_ADMIN || userSelectedRole === USER_ROLE_TYPE.EF_BLDG_ADMIN_ORG_VIEWER || userSelectedRole === USER_ROLE_TYPE.EF_SYSTEM_ADMIN_GATEWAY_VIEWER) {
                    setAccessRole(USER_BASE_ROLE_TYPE.ADMIN);
                }
                else {
                    setAccessRole(0);
                }
            }
            const selectionFlag = label.toString().split('$')[2];
            selectionFlag === 'true' ? setFlagDisabled(true) : setFlagDisabled(false);

        }
        else {
            setNodeName('');
            setNodeSubTitle('');
        }
    }, [label]); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (itemId) {
            const nodeDataItem = itemId.toString().split('#');
            if (nodeDataItem && nodeDataItem.length > 1) {
                setAccessRole(parseInt(nodeDataItem[1]));
                setViewOnly(true);
            }
            else {
                setViewOnly(false);
            }
        }
        else {
            setAccessRole(0);
            setViewOnly(false);
        }
    }, [itemId]); // eslint-disable-line react-hooks/exhaustive-deps

    const getAccessRole = (role: number) => {
        if (role === USER_ROLE_TYPE.EL_ORG_VIEWER || role === USER_ROLE_TYPE.EL_BLDG_VIEWER || role === USER_ROLE_TYPE.EF_SYSTEM_VIEWER) {
            return USER_BASE_ROLE_TYPE.VIEWER;
        }
        else if (role === USER_ROLE_TYPE.EL_ORG_ADMIN || role === USER_ROLE_TYPE.EF_BLDG_ADMIN_ORG_VIEWER || role === USER_ROLE_TYPE.EF_SYSTEM_ADMIN_GATEWAY_VIEWER) {
            return USER_BASE_ROLE_TYPE.ADMIN;
        }
        else {
            return 0;
        }
    }

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        preventSelection(event);
    };

    const handleExpansionClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
        handleExpansion(event);
    };

    const handleSelectionClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
        handleSelection(event);
    };

    const handleChange = (
        event: React.MouseEvent<HTMLElement, MouseEvent>,
        newRole: string,
    ) => {
        //Role- Admin/Viewer/Limited

        let entityType = '';
        let parentOrgId = '';
        let parentBuildingId = '';
        if (label) {
            const nodeDataItem = label.toString().split('$')[0].split('#');
            entityType = nodeDataItem[2]; //entityType
            setEntity(parseInt(entityType));
            setViewOnly(false);
            if (nodeDataItem[3] !== undefined
                && nodeDataItem[3] !== null
                && nodeDataItem[3] !== ''
            ) {
                parentOrgId = (nodeDataItem[3]); // parentOrgId
            }
            if ((entityType === USER_ROLE_TYPE.EL_BLDG_VIEWER.toString() || entityType === USER_ROLE_TYPE.EF_BLDG_ADMIN_ORG_VIEWER.toString()) && nodeDataItem[4] !== undefined   // entityType ==='4'
                && nodeDataItem[4] !== null
                && nodeDataItem[4] !== ''
            ) {
                parentBuildingId = (nodeDataItem[4]); // parentBuildingId
            }
        }
        getNodeDetails && getNodeDetails({ itemId, newRole, entityType, parentOrgId, parentBuildingId });
    };

    return (
        <div
            style={{ marginBottom: 8 }}
            className={clsx(className, classes.root, {
                [classes.expanded]: expanded,
                [classes.selected]: selected,
                [classes.focused]: focused,
                [classes.disabled]: disabled,
            })}
            onMouseDown={handleMouseDown}

            ref={ref as React.Ref<HTMLDivElement>}
        >
            <div data-testid="expand-icon" onClick={handleExpansionClick} className={classes.iconContainer}>
                {icon}
            </div>
            <Typography
                onClick={handleSelectionClick}
                component="div"
                className={classes.label}
            >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography
                        id="tree-item-name"
                        data-testid="tree-item-name"
                        style={{ color: COLOR_GRAY, fontWeight: 600, fontSize: 16, paddingLeft: 8, }} variant="subtitle1">
                        {nodeName}
                    </Typography>
                    <Typography
                        id="tree-item-name-sub"
                        data-testid="tree-item-name-sub"
                        style={{ alignSelf: 'left', color: COLOR_GRAY, fontWeight: 400, fontSize: 14, paddingLeft: 8, paddingRight: 24 }} variant="body2">
                        {' (' + nodeSubTitle + ')'}
                    </Typography>
                </div>
            </Typography >

            <ToggleButtonGroup
                data-testid="toggle-group"
                color="info"
                sx={{ height: 24 }}
                value={role}
                exclusive
                disabled={flagDisabled}
                onChange={handleChange}
                aria-label="small outlined"
            >
                {/* Limit Role not availa yet */}
                {/* <ToggleButton data-testid="toggle-limited" style={{ color: COLOR_GRAY_ICON, width: 80, textTransform: 'none' }} selected={accessRole === ACCESS_ROLE_TYPE.LIMITED ? true : false}  disabled={label?.toString().includes("Luminaire") ? true : false} value="limited">{label?.toString().includes("Luminaire") ? <LockOutlinedIcon style={{ color: 'gray', width: 16, height: 16 }} /> : t('MANAGE_USERS.USER_ROLE_LIMITED')}  </ToggleButton> */}

                {isViewOnly && <ToggleButton data-testid="toggle-viewer" selected={accessRole === USER_BASE_ROLE_TYPE.VIEWER ? true : false} style={{ width: 80, textTransform: 'none' }} value={USER_BASE_ROLE_TYPE.VIEWER} >{t('MANAGE_USERS.USER_ROLE_VIEWER')}</ToggleButton>}
                {isViewOnly && <ToggleButton data-testid="toggle-admin" selected={accessRole === USER_BASE_ROLE_TYPE.ADMIN ? true : false} style={{ width: 80, textTransform: 'none' }} value={USER_BASE_ROLE_TYPE.ADMIN}>{t('MANAGE_USERS.USER_ROLE_ADMIN')}</ToggleButton>}

                {!isViewOnly && <ToggleButton data-testid="toggle-viewer" selected={label ? (getAccessRole(parseInt(label.toString().split('$')[1])) === USER_BASE_ROLE_TYPE.VIEWER ? true : false) : false} style={{ width: 80, textTransform: 'none' }} value={USER_BASE_ROLE_TYPE.VIEWER} >{t('MANAGE_USERS.USER_ROLE_VIEWER')}</ToggleButton>}
                {!isViewOnly && <ToggleButton data-testid="toggle-admin" selected={label ? (getAccessRole(parseInt(label.toString().split('$')[1])) === USER_BASE_ROLE_TYPE.ADMIN ? true : false) : false} style={{ width: 80, textTransform: 'none' }} value={USER_BASE_ROLE_TYPE.ADMIN}>{t('MANAGE_USERS.USER_ROLE_ADMIN')}</ToggleButton>}

            </ToggleButtonGroup>
        </div>
    );
});

export default function CustomTreeItem(props: TreeItemProps & { ContentProps?: { getNodeDetails: (data: any) => void } }) {
    return <TreeItem ContentComponent={CustomTreeContent} {...props}
    >
    </TreeItem>;
};


