// eslint-disable-next-line
import React from 'react';
import { useSelector } from 'react-redux';
import { CardContent, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { Selectors } from '../../selectors';
import AvatarImage from '../../assets/images/Avatar.png';
import { INVITE_USER_TYPE } from '../enum/ENUM';

type Props = {
    email: string;
    userType: number;
};

export const Complete: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const registration = useSelector(Selectors.Registration.registration);
    const { email } = props;
    return (
        <>
            <CardContent style={{ flex: '1 1 0px', overflow: 'auto' }} data-testid="complete-content">
                {!registration.registrationLoading && registration.registrationSuccess && (
                    <>
                        <div id={'success-profile'} >
                            {/* data-testid="success-profile" */}
                            <img
                                style={{
                                    margin: 'auto',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    paddingBottom: '1.5rem',
                                }}
                                src={AvatarImage}
                                alt="Avatar Logo"
                            />
                        </div>
                        <Typography variant={'h6'} style={{ fontWeight: 600 }} data-testid="success-header">
                            {t('MESSAGES.WELCOME')}
                        </Typography>
                        <Typography data-testid="complete-body" >
                            {props.userType !== INVITE_USER_TYPE.TYPE_OTHER_ADOPTOR_USER &&
                                t('REGISTRATION.SUCCESS_MESSAGE', { replace: { email: email } })
                            }
                            {props.userType === INVITE_USER_TYPE.TYPE_OTHER_ADOPTOR_USER &&
                                <Trans i18nKey={t('REGISTRATION.SUCCESS_MESSAGE_OTHER_ADOPTER')}>
                                    Your EL Digital account has successfully synced with the Bright Layer Cloud information associated with the email{' '}
                                    <b style={{ fontWeight: 400 }}>{email}</b>.
                                </Trans>
                            }
                        </Typography>
                    </>
                )}
            </CardContent>
        </>
    );
};
