// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
import {
    Typography,
    Card,
    IconButton,
} from '@mui/material';
import { Luminary, LuminaryList } from '../../types/luminary';
import { useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import ListItem from '@mui/material/ListItem';
import { Button } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { AssetListItem } from '../../components/AssetListItem/AssetListItem';
import { ACTION_TYPE_NEXT } from '../../constants';
import { DeviceState, DEVICE_STATUS, getLuminaryStatusState } from '../enum/ENUM';
import { LuminaryInfo } from './LuminaryInfo';

const luminaryDefault: Luminary = {
    luminaryId: "",
    luminaryName: "",
    type: "",
    circuit: "",
    ATSD: 123,
    lmStatus: 1,
    lmStatusName: "",
    systemEventTime: "2022-07-11T04:03:58Z",
    systemEvent: "",
    lmAddress: 19,
    serialNumber: "",
    model: "",
    luminaryDescription: "",
    functionalTestDelay: "",
    testDuration: "",
    testDurationDescription: "",
    durationTestNext: "2022-07-01T04:03:58Z",
    durationTestPrevious: "2022-06-01T04:03:58Z",
    functionalTestNext: "2022-07-01T04:03:58Z",
    functionalTestPrevious: "2022-06-01T04:03:58Z",
    dtStatus: true,
    dtTestDescription: "",
    functionalTestStatus: true,
    functionalTestDescription: "",
    luminaryEPAS: '',
    luminaryFwVersion: 0,
    luminaryFwReleaseDate: "",
    luminaryDTDuration: 0,
    luminaryLastDT: '',
    luminaryPartNumber: '',
    luminaryManufacturingDate: '',
    luminaryBatteryEPAS: '',
    luminaryBatteryChangeDate: '',
    luminaryProtocolVersion: '',
    luminaryInstallationArea: '',
    luminaryMNMConfiguration: '',
    luminaryDurationTestOddEven: '',
    modelDescription: '',
    profileId: ''
}

type Props = {
    luminaryId: string;
    closeClick: Function;
};

export const DeviceLuminaryDetails: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {

    // const TAB_INFO: string = 'info';
    // const TAB_TEST: string = 'tests';

    // const [luminaryTab, setLuminaryTab] = React.useState(TAB_INFO);

    const { t } = useLanguageLocale();
    const asset = useSelector(Selectors.Dashboard.asset);
    const [luminaryList, setLuminaryList] = useState<LuminaryList>([]);
    const [luminary, setLuminary] = useState<Luminary>(luminaryDefault);
    const [luminaryId, setLuminaryId] = useState(props.luminaryId);

    const luminaryStatus = luminary.lmStatus;

    const updateLuminary = (luminaryId: string) => {
        const luminaryListUpdate: LuminaryList = luminaryList.filter((luminary) => luminary.luminaryId === luminaryId)
        if (luminaryListUpdate.length > 0) {
            setLuminary(luminaryListUpdate[0])
        }
    };

    useEffect(() => {
        setLuminaryId(props.luminaryId);
        updateLuminary(props.luminaryId);
    }, [props.luminaryId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!asset.luminaryListLoading && asset.luminaryListSuccess) {
            setLuminaryList(asset.luminaryList.detail);
            //updateLuminary();
        } else if (!asset.luminaryListLoading && asset.luminaryListFail) {
            console.log('Luminary list loading failed');
            setLuminaryList([]);
        }
    }, [asset.luminaryListLoading, asset.luminaryListSuccess, asset.luminaryListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    const deviceState: DeviceState = getLuminaryStatusState(luminaryStatus);

    const handleClose = (): void => {
        props.closeClick({ luminaryId: luminaryId });
    };

    const handleErrorNext = (): void => {
    };

    // const handleChange = (
    //     event: React.MouseEvent<HTMLElement>,
    //     luminaryTab: string,
    // ) => {
    //     if (luminaryTab !== null) setLuminaryTab(luminaryTab);
    // };

    return (
        <>
            <Card style={{ flex: '1 1 0px', overflow: 'auto' }} data-testid="device-luminary-details-container">

                <ListItem style={{
                    width: '100%',
                    marginTop: 10,
                }}
                    secondaryAction={
                        <IconButton id="device-luminary-close-icon" data-testid="device-luminary-close-icon" style={{ top: -17, right: -5, marginLeft: "auto", justifyContent: 'start' }} onClick={(): void => handleClose()}>
                            <Close />
                        </IconButton>
                    }>

                    <div>
                        <Typography variant="body2" style={{ marginLeft: 5, fontSize: 18, fontWeight: 600 }} id="luminary-name" data-testid="luminary-name" >{luminary.luminaryName}</Typography>

                        <Button variant="text"
                            id="luminary-status-button"
                            data-testid = "luminary-status-button"
                            style={{ marginTop: 5, fontSize: 14, color: '#424E54', textTransform: 'none' }}
                            startIcon={<img
                                src={deviceState.statusImage}
                                alt="logo"
                                style={{ display: 'flex', alignItems: 'right' }}
                                id="list-item-status-icon"
                            />}>
                            {t(deviceState.statusTitle)}
                        </Button>
                    </div>

                </ListItem>

                {luminary.lmStatus === DEVICE_STATUS.STATUS_ERROR && <div style={{ borderRadius: 5, backgroundColor: '#F9E8E8', marginLeft: 10, marginRight: 10 }}>

                    <AssetListItem
                        key={'luminary-info-system-event'}
                        subtitle={luminary.systemEvent ? luminary.systemEvent : '--'}
                        title={(luminary.systemEventTime)}
                        imageType={ACTION_TYPE_NEXT}
                        OnItemSelected={(): void =>
                            handleErrorNext()
                        }
                        divider={false}
                        titleBold={false}
                    ></AssetListItem>

                </div>
                }
                {/* <ToggleButtonGroup
                    id="luminary-toggle"
                    color="primary"
                    value={luminaryTab}
                    exclusive
                    onChange={handleChange}
                    style={{ margin: '2vh' }}
                >
                    <ToggleButton id="luminary-toggle-info" style={{ width: '16vh', height: '5vh', textTransform: 'none' }} value={TAB_INFO}>{t('DEVICE_DETAILS.LUMINARY_DETAIL_INFO')}</ToggleButton>
                    <ToggleButton id="luminary-toggle-test" style={{ width: '16vh', height: '5vh', textTransform: 'none' }} value={TAB_TEST}>{t('DEVICE_DETAILS.LUMINARY_DETAIL_TEST')}</ToggleButton>
                </ToggleButtonGroup>

                {luminaryTab === TAB_INFO &&
                    <LuminaryInfo
                        luminary={luminary} />}

                {luminaryTab === TAB_TEST &&
                    <LuminaryTests
                        luminary={luminary} />
                } */}
                <LuminaryInfo
                        luminary={luminary} />
            </Card >
        </>
    );
};