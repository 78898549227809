// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { Button, Card, CircularProgress, IconButton, InputAdornment, MenuItem, Select, SelectChangeEvent, Table, TableBody, TablePagination, TextField, useMediaQuery, useTheme } from '@mui/material';
import * as PXBColors from '@brightlayer-ui/colors';
import Box from '@mui/material/Box';
import { DropDownListComponent } from '../../components/DropDownList/DropDownListComponent';
import { EnhancedTableHead } from '../../components/Table/EnhancedTableHead';
import { EmptyState } from '@brightlayer-ui/react-components';
import { getComparator, stableSort } from '../../lib/table-helpers';
import { Close, Search } from '@mui/icons-material';
import { isValidCharWithoutSpace } from '../../lib/site-helper';
import { CURRENT_PAGE_SESSION, RECORD_PER_PAGE_LIMIT } from '../../constants';
import { HeadCell, Order } from '../../types/table';
import { Actions, Thunks } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import { MaintenanceDetail, MaintenanceDetailList, MaintenanceDetails, MaintenanceDetailsList } from '../../types/maintenance';
import { MaintenanceListItems } from './MaintenanceListItems';
import { ticketStatus, USER_ROLE_TYPE } from '../enum/ENUM';
import { UserManagementActions } from '../../actions/actions/usermanagement-actions';
import { getUserRoleType, useAuthState } from '../../components/AuthContextProvider';
import { selectStyle } from '../assets/selectStyle';
import { ItemList } from '../../types/logbook-type';
import { useOrganizationList } from '../../data/DataProviders/OrganizationListProvider';
import { customerListV2Data } from '../../types/customer-detail';
import { DeviceList } from '../../types/device';

type Props = {
    location?: any;
    onClick: Function;
};

export const MaintenanceList: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const [filterProject, setFilteredProject] = React.useState('');
    const [focusedProject, setFocusedProject] = React.useState(filterProject ? true : false);
    const dispatch = useDispatch();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState('maintenanceName');
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const [offset, setOffset] = React.useState(1);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(RECORD_PER_PAGE_LIMIT);
    const [searchText, setSearchText] = useState('');
    const [filterStatus, setFilteredStatus] = React.useState();
    const user = useSelector(Selectors.Usermanagement.usermanagement);
    const [maintenanceList, setMaintenanceList] = useState<MaintenanceDetailList>([]);
    const [emptyMaintenanceList, setEmptyMaintenanceList] = React.useState(false);
    const [maintenanceDataLoaded, setMaintenanceDataLoaded] = React.useState(false);
    const [maintenanceActivityList, setMaintenanceActivityList] = useState<MaintenanceDetailsList>([]);
    const [selectedMaintenanceId, setSelectedMaintenanceId] = React.useState('');
    const [filterCustomer, setFilteredCustomer] = React.useState('');
    const [focusedCustomer, setFocusedCustomer] = React.useState(filterCustomer ? true : false);
    const [firstCall, setFirstCall] = React.useState(true);
    const [isFav, setIsFav] = React.useState(false);
    const [isDataLoaded, setIsDataLoaded] = React.useState(true);
    const authState = useAuthState();
    const usertype = getUserRoleType(authState);
    const [organizationListData, setOrganizationListData] = React.useState<ItemList>([]);
    const useOrganizationDataList = useOrganizationList();
    const [customerList, setCustomerList] = React.useState<ItemList>([]);
    const customer = useSelector(Selectors.Customer.customer);
    const [focusedSystem, setFocusedSystem] = React.useState(false);
    const [systemId, setSystemId] = React.useState('');
    const [systemList, setSystemList] = useState<ItemList>([]);

    const asset = useSelector(Selectors.Asset.assets);

    function getDetails(): void {
        throw new Error('Function not implemented.');
    }

    const headCells: HeadCell[] = [
        { id: 'customerName', label: t('USERS.CUSTOMER'), hideSorting: false },
        { id: 'ticketNumber', label: t('MAINTENANCE_DETAILS.TICKET_NO'), hideSorting: false },
        { id: 'systemName', label: t('DEVICE_DETAILS.SYSTEMS'), hideSorting: false },
        { id: 'technicianName', label: t('DEVICE_DETAILS.TECHNICIAN'), hideSorting: false },
        { id: 'serviceType', label: t('MAINTENANCE_DETAILS.SERVICE_TYPE'), hideSorting: false },
        { id: 'serviceDate', label: t('MAINTENANCE_DETAILS.SERVICE_DATE'), hideSorting: false },
        { id: 'sLANumber', label: t('USERS.SLA_MANDATORY_HEADER'), hideSorting: false },
    ];

    useEffect(() => {
        setOrganizationListData(useOrganizationDataList.organizationList);
    }, [useOrganizationDataList, useOrganizationDataList.organizationList]); //eslint-disable-line react-hooks/exhaustive-deps

    const getMaintenanceList = (list: MaintenanceDetailsList): MaintenanceDetailsList => {
        return list.map((data, maintenanceActivity) => {
            return (
                {
                    customerName: data.customerName,
                    ticketNumber: data.ticketNumber,
                    systemName: data.systemName,
                    technicianName: data.technicianName,
                    serviceType: data.serviceType,
                    serviceDate: data.serviceDate,
                    sLANumber: data.sLANumber,
                    alarmLink: data.alarmLink,
                    assignedTo: data.assignedTo,
                    customerId: data.customerId,
                    description: data.description,
                    id: data.id,
                    isFavorite: data.isFavorite === 'true' ? 'true' : 'false',
                    lastUpdatedDateTime: data.lastUpdatedDateTime,
                    organizationId: data.organizationId,
                    organizationName: data.organizationName,
                    requestPriority: data.requestPriority,
                    systemId: data.systemId,
                    systemLocation: data.systemLocation,
                    systemStatus: data.systemStatus,
                    ticketStatus: data.ticketStatus,
                    systemType: data.systemType,
                    buildingId: data.buildingId,
                    buildingName: data.buildingName
                }
            );
        })
    };
    useEffect(() => {
        dispatch(Actions.UserManagement.maintenanceDetailUnmounted())
        dispatch(Thunks.Customer.getCustomerListV2(filterProject));
        dispatch(Thunks.UserManagement.getMaintenanceDetail(filterProject, '', '', '', filterCustomer, '', '', '', offset.toString(), rowsPerPage.toString()));
        setFirstCall(false)
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const systemListLogbook = (list: DeviceList): ItemList => {
        return list?.map((data, system) => {
            return {
                id: data.systemId,
                name: data.systemName,
            };
        });
    };

    useEffect(() => {
        if (!asset.deviceListLoading && asset.deviceListSuccess) {
            setSystemList(systemListLogbook(asset.deviceList.detail));
        } else if (!asset.deviceListLoading && asset.deviceListFail) {
            setSystemList([]);
        }
    }, [asset.deviceListLoading, asset.deviceListSuccess, asset.deviceListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!firstCall) {
            dispatch(Thunks.Customer.getCustomerListV2(filterProject));
            dispatch(Thunks.Asset.getDeviceList(filterProject, '', '', '', '', '', '', filterCustomer));
            dispatch(Thunks.UserManagement.getMaintenanceDetail(filterProject, '', '', searchText, filterCustomer, '', systemId, '', offset.toString(), rowsPerPage.toString(), '', '', filterStatus));
        }
    }, [filterStatus, filterProject, systemId]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!firstCall) {
            dispatch(Thunks.UserManagement.getMaintenanceDetail(filterProject, '', '', searchText, filterCustomer, '', '', '', offset.toString(), rowsPerPage.toString(), '', '', filterStatus));
            dispatch(Thunks.Asset.getDeviceList(filterProject, '', '', '', '', '', '', filterCustomer));
            const currentPageAfterSearchClear: string = window.sessionStorage.getItem(CURRENT_PAGE_SESSION) || '0';
            setCurrentPage(parseInt(currentPageAfterSearchClear));
        }

    }, [rowsPerPage, offset, filterCustomer]); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (user.maintenanceDetailLoading) {
            setIsDataLoaded(true);
        }
        else {
            if (!user.maintenanceDetailLoading && user.maintenanceDetailSuccess) {
                setEmptyMaintenanceList(user.maintenanceDetail.detail.length <= 0)
                if (user.maintenanceDetail.detail.length > 0) {
                    const MaintenanceList = getMaintenanceList(user.maintenanceDetail.detail);
                    setMaintenanceList(MaintenanceList);
                    setTotalRecords(user.maintenanceDetail.totalRecordCount);
                    setMaintenanceActivityList(MaintenanceList);
                } else {
                    setMaintenanceList([]);
                }
                setMaintenanceDataLoaded(true);
                setIsDataLoaded(false);
            } else if (!user.maintenanceDetailLoading && user.maintenanceDetailFail) {
                setMaintenanceList([]);
                setEmptyMaintenanceList(true);
                setMaintenanceActivityList([]);
                setIsDataLoaded(false);
            }
        }
        dispatch(UserManagementActions.maintenanceDetailUnmounted())

    }, [user.maintenanceDetailLoading, user.maintenanceDetailSuccess, user.maintenanceDetailFail]); // eslint-disable-line react-hooks/exhaustive-deps

    const customerListData = (list: customerListV2Data): ItemList => {
        return list?.map((data, customer) => {
            return {
                id: data.id,
                name: data.companyName,
                number: data.customerNumber
            };
        });
    };

    useEffect(() => {
        if (!customer.customerListLoading && customer.customerListSuccess) {
            setCustomerList(customerListData(customer.customerList.customerDetailList));
        }
        else if (!customer.customerListLoading && customer.customerListFail) {
            setCustomerList([]);
        }
    }, [customer.customerListLoading, customer.customerListSuccess, customer.customerListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!firstCall) {
            if (maintenanceDataLoaded) {
                /* Debouncing to override user typing */
                const delayDebounceFn = setTimeout(() => {
                    if (searchText !== '') {
                        dispatch(Thunks.UserManagement.getMaintenanceDetail(filterProject, '', '', searchText, filterCustomer, '', '', '', offset.toString(), rowsPerPage.toString(), '', '', filterStatus));
                        setCurrentPage(0);
                    } else {
                        dispatch(Thunks.UserManagement.getMaintenanceDetail(filterProject, '', '', searchText, filterCustomer, '', '', '', offset.toString(), rowsPerPage.toString(), '', '', filterStatus));
                        const currentPageAfterSearchClear: string = window.sessionStorage.getItem(CURRENT_PAGE_SESSION) || '0';
                        setCurrentPage(parseInt(currentPageAfterSearchClear));
                    }
                }, 3000)
                return () => clearTimeout(delayDebounceFn)
            }
        }
    }, [searchText]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: any): void => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        const sortedValue = stableSort(maintenanceActivityList, getComparator(order, orderBy));
        setMaintenanceActivityList(sortedValue);
    };

    /* const handleFavoriteChange = (
         event: React.ChangeEvent<HTMLElement>,
         checked: boolean,
     ) => {
         if (checked) {
             setIsFav(true);
             dispatch(Thunks.UserManagement.getMaintenanceDetail('','','','','','','',offset.toString(),rowsPerPage.toString()));
             setCurrentPage(0);

         } else {
             setIsFav(false);
         }
     };implementation pending from api side*/

    const onChangeSearchText = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (isValidCharWithoutSpace(e.target.value)) {
            setSearchText(e.target.value);
        }
    };
    const resetSearch = (): void => {
        setSearchText('');
    };
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setCurrentPage(newPage);
        window.sessionStorage.setItem(CURRENT_PAGE_SESSION, newPage.toString());
        setOffset(newPage + 1);
    };


    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        window.sessionStorage.setItem(CURRENT_PAGE_SESSION, '0');
        setCurrentPage(0);
        setOffset(1);
    };

    const handleDisplay = (value: {
        maintenance: MaintenanceDetail;
    }): void => {
        setSelectedMaintenanceId(value.maintenance.id)
        props.onClick({ maintenance: value.maintenance });
    };

    const systemOnChange = (event: SelectChangeEvent) => {
        setSystemId(event.target.value);
        setFocusedSystem(event.target.value !== '');
    };

    const projectOnChange = (event: SelectChangeEvent) => {
        setFilteredProject(event.target.value);
        setFilteredCustomer('');
        setSystemId('');
        setFocusedProject(event.target.value !== '');
        setFocusedCustomer(false);
        setFocusedSystem(false);
    };

    const customerOnChange = (event: SelectChangeEvent) => {
        setFilteredCustomer(event.target.value);
        setSystemId('');
        setFocusedCustomer(event.target.value !== '');
        setFocusedSystem(false);

    };

    return (
        <>
            <div data-testid="maintenance-detail-container">
                <div style={{ display: 'flex' }}>
                    {usertype !== USER_ROLE_TYPE.TECHNICIAN &&
                        <Button
                            style={{
                                flexDirection: 'row',
                                backgroundColor: PXBColors.white[50],
                                textTransform: 'none',
                            }}
                            id="maintenancebutton"
                            variant="outlined"
                            color="primary"
                            data-testid="maintenance-button"
                            disabled={true}
                            onClick={() => { getDetails() }}
                        >
                            {t('MAINTENANCE_DETAILS.NEW_MAINTENANCE')}
                        </Button>
                    }
                </div>


                <div data-testid="maintenance-search-panel" style={{ display: 'flex', }}>
                    <Select
                        disabled={!useOrganizationDataList.orgListLoaded}
                        id="project-list-dropdown"
                        data-testid="maintenance-project-filter"
                        style={{
                            backgroundColor: focusedProject ? PXBColors.lightBlue[50] : PXBColors.white[50],
                            marginRight: '1%',
                            width: 'auto',
                            height: 32,
                            borderBlock: 0,
                            outline: 'none',
                            borderRadius: 32,
                            overflow: 'hidden',
                            fontSize: 14,
                            color: focusedProject ? PXBColors.blue[500] : PXBColors.black[500],
                            fontStyle: 'normal',
                            fontWeight: 400,
                            marginTop: 28,
                        }}
                        value={filterProject}
                        onChange={projectOnChange}
                        displayEmpty
                    >
                        <MenuItem value=""
                            style={selectStyle.dropdown}>{t('SEARCH.ALL_ORGANIZATIONS')}</MenuItem>
                        {organizationListData?.map((data, val) => {
                            return (
                                <MenuItem id="system-list" key={data.id} value={data.id}
                                    style={
                                        selectStyle.dropdown
                                    }>
                                    {data.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <Select
                        disabled={customer.customerListLoading}
                        id="customer-list-dropdown"
                        data-testid="maintenance-customer-filter"
                        style={{
                            backgroundColor: focusedCustomer ? PXBColors.lightBlue[50] : PXBColors.white[50],
                            marginRight: '1%',
                            width: 'auto',
                            height: 32,
                            borderBlock: 0,
                            outline: 'none',
                            borderRadius: 32,
                            overflow: 'hidden',
                            fontSize: 14,
                            color: focusedCustomer ? PXBColors.blue[500] : PXBColors.black[500],
                            fontStyle: 'normal',
                            fontWeight: 400,
                            marginTop: 28,
                        }}
                        value={filterCustomer}
                        onChange={customerOnChange}
                        displayEmpty
                    >
                        <MenuItem value=""
                            style={
                                selectStyle.dropdown
                            }>{t('SEARCH.ALL_CUSTOMER')}</MenuItem>
                        {customerList?.map((data, val) => {
                            return (
                                <MenuItem id="system-list" key={data.id} value={data.id}
                                    style={selectStyle.dropdown}>
                                    {data.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <Select
                        id="system-list-dropdown"
                        data-testid="maintenance-system-list"
                        disabled={asset.deviceListLoading}
                        style={{
                            backgroundColor: focusedSystem ? PXBColors.lightBlue[50] : PXBColors.white[50],
                            marginRight: '1%',
                            width: 'auto',
                            height: 35,
                            borderBlock: 0,
                            outline: 'none',
                            borderRadius: 32,
                            overflow: 'hidden',
                            fontSize: 14,
                            color: focusedSystem ? PXBColors.blue[500] : PXBColors.black[500],
                            fontStyle: 'normal',
                            fontWeight: 400,
                            marginTop: 28,
                        }}
                        value={systemId}
                        onChange={systemOnChange}
                        displayEmpty
                    >
                        <MenuItem value=""
                            style={{
                                display: 'flex',
                                justifyContent: 'left', fontSize: 14,
                                lineHeight: 1.5, fontWeight: 400,
                                overflow: 'hidden',
                                boxSizing: 'border-box',
                                whiteSpace: 'nowrap',
                                minHeight: 48,
                                paddingTop: 6,
                                paddingBottom: 6,
                                width: 'auto',
                                paddingLeft: 16,
                                paddingRight: 16
                            }}>{t('SEARCH.ALL_SYSTEMS')}</MenuItem>
                        {systemList?.map((data, val) => {
                            return (
                                <MenuItem id="system-list" key={data.id} value={data.id}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'left', fontSize: 14,
                                        lineHeight: 1.5, fontWeight: 400,
                                        overflow: 'hidden',
                                        boxSizing: 'border-box',
                                        whiteSpace: 'nowrap',
                                        minHeight: 48,
                                        paddingTop: 6,
                                        paddingBottom: 6,
                                        width: 'auto',
                                        paddingLeft: 16,
                                        paddingRight: 16
                                    }}>
                                    {data.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <DropDownListComponent data-testid="maintenance-status-filter" list={ticketStatus} value={t('MAINTENANCE_DETAILS.STATUS')} handleSelection={setFilteredStatus} selected={''} defaultSelection={''} ></DropDownListComponent>
                    <TextField
                        disabled={false}
                        inputProps={{ 'data-testid': 'search', maxLength: 100 }}
                        value={searchText}
                        style={{
                            width: 'auto',
                            textAlign: 'right',
                            float: 'right',
                            border: '0px solid #727E84',
                            borderRadius: 5,
                            backgroundColor: 'white',
                            height: 32,
                            borderBlock: 0,
                            outline: 'none',
                            overflow: 'hidden',
                            marginTop: 28,
                            fontFamily: 'Open sans',
                            fontSize: 14,
                            color: '#727E84',
                            fontStyle: 'normal',
                            fontWeight: 400,
                        }}
                        placeholder={t('MAINTENANCE_DETAILS.SEARCH')}
                        onChange={(e): void => onChangeSearchText(e)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search data-testid="search-icon" style={{ color: PXBColors.gray[500] }} />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    {searchText !== '' && (
                                        <IconButton onClick={resetSearch}>
                                            <Close fontSize="small" />
                                        </IconButton>
                                    )}
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                    />
                </div>

                <Card style={{ flex: '1 1 0px', overflow: 'auto', marginTop: '2%' }} id="maintenance-list-container" data-testid="maintenance-list-container">
                    <Table stickyHeader size="small"
                        id="maintenance-list-table"
                        data-testid="maintenance-list-table">
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            headCells={headCells}
                        />
                        {!isDataLoaded && !emptyMaintenanceList && maintenanceDataLoaded && <TableBody className="tableHover" style={{ minHeight: 100 }} key={'mainTable'}
                            id="maintenance-list-table-body"
                            data-testid="maintenance-list-table-body">
                            {stableSort(maintenanceActivityList, getComparator(order, orderBy)).map((row: MaintenanceDetails, index: number) => (
                                <MaintenanceListItems
                                    key={row.id}
                                    row={row}
                                    selectedSystem={selectedMaintenanceId}
                                    displayClick={handleDisplay}
                                ></MaintenanceListItems>
                            ))}
                        </TableBody>}
                    </Table>
                    {isDataLoaded && <EmptyState style={{ flex: 1, minHeight: 400, backgroundColor: PXBColors.white[50], }}
                        icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}
                    {!isDataLoaded && emptyMaintenanceList && <EmptyState style={{ flex: 1, minHeight: 400, backgroundColor: PXBColors.white[50] }}
                        title={t('MAINTENANCE_DETAILS.NO_MAINTENACE_FOUND')} icon={undefined} placeholder={undefined} />}
                </Card >

                {!emptyMaintenanceList && <Box style={{ flex: '1 1 0px', backgroundColor: PXBColors.white[50] }}>
                    {<TablePagination
                        component="div"
                        data-testid="pagination-maintenance"
                        SelectProps={{
                            MenuProps: {
                                sx: {
                                    '.MuiTablePagination-menuItem': {
                                        display: 'flex',
                                        flexDirection: 'column'
                                    },
                                },
                            },
                        }}
                        count={totalRecords}
                        page={currentPage}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={t('LABELS.ITEMS_PER_PAGE')}
                        showFirstButton showLastButton
                    />}
                </Box>
                }
            </div>
        </>
    );
};
/*
                <div style={{ display: 'flex', marginLeft: 'auto', marginTop: 'auto' }}>
                    <Typography
                        id="maintenance-favorite-filter-checkbox-label"
                        data-testid="maintenance-favorite-filter-checkbox-label"
                        style={{ alignSelf: 'center' }} variant="body2">
                    </Typography>

                    <Button
                        style={{
                            width: '20px',
                            backgroundImage: `url(${SyncButtonImage})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'filled',
                            backgroundRepeat: 'no-repeat',
                        }}
                        color="primary"
                        data-testid="cancel-location"
                        onClick={() => {

                        }}
                    >
                        {''}
                    </Button>
                </div> {t('ASSETS_DETAILS.SHOW_FAVORITE_SYSTEMS')}
<Checkbox id="gateway-favorite-filter-checkbox"
                        onChange={handleFavoriteChange} /> */
