import { InitialState } from '../state';
import { defaultPlatformDetail, defaultServicePartnerDetail, defaultMaintenanceListData, defaultMaintenanceLogsData } from '../state/usermanagement-state';
import { AppReducer } from './app-reducer';

export const usermanagementReducer: AppReducer<'Usermanagement'> = (state = InitialState.Usermanagement, action): typeof state => {
    switch (action.type) {
        case 'Usermanagement/technicianList/Started':
            return {
                ...state,
                technicianListLoading: true,
                technicianListSuccess: false,
                technicianListFail: false,
                technicianListErrorCode: '',
            };
        case 'Usermanagement/technicianList/Succeeded':
            return {
                ...state,
                technicianListLoading: false,
                technicianListSuccess: true,
                technicianListFail: false,
                technicianListErrorCode: '',
                technicianList: action.payload.TechnicianList,
            };
        case 'Usermanagement/technicianList/Failed':
            return {
                ...state,
                technicianListLoading: false,
                technicianListSuccess: false,
                technicianListFail: true,
                technicianListErrorCode: action.payload.errorCode,
            };

        case 'Usermanagement/technicianList/Unmounted':
            return {
                ...state,
                technicianListLoading: false,
                technicianListSuccess: false,
                technicianListFail: false,
                technicianListErrorCode: '',
            };
        case 'Usermanagement/addPlatform/Started':
            return {
                ...state,
                addPlatformLoading: true,
                addPlatformSuccess: false,
                addPlatformFail: false,
                addPlatformErrorCode: '',
            };
        case 'Usermanagement/addPlatform/Succeeded':
            return {
                ...state,
                addPlatformLoading: false,
                addPlatformSuccess: true,
                addPlatformFail: false,
                addPlatformErrorCode: '',
            };
        case 'Usermanagement/addPlatform/Failed':
            return {
                ...state,
                addPlatformLoading: false,
                addPlatformSuccess: false,
                addPlatformFail: true,
                addPlatformErrorCode: action.payload.errorCode,
            };

        case 'Usermanagement/addPlatform/Unmounted':
            return {
                ...state,
                addPlatformLoading: false,
                addPlatformSuccess: false,
                addPlatformFail: false,
                addPlatformErrorCode: '',
            };
        case 'Usermanagement/addServiceManager/Started':
            return {
                ...state,
                addServiceManagerLoading: true,
                addServiceManagerSuccess: false,
                addServiceManagerFail: false,
                addServiceManagerErrorCode: '',
            };
        case 'Usermanagement/addServiceManager/Succeeded':
            return {
                ...state,
                addServiceManagerLoading: false,
                addServiceManagerSuccess: true,
                addServiceManagerFail: false,
                addServiceManagerErrorCode: '',
            };
        case 'Usermanagement/addServiceManager/Failed':
            return {
                ...state,
                addServiceManagerLoading: false,
                addServiceManagerSuccess: false,
                addServiceManagerFail: true,
                addServiceManagerErrorCode: action.payload.errorCode,
            };

        case 'Usermanagement/addServiceManager/Unmounted':
            return {
                ...state,
                addServiceManagerLoading: false,
                addServiceManagerSuccess: false,
                addServiceManagerFail: false,
                addServiceManagerErrorCode: '',
            };
        case 'Usermanagement/serviceManagerList/Started':
            return {
                ...state,
                serviceManagerListLoading: true,
                serviceManagerListSuccess: false,
                serviceManagerListFail: false,
                serviceManagerListErrorCode: '',
            };
        case 'Usermanagement/serviceManagerList/Succeeded':
            return {
                ...state,
                serviceManagerListLoading: false,
                serviceManagerListSuccess: true,
                serviceManagerListFail: false,
                serviceManagerListErrorCode: '',
                serviceManagerList: action.payload.ServiceManagerList,
            };
        case 'Usermanagement/serviceManagerList/Failed':
            return {
                ...state,
                serviceManagerListLoading: false,
                serviceManagerListSuccess: false,
                serviceManagerListFail: true,
                serviceManagerListErrorCode: action.payload.errorCode,
            };

        case 'Usermanagement/serviceManagerList/Unmounted':
            return {
                ...state,
                serviceManagerListLoading: false,
                serviceManagerListSuccess: false,
                serviceManagerListFail: false,
                serviceManagerListErrorCode: '',
            };

        case 'Usermanagement/addServicePartnerCustomer/Started':
            return {
                ...state,
                addServicePartnerCustomerLoading: true,
                addServicePartnerCustomerSuccess: false,
                addServicePartnerCustomerFail: false,
                addServicePartnerCustomerErrorCode: '',
            };
        case 'Usermanagement/addServicePartnerCustomer/Succeeded':
            return {
                ...state,
                addServicePartnerCustomerLoading: false,
                addServicePartnerCustomerSuccess: true,
                addServicePartnerCustomerFail: false,
                addServicePartnerCustomerErrorCode: '',
            };
        case 'Usermanagement/addServicePartnerCustomer/Failed':
            return {
                ...state,
                addServicePartnerCustomerLoading: false,
                addServicePartnerCustomerSuccess: false,
                addServicePartnerCustomerFail: true,
                addServicePartnerCustomerErrorCode: action.payload.errorCode,
            };

        case 'Usermanagement/addServicePartnerCustomer/Unmounted':
            return {
                ...state,
                addServicePartnerCustomerLoading: false,
                addServicePartnerCustomerSuccess: false,
                addServicePartnerCustomerFail: false,
                addServicePartnerCustomerErrorCode: '',
            };

        case 'Usermanagement/servicePartnerCustomersList/Started':
            return {
                ...state,
                servicePartnerCustomersListLoading: true,
                servicePartnerCustomersListSuccess: false,
                servicePartnerCustomersListFail: false,
                servicePartnerCustomersListErrorCode: '',
            };
        case 'Usermanagement/servicePartnerCustomersList/Succeeded':
            return {
                ...state,
                servicePartnerCustomersListLoading: false,
                servicePartnerCustomersListSuccess: true,
                servicePartnerCustomersListFail: false,
                servicePartnerCustomersListErrorCode: '',
                servicePartnerCustomerList: action.payload.servicePartnerCustomerList,
            };
        case 'Usermanagement/servicePartnerCustomersList/Failed':
            return {
                ...state,
                servicePartnerCustomersListLoading: false,
                servicePartnerCustomersListSuccess: false,
                servicePartnerCustomersListFail: true,
                servicePartnerCustomersListErrorCode: action.payload.errorCode,
            };

        case 'Usermanagement/servicePartnerCustomersList/Unmounted':
            return {
                ...state,
                servicePartnerCustomersListLoading: false,
                servicePartnerCustomersListSuccess: false,
                servicePartnerCustomersListFail: false,
                servicePartnerCustomersListErrorCode: '',
            };
        case 'Usermanagement/servicePartnerCustomersDetailList/Started':
            return {
                ...state,
                servicePartnerCustomersDetailListLoading: true,
                servicePartnerCustomersDetailListSuccess: false,
                servicePartnerCustomersDetailListFail: false,
                servicePartnerCustomersDetailListErrorCode: '',
            };
        case 'Usermanagement/servicePartnerCustomersDetailList/Succeeded':
            return {
                ...state,
                servicePartnerCustomersDetailListLoading: false,
                servicePartnerCustomersDetailListSuccess: true,
                servicePartnerCustomersDetailListFail: false,
                servicePartnerCustomersDetailListErrorCode: '',
                servicePartnerCustomerDetailList: action.payload.servicePartnerCustomerDetailList,
            };
        case 'Usermanagement/servicePartnerCustomersDetailList/Failed':
            return {
                ...state,
                servicePartnerCustomersDetailListLoading: false,
                servicePartnerCustomersDetailListSuccess: false,
                servicePartnerCustomersDetailListFail: true,
                servicePartnerCustomersDetailListErrorCode: action.payload.errorCode,
            };

        case 'Usermanagement/servicePartnerCustomersDetailList/Unmounted':
            return {
                ...state,
                servicePartnerCustomersDetailListLoading: false,
                servicePartnerCustomersDetailListSuccess: false,
                servicePartnerCustomersDetailListFail: false,
                servicePartnerCustomersDetailListErrorCode: '',
            };

        case 'Usermanagement/platformList/Started':
            return {
                ...state,
                platformListLoading: true,
                platformListSuccess: false,
                platformListFail: false,
                platformListErrorCode: '',
            };
        case 'Usermanagement/platformList/Succeeded':
            return {
                ...state,
                platformListLoading: false,
                platformListSuccess: true,
                platformListFail: false,
                platformListErrorCode: '',
                platformList: action.payload.platformList,
            };
        case 'Usermanagement/platformList/Failed':
            return {
                ...state,
                platformListLoading: false,
                platformListSuccess: false,
                platformListFail: true,
                platformListErrorCode: action.payload.errorCode,
            };
        case 'Usermanagement/serviceManagerCustomersDetailList/Started':
            return {
                ...state,
                serviceManagerCustomersDetailListLoading: true,
                serviceManagerCustomersDetailListSuccess: false,
                serviceManagerCustomersDetailListFail: false,
                serviceManagerCustomersDetailListErrorCode: '',
            };
        case 'Usermanagement/serviceManagerCustomersDetailList/Succeeded':
            return {
                ...state,
                serviceManagerCustomersDetailListLoading: false,
                serviceManagerCustomersDetailListSuccess: true,
                serviceManagerCustomersDetailListFail: false,
                serviceManagerCustomersDetailListErrorCode: '',
                serviceManagerCustomerDetailList: action.payload.serviceManagerCustomerDetailList,
            };
        case 'Usermanagement/serviceManagerCustomersDetailList/Failed':
            return {
                ...state,
                serviceManagerCustomersDetailListLoading: false,
                serviceManagerCustomersDetailListSuccess: false,
                serviceManagerCustomersDetailListFail: true,
                serviceManagerCustomersDetailListErrorCode: action.payload.errorCode,
            };

        case 'Usermanagement/serviceManagerCustomersDetailList/Unmounted':
            return {
                ...state,
                serviceManagerCustomersDetailListLoading: false,
                serviceManagerCustomersDetailListSuccess: false,
                serviceManagerCustomersDetailListFail: false,
                serviceManagerCustomersDetailListErrorCode: '',
            };
        case 'Usermanagement/servicePartnerDetail/Started':
            return {
                ...state,
                servicePartnerDetailLoading: true,
                servicePartnerDetailSuccess: false,
                servicePartnerDetailFail: false,
                servicePartnerDetailErrorCode: '',
            };
        case 'Usermanagement/servicePartnerDetail/Succeeded':
            return {
                ...state,
                servicePartnerDetailLoading: false,
                servicePartnerDetailSuccess: true,
                servicePartnerDetailFail: false,
                servicePartnerDetailErrorCode: '',
                servicePartnerDetail: action.payload.servicePartnerDetail,
            };
        case 'Usermanagement/servicePartnerDetail/Failed':
            return {
                ...state,
                servicePartnerDetailLoading: false,
                servicePartnerDetailSuccess: false,
                servicePartnerDetailFail: true,
                servicePartnerDetailErrorCode: action.payload.errorCode,
            };

        case 'Usermanagement/servicePartnerDetail/Unmounted':
            return {
                ...state,
                servicePartnerDetailLoading: false,
                servicePartnerDetailSuccess: false,
                servicePartnerDetailFail: false,
                servicePartnerDetailErrorCode: '',
                servicePartnerDetail: defaultServicePartnerDetail,
            };
        case 'Usermanagement/platformSystemInfo/Started':
            return {
                ...state,
                platformSystemInfoLoading: true,
                platformSystemInfoSuccess: false,
                platformSystemInfoFail: false,
                platformSystemInfoErrorCode: '',
            };
        case 'Usermanagement/platformSystemInfo/Succeeded':
            return {
                ...state,
                platformSystemInfoLoading: false,
                platformSystemInfoSuccess: true,
                platformSystemInfoFail: false,
                platformSystemInfoErrorCode: '',
                platformSystemInfo: action.payload.PlatformSystemInfo,
            };
        case 'Usermanagement/platformSystemInfo/Failed':
            return {
                ...state,
                platformSystemInfoLoading: false,
                platformSystemInfoSuccess: false,
                platformSystemInfoFail: true,
                platformSystemInfoErrorCode: action.payload.errorCode,
            };

        case 'Usermanagement/platformSystemInfo/Unmounted':
            return {
                ...state,
                platformSystemInfoLoading: false,
                platformSystemInfoSuccess: false,
                platformSystemInfoFail: false,
                platformSystemInfoErrorCode: '',
                platformSystemInfo: [],
            };
        case 'Usermanagement/platformDetail/Started':
            return {
                ...state,
                platformDetailLoading: true,
                platformDetailSuccess: false,
                platformDetailFail: false,
                platformDetailErrorCode: '',
            };
        case 'Usermanagement/platformDetail/Succeeded':
            return {
                ...state,
                platformDetailLoading: false,
                platformDetailSuccess: true,
                platformDetailFail: false,
                platformDetailErrorCode: '',
                platformDetail: action.payload.platformDetail,
            };
        case 'Usermanagement/platformDetail/Failed':
            return {
                ...state,
                platformDetailLoading: false,
                platformDetailSuccess: false,
                platformDetailFail: true,
                platformDetailErrorCode: action.payload.errorCode,
            };

        case 'Usermanagement/platformDetail/Unmounted':
            return {
                ...state,
                platformDetailLoading: false,
                platformDetailSuccess: false,
                platformDetailFail: false,
                platformDetailErrorCode: '',
                platformDetail: defaultPlatformDetail,
            };
        case 'Usermanagement/removeServiceUser/Started':
            return {
                ...state,
                removeServiceUserLoading: true,
                removeServiceUserSuccess: false,
                removeServiceUserErrorCode: '',
                removeServiceUserFail: false
            };

        case 'Usermanagement/removeServiceUser/Succeeded':
            return {
                ...state,
                removeServiceUserLoading: false,
                removeServiceUserSuccess: true,
                removeServiceUserErrorCode: '',
                removeServiceUserFail: false
            };

        case 'Usermanagement/removeServiceUser/Failed':
            return {
                ...state,
                removeServiceUserLoading: false,
                removeServiceUserSuccess: false,
                removeServiceUserFail: true,
                removeServiceUserErrorCode: action.payload.errorCode,
            };

        case 'Usermanagement/removeServiceUser/Unmounted':
            return {
                ...state,
                removeServiceUserLoading: false,
                removeServiceUserSuccess: false,
                removeServiceUserErrorCode: '',
                removeServiceUserFail: false
            };
        case 'Usermanagement/maintenanceDetail/Started':
            return {
                ...state,
                maintenanceDetailLoading: true,
                maintenanceDetailSuccess: false,
                maintenanceDetailFail: false,
                maintenanceDetailErrorCode: '',
            };
        case 'Usermanagement/maintenanceDetail/Succeeded':
            return {
                ...state,
                maintenanceDetailLoading: false,
                maintenanceDetailSuccess: true,
                maintenanceDetailFail: false,
                maintenanceDetailErrorCode: '',
                maintenanceDetail: action.payload.maintenanceDetail,
            };
        case 'Usermanagement/maintenanceDetail/Failed':
            return {
                ...state,
                maintenanceDetailLoading: false,
                maintenanceDetailSuccess: false,
                maintenanceDetailFail: true,
                maintenanceDetailErrorCode: action.payload.errorCode,
            };

        case 'Usermanagement/maintenanceDetail/Unmounted':
            return {
                ...state,
                maintenanceDetailLoading: false,
                maintenanceDetailSuccess: false,
                maintenanceDetailFail: false,
                maintenanceDetailErrorCode: '',
                maintenanceDetail: defaultMaintenanceListData,
            };

            case 'Usermanagement/createMaintainance/Started':
            return {
                ...state,
                createMaintainanceLoading: true,
                createMaintainanceSuccess: false,
                createMaintainanceFail: false,
                createMaintainanceErrorCode: '',
            };
        case 'Usermanagement/createMaintainance/Succeeded':
            return {
                ...state,
                createMaintainanceLoading: false,
                createMaintainanceSuccess: true,
                createMaintainanceFail: false,
                createMaintainanceErrorCode: '',
            };
        case 'Usermanagement/createMaintainance/Failed':
            return {
                ...state,
                createMaintainanceLoading: false,
                createMaintainanceSuccess: false,
                createMaintainanceFail: true,
                createMaintainanceErrorCode: action.payload.errorCode,
            };

        case 'Usermanagement/createMaintainance/Unmounted':
            return {
                ...state,
                createMaintainanceLoading: false,
                createMaintainanceSuccess: false,
                createMaintainanceFail: false,
                createMaintainanceErrorCode: '',
            };

        case 'Usermanagement/addNotes/Started':
            return {
                ...state,
                addNotesLoading: true,
                addNotesSuccess: false,
                addNotesFail: false,
                addNotesErrorCode: '',
            };
        case 'Usermanagement/addNotes/Succeeded':
            return {
                ...state,
                addNotesLoading: false,
                addNotesSuccess: true,
                addNotesFail: false,
                addNotesErrorCode: '',
            };
        case 'Usermanagement/addNotes/Failed':
            return {
                ...state,
                addNotesLoading: false,
                addNotesSuccess: false,
                addNotesFail: true,
                addNotesErrorCode: action.payload.errorCode,
            };

        case 'Usermanagement/addNotes/Unmounted':
            return {
                ...state,
                addNotesLoading: false,
                addNotesSuccess: false,
                addNotesFail: false,
                addNotesErrorCode: '',
            };

            case 'Usermanagement/updateMaintenance/Started':
                return {
                    ...state,
                    updateMaintenanceLoading: true,
                    updateMaintenanceSuccess: false,
                    updateMaintenanceFail: false,
                    updateMaintenanceErrorCode: '',
                };
            case 'Usermanagement/updateMaintenance/Succeeded':
                return {
                    ...state,
                    updateMaintenanceLoading: false,
                    updateMaintenanceSuccess: true,
                    updateMaintenanceFail: false,
                    updateMaintenanceErrorCode: '',
                };
            case 'Usermanagement/updateMaintenance/Failed':
                return {
                    ...state,
                    updateMaintenanceLoading: false,
                    updateMaintenanceSuccess: false,
                    updateMaintenanceFail: true,
                    updateMaintenanceErrorCode: action.payload.errorCode,
                };
    
            case 'Usermanagement/updateMaintenance/Unmounted':
                return {
                    ...state,
                    updateMaintenanceLoading: false,
                    updateMaintenanceSuccess: false,
                    updateMaintenanceFail: false,
                    updateMaintenanceErrorCode: '',
                };
            case 'Usermanagement/maintenanceLogs/Started':
                return {
                    ...state,
                    maintenanceLogsLoading: true,
                    maintenanceLogsSuccess: false,
                    maintenanceLogsFail: false,
                    maintenanceLogsErrorCode: '',
                };
            case 'Usermanagement/maintenanceLogs/Succeeded':
                return {
                    ...state,
                    maintenanceLogsLoading: false,
                    maintenanceLogsSuccess: true,
                    maintenanceLogsFail: false,
                    maintenanceLogsErrorCode: '',
                    maintenanceLogs: action.payload.maintenanceLogs,
                };
            case 'Usermanagement/maintenanceLogs/Failed':
                return {
                    ...state,
                    maintenanceLogsLoading: false,
                    maintenanceLogsSuccess: false,
                    maintenanceLogsFail: true,
                    maintenanceLogsErrorCode: action.payload.errorCode,
                };
    
            case 'Usermanagement/maintenanceLogs/Unmounted':
                return {
                    ...state,
                    maintenanceLogsLoading: false,
                    maintenanceLogsSuccess: false,
                    maintenanceLogsFail: false,
                    maintenanceLogsErrorCode: '',
                    maintenanceLogs: defaultMaintenanceLogsData,
                };

        default:
            return state;
    }
};
