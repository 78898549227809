import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { GET_MAINTENANCE_LOGS } from './url-constants';

type MaintenanceLogsEndpointSpecification = ApiEndpointSpecification;
export const MaintenanceLogsEndpointSpecifications: MaintenanceLogsEndpointSpecification = {
    url: GET_MAINTENANCE_LOGS,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'MaintenanceLogsRequestParams',
    requestBodySchemaName: 'MaintenanceLogsRequestBody',
    okResponseSchemaName: 'MaintenanceLogsRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeMaintenanceLogs = Helpers.makeApiRequestFn<
    Types.GetMaintenanceLogsRequestParams,
    Types.GetMaintenanceLogsRequestBody,
    Types.GetMaintenanceLogsOkResponse
>(MaintenanceLogsEndpointSpecifications);
