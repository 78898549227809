import { SystemLogData, SystemLogListData } from '../types/system-log';

const previousLogData: SystemLogData = {
    id: '',
    name: 'Event',
    description: '',
    title: '',
    datetimeutc: '0001-01-01T00:00:00',
    elstatus: 1,
    projectId: '',
    projectName: '',
    buildingName: '',
    buildingId: '',
    systemName: '',
    systemId: '',
    sublocation: '',
    isactive: false,
    acknowledgedTime: '',
    isAcknowledged: false,
    acknowledgedBy: '',
    logSourceId: '',
    partitionKey: '',
    logSourceName: '',
    systemPartNumber: '',
    systemType: 0,
    logSourcePartNumber: '',
    logSourceSerialNumber: '',
    logSourceDeviceType: 0,
    logStartDate: '0001-01-01T00:00:00',
    logEndDate: '0001-01-01T00:00:00',
    comment: '',
    subDevice: null,
    gatewayName: '',
    gatewayId: ''
}


const getLogData: SystemLogListData = {
    detail: [],
    totalRecordCount: 0,
    totalPage: 0
}


export type LogbookState = {
    latestLogListLoading: boolean;
    latestLogListSuccess: boolean;
    latestLogListFail: boolean;
    latestLogList: SystemLogListData;
    latestLogErrorCode: string;

    earlierLogListLoading: boolean,
    earlierLogListSuccess: boolean,
    earlierLogListFail: boolean,
    earlierLogList: SystemLogListData;
    earlierLogListErrorCode: string,

    previousLogLoading: boolean;
    previousLogSuccess: boolean;
    previousLogFail: boolean;
    previousLogData: SystemLogData;
    previousLogErrorCode: string;

    upsertLogCommentsLoading: boolean;
    upsertLogCommentsSuccess: boolean;
    upsertLogCommentsFail: boolean;
    upsertLogCommentsErrorMessage: string;

    acknowledgeLogLoading: boolean;
    acknowledgeLogSuccess: boolean;
    acknowledgeLogFail: boolean;
    acknowledgeLogErrorMessage: string;

    downloadLogbookReportLoading: boolean;
    downloadLogbookReportSuccess: boolean;
    downloadLogbookReportData: string;
    downloadLogbookReportFail: boolean;
    downloadLogbookReportErrorMessage: string;
};

export const initialLogbookState: LogbookState = {
    latestLogListLoading: false,
    latestLogListSuccess: false,
    latestLogListFail: false,
    latestLogList: getLogData,
    latestLogErrorCode: '',

    earlierLogListLoading: false,
    earlierLogListSuccess: false,
    earlierLogListFail: false,
    earlierLogList: getLogData,
    earlierLogListErrorCode: '',

    previousLogLoading: false,
    previousLogSuccess: false,
    previousLogFail: false,
    previousLogData: previousLogData,
    previousLogErrorCode: '',

    upsertLogCommentsLoading: false,
    upsertLogCommentsSuccess: false,
    upsertLogCommentsFail: false,
    upsertLogCommentsErrorMessage: '',

    acknowledgeLogLoading: false,
    acknowledgeLogSuccess: false,
    acknowledgeLogFail: false,
    acknowledgeLogErrorMessage: '',

    downloadLogbookReportLoading: false,
    downloadLogbookReportSuccess: false,
    downloadLogbookReportData: '',
    downloadLogbookReportFail: false,
    downloadLogbookReportErrorMessage: '',
};
