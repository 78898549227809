// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import * as PXBColors from '@brightlayer-ui/colors';
import { ItemList } from '../../types/logbook-type';
import { ALPHA_NUMBERIC, CUSTOMER_SLA_LIMIT } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Thunks } from '../../actions';
import CloseIcon from '@mui/icons-material/Close';
import { Delete, DeleteForeverOutlined, DeleteOutlined, Done } from '@mui/icons-material';
import { CustomerThunks } from '../../actions/thunks/customer-thunks';
import { formatDate } from '../../lib/date-time-formatter';
import { useAuthHelper } from '../../components/AuthContextProvider';
import { CustomerActions } from '../../actions/actions/customer-actions';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, IconButton, Portal, Snackbar, TextField, Typography } from '@mui/material';
import { SLADataList } from '../../types/sla';
import { AssetActions } from '../../actions/actions/asset-actions';
import moment from 'moment';
import { useToasts } from '../../data/DataProviders/ToastProvider';
import { validSlaNumber } from '../../lib/form-validator';

type Props = {
    closeClick: Function;
    open: boolean;
    slaId: string;
    close: Function;
};
export const EditAssetSLADetails: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const [slaExpiryDate, setSlaExpiryDate] = React.useState<Date | null>();
    const asset = useSelector(Selectors.Dashboard.asset);
    const [luminarySelection, setluminarySelection] = React.useState(true);
    const [slaNumber, setSlaNumber] = useState('');
    const [customerId, setcustomerId] = React.useState('');
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const customer = useSelector(Selectors.Customer.customer);
    const [slaExpiryDateFlag, setSlaExpiryDateFlag] = React.useState<boolean>(false);
    const [alertErrorMessage, setAlertErrorMessage] = useState<string>('');
    const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
    const [luminarySelectionFlag, setluminarySelectionFlag] = useState(false);
    const [isLuminaryChecked , setLuminaryChecked] = useState(false);
    const { addToast } = useToasts();
    const slaDetailsDefault: SLADataList = [{
        sLANumber: '',
        companyName: '',
        customerNumber: '0',
        customerCity: '',
        projectName: '',
        projectId: '0',
        buildingName: '',
        type: 0,
        buildingId: '0',
        systemName: '',
        systemId: '',
        systemStatus: 0,
        luminariesNotPartOfSLA: 'false',
        expiryDate: '',
        serviceDueDate: '',
        serviceDueStatus: '',
        serviceType: 0,
        isFavorite: '',
        ticketCreatedDate: '',
        technicianName: '',
        technicianId: ''
    }]

    const [slaList, setSlaList] = React.useState<SLADataList>(slaDetailsDefault);
    const [slaDetail, setSlaDetailsState] = React.useState(slaList[0]);
    const dispatch = useDispatch();
    const authHelper = useAuthHelper();
    const previousDate = new Date(slaDetail.expiryDate)
    const newDate = new Date()

    useEffect(() => {
        if (!asset.slaListLoading && asset.slaListSuccess) {
            if (asset.slaList.detail.length > 0) {
                setSlaList(asset.slaList.detail);
                setSlaDetailsState(asset.slaList.detail[0]);

            } else {
                setSlaList(slaDetailsDefault);
                setSlaDetailsState(slaDetailsDefault[0]);

            }
        } else if (!asset.slaListLoading && asset.slaListFail) {
            addToast(t('TOAST_MSG.TOAST_ERROR_MESSAGE'), 'error');
        }

        if (props.slaId !== '') {
            const slaDetails = asset?.slaList?.detail?.filter((slaDeatail) => slaDeatail.sLANumber === props.slaId)
            if (slaDetails.length > 0) {
                setSlaDetailsState(slaDetails[0]);

            } else {
                setSlaDetailsState(slaDetailsDefault[0]);

            }
            if (slaDetails[0].systemId)
                dispatch(Thunks.Asset.getDeviceCustomerData(slaDetails[0].systemId));
        }
        AssetActions.deviceCustomerDataUnmounted();
    }, [asset.slaListLoading, asset.slaListSuccess, asset.slaListFail, props.slaId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setcustomerId(slaDetail.companyName)
        setSlaNumber(slaDetail.sLANumber)
        setSlaExpiryDate(new Date(slaDetail.expiryDate))
        setluminarySelection(slaDetail.luminariesNotPartOfSLA ? false : true);
    }, [setSlaDetailsState, asset.deviceCustomerDataSuccess, asset.deviceCustomerDataFail, asset.deviceCustomerDataLoading]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!customer.linkCustomerLoading && customer.linkCustomerSuccess) {
            onCancel();
            props.close({});
        }
        else if (!customer.linkCustomerLoading && customer.linkCustomerFail) {
            handleLinkCustomerResponse();
            setDisplayErrorMessage(true);
            dispatch(CustomerActions.linkCustomerUnmounted());
        }
    }, [customer.linkCustomerLoading, customer.linkCustomerSuccess, customer.linkCustomerFail]); // eslint-disable-line react-hooks/exhaustive-deps

    function closeSelectOrgDialog(): void {
        props.closeClick({});
    }

    const onCancel = () => {
        closeSelectOrgDialog();
    }

    const onDelete = () => {
        setOpenDeleteDialog(false);
    }

    const handleCheck = (event: React.ChangeEvent<HTMLElement>,
        checked: boolean) => {
        if (checked) {
            setluminarySelection(true);
        }
        else {
            setluminarySelection(false);
        }
        checked.toString() === slaDetail.luminariesNotPartOfSLA.toString() ? setluminarySelectionFlag(true) : setluminarySelectionFlag(false)
    }

    const saveData = (): void => {
        if (customerId && slaNumber && slaExpiryDate) {
            dispatch(CustomerThunks.linkCustomer(slaDetail.customerNumber, slaDetail.systemId, slaNumber, formatDate(slaExpiryDate?.toString()), luminarySelection ? false : true, authHelper));
        }
        else {
            setAlertErrorMessage(t('FORMS.INVALID_DETAILS'));
            setDisplayErrorMessage(true);

        }
    }

    const onSlaNumberChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || ALPHA_NUMBERIC.test(e.target.value)) {
            setSlaNumber(e.target.value);
        }
    };

    const handleLinkCustomerResponse = (): void => {
        if (!customer.linkCustomerLoading && customer.linkCustomerFail) {
            if (customer.linkCustomerErrorCode === '9162')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_LINK_CUSTOMER_ERROR_INVALID_SLA'));
            else if (customer.linkCustomerErrorCode === '9169')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_LINK_CUSTOMER_ERROR_INVALID_EXPIRY_DATE'));
            else if (customer.linkCustomerErrorCode === '9001')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_LINK_CUSTOMER_ERROR_INVAILD_TOKEN'));
            else if (customer.linkCustomerErrorCode === '9004')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_LINK_CUSTOMER_SYSTEM_DETAIL_NOT_AVAILABLE'));
            else if (customer.linkCustomerErrorCode === '9050')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_LINK_CUSTOMER_INVALID_SYSTEM_ID'));
            else if (customer.linkCustomerErrorCode === '9172')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_LINK_CUSTOMER_INVALID_CUSTOMER_NUMBER'));
            else if (customer.linkCustomerErrorCode === '9182')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_LINK_CUSTOMER_CUSTOMER_DETAIL_NOT_AVAILABLE'));
            else if (customer.linkCustomerErrorCode === '9171')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_LINK_CUSTOMER_SLA_ALREADY_AVAILABLE'));
            else if (customer.linkCustomerErrorCode === '9203')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_LINK_CUSTOMER_9203'));
            else if (customer.linkCustomerErrorCode === '9204')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_LINK_CUSTOMER_9204'));
            else
                setAlertErrorMessage(t('REGISTRATION.STEPS_ERROR_MESSAGE.GENERIC_ERROR_MESSAGE'));
        }

    };

    return (
        <>
            <Dialog open={props.open}  >
                <DialogTitle data-testid='addslaformId' sx={{ m: 0, p: 2.5 }} style={{ paddingLeft: 24, paddingTop: 24, paddingBottom: 24, height: 75 ,fontWeight: 600}} id="addsla-dialog-title">
                    {t('CUSTOMER_DETAILS.EDIT_SLA_DETAILS')}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onCancel}
                    sx={{
                        position: 'absolute',
                        right: 20,
                        paddingLeft: 3,
                        paddingRight: 3,
                        width: 24,
                        height: 24,

                        color: (theme) => theme.palette.grey[500],
                    }}
                    style={{ height: 75 }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent data-testid='checkId' style={{ width: 450, paddingTop: 24 }}>
                    <>
                        <div >
                            <TextField
                                disabled={true}
                                required
                                variant={'filled'}
                                fullWidth style={{ backgroundColor: PXBColors.white[500] }}
                                label={t('USERS.CUSTOMER')}
                                data-testid="addsla-select"
                                value={customerId}
                            >
                            </TextField>
                        </div>
                        <div data-testid='slaCustomerNamehelperTextId'
                                style={{ color: PXBColors.gray[500] }}>
                                <Typography data-testid='customerNamecharLimitId' style={{ width: 'auto', marginLeft: 15, fontSize: 12, color: PXBColors.gray[500], marginBottom: 24 }}>
                                {t('CUSTOMER_DETAILS.CUSTOMER_NAME_LABEL')}
                                </Typography>
                            </div>
                        <div data-testid='customer-detail-right-container' >
                            <TextField
                                style={{ fontSize: 16, color: PXBColors.black[500] }}
                                id="slaCustomerNumber"
                                data-testid='slaCustomerNumberLabelId'
                                variant={'filled'}
                                disabled = { previousDate.getTime() <= newDate.getTime() ? false : true}
                                label={t('CUSTOMER_DETAILS.SLA_NUMBER')}
                                error={slaNumber.length > 0 && !validSlaNumber(slaNumber)}
                                helperText={slaNumber.length > 0 && !validSlaNumber(slaNumber) ? (t('FORMS.INVALID_SLA_NUMBER')) : ''}
                                fullWidth
                                required
                                value={slaNumber}
                                onChange={(e): void => onSlaNumberChange(e)}
                                inputProps={{ 'data-testid': 'slaCustomerNumberLabelId2', maxLength: CUSTOMER_SLA_LIMIT }}
                            />
                            <div data-testid='slaCustomerNumberhelperTextId'
                                style={{ color: PXBColors.gray[500] }}>
                                <Typography data-testid='customerSLAcharLimitId' style={{ width: 'auto', marginLeft: 15, fontSize: 12, color: PXBColors.gray[500], marginBottom: 24 }}>
                                    {slaNumber.length}/{CUSTOMER_SLA_LIMIT}
                                </Typography>
                            </div>
                        </div>
                        <div data-testid='calendarDivId' style={{ display: 'flex', width: '100%', flexDirection: 'column', marginBottom: 24 }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}  >
                                <DatePicker
                                    minDate={new Date()}
                                    value={slaExpiryDate}
                                    onChange={(newValue) => {
                                        setSlaExpiryDate(newValue)
                                        const dateIsValid = moment(newValue, "YYYY-MM-DD", true).isValid() && moment().diff(moment(newValue, "DD/MM/YYYY")) < 0 && formatDate(newValue?.toString()) != formatDate(slaDetail.expiryDate);
                                        dateIsValid ? setSlaExpiryDateFlag(true) : setSlaExpiryDateFlag(false);
                                        setLuminaryChecked(true);
                                    }
                                    }
                                    slotProps={{
                                        textField: {
                                            required: true,
                                            helperText: t('CUSTOMER_DETAILS.CALENDAR_HELPER_TEXT'),
                                            variant: 'filled',
                                            label: t('CUSTOMER_DETAILS.SLA_EXPIRATION_DATE')

                                        },
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                        <div>
                            <FormControlLabel style={{ marginLeft: 5, marginBottom: 5, fontWeight: 600 }} control={<Checkbox checked={luminarySelection} onChange={handleCheck} />} label={<Typography style={{ fontWeight: 600 }} data-testid="checkbox-labelId">{t('CUSTOMER_DETAILS.INCLUDE_LUM_MONITORING')}</Typography>} />

                        </div>
                    </>
                </DialogContent>
                <Divider style={{ color: 'grey' }} ></Divider>
                <DialogActions style={{ width: 450, height: 75, justifyContent: 'space-between', paddingLeft: 10, paddingRight: 10 }}>
                    <Button style={{ textTransform: 'none', color: PXBColors.red[500], borderColor: PXBColors.red[500] }} id="btnCancel" data-testid="btndelete" variant="outlined" color="primary" onClick={(): void => onDelete()}>
                        <DeleteOutlined style={{  marginRight: 5 }} />   {t('ACTIONS.DELETE')}
                    </Button>
                    <Button disabled={(!slaExpiryDateFlag && !(luminarySelection.toString() === slaDetail.luminariesNotPartOfSLA.toString()) && !slaNumber)} style={{
                        backgroundColor: ( isLuminaryChecked ?  slaExpiryDateFlag && slaNumber : (slaExpiryDateFlag || luminarySelectionFlag) )  ? PXBColors.blue[500] : PXBColors.blue[50],
                        color: (isLuminaryChecked ?  slaExpiryDateFlag && slaNumber  : (slaExpiryDateFlag || luminarySelectionFlag))  ? PXBColors.white[50] : PXBColors.blue[200],
                        opacity: (isLuminaryChecked ?  slaExpiryDateFlag && slaNumber  : (slaExpiryDateFlag || luminarySelectionFlag))  ? 1 : 0.5,
                        textTransform: 'none',
                        marginRight: 10
                    }} id="btnCancel" data-testid="btnSave" variant="contained" onClick={(): void => saveData()}>
                        <Done style={{ marginRight: 5 }} />  {t('ACTIONS.SAVE')}

                    </Button>
                </DialogActions>
                {openDeleteDialog && <Portal>
                    <Dialog open={true} style={{ position: 'absolute' }}>

                        <DialogTitle data-testid='addslaformId' sx={{ m: 0, p: 2.5 }} style={{ paddingLeft: 24, paddingTop: 24, paddingBottom: 24, height: 75 }} id="addsla-dialog-title">
                            {t('CUSTOMER_DETAILS.DELETE_SLA')}
                        </DialogTitle>

                        <DialogActions style={{ paddingLeft: 10, paddingRight: 10 }}>
                            <Button style={{ textTransform: 'none', color: PXBColors.black[500], borderColor: PXBColors.black[500] }} id="btnCancel" data-testid="btnCancel1" variant="outlined" color="primary" onClick={(): void => onDelete()}>
                                {t('CUSTOMER_DETAILS.LUMINARY_MONITORING_YES')}
                            </Button>
                            <Button style={{ textTransform: 'none', color: PXBColors.black[500], borderColor: PXBColors.black[500], marginRight: 10 }} id="btnCancel" data-testid="btnCancel1" variant="outlined" color="primary" onClick={(): void => setOpenDeleteDialog(false)}>
                                {t('CUSTOMER_DETAILS.LUMINARY_MONITORING_NO')}
                            </Button>
                        </DialogActions>

                    </Dialog>
                </Portal>}
                {
                 displayErrorMessage &&
                 <Portal>
                <Snackbar
                    open={true}
                    onClose={() => setDisplayErrorMessage(false)}
                    autoHideDuration={5000}
                    title={''}
                    message={alertErrorMessage}
                    action={
                        <Button color="inherit" size="small" onClick={() => setDisplayErrorMessage(false)}>
                            {t('ACTIONS.OKAY')}
                        </Button>
                    }
                    ContentProps={{ sx: { background: PXBColors.red[500] } }}
                    anchorOrigin={{ vertical: 'top' ,horizontal: "center" }}
                    sx={{ bottom: { xs: 90, sm: 0 } }}

                    style={{  height: '100%' ,width: 200, flexGrow: 0}}
                />
           </Portal> }
            </Dialog>
        </>
    );

};
