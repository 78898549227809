// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import * as PXBColors from '@brightlayer-ui/colors';
import { Country, CountryList } from '../../state/registration-state';
import { useDispatch, useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import { ALPHA_NUMBERIC, COMPANY_ADDRESS, COMPANY_ADDRESS_MAX_LENGTH_USER_DETAILS, COMPANY_CITY, COMPANY_CITY_MAX_LENGTH, COMPANY_NAME_MAX_LENGTH, COMPANY_POSTALCODE_MAX_LENGTH, COUNTRY_CODE_MAX_LENGTH, FIRST_NAME_MAX_LENGTH, LAST_NAME_MAX_LENGTH, ORGANIZATTION_NAME, PHONE_MAX_LENGTH, PHONE_MIN_LENGTH, PHONE_NUMBER } from '../../constants';
import { Thunks } from '../../actions';
import { getUserEmail, getUserId, useAuthHelper, useAuthState } from '../AuthContextProvider';
import { useToasts } from '../../data/DataProviders/ToastProvider';
import { GenericToolbar } from '../GenericToolbar';
import { ToolbarColor } from '../GenericToolbar/GenericToolbar';
import { validPhoneNumberLength, validPostalCode } from '../../lib/form-validator';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import { vh_26 } from '../../constants/dimentions';
import { UserDetailsData } from '../../types';
import { UserSettingsActions } from '../../actions/actions/user-settings-actions';
import { pushAppRoute } from '../../data/domain/route-manager';

const defaultUserDetail: UserDetailsData = {
    firstname: '',
    lastname: '',
    display_name: '',
    phone: '',
    email_id: '',
    job_title: null,
    department: null,
    countryCode: '',
    user_phone_number: '',
    company_name: '',
    company_phoneNumner: '',
    company_address: '',
    company_country: '',
    company_countrycode: '',
    company_complete_phone_number: '',
    company_city: '',
    company_postalcode: '',
    accountCreated: '',
    language: 0,
    timeZone: 1,
    dateFormat: 1,
    unitSystem: 1,
    appTheme: 1,
    isAutologoutEnabled: '',
    reportTypePreference: 1,
    reportFrequencyNotificationType: 1,
    productUpdateNotificationType: 1,
    alarmNotificationType: 1,
    statusUpdateNotificationType: 1,
    lastSeen: "3 days ago",
    userStatus: 0,
    roleTypeId: 0,
    platformName: ''
}


type Props = {
    location: any;
};

export const ModifyUserDetails: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const history = useHistory();
    const { t } = useLanguageLocale();
    const userData = useSelector(Selectors.User.user);
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const authHelper = useAuthHelper();
    const { addToast } = useToasts();
    const { backRoute } = props.location.state;
    const dispatch = useDispatch();
    const registration = useSelector(Selectors.Registration.registration);
    const countryListLoading = useSelector(Selectors.Registration.countryListLoading);
    const countryListSuccess = useSelector(Selectors.Registration.countryListSuccess);
    const [saveButtonState, setSaveButtonState] = useState(false);
    const [isFormEdit, setFormEdit] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [countryName, setCountryName] = useState('');
    const [companyPhoneNumber, setCompanyPhoneNumber] = useState('');
    const [userPhoneNumber, setUserPhoneNumber] = useState('');
    const [companyAddress, setCompanyAddress] = useState('');
    const [companyPostalCode, setCompanyPostalCode] = useState('');
    const [companyCity, setCompanyCity] = useState('');
    const [countryList, setCountryList] = useState<CountryList>([]);
    const [selectedCountry, setSelectedCountry] = useState<Country>({ name: '', code: '' });
    const [companyCountryCode, setCompanyCountryCode] = useState('');
    const [userCountryCode, setUserCountryCode] = useState('');
    const [appBarColor, setAppBarColor] = useState(ToolbarColor.Default);
    const [header, setHeader] = React.useState(t('DASHBOARD.EDIT_USER_DETAILS'));
    const [openQuitDialog, setOpenQuitDialog] = React.useState(false);
    const authState = useAuthState();
    const userId = getUserId(authState) || '';
    const emailId = getUserEmail(authState) || '';
    const [userDetails, setUserDetails] = useState<UserDetailsData>(defaultUserDetail);

    useEffect(() => {
        dispatch(Thunks.Registration.countryCodeRequest());
    }, [backRoute]); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (!countryListLoading && countryListSuccess) {
            setCountryList(registration.countryList);
        }
    }, [countryListLoading, countryListSuccess, userData.getUserDetailSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (userId)
            dispatch(Thunks.UserSettings.getUserData(userId, authHelper));
    }, [userId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!userData.getUserDetailLoading && userData.getUserDetailSuccess) {
            setUserDetails(userData.userDetail);
        } else if (!userData.getUserDetailLoading && userData.getUserDetailFail) {
            setUserDetails(defaultUserDetail);
        }
    }, [userData.getUserDetailLoading, userData.getUserDetailSuccess, userData.getUserDetailFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setUserDetailsData(userDetails);
        setCountryName(userDetails.company_country);
    }, [userDetails]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!userData.updateUserDetailLoading && userData.updateUserDetailSuccess) {
            onQuitClick();
            setSaveButtonState(false);
            setAppBarColor(ToolbarColor.Default);
            setFormEdit(false);
            addToast(t('TOAST_MSG.TOAST_SUCCESS_UPDATE_USER_DETAILS'), 'success');
            dispatch(UserSettingsActions.updateUserDetailUnmounted());
        }
        else
            if (!userData.updateUserDetailLoading && userData.updateUserDetailFail) {
                addToast(t('TOAST_MSG.TOAST_ERROR_UPDATE_USER_DETAILS'),'error');
            }
    }, [userData.updateUserDetailSuccess, userData.updateUserDetailFail, userData.updateUserDetailLoading]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleToolBar = (flag: boolean) => {
        setSaveButtonState(flag);
        setFormEdit(flag);
        setAppBarColor(flag ? ToolbarColor.Other : ToolbarColor.Default);
        setHeader(flag ? (t('DASHBOARD.SAVE_USER_DETAILS')) : (t('DASHBOARD.EDIT_USER_DETAILS')));

    };

    useEffect(() => {
        if (userData.getUserDetailSuccess) {
            setSaveButtonState(false);
            setAppBarColor(ToolbarColor.Default);
            if (firstName === userDetails.firstname &&
                lastName === userDetails.lastname &&
                userCountryCode === userDetails.countryCode &&
                userPhoneNumber === userDetails.phone &&
                companyName === userDetails.company_name &&
                companyAddress === userDetails.company_address &&
                companyPostalCode === userDetails.company_postalcode &&
                companyCity === userDetails.company_city &&
                countryName === userDetails.company_country &&
                companyCountryCode === userDetails.company_countrycode &&
                companyPhoneNumber === userDetails.company_phoneNumner
            ) {
                handleToolBar(false);
            }
            else {

                if (fieldsComplete([firstName, lastName, companyCountryCode, userPhoneNumber, companyName, companyAddress, companyPostalCode, companyCity, countryName, userCountryCode, companyPhoneNumber])) {
                    setSaveButtonState(true);
                    setAppBarColor(ToolbarColor.Other);
                    setFormEdit(true);
                    if (companyPhoneNumber.length > 0) {
                        if (validPhoneNumberLength(companyPhoneNumber)) {
                            handleToolBar(true);
                        }
                        else if (!validPhoneNumberLength(companyPhoneNumber)) {
                            handleToolBar(false);
                        }
                    }
                    if (userPhoneNumber.length > 0) {
                        if (validPhoneNumberLength(userPhoneNumber)) {
                            handleToolBar(true);
                        }
                        else if (!validPhoneNumberLength(userPhoneNumber)) {
                            handleToolBar(false);
                        }
                    }
                    if (companyPostalCode.length > 0) {
                        if (validPostalCode(companyPostalCode)) {
                            handleToolBar(true);
                        }
                        else if (!validPostalCode(companyPostalCode)) {
                            handleToolBar(false);
                        }
                    }
                    if (companyPhoneNumber.length > 0 && userPhoneNumber.length > 0 && companyPostalCode.length > 0) {
                        if (validPhoneNumberLength(companyPhoneNumber) && validPhoneNumberLength(userPhoneNumber) && validPostalCode(companyPostalCode)) {
                            setSaveButtonState(true);
                            setAppBarColor(ToolbarColor.Other);
                            setHeader(t('DASHBOARD.SAVE_USER_DETAILS'));
                        }
                        else {
                            setSaveButtonState(false);
                            setAppBarColor(ToolbarColor.Default);
                            setHeader(t('DASHBOARD.EDIT_USER_DETAILS'));
                        }
                    }
                }
                else {
                    handleToolBar(false);
                }
            }
        }
    }, [firstName, lastName, companyCountryCode, userPhoneNumber, companyName, companyAddress, companyPostalCode, companyCity, countryName, userCountryCode, companyPhoneNumber]) // eslint-disable-line react-hooks/exhaustive-deps

    const onCompanyAddressChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || COMPANY_ADDRESS.test(e.target.value)) {
            setCompanyAddress(e.target.value);
        }
    };

    const onCompanyPostalCodeChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || ALPHA_NUMBERIC.test(e.target.value)) {
            setCompanyPostalCode(e.target.value as string);
        }
    };

    const onCompanyCityChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || COMPANY_CITY.test(e.target.value)) {
            setCompanyCity(e.target.value as string);
        }
    };

    const onChangecompanyPhoneNumber = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || PHONE_NUMBER.test(e.target.value)) {
            setCompanyPhoneNumber(e.target.value);
        }
    };
    const onChangeuserPhoneNumber = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || PHONE_NUMBER.test(e.target.value)) {
            setUserPhoneNumber(e.target.value);
        }
    };

    const onChangeCompanyCountryCode = (event: SelectChangeEvent) => {
        setCompanyCountryCode(event.target.value as string);
    };
    const onChangeuserCountryCode = (event: SelectChangeEvent) => {
        setUserCountryCode(event.target.value as string);
    };

    const onChangeCountryName = (event: SelectChangeEvent) => {
        const countryNameData = event.target.value as string
        setCountryName(countryNameData);
        // eslint-disable-next-line
        const countryCodeData = countryList.filter(function (countryData) {
            if (countryData.name === countryNameData)
                return countryData
        });
        setCompanyCountryCode(countryCodeData?.[0].code);
    };

    const onFirstNameChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
        if (event.target.value === '' || ORGANIZATTION_NAME.test(event.target.value as string)) {
            setFirstName(event.target.value as string);
        }
    };
    const onLastNameChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
        if (event.target.value === '' || ORGANIZATTION_NAME.test(event.target.value as string)) {
            setLastName(event.target.value as string);
        }
    };

    const onCompanyNameChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
        if (event.target.value === '' || ORGANIZATTION_NAME.test(event.target.value as string)) {
            setCompanyName(event.target.value as string);
        }
    };

    const handleQuitDialog = () => {
        setOpenQuitDialog(true);
    };
    const handleCancel = () => {
        setOpenQuitDialog(false);
    }

    const onQuitClick = () => {
        if (backRoute === 'Settings') {
            pushAppRoute(history,
                { type: 'Settings', changeId: '0' });
        }
        else {
            pushAppRoute(history, {
                type: backRoute,
            });
        }
    }


    const fieldsComplete = (fields: string[]): boolean => {
        for (let i = 0; i < fields.length; i++) {
            if (fields[i].length < 1) {
                return false;
            }
        }
        return true;
    };

    const setUserDetailsData = (userDetail: UserDetailsData) => {
        setFirstName(userDetail.firstname);
        setLastName(userDetail.lastname);
        setCompanyCountryCode(userDetail.company_countrycode ? userDetail.company_countrycode : '');
        setCompanyPhoneNumber(userDetail.company_phoneNumner ? userDetail.company_phoneNumner : '');
        setCompanyName(userDetail.company_name);
        setCompanyAddress(userDetail.company_address);
        setCompanyPostalCode(userDetail.company_postalcode);
        setCompanyCity(userDetail.company_city);
        setCountryName(userDetail.company_country);
        setUserCountryCode(userDetail.countryCode ? userDetail.countryCode : '');
        setUserPhoneNumber(userDetail.phone ? userDetail.phone : '')
    };

    const saveData = (): void => {
        if (fieldsComplete([firstName, lastName, companyCountryCode, userPhoneNumber, companyName, companyAddress, companyPostalCode, companyCity, countryName, userCountryCode, companyPhoneNumber])) {
            dispatch(Thunks.UserSettings.updateUserNotification(
                authHelper,
                addToast,
                firstName,
                lastName,
                userPhoneNumber,
                userCountryCode,
                companyName,
                companyPhoneNumber,
                companyCountryCode,
                companyAddress,
                companyCity,
                companyPostalCode,
                countryName,
            ));
        }
        else {
            addToast(t('TOAST_MSG.TOAST_ERROR_MESSAGE'),'error');
        }
    }

    const onCancel = () => {
        setUserDetailsData(userDetails);
        setSaveButtonState(false);
        setAppBarColor(ToolbarColor.Default);
    }

    return (
        <div data-testid="modifyUser-details-container">
            <GenericToolbar
                title={header}
                showBackButton={!isFormEdit}
                showCloseButton={isFormEdit}
                actionUIState={saveButtonState}
                backButtonOnClick={(): void => {
                    if (!isFormEdit) {
                        onQuitClick()
                    }
                    else {
                        handleQuitDialog()
                    }
                }}
                saveOnClick={(): void => {
                    saveData()
                }}
                cancelOnClick={(): void => {
                    onCancel()
                }}
                color={appBarColor}
            />
            <div data-testid="container-edit-userDetails" style={{ backgroundColor: PXBColors.white[50] }}>

                <div style={{ height: md ? 1250 : 1600, width: '100%', padding: '10%', paddingTop: '3%', display: md ? 'flex' : '' }}>

                    <div style={{ float: 'left', width: md ? '49%' : '', paddingRight: '5%' }}>
                        <div style={{ display: 'flex', gap: 15 }}>
                            <InfoIcon style={{ color: PXBColors.blue[500] }} />
                            <Typography variant='body1' style={{ fontWeight: 400, fontSize: 16 }}>{t('FORMS.HELPING_TEXT')}</Typography>
                        </div>
                        <TextField
                            style={{ marginTop: 24, width: '100%', fontSize: '16px', color: PXBColors.black[500] }}
                            id="userName"
                            data-testid='userNameLabelId'
                            variant={'filled'}
                            label={t('FORMS.FIRST_NAME')}
                            fullWidth
                            required
                            value={firstName}
                            onChange={(e): void => onFirstNameChange(e)}
                            inputProps={{ 'data-testid': 'userFirstNameId', maxLength: FIRST_NAME_MAX_LENGTH }}
                        />
                        <TextField
                            style={{ marginTop: 24, width: '100%', fontSize: '16px', color: PXBColors.black[500] }}
                            id="userName"
                            data-testid='userNameLabelId'
                            variant={'filled'}
                            label={t('FORMS.LAST_NAME')}
                            fullWidth
                            required
                            value={lastName}
                            onChange={(e): void => onLastNameChange(e)}
                            inputProps={{ 'data-testid': 'userLastNameId', maxLength: LAST_NAME_MAX_LENGTH }}
                        />
                        <div>
                        <FormControl variant={'filled'} style={{marginTop: 24,marginRight: '2%'}}>
                            <InputLabel data-testid='countryCodeAsterisk' id="country-name-label">{'*'}</InputLabel>
                            <Select
                                style={{ width: md ? 84 : '33%', marginRight: '2%', textAlign: 'center' }}
                                variant={'filled'}
                                labelId="userCountryCode"
                                inputProps={{ 'data-testid': 'userCountryCodeTestId', maxLength: COUNTRY_CODE_MAX_LENGTH }}
                                id="countrycodetestid"
                                defaultValue={userCountryCode}
                                value={userCountryCode}
                                onChange={(e) => onChangeuserCountryCode(e)}
                            >
                                {countryList?.map((e, code) => {
                                    return (
                                        <MenuItem id="country-code-list" key={code} value={e.code}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'left', fontSize: 14,
                                                lineHeight: 1.5, fontWeight: 400,
                                                overflow: 'hidden',
                                                boxSizing: 'border-box',
                                                whiteSpace: 'nowrap',
                                                minHeight: 48,
                                                paddingTop: 6,
                                                paddingBottom: 6,
                                                width: 'auto',
                                                paddingLeft: 16,
                                                paddingRight: 16
                                            }}>
                                            {'+' + e.code}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            </FormControl>


                            <TextField
                                required
                                data-testid='companyuserPhoneNumberTestId'
                                style={{ marginTop: 24, marginBottom: 24, width: md ? '83%' : '65%' }}
                                variant={'filled'}
                                error={userPhoneNumber.length > 0 && !validPhoneNumberLength(userPhoneNumber)}
                                helperText={userPhoneNumber.length > 0 && !validPhoneNumberLength(userPhoneNumber) ? (t('FORMS.INVALID_PHONE_NUMBER')) : ''}
                                label={t('FORMS.PHONE_NUMBER')}
                                inputProps={{ 'data-testid': 'secondary-phone-number', maxLength: PHONE_MAX_LENGTH, minLength: PHONE_MIN_LENGTH }}
                                fullWidth
                                value={userPhoneNumber}
                                onChange={(e): void => onChangeuserPhoneNumber(e)}
                            />
                        </div>


                    </div>

                    <div style={{ float: 'right', width: md ? '49%' : '', paddingTop: vh_26 }}>
                        <TextField
                            style={{ marginTop: 24, width: '100%', fontSize: '16px', color: PXBColors.black[500] }}
                            id="companyName"
                            data-testid='companyNameLabelId'
                            variant={'filled'}
                            label={t('USER_SETTINGS.COMPANY_NAME')}
                            fullWidth
                            required
                            value={companyName}
                            onChange={(e): void => onCompanyNameChange(e)}
                            inputProps={{ 'data-testid': 'companyNameLabelId', maxLength: COMPANY_NAME_MAX_LENGTH }}
                        />
                        <TextField
                            required
                            data-testid='companyAddressId'
                            style={{ marginTop: 24, float: 'right', width: '100%' }}
                            id="companyaddress"
                            variant={'filled'}
                            label={t('FORMS.USER_ADDRESS_1')}
                            fullWidth
                            inputProps={{ 'data-testid': 'company-address', maxLength: COMPANY_ADDRESS_MAX_LENGTH_USER_DETAILS }}
                            value={companyAddress}
                            onChange={(e): void => onCompanyAddressChange(e)}
                        />
                        <TextField
                            style={{ marginTop: 24, width: '35%', marginRight: '2%', textAlign: 'center' }}
                            id="companypostalcode"
                            required
                            data-testid='company-postal-code'
                            variant={'filled'}
                            label={t('FORMS.POSTAL_CODE')}
                            error={companyPostalCode.length > 0 && !validPostalCode(companyPostalCode)}
                            helperText={companyPostalCode.length > 0 && !validPostalCode(companyPostalCode) ? (t('FORMS.INVALID_POSTAL_CODE')) : ''}
                            inputProps={{ 'data-testid': 'companyPostalCode', maxLength: COMPANY_POSTALCODE_MAX_LENGTH }}
                            fullWidth
                            value={companyPostalCode}
                            onChange={(e): void => onCompanyPostalCodeChange(e)}
                        />
                        <TextField
                            required
                            data-testid='companyCityTestId'
                            style={{ marginTop: 24, width: '63%', textAlign: 'center' }}
                            id="companycity"
                            variant={'filled'}
                            label={t('FORMS.CITY')}
                            inputProps={{ 'data-testid': 'company-city', maxLength: COMPANY_CITY_MAX_LENGTH }}
                            fullWidth
                            value={companyCity}
                            onChange={(e): void => onCompanyCityChange(e)}
                        />
                        <FormControl variant={'filled'} style={{ display: 'flex', marginTop: 24 }}>
                            <InputLabel data-testid='countryNameLabelId' id="country-name-label">{t('FORMS.COUNTRY')}</InputLabel>
                            <Select
                                labelId="country-name-label"
                                id="country-name-label"
                                data-testid='select'
                                fullWidth
                                defaultValue={selectedCountry.name !== '' ? selectedCountry.name : ''}
                                value={countryName}
                                inputProps={{ 'data-testid': 'country-name' }}
                                onChange={onChangeCountryName}
                            >
                                {countryList?.map((e, code) => {
                                    return (
                                        <MenuItem id="country-name-list" key={code} value={e.name}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'left', fontSize: 14,
                                                lineHeight: 1.5, fontWeight: 400,
                                                overflow: 'hidden',
                                                boxSizing: 'border-box',
                                                whiteSpace: 'nowrap',
                                                minHeight: 48,
                                                paddingTop: 6,
                                                paddingBottom: 6,
                                                width: 'auto',
                                                paddingLeft: 16,
                                                paddingRight: 16
                                            }}>
                                            {e.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <div>
                            <FormControl variant={'filled'} style={{marginTop: 24,marginRight: '2%'}}>
                            <InputLabel data-testid='countryCodeRequiredId' id="country-name-label">{'*'}</InputLabel>
                            <Select
                                style={{ width: md ? 84 : '33%', textAlign: 'center' }}
                                variant={'filled'}
                                labelId="countrycode-label"
                                inputProps={{ 'data-testid': 'country-code', maxLength: COUNTRY_CODE_MAX_LENGTH }}
                                id="countrycode-testid"
                                defaultValue={companyCountryCode}
                                value={companyCountryCode}
                                required
                                onChange={() => onChangeCompanyCountryCode}
                            >
                                {countryList?.map((e, code) => {
                                    return (
                                        <MenuItem id="country-code-list" key={code} value={e.code}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'left', fontSize: 14,
                                                lineHeight: 1.5, fontWeight: 400,
                                                overflow: 'hidden',
                                                boxSizing: 'border-box',
                                                whiteSpace: 'nowrap',
                                                minHeight: 48,
                                                paddingTop: 6,
                                                paddingBottom: 6,
                                                width: 'auto',
                                                paddingLeft: 16,
                                                paddingRight: 16
                                            }}>
                                            {'+' + e.code}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            </FormControl>

                            <TextField
                                required
                                data-testid='companyPhoneNumberTestId'
                                style={{ marginTop: 24, width: md ? '85%' : '65%' }}
                                variant={'filled'}
                                label={t('USER_SETTINGS.COMPANY_PHONE_NUMBER')}
                                error={companyPhoneNumber.length > 0 && !validPhoneNumberLength(companyPhoneNumber)}
                                helperText={companyPhoneNumber.length > 0 && !validPhoneNumberLength(companyPhoneNumber) ? (t('FORMS.INVALID_PHONE_NUMBER')) : ''}
                                inputProps={{ 'data-testid': 'phone-number', maxLength: PHONE_MAX_LENGTH, minLength: PHONE_MIN_LENGTH }}
                                fullWidth
                                value={companyPhoneNumber}
                                onChange={(e): void => onChangecompanyPhoneNumber(e)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Dialog id="quitdialog" maxWidth="md" open={openQuitDialog} onClose={handleQuitDialog}>
                <DialogTitle id="dialog-header">{t('ORGANIZATION_MANAGE.QUIT_EDIT_USER_DETAILS')}</DialogTitle>
                <DialogContent>
                    <DialogContentText data-testid="dialog-header-text">
                        {t('ORGANIZATION_MANAGE.QUIT_MESSAGE')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        style={{
                            marginBottom: 10,
                            width: 77,
                            height: 36,
                            flexDirection: 'row',
                            marginRight: 10,
                            backgroundColor: PXBColors.white[50],
                        }}
                        id="cancelButton"
                        color="primary"
                        data-testid="cancelButton"
                        onClick={handleCancel}
                    >
                        {t('ACTIONS.CANCEL')}
                    </Button>
                    <Button
                        style={{
                            marginBottom: 10,
                            width: 64,
                            height: 36,
                            flexDirection: 'row',
                            backgroundColor: PXBColors.white[50],
                            color: PXBColors.red[500],
                        }}
                        id="saveButton"
                        variant="text"
                        data-testid="quitButton"
                        onClick={onQuitClick}
                    >
                        {t('ACTIONS.QUIT')}
                    </Button>
                </DialogActions>
            </Dialog>

        </div >
    );
};
