// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { AppBar, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, ListItemText, Tooltip, Typography } from '@mui/material';
import { GenericToolbar } from '../../components/GenericToolbar';
import { OverviewSystemLogs } from '../overview/OverviewSystemLogs';
import { Thunks } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { RecordDataState, isRecordFound, useSelectedCustomerData, useSelectedOrganizationData } from '../../hooks/data-hooks';
import { SELECTED_ORGANIZATION, ALL_ORGANIZATION_ID, ALL_ORGANIZATION_API_ID, ALL, LOGBOOK_NINTY_DAYS_COUNT, LOGBOOK_ONE_EIGHTY_DAYS_COUNT, LOGBOOK_YEAR_DAYS_COUNT, LOGBOOK_ZERO_DAYS_COUNT, LOGBOOK_DEFAULT_SELECTION_TWELVE_MONTHS, RECORD_PER_PAGE_LIMIT, DEFAULT_PAGE_ONE, TAB_ORG, SELECTED_SM_ORGANIZATION, DEFAULT_ALL_ID, TAB_CUSTOMER, SELECTED_CUSTOMER, ALL_CUSTOMER_API_ID } from '../../constants';
import { Spacer, ToolbarMenu } from '@brightlayer-ui/react-components';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { BuildingList } from '../../types/building';
import { Selectors } from '../../selectors';
import { ItemList } from '../../types/logbook-type';
import { DeviceList } from '../../types/device';
import * as PXBColors from '@brightlayer-ui/colors';
import { useHistory } from 'react-router';
import { GetApp } from '@material-ui/icons';
import { LogbookActions } from '../../actions/actions/logbook-actions';
import { getDateFromDays, getDaysDifferenceFromDates, getDaysFromDate, formatToSimpleDate, subtractDaysFromDate, addDaysToDate } from '../enum/DateTimeFormat';
import { vh_20, vh_26 } from '../../constants/dimentions';
import { LOGBOOK_SEVERITY_STATUS, LOGBOOK_TIME_RANGE_FILTER, LOGBOOK_TYPE_FILTER, USER_ROLE_TYPE, logStatusEnum } from '../enum/ENUM';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { Check } from '@mui/icons-material';
import { DOWNLOAD_TYPE } from '../enum/ENUM';
import { Icon } from '../../lib/icons';
import { getUserRoleType, useAuthState } from '../../components/AuthContextProvider';
import { useCustomerList } from '../../data/DataProviders/CustomerListProvider';
import { DashboardActions } from '../../actions/actions/dashboard-actions';
import { AssetActions } from '../../actions/actions/asset-actions';
import { useOrganizationList } from '../../data/DataProviders/OrganizationListProvider';
import { pushAppRoute } from '../../data/domain/route-manager';

type Props = {
    userId?: string;
};

export const LogBook: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const dispatch = useDispatch();
    const history = useHistory();
    const DEFAULT_ID: string = '';
    const authState = useAuthState();
    const usertype = getUserRoleType(authState) || '';
    const [buildingList, setBuildingList] = useState<ItemList>([]);
    const [systemList, setSystemList] = useState<ItemList>([]);
    const [projectId, setProjectId] = React.useState('');
    const [selectedId, setSelectedId] = React.useState(ALL_CUSTOMER_API_ID);
    const [buildingId, setBuildingId] = React.useState(DEFAULT_ID);
    const [systemId, setSystemId] = React.useState(DEFAULT_ID);
    const [enableDownload, setEnableDownload] = React.useState(false);
    const dashboard = useSelector(Selectors.Dashboard.dashboard);
    const asset = useSelector(Selectors.Dashboard.asset);
    const logbook = useSelector(Selectors.Logbook.logbook);
    const selectedCustomer = useSelectedCustomerData();
    const selectedOrganization = useSelectedOrganizationData();
    const [focusedBuilding, setFocusedBuilding] = React.useState(false);
    const [focusedSystem, setFocusedSystem] = React.useState(false);
    const [openDateDialog, setOpenDateDialog] = React.useState(false);
    const [customDateConfirm, setCustomDateConfirm] = React.useState(false);

    const [selectionLogType, setSelectionLogType] = React.useState<string[]>([]);

    const [isAllLogType, setAllLogType] = React.useState(false);
    const [isActiveLog, setActiveLog] = React.useState(false);
    const [firstCall, setFirstCall] = React.useState(true);
    const [logType, setLogType] = React.useState<string[]>([]);

    const [selectedRangeIndex, setSelectedRangeIndex] = React.useState(LOGBOOK_DEFAULT_SELECTION_TWELVE_MONTHS);
    const [previousRangeIndex, setSelectedPreviousRangeIndex] = React.useState(LOGBOOK_DEFAULT_SELECTION_TWELVE_MONTHS);

    const [selectedTimeRange, setSelectedTimeRange] = React.useState<string>('');

    const [fromDate, setFromDate] = React.useState<Date | null>(new Date(getDateFromDays(LOGBOOK_YEAR_DAYS_COUNT)));
    const [intermediateDate, setIntermediateDate] = React.useState<Date | null>(new Date(getDateFromDays(LOGBOOK_NINTY_DAYS_COUNT)));
    const [toDate, setToDate] = React.useState<Date | null>(new Date(getDateFromDays(LOGBOOK_ZERO_DAYS_COUNT)));
    const [startDate, setStartDate] = React.useState<Date | null>(new Date());
    const [endDate, setEndDate] = React.useState<Date | null>(new Date());
    const [previousStartDate, setPreviousStartDate] = React.useState<Date | null>(new Date());
    const [previousEndDate, setPreviousEndDate] = React.useState<Date | null>(new Date());

    const [isFilterApplied, setIsFilterApplied] = React.useState(false);
    const [currentPageLatest, setCurrentPageLatest] = React.useState(DEFAULT_PAGE_ONE);
    const [rowsPerPageLatest, setRowsPerPageLatest] = React.useState(RECORD_PER_PAGE_LIMIT);
    const [currentPageEarlier, setCurrentPageEarlier] = React.useState(DEFAULT_PAGE_ONE);
    const [rowsPerPageEarlier, setRowsPerPageEarlier] = React.useState(RECORD_PER_PAGE_LIMIT);

    const useCustomer = useCustomerList();
    const useOrganization = useOrganizationList();
    const [isServiceManager, setIsServiceManager] = React.useState(usertype === USER_ROLE_TYPE.SERVICE_MANAGER || usertype === USER_ROLE_TYPE.TECHNICIAN);



    const buildingListLogbook = (list: BuildingList): ItemList => {
        return list?.map((data, building) => {
            return {
                id: data.buildingId,
                name: data.name,
            };
        });
    };

    const systemListLogbook = (list: DeviceList): ItemList => {
        return list?.map((data, system) => {
            return {
                id: data.systemId,
                name: data.systemName,
            };
        });
    };

    const buildingOnChange = (event: SelectChangeEvent) => {
        setBuildingId(event.target.value);
        setFocusedBuilding(event.target.value !== '');
        setFocusedSystem(false);
    };

    const systemOnChange = (event: SelectChangeEvent) => {
        setSystemId(event.target.value);
        setFocusedSystem(event.target.value !== '');
    };

    useEffect(() => {
        if ((usertype === USER_ROLE_TYPE.SERVICE_MANAGER || usertype === USER_ROLE_TYPE.TECHNICIAN) && dashboard.dashboardTabSelection === TAB_CUSTOMER) {
            setProjectId(ALL_ORGANIZATION_API_ID);
            if (selectedCustomer.state !== RecordDataState.Found)
                updateLogbook(ALL_ORGANIZATION_API_ID, '', '')
        }
        else if ((usertype === USER_ROLE_TYPE.SERVICE_MANAGER || usertype === USER_ROLE_TYPE.TECHNICIAN) && dashboard.dashboardTabSelection === TAB_ORG) {
            setSelectedId(ALL_CUSTOMER_API_ID);
            if (selectedOrganization.state !== RecordDataState.Found) {
                updateLogbook(ALL_ORGANIZATION_API_ID, '', '');
            }
        }
        else if (selectedOrganization.state !== RecordDataState.Found) {
            updateLogbook(ALL_ORGANIZATION_API_ID, '', '')
        }
    }, [dashboard.dashboardTabSelection]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!firstCall) {
            dispatch(Thunks.Asset.getDeviceList(useOrganization.isTabClicked ? ALL_ORGANIZATION_API_ID : projectId, buildingId, DEFAULT_ID, '', '', '', '', selectedId));
            setSystemId(DEFAULT_ID);
        }
    }, [buildingId]); // eslint-disable-line react-hooks/exhaustive-deps

    //Filter api call
    useEffect(() => {
        if (!firstCall) {
            dispatch(LogbookActions.LatestLogListUnmounted());
            dispatch(LogbookActions.EarlierLogListUnmounted())
            setCurrentPageEarlier(DEFAULT_PAGE_ONE);
            setCurrentPageLatest(DEFAULT_PAGE_ONE);
            if (!firstCall)
                setIsFilterApplied(true);
            if (toDate !== null && fromDate !== null && intermediateDate !== null && formatToSimpleDate(intermediateDate) !== formatToSimpleDate(fromDate)) {
                dispatch(Thunks.Logbook.getLatestLogs('', buildingId, rowsPerPageLatest.toString(), DEFAULT_PAGE_ONE.toString(), DEFAULT_ID, systemId, addDaysToDate(intermediateDate, 1), formatToSimpleDate(toDate), logType, isActiveLog, '', isServiceManager, selectedId));
                dispatch(Thunks.Logbook.getEarlierLogs('', buildingId, rowsPerPageLatest.toString(), DEFAULT_PAGE_ONE.toString(), DEFAULT_ID, systemId, formatToSimpleDate(fromDate), formatToSimpleDate(intermediateDate), logType, isActiveLog, '', isServiceManager, selectedId));
            }
            else if (intermediateDate !== null && fromDate !== null && toDate !== null && (formatToSimpleDate(intermediateDate) === formatToSimpleDate(fromDate)) && (formatToSimpleDate(intermediateDate) !== formatToSimpleDate(toDate))) {
                dispatch(Thunks.Logbook.getLatestLogs('', buildingId, rowsPerPageLatest.toString(), DEFAULT_PAGE_ONE.toString(), DEFAULT_ID, systemId, formatToSimpleDate(fromDate), formatToSimpleDate(toDate), logType, isActiveLog, '', isServiceManager, selectedId));
                dispatch(LogbookActions.EarlierLogListUnmounted());
            }
            else if (intermediateDate !== null && fromDate !== null && toDate !== null && customDateConfirm && (formatToSimpleDate(intermediateDate) === formatToSimpleDate(fromDate)) && (formatToSimpleDate(intermediateDate) === formatToSimpleDate(toDate))) {
                dispatch(Thunks.Logbook.getLatestLogs('', buildingId, rowsPerPageLatest.toString(), DEFAULT_PAGE_ONE.toString(), DEFAULT_ID, systemId, formatToSimpleDate(fromDate), formatToSimpleDate(toDate), logType, isActiveLog, '', isServiceManager, selectedId));
                dispatch(LogbookActions.EarlierLogListUnmounted());
                setCustomDateConfirm(false);
            }
        }
    }, [buildingId, systemId, fromDate, toDate, isActiveLog, logType, intermediateDate]); // eslint-disable-line react-hooks/exhaustive-deps

    //Earlier logs pagination api call
    useEffect(() => {
        if (!firstCall) {
            dispatch(LogbookActions.EarlierLogListUnmounted());
            if (toDate !== null && fromDate !== null && intermediateDate !== null && formatToSimpleDate(intermediateDate) !== formatToSimpleDate(fromDate)) {
                dispatch(Thunks.Logbook.getEarlierLogs('', buildingId, rowsPerPageEarlier.toString(), currentPageEarlier.toString(), DEFAULT_ID, systemId, formatToSimpleDate(fromDate), formatToSimpleDate(intermediateDate), logType, isActiveLog, '', isServiceManager, selectedId));
            }
            else if (intermediateDate !== null && fromDate !== null && toDate !== null && (formatToSimpleDate(intermediateDate) === formatToSimpleDate(fromDate)) && (formatToSimpleDate(intermediateDate) !== formatToSimpleDate(toDate))) {
                dispatch(LogbookActions.EarlierLogListUnmounted());
            }
            else if (intermediateDate !== null && fromDate !== null && toDate !== null && customDateConfirm && (formatToSimpleDate(intermediateDate) === formatToSimpleDate(fromDate)) && (formatToSimpleDate(intermediateDate) === formatToSimpleDate(toDate))) {
                dispatch(LogbookActions.EarlierLogListUnmounted());
                setCustomDateConfirm(false);
            }
        }
    }, [currentPageEarlier, rowsPerPageEarlier]); // eslint-disable-line react-hooks/exhaustive-deps

    //Latest logs pagination api call
    useEffect(() => {
        if (!firstCall) {
            dispatch(LogbookActions.LatestLogListUnmounted());
            if (toDate !== null && fromDate !== null && intermediateDate !== null && formatToSimpleDate(intermediateDate) !== formatToSimpleDate(fromDate)) {
                dispatch(Thunks.Logbook.getLatestLogs('', buildingId, rowsPerPageLatest.toString(), currentPageLatest.toString(), DEFAULT_ID, systemId, addDaysToDate(intermediateDate, 1), formatToSimpleDate(toDate), logType, isActiveLog, '', isServiceManager, selectedId));
            }
            else if (intermediateDate !== null && fromDate !== null && toDate !== null && (formatToSimpleDate(intermediateDate) === formatToSimpleDate(fromDate)) && (formatToSimpleDate(intermediateDate) !== formatToSimpleDate(toDate))) {
                dispatch(Thunks.Logbook.getLatestLogs('', buildingId, rowsPerPageLatest.toString(), currentPageLatest.toString(), DEFAULT_ID, systemId, formatToSimpleDate(fromDate), formatToSimpleDate(toDate), logType, isActiveLog, '', isServiceManager, selectedId));
            }
            else if (intermediateDate !== null && fromDate !== null && toDate !== null && customDateConfirm && (formatToSimpleDate(intermediateDate) === formatToSimpleDate(fromDate)) && (formatToSimpleDate(intermediateDate) === formatToSimpleDate(toDate))) {
                dispatch(Thunks.Logbook.getLatestLogs('', buildingId, rowsPerPageLatest.toString(), currentPageLatest.toString(), DEFAULT_ID, systemId, formatToSimpleDate(fromDate), formatToSimpleDate(toDate), logType, isActiveLog, '', isServiceManager, selectedId));
                setCustomDateConfirm(false);
            }
        }
    }, [currentPageLatest, rowsPerPageLatest]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isRecordFound(selectedOrganization) && isLoadProjectSelectedOnUI(selectedOrganization.value.id)) {
            setProjectId(selectedOrganization.value.id);
            setBuildingId(DEFAULT_ID);
            setSystemId(DEFAULT_ID);
            setSelectedId(ALL_CUSTOMER_API_ID);
            updateLogbook(useOrganization.isTabClicked ? ALL_ORGANIZATION_API_ID : selectedOrganization.value.id, DEFAULT_ID, DEFAULT_ID);
        } else if (!isRecordFound(selectedOrganization) && isLoadProjectSelectedOnUI(ALL_ORGANIZATION_API_ID)) {
            const orgId = sessionStorage.getItem(SELECTED_ORGANIZATION);
            if (orgId === ALL_ORGANIZATION_ID) {
                setProjectId(ALL_ORGANIZATION_API_ID);
                setSelectedId(ALL_CUSTOMER_API_ID);
                updateLogbook(ALL_ORGANIZATION_API_ID, DEFAULT_ID, DEFAULT_ID);
                setBuildingId(DEFAULT_ID);
                setSystemId(DEFAULT_ID);
            }
        }
    }, [selectedOrganization]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if ((usertype === USER_ROLE_TYPE.SERVICE_MANAGER || usertype === USER_ROLE_TYPE.TECHNICIAN) && dashboard.dashboardTabSelection === TAB_CUSTOMER) {
            if (isRecordFound(selectedCustomer) && isLoadCustomerSelectedOnUI(selectedCustomer.value.id)) {
                setSelectedId(selectedCustomer.value.id);
                setProjectId(ALL_ORGANIZATION_API_ID);
                setBuildingId(DEFAULT_ID);
                setSystemId(DEFAULT_ID);
                updateLogbook(ALL_ORGANIZATION_API_ID, DEFAULT_ID, DEFAULT_ID, useCustomer.isTabClicked ? ALL_CUSTOMER_API_ID : selectedCustomer.value.id);
            } else if (!isRecordFound(selectedCustomer) && isLoadCustomerSelectedOnUI(ALL_ORGANIZATION_API_ID)) {
                const cusId = sessionStorage.getItem(SELECTED_CUSTOMER);
                if (cusId === ALL_ORGANIZATION_ID) {
                    setProjectId(ALL_ORGANIZATION_API_ID);
                    setSelectedId(ALL_ORGANIZATION_API_ID);
                    updateLogbook(ALL_ORGANIZATION_API_ID, DEFAULT_ID, DEFAULT_ID, ALL_CUSTOMER_API_ID);
                    setBuildingId(DEFAULT_ID);
                    setSystemId(DEFAULT_ID);
                }
            }
        }
    }, [selectedCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

    //TODO- to bee removed later
    // useEffect(() => {
    //     if ((usertype === USER_ROLE_TYPE.SERVICE_MANAGER || usertype === USER_ROLE_TYPE.TECHNICIAN) && dashboard.dashboardTabSelection === TAB_ORG) {
    //         if (isRecordFound(selectedServiceOrganization) && isLoadProjectSelectedOnUI(selectedServiceOrganization.value.id)) {
    //             setProjectId(selectedServiceOrganization.value.id);
    //             setSelectedId(ALL_CUSTOMER_API_ID);
    //             setBuildingId(DEFAULT_ID);
    //             setSystemId(DEFAULT_ID);
    //             updateLogbook(useServiceOrganization.isTabClicked ? ALL_ORGANIZATION_API_ID : selectedServiceOrganization.value.id, DEFAULT_ID, DEFAULT_ID, selectedId);
    //         } else if (!isRecordFound(selectedServiceOrganization) && isLoadProjectSelectedOnUI(ALL_ORGANIZATION_API_ID)) {
    //             const orgId = sessionStorage.getItem(SELECTED_SM_ORGANIZATION);
    //             if (orgId === ALL_ORGANIZATION_ID) {
    //                 setSelectedId(ALL_CUSTOMER_API_ID);
    //                 setProjectId(ALL_ORGANIZATION_API_ID);
    //                 updateLogbook(ALL_ORGANIZATION_API_ID, DEFAULT_ID, DEFAULT_ID, ALL_CUSTOMER_API_ID);
    //                 setBuildingId(DEFAULT_ID);
    //                 setSystemId(DEFAULT_ID);
    //             }
    //         }
    //     }
    // }, [selectedServiceOrganization]); // eslint-disable-line react-hooks/exhaustive-deps



    const isLoadProjectSelectedOnUI = (selectedProjectId: string): boolean => {
        return projectId !== selectedProjectId;
    };

    const isLoadCustomerSelectedOnUI = (selectedCustomerId: string): boolean => {
        return selectedId !== selectedCustomerId;
    };

    useEffect(() => {
        if (!dashboard.buildingListLoading && dashboard.buildingListSuccess) {
            setBuildingList(buildingListLogbook(dashboard.buildingList.detail));
        } else if (!dashboard.buildingListLoading && dashboard.buildingListFail) {
            // addToast('Building list loading failed');
            setEnableDownload(false);
            setBuildingList([]);
            setBuildingId(DEFAULT_ID);
        }
    }, [dashboard.buildingListLoading, dashboard.buildingListSuccess, dashboard.buildingListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!asset.deviceListLoading && asset.deviceListSuccess) {
            setSystemList(systemListLogbook(asset.deviceList.detail));
        } else if (!asset.deviceListLoading && asset.deviceListFail) {
            setSystemList([]);
            setEnableDownload(false);
        }
    }, [asset.deviceListLoading, asset.deviceListSuccess, asset.deviceListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    const updateLogbook = (projectId: string, buildingId: string, systemId: string, customerId?: string) => {
        dispatch(LogbookActions.LatestLogListUnmounted());
        dispatch(LogbookActions.EarlierLogListUnmounted());
        if (fromDate !== null && toDate !== null && intermediateDate !== null) {
            dispatch(Thunks.Logbook.getLatestLogs(projectId, buildingId, rowsPerPageLatest.toString(), DEFAULT_PAGE_ONE.toString(), DEFAULT_ID, systemId, addDaysToDate(intermediateDate, 1), formatToSimpleDate(toDate), logType, isActiveLog, '', isServiceManager, useCustomer.isTabClicked ? ALL_CUSTOMER_API_ID : customerId));
            dispatch(Thunks.Logbook.getEarlierLogs(projectId, buildingId, rowsPerPageLatest.toString(), DEFAULT_PAGE_ONE.toString(), DEFAULT_ID, systemId, formatToSimpleDate(fromDate), formatToSimpleDate(intermediateDate), logType, isActiveLog, '', isServiceManager, useCustomer.isTabClicked ? ALL_CUSTOMER_API_ID : customerId));
        }
        dispatch(DashboardActions.buildingListUnmounted());
        dispatch(AssetActions.deviceListUnmounted());
        dispatch(Thunks.Dashboard.getBuildingList(projectId, DEFAULT_ID, DEFAULT_ID, useCustomer.isTabClicked ? ALL_CUSTOMER_API_ID : customerId));
        dispatch(Thunks.Asset.getDeviceList(projectId, buildingId, DEFAULT_ID, '', '', '', '', useCustomer.isTabClicked ? ALL_CUSTOMER_API_ID : customerId));
    };

    useEffect(() => {
        if (!logbook.latestLogListLoading && logbook.latestLogListSuccess) {
            if (selectionLogType.length === 0 && firstCall) {
                setFirstCall(false);
                setSelectionLogType([ALL]);
                setAllLogType(true);
            }
            if (logbook.latestLogList.detail.length > 0 || logbook.earlierLogList.detail.length > 0)
                setEnableDownload(true);
            else
                setEnableDownload(false);
        } else if (!logbook.latestLogListLoading && logbook.latestLogListFail) {
            console.log('latest log list loading failed ');
        }
    }, [logbook.latestLogListLoading, logbook.latestLogListSuccess, logbook.latestLogListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!logbook.downloadLogbookReportLoading && logbook.downloadLogbookReportSuccess) {
            console.log("Download logbook file success");
        } else if (!logbook.downloadLogbookReportLoading && logbook.downloadLogbookReportFail) {
            console.log('download log list loading failed ');
        }
    }, [logbook.downloadLogbookReportLoading, logbook.downloadLogbookReportSuccess, logbook.downloadLogbookReportFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if ((systemId || buildingId) && (logbook.latestLogList.detail.length > 0 || logbook.earlierLogList.detail.length > 0))
            setEnableDownload(true);
        else
            setEnableDownload(false);
    }, [systemId, buildingId, logbook.earlierLogList, logbook.latestLogList])
    //ToDo - handle project not found UI error
    /*if (!isRecordFound(selectedOrganization)) {
        return (
            <>
                <ContentContainer>
                    <RecordDataIndicators
                        recordData={selectedOrganization}
                        errorMessage={t('DASHBOARD.ERROR_LOADING_ORGANIZATION')}
                        notFoundIcon="Business"
                        notFoundMessage={t('DASHBOARD.ORGANIZATION_NOT_FOUND')}
                    />
                </ContentContainer>
            </>
        );
    }*/
    const onChangeLimtOffSet = (currentPage: number, offsetValue: number, isLatestLogs: boolean): void => {
        if (isLatestLogs) {
            setCurrentPageLatest(currentPage);
            setRowsPerPageLatest(offsetValue);
        }
        else {
            setCurrentPageEarlier(currentPage);
            setRowsPerPageEarlier(offsetValue);
        }
    };

    const resetFilterFlag = (flag: boolean) => {
        setIsFilterApplied(flag);
    }

    const NavigateToLogbookDetails = (logId: string, logSourceId: string): void => {
        pushAppRoute(
            history,
            { type: 'LogbookDetails', logId: logId },
            { backRoute: 'Logbook', logId: logId }
        );
    };

    const downloadSelection = (type: number) => {
        dispatch(Thunks.Logbook.downloadLogbookReport(
            type,
            "",
            buildingId,
            systemId,
            "",
            ""
        ));
    }
    const menuTimeRangeItems = [
        {
            "id": 1,
            "value": t('FILTER.PAST_SIX_MONTHS'),
            "checked": "false"
        }, {
            "id": 2,
            "value": t('FILTER.PAST_TWELVE_MONTHS'),
            "checked": "true"
        }, {
            "id": 3,
            "value": t('FILTER.SINCE_THE_BEGINNING_OF_YEAR', { replace: { currentyear: new Date().getFullYear().toString() } }),
            "checked": "false"
        }, {
            "id": 4,
            "value": t('FILTER.CUSTOM'),
            "checked": "false"
        },
    ];
    const menuDownloadGroups = [
        {
            items: [
                {
                    title: t('LABELS.PDF'),
                    onClick: (): void => {
                        downloadSelection(DOWNLOAD_TYPE.PDF);
                    },
                },
                {
                    title: t('LABELS.EXCEL'),
                    onClick: (): void => {
                        downloadSelection(DOWNLOAD_TYPE.EXCEL);
                    },
                },
                {
                    title: t('LABELS.CSV'),
                    onClick: (): void => {
                        downloadSelection(DOWNLOAD_TYPE.CSV);
                    },
                },
            ],
        },
    ];

    const handleLogTypeChange = (event: SelectChangeEvent<typeof selectionLogType>) => {
        const {
            target: { value },
        } = event;
        let val = value as string[];
        var logTypeValue: string[] = [];
        val.forEach((item) => {
            let logTypeId = logStatusEnum.filter((x) => item === x.name).map(a => a.id);
            logTypeValue.push(logTypeId.toString());
        });

        logTypeValue = logTypeValue.filter(n => n);
        setLogType(logTypeValue);

        if (value.length === 0) {
            setAllLogType(true);
            setSelectionLogType([ALL]);
            setLogType([]);
        }
        else if (value.includes(LOGBOOK_TYPE_FILTER.ACTIVE)) {
            if (!isActiveLog)
                setActiveLog(true);
            else
                setActiveLog(false);
            setSelectionLogType(val.filter((x) => x !== LOGBOOK_TYPE_FILTER.ACTIVE));
        }
        else if (value.includes(LOGBOOK_TYPE_FILTER.ALL)) {
            setSelectionLogType(val.filter((x) => x !== LOGBOOK_TYPE_FILTER.ALL));
            if (!isAllLogType) {
                setAllLogType(true);
                setSelectionLogType([ALL]);
            }
        }
        else if (value.includes(t('FILTER.ALL'))) {
            setAllLogType(false);
            setSelectionLogType(val.filter((x) => x !== t('FILTER.ALL')));
        }
        else if (value.length === logStatusEnum.length) {
            setAllLogType(true);
            setSelectionLogType([ALL]);
        }
        else {
            setSelectionLogType(
                // On autofill we get a stringified value.
                typeof value === 'string' ? value.split(',') : value,
            );
        }
    };

    const activeChecked = (event: React.ChangeEvent<HTMLElement>,
        checked: boolean,) => {
        setActiveLog(checked);
    }

    const allTypeChecked = (event: React.ChangeEvent<HTMLElement>,
        checked: boolean,) => {
        setAllLogType(true);
        setSelectionLogType([ALL]);
        setLogType([LOGBOOK_SEVERITY_STATUS.STATUS_EVENT.toString(), LOGBOOK_SEVERITY_STATUS.STATUS_WARNING.toString(), LOGBOOK_SEVERITY_STATUS.STATUS_ERROR.toString(), LOGBOOK_SEVERITY_STATUS.STATUS_BLOCK.toString()]);
    }

    const confirmCustomDate = () => {
        setPreviousStartDate(startDate);
        setPreviousEndDate(endDate);
        setCustomDateConfirm(true);
        if (startDate && endDate) {
            let daysCount = getDaysDifferenceFromDates(startDate, endDate);
            if (daysCount >= 0 && daysCount < 91) {
                setToDate(endDate);
                setIntermediateDate(startDate);
                setFromDate(startDate);
            }
            else {
                let intermediateDate = subtractDaysFromDate(endDate.toUTCString(), LOGBOOK_NINTY_DAYS_COUNT);
                setToDate(endDate);
                setIntermediateDate(intermediateDate);
                setFromDate(startDate);
            }
        }
        else {
            setSelectedRangeIndex(previousRangeIndex);
            setStartDate(new Date());
            setEndDate(new Date());
        }
        setOpenDateDialog(false);
    }
    const cancelCustomDate = () => {
        setOpenDateDialog(false);
        setSelectedRangeIndex(previousRangeIndex);
        setStartDate(previousStartDate);
        setEndDate(previousEndDate);
    }
    const resetCustomDate = () => {
        setStartDate(new Date());
        setEndDate(new Date());
    }

    const handleRangeMenuItemClick = (
        event: React.MouseEvent<HTMLElement>,
        index: number,
    ) => {

        switch (index) {
            case (LOGBOOK_TIME_RANGE_FILTER.SIX_MONTHS):
                {
                    setSelectedPreviousRangeIndex(index);
                    setSelectedRangeIndex(index);
                    setToDate(getDateFromDays(LOGBOOK_ZERO_DAYS_COUNT));
                    setIntermediateDate(getDateFromDays(LOGBOOK_NINTY_DAYS_COUNT));
                    setFromDate(getDateFromDays(LOGBOOK_ONE_EIGHTY_DAYS_COUNT));
                    resetCustomDate();
                    break;
                }
            case (LOGBOOK_TIME_RANGE_FILTER.TWELVE_MONTHS): {
                setSelectedPreviousRangeIndex(index);
                setSelectedRangeIndex(index);
                setToDate(getDateFromDays(LOGBOOK_ZERO_DAYS_COUNT));
                setIntermediateDate(getDateFromDays(LOGBOOK_NINTY_DAYS_COUNT));
                setFromDate(getDateFromDays(LOGBOOK_YEAR_DAYS_COUNT));
                resetCustomDate();
                break;
            }
            case (LOGBOOK_TIME_RANGE_FILTER.CURRENT_YEAR):
                {
                    setSelectedPreviousRangeIndex(index);
                    setSelectedRangeIndex(index);
                    setToDate(getDateFromDays(LOGBOOK_ZERO_DAYS_COUNT));
                    let currentYearDaysCount = getDaysFromDate(new Date().getUTCFullYear().toString());

                    if (currentYearDaysCount > 0 && currentYearDaysCount < 91) {
                        setIntermediateDate(getDateFromDays(currentYearDaysCount));
                        setFromDate(getDateFromDays(currentYearDaysCount));
                    }
                    else {
                        setIntermediateDate(getDateFromDays(LOGBOOK_NINTY_DAYS_COUNT));
                        setFromDate(getDateFromDays(currentYearDaysCount));
                    }
                    resetCustomDate();
                    break;
                }
            case (LOGBOOK_TIME_RANGE_FILTER.CUSTOM):
                {
                    setSelectedPreviousRangeIndex(selectedRangeIndex);
                    setSelectedRangeIndex(index);
                    setOpenDateDialog(true);
                    break;
                }
            default:
                setSelectedPreviousRangeIndex(index);
                setSelectedRangeIndex(index);
                break;

        }
    };

    const selectionTimeRangeChangeHandler = (event: SelectChangeEvent) => {
        setSelectedTimeRange(event.target.value as string);
    };

    return (
        <>
            <GenericToolbar data-testid="logbook" title={t('DASHBOARD.DASHBOARD_LOGBOOK_TITLE')} />
            <AppBar position="static" style={{ backgroundColor: PXBColors.blue[700], }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography
                        data-testid="show-title"
                        style={{ fontSize: 14, fontWeight: 400, color: PXBColors.blue[50] }}>
                        {t('LABELS.SHOW')}
                    </Typography>

                    <Select variant='standard'
                        disableUnderline
                        id="log-type-select"
                        data-testid="log-type-select"
                        multiple
                        multiselect-select-all="true"
                        style={{
                            display: 'inline',
                            marginLeft: 10,
                            fontSize: 14,
                            color: PXBColors.white[500],
                            fontWeight: 600,
                            width: "auto"
                        }}
                        value={selectionLogType}
                        onChange={handleLogTypeChange}
                        renderValue={(selectionLogType) => selectionLogType.join(', ')}
                        displayEmpty
                        sx={{
                            color: PXBColors.white[500],
                        }}
                    >
                        <MenuItem value={LOGBOOK_TYPE_FILTER.ALL}
                            style={{
                                display: 'flex',
                                justifyContent: 'left',
                                lineHeight: 1.5, fontWeight: 400,
                                overflow: 'hidden',
                                boxSizing: 'border-box',
                                whiteSpace: 'nowrap',
                                minHeight: 48,
                                paddingTop: 6,
                                paddingBottom: 6,
                                width: 'auto',
                                paddingLeft: 16,
                                paddingRight: 16
                            }}>
                            <Checkbox onChange={allTypeChecked}
                                checked={isAllLogType}
                            />
                            <ListItemText primary={t('FILTER.ALL_LOG_TYPES')} />
                        </MenuItem>
                        <Divider />
                        {logStatusEnum.map((data, val) => {
                            return (
                                <MenuItem id="log-type-list"
                                    data-testid="log-type-list"
                                    key={data.id} value={data.name}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'left',
                                        lineHeight: 1.5, fontWeight: 400,
                                        overflow: 'hidden',
                                        boxSizing: 'border-box',
                                        whiteSpace: 'nowrap',
                                        minHeight: 48,
                                        paddingTop: 6,
                                        paddingBottom: 6,
                                        width: 'auto',
                                        paddingLeft: 16,
                                        paddingRight: 16

                                    }}>
                                    <Checkbox checked={isAllLogType ? isAllLogType : selectionLogType.indexOf(data.name) > -1} />
                                    <img src={data.image} alt={data.id} style={{ width: 20, height: 20, marginLeft: 8, marginRight: 8 }}></img>
                                    <ListItemText primary={data.name} />
                                </MenuItem>
                            );
                        })}
                        <Divider />
                        <MenuItem value={LOGBOOK_TYPE_FILTER.ACTIVE}
                            style={{
                                display: 'flex',
                                justifyContent: 'left',
                                lineHeight: 1.5, fontWeight: 400,
                                overflow: 'hidden',
                                boxSizing: 'border-box',
                                whiteSpace: 'nowrap',
                                minHeight: 48,
                                paddingTop: 6,
                                paddingBottom: 6,
                                width: 'auto',
                                paddingLeft: 16,
                                paddingRight: 16
                            }}>
                            <Checkbox onChange={activeChecked} checked={isActiveLog} />
                            <ListItemText primary={t('FILTER.ACTIVE_LOGS_ONLY')} />
                        </MenuItem>
                    </Select>

                    <Typography
                        data-testid="time-range-title"
                        style={{ fontSize: 14, fontWeight: 400, marginLeft: 12, color: PXBColors.blue[50] }}>
                        {t('LABELS.TIME_RANGE')}
                    </Typography>

                    <Select variant='standard'
                        disableUnderline
                        data-testid="range-time-select"
                        value={menuTimeRangeItems[selectedRangeIndex].value}
                        onChange={selectionTimeRangeChangeHandler}
                        style={{
                            display: 'inline',
                            marginLeft: 10,
                            fontSize: 14,
                            color: PXBColors.white[500],
                            fontWeight: 600,
                            width: "auto",
                            paddingBottom: 8
                        }}
                        displayEmpty
                    >
                        {menuTimeRangeItems.map((data, index) => (
                            <MenuItem
                                data-testid="range-time-menu"
                                key={data.id}
                                value={data.value}
                                divider={index === 2}
                                selected={index === selectedRangeIndex}
                                style={{
                                    display: 'flex',
                                    justifyContent: "space-between",
                                    lineHeight: 1.5, fontWeight: 400,
                                    overflow: 'hidden',
                                    boxSizing: 'border-box',
                                    whiteSpace: 'nowrap',
                                    minHeight: 48,
                                    paddingTop: 6,
                                    paddingBottom: 6,
                                    width: 'auto',
                                    paddingLeft: 16,
                                    paddingRight: 16
                                }}
                                onClick={(event) => handleRangeMenuItemClick(event, index)}
                            >
                                {data.value}
                                {selectedRangeIndex === index && <Check sx={{ color: PXBColors.gray[500] }} />}
                            </MenuItem>
                        ))}
                    </Select>

                </div>
            </AppBar>
            <Box
                data-testid="logbook-box"
                style={{
                    paddingLeft: '5%',
                    paddingTop: vh_26,
                    paddingRight: '5%',
                    backgroundSize: 'contain',
                    alignItems: 'center',
                }}
            >
                <div data-testid="bldg" style={{ display: 'flex', marginBottom: vh_20, flexFlow: 'row wrap', justifyContent: 'space-between' }}>
                    <Select
                        id="building-list-dropdown"
                        data-testid="building-list-select"
                        style={{
                            backgroundColor: focusedBuilding ? PXBColors.lightBlue[50] : PXBColors.white[50],
                            marginRight: '1%',
                            width: 'auto',
                            height: 35,
                            borderBlock: 0,
                            outline: 'none',
                            borderRadius: 32,
                            overflow: 'hidden',
                            fontSize: 14,
                            color: focusedBuilding ? PXBColors.blue[500] : PXBColors.black[500],
                            fontStyle: 'normal',
                            fontWeight: 400,
                            marginTop: '2%',
                        }}
                        value={buildingId}
                        onChange={buildingOnChange}
                        displayEmpty
                    >
                        <MenuItem value=""
                            style={{
                                display: 'flex',
                                justifyContent: 'left', fontSize: 14,
                                lineHeight: 1.5, fontWeight: 400,
                                overflow: 'hidden',
                                boxSizing: 'border-box',
                                whiteSpace: 'nowrap',
                                minHeight: 48,
                                paddingTop: 6,
                                paddingBottom: 6,
                                width: 'auto',
                                paddingLeft: 16,
                                paddingRight: 16
                            }}>{t('SEARCH.ALL_BUILDINGS')}</MenuItem>
                        {buildingList.map((data, val) => {
                            return (
                                <MenuItem id="building-list" key={data.id} value={data.id}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'left', fontSize: 14,
                                        lineHeight: 1.5, fontWeight: 400,
                                        overflow: 'hidden',
                                        boxSizing: 'border-box',
                                        whiteSpace: 'nowrap',
                                        minHeight: 48,
                                        paddingTop: 6,
                                        paddingBottom: 6,
                                        width: 'auto',
                                        paddingLeft: 16,
                                        paddingRight: 16
                                    }}>
                                    {data.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <Select
                        id="system-list-dropdown"
                        data-testid="system-list"
                        style={{
                            backgroundColor: focusedSystem ? PXBColors.lightBlue[50] : PXBColors.white[50],
                            marginRight: '1%',
                            width: 'auto',
                            height: 35,
                            borderBlock: 0,
                            outline: 'none',
                            borderRadius: 32,
                            overflow: 'hidden',
                            fontSize: 14,
                            color: focusedSystem ? PXBColors.blue[500] : PXBColors.black[500],
                            fontStyle: 'normal',
                            fontWeight: 400,
                            marginTop: '2%',
                        }}
                        value={systemId}
                        onChange={systemOnChange}
                        displayEmpty
                    >
                        <MenuItem value=""
                            style={{
                                display: 'flex',
                                justifyContent: 'left', fontSize: 14,
                                lineHeight: 1.5, fontWeight: 400,
                                overflow: 'hidden',
                                boxSizing: 'border-box',
                                whiteSpace: 'nowrap',
                                minHeight: 48,
                                paddingTop: 6,
                                paddingBottom: 6,
                                width: 'auto',
                                paddingLeft: 16,
                                paddingRight: 16
                            }}>{t('SEARCH.ALL_SYSTEMS')}</MenuItem>
                        {systemList.map((data, val) => {
                            return (
                                <MenuItem id="system-list" key={data.id} value={data.id}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'left', fontSize: 14,
                                        lineHeight: 1.5, fontWeight: 400,
                                        overflow: 'hidden',
                                        boxSizing: 'border-box',
                                        whiteSpace: 'nowrap',
                                        minHeight: 48,
                                        paddingTop: 6,
                                        paddingBottom: 6,
                                        width: 'auto',
                                        paddingLeft: 16,
                                        paddingRight: 16
                                    }}>
                                    {data.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <Tooltip title={enableDownload ? '' : t('ASSETS_DETAILS.DOWNLOAD_TOOLTIP')} placement='bottom-start'>
                        <div style={{ display: 'flex', marginLeft: 'auto', marginTop: 'auto' }}>
                            <div style={{ opacity: enableDownload ? '1' : '0.5', pointerEvents: enableDownload ? 'auto' : 'none' }}>
                                <ToolbarMenu
                                    id="download-as-button"
                                    data-testid="download-as-button"
                                    style={{
                                        backgroundColor: PXBColors.blue[700],
                                        color: PXBColors.white[50],
                                        marginTop: '2%',
                                        minWidth: 40
                                    }}
                                    sx={{
                                        fontSize: 14,
                                        height: 35,
                                        textTransform: 'none',
                                        border: '0px solid #727E84',
                                        borderRadius: 2,
                                    }}
                                    label={t('ASSETS_DETAILS.DOWNLOAD_AS')}
                                    icon={<GetApp style={{ padding: 3, marginLeft: 2 }} />}
                                    menuGroups={menuDownloadGroups} placeholder={undefined}                        >
                                </ToolbarMenu>
                            </div>
                        </div>
                    </Tooltip>
                </div>
                <OverviewSystemLogs
                    OnLogSelected={(logId: string, logSourceId: string): void => {
                        NavigateToLogbookDetails(logId, logSourceId);
                    }}
                    onLimitOffSetChange={onChangeLimtOffSet}
                    data-testid="latest-logs-id"
                    userId={''}
                    buildingId={''}
                    isDashboard={false}
                    isLogbook={true}
                    defaultOpenState={true}
                    title={t('DASHBOARD.LAST_90_DAYS')}
                    isLatestLogs={true}
                    isFilterApplied={isFilterApplied}
                    resetFilterFlag={() => resetFilterFlag}
                />
                <Spacer style={{ height: 16 }} placeholder={undefined} />
                <OverviewSystemLogs
                    OnLogSelected={(logId: string, logSourceId: string): void => {
                        NavigateToLogbookDetails(logId, logSourceId);
                    }}
                    onLimitOffSetChange={onChangeLimtOffSet}
                    data-testid="earlier-logs-id"
                    userId={''}
                    buildingId={''}
                    isDashboard={false}
                    isLogbook={true}
                    defaultOpenState={true}
                    title={t('DASHBOARD.EARLIER')}
                    isLatestLogs={false}
                    isFilterApplied={isFilterApplied}
                    resetFilterFlag={resetFilterFlag}
                />
            </Box>
            <Dialog id="invite-user-dialog" maxWidth="lg" open={openDateDialog} >
                <DialogTitle id="invite-user-dialog-header">{t('LABELS.CUSTOM_LOGBOOK_RANGE')}</DialogTitle>
                <DialogContent style={{ padding: 20 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label={t('LABELS.START_DATE')}
                            value={startDate}
                            onChange={(date) => setStartDate(date)}
                            maxDate={new Date()}          //this works but showing error to find workaround
                        />
                        <DatePicker
                            sx={{ marginLeft: 2 }}
                            label={t('LABELS.END_DATE')}
                            value={endDate}
                            onChange={(date) => setEndDate(date)}
                            minDate={startDate}
                            maxDate={new Date()}
                        />
                    </LocalizationProvider>

                </DialogContent>
                <DialogActions style={{ justifyContent: 'right', marginLeft: 15 }}>
                    <Button
                        style={{ textTransform: 'none', fontSize: 16 }}
                        id="log-date-dialog-btnCancel"
                        data-testid="log-date-dialog-btnCancel"
                        variant="outlined" color="primary"
                        onClick={(): void => cancelCustomDate()}
                    >
                        <Icon.Close style={{ color: PXBColors.blue[500], marginRight: 12 }} />
                        {t('ACTIONS.CANCEL')}
                    </Button>
                    <Button
                        id="log-date-dialog-btnConfirm"
                        data-testid="log-date-dialog-btnConfirm"
                        style={{
                            paddingLeft: 16, paddingRight: 16, paddingTop: 8.5, paddingBottom: 8.5,
                            fontSize: 16,
                            flexDirection: 'row',
                            backgroundColor: endDate && startDate && endDate >= startDate ? PXBColors.blue[500] : PXBColors.blue[50],
                            color: endDate && startDate && endDate >= startDate ? PXBColors.white[50] : PXBColors.blue[200],
                            marginLeft: 16,
                            textTransform: 'none'
                        }}
                        type="submit"
                        color="primary"
                        disabled={endDate && startDate ? !(endDate >= startDate) : true}
                        onClick={(): void => confirmCustomDate()}
                    >
                        <Icon.Check style={{ color: endDate && startDate && endDate >= startDate ? PXBColors.white[50] : PXBColors.blue[200], marginRight: 8 }} />
                        {t('ACTIONS.CONFIRM')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

//ToDo - for handling logbook date filters

/*

    const [selectDays, setSelectDays] = useState(90);

    const [fromDate, setFromDate] = useState(moment.utc().format());

    const [toDate, setToDate] = useState(
        moment()
            .subtract(selectDays, 'days')
            .calendar()
    );
    function setFromAndToDates(selectedDays: number): void {

        setSelectDays(selectedDays);

        // Latest logs date
        setFromDate(moment.utc().format());
        setToDate(
            moment()
                .subtract(selectedDays, 'days')
                .calendar()
        );

        // Previous logs date
        setFromDateEarlier(
            moment()
                .subtract(selectedDays, 'days')
                .calendar()
        );
        setToDateEarlier(
            moment()
                .subtract(500, 'days')
                .calendar()
        );
    }
*/
