// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
import { Divider, Card, ListSubheader, Typography, Collapse, CircularProgress, TablePagination } from '@mui/material';
import List from '@mui/material/List';
import { SystemLogListItem } from '../../components/SystemLog/SystemLogListItem';
import { SystemLogList } from '../../types/system-log';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { ActionRow } from '../../components/OverviewCard/ActionRow';
import { AppRoute } from '../../routes';
import { useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import * as PXBColors from '@brightlayer-ui/colors';
import ListItemButton from '@mui/material/ListItemButton';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { EmptyState } from '@brightlayer-ui/react-components';
import { RECORD_PER_PAGE_LIMIT, RECORD_PER_PAGE_LIMIT_CUSTOMER_ALARM } from '../../constants';
import { getUserRoleType, useAuthState } from '../../components/AuthContextProvider/component';
import { USER_ROLE_TYPE } from '../enum/ENUM';

type Props = {
    userId?: string;
    projectId?: string;
    buildingId?: string;
    isDashboard?: boolean;
    isLogbook?: boolean;
    defaultOpenState: boolean;
    title?: string;
    OnLogSelected: Function;
    isLatestLogs: boolean;
    isServiceDashboard?: boolean;
    onLimitOffSetChange?: Function;
    isFilterApplied?: boolean;
    resetFilterFlag?: Function;
};

export const OverviewSystemLogs: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const [open, setOpen] = React.useState(props.defaultOpenState);
    const { t } = useLanguageLocale();
    const logbook = useSelector(Selectors.Logbook.logbook);
    const [systemLogList, setSystemLogList] = useState<SystemLogList>([]);
    const authState = useAuthState();
    const usertype = getUserRoleType(authState) || '';
    const [emptyLogList, setEmptyLogList] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(0);

    const [rowsPerPage, setRowsPerPage] = React.useState(RECORD_PER_PAGE_LIMIT);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setCurrentPage(newPage);
        if (props.onLimitOffSetChange)
            props.onLimitOffSetChange(newPage + 1, rowsPerPage, props.isLatestLogs);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
        if (props.onLimitOffSetChange)
            props.onLimitOffSetChange(1, parseInt(event.target.value, 10), props.isLatestLogs);
    };

    useEffect(() => {
        if (props.isFilterApplied) {
            setCurrentPage(0);
            if (props.resetFilterFlag) {
                props.resetFilterFlag(false);
            }
        }
    }, [props.isFilterApplied]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.isLatestLogs) {
            if (logbook.latestLogListLoading) {
                setEmptyLogList(false);
            }
            if (!logbook.latestLogListLoading && logbook.latestLogListSuccess) {
                if (props.isServiceDashboard && logbook.latestLogList) {
                    setSystemLogList(logbook.latestLogList.detail.slice(0, RECORD_PER_PAGE_LIMIT_CUSTOMER_ALARM));
                }
                else if (props.isDashboard && logbook.latestLogList) {
                    setSystemLogList(logbook.latestLogList.detail.slice(0, RECORD_PER_PAGE_LIMIT));
                } else {
                    setTotalRecords(logbook.latestLogList.totalRecordCount ? logbook.latestLogList.totalRecordCount : 0);
                    setSystemLogList(logbook.latestLogList.detail.slice(0, rowsPerPage));
                }
                setEmptyLogList(logbook.latestLogList.detail.length <= 0)
            } else if (!logbook.latestLogListLoading && logbook.latestLogListFail) {
                console.log('latest log list loading failed ');
                setSystemLogList([]);
                setEmptyLogList(true);
            }
        }
    }, [logbook.latestLogListLoading, logbook.latestLogListSuccess, logbook.latestLogListFail]); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (!props.isLatestLogs) {
            if (logbook.earlierLogListLoading) {
                setEmptyLogList(false);
            }
            if (!logbook.earlierLogListLoading && logbook.earlierLogListSuccess) {
                setTotalRecords(logbook.earlierLogList.totalRecordCount ? logbook.earlierLogList.totalRecordCount : 0)
                if (props.isDashboard)
                    setSystemLogList(logbook.earlierLogList.detail.slice(0, RECORD_PER_PAGE_LIMIT));
                else
                    setSystemLogList(logbook.earlierLogList.detail.slice(0, rowsPerPage));
                setEmptyLogList(logbook.earlierLogList.detail.length <= 0)

            } else if (!logbook.earlierLogListLoading && logbook.earlierLogListFail) {
                console.log('latest log list loading failed ');
                setSystemLogList([]);
                setEmptyLogList(true);
            }
        }
    }, [logbook.earlierLogListLoading, logbook.earlierLogListSuccess, logbook.earlierLogListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    const viewAllEventsAppRoute: AppRoute = {
        type: 'OverviewSiteTimeline',
        siteId: '1',
        locationId: '1',
    };

    const OnLogSelected = (logId: string, logSourceId: string): void => {
        props.OnLogSelected(logId, logSourceId);
    };

    return (
        <>
            <Card style={{ flex: '1 1 0px', overflow: 'auto' }} data-testid="system-log-list-container">
                <List
                    component="div"
                    disablePadding
                    id="system-log-list"
                    data-testid="system-log-list"
                    style={!props.isLogbook && props.isDashboard ? { minHeight: 'auto' } : {}}
                    subheader={
                        <>
                            <ListSubheader
                                color="primary"
                                component="div"
                                data-testid="system-log-list-header"
                                id="system-log-list-header"
                                style={{
                                    display: 'flex',
                                    fontWeight: 600,
                                    fontSize: 14,
                                    alignItems: 'center',
                                    overflow: 'auto',
                                    whiteSpace: 'nowrap',
                                    marginLeft: 10,
                                    height: 45,
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    style={{
                                        alignItems: 'left',
                                        fontSize: 14,
                                        fontWeight: 600,
                                        color: PXBColors.blue[400],
                                        width: '92.3%',
                                        paddingLeft: '10',
                                    }}
                                    id="system-log-title"
                                    data-testid="system-log-title"
                                >
                                    {usertype!==USER_ROLE_TYPE.SERVICE_MANAGER && props.title}
                                    {usertype===USER_ROLE_TYPE.SERVICE_MANAGER && props.title ? (props.title + " ("+ systemLogList.length) +")" : ''}
                                </Typography>

                                {(props.isLogbook || props.isServiceDashboard) && (
                                    <ListItemButton
                                        onClick={handleClick}
                                        id="list-header-item-button"
                                        style={{
                                            width: '7.41%',
                                        }}
                                    >
                                        {open ? (
                                            <KeyboardArrowUp data-testid="arrow-up" id="list-header-up-icon" />
                                        ) : (
                                            <KeyboardArrowDown data-testid="arrow-down" id="list-header-down-icon" />
                                        )}
                                    </ListItemButton>
                                )}
                            </ListSubheader>

                            <Divider />
                        </>
                    }
                >
                    {(props.isLatestLogs ? logbook.latestLogListLoading : logbook.earlierLogListLoading) && <EmptyState style={{ flex: 1, minHeight: 300, backgroundColor: PXBColors.white[50] }}
                    icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}

                    {!(props.isLatestLogs ? logbook.latestLogListLoading : logbook.earlierLogListLoading) && <Collapse in={open} timeout="auto" unmountOnExit>
                        {emptyLogList && <EmptyState style={{ flex: 1, height: 200 }}
                        title={t('ORGANIZATION_MANAGE.ERROR_NO_LOGS_TITLE')} icon={undefined} placeholder={undefined} />}
                        {systemLogList?.map((data, logs) => {
                            return (
                                <SystemLogListItem
                                    key={'system-log-key-' + data.id}
                                    OnItemSelected={(logId: string, logSourceId: string): void => OnLogSelected(logId, logSourceId)}
                                    isLogbook={props.isLogbook}
                                    value={{
                                        id: data.id,
                                        comment: data.comment,
                                        logSourceId: data.logSourceId,
                                        partitionKey: data.partitionKey,
                                        name: data.name,
                                        title: data.title,
                                        description: data.description,
                                        datetimeutc: data.datetimeutc,
                                        elstatus: data.elstatus,
                                        systemId: data.systemId,
                                        systemName: data.systemName,
                                        logSourceName: data.logSourceName,
                                        projectId: data.projectId,
                                        projectName: data.projectName,
                                        buildingName: data.buildingName,
                                        buildingId: data.buildingId,
                                        sublocation: data.sublocation,
                                        isactive: data.isactive,
                                        systemPartNumber: data.systemPartNumber,
                                        systemType: data.systemType,
                                        logSourcePartNumber: data.logSourcePartNumber,
                                        logSourceSerialNumber: data.logSourceSerialNumber,
                                        logSourceDeviceType: data.logSourceDeviceType,
                                        logStartDate: data.logStartDate,
                                        logEndDate: data.logEndDate,
                                        acknowledgedTime: data.acknowledgedTime,
                                        isAcknowledged: data.isAcknowledged,
                                        acknowledgedBy: data.acknowledgedBy,
                                        subDevice: data.subDevice,
                                        gatewayName: data.gatewayName, 
                                        gatewayId: data.gatewayId
                                    }}
                                ></SystemLogListItem>
                            );
                        })}
                        {!emptyLogList && !props.isDashboard && <TablePagination
                            component="div"
                            data-testid="pagination-logbook"
                            count={totalRecords}
                            page={currentPage}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={t("LABELS.ITEMS_PER_PAGE")}
                            showFirstButton showLastButton
                        />}
                    </Collapse>}
                </List>
                <div data-testid='viewLogbookLink'>
                    {props.isDashboard && <Divider />}
                    {props.isDashboard && (
                        <ActionRow data-testid='view-logbook-link' label={usertype !== USER_ROLE_TYPE.SERVICE_MANAGER?t('DASHBOARD.VIEW_LOGBOOK'):t('LABELS.VIEW_ALL')} appRoute={viewAllEventsAppRoute} />
                    )}
                </div>
            </Card>
        </>
    );
};
