import React, { useEffect } from "react";
import { Card, CardContent, CircularProgress, Divider, IconButton, ListItem, Typography, useMediaQuery, useTheme } from "@mui/material";
import * as PXBColors from '@brightlayer-ui/colors';
import * as MuiCore from '@mui/material';
import { EmptyState, InfoListItem } from "@brightlayer-ui/react-components";
import { Contacts, ReceiptLong, Group, ChevronRight, Person } from "@mui/icons-material";
import { OrgDetailListItem } from "../../components/OverviewList/OrgDetailListItem";
import { COLOR_GRAY, COLOR_GRAY_ICON } from "../../constants/color-codes";
import { useLanguageLocale } from "../../hooks/language-locale-hooks";
import { SEPERATOR_COMMA, SEPERATOR_SPACE, SYMBOL_PLUS } from "../../constants";
import * as MuiIcons from '@mui/icons-material';
import { useDispatch, useSelector } from "react-redux";
import { Selectors } from "../../selectors";
import { TooltipItem } from "../../components/TooltipItem/TooltipItem";
import { getControlledText } from "../enum/GenericFormat";
import { BuildingData } from "../../types/building";


type Props = {
    onSLAClick: Function;
    onUserClick: Function;
    onBuildingDetailClick: Function;
    OnEditSelectedAction: Function;
    userId?: string;
    selectedId: string;
    name: string;
    location: string;
};

export const OverviewBuildingCardDetails: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {

    const { t } = useLanguageLocale();
    const theme = useTheme();
    const dashboard = useSelector(Selectors.Dashboard.dashboard);
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const usersSelector = useSelector(Selectors.Users.users);

    const [users, setUsers] = React.useState(t('MESSAGES.LOADING'));
    const [selectedBuilding, setSelectedBuilding] = React.useState<BuildingData>();

    const updateUserListData = (adminCount: number, viewerCount: number): void => {
        const adminsText = adminCount > 0 ? (adminCount > 1 ? adminCount + ' ' + t('DASHBOARD.USERS_ADMIN_S') : adminCount + ' ' + t('DASHBOARD.USERS_ADMIN')) : '';
        const seperatorText = viewerCount > 0 ? SEPERATOR_COMMA : '';
        const viewersText = viewerCount > 0 ? (viewerCount > 1 ? viewerCount + ' ' + t('DASHBOARD.USERS_VIEWER_S') : viewerCount + ' ' + t('DASHBOARD.USERS_VIEWER')) : '';
        setUsers(adminsText + seperatorText + viewersText)
    }

    const goToUserDetails = (): void => {
        props.onUserClick();
    };

    const goToSLAList = (): void => {
        props.onSLAClick();
    };

    const goToBuildingDetail = (): void => {
        props.onBuildingDetailClick();
    };

    const OnEditSelectedAction = (): void => {
        props.OnEditSelectedAction();
    };

    useEffect(() => {
        if (!dashboard.buildingListLoading && dashboard.buildingListSuccess) {
            setSelectedBuilding(dashboard.buildingList.detail[0]);
        } else if (!dashboard.buildingListLoading && dashboard.buildingListFail) {
            console.log('Building list loading failed');
        }else{
            setSelectedBuilding(undefined);
        }
    }, [dashboard.buildingListLoading, dashboard.buildingListSuccess, dashboard.buildingListFail]); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (!usersSelector.userListLoading && usersSelector.userListSuccess) {
            updateUserListData(usersSelector.userList.AdminCount, usersSelector.userList.viewersCount);
        } else if (!usersSelector.userListLoading && usersSelector.userListFail) {
            updateUserListData(0, 0);
        }
    }, [usersSelector.userListLoading, usersSelector.userListSuccess, usersSelector.userListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Card style={{ flex: '1 1 0px', overflow: 'auto', zIndex: 5 }} data-testid="dashboard-building-detail-container">
            {!selectedBuilding && <EmptyState style={{ flex: 1, minHeight: 400, backgroundColor: PXBColors.white[50] }}
                icon={<CircularProgress id="progress-spinner" />} title="" placeholder={""} />}
            {selectedBuilding &&
                <>
                    <div style={{ display: 'absolute', width: '100%', height: 'auto' }}>

                        <div style={{ display: 'flex', alignItems: 'center', overflow: 'auto', whiteSpace: 'nowrap', marginRight: 16 }}>
                            <CardContent style={{ flex: '1 1 0px', overflow: 'auto', alignItems: 'left', width: '90%' }}>
                                <TooltipItem
                                    title={props.name ? props.name : ''}
                                    isTopPlacement={true}
                                    lenghtLimit={22}
                                    component={
                                        <Typography variant="body2" style={{ fontSize: 24 }} id="header-name" data-testid="header-name" >{getControlledText(props.name ? props.name : '', 22)}</Typography>
                                    }>
                                </TooltipItem>

                                <TooltipItem
                                    title={props.location ? props.location : ''}
                                    isTopPlacement={false}
                                    lenghtLimit={30}
                                    component={
                                        <Typography variant="body2" style={{ fontSize: 14 }} id="header-location" data-testid="header-location" >{getControlledText(props.location ? props.location : '', 30)}</Typography>
                                    }>
                                </TooltipItem>
                            </CardContent>
                            <MuiCore.IconButton style={{ alignItems: 'right', width: '10%' }} data-testid="header-edit" id="header-edit" onClick={() => OnEditSelectedAction()} > <MuiIcons.Edit /> </MuiCore.IconButton>
                        </div>

                        {selectedBuilding?.buildingId && <OrgDetailListItem
                            title1={(selectedBuilding.primaryFirstName ? selectedBuilding.primaryFirstName : "") + (selectedBuilding.primaryLastName ? SEPERATOR_SPACE + selectedBuilding.primaryLastName : "")}
                            title2={SYMBOL_PLUS + selectedBuilding.countryCode + SEPERATOR_SPACE + selectedBuilding.phone}
                            title3={selectedBuilding.email ? selectedBuilding.email : ''}
                            subtitle={t('ORGANIZATION_DETAILS.PRIMARY_CONTACT')}
                            icon={<Contacts style={{ color: COLOR_GRAY_ICON }} />}
                            iconAlignment={'center'}
                            displayAction={false}
                            dividerDisable={true}
                        />}
                        {selectedBuilding?.buildingId && <Divider />}

                        {selectedBuilding?.buildingId && <OrgDetailListItem
                            title1={(selectedBuilding.secondaryFirstName ? selectedBuilding.secondaryFirstName : "") + (selectedBuilding.secondaryLastName ? SEPERATOR_SPACE + selectedBuilding.secondaryLastName : "")}
                            title2={SYMBOL_PLUS + selectedBuilding.secondaryCountryCode + SEPERATOR_SPACE + selectedBuilding.secondaryPhone}
                            title3={selectedBuilding.secondaryEmail ? selectedBuilding.secondaryEmail : ''}
                            subtitle={t('ORGANIZATION_DETAILS.SECONDARY_CONTACT')}
                            icon={<Contacts style={{ color: COLOR_GRAY_ICON }} />}
                            iconAlignment={'center'}
                            displayAction={false}
                            dividerDisable={true}
                        />}
                        {selectedBuilding?.buildingId && <Divider />}
                        <InfoListItem data-testid='service-number' title={"--"} subtitle={t('DASHBOARD.SERVICE_LEVEL_AGREEMENT')}
                            icon={<ReceiptLong style={{ color: COLOR_GRAY_ICON }} />}
                            iconAlign={'left'}
                            divider={'full'}
                            rightComponent={<IconButton edge="end" id="list-item-next-icon-sla" data-testid="list-item-next-icon-sla" style={{ marginLeft: "auto" }}
                                onClick={() => goToSLAList()}
                            >
                                <ChevronRight style={{ color: COLOR_GRAY }} />
                            </IconButton>} placeholder={undefined} />
                        <OrgDetailListItem
                            title1={users}
                            title2={''}
                            title3={''}
                            subtitle={t('ORGANIZATION_DETAILS.USERS')}
                            icon={<Group style={{ color: COLOR_GRAY_ICON }} />}
                            iconAlignment={'center'}
                            displayAction={true}
                            OnItemSelected={() => goToUserDetails()}
                            dividerDisable={true}
                        />
                        <Divider />
                        {selectedBuilding?.buildingId &&
                            <>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 16 }}>
                                    <Typography
                                        variant="subtitle2"
                                        data-testid="view-all-building"
                                        style={{
                                            color: PXBColors.blue[400],
                                        }}
                                        id="view-all-building"
                                    >
                                        {t('LABELS.VIEW_BUILDING_DETAILS')}
                                    </Typography>
                                    <MuiCore.IconButton style={{ alignItems: 'right', width: '8%', padding: '0' }}
                                        onClick={(): void => goToBuildingDetail()}
                                    >
                                        {' '}
                                        <MuiIcons.ChevronRight />{' '}
                                    </MuiCore.IconButton>
                                </div>
                            </>
                        }
                    </div>

                </>}

        </Card >
    );

};
