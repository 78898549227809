// eslint-disable-next-line
import React from 'react';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { Button, Card, Divider, Typography } from '@mui/material';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { ChevronRight, InsertDriveFile, } from '@mui/icons-material';
import { COLOR_BLUE_ICON, COLOR_GRAY_ICON, } from '../../constants/color-codes';
import { DocumentsDetail } from '../../types/maintenance';

type Props = {
    maintenanceDetail: DocumentsDetail;
};

export const MaintenanceDocument: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const maintenanceDetail = props.maintenanceDetail;

    return (
        <>
            <Card style={{ height: 'fit-content', flex: '1 1 0px', overflow: 'auto' }}>
                <div>
                    <Typography data-testid="list-item-text" style={{ fontWeight: 600, fontSize: 14, lineHeight: 3.5, fontStyle: 'normal', color: COLOR_BLUE_ICON, marginLeft: 16 }}  >
                        {t('MAINTENANCE_DETAILS.DOCUMENTATION')}
                    </Typography>
                </div>
                <Divider />
                {maintenanceDetail.length !== 0 ?
                    maintenanceDetail.map((maintenance) => (<InfoListItem data-testid='doc-name' style={{ height: 56 }} title={''} subtitle={maintenanceDetail.length !== 0 ? maintenance.fileName : ''}
                        icon={<InsertDriveFile style={{ color: COLOR_GRAY_ICON }} />}
                        iconAlign={'center'}
                        divider={'full'} placeholder={undefined}
                        rightComponent={<ChevronRight style={{ color: COLOR_GRAY_ICON, marginRight: '5%', marginTop: '5.5%' }} />} />))
                    : <Typography data-testid="list-item-text-doc-name" style={{ fontWeight: 600, fontSize: 20, lineHeight: 3.5, fontStyle: 'normal', color: COLOR_GRAY_ICON, marginLeft: 16, textAlign: 'center' }}  >
                        {t('MAINTENANCE_DETAILS.NO_DOC_ADDED')}
                    </Typography>}
                <div>
                    <Button style={{ width: '95%', textTransform: 'none', marginLeft: 16, marginBottom: 10, marginTop: 10 }} id="btnCancel" data-testid="btnCancel1" variant="outlined" color="primary" disabled={false} onClick={() => <a href={maintenanceDetail.length !== 0 ? maintenanceDetail[0].filePath : ''} download="DesiredFileName.ext"></a>} >
                        {t('MAINTENANCE_DETAILS.ADD_DOC')}
                    </Button>
                </div>
            </Card>
        </>
    );
};


