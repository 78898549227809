// eslint-disable-next-line
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { Box, Typography, Paper, Button } from '@mui/material';
import { GenericToolbar } from '../../components/GenericToolbar';
import * as PXBColors from '@brightlayer-ui/colors';
import SyncButtonImage from '../../assets/images/sync_button.svg';
import { OverviewBuildingList } from './OverviewBuildingList';
import { OverviewStatisticSummary } from './OverviewStatisticSummary';
import { OverviewSystemLogs } from './OverviewSystemLogs';
import { Actions, Thunks } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { RecordDataState, isRecordFound, useSelectedCustomerData, useSelectedOrganizationData } from '../../hooks/data-hooks';
import { SELECTED_ORGANIZATION, ALL_ORGANIZATION_ID, ALL_ORGANIZATION_API_ID, DEFAULT_PAGE_ONE, RECORD_PER_PAGE_LIMIT, TAB_CUSTOMER, TAB_ORG, ALL_CUSTOMER_ID, ALL_CUSTOMER_API_ID, RECORD_PER_PAGE_LIMIT_CUSTOMER_ALARM, SELECTED_CUSTOMER, SELECTED_SM_ORGANIZATION, DEFAULT_ALL_ID, ActionType, TAB_BUILDING_LIST, TAB_BUILDING_SUMMARY, SM_BUILDING_TAB } from '../../constants';
import OverviewMap from './OverviewMap';
import { OverviewSelectedData } from '../../types/overview-type';
import { ToolbarColor } from '../../components/GenericToolbar/GenericToolbar';
import { useHistory } from 'react-router-dom';
import { EmptyState } from '@brightlayer-ui/react-components';
import { Add, Dashboard } from '@mui/icons-material';
import { Selectors } from '../../selectors';
import { DashboardThunks } from '../../actions/thunks/dashboard-thunks';
import { ActiveInviteDialog } from '../../components/AcceptInvite/AcceptInviteDialog';
import { UsersThunks } from '../../actions/thunks/users-thunks';
import { ENTITY_TYPE, INVITE_STATUS, LOGBOOK_SEVERITY_STATUS, USER_ROLE_TYPE } from '../enum/ENUM';
import { useToasts } from '../../data/DataProviders/ToastProvider';
import { InviteRequestDialog } from '../../components/RequesttInvite/InviteRequestDialog';
import { rem_10, vh_10, vh_250, vw_10 } from '../../constants/dimentions';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { TAB_BUILDING_KEY, TAB_SLA_KEY, TAB_SYSTEM_KEY, updateTabSession } from '../assets/Assets';
import { useAuthState } from '../../components/AuthContextProvider';
import { getUserRoleType } from '../../components/AuthContextProvider/component';
import { OverviewCardDetails } from './OverviewCardDetails';
import { useCustomerList } from '../../data/DataProviders/CustomerListProvider';
import { TAB_CUSTOMERS_KEY, updateUserTabSession } from '../user-management/ManageUser';
import { addLocationId, AppRoute } from '../../routes';
import { useCurrentAppRoute } from '../../hooks/app-route-hooks';
import { useOrganizationList } from '../../data/DataProviders/OrganizationListProvider';
import { UserSettingsActions } from '../../actions/actions/user-settings-actions';
import { OverviewSMBuildingCard } from './OverviewSMBuildingCard';
import { addDaysToDate } from '../enum/DateTimeFormat';
import { TAB_SCHEDULED_KEY } from '../maintenance/Maintenance';
import { OverviewMaintenanceServiceDueLogs } from './OverviewMaintenanceServiceDueLogs';
import { pushAppRoute } from '../../data/domain/route-manager';

type Props = {
    userId?: string;
};

export const Overview: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const history = useHistory();
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const DEFAULT_ID: string = '';
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const [searchBuildingQuery, setSearchBuildingQuery] = useState('');
    const dashboard = useSelector(Selectors.Dashboard.dashboard);
    const customer = useSelector(Selectors.Customer.customer);
    const users = useSelector(Selectors.Users.users);

    const authState = useAuthState();
    const usertype = getUserRoleType(authState) || '';
    const hasPageBeenRendered = useRef(false);
    const [buildingEmptyState, setBuildingEmptyState] = React.useState(false);
    const [inviteEmptyState, setInviteEmptyState] = React.useState(false);
    const [inviteApprovalEmptyState, setInviteApprovalEmptyState] = React.useState(false);
    const [projectId, setProjectId] = React.useState(ALL_ORGANIZATION_API_ID);
    const [projectName, setProjectName] = React.useState(t('DASHBOARD.ALL_ORGANIZATION'));
    const [selectedId, setSelectedId] = React.useState(DEFAULT_ALL_ID);
    const [name, setName] = React.useState(t('DASHBOARD.ALL_CUSTOMER'));
    const [customerNumber, setCustomerNumber] = React.useState('');
    const [customerLocation, setCustomerLocation] = React.useState('');
    const [projectLocation, setProjectLocation] = React.useState('');

    const [buildingId, setBuildingId] = React.useState(DEFAULT_ID);

    const [backButtonState, setBackButtonState] = React.useState(false);
    const [appBarColorState, setAppBarColorState] = React.useState(ToolbarColor.Primary);

    const [dashboardHeader, setDashboardHeader] = React.useState(t('DASHBOARD.ALL_ORGANIZATION'));

    const selectedOrganization = useSelectedOrganizationData();
    const selectedCustomer = useSelectedCustomerData();
    const [invitationCount, setInvitationCount] = useState(0);
    const [userActionType, setUserActionType] = useState(0);
    const [invitationRequestCount, setInvitationRequestCount] = useState(0);
    const [updateRequestInvitationCount, setUpdateRequestInvitationCount] = useState(0);
    const useCustomer = useCustomerList();
    const useOrganization = useOrganizationList();
    const currentAppRoute = useCurrentAppRoute();
    const fromDate = new Date().toISOString();
    const toDate = new Date(addDaysToDate(new Date(), 30)).toISOString();


    const isLoadProjectSelectedOnUI = (selectedProjectId: string): boolean => {
        return projectId !== selectedProjectId;
    };

    const isLoadCustomerSelectedOnUI = (selectedCustomerId: string): boolean => {
        return selectedId !== selectedCustomerId;
    };
    const routeTo = useCallback(
        (destinationAppRoute: AppRoute) => {
            if ('locationId' in currentAppRoute) {
                pushAppRoute(history, addLocationId(destinationAppRoute, currentAppRoute.locationId));
            } else {
                pushAppRoute(history, destinationAppRoute);
            }
        },
        [history, currentAppRoute]
    );

    useEffect(() => {
        if (usertype === USER_ROLE_TYPE.PAAS_SERVICE_MANAGER)
            routeTo({ type: 'ManageUsers' });
        dispatch(UserSettingsActions.deleteUserUnmounted());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const buildingTabStatus = window.sessionStorage.getItem(SM_BUILDING_TAB);

    useEffect(() => {
        if (buildingTabStatus === TAB_BUILDING_SUMMARY && searchBuildingQuery !== '') {
            setSearchBuildingQuery('');
        }
    }, [buildingTabStatus]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if ((usertype === USER_ROLE_TYPE.SERVICE_MANAGER || usertype === USER_ROLE_TYPE.TECHNICIAN) && dashboard.dashboardTabSelection === TAB_CUSTOMER) {
            if (isRecordFound(selectedCustomer) && isLoadCustomerSelectedOnUI(selectedCustomer.value.id)) {
                setSelectedId(selectedCustomer.value.id);
                setName(selectedCustomer.value.name);
                setCustomerNumber(selectedCustomer.value.customerNumber);
                setCustomerLocation(selectedCustomer.value.address);
                setDashboardHeader(selectedCustomer.value.name);
                setDashboardCustomerView(ALL_ORGANIZATION_API_ID, selectedCustomer.value.name, useCustomer.isTabClicked ? ALL_CUSTOMER_API_ID : selectedCustomer.value.id);
                setSearchBuildingQuery('');

            } else if (!isRecordFound(selectedCustomer) && isLoadCustomerSelectedOnUI(ALL_CUSTOMER_API_ID)) {
                const custId = sessionStorage.getItem(SELECTED_CUSTOMER);
                if (custId === ALL_CUSTOMER_ID) {
                    setSelectedId(ALL_CUSTOMER_API_ID);
                    setName(t('DASHBOARD.ALL_CUSTOMER'));
                    setCustomerNumber('');
                    setCustomerLocation(t('DASHBOARD.ALL_CUSTOMER'));
                    setDashboardHeader(t('DASHBOARD.ALL_CUSTOMER'));
                    setDashboardCustomerView(ALL_CUSTOMER_API_ID, t('DASHBOARD.ALL_CUSTOMER'));
                    setSearchBuildingQuery('');
                }
            }
        }

        else {
            setCustomerNumber('');
            if (isRecordFound(selectedOrganization) && isLoadProjectSelectedOnUI(selectedOrganization.value.id)) {
                if ((usertype === USER_ROLE_TYPE.SERVICE_MANAGER || usertype === USER_ROLE_TYPE.TECHNICIAN) && dashboard.dashboardTabSelection === TAB_ORG) {
                    setProjectId(selectedOrganization.value.id);
                    setSelectedId(selectedOrganization.value.id);
                    setName(selectedOrganization.value.name);
                    setCustomerLocation(selectedOrganization.value.address);
                    setDashboardCustomerView(ALL_ORGANIZATION_API_ID, selectedOrganization.value.name, useOrganization.isTabClicked ? ALL_ORGANIZATION_API_ID : selectedOrganization.value.id);
                    setDashboardHeader(selectedOrganization.value.name);
                }
                else {
                    setProjectId(selectedOrganization.value.id);
                    setProjectName(selectedOrganization.value.name);
                    setProjectLocation(selectedOrganization.value.address);
                    setDashboardOrganizationView(selectedOrganization.value.id, buildingId, selectedOrganization.value.name);
                    setDashboardHeader(selectedOrganization.value.name);
                    setSearchBuildingQuery('');
                }

            } else if (!isRecordFound(selectedOrganization) && isLoadProjectSelectedOnUI(ALL_ORGANIZATION_API_ID)) {
                const orgId = sessionStorage.getItem(SELECTED_ORGANIZATION);
                if (orgId === ALL_ORGANIZATION_ID) {

                    if ((usertype === USER_ROLE_TYPE.SERVICE_MANAGER || usertype === USER_ROLE_TYPE.TECHNICIAN) && dashboard.dashboardTabSelection === TAB_ORG) {
                        setSelectedId(ALL_ORGANIZATION_API_ID);
                        setProjectId(ALL_ORGANIZATION_API_ID);
                        setName(t('DASHBOARD.ALL_ORGANIZATION'));
                        setCustomerLocation(t('DASHBOARD.ALL_ORGANIZATION'));
                        setDashboardHeader(t('DASHBOARD.ALL_ORGANIZATION'));
                        setDashboardCustomerView(ALL_ORGANIZATION_API_ID, t('DASHBOARD.ALL_ORGANIZATION'));
                        setSearchBuildingQuery('');
                    } else {
                        setProjectId(ALL_ORGANIZATION_API_ID);
                        setProjectName(t('DASHBOARD.ALL_ORGANIZATION'));
                        setProjectLocation(t('DASHBOARD.ALL_ORGANIZATION'));
                        setDashboardHeader(t('DASHBOARD.ALL_ORGANIZATION'));
                        setDashboardOrganizationView(ALL_ORGANIZATION_API_ID, buildingId, t('DASHBOARD.ALL_ORGANIZATION'));
                        setSearchBuildingQuery('');
                    }
                }
            }
        }
    }, [selectedOrganization, selectedCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (usertype === USER_ROLE_TYPE.SERVICE_MANAGER || usertype === USER_ROLE_TYPE.TECHNICIAN) {
            if (dashboard.dashboardTabSelection === TAB_CUSTOMER) {
                if ((selectedCustomer.state !== RecordDataState.Found)) {
                    setName(t('DASHBOARD.ALL_CUSTOMER'));
                    setCustomerLocation(t('DASHBOARD.ALL_CUSTOMER'));
                    setDashboardHeader(t('DASHBOARD.ALL_CUSTOMER'));
                }
            }
            else {
                if ((selectedOrganization.state !== RecordDataState.Found)) {
                    setName(t('DASHBOARD.ALL_ORGANIZATION'));
                    setProjectName(t('DASHBOARD.ALL_ORGANIZATION'));
                    setProjectLocation(t('DASHBOARD.ALL_ORGANIZATION'));
                    setDashboardHeader(t('DASHBOARD.ALL_ORGANIZATION'));
                }
            }
            if (history.action !== "PUSH") {
                dispatch(DashboardThunks.getBuildingList('', '', ''));
                dispatch(Thunks.Dashboard.getStatisticSummaryDashboard('', '', '', '', ''));
                dispatch(Thunks.Logbook.getLatestLogs('', buildingId, (usertype === USER_ROLE_TYPE.SERVICE_MANAGER || usertype === USER_ROLE_TYPE.TECHNICIAN) ? RECORD_PER_PAGE_LIMIT_CUSTOMER_ALARM.toString() : RECORD_PER_PAGE_LIMIT.toString(), DEFAULT_PAGE_ONE.toString(), '', '', '', '', (usertype === USER_ROLE_TYPE.SERVICE_MANAGER || usertype === USER_ROLE_TYPE.TECHNICIAN) ? ([LOGBOOK_SEVERITY_STATUS.STATUS_WARNING.toString(), LOGBOOK_SEVERITY_STATUS.STATUS_ERROR.toString(), LOGBOOK_SEVERITY_STATUS.STATUS_BLOCK.toString()]) : [], true, '', (usertype === USER_ROLE_TYPE.SERVICE_MANAGER || usertype === USER_ROLE_TYPE.TECHNICIAN), ''));
                dispatch(Thunks.UserManagement.getMaintenanceDetail('', '', '', '', '', '', '', buildingId, DEFAULT_PAGE_ONE.toString(), RECORD_PER_PAGE_LIMIT_CUSTOMER_ALARM.toString(), fromDate, toDate));
            }
        }
        else {
            dispatch(DashboardThunks.getBuildingList(projectId, '', ''));
            dispatch(Thunks.Logbook.getLatestLogs(projectId, buildingId, RECORD_PER_PAGE_LIMIT.toString(), DEFAULT_PAGE_ONE.toString()));
            dispatch(Thunks.Dashboard.getStatisticSummaryDashboard('', projectId, buildingId));
            dispatch(UsersThunks.adminApprovalHistory());
        }
    }, [dashboard.dashboardTabSelection]);// eslint-disable-line react-hooks/exhaustive-deps

    const refreshDashboard = (projectId: string, buildingId: string) => {
        setBuildingId(buildingId);
        if ((usertype === USER_ROLE_TYPE.SERVICE_MANAGER || usertype === USER_ROLE_TYPE.TECHNICIAN) && dashboard.dashboardTabSelection === TAB_CUSTOMER) {
            dispatch(Thunks.Customer.getDashboardCustomerList());
            dispatch(DashboardThunks.getBuildingList('', '', searchBuildingQuery, selectedId));
            dispatch(Thunks.Dashboard.getStatisticSummaryDashboard('', '', '', '', selectedId));
            dispatch(Thunks.Logbook.getLatestLogs(projectId, buildingId, RECORD_PER_PAGE_LIMIT_CUSTOMER_ALARM.toString(), DEFAULT_PAGE_ONE.toString(), '', '', '', '', [LOGBOOK_SEVERITY_STATUS.STATUS_WARNING.toString(), LOGBOOK_SEVERITY_STATUS.STATUS_ERROR.toString(), LOGBOOK_SEVERITY_STATUS.STATUS_BLOCK.toString()], true, '', (usertype === USER_ROLE_TYPE.SERVICE_MANAGER || usertype === USER_ROLE_TYPE.TECHNICIAN), selectedId));
            dispatch(Thunks.UserManagement.getMaintenanceDetail('', '', '', '', selectedId, '', '', buildingId, DEFAULT_PAGE_ONE.toString(), RECORD_PER_PAGE_LIMIT_CUSTOMER_ALARM.toString(), fromDate, toDate));
        }
        else if ((usertype === USER_ROLE_TYPE.SERVICE_MANAGER || usertype === USER_ROLE_TYPE.TECHNICIAN) && dashboard.dashboardTabSelection === TAB_ORG) {
            dispatch(Thunks.Organization.getServiceManagerOrganizationList(projectId));
            dispatch(DashboardThunks.getBuildingList(selectedId ? selectedId : '', '', searchBuildingQuery));
            dispatch(Thunks.Dashboard.getStatisticSummaryDashboard('', selectedId ? selectedId : '', '', ''));
            dispatch(Thunks.Logbook.getLatestLogs(selectedId ? selectedId : '', buildingId, RECORD_PER_PAGE_LIMIT_CUSTOMER_ALARM.toString(), DEFAULT_PAGE_ONE.toString(), '', '', '', '', [LOGBOOK_SEVERITY_STATUS.STATUS_WARNING.toString(), LOGBOOK_SEVERITY_STATUS.STATUS_ERROR.toString(), LOGBOOK_SEVERITY_STATUS.STATUS_BLOCK.toString()], true, '', (usertype === USER_ROLE_TYPE.SERVICE_MANAGER || usertype === USER_ROLE_TYPE.TECHNICIAN), ''));
            dispatch(Thunks.UserManagement.getMaintenanceDetail(selectedId ? selectedId : '', '', '', '', '', '', '', buildingId, DEFAULT_PAGE_ONE.toString(), RECORD_PER_PAGE_LIMIT_CUSTOMER_ALARM.toString(), fromDate, toDate));
        } else {
            dispatch(DashboardThunks.getBuildingList(projectId, '', searchBuildingQuery));
            dispatch(Thunks.Logbook.getLatestLogs(projectId, buildingId, RECORD_PER_PAGE_LIMIT.toString(), DEFAULT_PAGE_ONE.toString()));
            dispatch(Thunks.Dashboard.getStatisticSummaryDashboard('', projectId, buildingId));
        }
        if (projectId !== ALL_ORGANIZATION_API_ID) {
            dispatch(Thunks.Users.getOrgUserList(projectId, ENTITY_TYPE.ORGANIZATION.toString()));
        }
    };

    const updateDashboard = (projectId: string, buildingId: string, selectedId?: string) => {
        setBuildingId(buildingId);
        if ((usertype === USER_ROLE_TYPE.SERVICE_MANAGER || usertype === USER_ROLE_TYPE.TECHNICIAN) && dashboard.dashboardTabSelection === TAB_CUSTOMER) {
            dispatch(Thunks.Customer.getDashboardCustomerList());
            dispatch(DashboardThunks.getBuildingList('', '', '', selectedId));
            dispatch(Thunks.Dashboard.getStatisticSummaryDashboard('', '', '', '', selectedId));
            dispatch(Thunks.Logbook.getLatestLogs(projectId, buildingId, RECORD_PER_PAGE_LIMIT_CUSTOMER_ALARM.toString(), DEFAULT_PAGE_ONE.toString(), '', '', '', '', [LOGBOOK_SEVERITY_STATUS.STATUS_WARNING.toString(), LOGBOOK_SEVERITY_STATUS.STATUS_ERROR.toString(), LOGBOOK_SEVERITY_STATUS.STATUS_BLOCK.toString()], true, '', (usertype === USER_ROLE_TYPE.SERVICE_MANAGER || usertype === USER_ROLE_TYPE.TECHNICIAN), selectedId));
            dispatch(Thunks.UserManagement.getMaintenanceDetail('', '', '', '', selectedId, '', '', buildingId, DEFAULT_PAGE_ONE.toString(), RECORD_PER_PAGE_LIMIT_CUSTOMER_ALARM.toString(), fromDate, toDate));
        }
        else if ((usertype === USER_ROLE_TYPE.SERVICE_MANAGER || usertype === USER_ROLE_TYPE.TECHNICIAN) && dashboard.dashboardTabSelection === TAB_ORG) {
            dispatch(Thunks.Organization.getServiceManagerOrganizationList(selectedId ? selectedId : ''));
            dispatch(DashboardThunks.getBuildingList(selectedId ? selectedId : '', '', ''));
            dispatch(Thunks.Dashboard.getStatisticSummaryDashboard('', selectedId ? selectedId : '', '', ''));
            dispatch(Thunks.Logbook.getLatestLogs(selectedId ? selectedId : '', buildingId, RECORD_PER_PAGE_LIMIT_CUSTOMER_ALARM.toString(), DEFAULT_PAGE_ONE.toString(), '', '', '', '', [LOGBOOK_SEVERITY_STATUS.STATUS_WARNING.toString(), LOGBOOK_SEVERITY_STATUS.STATUS_ERROR.toString(), LOGBOOK_SEVERITY_STATUS.STATUS_BLOCK.toString()], true, '', (usertype === USER_ROLE_TYPE.SERVICE_MANAGER || usertype === USER_ROLE_TYPE.TECHNICIAN), ''));
            dispatch(Thunks.UserManagement.getMaintenanceDetail(selectedId ? selectedId : '', '', '', '', '', '', '', buildingId, DEFAULT_PAGE_ONE.toString(), RECORD_PER_PAGE_LIMIT_CUSTOMER_ALARM.toString(), fromDate, toDate));
        } else {
            dispatch(DashboardThunks.getBuildingList(projectId, '', ''));
            dispatch(Thunks.Logbook.getLatestLogs(projectId, buildingId, RECORD_PER_PAGE_LIMIT.toString(), DEFAULT_PAGE_ONE.toString()));
            dispatch(Thunks.Dashboard.getStatisticSummaryDashboard('', projectId, buildingId));
        }
        dispatch(UsersThunks.adminApprovalHistory());
        //ToDo - remove API call on approval implementation
        //dispatch(UsersThunks.userInvitationHistory([INVITE_STATUS.INVITED]));
        if (projectId !== ALL_ORGANIZATION_API_ID) {
            dispatch(Thunks.Users.getOrgUserList(projectId, ENTITY_TYPE.ORGANIZATION.toString()));
        }
    };

    //ToDo - handle project not found UI error
    /*if (!isRecordFound(selectedOrganization)) {
        return (
            <>
                <ContentContainer>
                    <RecordDataIndicators
                        recordData={selectedOrganization}
                        errorMessage={t('DASHBOARD.ERROR_LOADING_ORGANIZATION')}
                        notFoundIcon="Business"
                        notFoundMessage={t('DASHBOARD.ORGANIZATION_NOT_FOUND')}
                    />
                </ContentContainer>
            </>
        );
    }*/

    const setDashboardOrganizationView = (projectId: string, buildingId: string, projectName: string): void => {
        setBackButtonState(false);
        setBuildingId(DEFAULT_ID);
        setDashboardHeader(projectName);
        setAppBarColorState(ToolbarColor.Primary);
        setBuildingId(DEFAULT_ID);
        updateDashboard(projectId, DEFAULT_ID);
    };
    const setDashboardCustomerView = (projectId: string, customerName: string, selectedId?: string): void => {
        setBackButtonState(false);
        setBuildingId(DEFAULT_ID);
        setDashboardHeader(customerName);
        setAppBarColorState(ToolbarColor.Primary);
        setBuildingId(DEFAULT_ID);
        updateDashboard(DEFAULT_ID, DEFAULT_ID, selectedId);
    };

    const NavigateToBuildingDetails = (buildingId: string, projectId: string): void => {
        pushAppRoute(
            history,
            { type: 'OverviewBuildingDetails' },
            { backRoute: 'Dashboard', buildingId: buildingId, projectId: projectId, customerID: customerNumber ? selectedId : '' }
        );
    };

    const NavigateToUserDetails = (): void => {
        if ((usertype === USER_ROLE_TYPE.SERVICE_MANAGER || usertype === USER_ROLE_TYPE.TECHNICIAN) && dashboard.dashboardTabSelection === TAB_ORG) {
            pushAppRoute(
                history,
                { type: 'ManageAssetUsers', assetId: selectedId },
                { backRoute: 'Dashboard', assetId: selectedId, assetType: ENTITY_TYPE.ORGANIZATION, title: name }
            );
        }
        else {
            pushAppRoute(
                history,
                { type: 'ManageAssetUsers', assetId: projectId },
                { backRoute: 'Dashboard', assetId: projectId, assetType: ENTITY_TYPE.ORGANIZATION, title: projectName }
            );
        }
    };

    const NaviagteToEditOrganization = (): void => {
        pushAppRoute(history,
            { type: 'EditOrganization', orgId: projectId },
            { backRoute: 'OrganizationDetails', orgId: projectId });
    };


    const NavigateToLogbookDetails = (logId: string, systemId: string): void => {
        pushAppRoute(
            history,
            { type: 'LogbookDetails', logId: logId },
            { backRoute: 'Dashboard', logId: logId, systemId: systemId }
        );
    };
    //SM Customer level navigation
    const NavigateToCustomerDetails = (): void => {
        pushAppRoute(
            history,
            { type: 'CustomerDetails' },
            { backRoute: 'Assets', customerNo: customerNumber }
        );
    };

    const NavigateToEditCustomer = (actionType: ActionType): void => {
        pushAppRoute(
            history,
            { type: 'AddCustomerDetails', actionType: actionType },
            { backRoute: 'Assets', customerNo: customerNumber }

        );
    };

    const NavigateToCustomerList = (): void => {
        pushAppRoute(
            history,
            { type: 'ManageUsers' },
            { backRoute: 'Dashboard', orgId: projectId }
        );
        updateUserTabSession(TAB_CUSTOMERS_KEY);
    };

    const NavigateToSLAList = (): void => {
        pushAppRoute(
            history,
            { type: 'Assets' },
            { backRoute: 'ManagerUsers', customerNo: dashboard.dashboardTabSelection === TAB_CUSTOMER ? customerNumber : '', projectID: dashboard.dashboardTabSelection === TAB_ORG ? projectId : '' }
        );
        updateTabSession(TAB_SLA_KEY);
    };
    //--end

    //- Start Maintenance Navigation
    const NavigateToMaintenanceList = (): void => {
        pushAppRoute(history,
            { type: 'Maintenance' },
            { backRoute: 'Dashboard' });
        updateTabSession(TAB_SCHEDULED_KEY);
    };

    const NavigateToMaintenanceDetails = (maintananceId: string): void => {
        pushAppRoute(history,
            { type: 'MaintenanceDetails', maintenanceId: maintananceId },
            { backRoute: 'Maintenance', maintenanceId: maintananceId, sourcePath: 'Maintenance' });
        updateTabSession(TAB_SCHEDULED_KEY);
    };
    //--End

    //SM Navigation Start
    const NavigateToMyOrganization = (): void => {
        pushAppRoute(
            history,
            { type: 'Organizations' }
        );
    };

    const NavigateToOrganizationDetails = (): void => {
        pushAppRoute(
            history,
            { type: 'OrganizationDetails', orgId: selectedId },
            { backRoute: 'Organizations', orgId: selectedId }
        );
    };

    const NavigateToEditOrganizationBySM = (): void => {
        pushAppRoute(history,
            { type: 'EditOrganization', orgId: selectedId },
            { backRoute: 'OrganizationDetails', orgId: selectedId });
    };
    //---end
    const closeInviteHistoryPopUp = (): void => {
        setInviteEmptyState(false);
    };

    const closeInviteApporvalHistoryPopUp = (): void => {
        setInviteApprovalEmptyState(false);
    };

    useEffect(() => {
        if (dashboard.buildingListLoading) {
            setBuildingEmptyState(false);
        }
        else if (!dashboard.buildingListLoading && dashboard.buildingListSuccess) {
            if (dashboard.buildingList.detail.length < 1 && searchBuildingQuery === '') {
                if (usertype !== USER_ROLE_TYPE.SERVICE_MANAGER && usertype !== USER_ROLE_TYPE.TECHNICIAN) {
                    setBuildingEmptyState(dashboard.buildingList.detail.length <= 0)
                }
            }
        }
        else if (!dashboard.buildingListLoading && dashboard.buildingListFail) {
            setBuildingEmptyState(true);
        }
    }, [dashboard.buildingListLoading, dashboard.buildingListSuccess, dashboard.buildingListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (users.inviteUserHistoryLoading) {
            setInviteEmptyState(false);
        }
        else if (!users.inviteUserHistoryLoading && users.inviteUserHistorySuccess) {
            setInvitationCount(users.inviteUserHistory.invited.length)
            if (users.inviteUserHistory.invited.length > 0) {
                setInviteEmptyState(true);
            }
        }
        else if (!users.inviteUserHistoryLoading && users.inviteUserHistoryFail) {
            setInviteEmptyState(false);
        }
    }, [users.inviteUserHistoryLoading, users.inviteUserHistorySuccess, users.inviteUserHistoryFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (users.adminApporvalInviteHistoryLoading) {
            setInviteApprovalEmptyState(false);
        }
        else if (!users.adminApporvalInviteHistoryLoading && users.adminApporvalInviteHistorySuccess) {
            setInvitationRequestCount(users.adminApporvalInviteHistory.length)
            if (users.adminApporvalInviteHistory.length > 0) {
                setInviteApprovalEmptyState(true);
            }
        }
        else if (!users.adminApporvalInviteHistoryLoading && users.adminApporvalInviteHistoryFail) {
            setInviteApprovalEmptyState(false);
            dispatch(UsersThunks.userInvitationHistory([INVITE_STATUS.INVITED]));
        }
    }, [users.adminApporvalInviteHistoryLoading, users.adminApporvalInviteHistorySuccess, users.adminApporvalInviteHistoryFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (hasPageBeenRendered.current) {
            if (!dashboard.buildingListLoading && dashboard.buildingListSuccess) {
                /* Debouncing to override user typing */
                const delayDebounceFn = setTimeout(() => {
                    dispatch(DashboardThunks.getBuildingList(useOrganization.isTabClicked ? ALL_ORGANIZATION_API_ID : projectId, '', searchBuildingQuery, useCustomer.isTabClicked ? ALL_CUSTOMER_API_ID : selectedId));
                }, 3000)
                return () => clearTimeout(delayDebounceFn)
            }
        }
        hasPageBeenRendered.current = true;
    }, [searchBuildingQuery, projectId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!users.updateUserInvitationLoading && users.updateUserInvitationSuccess) {
            dispatch(UsersThunks.userInvitationHistory([INVITE_STATUS.INVITED]));

            let failureCount = users.updateUserInvitationResult.failureDetail.length;
            if (invitationCount !== 0) {
                if (users.updateUserInvitationResult.successDetail.length === 1){
                    addToast(t('USER_INVITATION_MANAGEMENT.INVITATION_SUCCESS_MESSAGE', { replace: { inviteStatus: users.updateUserInvitationResult.successDetail[0].acceptStatus === 2 ? t('USER_INVITATION_MANAGEMENT.INVITE_ACCEPTED') : t('USER_INVITATION_MANAGEMENT.INVITE_DENIED') } }), 'success');
                    if(users.updateUserInvitationResult.successDetail[0].acceptStatus === 2){
                        refreshDashboard(projectId, '');
                    }
                }
                else if (users.updateUserInvitationResult.successDetail.length === invitationCount && failureCount > 0)
                    addToast(t('USER_INVITATION_MANAGEMENT.INVITATION_MESSAGE_UPDATE_COUNT', { replace: { updatedInvitationCount: users.updateUserInvitationResult.successDetail.length, rejectedInvitationCount: failureCount, inviteStatus: users.updateUserInvitationResult.successDetail[0].acceptStatus === 2 ? t('USER_INVITATION_MANAGEMENT.INVITE_ACCEPTED') : t('USER_INVITATION_MANAGEMENT.INVITE_DENIED') } }), 'success');
                else if (users.updateUserInvitationResult.successDetail.length > 0)
                    addToast(t('USER_INVITATION_MANAGEMENT.SUCCESS_COUNT', { replace: { updatedInvitationCount: users.updateUserInvitationResult.successDetail.length, inviteStatus: users.updateUserInvitationResult.successDetail[0].acceptStatus === 2 ? t('USER_INVITATION_MANAGEMENT.INVITE_ACCEPTED') : t('USER_INVITATION_MANAGEMENT.INVITE_DENIED') } }), 'success');
                else {
                    //invite count is zero- no alert
                }
            }
            else if (!users.updateUserInvitationLoading && users.updateUserInvitationFail)
                addToast(t('USER_INVITATION_MANAGEMENT.SUCCESS_MESSAGE', 'error'));
        }
    }, [users.updateUserInvitationLoading, users.updateUserInvitationSuccess, users.updateUserInvitationFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!users.updateInvitationRequestLoading && users.updateInvitationRequestSuccess) {
            setUpdateRequestInvitationCount(users.updateInvitationRequestResult.successDetail.length + users.updateInvitationRequestResult.failureDetail.length);
            dispatch(UsersThunks.adminApprovalHistory());

            let failureCount = users.updateInvitationRequestResult.failureDetail.length;

            if (users.updateInvitationRequestResult.successDetail.length === 1)
                addToast(t('USER_INVITATION_MANAGEMENT.INVITATION_SUCCESS_MESSAGE', { replace: { inviteStatus: userActionType === 1 ? t('USER_INVITATION_MANAGEMENT.INVITE_ACCEPTED') : t('USER_INVITATION_MANAGEMENT.INVITE_DENIED') } }), 'success');
            else if (users.updateInvitationRequestResult.successDetail.length === invitationCount && failureCount > 0)
                addToast(t('USER_INVITATION_MANAGEMENT.INVITATION_MESSAGE_UPDATE_COUNT', { replace: { updatedInvitationCount: users.updateInvitationRequestResult.successDetail.length, rejectedInvitationCount: failureCount, inviteStatus: userActionType === 1 ? t('USER_INVITATION_MANAGEMENT.INVITE_ACCEPTED') : t('USER_INVITATION_MANAGEMENT.INVITE_DENIED') } }), 'success');
            else if (users.updateInvitationRequestResult.successDetail.length > 0)
                addToast(t('USER_INVITATION_MANAGEMENT.SUCCESS_COUNT', { replace: { updatedInvitationCount: users.updateInvitationRequestResult.successDetail.length, inviteStatus: userActionType === 1 ? t('USER_INVITATION_MANAGEMENT.INVITE_ACCEPTED') : t('USER_INVITATION_MANAGEMENT.INVITE_DENIED') } }));
        } else if (!users.updateInvitationRequestLoading && users.updateInvitationRequestFail) {
            addToast(t('USER_INVITATION_MANAGEMENT.SUCCESS_MESSAGE', 'success'));
            dispatch(UsersThunks.adminApprovalHistory());
        }
    }, [users.updateInvitationRequestLoading, users.updateInvitationRequestSuccess, users.updateInvitationRequestFail]); // eslint-disable-line react-hooks/exhaustive-deps


    // Dashboard to Assets System navigation
    const viewAllSystemNavigation = (): void => {
        pushAppRoute(
            history,
            { type: 'Assets' },
            { backRoute: 'Dashboard', buildingID: '', projectID: projectId, customerID: customerNumber ? selectedId : '' }
        );
        updateTabSession(TAB_SYSTEM_KEY);
    };

    //Dashboard to Assets System navigation
    const setSelectedElStatus = (value: { elstatus: number }): void => {
        pushAppRoute(
            history,
            { type: 'Assets' },
            { backRoute: 'Dashboard', elstatus: value.elstatus, buildingID: '', projectID: projectId, customerID: customerNumber ? selectedId : '' }
        );
        updateTabSession(TAB_SYSTEM_KEY);
    };

    //Dashboard to Assets Building Navigation
    const navSelectedBuildingStatus = (value: { elstatus: number }): void => {
        pushAppRoute(
            history,
            { type: 'Assets' },
            { backRoute: 'Dashboard', elstatus: value.elstatus, projectID: projectId, customerID: customerNumber ? selectedId : '' }
        );
        updateTabSession(TAB_BUILDING_KEY);
    };

    const viewAllNavigation = (tab: string): void => {
        pushAppRoute(
            history,
            { type: 'Assets' },
            { backRoute: 'Dashboard', projectID: projectId, customerID: customerNumber ? selectedId : '' }
        )
        updateTabSession(tab)
    };

    return (
        <div data-testid='overviewContainer'>
            <GenericToolbar
                title={t('DASHBOARD.DASHBOARD_TITLE')}
                subtitle={dashboardHeader}
                color={appBarColorState}
                showBackButton={backButtonState}
                backButtonOnClick={(): void => setDashboardOrganizationView(projectId, DEFAULT_ID, projectName)}
            />
            {inviteEmptyState && <ActiveInviteDialog show={inviteEmptyState} closeDialog={closeInviteHistoryPopUp} actionType={setUserActionType}></ActiveInviteDialog>}
            {inviteApprovalEmptyState && <InviteRequestDialog show={inviteApprovalEmptyState} closeDialog={closeInviteApporvalHistoryPopUp} actionType={setUserActionType}></InviteRequestDialog>}

            {buildingEmptyState && (usertype !== USER_ROLE_TYPE.SERVICE_MANAGER) && <EmptyState style={{ flex: 1, height: md ? 600 : 'auto' }}
                icon={<Dashboard fontSize={'inherit'} />}
                title={t('ORGANIZATION_MANAGE.ERROR_NO_DEVICE_TITLE')}
                description={t('ORGANIZATION_MANAGE.ERROR_NO_DEVICE_DESCRIPTION')}
                actions={<><Button variant={'contained'} color={'primary'} onClick={() => { }} startIcon={<Add />}>
                    {t('ORGANIZATION_MANAGE.ERROR_ADD_NEW_SYSTEM_ACTION')}
                </Button>

                    <Button style={{ marginLeft: 20, marginTop: md ? 0 : 20 }} variant={'outlined'} color={'primary'} onClick={() => { }}>
                        {t('ORGANIZATION_MANAGE.ERROR_READ_INSTALLATION_MANUALS_ACTION')}
                    </Button>
                </>} placeholder={undefined} />}

            {/* Service Manager View */}
            {!buildingEmptyState && ((usertype === USER_ROLE_TYPE.SERVICE_MANAGER) || (usertype === USER_ROLE_TYPE.TECHNICIAN)) &&

                <div data-testid="dashboard-customer-view">

                    <div style={{ display: 'absolute', width: '100%' }}>
                        <Paper square style={{ backgroundColor: PXBColors.white[400], width: '100%' }}>
                            <Box
                                display="flex"
                                style={{
                                    flexDirection: 'column',
                                    height: vh_250,
                                    alignContent: 'center',
                                }}
                            >
                                <OverviewMap userId={''} projectId={projectId} buildingId={buildingId}
                                    onBuildingNameClicked={(buildingId: string, projectId: string) => NavigateToBuildingDetails(buildingId, projectId)} />

                            </Box>
                            <Box
                                display="flex"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    height: vh_10,
                                    marginTop: vh_10,
                                    width: '100%',
                                    transitionDelay: '0.2s',
                                }}
                            >
                                <Typography
                                    variant="caption"
                                    style={{
                                        width: '92%',
                                        textAlign: 'right',
                                    }}
                                >
                                    {t('COMMON.LAST_RETRIEVED')}
                                </Typography>

                                <Button
                                    style={{
                                        width: 20,
                                        backgroundImage: `url(${SyncButtonImage})`,
                                        backgroundPosition: 'center',
                                        backgroundSize: 'filled',
                                        backgroundRepeat: 'no-repeat',
                                    }}
                                    color="primary"
                                    data-testid="refresh-button"
                                    onClick={() => refreshDashboard(projectId, '')}
                                >
                                    {''}
                                </Button>
                            </Box>

                            <Box
                                display="flex"
                                style={{
                                    flexDirection: md ? 'row' : 'column',
                                    alignItems: 'left',
                                    padding: vh_10,
                                    width: '100%',
                                    height: 'wrap',
                                    display: 'absolute',
                                }}
                            >
                                <Box
                                    display="flex"
                                    style={{
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignContent: 'flex-start',
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        height: 'wrap',
                                        width: md ? '33%' : '',
                                        marginTop: -70,
                                        transitionDelay: '0.2s',
                                        marginBottom: rem_10
                                    }}
                                >
                                    <Box
                                        display="flex"
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            marginBottom: vh_10,
                                            alignContent: 'flex-start',
                                            flexWrap: 'wrap',
                                            width: '100%',
                                        }}
                                        data-testid="customer-profile"
                                    >
                                        <OverviewCardDetails onUserClick={() => NavigateToUserDetails()} onSLAClick={() => NavigateToSLAList()}
                                            selectedId={selectedId} name={name} onCustomerListClick={() => NavigateToCustomerList()}
                                            onCustomerDetailsClick={() => NavigateToCustomerDetails()}
                                            onOrganizationListClick={() => NavigateToMyOrganization()} onOrganizationDetailClick={() => NavigateToOrganizationDetails()}
                                            onEditOrganizationClicked={() => NavigateToEditOrganizationBySM()}
                                            onCustomerEditClick={(actionType: ActionType) => NavigateToEditCustomer(actionType)}
                                        >
                                        </OverviewCardDetails>
                                    </Box>

                                    <Box
                                        display="flex"
                                        style={{
                                            flexDirection: 'row',
                                            justifyContent: 'flex-end',
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            width: '100%',
                                            marginBottom: 'vh_10'
                                        }}
                                        data-testid="customer-device-profile"
                                    >
                                        <OverviewSMBuildingCard projectId={projectId} customerId={selectedId} elStatusClick={setSelectedElStatus} OnSearchTextChanged={(query: string): void => setSearchBuildingQuery(query)}
                                            buildingStatusClick={navSelectedBuildingStatus} viewAllClicked={viewAllNavigation} onBuildingSelected={(building: OverviewSelectedData): void => {
                                                NavigateToBuildingDetails(building.id, building.projectId);
                                            }}></OverviewSMBuildingCard>
                                    </Box>
                                </Box>

                                <Box
                                    display="flex"
                                    style={{
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-center',
                                        marginLeft: vw_10,
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        width: md ? '66%' : '',
                                    }}
                                >
                                    <Box
                                        display="flex"
                                        style={{
                                            flexDirection: 'row',
                                            marginBottom: vh_10,
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            width: '100%',
                                        }}
                                        data-testid="device-logs"
                                    >
                                        <OverviewSystemLogs
                                            OnLogSelected={(logId: string, systemId: string): void => {
                                                NavigateToLogbookDetails(logId, systemId);
                                            }}
                                            userId={''}
                                            buildingId={''}
                                            isDashboard={true}
                                            isLogbook={false}
                                            defaultOpenState={true}
                                            title={t('DASHBOARD.ON_GOING_ALERTS')}
                                            isLatestLogs={true}
                                            isServiceDashboard={(usertype === USER_ROLE_TYPE.SERVICE_MANAGER || usertype === USER_ROLE_TYPE.TECHNICIAN)}
                                        />
                                    </Box>

                                    <Box
                                        display="flex"
                                        style={{
                                            flexDirection: 'row',
                                            justifyContent: 'flex-end',
                                            height: 'wrap',
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            width: '100%',
                                            alignItems: 'center'
                                        }}
                                        data-testid="luminaries-list"
                                    >

                                        {/* Reference for maintanence changes */}
                                        <OverviewMaintenanceServiceDueLogs
                                            OnTicketItemSelected={(maintenanceId: string): void => {
                                                NavigateToMaintenanceDetails(maintenanceId);
                                            }}
                                            OnViewAllClicked={() => NavigateToMaintenanceList()}
                                            userId={''}
                                            buildingId={''}
                                            isDashboard={true}
                                            isService={true}
                                            defaultOpenState={true}
                                            title={t('DASHBOARD.UPCOMING_IN_DAYS')}
                                        />
                                    </Box>
                                </Box>


                            </Box>
                        </Paper>
                    </div>
                </div>

            }

            {/* Organization View */}
            {!buildingEmptyState && (usertype !== USER_ROLE_TYPE.SERVICE_MANAGER && usertype !== USER_ROLE_TYPE.TECHNICIAN) && <Paper square style={{ backgroundColor: '#F5F5F5' }}>
                <Box
                    display="flex"
                    style={{
                        flexDirection: 'column',
                        height: vh_250,
                        alignContent: 'center',
                    }}
                >
                    <OverviewMap userId={''} projectId={projectId} buildingId={buildingId}
                        onBuildingNameClicked={(buildingId: string, projectId: string) => NavigateToBuildingDetails(buildingId, projectId)} />
                </Box>
                {/* Desktop View */}
                {md ?
                    <Box
                        display="flex"
                        style={{
                            flexDirection: 'row',
                            alignItems: 'left',
                            padding: 10,
                        }}
                    >
                        <OverviewBuildingList
                            userId={''}
                            projectId={projectId}
                            projectName={projectName}
                            projectLocation={projectLocation}
                            OnBuildingSelected={(building: OverviewSelectedData): void => {
                                NavigateToBuildingDetails(building.id, building.projectId);
                            }}
                            OnUserSelected={(): void => {
                                NavigateToUserDetails();
                            }}
                            OnEditSelected={(): void => {
                                NaviagteToEditOrganization();
                            }}
                            OnSearchTextChanged={(query: string): void => setSearchBuildingQuery(query)}
                        />

                        <Box
                            display="flex"
                            style={{
                                alignItems: 'center',
                                flexDirection: 'column',
                                justifyContent: 'flex-end',
                                height: '100%',
                                marginLeft: 10,
                                display: 'flex',
                                flexWrap: 'wrap',
                                width: '70%',
                            }}
                        >
                            <Box
                                display="flex"
                                style={{
                                    alignItems: 'right',
                                    justifyContent: 'flex-start',
                                    height: 15,
                                    marginBottom: 10,
                                    display: 'flex',
                                    width: '100%',
                                }}
                            >
                                <Typography
                                    variant="caption"
                                    style={{
                                        width: '92%',
                                        textAlign: 'right',
                                    }}
                                >
                                    {t('COMMON.LAST_RETRIEVED')}
                                </Typography>

                                <Button
                                    style={{
                                        width: 20,
                                        backgroundImage: `url(${SyncButtonImage})`,
                                        backgroundPosition: 'center',
                                        backgroundSize: 'filled',
                                        backgroundRepeat: 'no-repeat',
                                    }}
                                    color="primary"
                                    data-testid="refresh-button"
                                    onClick={() => refreshDashboard(projectId, '')}
                                >
                                    {''}
                                </Button>
                            </Box>

                            <Box
                                display="flex"
                                style={{
                                    alignItems: 'center',
                                    backgroundColor: PXBColors.white[400],
                                    flexDirection: 'row',
                                    height: 'auto',
                                    marginBottom: 10,
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                }}
                                data-testid="overview-status-summary"
                            >
                                <OverviewStatisticSummary userId={''} projectId={''} elStatusClick={setSelectedElStatus} viewAllClick={viewAllSystemNavigation} />
                            </Box>
                            <Box
                                display="flex"
                                style={{
                                    backgroundColor: PXBColors.white[400],
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                    height: 'auto',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                }}
                            >
                                <OverviewSystemLogs
                                    OnLogSelected={(logId: string, systemId: string): void => {
                                        NavigateToLogbookDetails(logId, systemId);
                                    }}
                                    userId={''}
                                    buildingId={''}
                                    isDashboard={true}
                                    isLogbook={false}
                                    defaultOpenState={true}
                                    title={t('DASHBOARD.LATEST_LOGS')}
                                    isLatestLogs={true}
                                />
                            </Box>
                        </Box>
                    </Box>
                    :
                    //Mobile view
                    <div className="flex-container">
                        <Box
                            display="flex"
                            style={{
                                flexDirection: 'column',
                                alignItems: 'left',
                                padding: '1%',
                            }}
                        >
                            <Box
                                display="flex"
                                style={{
                                    order: 3,
                                    backgroundColor: 'transparent',
                                    flexDirection: 'row',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                }}
                            >
                                <OverviewBuildingList
                                    userId={''}
                                    projectId={projectId}
                                    projectName={projectName}
                                    projectLocation={projectLocation}
                                    OnBuildingSelected={(building: OverviewSelectedData): void => {
                                        NavigateToBuildingDetails(building.id, building.projectId);
                                    }}
                                    OnUserSelected={(): void => {
                                        NavigateToUserDetails();
                                    }}
                                    OnEditSelected={(): void => {
                                        NaviagteToEditOrganization();
                                    }}
                                    OnSearchTextChanged={(query: string): void => setSearchBuildingQuery(query)}
                                />
                            </Box>

                            <Box
                                display="flex"
                                style={{
                                    alignItems: 'right',
                                    justifyContent: 'flex-start',
                                    height: 15,
                                    marginBottom: 10,
                                    display: 'flex',
                                    width: '100%',
                                    order: 1,
                                }}
                            >
                                <Typography
                                    variant="caption"
                                    style={{
                                        width: '92%',
                                        textAlign: 'right',
                                    }}
                                >
                                    {t('COMMON.LAST_RETRIEVED')}
                                </Typography>

                                <Button
                                    style={{
                                        width: 20,
                                        backgroundImage: `url(${SyncButtonImage})`,
                                        backgroundPosition: 'center',
                                        backgroundSize: 'filled',
                                        backgroundRepeat: 'no-repeat',
                                    }}
                                    color="primary"
                                    data-testid="refresh-button"
                                    onClick={() => refreshDashboard(projectId, '')}
                                >
                                    {''}
                                </Button>
                            </Box>

                            <Box
                                display="flex"
                                style={{
                                    alignItems: 'center',
                                    backgroundColor: PXBColors.white[400],
                                    flexDirection: 'row',
                                    marginBottom: 50,
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                    order: 2
                                }}
                                data-testid="overview-status-summary"
                            >
                                <OverviewStatisticSummary userId={''} projectId={''} elStatusClick={setSelectedElStatus} viewAllClick={viewAllSystemNavigation} />
                            </Box>

                            <Box
                                display="flex"
                                style={{
                                    backgroundColor: PXBColors.white[400],
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                    marginTop: 10,
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                    order: 4
                                }}
                            >
                                <OverviewSystemLogs
                                    OnLogSelected={(logId: string, systemId: string): void => {
                                        NavigateToLogbookDetails(logId, systemId);
                                    }}
                                    userId={''}
                                    buildingId={''}
                                    isDashboard={true}
                                    isLogbook={false}
                                    defaultOpenState={true}
                                    title={t('DASHBOARD.LATEST_LOGS')}
                                    isLatestLogs={true}
                                />
                            </Box>
                        </Box>
                    </div>
                }
            </Paper>}

        </div>
    );
};
