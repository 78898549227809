// eslint-disable-next-line
import React, { useState } from 'react';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { GenericToolbar } from '../../components/GenericToolbar';
import Box from '@mui/material/Box';
import * as PXBColors from '@brightlayer-ui/colors';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Customers } from './Customers';
import { ServiceManagers } from './ServiceManagers';
import { Technicians } from './Technicians';
import { getUserRoleType, useAuthState } from '../../components/AuthContextProvider/component';
import { USER_ROLE_TYPE } from '../enum/ENUM';

import { PAAS } from './PAAS';
import { ServicePartners } from './ServicePartners';
import { updateTabSession } from '../assets/Assets';
type Props = {
    location?: any;
};
export const TAB_STATE_KEY = 'backState';
export const TAB_TECHNICIANS = 0;
export const TAB_TECHNICIANS_KEY = 'Technicians';
export const TAB_SERVICE_MANAGERS = 1;
export const TAB_SERVICE_MANAGERS_KEY = 'Service Managers';
export const TAB_CUSTOMERS = 2;
export const TAB_CUSTOMERS_KEY = 'Customers';
export const TAB_PAAS = 3;
export const TAB_PAAS_KEY = 'PAAS';
export const getTabState = (): number => {
    const tabState = sessionStorage.getItem(TAB_STATE_KEY);
    switch (tabState) {
        case TAB_TECHNICIANS_KEY:
            return TAB_TECHNICIANS;
        case TAB_SERVICE_MANAGERS_KEY:
            return TAB_SERVICE_MANAGERS;
        case TAB_CUSTOMERS_KEY:
            return TAB_CUSTOMERS;
        case TAB_PAAS_KEY:
            return TAB_PAAS;
        default:
            return TAB_TECHNICIANS;
    }
};

export const updateUserTabSession = (tab: string): void => {
    sessionStorage.setItem(TAB_STATE_KEY, tab);
};

export const ManageUser: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const authState = useAuthState();
    const usertype = getUserRoleType(authState) || '';
    const [value, setValue] = React.useState(getTabState());
    const { orgId } = props.location.state !== undefined ? props.location.state : '';
    const [projectId, setProjectId] = React.useState(orgId);

    useState(() => {
        //*** When technician will come back from customer details to Customers tab ***//
        if (usertype === USER_ROLE_TYPE.TECHNICIAN) {
            setValue(TAB_TECHNICIANS)
        }
    })

    function TabPanel(props: { [x: string]: any; children: any; value: any; index: any }) {
        const { children, value, index, ...other } = props;
        return <div {...other}>{value === index && <Box p={3}>{children}</Box>}</div>;
    }

    updateTabSession(TAB_TECHNICIANS_KEY);

    const handleTabChange = (_event: any, newValue: React.SetStateAction<number>) => {
        setProjectId('');
        setValue(newValue);
    };

    return (
        <>
            <div data-testid="users-container">
                <GenericToolbar title={t('USERS.USERS_TITLE')} />
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <AppBar position="static" style={{ backgroundColor: PXBColors.blue[700] }}>
                        <Tabs indicatorColor='secondary' variant="scrollable"
                            scrollButtons={false} textColor="inherit" value={value} onChange={handleTabChange} sx={{
                                backgroundColor: PXBColors.blue[700],
                                height: 2,
                            }} >
                            {(usertype === USER_ROLE_TYPE.SERVICE_MANAGER) &&

                                <Tab data-testid="tabtechnicians" label={t('USERS.TECHNICIANS')} style={{ textTransform: 'none', marginLeft: 35, color: PXBColors.white[500] }} />
                            }
                            {(usertype === USER_ROLE_TYPE.SERVICE_MANAGER) &&
                                <Tab data-testid="tabservicemanagers" label={t('USERS.SERVICE_MANAGERS')} style={{ textTransform: 'none', marginLeft: 35, color: PXBColors.white[500] }} />
                            }
                            {(usertype === USER_ROLE_TYPE.SERVICE_MANAGER || usertype === USER_ROLE_TYPE.TECHNICIAN) &&
                                <Tab data-testid="tabcustomers" label={t('USERS.CUSTOMERS')} style={{ textTransform: 'none', marginLeft: 35, color: PXBColors.white[500] }} />
                            }
                            {usertype === USER_ROLE_TYPE.PAAS_SERVICE_MANAGER &&
                                <Tab data-testid="tabservicemanagers" label={t('USERS.SERVICE_MANAGERS')} style={{ textTransform: 'none', marginLeft: 35, color: PXBColors.white[500] }} />
                            }
                            {usertype === USER_ROLE_TYPE.PAAS_SERVICE_MANAGER &&
                                <Tab data-testid="tabpaas" label={t('USERS.PAAS')} style={{ textTransform: 'none', marginLeft: 35, color: PXBColors.white[500] }} />
                            }
                            {usertype === USER_ROLE_TYPE.PAAS_SERVICE_MANAGER &&
                                <Tab data-testid="tabcustomers" label={t('USERS.SERVICE_PARTNERS')} style={{ textTransform: 'none', marginLeft: 35, color: PXBColors.white[500] }} />
                            }
                        </Tabs>
                    </AppBar>
                    {usertype === USER_ROLE_TYPE.SERVICE_MANAGER &&
                        <>
                            <TabPanel value={value} index={TAB_TECHNICIANS} data-testid="tabtechnician-container">
                                <Technicians />
                            </TabPanel>
                            <TabPanel value={value} index={TAB_SERVICE_MANAGERS} date-testid='tab-serviceManager-container'><ServiceManagers /></TabPanel>
                            <TabPanel value={value} index={TAB_CUSTOMERS} data-testid="tabcustomers-container">
                                <Customers defaultProject={projectId} />
                            </TabPanel>
                        </>
                    }
                     {usertype === USER_ROLE_TYPE.TECHNICIAN &&
                        <>
                            <TabPanel value={value} index={TAB_TECHNICIANS} data-testid="tabcustomers-container">
                                <Customers defaultProject={projectId} />
                            </TabPanel>
                        </>
                    }
                    {usertype === USER_ROLE_TYPE.PAAS_SERVICE_MANAGER &&
                        <>
                            <TabPanel value={value} index={TAB_TECHNICIANS} data-testid="tabcustomers-container">
                                <ServiceManagers />
                            </TabPanel>
                            <TabPanel value={value} index={TAB_SERVICE_MANAGERS} data-testid="tabcustomers-container">
                                <PAAS />
                            </TabPanel>
                            <TabPanel value={value} index={TAB_CUSTOMERS} data-testid="tabcustomers-container">
                                <ServicePartners />
                            </TabPanel>
                        </>
                    }
                </Box>
            </div >
        </>
    );
};
