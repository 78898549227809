import React, { } from 'react';
import {
    Typography,
} from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import * as PXBColors from '@brightlayer-ui/colors';
import { DeviceState, getDeviceStatusState } from '../enum/ENUM';
import { getControlledText } from '../enum/GenericFormat';
import { TooltipItem } from '../../components/TooltipItem/TooltipItem';

export const MaintainenceListItem: React.FC<React.PropsWithChildren<React.PropsWithChildren<{ previousTitleHeader: string, nextTitleSubheader: string, nextTitle: string, previousTitle: string, icon?: boolean, systemStatus?: number }>>> = (props) => {
    const deviceState: DeviceState = getDeviceStatusState(props.systemStatus ?? 0);
    const { t } = useLanguageLocale();
    return (
        <div >
            <div style={{ display: 'flex', height: 57, justifyContent: 'center', alignItems: 'flex-start', gap: 127 }}>
                <div className='rows' style={{ width: '60%', paddingLeft: 16, paddingTop: 16, alignItems: 'center' }}>
                    <Typography variant="subtitle2" id="previousTitleHeader" data-testid="previousTitleHeaderId" >{props.previousTitle}</Typography>
                    <Typography style={{ fontSize: 12, fontWeight: 400, marginTop: 3 }} id="previousTitleSubHeader" data-testid="previousTitleSubHeaderId" >{(props.previousTitleHeader)}</Typography>
                </div>
                <div className='rows' style={{ width: '30%', paddingTop: 16 }}>
                    <TooltipItem title={props.nextTitle} isTopPlacement={true} lenghtLimit={20} component={
                        <Typography style={{ fontSize: 12, fontWeight: 300 }} id="nextTitleHeader" data-testid="nextTitleHeaderId" >
                            {getControlledText(props.nextTitle, 20)}
                        </Typography>
                    }>
                    </TooltipItem>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'left',
                            marginTop: 3
                        }}
                    >
                        {props.icon && <div style={{ minWidth: 16 }}>
                            <img
                                src={deviceState.statusImage}
                                alt="logo"
                                style={{ display: 'flex', alignItems: 'right' }}
                                id="device-status-icon"
                                data-testid="device-status-icon"
                            />
                        </div>}
                        <Typography
                            id="device-status-button"
                            data-testid="device-status-button"

                            style={{ color: PXBColors.black, textTransform: 'none', marginLeft: 10, fontWeight: 300, fontSize: 12 }} variant="body2">
                            {props.icon ? t(deviceState.statusTitle) :
                                <TooltipItem title={props.nextTitleSubheader} isTopPlacement={true} lenghtLimit={20} component={
                                    <Typography style={{ fontSize: 12, fontWeight: 300 }} id="customerName" data-testid="customerNameId" >
                                        {getControlledText(props.nextTitleSubheader, 20)}
                                    </Typography>
                                }>
                                </TooltipItem>

                            }
                        </Typography>
                    </div>
                </div>

            </div>

        </div>
    );
};
