// eslint-disable-next-line
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { GenericToolbar } from '../../components/GenericToolbar';
import { Button, Card, Dialog, DialogContent, Divider, Snackbar, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { EmptyState, InfoListItem } from '@brightlayer-ui/react-components';
import { Check, ChevronRight, Close } from '@mui/icons-material';
import { COLOR_BLUE_ICON, COLOR_GRAY_ICON } from '../../constants/color-codes';
import { rem_10, vh_10, vh_12, vh_20, vw_10 } from '../../constants/dimentions';
import { MaintenanceInfoCard } from './MaintenanceInfoCard';
import { MaintenanceCustomerDetail } from './MaintenanceCustomerDetail';
import { useDispatch, useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import { MaintenanceDetail, MaintenanceDetailList } from '../../types/maintenance';
import { Actions, Thunks } from '../../actions';
import { defaultMaintenanceListData } from '../../state/usermanagement-state';
import { ToolbarColor } from '../../components/GenericToolbar/GenericToolbar';
import { MaintenanceDocument } from './MaintenanceDocument';
import { Technician } from '@brightlayer-ui/icons-mui';
import { customerData } from '../../state/assets-state';
import { DeviceCustomerDetail } from '../../types/customer-detail';
import { MaintenanceCustomerCard } from './MaintenanceCustomerCard';
import { AssetActions } from '../../actions/actions/asset-actions';
import { UserManagementActions } from '../../actions/actions/usermanagement-actions';
import { getDeviceType, TICKET_STATUS } from '../enum/ENUM';
import { NOTES_LIMIT, RECORD_PER_PAGE_LIMIT } from '../../constants';
import * as PXBColors from '@brightlayer-ui/colors';
import { formatDate } from '../enum/DateTimeFormat';
import { formatTimeShort } from '../../lib/date-time-formatter';
import { UsermanagementThunks } from '../../actions/thunks/user-management-thunks';
import { useToasts } from '../../data/DataProviders/ToastProvider';

type Props = {
    location: any;
};

export const MaintenanceDetails: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    // eslint-disable-next-line
    const { t } = useLanguageLocale();
    const dispatch = useDispatch();
    const onCancel = () => { }
    const { maintenanceId } = props.location.state;
    const user = useSelector(Selectors.Usermanagement.usermanagement);
    const [maintenanceList, setMaintenanceList] = useState<MaintenanceDetailList>([]);
    const [maintenanceDetails, setMaintenanceDetails] = useState<MaintenanceDetail>(defaultMaintenanceListData.detail[0]);
    const [emptyMaintenanceList, setEmptyMaintenanceList] = React.useState(false);
    const [name, setName] = React.useState('');
    const [systemId, setSystemId] = React.useState('');
    const [maintenanceDataLoaded, setMaintenanceDataLoaded] = React.useState(false);
    const asset = useSelector(Selectors.Asset.assets);
    const [deviceCustomerData, setDeviceCustomerData] = useState<DeviceCustomerDetail>(customerData);
    const [isDataLoaded, setIsDataLoaded] = React.useState(true);
    const [openNotesDialog, setOpenNotesDialog] = React.useState(false);
    const [notes, setNotes] = React.useState('');
    const [rowsPerPage, setRowsPerPage] = React.useState(RECORD_PER_PAGE_LIMIT);
    const [offset, setOffset] = React.useState(1);
    const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
    const [alertErrorMessage, setAlertErrorMessage] = useState<string>('');
    const { addToast } = useToasts();

    useEffect(() => {
        dispatch(Actions.UserManagement.maintenanceDetailUnmounted());
        dispatch(AssetActions.deviceCustomerDataUnmounted());
        dispatch(Thunks.UserManagement.getMaintenanceDetail('', maintenanceId));
        dispatch(UserManagementActions.createMaintainanceUnmounted());
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setMaintenanceDetails(maintenanceDetails);
    }, [maintenanceDetails]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // eslint-disable-next-line
        if (!user.maintenanceDetailLoading && user.maintenanceDetailSuccess) {
            setEmptyMaintenanceList(user.maintenanceDetail.detail.length <= 0)
            if (user.maintenanceDetail.detail.length > 0) {
                setMaintenanceList(user.maintenanceDetail.detail);
                setMaintenanceDetails(user.maintenanceDetail.detail[0]);
                setName('"'+user.maintenanceDetail.detail[0].systemName +  ',' + '" '+ getDeviceType(user.maintenanceDetail.detail[0].systemType) +' < ' + user.maintenanceDetail.detail[0].customerName);
                dispatch(Thunks.Asset.getDeviceCustomerData(user.maintenanceDetail.detail[0].systemId));

            } else {
                setMaintenanceList([]);
                setMaintenanceDetails(defaultMaintenanceListData.detail[0])
            }
            setMaintenanceDataLoaded(true);
        } else if (!user.maintenanceDetailLoading && user.maintenanceDetailFail) {
            setMaintenanceList([]);
            setMaintenanceDetails(defaultMaintenanceListData.detail[0])
            setEmptyMaintenanceList(true);

        }
        dispatch(Actions.UserManagement.maintenanceDetailUnmounted())
    }, [user.maintenanceDetailLoading, user.maintenanceDetailSuccess, user.maintenanceDetailFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (asset.deviceCustomerDataLoading) {
            setIsDataLoaded(true);
        }
        else {
            if (!asset.deviceCustomerDataLoading && (asset.deviceCustomerDataSuccess)) {
                setDeviceCustomerData(asset.deviceCustomerData);
                setIsDataLoaded(false);
            } else if (!asset.deviceCustomerDataLoading && asset.deviceCustomerDataFail) {
                setDeviceCustomerData(customerData);
                setIsDataLoaded(false);
            }
        }
        dispatch(AssetActions.deviceCustomerDataUnmounted());
    }, [asset.deviceCustomerDataLoading, asset.deviceCustomerDataSuccess, asset.deviceCustomerDataFail]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleAddNotesResponse = (): void => {
        if (user.addNotesErrorCode !== '') {
            if (user.addNotesErrorCode === '9001')
                setAlertErrorMessage(t('TOAST_MSG.ADD_NOTES_ERROR_CODE_9001'),);
            else if (user.addNotesErrorCode === '9009')
                setAlertErrorMessage(t('TOAST_MSG.ADD_NOTES_ERROR_CODE_9009'));
            else if (user.addNotesErrorCode === '9227')
                setAlertErrorMessage(t('TOAST_MSG.ADD_NOTES_ERROR_CODE_9227'));
            else
                setAlertErrorMessage(t('TOAST_MSG.ADD_NOTES_API_FAILURE'));
        }
    };

    useEffect(() => {
        if (!user.addNotesLoading && user.addNotesSuccess) {
            addToast(t('TOAST_MSG.NOTE_ADD_SUCCESS'), 'success');
            dispatch(Thunks.UserManagement.getMaintenanceDetail('', maintenanceDetails.id, '', '', '', '', '', '', offset.toString(), rowsPerPage.toString()));
        }
        else if (!user.updateMaintenanceLoading && user.updateMaintenanceFail) {
            setDisplayErrorMessage(true);
            handleAddNotesResponse();
        }
        dispatch(UserManagementActions.addNotesUnmounted())
    }, [user.addNotesSuccess, user.addNotesFail, user.addNotesLoading]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleOpenNotesDialog = () => {
        setOpenNotesDialog(true);
    };

    const handleCloseNotesDialog = () => {
        setOpenNotesDialog(false);
        onCancel();
        setNotes('');
    };

    const onNotesSave = (id: string): void => {
        dispatch(UsermanagementThunks.addNotes(
            maintenanceDetails.id,
            notes
        ));
        setOpenNotesDialog(false);
        onCancel();
        setNotes('');
    }

    return (
        <>
            <div data-testid="maintenance-details-container">
                <GenericToolbar
                    title={t('MAINTENANCE_DETAILS.MAINTENANCE_REQUEST')}
                    subtitle={name}
                    disableButton={!asset.deviceCustomerDataLoading && !user.maintenanceDetailLoading}
                    showBackButtonoptional={true}
                    backButtonOnClick={(): void => { history.back() }}
                    color={ToolbarColor.Default}
                />
                <div>
                    <Box
                        display="flex"
                        style={{
                            flexDirection: 'row',
                            alignItems: 'left',
                            padding: vh_20,
                            width: '100%',
                            height: 'wrap',
                            display: 'absolute',
                        }}
                    >
                        <Box
                            display="flex"
                            style={{
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                display: 'flex',
                                flexWrap: 'wrap',
                                marginLeft: 0,
                                transitionDelay: '0.2s',
                                marginBottom: rem_10,
                                width: '40%'
                            }}
                        >
                            <Box
                                display="flex"
                                style={{
                                    flexDirection: 'row',
                                    minHeight: '10%',
                                    marginBottom: vh_10,
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                }}
                                data-testid="maintenance-detail-card"
                            >
                                <MaintenanceInfoCard maintenanceDetail={maintenanceDetails} resolutions={maintenanceDetails.resolutions!}></MaintenanceInfoCard>
                            </Box>
                            <Box
                                display="flex"
                                style={{
                                    flexDirection: 'row',
                                    minHeight: '10%',
                                    marginBottom: vh_10,
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                }}
                                data-testid="maintenance-detail-card"
                            >
                                <MaintenanceCustomerDetail maintenanceDetail={maintenanceDetails}></MaintenanceCustomerDetail>
                            </Box>
                            <Box
                                display="flex"
                                style={{
                                    flexDirection: 'row',
                                    marginBottom: vh_10,
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                }}
                                data-testid="maintenance-detail-card"
                            >
                                <MaintenanceCustomerCard deviceCustomerData={deviceCustomerData} isLoadingstate={isDataLoaded} ></MaintenanceCustomerCard>
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            style={{
                                alignItems: 'center',
                                flexDirection: 'column',
                                justifyContent: 'flex-center',
                                marginLeft: vw_10,
                                display: 'flex',
                                flexWrap: 'wrap',
                                width: '55%',
                            }}
                        >
                            <Box
                                display="flex"
                                style={{
                                    flexDirection: 'row',
                                    marginBottom: vh_10,
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                }}
                                data-testid="maintenance-detail-card"
                            >
                                <Card style={{ height: 'fit-content', flex: '1 1 0px', overflow: 'auto' }}>
                                    <div>
                                        <Typography data-testid="maintenancedetail-support-technician" style={{ fontWeight: 600, fontSize: 14, lineHeight: 3.5, fontStyle: 'normal', color: COLOR_BLUE_ICON, marginLeft: 16 }}  >
                                            {t('MAINTENANCE_DETAILS.SUPPORT_TECHNICIAN')}
                                        </Typography>
                                    </div>
                                    <Divider />
                                    <InfoListItem data-testid='maintenancedetail-techicianname' title={maintenanceDetails.technicianName}
                                        icon={<Technician style={{ color: COLOR_GRAY_ICON }} />}
                                        iconAlign={'center'}
                                        divider={'full'} placeholder={undefined}
                                        rightComponent={<ChevronRight style={{ color: COLOR_GRAY_ICON, marginRight: '5%', marginTop: '5.5%' }} />}
                                    />
                                </Card>
                            </Box>

                            <Box
                                display="flex"
                                style={{
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                    height: 'wrap',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                    alignItems: 'center',
                                    marginBottom: vh_10,
                                }}
                                data-testid="maintenance-detail-card"
                            >
                                <MaintenanceDocument maintenanceDetail={maintenanceDetails.documents || []}></MaintenanceDocument>
                            </Box>
                            <Box
                                display="flex"
                                style={{
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                    height: 'wrap',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                    alignItems: 'center',
                                    marginBottom: vh_10,
                                }}
                                data-testid="maintenance-detail-card"
                            >
                                <Card style={{ height: 'fit-content', flex: '1 1 0px', overflow: 'auto' }}>
                                    <div>
                                        <Typography data-testid="list-item-text" style={{ fontWeight: 600, fontSize: 14, lineHeight: 3.5, fontStyle: 'normal', color: COLOR_BLUE_ICON, marginLeft: 16 }}  >
                                            {t('MAINTENANCE_DETAILS.NOTES')}
                                        </Typography>
                                        <Divider />
                                    </div>
                                    {maintenanceDetails.description !== null ?
                                        <div>
                                            <Typography data-testid="notes" style={{ fontWeight: 400, fontSize: 14, fontStyle: 'normal', marginLeft: 16, marginTop: '2%' }}  >
                                                {maintenanceDetails.description}
                                            </Typography>
                                        </div>
                                        : <Typography data-testid="list-item-text-notes" style={{ fontWeight: 600, fontSize: 20, lineHeight: 3.5, fontStyle: 'normal', color: COLOR_GRAY_ICON, marginLeft: 16, textAlign: 'center' }}  >
                                            {t('MAINTENANCE_DETAILS.NO_NOTES_ADDED')}
                                        </Typography>
                                    }

                                    <div style={{ overflow: 'auto', maxHeight: '450px' }}>
                                        {maintenanceDetails?.notes?.length! > 0 ? maintenanceDetails?.notes?.map((note) => {
                                            return (
                                                <Box
                                                    display="flex"
                                                    style={{
                                                        flexDirection: 'row',
                                                        marginBottom: vh_10,
                                                        display: 'flex',
                                                        flexWrap: 'wrap',
                                                        width: '100%'
                                                    }}
                                                    data-testid="maintenance-notes-card"
                                                >
                                                    <Card style={{ height: 'fit-content', flex: '1 1 0px', overflow: 'auto' }}>
                                                        <div style={{ marginLeft: '2%' }}>
                                                            <Typography variant='h6'>{note.createdBy}</Typography>
                                                            <Typography variant='subtitle2'>{formatTimeShort(note.createdDateTime)}, {formatDate(note.createdDateTime)}</Typography>
                                                        </div>
                                                        <div style={{ marginLeft: '2%', marginBottom: '2%', paddingTop: '2%' }}>
                                                            <Typography variant='body1'>{note.note}</Typography>
                                                        </div>
                                                    </Card>
                                                </Box>
                                            )
                                        }) : <div></div>
                                        }
                                    </div>
                                    <div>
                                        {maintenanceDetails?.notes!?.length < 10 && (maintenanceDetails.ticketStatus === TICKET_STATUS.STATUS_SCHEDULED || maintenanceDetails.ticketStatus === TICKET_STATUS.STATUS_DUE || maintenanceDetails.ticketStatus === TICKET_STATUS.STATUS_EXPIRED) ?
                                            <Button style={{ width: '95%', textTransform: 'none', marginLeft: 16, marginBottom: 10, marginTop: 10 }} id="btnCancel" data-testid="btnCancel1" variant="outlined" color="primary" disabled={false} onClick={handleOpenNotesDialog} >
                                                {t('MAINTENANCE_DETAILS.ADD_NOTES')}
                                            </Button>
                                            :
                                            <Button style={{ width: '95%', textTransform: 'none', marginLeft: 16, marginBottom: 10, marginTop: 10 }} id="btnCancel" data-testid="btnCancel1" variant="outlined" color="primary" disabled={true} onClick={handleOpenNotesDialog} >
                                                {t('MAINTENANCE_DETAILS.ADD_NOTES')}
                                            </Button>
                                        }
                                    </div>
                                </Card>
                                {
                                    displayErrorMessage &&
                                    <Snackbar
                                        open={true}
                                        onClose={() => setDisplayErrorMessage(false)}
                                        autoHideDuration={5000}
                                        title={''}
                                        message={alertErrorMessage}
                                        action={
                                            <Button color="inherit" size="small" onClick={() => setDisplayErrorMessage(false)}>
                                                {t('ACTIONS.OKAY')}
                                            </Button>
                                        }
                                        ContentProps={{ sx: { background: PXBColors.red[500] } }}
                                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                        sx={{ bottom: { xs: 90, sm: 0 } }}

                                        style={{ height: 200, width: 300, flexGrow: 0 }}
                                    />
                                }
                            </Box>
                            <Dialog id='notesDialogBox' fullWidth open={openNotesDialog} onClose={handleCloseNotesDialog} style={{ borderRadius: 4, boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)' }} >
                                <div id='dialogTitleDiv' style={{ marginTop: 10 }}>
                                    <Button id="close-ticket-closeBtn" data-testid="close-ticket-closeBtn" style={{ float: 'left', color: 'rgba(0, 0, 0, 0.54)', backgroundColor: 'white' }} onClick={handleCloseNotesDialog}>
                                        <Close />
                                    </Button>
                                    <Typography variant='subtitle1' style={{ width: 'auto', paddingTop: '0.6%' }}>
                                        {t('MAINTENANCE_DETAILS.ADD_NOTES_TITLE')}
                                    </Typography>
                                </div>
                                <Divider></Divider>
                                <DialogContent id='commentBoxContent' style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 20, paddingBottom: 5 }}>
                                    <TextField
                                        style={{ width: '100%', float: 'left', wordWrap: 'break-word', color: PXBColors.black[500] }}
                                        id="description"
                                        data-testid='description'
                                        variant={'filled'}
                                        label={t('MAINTENANCE_DETAILS.NOTES')}
                                        rows={2}
                                        multiline
                                        fullWidth
                                        value={notes}
                                        onChange={(evt: ChangeEvent<HTMLInputElement>): void => setNotes(evt.target.value)}
                                        inputProps={{ 'data-testid': 'close-ticket-description', maxLength: NOTES_LIMIT }}
                                    />
                                    <Typography style={{ width: 'auto', float: 'right', fontSize: 13, color: PXBColors.gray[500] }}>
                                        {notes?.length}/{NOTES_LIMIT}
                                    </Typography>
                                </DialogContent>
                                <Button
                                    style={{
                                        marginBottom: 10,
                                        marginTop: 15,
                                        width: 'auto',
                                        height: 36,
                                        flexDirection: 'row',
                                        marginRight: 10,
                                        marginLeft: 10,
                                        backgroundColor: PXBColors.blue[500],
                                        color: PXBColors.white[50],
                                    }}
                                    fullWidth
                                    id="cancelButton"
                                    variant="outlined"
                                    color="primary"
                                    data-testid="cancelButton"
                                    onClick={() => onNotesSave(maintenanceDetails.id)}
                                    startIcon={<Check style={{ color: PXBColors.white[500] }} />}>
                                    {t('MAINTENANCE_DETAILS.ADD_NOTES_TITLE')}
                                </Button>
                            </Dialog>
                        </Box>
                    </Box>
                </div>
            </div>
        </>
    );
};
