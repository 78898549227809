// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { GenericToolbar } from '../../components/GenericToolbar';
import { Box, CircularProgress, Paper } from '@mui/material';
import * as PXBColors from '@brightlayer-ui/colors';
import { ToolbarColor } from '../../components/GenericToolbar/GenericToolbar';
import { vh_10, vh_20, rem_10, vh_1000, vw_10, vh_400 } from '../../constants/dimentions';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CustomerInfoCard } from './CustomerInfoCard';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { CustomerContactInfo } from './CustomerContactInfo';
import { useDispatch, useSelector } from 'react-redux';
import { Thunks } from '../../actions';
import { Selectors } from '../../selectors';
import { DeviceCustomerDetail } from '../../types/customer-detail';
import { customerData } from '../../state/assets-state';
import { useToasts } from '../../data/DataProviders/ToastProvider';
import { CustomerSLADetails } from './CustomerSLADetails';
import { AssetActions } from '../../actions/actions/asset-actions';
import { useHistory } from 'react-router-dom';
import { pushAppRoute } from '../../data/domain/route-manager';
import { EmptyState } from '@brightlayer-ui/react-components';

type Props = {
    //TO-DO
    location: any
};

export const CustomerDetails: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const asset = useSelector(Selectors.Asset.assets);
    const [deviceCustomerData, setDeviceCustomerData] = useState<DeviceCustomerDetail>(customerData);
    const theme = useTheme();
    const { t } = useLanguageLocale();
    const { backRoute, customerNo, deviceId, projectId, customerId } = props.location.state;
    const history = useHistory();
    const [dataLoaded, setDataLoaded] = React.useState(false);


    const md = useMediaQuery(theme.breakpoints.up('md'));
    useEffect(() => {
        if (!asset.deviceCustomerDataLoading && asset.deviceCustomerDataSuccess) {
            setDataLoaded(true);
            setDeviceCustomerData(asset.deviceCustomerData);
        } else if (!asset.deviceCustomerDataLoading && asset.deviceCustomerDataFail) {
            setDataLoaded(false);
        }
        dispatch(AssetActions.deviceCustomerDataUnmounted());
    }, [asset.deviceCustomerDataLoading, asset.deviceCustomerDataSuccess, asset.deviceCustomerDataFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        AssetActions.deviceCustomerDataUnmounted();
        if (deviceId || customerNo)
            dispatch(Thunks.Asset.getDeviceCustomerData(deviceId, customerNo));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const NavigateToCustomerList = (): void => {
        pushAppRoute(
            history,
            { type: 'ManageUsers' },
            { backRoute: 'Users', orgId: '' }
        );

    };

    return (
        <div data-testid="customer-details-container">
            <GenericToolbar
                title={deviceCustomerData.companyName}
                subtitle={t('CUSTOMER_DETAILS.CUSTOMER_HEADER_SUBTITLE')}
                showBackButton={true}
                backButtonOnClick={(): void => {
                    backRoute === 'AssetDetails' ? history.go(-2) : backRoute === 'ManageUsers' ? NavigateToCustomerList() : history.goBack();
                }}
                color={ToolbarColor.Default}
            />
            {!dataLoaded && <EmptyState style={{ flex: 1, minHeight: 400, backgroundColor: PXBColors.white[50] }}
                icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}
            {dataLoaded && <div style={{ display: 'absolute', width: '100%' }}>
                <Paper square style={{ backgroundColor: PXBColors.white[400], width: '100%' }}>
                    <Box
                        display="flex"
                        style={{
                            flexDirection: md ? 'row' : 'column',
                            alignItems: 'left',
                            padding: vh_20,
                            width: '100%',
                            height: 'wrap',
                            display: 'absolute',
                        }}
                    >
                        <Box
                            display="flex"
                            style={{
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                minHeight: vh_400,
                                display: 'flex',
                                flexWrap: 'wrap',
                                height: 'wrap',
                                marginLeft: 0,
                                transitionDelay: '0.2s',
                                marginBottom: rem_10,
                                width: '40%'
                            }}
                        >
                            <Box
                                display="flex"
                                style={{
                                    flexDirection: 'row',
                                    minHeight: vh_400,
                                    marginBottom: vh_10,
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                }}
                                data-testid="customer-profile-card"
                            >
                                <CustomerInfoCard deviceId={deviceId} customerInfo={deviceCustomerData} projectId={projectId} customerId={customerId} customerNo={customerNo} />
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            style={{
                                alignItems: 'center',
                                flexDirection: 'column',
                                justifyContent: 'flex-center',
                                minHeight: vh_1000,
                                marginLeft: vw_10,
                                display: 'flex',
                                flexWrap: 'wrap',
                                width: md ? '65%' : '',
                            }}
                        >
                            <Box
                                display="flex"
                                style={{
                                    flexDirection: 'row',
                                    marginBottom: vh_10,
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                }}
                                data-testid="device-sla-details"
                            >
                                <CustomerSLADetails customerInfo={deviceCustomerData}></CustomerSLADetails>
                            </Box>

                            <Box
                                display="flex"
                                style={{
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                    height: 'wrap',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                    alignItems: 'center',
                                    marginBottom: vh_10,
                                }}
                                data-testid="customerPrimaryContactInfo"
                            >
                                <CustomerContactInfo
                                    customerInfo={{
                                        firstName: deviceCustomerData.primaryFirstName,
                                        lastName: deviceCustomerData.primaryLastName,
                                        phone: deviceCustomerData.primaryPhone,
                                        countryCode: deviceCustomerData.primaryCountryCode,
                                        countryCode2: deviceCustomerData.primaryCountryCode2,
                                        phone2: deviceCustomerData.primaryPhone2,
                                        Email: deviceCustomerData.primaryEmail,
                                        ContactType: deviceCustomerData.primaryContactType,
                                        Comments: deviceCustomerData.primaryContactComments ? deviceCustomerData.primaryContactComments : ''
                                    }}
                                    header={t('ORGANIZATION_DETAILS.PRIMARY_CONTACT')} contactPersonHeader={t('CUSTOMER_DETAILS.BUYER_HEADER')}
                                />
                            </Box>
                            <Box
                                display="flex"
                                style={{
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                    height: 'wrap',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                    alignItems: 'center'
                                }}
                                data-testid="customerSecondaryContactInfo"
                            >
                                <CustomerContactInfo
                                    header={t('ORGANIZATION_DETAILS.SECONDARY_CONTACT')} contactPersonHeader={t('CUSTOMER_DETAILS.TECH_LEAD_HEADER')}
                                    customerInfo={{
                                        firstName: deviceCustomerData.secondaryFirstName ? deviceCustomerData.secondaryFirstName : '',
                                        lastName: deviceCustomerData.secondaryLastName ? deviceCustomerData.secondaryLastName : '',
                                        phone: deviceCustomerData.secondaryPhone,
                                        countryCode: deviceCustomerData.secondaryCountryCode,
                                        countryCode2: deviceCustomerData.secondaryCountryCode2,
                                        phone2: deviceCustomerData.secondaryPhone2 ? deviceCustomerData.secondaryPhone2 : '',
                                        Email: deviceCustomerData.secondaryEmail ? deviceCustomerData.secondaryEmail : '',
                                        ContactType: deviceCustomerData.secondaryContactType,
                                        Comments: deviceCustomerData.secondaryContactComments ? deviceCustomerData.secondaryContactComments : ''
                                    }} />
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </div>
            }
        </div>
    );
};
