// eslint-disable-next-line
import React from 'react';
import { IconButton, Paper, List, ListItem, ListItemText, Typography } from '@mui/material';
import { GetApp } from '@mui/icons-material';
import { useHistory, Switch, Route } from 'react-router-dom';
import { GenericToolbar } from '../../components/GenericToolbar';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { ContentContainer } from '../../components/ContentContainer';
import { useDispatch } from 'react-redux';
import { HelpAndFeedback } from '../enum/ENUM';
import { DownloadDocumentThunks } from '../../actions/thunks/download-document-thunks';

export const HelpFeedback: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => {
    const { t } = useLanguageLocale();
    const dispatch = useDispatch();


    return (
        <>
            <GenericToolbar title={t('DRAWER.HELPANDFEEDBACK')} />

            <Typography data-testid="manuals" style={{ fontWeight: 400, fontSize: 34, paddingLeft: '8%', paddingTop: '2%' }}>
                {t('HELPANDFEEDBACK.MANUALS')}
            </Typography>

            <ContentContainer>
                <Paper>
                    <div>
                        <List className="list" disablePadding data-testid="download-document-menu">
                            <ListItem
                                data-testid="download-document"
                                className="selected"
                                divider={true}
                                style={{ height: 72, cursor: 'pointer' }}
                            >
                                <ListItemText
                                    primary={t('HELPANDFEEDBACK.TITLE')}
                                    primaryTypographyProps={{
                                        variant: 'body1',
                                        style: { fontWeight: 600 },
                                    }}
                                ></ListItemText>
                                <IconButton onClick={() => dispatch(DownloadDocumentThunks.downloadDocument(HelpAndFeedback.Installation_Manual))}>
                                    <GetApp id="download-document-icon" data-testid="download-document-icon" />
                                </IconButton>
                            </ListItem>
                        </List>
                    </div>
                </Paper>
            </ContentContainer>


        </>
    );
};