// eslint-disable-next-line
import React, { useEffect } from 'react';
import {
    Typography,
    Card,
    IconButton,
} from '@material-ui/core';
import ListItem from '@mui/material/ListItem';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { Build, Close } from '@mui/icons-material';
import { Divider, Link, useMediaQuery, useTheme, Button } from '@mui/material';
import { ACTION_TYPE_COPY, ACTION_TYPE_NONE } from '../../constants';
import * as PXBColors from '@brightlayer-ui/colors';
import { useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import { GatewayDetailsList } from '../../types/gateway';
import { AssetListItem } from '../../components/AssetListItem/AssetListItem';
import { DeviceState, getDeviceStatusState, getGatewayConnectionType } from '../enum/ENUM';
import * as MuiIcons from '@mui/icons-material';
import RestoreIcon from '@mui/icons-material/Restore';
import { Thunks } from '../../actions';
import { useDispatch } from 'react-redux';
import { SLADataList, SLAListItems } from '../../types/sla';
import { DeviceCustomerDetail, DeviceCustomerDetailData } from '../../types/customer-detail';
import { customerData } from '../../state/assets-state';
import { useToasts } from '../../data/DataProviders/ToastProvider';
import { formatToDate, getFormattedDate, getShortDate } from '../../app/enum/DateTimeFormat';
import { AssetActions } from '../../actions/actions/asset-actions';

type Props = {
    closeClick: Function;
    slaId: string;
    open: Function;
};

export const AssetSLADetails: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const { addToast } = useToasts();
    const dispatch = useDispatch();
    const { t } = useLanguageLocale();
    const asset = useSelector(Selectors.Dashboard.asset);

    const slaDetailsDefault: SLADataList = [{
        sLANumber: '',
        companyName: '',
        customerNumber: '0',
        customerCity: '',
        projectName: '',
        projectId: '0',
        buildingName: '',
        type: 0,
        buildingId: '0',
        systemName: '',
        systemId: '0',
        systemStatus: 0,
        luminariesNotPartOfSLA: 'false',
        expiryDate: '',
        serviceDueDate: '',
        serviceDueStatus: '',
        serviceType: 0,
        isFavorite: '',
        ticketCreatedDate: '',
        technicianName: '',
        technicianId: ''
    }]

    const [slaList, setSlaList] = React.useState<SLADataList>(slaDetailsDefault);
    const [slaDetail, setSlaDetailsState] = React.useState(slaList[0]);
    const [slaCustomerData, setSlaCustomerData] = React.useState<DeviceCustomerDetail>(customerData);

    const formatExpiryDate = (timestamp: any) => {
        const slaExpiryDate = formatToDate(timestamp);
        return slaExpiryDate;
    }


    const luminariesPartOfSLA = (isLuminariesPartOfSLA: string): string => {
        return isLuminariesPartOfSLA ? 'No' : 'Yes';
    }

    useEffect(() => {
        if (!asset.slaListLoading && asset.slaListSuccess) {
            if (asset.slaList.detail.length > 0) {
                setSlaList(asset.slaList.detail);
                setSlaDetailsState(asset.slaList.detail[0]);
            } else {
                setSlaList(slaDetailsDefault);
                setSlaDetailsState(slaDetailsDefault[0]);
            }
        } else if (!asset.slaListLoading && asset.slaListFail) {
            console.log('SLA details loading failed');
        }

        if (props.slaId !== '') {
            const slaDetails = asset?.slaList?.detail?.filter((slaDetail) => slaDetail.sLANumber === props.slaId);
            if (slaDetails.length > 0) {
                setSlaDetailsState(slaDetails[0]);
                if (slaDetails[0].systemId) {
                    dispatch(Thunks.Asset.getDeviceCustomerData(slaDetails[0].systemId));
                }
            } else {
                setSlaDetailsState(slaDetailsDefault[0]);
            }
        }
    }, [asset.slaListLoading, asset.slaListSuccess, asset.slaListFail, props.slaId]); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (!asset.deviceCustomerDataLoading && asset.deviceCustomerDataSuccess) {
            setSlaCustomerData(asset.deviceCustomerData);
        } else if (!asset.deviceCustomerDataLoading && asset.deviceCustomerDataFail) {
            addToast(t('TOAST_MSG.TOAST_ERROR_GET_CUSTOMER_INFO'), 'error');
        }
        dispatch(AssetActions.deviceCustomerDataUnmounted());
    }, [asset.deviceCustomerDataLoading, asset.deviceCustomerDataSuccess, asset.deviceCustomerDataFail]); // eslint-disable-line react-hooks/exhaustive-deps

    function copyToClipboard(valueToCopy: string) {
        const tempInput = document.createElement('input')
        tempInput.value = valueToCopy
        document.body.appendChild(tempInput)
        tempInput.select()
        document.execCommand('copy')
        document.body.removeChild(tempInput)
    }

    const closeSLADetail = (): any => {
        props.closeClick({ isOpen: false })
        props.open(false)
    }

    return (
        <>

            <Card style={{ flex: '1 1 0px', overflow: 'auto' }} data-testid="assets-sla-details-container">
                <ListItem style={{

                }}
                    secondaryAction={
                        <Button id="sla-detail-close" data-testid="sla-detail-close" style={{ top: -8, right: -35, marginLeft: "auto", justifyContent: 'start', color: 'rgba(0, 0, 0, 0.54)', backgroundColor: 'white' }} onClick={(): void => closeSLADetail()}>
                            <Close />
                        </Button>
                    }>
                    <div style={{ paddingTop: '5' }}>
                        <Typography variant="h6" style={{ fontSize: 20, fontWeight: 600 }} id="sla-number" data-testid="sla-number" >{slaDetail.sLANumber}</Typography>
                        <div style={{ display: 'flex' }}>
                            <Typography variant="body2" style={{ fontSize: 15 }} id="sla-numer-title" data-testid="sla-numer-title" >System SLA Number</Typography>
                        </div>
                    </div>
                </ListItem>
                <ListItem style={{
                    width: '100%',
                    paddingTop: '10'
                }}>
                    <div>
                        <Typography variant="body2" style={{ fontSize: 15 }} id="sla-location" data-testid="sla-location" >{slaDetail.projectName} {'>'} {slaDetail.companyName} {'>'} {slaDetail.buildingName}</Typography>

                        <div style={{ display: 'flex' }}>
                            <Typography variant="body2" style={{ fontSize: 15 }} id="sla-systems" data-testid="sla-systems" >
                                <Link underline="hover">
                                    <span>View all Systems in this building</span> </Link>
                            </Typography>
                        </div>
                    </div>
                </ListItem>
                <AssetListItem
                    data-testid="sla-detail-expirydate"
                    key={'sla-detail-expirydate'}
                    subtitle={formatExpiryDate(slaDetail.expiryDate)}
                    title={`SLA Expiration`}
                    imageType={ACTION_TYPE_NONE}
                    divider={false}
                    titleBold={false}
                ></AssetListItem>
                <AssetListItem
                    data-testid="sla-detail-luminariesPartOfSLA"
                    key={'sla-detail-luminariesPartOfSLA'}
                    subtitle={luminariesPartOfSLA(slaDetail.luminariesNotPartOfSLA)}
                    title={`Luminaire Monitoring`}
                    imageType={ACTION_TYPE_NONE}
                    divider={false}
                    titleBold={false}
                ></AssetListItem>
                <Divider />
                <AssetListItem
                    data-testid="sla-detail-maincontact"
                    key={'sla-detail-maincontact'}
                    subtitle={`${slaCustomerData.primaryFirstName} ${slaCustomerData.primaryLastName}`}
                    title={`Main Contact`}
                    imageType={ACTION_TYPE_NONE}
                    divider={false}
                    titleBold={false}
                ></AssetListItem>
                <AssetListItem
                    data-testid="sla-detail-primaryemail"
                    key={'sla-detail-primaryemail'}
                    subtitle={slaCustomerData.primaryEmail ? slaCustomerData.primaryEmail : '--'}
                    title={`Email`}
                    imageType={ACTION_TYPE_COPY}
                    OnItemSelected={(): void =>
                        copyToClipboard(slaCustomerData.primaryEmail ? slaCustomerData.primaryEmail : '')
                    }
                    divider={false}
                    titleBold={false}
                ></AssetListItem>
                <AssetListItem
                    data-testid="sla-detail-primaryphone"
                    key={'sla-detail-primaryphone'}
                    subtitle={`+${slaCustomerData.primaryCountryCode} ${slaCustomerData.primaryPhone ? slaCustomerData.primaryPhone! : '--'}`}
                    title={`Phone`}
                    imageType={ACTION_TYPE_NONE}
                    divider={false}
                    titleBold={false}
                ></AssetListItem>
                <Divider />
                <AssetListItem
                    data-testid="sla-detail-secondarycontact"
                    key={'sla-detail-secondarycontact'}
                    subtitle={`${slaCustomerData.secondaryFirstName} ${slaCustomerData.secondaryLastName}`}
                    title={`Secondary Contact`}
                    imageType={ACTION_TYPE_NONE}
                    divider={false}
                    titleBold={false}
                ></AssetListItem>
                <AssetListItem
                    data-testid="sla-detail-secondaryEmail"
                    key={'sla-detail-secondaryEmail'}
                    subtitle={slaCustomerData.secondaryEmail ? slaCustomerData.secondaryEmail! : '--'}
                    title={`Email`}
                    imageType={ACTION_TYPE_COPY}
                    OnItemSelected={(): void =>
                        copyToClipboard(slaCustomerData.secondaryEmail ? slaCustomerData.secondaryEmail : '')
                    }
                    divider={false}
                    titleBold={false}
                ></AssetListItem>
                <AssetListItem
                    data-testid="sla-detail-secondaryPhone"
                    key={'sla-detail-secondaryPhone'}
                    subtitle={`+${slaCustomerData.secondaryCountryCode} ${slaCustomerData.secondaryPhone ? slaCustomerData.secondaryPhone! : '--'}`}
                    title={`Phone`}
                    imageType={ACTION_TYPE_NONE}
                    divider={false}
                    titleBold={false}
                ></AssetListItem>
                <AssetListItem
                    data-testid="sla-detail-empty"
                    key={'sla-detail-empty'}
                    subtitle={''}
                    title={``}
                    imageType={ACTION_TYPE_NONE}
                    divider={false}
                    titleBold={false}
                ></AssetListItem>
                <Divider />
                <div style={{ width: '100%', paddingBottom: '3%', paddingTop: '3%', paddingLeft: '8%' }}>
                    <div>
                        <Button
                            id="sla-edit-button"
                            data-testid="sla-edit-button"
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '95%',
                                alignContent: 'flex-end',
                                backgroundColor: PXBColors.white[50],
                                textTransform: 'none',
                                paddingRight: '10%'
                            }}

                            variant="outlined"
                            color="primary"
                            onClick={() => { props.open(true) }}
                        >
                            <Build style={{ padding: 3 }} />
                            &nbsp;
                            {md ? 'Edit' : ''}
                        </Button>
                    </div>
                    <div style={{ display: 'flex', paddingTop: '5%' }}>
                        <Button
                            id="sla-maintenance-history-button"
                            data-testid="sla-maintenance-history-button"
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '95%',
                                textAlign: 'center'
                            }}
                            variant="outlined"
                            color="primary"
                        >
                            <RestoreIcon style={{ padding: 3, fontSize: '25' }} />
                            &nbsp;
                            {md ? 'Maintenance History' : ''}
                        </Button>
                    </div>
                </div>

            </Card >

        </>
    );
};
