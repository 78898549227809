//Converted pixcel to vh or vw
export const vh_10 = '1.328vh';
export const vh_12 = '1.365vh';
export const vh_14 = '1.699vh';
export const vh_15 = '1.706vh';
export const vh_16 = '1.941vh';
export const vh_18 = '2.390vh';
export const vh_20 = '2.656vh';
export const vh_25 = '2.844vh';
export const vh_26 = '2.957vh';
export const vh_45 = '4.5vh';
export const vh_52 = '5.915vh';
export const vh_65 = '8.632vh';
export const vh_109 = '10.0vh';
export const vh_166 = '16.58vh';
export const vh_220 = '23.809vh'
export const vh_250 = '26.567vh';
export const vh_300 = '39.840vh';
export const vh_400 = '45.506vh';
export const vh_502 = '50.14985014985015vh';
export const vh_700 = '92.961vh';
export const vh_800 = '106.241vh';
export const vh_900 = '88.01198801198801vh';
export const vh_1000 = '132.802vh';

export const vw_10 = '0.651vw';
export const vw_12 = '0.741vw';
export const vw_14 = '0.8101851851851852vw';
export const vw_15 = '0.96vw';
export const vw_20 = '1.302vw';
export const vw_25 = '1.627vw';
export const vw_80 = '4.62962962962963vw'
export const vw_90 = '5.232558139534884vw';
export const vw_100 = '5.787037037037037vw';
export const vw_110 = '6.3657407407407405vw';
export const vw_120 = '6.944444444444445vw';
export const vw_133 = '7.732558139534884vw';
export const vw_140 = '8.101851851851851vw';
export const vw_250 = '16.276vw';
export const vw_300 = '19.531vw';
export const vw_420 = '24.537037037037038vw';
export const vw_442 = '25.578703703703702vw';
export const vw_530 = '30.787037037037037vw';
export const vw_600 = '34.72222222222222vw'
export const rem_8 = '0.5rem';
export const rem_10 = '0.625rem';
export const rem_12 = '0.75rem';
export const rem_14 = '0.875rem';
export const rem_16 = '1rem';
export const rem_18 = '1.125rem';
