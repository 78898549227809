
export type DocumentDownloadState = {

    downloadDocumentLoading: boolean;
    downloadDocumentSuccess: boolean;
    downloadDocumentData: string;
    downloadDocumentFail: boolean;
    downloadDocumentErrorMessage: string;
};

export const initialDocumentDownloadState: DocumentDownloadState = {

    downloadDocumentLoading: false,
    downloadDocumentSuccess: false,
    downloadDocumentData: '',
    downloadDocumentFail: false,
    downloadDocumentErrorMessage: '',
};
