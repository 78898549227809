// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CircularProgress, IconButton, InputAdornment, Menu, MenuItem, Select, SelectChangeEvent, Snackbar, Table, TableBody, TablePagination, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import * as PXBColors from '@brightlayer-ui/colors';
import { Close, GetApp, Search, Build } from '@mui/icons-material';
import { HeadCell, Order } from '../../types/table';
import { Selectors } from '../../selectors';
import { useSelector } from 'react-redux';
import { EnhancedTableHead } from '../../components/Table/EnhancedTableHead';
import { getComparator, stableSort } from '../../lib/table-helpers';
import { ArrowDropDown } from '@mui/icons-material';
import { Checkbox } from '@mui/material';
import { Thunks } from '../../actions';
import { useDispatch } from 'react-redux';
import { DropDownListComponent } from '../../components/DropDownList/DropDownListComponent';
import { ItemList } from '../../types/logbook-type';
import { BuildingList } from '../../types/building';
import { EmptyState } from '@brightlayer-ui/react-components';
import { CURRENT_PAGE_SESSION, ORGANIZATTION_NAME, RECORD_PER_PAGE_LIMIT } from '../../constants';
import { USER_ROLE_TYPE, deviceStatusEnum, deviceTypeEnum } from '../enum/ENUM';
import { useToasts } from '../../data/DataProviders/ToastProvider';
import * as MuiIcons from '@mui/icons-material';
import { vw_15 } from '../../constants/dimentions';
import { SLADataList, SLAListItems } from '../../types/sla';
import { AssetSLAListItem } from '../../components/AssetListItem/AssetSLAListItem';
import { slaCustomerList } from '../../types/sla-customer-details';
import { AssetsSLACalendar } from './AssetsSLACalendar';
import { AssetSLADetails } from './AssetsSLADetails';
import { AssetAddSLADialog } from './AssetAddSLADialog';
import { useOrganizationList } from '../../data/DataProviders/OrganizationListProvider';
import { EditAssetSLADetails } from './EditAssetSLADetails';
import { CustomerActions } from '../../actions/actions/customer-actions';
import { useAuthState, getUserRoleType } from '../../components/AuthContextProvider';
import { customerListV2Data } from '../../types/customer-detail';
import { selectStyle } from './selectStyle';


type Props = {
    defaultProject: string;
    defaultBuilding: string;
    defaultCustomer: string;
};

export const AssetsSLA: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const DEFAULT_ID: string = '';
    const theme = useTheme();
    const authState = useAuthState();
    const usertype = getUserRoleType(authState) || '';
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState('sLANumber');
    const dashboard = useSelector(Selectors.Dashboard.dashboard);
    const asset = useSelector(Selectors.Dashboard.asset);
    const [buildings, setBuildings] = useState<ItemList>([]);
    const [buildingId, setBuildingId] = React.useState(props.defaultBuilding !== undefined ? props.defaultBuilding : '');
    const [customers, setCustomers] = useState<ItemList>([]);
    const [organizationListData, setOrganizationListData] = React.useState<ItemList>([]);
    const [customerNumber, setCustomerNumber] = React.useState(props.defaultCustomer !== undefined ? props.defaultCustomer : '');
    const [filterBuilding, setFilteredBuilding] = React.useState(props.defaultBuilding !== undefined ? props.defaultBuilding : '');
    const [filterProject, setFilteredProject] = React.useState(props.defaultProject !== undefined ? props.defaultProject : '');
    const [filterCustomerNumber, setFilteredCustomerNumber] = React.useState(props.defaultCustomer !== undefined ? props.defaultCustomer : '');
    const [focusedCustomer, setFocusedCustomer] = React.useState(props.defaultCustomer ? true : false);
    const [searchText, setSearchText] = useState(DEFAULT_ID);
    const [emptySlaList, setEmptySlaList] = React.useState(false);
    const [slaList, setSlaList] = useState<SLADataList>([]);
    const [slaDataLoaded, setSlaDataLoaded] = React.useState(false);
    const [selectedSLA, setSelectedSLA] = React.useState(DEFAULT_ID);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(RECORD_PER_PAGE_LIMIT);
    const [offset, setOffset] = React.useState(1);
    const [isAssetSLACalendarOpen, setAssetSLACalendarOpenState] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [slaDetailsOpenState, setSlaDetailsOpenState] = React.useState(false);
    const [openAddSLADialog, setOpenAddSLADialog] = React.useState(false);
    const useOrganizationDataList = useOrganizationList();
    const [openOrgSelectDialog, setOpenOrgSelectDialog] = React.useState(false);
    const [alertSuccessMessage, setAlertSuccessMessage] = useState<string>('');
    const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false);
    const customer = useSelector(Selectors.Customer.customer);

    const headCells: HeadCell[] = [
        { id: 'sLANumber', label: t('DEVICE_DETAILS.SLA_Number'), hideSorting: false },
        { id: 'companyName', label: t('DEVICE_DETAILS.CUSTOMER'), hideSorting: false },
        { id: 'customerCity', label: t('DEVICE_DETAILS.CITY'), hideSorting: false },
        { id: 'buildingName', label: t('DEVICE_DETAILS.BUILDING'), hideSorting: false },
        { id: 'systemName', label: t('ENTITY_TYPE.SYSTEM'), hideSorting: false },
        { id: 'systemStatus', label: t('DEVICE_DETAILS.STATUS'), hideSorting: false },
        { id: 'expiryDate', label: t('DEVICE_DETAILS.NEXT_SERVICE_DUE'), hideSorting: false },
        { id: 'technician', label: t('DEVICE_DETAILS.TECHNICIAN'), hideSorting: false },
    ];

    const buildingListData = (list: BuildingList): ItemList => {
        return list?.map((data, building) => {
            return {
                id: data.buildingId,
                name: data.name,
            };
        });
    };

    const customerListData = (list: customerListV2Data): ItemList => {
        return list?.map((data, customer) => {
            return {
                id: data.id,
                name: data.companyName,
                number: data.customerNumber
            };
        });
    };

    const getSlaList = (list: SLADataList): SLADataList => {
        return list.map((data, sla) => {
            return (
                {
                    sLANumber: data.sLANumber,
                    companyName: data.companyName,
                    customerNumber: data.customerNumber,
                    customerCity: data.customerCity,
                    projectName: data.projectName,
                    projectId: data.projectId,
                    buildingName: data.buildingName,
                    buildingId: data.buildingId,
                    systemName: data.systemName,
                    type: data.type,
                    systemId: data.systemId,
                    systemStatus: data.systemStatus,
                    luminariesNotPartOfSLA: data.luminariesNotPartOfSLA,
                    expiryDate: data.expiryDate,
                    serviceType: data.serviceType,
                    ticketCreatedDate: data.ticketCreatedDate,
                    technicianName: data.technicianName,
                    technicianId: data.technicianId,
                    serviceDueDate: data.serviceDueDate,
                    serviceDueStatus: data.serviceDueStatus,
                    isFavorite: data.isFavorite
                }
            );
        })
    };

    useEffect(() => {
        window.sessionStorage.setItem(CURRENT_PAGE_SESSION, '0');
        //*** Loading all filter data ***//
        dispatch(Thunks.Dashboard.getBuildingList(filterProject, DEFAULT_ID));
        dispatch(Thunks.Customer.getCustomerListV2(filterProject))
        // eslint-disable-next-line
    }, []);

    //***** Populating building filter data ***//
    useEffect(() => {
        if (!dashboard.buildingListLoading && dashboard.buildingListSuccess) {
            setBuildings(buildingListData(dashboard.buildingList.detail));
        } else if (!dashboard.buildingListLoading && dashboard.buildingListFail) {
            setBuildings([]);
            setBuildingId(DEFAULT_ID);
        }
    }, [dashboard.buildingListLoading, dashboard.buildingListSuccess, dashboard.buildingListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setOrganizationListData(useOrganizationDataList.organizationList);
    }, [useOrganizationDataList, useOrganizationDataList.organizationList]); //eslint-disable-line react-hooks/exhaustive-deps

    //***** Populating customer filter data ***//
    useEffect(() => {
        if (!customer.customerListLoading && customer.customerListSuccess) {
            setCustomers(customerListData(customer.customerList.customerDetailList));
        }
        else if (!customer.customerListLoading && customer.customerListFail) {
            setCustomers([]);
            setCustomerNumber(DEFAULT_ID);
        }
    }, [customer.customerListLoading, customer.customerListSuccess, customer.customerListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(Thunks.Asset.getSLAList(filterProject, filterBuilding, filterCustomerNumber, '', '1', rowsPerPage.toString(), searchText));
    }, [customer.linkCustomerLoading, customer.linkCustomerSuccess, customer.linkCustomerFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (filterBuilding !== '' || filterCustomerNumber !== '') {
            dispatch(Thunks.Asset.getSLAList(filterProject, filterBuilding, filterCustomerNumber, '', '1', rowsPerPage.toString(), searchText));
            setCurrentPage(0);
        } else {
            dispatch(Thunks.Asset.getSLAList(filterProject, filterBuilding, filterCustomerNumber, '', offset.toString(), rowsPerPage.toString(), searchText));
            const currentPageAfterSearchClear: string = window.sessionStorage.getItem(CURRENT_PAGE_SESSION) || '0';
            setCurrentPage(parseInt(currentPageAfterSearchClear));
        }
    }, [filterBuilding, filterCustomerNumber, rowsPerPage, offset, filterProject]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (!asset.slaListLoading && asset.slaListSuccess) {
            setEmptySlaList(asset.slaList.detail.length <= 0)
            if (asset.slaList.detail.length > 0) {
                const slaList = getSlaList(asset.slaList.detail);
                setSlaList(slaList);
                setTotalRecords(asset.slaList.totalRecordCount)
            } else {
                setSlaList([]);
            }
            setSlaDataLoaded(true);
        } else if (!asset.gatewayDetailsListLoading && asset.gatewayDetailsListFail) {
            setSlaList([]);
            setEmptySlaList(true);
        }
    }, [asset.slaListLoading, asset.slaListSuccess, asset.slaListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (slaDataLoaded) {
            /* Debouncing to override user typing */
            const delayDebounceFn = setTimeout(() => {
                if (searchText !== '') {
                    dispatch(Thunks.Asset.getSLAList(filterProject, filterBuilding, filterCustomerNumber, '', '1', rowsPerPage.toString(), searchText));
                    setCurrentPage(0);
                } else {
                    dispatch(Thunks.Asset.getSLAList(filterProject, filterBuilding, filterCustomerNumber, '', offset.toString(), rowsPerPage.toString(), searchText));
                    const currentPageAfterSearchClear: string = window.sessionStorage.getItem(CURRENT_PAGE_SESSION) || '0';
                    setCurrentPage(parseInt(currentPageAfterSearchClear));
                }
            }, 3000)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [searchText]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!customer.linkCustomerLoading && customer.linkCustomerSuccess) {
            setAlertSuccessMessage(t('TOAST_MSG.TOAST_SUCCESS_LINK_CUSTOMER_DIALOG'));
            setDisplaySuccessMessage(true);
        }
        dispatch(CustomerActions.linkCustomerUnmounted());
    }, [customer.linkCustomerLoading, customer.linkCustomerSuccess]); // eslint-disable-line react-hooks/exhaustive-deps


    function downloadSLAReport(): void {
        //ToDo - Download SLA report
    }
    function addNewSLA(): void {
        //ToDo - Add new system
    }

    //**** Search text ***//
    const onChangeSearchText = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || ORGANIZATTION_NAME.test(e.target.value as string))
            setSearchText(e.target.value);
    };

    const resetSearch = (): void => {
        setSearchText(DEFAULT_ID);
        setSelectedSLA(DEFAULT_ID);
    };

    //*** Sorting ***//
    const handleRequestSort = (event: React.MouseEvent<unknown>, property: any): void => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        const sortedValue = stableSort(slaList, getComparator(order, orderBy));
        setSlaList(sortedValue);
    };

    const handleDisplay = (value: {
        SLAData: SLAListItems;
    }): void => {
        setSelectedSLA(value.SLAData.sLANumber);
        setSlaDetailsOpenState(true);
    };

    const handleSLADetailsOpen = (value: {
        isOpen: boolean
    }): void => {
        setSlaDetailsOpenState(value.isOpen);
    };

    //*** Pagination ***//
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setCurrentPage(newPage);
        window.sessionStorage.setItem(CURRENT_PAGE_SESSION, newPage.toString());
        setOffset(newPage + 1);
        setSelectedSLA(DEFAULT_ID);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        window.sessionStorage.setItem(CURRENT_PAGE_SESSION, '0');
        setCurrentPage(0);
        setOffset(1);
        setSelectedSLA(DEFAULT_ID);
    };

    const slaCalendarClose = (): void => {
        setAssetSLACalendarOpenState(false);
    };

    const iconClick = (value: {
        openCalendar: boolean
    }): void => {
        setAssetSLACalendarOpenState(true);
    };

    const openEdit = (open: boolean): void => {
        setOpenOrgSelectDialog(open);
    };

    const orgOnChange = (event: string) => {
        setFilteredProject(event);
        setFilteredCustomerNumber('');
        setFocusedCustomer(false)
        setSlaDetailsOpenState(false);
    };

    const customerOnChange = (event: SelectChangeEvent) => {
        setFilteredCustomerNumber(event.target.value);
        setFocusedCustomer(event.target.value !== '');
        setSlaDetailsOpenState(false);
    };

    return (
        <div id="asset-sla-container" data-testid="asset-sla-container">
            <div data-testid="asset-sla-buttons" style={{ display: 'flex', justifyContent: 'space-between' }}>
                {usertype !== USER_ROLE_TYPE.TECHNICIAN && <Button
                    id="sla-add-button"
                    data-testid="sla-add-button"
                    style={{
                        flexDirection: 'row',
                        backgroundColor: PXBColors.white[50],
                        textTransform: 'none',
                    }}
                    variant="outlined"
                    color="primary"
                    onClick={() => { setOpenAddSLADialog(true) }}
                >
                    {md ? t('ASSETS_DETAILS.ADD_SLA') : <MuiIcons.Add></MuiIcons.Add>}
                </Button>
                }
                {usertype !== USER_ROLE_TYPE.SERVICE_MANAGER && usertype !== USER_ROLE_TYPE.TECHNICIAN && <Button
                    id="sla-schedule-maintenance-button"
                    data-testid="sla-schedule-maintenance-button"
                    style={{
                        flexDirection: 'row',
                        backgroundColor: PXBColors.white[50],
                        textTransform: 'none',
                        marginLeft: vw_15
                    }}
                    variant="outlined"
                    color="primary"
                    onClick={(): void => addNewSLA()}
                >
                    <Build style={{ padding: 3 }} />
                    &nbsp;
                    {md ? t('ASSETS_DETAILS.SCHEDULE_MAINTENANCE') : ''}
                </Button>}
                <div style={{ display: 'flex', marginLeft: 'auto', marginTop: 'auto' }}>
                    <Typography
                        id="sla-favorite-filter-checkbox-lable"
                        data-testid="sla-favorite-filter-checkbox-lable"
                        style={{ alignSelf: 'center' }} variant="body2">
                        {t('ASSETS_DETAILS.SHOW_FAVORITE_SLAS')}
                    </Typography>
                    <Checkbox id="sla-favorite-filter-checkbox"
                    />
                </div>
            </div>

            <div data-testid="asset-sla-search" style={{ display: 'flex', }}>

                <DropDownListComponent disabled={true} data-testid="asset-SLA-filter" list={deviceTypeEnum} value={t('SEARCH.ALL_SLA')} handleSelection={setFilteredBuilding} selected={buildingId} defaultSelection={''}></DropDownListComponent>
                <DropDownListComponent disabled={false} data-testid="asset-organization-filter" list={organizationListData} value={t('SEARCH.ALL_ORGANIZATIONS')} handleSelection={orgOnChange} selected={''} defaultSelection={props.defaultProject}></DropDownListComponent>
                <Select
                    disabled={customer.customerListLoading}
                    id="asset-customer-list-dropdown"
                    data-testid="asset-customer-filter"
                    style={{
                        backgroundColor: focusedCustomer ? PXBColors.lightBlue[50] : PXBColors.white[50],
                        marginRight: '1%',
                        width: 'auto',
                        height: 32,
                        borderBlock: 0,
                        outline: 'none',
                        borderRadius: 32,
                        overflow: 'hidden',
                        fontSize: 14,
                        color: focusedCustomer ? PXBColors.blue[500] : PXBColors.black[500],
                        fontStyle: 'normal',
                        fontWeight: 400,
                        marginTop: 28,
                    }}
                    value={filterCustomerNumber}
                    onChange={customerOnChange}
                    displayEmpty
                >
                    <MenuItem value=""
                        style={
                            selectStyle.dropdown
                        }>{t('SEARCH.ALL_CUSTOMER')}</MenuItem>
                    {customers.map((data, val) => {
                        return (
                            <MenuItem id="customer-list" key={data.id} value={data.number}
                                style={selectStyle.dropdown}>
                                {data.name}
                            </MenuItem>
                        );
                    })}
                </Select>
                <DropDownListComponent disabled={true} data-testid="asset-city-filter" list={deviceTypeEnum} value={t('SEARCH.ALL_CITY')} handleSelection={setFilteredBuilding} selected={buildingId} defaultSelection={''}></DropDownListComponent>
                <DropDownListComponent disabled={true} data-testid="asset-buildings-filter" list={buildings} value={t('SEARCH.ALL_BUILDINGS')} handleSelection={setFilteredBuilding} selected={buildingId} defaultSelection={''}></DropDownListComponent>
                <DropDownListComponent disabled={true} data-testid="asset-zipcode-filter" list={deviceTypeEnum} value={t('SEARCH.ALL_ZIPCODE')} handleSelection={setFilteredBuilding} selected={buildingId} defaultSelection={''}></DropDownListComponent>

                <TextField
                    inputProps={{ 'data-testid': 'search', maxLength: 100 }}
                    value={searchText}
                    style={{
                        width: 'auto',
                        textAlign: 'right',
                        float: 'right',
                        border: '0px solid #727E84',
                        borderRadius: 5,
                        backgroundColor: 'white',
                        height: 32,
                        borderBlock: 0,
                        outline: 'none',
                        overflow: 'hidden',
                        marginTop: 28,
                        fontFamily: 'Open sans',
                        fontSize: 14,
                        color: '#727E84',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        caretColor: PXBColors.blue[500]
                    }}
                    placeholder={'Search'}
                    onChange={(e): void => onChangeSearchText(e)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search data-testid="search-icon" style={{ color: PXBColors.gray[500] }} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                {searchText !== '' && (
                                    <IconButton onClick={resetSearch}>
                                        <Close fontSize="small" />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                />
            </div>

            <Card style={{ flex: '1 1 0px', overflow: 'auto', marginTop: '2%' }} id="sla-list-container" data-testid="sla-list-container">

                <Table stickyHeader size="small"
                    id="sla-list-table"
                    data-testid="sla-list-table">
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={headCells}
                        iconClick={iconClick}
                    />

                    {!emptySlaList && !asset.slaListLoading && <TableBody className="tableHover" style={{ minHeight: 100 }} key={'mainTable'}
                        id="sla-list-table-body"
                        data-testid="sla-list-table-body">
                        {stableSort(slaList, getComparator(order, orderBy)).map((row: SLAListItems, index: number) => (
                            <AssetSLAListItem
                                key={row.sLANumber}
                                row={row}
                                selectedSLA={selectedSLA}
                                displayClick={handleDisplay}
                                displaySLADetailsClick={handleSLADetailsOpen}
                            ></AssetSLAListItem>
                        ))}
                    </TableBody>}

                </Table>

                {asset.slaListLoading && <EmptyState style={{ flex: 1, minHeight: 400, backgroundColor: PXBColors.white[50] }}
                    icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}
                {!asset.slaListLoading && emptySlaList && <EmptyState style={{ flex: 1, height: 200 }}
                    title={t('DEVICE_DETAILS.ERROR_NO_SLA_FOUND')} icon={undefined} placeholder={undefined} />}

            </Card >
            {openAddSLADialog &&
                <AssetAddSLADialog openDialogFlag={openAddSLADialog} closeClick={() => setOpenAddSLADialog(false)} ></AssetAddSLADialog>
            }
            <div style={{ flex: '1 1 0px', backgroundColor: PXBColors.white[50] }}>
                {!emptySlaList && <TablePagination
                    component="div"
                    data-testid="pagination-logbook"
                    count={totalRecords}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t('LABELS.ITEMS_PER_PAGE')}
                    showFirstButton showLastButton
                />}
            </div>

            <Box
                display="flex"
                style={{
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    top: 112,
                    right: 0,
                    zIndex: 100,
                    minHeight: '69%',
                    backgroundColor: PXBColors.white[500],
                    position: 'absolute',
                    visibility: isAssetSLACalendarOpen ? 'visible' : 'hidden',
                }}
                data-testid="sla-calendar"
            >
                <AssetsSLACalendar
                    anchorEl={anchorEl}
                    isCalendarOpen={isAssetSLACalendarOpen}
                    setIsCalendarOpen={setAssetSLACalendarOpenState}
                    setAnchorEl={setAnchorEl}
                />
            </Box>
            {slaDetailsOpenState &&
                <Box
                    display="flex"
                    style={{
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        top: 112,
                        right: 0,
                        zIndex: 100,
                        minHeight: '89%',
                        backgroundColor: PXBColors.white[500],
                        position: 'absolute',
                        visibility: 'visible',
                    }}
                    sx={{ maxWidth: '20%' }}
                    data-testid="sla-details"
                >
                    <AssetSLADetails
                        closeClick={handleSLADetailsOpen} slaId={selectedSLA} open={openEdit} />
                    {openOrgSelectDialog && < EditAssetSLADetails
                        open={openOrgSelectDialog} close={() => setSlaDetailsOpenState(false)} closeClick={() => setOpenOrgSelectDialog(false)} slaId={selectedSLA} />}
                </Box>
            }
            {!slaDetailsOpenState && displaySuccessMessage &&
                <Snackbar
                    open={true}
                    onClose={() => setDisplaySuccessMessage(false)}
                    autoHideDuration={5000}
                    title={''}
                    message={alertSuccessMessage}
                    action={
                        <Button color="inherit" size="small" onClick={() => setDisplaySuccessMessage(false)}>
                            {t('ACTIONS.OKAY')}
                        </Button>
                    }
                    ContentProps={{ sx: { background: PXBColors.black[900] } }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    sx={{ bottom: { xs: 90, sm: 0 } }}
                    style={{ height: 200, width: 200, flexGrow: 0 }}
                />
            }

        </div>
    );
};
