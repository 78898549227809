// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import {
    CardContent,
    Typography,
    Divider,
    Card,
    IconButton,
    CircularProgress,
    Box,
} from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import * as PXBColors from '@brightlayer-ui/colors';
import { AlarmStatus } from '../../lib/alarm-helpers';
import Chip from '@mui/material/Chip';
import ListItem from '@mui/material/ListItem';
import { Button } from '@mui/material';
import BackgroundImageReady from '../../assets/images/device_profile_background_ready.svg';
import BackgroundImageWarning from '../../assets/images/device_profile_background_warning.svg';
import BackgroundImageBlock from '../../assets/images/device_profile_background_block.svg';
import BackgroundImageOffline from '../../assets/images/device_profile_background_offline.svg';
import BackgroundImageOfflineDisable from '../../assets/images/device_profile_background_offline_disable.svg';
import BackgroundImageError from '../../assets/images/device_profile_background_error.svg';
import BackgroundImageInFire from '../../assets/images/device_profile_background_error.svg';
import { Done } from '@mui/icons-material';
import { Close } from '@mui/icons-material';
import { AlarmBadgeStatus } from '../../components/AlarmBadge/AlarmBadge';
import { FilterNone } from '@material-ui/icons';
import { ACTION_TYPE_COPY, ACTION_TYPE_NEXT, ACTION_TYPE_NONE, SEPERATOR_COMMA } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import { DeviceData } from '../../types/device';
import { AssetListItem } from '../../components/AssetListItem/AssetListItem';
import { DeviceState, DEVICE_STATUS, getDeviceStatusState, getDeviceType, DEVICE_TYPE, getFireDeviceStatusState } from '../enum/ENUM';
import { EmptyState } from '@brightlayer-ui/react-components';
import { vh_18, vh_65, vh_900, vw_300 } from '../../constants/dimentions';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { BuildingContactDetails } from './BuildingContactDetails';
import { buildingDetailsDefault } from '../overview/OverviewListComponent';
import { OrgBuildingData } from '../../types/organization';
import { Thunks } from '../../actions';
import EditIcon from '@mui/icons-material/Edit';
import { TooltipItem } from '../../components/TooltipItem/TooltipItem';
import { getControlledText } from '../enum/GenericFormat';
import { compareVersions } from './DeviceGatewayDetails';

type Props = {
    onUserClick: Function;
    deviceId: string;
};

export const DeviceProfileCard: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const organization = useSelector(Selectors.Organization.organization);
    const usersSelector = useSelector(Selectors.Users.users);
    const asset = useSelector(Selectors.Asset.assets);
    const [users, setUsers] = React.useState(0 + t('DEVICE_DETAILS.USERS_ADMINS') + ' ' + 0 + t('DEVICE_DETAILS.USERS_VIEWER'));
    const [isbuildingContactDrawerOpen, setIsbuildingContactDrawerOpen] = React.useState(false);
    const [systemDetail, setSystemDetailState] = React.useState<DeviceData>(systemDetailDefault);
    const [buildingDetails, setBuildingDetails] = useState<OrgBuildingData>(buildingDetailsDefault);
    const [deviceType, setDeviceType] = React.useState('');
    let systemState: DeviceState = {
        statusTitle: '',
        statusColor: '',
        statusImage: ''
    }
    const primaryContact = (buildingDetails.primaryFirstName && buildingDetails.primaryLastName) ? buildingDetails.primaryFirstName + " " + buildingDetails.primaryLastName : " ";

    const updateSubTitle = (adminCount: number, viewerCount: number): void => {
        const adminsText = adminCount > 0 ? (adminCount > 1 ? adminCount + ' ' + t('DASHBOARD.USERS_ADMIN_S') : adminCount + ' ' + t('DASHBOARD.USERS_ADMIN')) : '';
        const seperatorText = viewerCount > 0 ? SEPERATOR_COMMA : '';
        const viewersText = viewerCount > 0 ? (viewerCount > 1 ? viewerCount + ' ' + t('DASHBOARD.USERS_VIEWER_S') : viewerCount + ' ' + t('DASHBOARD.USERS_VIEWER')) : '';
        setUsers(adminsText + seperatorText + viewersText)
    }

    useEffect(() => {
        if (!asset.deviceListLoading && asset.deviceListSuccess) {
            const deviceDetails = asset.deviceList.detail.filter((device) => device.systemId === props.deviceId)[0]
            setSystemDetailState(deviceDetails);
            setDeviceType(getDeviceType(deviceDetails.type));
        } else if (!asset.deviceListLoading && asset.deviceListFail) {
            console.log('Device list loading failed');
        }
    }, [asset.deviceListLoading, asset.deviceListSuccess, asset.deviceListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!usersSelector.userListLoading && usersSelector.userListSuccess) {
            updateSubTitle(usersSelector.userList.AdminCount, usersSelector.userList.viewersCount);
        } else if (!usersSelector.userListLoading && usersSelector.userListFail) {
            console.log('User list loading failed');
        }
    }, [usersSelector.userListLoading, usersSelector.userListSuccess, usersSelector.userListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!organization.buildingListLoading && organization.buildingListSuccess) {
            if (organization.buildingList.length > 0)
                setBuildingDetails(organization.buildingList[0]);
        } else if (!organization.buildingListLoading && organization.buildingListFail) {
            setBuildingDetails(buildingDetailsDefault);
        }
    }, [organization.buildingListLoading, organization.buildingListSuccess, organization.buildingListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    const systemStatus = systemDetail.systemStatus;

    const goToUserDetails = (): void => {
        props.onUserClick();
    };

    const buildingContactDrawerClose = (): void => {
        setIsbuildingContactDrawerOpen(false);
    };

    function copyToClipboard(valueToCopy: string) {
        const tempInput = document.createElement('input')
        tempInput.value = valueToCopy
        document.body.appendChild(tempInput)
        tempInput.select()
        document.execCommand('copy')
        document.body.removeChild(tempInput)
    }

    const getStatusStateBackground = (status: number): any => {
        switch (status) {
            case DEVICE_STATUS.STATUS_READY: return BackgroundImageReady;
            case DEVICE_STATUS.STATUS_WARNING: return BackgroundImageWarning;
            case DEVICE_STATUS.STATUS_BLOCK: return BackgroundImageBlock;
            case DEVICE_STATUS.STATUS_OFFLINE: return BackgroundImageOffline;
            case DEVICE_STATUS.STATUS_OFFLINE_DISABLE: return BackgroundImageOfflineDisable;
            case DEVICE_STATUS.STATUS_ERROR: return BackgroundImageError;
            case DEVICE_STATUS.STATUS_FIRE: return BackgroundImageInFire;
            default: return BackgroundImageOffline;
        }
    };

    if (deviceType === DEVICE_TYPE.DEVICE_TYPE_XDETECT) {
        systemState = getFireDeviceStatusState(systemStatus);
    } else {
        systemState = getDeviceStatusState(systemStatus);
    }

    const systemStateBackgound = getStatusStateBackground(systemStatus);

    const onBuildingContactSelect = (): void => {
        setIsbuildingContactDrawerOpen(true);
    };

    const isUpdateRequired = !systemDetail.firmwareVersion || systemDetail.firmwareVersion === '--' || compareVersions(systemDetail.firmwareVersion, systemDetail.newFirmwareVersion) < 0;

    return (
        <Card style={{ flex: '1 1 0px', overflow: 'auto' }} data-testid="device-profile-card-container">
            {asset.deviceListLoading && <EmptyState style={{ flex: 1, maxHeight: 400, backgroundColor: PXBColors.white[50] }}
                icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}
            {!asset.deviceListLoading && <>
                <ListItem style={{
                    width: '100%',
                    backgroundImage: `url(${systemStateBackgound})`,
                    backgroundSize: "100%"
                }}
                //ToDo- Edit icon not avail for web
                // secondaryAction={
                //     <IconButton disabled={!systemDetail.isAdmin} id="device-edit" data-testid="device-edit" style={{ top: -50, right: -10, marginLeft: "auto", justifyContent: 'start' ,paddingRight: 16}} onClick={(): void => editSystemProfile()}>
                //         <EditIcon />
                //     </IconButton>
                // }
                >

                    <CardContent style={{ width: '100%' }}>
                        <Typography variant="overline" >{t('DEVICE_DETAILS.SYSTEM')}</Typography>
                        <TooltipItem
                            title={systemDetail.systemName}
                            isTopPlacement={true}
                            lenghtLimit={28}
                            component={
                                <Typography variant="h6" id="device-name" data-testid="device-name" >{getControlledText(systemDetail.systemName, 28)}</Typography>
                            }></TooltipItem>
                        <Typography variant="body2" id="device-sublocation" >{systemDetail.subLocation ? systemDetail.subLocation : ''}</Typography>
                        <Typography variant="body2" style={{ fontStyle: 'italic' }} id="device-description" >{systemDetail.systemDescription}</Typography>
                        <Button variant="outlined"
                            id="device-status-button"
                            disabled
                            style={{ marginTop: 20, borderRadius: 30, borderColor: systemState.statusColor, fontSize: 14, color: '#424E54', fontWeight: 600, textTransform: 'none' }}
                            startIcon={<img
                                src={systemState.statusImage}
                                alt="logo"
                                style={{ display: 'flex', alignItems: 'right' }}
                                id="list-item-status-icon"
                            />}>
                            {t(systemState.statusTitle)}
                        </Button>
                    </CardContent>
                </ListItem>

                <Divider />

                <ListItem style={{
                    width: '100%',
                    backgroundSize: "100%"
                }}>
                    {deviceType === DEVICE_TYPE.DEVICE_TYPE_XDETECT &&
                        <CardContent style={{ width: '100%' }}>
                            <Typography style={{ fontWeight: 'bold' }} >{systemDetail.errorCount}</Typography>
                            <TooltipItem
                                title={t('DEVICE_DETAILS.ERROR_COUNT')}
                                isTopPlacement={true}
                                lenghtLimit={28}
                                component={
                                    <Typography variant="body2" id="device-name" data-testid="device-name" >{t('DEVICE_DETAILS.ERROR_COUNT')}</Typography>
                                }></TooltipItem>
                            <br></br>
                            <Typography style={{ fontWeight: 'bold' }} id="device-sublocation" >{'This is error detail'}</Typography>
                            <TooltipItem
                                title={systemDetail.systemName}
                                isTopPlacement={true}
                                lenghtLimit={28}
                                component={
                                    <Typography variant="body2" id="device-name" data-testid="device-name" >{t('DEVICE_DETAILS.ERROR_DETAILS')}</Typography>
                                }></TooltipItem>
                        </CardContent>
                    }
                </ListItem>

                <div style={{ margin: 16 }}>
                    {deviceType !== DEVICE_TYPE.DEVICE_TYPE_XDETECT &&
                        <DeviceTest
                            header={t('DEVICE_DETAILS.DURATION_TEST') + '(' + (systemDetail.testDuration ? systemDetail.testDuration : '--') + ')'}
                            previousDate={systemDetail.durationTestPrevious ? systemDetail.durationTestPrevious : '--'}
                            nextDate={systemDetail.durationTestNext ? systemDetail.durationTestNext : '--'}
                            testStatus={systemDetail.durationTestStatus}
                            testDescription={systemDetail.durationTestStatus ? t('DEVICE_DETAILS.DURATION_TEST_FAILED') : t('DEVICE_DETAILS.DURATION_TEST_PASSED')}
                            nextTitle={t('DEVICE_DETAILS.NEXT')}
                            previousTitle={t('DEVICE_DETAILS.PREVIOUS')}
                        />
                    }
                    <div style={{ marginTop: 16 }}></div>
                    {deviceType !== DEVICE_TYPE.DEVICE_TYPE_XDETECT &&
                        <DeviceTest
                            header={t('DEVICE_DETAILS.FUNCTIONAL_TEST')}
                            previousDate={systemDetail.functionalTestPrevious ? systemDetail.functionalTestPrevious : '--'}
                            nextDate={systemDetail.functionalTestNext ? systemDetail.functionalTestNext : '--'}
                            testStatus={systemDetail.functionalTestStatus}
                            testDescription={systemDetail.functionalTestStatus ? t('DEVICE_DETAILS.FUNCTIONAL_TEST_FAILED') : t('DEVICE_DETAILS.FUNCTIONAL_TEST_PASSED')}
                            nextTitle={t('DEVICE_DETAILS.NEXT')}
                            previousTitle={t('DEVICE_DETAILS.PREVIOUS')}
                        />
                    }
                </div>

                <Divider />


                <ListItem style={{
                    display: 'flex', justifyContent: 'flex-end', height: 'wrap', width: '100%'
                }}
                    secondaryAction={
                        <IconButton id="device-firmware-copy-action" style={{ right: -10, marginLeft: "auto", justifyContent: 'start' }} onClick={(): void => copyToClipboard(systemDetail.firmwareVersion != null || systemDetail.firmwareVersion != undefined ? systemDetail.firmwareVersion + '' : '')}>
                            <FilterNone />
                        </IconButton>
                    }>

                    <div style={{ flex: '1 1 0px', overflow: 'auto', alignItems: 'left', width: '100%' }}>

                        <Typography variant="body2" style={{ fontSize: 14, fontWeight: 600 }} id="device-firmware-version" data-testid="device-firmware-version" >{systemDetail.firmwareVersion ? systemDetail.firmwareVersion + '' : '--'}</Typography>
                        <Typography variant="body2" style={{ fontSize: 13, fontWeight: 50 }} >{t('DEVICE_DETAILS.FIRMWARE_VERSION')}</Typography>
                        {systemDetail.newFirmwareVersion?<div style={{ display: 'flex' }}>
                            <AlarmBadgeStatus
                                alarmStatus={AlarmStatus.Open}
                                statusName={isUpdateRequired ? t('DEVICE_DETAILS.UPDATE_REQUIRED') : t('DEVICE_DETAILS.UP_TO_DATE')}
                        statusColor={isUpdateRequired ? '#CA3C3D' : '#39B620'}
                                foregroundColor={PXBColors.white[500]}
                            />

                            <Typography variant="body2" style={{ fontSize: 13, fontWeight: 100 }} id="device-firmware-version-available" data-testid="device-firmware-version-available" >{systemDetail.newFirmwareVersion + ' ' + t('DEVICE_DETAILS.FIRMWARE_AVAILABLE_GUIDE')}</Typography>
                        </div>:t('MANAGE_USERS.NOT_APPLICABLE')}
                    </div>

                </ListItem>
                <Divider style={{ marginLeft: 10 }} />

                <AssetListItem
                    key={'device-profile-users'}
                    title={users}
                    subtitle={t('DEVICE_DETAILS.USERS')}
                    imageType={ACTION_TYPE_NEXT}
                    OnItemSelected={(selectedData: string): void =>
                        goToUserDetails()
                    }
                    divider={true}
                    titleBold={true}
                ></AssetListItem>

                <AssetListItem
                    key={'device-profile-building-contact'}
                    title={primaryContact}
                    subtitle={t('DASHBOARD.BUILDING_CONTACT')}
                    imageType={ACTION_TYPE_NEXT}
                    OnItemSelected={(): void => onBuildingContactSelect()}
                    divider={true}
                    titleBold={true}
                ></AssetListItem>

                <AssetListItem
                    key={'device-profile-type'}
                    title={getDeviceType(systemDetail.type)}
                    subtitle={t('DEVICE_DETAILS.TYPE')}
                    imageType={ACTION_TYPE_NONE}
                    divider={true}
                    titleBold={true}
                ></AssetListItem>

                <AssetListItem
                    key={'device-profile-partNumber'}
                    title={systemDetail.partNumber ? systemDetail.partNumber : '--'}
                    subtitle={t('DEVICE_DETAILS.PART_NUMBER')}
                    imageType={ACTION_TYPE_COPY}
                    OnItemSelected={(): void =>
                        copyToClipboard(systemDetail.partNumber ? systemDetail.partNumber : '')
                    }
                    divider={true}
                    titleBold={true}
                ></AssetListItem>

                <AssetListItem
                    key={'device-profile-epasNumber'}
                    title={systemDetail.epasNumber ? systemDetail.epasNumber : '--'}
                    subtitle={t('DEVICE_DETAILS.SERIAL_NUMBER')}
                    imageType={ACTION_TYPE_COPY}
                    OnItemSelected={(): void =>
                        copyToClipboard(systemDetail.epasNumber ? systemDetail.epasNumber : '')
                    }
                    divider={true}
                    titleBold={true}
                ></AssetListItem>

                <AssetListItem
                    key={'device-profile-date-commissioned'}
                    title={(systemDetail.dateCommissioned ? systemDetail.dateCommissioned : '--')}
                    subtitle={t('DEVICE_DETAILS.DATE_COMMISSIONED')}
                    imageType={ACTION_TYPE_NONE}
                    divider={true}
                    titleBold={true}
                ></AssetListItem>
                <Box
                    display="flex"
                    style={{
                        flexDirection: 'column',
                        top: vh_65,
                        justifyContent: 'flex-end',
                        right: vh_18,
                        width: md ? vw_300 : '',
                        zIndex: 100,
                        minHeight: vh_900,
                        backgroundColor: PXBColors.white[500],
                        position: 'absolute',
                        transitionDelay: '0.2s',
                        visibility: isbuildingContactDrawerOpen ? 'visible' : 'hidden',
                    }}
                    data-testid="buildingDetails"
                >
                    <BuildingContactDetails
                        closeClick={buildingContactDrawerClose} buildingID={buildingDetails.buildingId} />
                </Box>
            </>}
        </Card >
    );
};


export const DeviceTest: React.FC<React.PropsWithChildren<React.PropsWithChildren<{ header: string, previousDate: string, nextDate: string, testStatus: boolean, testDescription: string, nextTitle: string, previousTitle: string }>>> = (props) => {

    return (
        <div >
            <Typography variant="subtitle1" id="device-test-header" data-testid="device-test-header" >{props.header}</Typography>

            <div style={{ display: 'flex' }}>
                <div className='rows' style={{ width: '50%', marginTop: 10 }}>
                    <Typography variant="body2" style={{ fontSize: 12 }} id="device-test-previous" data-testid="device-test-previous" >{props.previousTitle}</Typography>
                    <Typography variant="body2" style={{ fontSize: 14, fontWeight: 600 }} id="device-test-previous-date" data-testid="device-test-previous-date" >{(props.previousDate)}</Typography>

                    <Chip style={{ backgroundColor: '#FFFFFF', color: '#424E54', fontSize: 12, }} icon={props.testStatus ? <Close style={{ color: '#CA3C3D' }} /> : <Done style={{ color: '#39B620' }} />} label={props.testDescription} />
                </div>
                <div className='rows' style={{ width: '50%', marginTop: 10 }}>
                    <Typography variant="body2" style={{ fontSize: 12 }} id="device-test-next" data-testid="device-test-next" >{props.nextTitle}</Typography>
                    <Typography variant="body2" style={{ fontSize: 14, fontWeight: 600 }} id="device-test-next-date" data-testid="device-test-next-date" >{(props.nextDate)}</Typography>
                </div>
            </div>

        </div>
    );
};

//Todo- using API date time as of now (no formating)
// const getFormattedDateTime = (dateTime: string): string => {
//     return moment(dateTime).format('DD MMM YYYY') + ' ' + moment(dateTime).format('LT');
// };

export const systemDetailDefault = {
    systemId: "",
    systemName: "Loading...",
    range: "",
    type: 1,
    firmwareVersion: "",
    partNumber: "",
    epasNumber: "",
    controlCommands: "",
    dateCommissioned: "2022-06-01T04:03:58Z",
    systemDescription: "",
    subLocation: "",
    testDuration: "",
    durationTestPrevious: "2022-06-01T04:03:58Z",
    durationTestNext: "2022-07-01T04:03:58Z",
    functionalTestNext: "2022-07-01T04:03:58Z",
    functionalTestPrevious: "2022-06-01T04:03:58Z",
    durationTestStatus: true,
    durationTestDescription: "",
    functionalTestStatus: true,
    functionalTestDescription: "",
    macAddress: "",
    ip: "",
    subnet: "",
    systemStatus: 5,
    gateWayId: "",
    gateWayName: "",
    gateWayIpAddress: "",
    gateWayHostName: "",
    projectId: "",
    projectName: "BT Battery & Charge System",
    buildingName: "BT Battery Building Block 1",
    buildingId: "",
    luminaryCount: 3,
    isFavourite: true,
    isAdmin: false,
    errorCount: 0,
    dtStatus: false,
    isNotLinkedToGateway: false,
    entityType: 0,
    DGS_Ready: false,
    DGS_Warning: false,
    DGS_Error: false,
    DGS_Blocked: false,
    DGS_Offline: false,
    System_DT: false,
    StatusUpdateDateTimeUTC: "",
    activationStatus: 0,
    gatewayConnectionStatus: 0,
    configurationAlertSettings: 0,
    configurationFaultThresholdOtherAlerts: 0,
    noOfLuminairesBatteryFailure: 0,
    noOfLuminairesLampFailure: 0,
    noOfLuminairesCommunicationFailure: 0,
    noOfLuminairesEmergency: 0,
    noOfLuminairesTest: 0,
    noOfLumianiresRest: 0,
    noOfLuminairesTotalFailure: 0,
    noOfLuminairesReady: 0,
    IsChanged: false,
    DurationTestStatusName: "",
    FunctionalTestStatusName: "",
    LatestStatusUpdate: "",
    SLANumber: "",
    TotalNumberOfLuminaires: "",
    FirmwareReleaseDate: "",
    ConnectionString: "",
    SerialNumber: "",
    ModelName: "",
    ManufacturingDate: "",
    ConfigurationDryContactInput: "",
    ConfigurationDryContactOutput: "",
    StatusDryContactInput: "",
    StatusDryContactOutput: "",
    newFirmwareVersion: ""
};
