// eslint-disable-next-line
import React from 'react';
import {
    TableRow,
    IconButton,
    Typography,
    TableCell,
} from '@mui/material';
import * as PXBColors from '@brightlayer-ui/colors';
import { ChevronRight } from '@mui/icons-material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { getServiceDateStatusState, getServiceType, ticketStatus } from '../enum/ENUM';
import { getControlledText } from '../enum/GenericFormat';
import { TooltipItem } from '../../components/TooltipItem/TooltipItem';
import { MaintenanceDetail } from '../../types/maintenance';
import { AlarmBadgeStatus } from '../../components/AlarmBadge/AlarmBadge';
import { daysBetweentwoDates, getShortDate } from '../enum/DateTimeFormat';


type Props = {
    row: MaintenanceDetail;
    selectedSystem: string;
    displayClick: Function;
};

export const MaintenanceListItems: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const row: MaintenanceDetail = props.row;
    const ticketStatusNumber = row.ticketStatus !== 0 ? row.ticketStatus - 1 : row.ticketStatus;
    const ticketStatusName = ticketStatus[ticketStatusNumber].name !== null ? ticketStatus[ticketStatusNumber].name : 'MAINTENANCE_DETAILS.STATUS_SCHEDULED';
    const ticketStatusState = getServiceDateStatusState(ticketStatusName).statusTitle === 'MAINTENANCE_DETAILS.STATUS_SCHEDULED' ? true : false;


    const formatDate = (timestamp: any) => {
        const date = new Date(timestamp)
        const sDate = date.getDate();
        const sYear = date.getFullYear();
        const month = date.getMonth();
        //** To get month in string **//
        date.setMonth(month) // starts with 0, so 0 is January
        const sMonth = date.toLocaleString('en-EN', { month: "long" }).slice(0, 3);
        return `${sDate} ${sMonth} ${sYear} `;
    }

    const getScheduledDaysFromDate = (date: any) => {
        const startDate = getShortDate(new Date().toString());
        const endDate = getShortDate(new Date(date).toString());
        const differenceInDays = daysBetweentwoDates(startDate, endDate)
        return (differenceInDays <= 40 && differenceInDays >= 0) ? (differenceInDays === 0 ? ('MAINTENANCE_DETAILS.TODAY') : t('MAINTENANCE_DETAILS.IN_DUE', { replace: { differenceInDays: differenceInDays } })) : differenceInDays > 40 ? ('MAINTENANCE_DETAILS.SCHEDULED') : ('MAINTENANCE_DETAILS.PAST_DUE')
    }

    const handleDisplay = () => {
        props.displayClick({ maintenance: row });
    };
    //TODO - API implementation is pending
    /*const handleMarkAsFav = (): void => {
        dispatch(OrganizationThunks.markAsFav(
            row.id,
            ENTITY_TYPE.SYSTEM
        ));
    }

    const handleRemoveMarkAsFav = (): void => {
        dispatch(OrganizationThunks.removeMarkAsFav(
            row.id,
            ENTITY_TYPE.SYSTEM
        ));
    } - api implementation is pending */

    return (
        <TableRow
            id={`row${row.id}`}
            data-testid={`row${row.id}`}
            style={{ cursor: 'pointer', width: '100%', backgroundColor: props.selectedSystem === row.id ? PXBColors.blue[50] : PXBColors.white[100] }}

        >
            <TableCell style={{ width: '0%', paddingLeft: 10 }}
                id="maintenance-item-favorite"
                data-testid="system-list-item-favorite">
            </TableCell>
            <TooltipItem title={row.customerName} isTopPlacement={true} lenghtLimit={15} component={<TableCell
                id="maintenance-name"
                data-testid="maintenance-list-item-name"
                align="left"
                padding="checkbox"
                style={{ width: '15%', wordWrap: 'break-word', wordBreak: 'break-all' }}
            >
                {getControlledText(row.customerName, 15)}
            </TableCell>}>
            </TooltipItem>
            <TooltipItem title={row.ticketNumber} isTopPlacement={true} lenghtLimit={15} component={<TableCell align="left" padding="checkbox" style={{ width: '15%' }}
                id="maintenance-list-ticket-number"
                data-testid="maintenance-list-ticket-number">
                {getControlledText(row.ticketNumber, 15)}
            </TableCell>}>=
            </TooltipItem>
            <TooltipItem title={row.systemName} isTopPlacement={true} lenghtLimit={15} component={<TableCell align="left" padding="checkbox" style={{ width: '12%', padding: 0, paddingLeft: 16 }}
                id="maintenance-list-system-name"
                data-testid="maintenance-list-system-name">
                {getControlledText(row.systemName, 15)}
            </TableCell>}>
            </TooltipItem>
            <TableCell align="left" padding="checkbox" style={{ width: '15%', padding: 0, paddingLeft: 16 }}
                id="maintenance-list-technician-name"
                data-testid="maintenance-list-technician-name">
                {row.technicianName}
            </TableCell>

            <TableCell align="left" padding="checkbox" style={{ width: '15%', padding: 0, paddingLeft: 16 }}
                id="maintenance-list-service-type"
                data-testid="maintenance-list-service-type">
                {t(getServiceType(row.serviceType))}
            </TableCell>

            <TableCell align="left" padding="checkbox" style={{ width: '15%', padding: 0, paddingLeft: 16 }}
                id="maintenance-list-service-date"
                data-testid="maintenance-list-service-date">
                {formatDate(row.serviceDate)}
                <AlarmBadgeStatus
                    statusName={ ticketStatusState ? t(getScheduledDaysFromDate(row.serviceDate)) : t(getServiceDateStatusState(ticketStatusName).statusTitle) }
                    statusColor={ ticketStatusState ? getServiceDateStatusState(getScheduledDaysFromDate(row.serviceDate)).statusColor : getServiceDateStatusState(ticketStatusName).statusColor }
                    foregroundColor={ticketStatusState ? getServiceDateStatusState(getScheduledDaysFromDate(row.serviceDate)).foregroundColor : getServiceDateStatusState(ticketStatusName).foregroundColor} alarmStatus={''} />
            </TableCell>

            <TableCell align="left" padding="checkbox" style={{ width: '10%', padding: 0, paddingLeft: 16 }}
                id="maintenance-list-sla-number"
                data-testid="maintenance-list-sla-number">
                {row.sLANumber}
            </TableCell>


            <TableCell style={{ width: '5%' }}
                id="system-list-item-next"
                data-testid="maintenance-list-item-next" onClick={() => handleDisplay()}>
                <IconButton edge="end" id="list-item-next-icon" style={{ marginLeft: "auto" }} >
                    <ChevronRight />
                </IconButton>
            </TableCell>

            <TableCell align="left" padding="checkbox" style={{ width: '0%', padding: 0 }}
                id="maintenance-list-item-empty"
                data-testid="maintenance-list-item-empty">

            </TableCell>
        </TableRow>
    );
};
// {row.isFavorite === 'true' ? <Star data-testid='removeMarkAsFaVSystem' style={{ color: '#007BC1' }} onClick={handleRemoveMarkAsFav} /> : <StarBorder style={{ color: '#727E84' }} onClick={handleMarkAsFav} />} - this is commented because api side implementation is pending