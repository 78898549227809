// eslint-disable-next-line
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { Avatar, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, InputAdornment, InputLabel, ListItem, ListItemAvatar, ListItemText, MenuItem, Select, SelectChangeEvent, Snackbar, TextField, Tooltip, Typography } from '@mui/material';
import * as PXBColors from '@brightlayer-ui/colors';
import { useDispatch, useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import { Thunks } from '../../actions';
import { useAuthHelper } from '../../components/AuthContextProvider';
import { useToasts } from '../../data/DataProviders/ToastProvider';
import { GenericToolbar } from '../../components/GenericToolbar';
import { ToolbarColor } from '../../components/GenericToolbar/GenericToolbar';
import { OrgBuildingData } from '../../types/organization';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { MaintainenceListItem } from './MaintainenceListItem';
import { AccountCircle, Delete } from '@mui/icons-material';
import { DeviceData } from '../../types/device';
import { systemDetailDefault } from '../assets/DeviceProfileCard';
import { DeviceCustomerDetail } from '../../types/customer-detail';
import { customerData } from '../../state/assets-state';
import { getDeviceType, getIssueTypeList, getIssueTypeListKey, getMaintainenceRequestPriorityList, getMaintainenceRequestPriorityListKey, issueTypeList, maintainanceRequestPriorityList } from '../enum/ENUM';
import { EmptyState } from '@brightlayer-ui/react-components';
import { getControlledText } from '../enum/GenericFormat';
import { TooltipItem } from '../../components/TooltipItem/TooltipItem';
import { TechnicianDataList } from '../../types/technicians';
import { FilePdf, Technician } from '@brightlayer-ui/icons-mui';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ActionType, ORG_COMMENTS_LIMIT } from '../../constants';
import * as MuiIcons from '@mui/icons-material';
import { UsermanagementThunks } from '../../actions/thunks/user-management-thunks';
import moment from 'moment';
import { DocumentsDetail, MaintenanceDetail } from '../../types/maintenance';
import { defaultMaintenanceListData } from '../../state/usermanagement-state';

const buildingDetailsDefault: OrgBuildingData = {
    buildingId: "",
    name: "",
    parentProjectId: "",
    projectName: "",
    address: "",
    postalCode: "",
    city: "",
    country: "",
    contact: '',
    email: "",
    countryCode: "",
    phone: "",
    description: "",
    adopterId: '',
    latitude: "",
    longitude: "",
    gatewayCount: 0,
    systemCount: 0,
    adminCount: 0,
    viewerCount: 0,
    isAdmin: false,
    isFavourite: false,
    address2: '',
    secondaryEmail: '',
    secondaryCountryCode: '',
    secondaryPhone: '',
    primaryFirstName: '',
    primaryLastName: '',
    secondaryFirstName: null,
    secondaryLastName: null
};
type Props = {
    location: any;
};
export const CreateMaintainence: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const history = useHistory();
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const { maintenanceId } = props.location.state ? props.location.state : '3ac113d8-b92c-418a-a044-a84f7c518fef';
    const { t } = useLanguageLocale();
    const authHelper = useAuthHelper();
    const { addToast } = useToasts();
    const dispatch = useDispatch();
    // @ts-ignore
    const { buildingId, systemId, actionType = ActionType.ADD } = useParams();
    const [requestPriorityType, setRequestPriorityType] = useState('');
    const [selectedTechnician, setSelectedTechnician] = useState('');
    const [issueType, setIssueType] = useState('');
    const [appBarColor, setAppBarColor] = useState(ToolbarColor.Primary);
    const [header, setHeader] = React.useState(t('ASSETS_DETAILS.CREATE_MAINTAINENCE_HEADER'));
    const organization = useSelector(Selectors.Organization.organization);
    const asset = useSelector(Selectors.Asset.assets);
    const [buildingDetails, setBuildingDetails] = useState<OrgBuildingData>(buildingDetailsDefault);
    const [systemDetail, setSystemDetailState] = React.useState<DeviceData>(systemDetailDefault);
    const [deviceCustomerData, setDeviceCustomerData] = useState<DeviceCustomerDetail>(customerData);
    const [dataLoaded, setDataLoaded] = React.useState(false);
    const [systemLoaded, setSystemLoaded] = React.useState(false);
    const [buildingLoaded, setBuildingLoaded] = React.useState(false);
    const [customerLoaded, setCustomerLoaded] = React.useState(false);
    const [techniciansList, setTechniciansList] = useState<TechnicianDataList>([]);
    const userManagement = useSelector(Selectors.Usermanagement.usermanagement);
    const [serviceDate, setServiceDate] = React.useState<Date | null>(new Date());
    const [customerComments, setCustomerComments] = React.useState('');
    const [alertErrorMessage, setAlertErrorMessage] = useState<string>('');
    const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [documents, setDocuments] = useState<{ fileName: string, fileBase64: string }[]>([]);
    const user = useSelector(Selectors.Usermanagement.usermanagement);
    const [maintenanceDetails, setMaintenanceDetails] = useState<MaintenanceDetail>(defaultMaintenanceListData.detail[0]);
    const [savebuttonState, setSavebuttonState] = React.useState(false);
    const [isFormEdit, setFormEdit] = useState(false);
    const [openQuitDialog, setOpenQuitDialog] = React.useState(false);
    const [documentsToShowImageIcon, setDocumentsToShowImageIcon] = useState<{ fileName: string, fileBase64: string }[]>([]);

    const handleToolBar = (flag: boolean) => {
        setSavebuttonState(flag);
        setFormEdit(flag);
        setAppBarColor(flag ? ToolbarColor.Other : ToolbarColor.Default);
    };

    const fieldsComplete = (fields: string[]): boolean => {
        for (let i = 0; i < fields.length; i++) {
            if (fields[i].length < 1) {
                return false;
            }
        }
        return true;
    };

    const validateServiceDate = (date: Date | null): boolean => {
        if (!date) return false;
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set to start of the day for comparison

        const serviceDate = new Date(date);
        serviceDate.setHours(0, 0, 0, 0); // Set to start of the day for comparison

        return serviceDate >= today; // Date is today or in the future
    };

    const handleQuitDialog = () => {
        setOpenQuitDialog(true);
    };

    const handleCancel = () => {
        setOpenQuitDialog(false);
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            const validTypes = ['application/pdf', 'image/jpeg', 'image/png'];
            const newFiles: File[] = [];
            let errorMessage = '';

            if (files.length + selectedFiles.length > 3 || documents.length + files.length > 3) {
                setError(t('MAINTENANCE_DETAILS.UPLOAD_DOCUMENT_ERROR_MESSAGE_MAX_3_FILES'));
                return;
            }

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                if (!validTypes.includes(file.type)) {
                    errorMessage = t('MAINTENANCE_DETAILS.UPLOAD_DOCUMENT_ERROR_MESSAGE_INVALID_TYPE');
                    continue;
                }
                if (file.size > 2 * 1024 * 1024) {
                    errorMessage = t('MAINTENANCE_DETAILS.UPLOAD_DOCUMENT_ERROR_MESSAGE_INVALID_FILE_SIZE');
                    continue;
                }
                newFiles.push(file);
            }

            if (newFiles.length + selectedFiles.length > 3 || documents.length + files.length > 3) {
                errorMessage = t('MAINTENANCE_DETAILS.UPLOAD_DOCUMENT_ERROR_MESSAGE_MAX_3_FILES');
            }

            if (errorMessage) {
                setError(errorMessage);
            } else {
                setError(null);
            }

            setSelectedFiles(prevFiles => [...prevFiles, ...newFiles]);
            newFiles.forEach(file => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64String = (reader.result as string).replace(/^data:image\/(jpeg|png);base64,/, '');
                    const cleanedFileBase64 = base64String.replace('data:application/pdf;base64,', '');
                    const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, '');
                    setDocumentsToShowImageIcon(prevDocs => [...prevDocs, { fileName: file.name, fileBase64: base64String }]);
                    setDocuments(prevDocs => [...prevDocs, { fileName: fileNameWithoutExtension, fileBase64: cleanedFileBase64 }]);
                };
                reader.readAsDataURL(file);
            });
        }
    };

    const handleDeleteFile = (index: number) => {
        setError(null);
        const updatedFiles = selectedFiles.filter((_, i) => i !== index);
        setSelectedFiles(updatedFiles);
        const updatedDocuments = documents.filter((_, i) => i !== index);
        setDocumentsToShowImageIcon(updatedDocuments);
        setDocuments(updatedDocuments);
    };

    const requestPriorityValue = getMaintainenceRequestPriorityList(requestPriorityType);
    const requestPriorityNumber = (requestPriorityType !== '' && requestPriorityType !== undefined) ? requestPriorityValue.key : 0;
    const issueTypeValue = getIssueTypeList(issueType);
    const issueTypeNumber = (issueType !== '' && issueType !== undefined) ? issueTypeValue.key : 0;
    const serviceDateFormat = moment(serviceDate).format("yyyy-MM-DD HH:mm");

    useEffect(() => {
        dispatch(Thunks.Organization.getBuildingDetailsList('', buildingId));
        dispatch(Thunks.Asset.getDeviceCustomerData(systemId));
        dispatch(Thunks.Asset.getDeviceList('', '', systemId, '', ''));
        dispatch(Thunks.UserManagement.getTechniciansList('', ''));
        if (actionType == ActionType.EDIT)
            dispatch(Thunks.UserManagement.getMaintenanceDetail('', maintenanceId)); // it should call once we click on edit button
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (user.maintenanceDetailSuccess || actionType === ActionType.EDIT) {
            setAppBarColor(ToolbarColor.Default);

            const maintenanceDocuments = maintenanceDetails.documents || [];

            const documentsMatch = arraysAreEqual(
                transformDocumentsDetailListDataGet(maintenanceDocuments),
                documents
            );

            if (requestPriorityType === getMaintainenceRequestPriorityListKey(maintenanceDetails.requestPriority.toString()).value &&
                issueType === getIssueTypeListKey(maintenanceDetails.serviceType.toString()).value &&
                selectedTechnician === getTechnicianIdByName(maintenanceDetails.technicianName) &&
                serviceDate?.getTime() === new Date(maintenanceDetails.serviceDate).getTime() &&
                customerComments === maintenanceDetails.description &&
                documentsMatch
            ) {
                handleToolBar(false);
            } else {
                if ((fieldsComplete([requestPriorityType, issueType, selectedTechnician]) && validateServiceDate(serviceDate))) {
                    setSavebuttonState(true);
                    setAppBarColor(ToolbarColor.Other);
                    setFormEdit(true);
                } else {
                    handleToolBar(false);
                }
            }
        } else {
            if ((fieldsComplete([requestPriorityType, issueType, selectedTechnician]) && validateServiceDate(serviceDate))) {
                setSavebuttonState(true);
                setAppBarColor(ToolbarColor.Other);
                setFormEdit(true);
            } else {
                handleToolBar(false);
            }
        }
    }, [requestPriorityType, issueType, selectedTechnician, serviceDate, user.maintenanceDetailSuccess, documents, customerComments]); // eslint-disable-line react-hooks/exhaustive-deps

    const arraysAreEqual = (maintenanceDocuments: { fileName: string, filePath: string }[], documenstArray: { fileName: string, fileBase64: string }[]) => {
        if (maintenanceDocuments.length !== documenstArray.length) return false;
        const sortedArr1 = maintenanceDocuments.slice().sort();
        const sortedArr2 = documenstArray.slice().sort();
        return sortedArr1.every((value, index) => value.filePath === sortedArr2[index].fileBase64);
    };

    const transformDocumentsDetailListDataGet = (documents: DocumentsDetail) => {
        return documents.map(doc => ({
            fileName: doc.fileName,
            filePath: doc.filePath,
        }));
    };

    useEffect(() => {
        // eslint-disable-next-line
        if (actionType == ActionType.EDIT) {
            if (!user.maintenanceDetailLoading && user.maintenanceDetailSuccess) {
                if (user.maintenanceDetail.detail.length > 0) {
                    setMaintenanceDetails(user.maintenanceDetail.detail[0]);
                } else {
                    setMaintenanceDetails(defaultMaintenanceListData.detail[0])
                }
            } else if (!user.maintenanceDetailLoading && user.maintenanceDetailFail) {
                setMaintenanceDetails(defaultMaintenanceListData.detail[0]);
            }
        }
    }, [user.maintenanceDetailLoading, user.maintenanceDetailSuccess, user.maintenanceDetailFail]); // eslint-disable-line react-hooks/exhaustive-deps

    const getTechnicianIdByName = (name: string): string => {
        const technician = techniciansList.find(tech => tech.userName === name);
        return technician ? technician.id : '';
    };
    const removeFileExtension = (fileName: string): string => {
        return fileName.replace(/\.[^/.]+$/, "");
    };

    useEffect(() => {
        if (actionType == ActionType.EDIT) {
            setHeader(maintenanceDetails ? '"' + maintenanceDetails.systemName + ',' + '"' + " " + getDeviceType(maintenanceDetails.systemType) + ' , ' + user.maintenanceDetail.detail[0].customerName : '');
            let requestPriorityEnum = getMaintainenceRequestPriorityListKey(maintenanceDetails.requestPriority.toString());
            let issueTypeEnum = getIssueTypeListKey(maintenanceDetails.serviceType.toString());
            if (requestPriorityEnum && requestPriorityEnum.value && requestPriorityEnum.key) {
                setRequestPriorityType(requestPriorityEnum.value)
            }
            if (issueTypeEnum && issueTypeEnum.value && issueTypeEnum.key) {
                setIssueType(issueTypeEnum.value)
            }
            const technicianId = getTechnicianIdByName(maintenanceDetails.technicianName);
            setSelectedTechnician(technicianId);
            setServiceDate(new Date(maintenanceDetails.serviceDate));
            setCustomerComments(maintenanceDetails.description);
            setDocuments(maintenanceDetails.documents ? maintenanceDetails.documents.map(doc => ({
                fileName: removeFileExtension(doc.fileName),
                fileBase64: doc.filePath
            })) : []);
            setDocumentsToShowImageIcon(maintenanceDetails.documents ? maintenanceDetails.documents.map(doc => ({
                fileName: doc.fileName,
                fileBase64: doc.filePath
            })) : [])
        }
    }, [maintenanceDetails]);// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (!organization.buildingListLoading && organization.buildingListSuccess) {
            setBuildingLoaded(true);
            setBuildingDetails(organization.buildingList[0]);
        } else if (!organization.buildingListLoading && organization.buildingListFail) {
            setBuildingDetails(buildingDetailsDefault);
        }
    }, [organization.buildingListLoading, organization.buildingListSuccess, organization.buildingListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!asset.deviceListLoading && asset.deviceListSuccess) {
            setSystemLoaded(true);
            setSystemDetailState(asset.deviceList.detail.filter((device) => device.systemId === systemId)[0]);
        } else if (!asset.deviceListLoading && asset.deviceListFail) { }
    }, [asset.deviceListLoading, asset.deviceListSuccess, asset.deviceListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!asset.deviceCustomerDataLoading && (asset.deviceCustomerDataSuccess || asset.deviceCustomerDataFail)) {
            setCustomerLoaded(true);
            setDeviceCustomerData(asset.deviceCustomerData);
        } else if (!asset.deviceCustomerDataLoading && asset.deviceCustomerDataFail) { }
    }, [asset.deviceCustomerDataLoading, asset.deviceCustomerDataSuccess, asset.deviceCustomerDataFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (systemLoaded && buildingLoaded && customerLoaded) {
            setDataLoaded(true);
        }
        else
            setDataLoaded(false);
    }, [systemLoaded, buildingLoaded, customerLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

    const getTechniciansList = (list: TechnicianDataList): TechnicianDataList => {
        return list.map((data, index) => {
            return (
                {
                    userName: data.userName,
                    id: data.id,
                    email: data.email,
                    company_name: data.company_name,
                    company_country: data.company_country,
                    userStatus: data.userStatus
                }
            );
        })
    };



    useEffect(() => {
        if (!userManagement.technicianListLoading && userManagement.technicianListSuccess) {
            if (userManagement.technicianList.detail.length > 0) {
                const techniciansList = getTechniciansList(userManagement.technicianList.detail);
                setTechniciansList(techniciansList);
            } else {
                setTechniciansList([]);
            }
        }
    }, [userManagement.technicianListLoading, userManagement.technicianListSuccess, userManagement.technicianListFail]); // eslint-disable-line react-hooks/exhaustive-deps


    const onChangeRequestPriorityType = (event: SelectChangeEvent) => {
        setRequestPriorityType(event.target.value);

    };

    const onChangeTechnician = (event: SelectChangeEvent) => {
        setSelectedTechnician(event.target.value);

    };

    const onChangeIssueType = (event: SelectChangeEvent) => {
        setIssueType(event.target.value);

    };

    const onQuitClick = () => {
        history.goBack();

    }

    const getInitials = (firstName: string, lastName: string) => {
        const firstInitial = firstName ? firstName.charAt(0) : '';
        const lastInitial = lastName ? lastName.charAt(0) : '';
        return `${firstInitial}${lastInitial}`;
    };

    const getFileExtension = (fileName: string): string => {
        return fileName.split('.').pop()?.toLowerCase() || '';
    };

    const handleCreateMaintainanceResponse = (): void => {
        if (userManagement.createMaintainanceErrorCode !== '') {
            if (userManagement.createMaintainanceErrorCode === '9001')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_MAINTAINANCE_ERROR_CODE_9001'));
            else if (userManagement.createMaintainanceErrorCode === '9009')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_MAINTAINANCE_ERROR_CODE_9009'));
            else if (userManagement.createMaintainanceErrorCode === '9050')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_MAINTAINANCE_ERROR_CODE_9050'));
            else if (userManagement.createMaintainanceErrorCode === '9215')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_MAINTAINANCE_ERROR_CODE_9215'));
            else if (userManagement.createMaintainanceErrorCode === '9216')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_MAINTAINANCE_ERROR_CODE_9216'));
            else if (userManagement.createMaintainanceErrorCode === '9162')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_MAINTAINANCE_ERROR_CODE_9162'));
            else if (userManagement.createMaintainanceErrorCode === '9228')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_MAINTAINANCE_ERROR_CODE_9228'));
            else if (userManagement.createMaintainanceErrorCode === '9078')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_MAINTAINANCE_ERROR_CODE_9078'));
            else if (userManagement.createMaintainanceErrorCode === '9218')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_MAINTAINANCE_ERROR_CODE_9218'));
            else if (userManagement.createMaintainanceErrorCode === '9219')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_MAINTAINANCE_ERROR_CODE_9219'));
            else if (userManagement.createMaintainanceErrorCode === '9220')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_MAINTAINANCE_ERROR_CODE_9220'));
            else if (userManagement.createMaintainanceErrorCode === '9185')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_MAINTAINANCE_ERROR_CODE_9185'));
            else if (userManagement.createMaintainanceErrorCode === '9221')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_MAINTAINANCE_ERROR_CODE_9221'));
            else if (userManagement.createMaintainanceErrorCode === '9222')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_MAINTAINANCE_ERROR_CODE_9222'));
            else if (userManagement.createMaintainanceErrorCode === '9217')
                setAlertErrorMessage(t('TOAST_MSG.ACREATE_MAINTAINANCE_ERROR_CODE_9217'));
            else
                setAlertErrorMessage(t('TOAST_MSG.CREATE_MAINTAINANCE_API_FAILURE'));
        }
    };

    useEffect(() => {
        if (!userManagement.createMaintainanceLoading && userManagement.createMaintainanceSuccess) {
            history.goBack();
            actionType == ActionType.ADD ? addToast(t('TOAST_MSG.CREATE_MAINTAINANCE_SUCCESS'), 'success') : addToast(t('TOAST_MSG.UPDATE_MAINTAINANCE_SUCCESS'), 'success')
        }
        else if (!userManagement.createMaintainanceLoading && userManagement.createMaintainanceFail) {
            setDisplayErrorMessage(true);
            handleCreateMaintainanceResponse();
        }
    }, [userManagement.createMaintainanceSuccess, userManagement.createMaintainanceFail, userManagement.createMaintainanceLoading]); // eslint-disable-line react-hooks/exhaustive-deps

    const saveData = (): void => {
        dispatch(UsermanagementThunks.createMaintainance(
            actionType == ActionType.EDIT ? maintenanceDetails.id : '',
            systemId,
            deviceCustomerData.customerId,
            deviceCustomerData.sLANumber,
            requestPriorityNumber,
            issueTypeNumber,
            selectedTechnician,
            serviceDateFormat,
            customerComments,
            documents,
            ''
        ));
    }





    const onCancel = () => {
        history.goBack();
    }

    return (
        <div data-testid="createMaintainenceTicket-container">
            <GenericToolbar
                title={header}
                showBackButton={!isFormEdit}
                showCloseButton={isFormEdit}
                actionUIState={savebuttonState}
                backButtonOnClick={(): void => {
                    if (!isFormEdit) {
                        onQuitClick()
                    }
                    else {
                        handleQuitDialog()
                    }
                }}
                saveOnClick={(): void => {
                    saveData()
                }}
                cancelOnClick={(): void => {
                    onCancel()
                }}
                color={appBarColor}
            />

            {dataLoaded && !userManagement.createMaintainanceLoading && <div data-testid="container-create-maintainence-ticket" style={{ backgroundColor: PXBColors.white[50], height: md ? 1250 : 1600 }}>

                <Typography data-testid='createMaintainenceHeaderId' variant='body2' style={{ fontWeight: 400, fontSize: 14, paddingTop: '2%', paddingLeft: '10%', paddingRight: '10%' }}>{t('ASSETS_DETAILS.HELPER_TEXT_MAINTAINENCE', { replace: { customerName: deviceCustomerData.companyName } })}</Typography>
                <Typography data-testid='createMaintainenceSubHeaderId' variant='body2' style={{ fontWeight: 400, fontSize: 14, paddingTop: '1%', paddingLeft: '10%', paddingRight: '10%', color: PXBColors.red[500] }}>{t('ASSETS_DETAILS.SUBHEADER_TEXT_MAINTAINENCE')}</Typography>

                <div style={{ height: 800, width: '100%', padding: '10%', paddingTop: '2%', display: md ? 'flex' : '' }}>

                    <div style={{ float: 'left', width: md ? '49%' : '', paddingRight: '5%' }}>
                        <div style={{ display: 'flex', marginLeft: 'auto', marginTop: 15, marginBottom: 10 }}>
                            <Typography
                                id="systemDetails-maintainenceHeader"
                                data-testid="systemDetails-maintainenceHeaderId"
                                style={{ fontSize: 14, fontWeight: 600, alignSelf: 'center', color: PXBColors.blue[500] }}>
                                {t('ASSETS_DETAILS.LEFT_MAINTAINENCE_HEADER')}
                            </Typography>
                        </div>
                        <MaintainenceListItem
                            systemStatus={systemDetail?.systemStatus}
                            icon={true}
                            previousTitleHeader={t('ASSETS_DETAILS.SYSTEM_NAME_TYPE_SUBTITLE')}
                            nextTitleSubheader={''}
                            nextTitle={t('ASSETS_DETAILS.MOST_RECENT_STATUS')}
                            previousTitle={'"' + systemDetail?.systemName + ',' + '"' + " " + getDeviceType(systemDetail?.type)}
                        />
                        <Divider style={{ color: PXBColors.gray[100], marginTop: 10 }} />
                        <MaintainenceListItem
                            icon={true}
                            previousTitleHeader={''}
                            nextTitleSubheader={''}
                            nextTitle={'05 Oct 2023, 09:41'}
                            previousTitle={t('ASSETS_DETAILS.PREVIOUS_SYSTEM_STATUS')}
                        />
                        <Divider style={{ color: PXBColors.gray[100], marginTop: 10 }} />
                        <MaintainenceListItem
                            icon={false}
                            previousTitleHeader={''}
                            nextTitleSubheader={buildingDetails.city ? buildingDetails.city : t('MANAGE_USERS.NOT_APPLICABLE')}
                            nextTitle={buildingDetails.name}
                            previousTitle={t('USERS.LOCATION')}
                        />
                        <Divider style={{ color: PXBColors.gray[100], marginTop: 10 }} />
                        <MaintainenceListItem
                            icon={false}
                            previousTitleHeader={''}
                            nextTitleSubheader={''}
                            nextTitle={systemDetail?.firmwareVersion}
                            previousTitle={t('ASSETS_DETAILS.FIRMWARE')}
                        />
                        <Divider style={{ color: PXBColors.gray[100] }} />
                        <div style={{ display: 'flex', marginLeft: 'auto', marginTop: 30 }}>
                            <Typography
                                id="customerDetails-maintainenceHeader"
                                data-testid="customerDetails-maintainenceHeaderId"
                                style={{ fontSize: 14, fontWeight: 600, alignSelf: 'center', color: PXBColors.blue[500] }}>
                                {t('ASSETS_DETAILS.CUSTOMER_MAINTAINENCE_HEADER')}
                            </Typography>
                        </div>
                        <div style={{ display: 'flex', height: 57, justifyContent: 'center', alignItems: 'flex-start', gap: 127, marginTop: 16 }}>
                            <div className='rows' style={{ width: '60%', paddingLeft: 16, paddingTop: 16 }}>
                                <Typography variant="subtitle2" id="customerTitle" data-testid="customerTitleId" >{t('CUSTOMER_DETAILS.CUSTOMER_HEADER_SUBTITLE')}</Typography>
                            </div>
                            {asset.deviceCustomerDataErrorCode === '9004' ?
                                <div style={{ display: 'flex', height: 40, gap: 10, alignItems: 'center', width: '30%' }}>
                                    {deviceCustomerData.imageBase64 ? <img style={{ height: 30, width: 30, borderRadius: '50%' }} src={"data:image/jpeg;base64," + deviceCustomerData.imageBase64} alt="ListItemAvatar" /> :
                                        <AccountCircle fontSize='large' style={{ color: PXBColors.gray[500] }}></AccountCircle>
                                    }
                                    <TooltipItem title={t('MAINTENANCE_DETAILS.NO_CUSTOMER_LINKED')} isTopPlacement={true} lenghtLimit={20} component={
                                        <Typography style={{ fontSize: 12, fontWeight: 300 }} id="customerName" data-testid="customerNameId" >
                                            {getControlledText(t('MAINTENANCE_DETAILS.NO_CUSTOMER_LINKED'), 20)}
                                        </Typography>
                                    }>
                                    </TooltipItem>
                                </div>
                                :
                                <div style={{ display: 'flex', height: 40, gap: 10, alignItems: 'center', width: '30%' }}>
                                    {deviceCustomerData.imageBase64 ? <img style={{ height: 30, width: 30, borderRadius: '50%' }} src={"data:image/jpeg;base64," + deviceCustomerData.imageBase64} alt="ListItemAvatar" /> :
                                        <AccountCircle fontSize='large' style={{ color: PXBColors.gray[500] }}></AccountCircle>
                                    }
                                    <TooltipItem title={deviceCustomerData.companyName} isTopPlacement={true} lenghtLimit={20} component={
                                        <Typography style={{ fontSize: 12, fontWeight: 300 }} id="customerName" data-testid="customerNameId" >
                                            {getControlledText(deviceCustomerData.companyName, 20)}
                                        </Typography>
                                    }>
                                    </TooltipItem>
                                </div>
                            }

                        </div>
                        <Divider style={{ color: PXBColors.gray[100] }} />
                        <MaintainenceListItem
                            icon={false}
                            previousTitleHeader={''}
                            nextTitleSubheader={''}
                            nextTitle={asset.deviceCustomerDataErrorCode === '9004' ? t('MANAGE_USERS.NOT_APPLICABLE') : deviceCustomerData.sLANumber}
                            previousTitle={t('DEVICE_DETAILS.SLA_Number')}
                        />
                        <Divider style={{ color: PXBColors.gray[100] }} />
                        <div style={{ display: 'flex', marginLeft: 'auto', marginTop: 30, marginBottom: 10 }}>
                            <Typography
                                id="createMaintainence-notifyUserHeader"
                                data-testid="createMaintainence-notifyUserHeaderId"
                                style={{ fontSize: 14, fontWeight: 600, alignSelf: 'center', color: PXBColors.blue[500] }}>
                                {t('ASSETS_DETAILS.NOTIFY_USERS_MAINTAINENCE_HEADER')}
                            </Typography>
                        </div>
                        <ListItem alignItems="flex-start">
                            <ListItemAvatar style={{ display: 'flex', paddingRight: 16, justifyContent: 'center', alignItems: 'center', alignSelf: 'stretch' }}>
                                <Avatar>{getInitials(deviceCustomerData.primaryFirstName, deviceCustomerData.primaryLastName)}</Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={deviceCustomerData.primaryFirstName + " " + deviceCustomerData.primaryLastName}
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            data-testid='maintainence-primaryEmailId'
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            {deviceCustomerData.primaryEmail}
                                        </Typography>
                                        <Typography>{t('ORGANIZATION_DETAILS.PRIMARY_CONTACT')}</Typography>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <Divider variant="inset" />
                        <ListItem alignItems="flex-start">
                            <ListItemAvatar style={{ display: 'flex', paddingRight: 16, justifyContent: 'center', alignItems: 'center', alignSelf: 'stretch' }}>
                                <Avatar> {getInitials(deviceCustomerData.secondaryFirstName, deviceCustomerData.secondaryLastName)}</Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={deviceCustomerData.secondaryFirstName + " " + deviceCustomerData.secondaryLastName}
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            data-testid='maintainence-secondaryEmailId'
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            {deviceCustomerData.secondaryEmail}
                                        </Typography>
                                        <Typography>{t('ORGANIZATION_DETAILS.SECONDARY_CONTACT')}</Typography>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <Divider variant="inset" />

                    </div>
                    <div style={{ float: 'right', width: md ? '40%' : '' }}>

                        <div style={{ display: 'flex', marginLeft: 'auto', marginTop: 15 }}>
                            <Typography
                                id="createMaintainenceDetailsHeader"
                                data-testid="createMaintainenceDetailsHeaderId"
                                style={{ fontSize: 14, fontWeight: 600, alignSelf: 'center', color: PXBColors.blue[500] }}>
                                {t('ASSETS_DETAILS.RIGHT_MAINTAINENCE_HEADER')}
                            </Typography>
                        </div>
                        <FormControl variant="filled" sx={{ width: '100%', marginTop: 2 }}>
                            <InputLabel id="requestPriorityType-select-filled-label" data-testid='requestPriorityTypeId'>{t('MAINTENANCE_DETAILS.REQUEST_PRIORITY_INPUT_LABEL')}</InputLabel>
                            <Select
                                labelId="requestPriorityType-simple-select-filled-label"
                                id="requestPriorityType-simple-select-filled"
                                value={requestPriorityType}
                                onChange={onChangeRequestPriorityType}
                                inputProps={{ 'data-testid': 'requestPriority-type' }}
                            >
                                {maintainanceRequestPriorityList.map((option) => (
                                    <MenuItem

                                        sx={{ textAlign: 'center' }}
                                        key={option.key} value={option.value} data-testid={option.value} >
                                        {t(option.value)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="filled" sx={{ width: '100%', marginTop: 4 }}>
                            <InputLabel id="issue-select-filled-label" data-testid='issueTypeId'>{t('MAINTENANCE_DETAILS.SUPPORT_TYPE_INPUT_LABEL')}</InputLabel>
                            <Select
                                labelId="issue-simple-select-filled-label"
                                id="issueType-simple-select-filled"
                                value={issueType}
                                onChange={onChangeIssueType}
                                inputProps={{ 'data-testid': 'issue-type' }}
                            >
                                {issueTypeList.map((option) => (
                                    <MenuItem
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                        key={option.key} value={option.value} data-testid={option.value} >
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ width: '20', height: '20', marginRight: '18', paddingRight: 20, color: PXBColors.gray[500] }} >
                                                {option.image}
                                            </div>
                                            {t(option.value)}
                                        </div>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="filled" sx={{ width: '100%', marginTop: 4 }}>
                            <InputLabel id="technician-select-filled-label" data-testid='technicianId'>{t('MAINTENANCE_DETAILS.TECHNICIAN_INPUT_LABEL')}</InputLabel>
                            <Select
                                labelId="technician-simple-select-filled-label"
                                id="technician-simple-select-filled"
                                value={selectedTechnician}
                                onChange={onChangeTechnician}
                                inputProps={{ 'data-testid': 'technician-Id' }}
                                startAdornment={
                                    <InputAdornment position="start" >
                                        <Technician />
                                    </InputAdornment>
                                }
                            >
                                {techniciansList.map((option) => (
                                    <MenuItem
                                        sx={{ textAlign: 'center' }}
                                        key={option.id}
                                        value={option.id}
                                        data-testid={option.id} >
                                        {t(option.userName)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div data-testid='serviceDateId' style={{ display: 'flex', width: '100%', flexDirection: 'column', marginTop: 24, marginBottom: 24 }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    minDate={new Date()}
                                    value={serviceDate}
                                    onChange={(newValue) => setServiceDate(newValue)}
                                    slotProps={{
                                        textField: {
                                            helperText: t('CUSTOMER_DETAILS.CALENDAR_HELPER_TEXT'),
                                            variant: 'filled',
                                            label: t('MAINTENANCE_DETAILS.SERVICE_DATE')
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                        <TextField
                            style={{ width: '100%', float: 'left', wordWrap: 'break-word', color: PXBColors.black[500] }}
                            id="maintainenceComments"
                            data-testid='maintainenceCommentsId'
                            variant={'filled'}
                            label={t('MAINTENANCE_DETAILS.COMMENTS_LABEL')}
                            rows={2}
                            multiline
                            fullWidth
                            onChange={(evt: ChangeEvent<HTMLInputElement>): void => setCustomerComments(evt.target.value)}
                            value={customerComments}
                            inputProps={{ 'data-testid': 'maintainence-comments', maxLength: ORG_COMMENTS_LIMIT }}
                        />
                        <div data-testid='maintainenceCommentsDivId'
                            style={{ height: 17, width: '100%', color: PXBColors.gray[500] }}>
                            <small data-testid='helperTextIdTechnician' className="form-text" style={{ width: '86%', float: 'left', paddingLeft: '2%', marginBottom: 30 }}>
                                {t('ORGANIZATION_MANAGE.COMMENTS_HELPER')}</small>
                            <Typography data-testid='maintainenceCommentsLimit' style={{ width: 'auto', float: 'right', fontSize: 12, color: PXBColors.gray[500] }}>
                                {customerComments.length}/{ORG_COMMENTS_LIMIT}
                            </Typography>
                        </div>
                        <div>
                            <input
                                type="file"
                                accept=".pdf, .jpeg, .jpg, .png"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                                id="file-upload"
                                multiple
                            />
                            <Button
                                style={{
                                    display: 'flex',
                                    height: 36,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: 8,
                                    width: '100%',
                                }}
                                id="uploadDocument"
                                variant="outlined"
                                color="primary"
                                data-testid="upload-Document"
                                onClick={() => document.getElementById('file-upload')?.click()}
                                disabled={documents.length >= 3}
                            >
                                {md ? t('MAINTENANCE_DETAILS.ADD_DOCUMENT_BUTTON') : <MuiIcons.Add></MuiIcons.Add>}
                            </Button>
                            <div>
                                <ul style={{ display: 'flex', gap: 16, paddingLeft: 0 }}>
                                    {documentsToShowImageIcon?.map((file, index) => (
                                        <li key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 8 }}>
                                            <div style={{ border: '1px solid #ccc', padding: 16, borderRadius: 8, textAlign: 'center', width: 150, height: 155 }}>
                                                {getFileExtension(file.fileName) === 'pdf' ? (
                                                    <FilePdf style={{
                                                        color: PXBColors.gray[500], fontSize: 40,
                                                        display: 'flex',
                                                        width: 96,
                                                        height: 88,
                                                        justifyContent: 'center',
                                                        alignItems: 'center'

                                                    }} />
                                                ) : (

                                                    <MuiIcons.InsertPhoto style={{
                                                        color: PXBColors.gray[500], fontSize: 40,
                                                        display: 'flex',
                                                        width: 96,
                                                        height: 88,
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }} />
                                                )

                                                }
                                                <div style={{ display: 'flex', alignItems: 'center', gap: 8, padding: 8, color: PXBColors.blue[500], fontSize: 14, fontWeight: 600 }}>
                                                    <Tooltip title={file.fileName}>
                                                        <span>{file.fileName.length > 7 ? `${file.fileName.substring(0, 7)}...` : file.fileName}</span>
                                                    </Tooltip>
                                                    <Delete
                                                        style={{ cursor: 'pointer', color: PXBColors.gray[500] }}
                                                        onClick={() => handleDeleteFile(index)}
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            {error && <p style={{ color: 'red', paddingTop: 10 }}>{error}</p>}
                        </div>
                    </div>
                </div>
                <Divider />
            </div>}
            {(!dataLoaded || userManagement.createMaintainanceLoading) && <EmptyState style={{ flex: 1, minHeight: 400, backgroundColor: PXBColors.white[50] }}
                icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}
            {
                displayErrorMessage &&
                <Snackbar
                    open={true}
                    onClose={() => setDisplayErrorMessage(false)}
                    autoHideDuration={5000}
                    title={''}
                    message={alertErrorMessage}
                    action={
                        <Button color="inherit" size="small" onClick={() => setDisplayErrorMessage(false)}>
                            {t('ACTIONS.OKAY')}
                        </Button>
                    }
                    ContentProps={{ sx: { background: PXBColors.red[500] } }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    sx={{ bottom: { xs: 90, sm: 0 } }}

                    style={{ height: 200, width: 200, flexGrow: 0 }}
                />
            }
            <Dialog id="quitdialog" maxWidth="md" open={openQuitDialog} onClose={handleQuitDialog}>
                <DialogTitle id="dialog-header">{t('ORGANIZATION_MANAGE.QUIT_EDIT_USER_DETAILS')}</DialogTitle>
                <DialogContent>
                    <DialogContentText data-testid="dialog-header-text">
                        {t('ORGANIZATION_MANAGE.QUIT_MESSAGE')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        style={{
                            marginBottom: 10,
                            width: 77,
                            height: 36,
                            flexDirection: 'row',
                            marginRight: 10,
                            backgroundColor: PXBColors.white[50],
                        }}
                        id="cancelButton"
                        color="primary"
                        data-testid="cancelButton"
                        onClick={handleCancel}
                    >
                        {t('ACTIONS.CANCEL')}
                    </Button>
                    <Button
                        style={{
                            marginBottom: 10,
                            width: 64,
                            height: 36,
                            flexDirection: 'row',
                            backgroundColor: PXBColors.white[50],
                            color: PXBColors.red[500],
                        }}
                        id="saveButton"
                        variant="text"
                        data-testid="quitButton"
                        onClick={onQuitClick}
                    >
                        {t('ACTIONS.QUIT')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};
