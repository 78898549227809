import { ChangePasswordDialogProgress, SiteRole, UserDetailsData, UserInfo } from "../types";

export const userDetail: UserDetailsData = {
    firstname: '',
    lastname: '',
    display_name: '',
    phone: '',
    email_id: '',
    job_title: null,
    department: null,
    countryCode: '',
    user_phone_number: '',
    company_name: '',
    company_phoneNumner: '',
    company_address: '',
    company_country: '',
    company_countrycode: '',
    company_complete_phone_number: '',
    company_city: '',
    company_postalcode: '',
    accountCreated: '',
    language: 1,
    timeZone: 1,
    dateFormat: 1,
    unitSystem: 1,
    appTheme: 1,
    isAutologoutEnabled: '',
    reportTypePreference: 1,
    reportFrequencyNotificationType: 1,
    productUpdateNotificationType: 1,
    alarmNotificationType: 1,
    statusUpdateNotificationType: 1,
    lastSeen: "3 days ago",
    userStatus: 0,
    roleTypeId: 0,
    platformName: ""
}
export const initialUserInfo: UserInfo = {
    user: userDetail,
    notification: { emailenabled: false, smsenabled: false },
    session: { sessionEnabled: true },
};

export type UserState = {
    getUserDetailLoading: boolean;
    getUserDetailSuccess: boolean;
    getUserDetailFail: boolean;
    userDetail: UserDetailsData,
    getUserDetailErrorMessage: string;
    updateUserDetailLoading: boolean;
    updateUserDetailSuccess: boolean;
    updateUserDetailFail: boolean;
    updateUserDetailErrorMessage: string;
    deleteUserLoading: boolean;
    deleteUserSuccess: boolean;
    deleteUserErrorCode: string;
    changePassword: {
        loading: boolean;
        progress: ChangePasswordDialogProgress;
    };
    sessionLoading: boolean;
    loggedInUser: UserInfo;
    emailLoading: boolean;
    smsLoading: boolean;


    updateSuccess: boolean;
    updateRoleLoading: boolean;
    updateRoleSuccess: boolean;
    updatedRolesSites: SiteRole[];


};

export const roleSiteUser: SiteRole = {
    roleId: '',
    siteIds: [''],
};
export const initialUserState: UserState = {
    updateUserDetailLoading: false,
    updateUserDetailSuccess: false,
    updateUserDetailFail: false,
    userDetail: userDetail,
    deleteUserLoading: false,
    deleteUserSuccess: false,
    deleteUserErrorCode: '',
    updateUserDetailErrorMessage: '',
    getUserDetailLoading: false,
    getUserDetailSuccess: false,
    getUserDetailFail: false,
    getUserDetailErrorMessage: '',
    changePassword: {
        loading: false,
        progress: ChangePasswordDialogProgress.Initial,
    },
    sessionLoading: false,
    loggedInUser: initialUserInfo,
    emailLoading: false,
    smsLoading: false,
    updateRoleLoading: false,
    updateRoleSuccess: false,
    updatedRolesSites: [roleSiteUser],
    updateSuccess: false
};
