import { ALPHA_NUMBERIC, EMAIL_REGEX, PHONE_NUMBER_LENGTH, POSTAL_CODE_LENGTH, RESTRICT_SPEC_REGEX, SLA_NUMBER } from "../constants";

export const isValidChar = (value: string): boolean => RESTRICT_SPEC_REGEX.test(value);

export const validEmail = (emailId: string): boolean =>
    Boolean(
        emailId &&
        typeof emailId === 'string' &&
        emailId.length > 0 && 
        emailId.length <= 75 &&
        EMAIL_REGEX.test(emailId) &&
        emailId.split('@')[0].length <= 64 &&
        emailId.split('@')[1].length <= 48
    );

export const validPhoneNumberLength = (phone: string): boolean => {
    return PHONE_NUMBER_LENGTH.test(phone);
};
export const validPostalCode = (postalCode: string): boolean => {
    return POSTAL_CODE_LENGTH.test(postalCode);
};

export const validSlaNumber = (slaNumber: string): boolean => {
    return SLA_NUMBER.test(slaNumber);
}

export const isAlphabetical = (phone: string): boolean => {
    return ALPHA_NUMBERIC.test(phone);
};

export const isAlphanumeric = (phone: string): boolean => {
    return ALPHA_NUMBERIC.test(phone);
};
export const validExpirationDate = (slaNumber: any): boolean => {
    const currentDate = new Date();
    const enteredDate = new Date(slaNumber);   
      if (enteredDate < currentDate) {
        return false;
      }
    return true;      
};