// eslint-disable-next-line
import React, { useEffect } from 'react';
import {
    Typography,
    Card,
    ListSubheader,
    Divider,
    Button,
    useTheme,
    useMediaQuery,
} from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import * as PXBColors from '@brightlayer-ui/colors';
import { Build } from '@mui/icons-material';
import { COLOR_GRAY_ICON } from '../../constants/color-codes';
import { MaintenanceCardItem } from '../../components/MaintenanceServiceLog/MaintenanceCardItem';
import { useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import { MaintenanceLogData, MaintenanceLogsData } from '../../types/maintenance';
import { CalendarIcon } from '@mui/x-date-pickers';
import * as MuiIcons from '@mui/icons-material';
import * as MuiCore from '@mui/material';
import { getDate, getTimeFromDate } from '../enum/DateTimeFormat';
import { EmptyState } from '@brightlayer-ui/react-components';
import { USER_ROLE_TYPE } from '../enum/ENUM';
import { getUserRoleType, useAuthState } from '../../components/AuthContextProvider';

type Props = {
    addCustomer: Function;
    viewAllClicked: Function;
};

export const MaintenanceCard: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const user = useSelector(Selectors.Usermanagement.usermanagement);
    const [maintenanceData, setMaintenanceData] = React.useState<MaintenanceLogsData>();
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const authState = useAuthState();
    const usertype = getUserRoleType(authState) || '';
    const [completeDate, setCompleteDate] = React.useState('');
    const [scheduledDate, setScheduledDate] = React.useState('');
    const [completeTime, setCompleteTime] = React.useState('');
    const [scheduledTime, setScheduledTime] = React.useState('');

    useEffect(() => {
        if (!user.maintenanceLogsLoading && user.maintenanceLogsSuccess) {
            setMaintenanceData(user.maintenanceLogs);
            setDateTime(user.maintenanceLogs.scheduled[0], 1);
            setDateTime(user.maintenanceLogs.completed[0], 0);
        }
        else if (!user.maintenanceLogsLoading && user.maintenanceLogsFail) {
            setMaintenanceData(undefined);
        }
        else {

        }
        // eslint-disable-next-line
    }, [user.maintenanceLogsLoading, user.maintenanceLogsSuccess, user.maintenanceLogsFail,]);

    const setDateTime = (data: MaintenanceLogData, type: number): void => {
        if (data) {
            if (data.serviceDate) {
                if (data.serviceDate.includes('T')) {
                    const date = data.serviceDate.split('T');
                    if (date && date.length > 0) {
                        if (type === 0) {
                            setCompleteDate(getDate(date[0]));
                            setCompleteTime(getTimeFromDate(data.serviceDate));
                        }
                        else {
                            setScheduledDate(getDate(date[0]));
                            setScheduledTime(getTimeFromDate(data.serviceDate));
                        }
                    }
                }
            }
        }
    };

    return (
        <>
            <Card style={{ flex: '1 1 0px', overflow: 'auto' }} id="maintenance-card-container" data-testid="maintenance-card-container">

                <ListSubheader
                    color="primary"
                    component="div"
                    data-testid="maintenance-card-header"
                    id="maintenance-card-header"
                    style={{
                        display: 'flex',
                        fontWeight: 600,
                        fontSize: 14,
                        alignItems: 'center',
                        overflow: 'auto',
                        whiteSpace: 'nowrap',
                        marginLeft: 10,
                        height: 50,
                    }}
                >
                    <Typography
                        variant="subtitle1"
                        style={{
                            alignItems: 'left',
                            color: PXBColors.blue[400],
                            width: '92.3%',
                            paddingLeft: '10',
                        }}
                        id="maintenance-card-title"
                        data-testid="maintenance-card-title"
                    >
                        {t('MAINTENANCE_DETAILS.MAINTENANCE_OPERATIONS')}
                    </Typography>
                    {usertype === USER_ROLE_TYPE.SERVICE_MANAGER && <Button
                        style={{
                            flexDirection: 'row',
                            backgroundColor: PXBColors.white[50],
                            textTransform: 'none',
                            width: 220,
                            height: 36,
                            color: PXBColors.blue[500],
                            borderColor: PXBColors.blue[500],
                            border: '1px solid rgba(0, 123, 193, 1)', borderRadius: 4,
                        }}
                        id="btnCreateMaintainenceTicket"
                        variant="outlined"
                        color="primary"
                        data-testid="btnCreateMaintainenceTicketId"
                        onClick={(): void => props.addCustomer()}
                    >
                        {md ? t('ASSETS_DETAILS.NEW_MAINTAINENCE') : <MuiIcons.Add></MuiIcons.Add>}
                    </Button>}
                </ListSubheader>
                <Divider />
                {user.maintenanceLogsLoading && <EmptyState style={{ flex: 1, backgroundColor: PXBColors.white[50] }}
                    icon={<MuiCore.CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}

                {!user.maintenanceLogsLoading &&
                    <> <MaintenanceCardItem
                        title1={t('MAINTENANCE_DETAILS.RECENT_MAINTENANCE')}
                        title2={''}
                        title3={''}
                        subtitle={(maintenanceData?.completed !== undefined && maintenanceData.completed.length > 0) ? (maintenanceData?.completed[0].technicianName) : '--'}
                        subtitle2={(maintenanceData?.completed !== undefined && maintenanceData.completed.length > 0) ? (completeTime + '; ' + completeDate) : '--'}
                        icon={<Build style={{ color: COLOR_GRAY_ICON }} />}
                        iconAlignment={'center'}
                        displayAction={false}
                        dividerDisable={true}
                    />
                        <Divider></Divider>
                        <MaintenanceCardItem
                            title1={t('MAINTENANCE_DETAILS.SCHEDULED_MAINTENANCE')}
                            title2={''}
                            title3={''}
                            subtitle={(maintenanceData?.scheduled !== undefined && maintenanceData.scheduled.length > 0) ? (maintenanceData?.scheduled[0].technicianName) : '--'}
                            subtitle2={(maintenanceData?.scheduled !== undefined && maintenanceData.scheduled.length > 0) ? (scheduledTime + '; ' + scheduledDate) : '--'}
                            icon={<CalendarIcon style={{ color: COLOR_GRAY_ICON }} />}
                            iconAlignment={'center'}
                            displayAction={false}
                            dividerDisable={true}
                        />
                    </>}
                <Divider></Divider>
                {(usertype === USER_ROLE_TYPE.SERVICE_MANAGER || usertype === USER_ROLE_TYPE.TECHNICIAN) && <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 16, }}>
                    <Typography
                        variant="subtitle2"
                        data-testid="view-all"
                        style={{
                            color: PXBColors.blue[400],
                        }}
                        id="maintenance-dashboard-viewall"
                    >
                        {t('LABELS.VIEW_ALL')}
                    </Typography>
                    <MuiCore.IconButton style={{ alignItems: 'right', padding: '0' }} onClick={(): void => props.viewAllClicked()}>
                        {' '}
                        <MuiIcons.ChevronRight />{' '}
                    </MuiCore.IconButton>
                </div>}
            </Card >
        </>
    );
};

