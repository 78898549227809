// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, Icon, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Snackbar, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import * as PXBColors from '@brightlayer-ui/colors';
import { pushAppRoute } from '../../data/domain/route-manager';
import { useHistory } from 'react-router';
import { ItemList } from '../../types/logbook-type';
import { useOrganizationList } from '../../data/DataProviders/OrganizationListProvider';
import { ALPHA_NUMBERIC, ActionType, CUSTOMER_SLA_LIMIT, EMPTY_STRING, ORGANIZATTION_NAME } from '../../constants';
import { BuildingList } from '../../types/building';
import { useDispatch, useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import { DeviceList } from '../../types/device';
import { slaCustomerList } from '../../types/sla-customer-details';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { validSlaNumber,validExpirationDate } from '../../lib/form-validator';
import { Thunks } from '../../actions';
import CloseIcon from '@mui/icons-material/Close';
import { Done } from '@mui/icons-material';
import { CustomerThunks } from '../../actions/thunks/customer-thunks';
import { formatDate } from '../../lib/date-time-formatter';
import { useAuthHelper } from '../../components/AuthContextProvider';
import { CustomerActions } from '../../actions/actions/customer-actions';
import { getDate, getNextYearDate } from '../enum/DateTimeFormat';




type Props = {
    closeClick: Function;
    openDialogFlag: boolean;



};
export const AssetAddSLADialog: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const DEFAULT_ID: string = '';
    const [buildings, setBuildings] = useState<ItemList>([]);
    const dashboard = useSelector(Selectors.Dashboard.dashboard);
    const [buildingId, setBuildingId] = React.useState(DEFAULT_ID);
    const [systemList, setSystemList] = useState<ItemList>([]);
    const asset = useSelector(Selectors.Dashboard.asset);
    const [customers, setCustomers] = React.useState<ItemList>([]);
    const [slaNumber, setSlaNumber] = useState('');
    const [slaExpiryDate, setSlaExpiryDate] = React.useState<Date | null>();
    const dispatch = useDispatch();
    const [projectId, setProjectId] = React.useState('');
    const [deviceId, setdeviceId] = React.useState('');
    const [filterType, setFilteredType] = React.useState('');
    const [searchText, setSearchText] = useState('');
    const [customerId, setcustomerId] = React.useState('');
    const [luminarySelection, setluminarySelection] = React.useState(true);
    var slaExpiryDateInString = getDate(slaExpiryDate?.toString());
    const authHelper = useAuthHelper();
    const history = useHistory();
    const [isDataAvailable, setDataAvailable] = React.useState(false);
    var disabled = projectId && buildingId && deviceId && customerId;
    const customer = useSelector(Selectors.Customer.customer);
    const [alertErrorMessage, setAlertErrorMessage] = useState<string>('');
    const [alertSuccessMessage, setAlertSuccessMessage] = useState<string>('');
    const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false);
    const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
    const [openOrgSelectDialog, setOpenOrgSelectDialog] = React.useState(false);
    const [serviceOrganizationListData, setServiceOrganizationListData] = React.useState<ItemList>([]);
    const useOrganizationDataList = useOrganizationList();



    const handleSave = () => {
        dispatch(CustomerThunks.linkCustomer(customerId, deviceId, slaNumber, slaExpiryDateInString, luminarySelection, authHelper));
    };


    const handleSetOrganization = (event: SelectChangeEvent) => {
        setProjectId(event.target.value);
    };
    const handleSetbuildingSelection = (event: SelectChangeEvent) => {
        setBuildingId(event.target.value);
    };
    const handleSetsystemSelection = (event: SelectChangeEvent) => {

        setdeviceId(event.target.value);
    };
    const handleSetcustomerSelection = (event: SelectChangeEvent) => {

        setcustomerId(event.target.value);
    };

    const handleCheck = (event: React.ChangeEvent<HTMLElement>,
        checked: boolean) => {

        if (checked) {
            setluminarySelection(false);
        }
        else {
            setluminarySelection(true);
        }
    }


    //eslint-disable-line react-hooks/exhaustive-deps
    /*useEffect(() => {
         dispatch(Thunks.Asset.getSlaCustomerList(''));
         dispatch(Thunks.Dashboard.getBuildingList(''));
         dispatch(Thunks.Asset.getDeviceList(projectId, buildingId, DEFAULT_ID));
         // eslint-disable-next-line
     }, []);*/
    /* useEffect(() => {
       setcustomerListData(useCustomer.customerList);
   }, [useCustomer.customerList]);*/


    useEffect(() => {
        const nextYearDate = getNextYearDate();
        setSlaExpiryDate(nextYearDate);
        dispatch(Thunks.Asset.getSlaCustomerList(''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        dispatch(Thunks.Dashboard.getBuildingList(projectId, DEFAULT_ID));
        setBuildings([]);
        setSystemList([]);
        // eslint-disable-next-line
    }, [projectId]);

    useEffect(() => {
        dispatch(Thunks.Asset.getDeviceList(projectId, buildingId, DEFAULT_ID, searchText, filterType));
        // eslint-disable-next-line
    }, [buildingId]);


    function closeSelectOrgDialog(): void {
        props.closeClick({});
    }
    const onCancel = () => {
        closeSelectOrgDialog();
        setProjectId(EMPTY_STRING);
        setBuildingId(EMPTY_STRING);
        setdeviceId(EMPTY_STRING);
        setSlaNumber(EMPTY_STRING);
        setSlaExpiryDate(null);
        setcustomerId(EMPTY_STRING);

    }
    const buildingListData = (list: BuildingList): ItemList => {
        return list?.map((data, building) => {
            return {
                id: data.buildingId,
                name: data.name,
            };
        });
    };

    const systemListLogbook = (list: DeviceList): ItemList => {
        return list?.map((data, system) => {
            return {
                id: data.systemId,
                name: data.systemName,
            };
        });
    };

    const customerListData = (list: slaCustomerList): ItemList => {
        return list?.map((data, customer) => {
            return {
                id: data.customerNumber!,
                name: data.companyName,
            };
        });
    };

    const onSlaNumberChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || ALPHA_NUMBERIC.test(e.target.value)) {
            setSlaNumber(e.target.value);
        }
    };

    const handleLinkCustomerResponse = (): void => {
        if (!customer.linkCustomerLoading && customer.linkCustomerFail) {
            if (customer.linkCustomerErrorCode === '9162')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_LINK_CUSTOMER_ERROR_INVALID_SLA'));
            else if (customer.linkCustomerErrorCode === '9169')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_LINK_CUSTOMER_ERROR_INVALID_EXPIRY_DATE'));
            else if (customer.linkCustomerErrorCode === '9001')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_LINK_CUSTOMER_ERROR_INVAILD_TOKEN'));
            else if (customer.linkCustomerErrorCode === '9004')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_LINK_CUSTOMER_SYSTEM_DETAIL_NOT_AVAILABLE'));
            else if (customer.linkCustomerErrorCode === '9050')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_LINK_CUSTOMER_INVALID_SYSTEM_ID'));
            else if (customer.linkCustomerErrorCode === '9172')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_LINK_CUSTOMER_INVALID_CUSTOMER_NUMBER'));
            else if (customer.linkCustomerErrorCode === '9186')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_LINK_CUSTOMER_CUSTOMER_DETAIL_NOT_AVAILABLE'));
            else if (customer.linkCustomerErrorCode === '9171')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_LINK_CUSTOMER_SLA_ALREADY_AVAILABLE'));
            else if (customer.linkCustomerErrorCode === '9204')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_LINK_CUSTOMER_9204'));
            else
                setAlertErrorMessage(t('REGISTRATION.STEPS_ERROR_MESSAGE.GENERIC_ERROR_MESSAGE'));
        }

    };


    useEffect(() => {
        if (!dashboard.buildingListLoading && dashboard.buildingListSuccess) {
            setBuildings(buildingListData(dashboard.buildingList.detail));

        } else if (!dashboard.buildingListLoading && dashboard.buildingListFail) {
            setBuildings([]);
        }
    }, [dashboard.buildingListLoading, dashboard.buildingListSuccess, dashboard.buildingListFail]); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        setServiceOrganizationListData(useOrganizationDataList.organizationList);
        setDataAvailable(true);
    }, [useOrganizationDataList, useOrganizationDataList.organizationList]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!asset.deviceListLoading && asset.deviceListSuccess) {
            setSystemList(systemListLogbook(asset.deviceList.detail));

        } else if (!asset.deviceListLoading && asset.deviceListFail) {
            setSystemList([]);
        }
    }, [asset.deviceListLoading, asset.deviceListSuccess, asset.deviceListFail]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!asset.slaCustomerListLoading && asset.slaCustomerListSuccess) {
            setCustomers(customerListData(asset.slaCustomerList));

        } else if (!asset.slaCustomerListLoading && asset.slaCustomerListFail) {
            setCustomers([]);
        }
    }, [asset.slaCustomerListLoading, asset.slaCustomerListSuccess, asset.slaCustomerListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    const goToCreateCustomer = (): void => {
        pushAppRoute(history,
            { type: 'AddCustomerDetails', actionType: ActionType.ADD },
            { backRoute: 'Assets', deviceId: deviceId, projectId: projectId, }
        );
    };

    useEffect(() => {
        if (!customer.linkCustomerLoading && customer.linkCustomerSuccess) {
            setAlertSuccessMessage(t('TOAST_MSG.TOAST_SUCCESS_LINK_CUSTOMER_DIALOG'));
            setOpenOrgSelectDialog(false);
            setDisplaySuccessMessage(true);
            onCancel();

        } else if (!customer.linkCustomerLoading && customer.linkCustomerFail) {
            handleLinkCustomerResponse();
            setDisplayErrorMessage(true);
            setOpenOrgSelectDialog(false);

        }
        dispatch(CustomerActions.linkCustomerUnmounted());
    }, [customer.linkCustomerLoading, customer.linkCustomerSuccess, customer.linkCustomerFail]); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <>

            <Dialog data-testid='addslaSelectDialog' id="addsla-select-dialog" open={props.openDialogFlag}  >

                <DialogTitle data-testid='addslaformId' sx={{ m: 0, p: 2.5 }} style={{ paddingLeft: 24, paddingTop: 24, paddingBottom: 24, height: 75 }} id="addsla-dialog-title">
                    {t('ASSETS_DETAILS.ADD_SLAS')}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onCancel}
                    sx={{
                        position: 'absolute',
                        right: 20,
                        paddingLeft: 3,
                        paddingRight: 3,
                        width: 24,
                        height: 24,

                        color: (theme) => theme.palette.grey[500],
                    }}
                    style={{ height: 75 }}
                >
                    <CloseIcon />
                </IconButton>


                <DialogContent data-testid='checkId' style={{ width: 450, paddingTop: 24 }}>
                    <>
                        <div  >
                            <FormControl variant={'filled'} fullWidth required>
                                <InputLabel data-testid='checkId1' >
                                    {t('ENTITY_TYPE.ORGANIZATION')}
                                </InputLabel>
                                <Select
                                    variant={'filled'}
                                    fullWidth style={{ marginBottom: 24, backgroundColor: PXBColors.white[500] }}
                                    labelId="addsla-select-label"
                                    inputProps={{ 'data-testid': 'addsla-select' }}
                                    data-testid="addsla-select"
                                    value={projectId}
                                    onChange={handleSetOrganization}

                                >
                                    {serviceOrganizationListData.map((data) => {
                                        return (
                                            <MenuItem id="addsla-org-list" key={data.id} value={data.id}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'left', fontSize: 14,
                                                    lineHeight: 1.5, fontWeight: 400,
                                                    overflow: 'hidden',
                                                    boxSizing: 'border-box',
                                                    whiteSpace: 'nowrap',
                                                    minHeight: 48,
                                                    paddingTop: 6,
                                                    paddingBottom: 6,
                                                    width: 'auto',
                                                    paddingLeft: 16,
                                                    paddingRight: 16
                                                }}>
                                                {data.name}
                                            </MenuItem>

                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            <FormControl variant={'filled'} fullWidth required>
                                <InputLabel data-testid='inputLabel' >
                                    {t('ENTITY_TYPE.BUILDING')}
                                </InputLabel>
                                <Select
                                    variant={'filled'}
                                    fullWidth style={{ marginBottom: 24, backgroundColor: PXBColors.white[500] }}
                                    labelId="addsla-select-label"
                                    inputProps={{ 'data-testid': 'addsla-select' }}
                                    data-testid="addsla-select"
                                    value={buildingId}
                                    onChange={handleSetbuildingSelection}
                                    disabled={!projectId}

                                >
                                    {buildings.map((data) => {
                                        return (
                                            <MenuItem id="building-list" key={data.id} value={data.id}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'left', fontSize: 14,
                                                    lineHeight: 1.5, fontWeight: 400,
                                                    overflow: 'hidden',
                                                    boxSizing: 'border-box',
                                                    whiteSpace: 'nowrap',
                                                    minHeight: 48,
                                                    paddingTop: 6,
                                                    paddingBottom: 6,
                                                    width: 'auto',
                                                    paddingLeft: 16,
                                                    paddingRight: 16
                                                }}>
                                                {data.name}

                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            <FormControl variant={'filled'} fullWidth required>
                                <InputLabel data-testid='inputLabel2' >
                                    {t('ENTITY_TYPE.SYSTEM')}
                                </InputLabel>

                                <Select
                                    variant={'filled'}
                                    fullWidth style={{ marginBottom: 24, backgroundColor: PXBColors.white[500] }}
                                    labelId="org-select-label"
                                    inputProps={{ 'data-testid': 'addsla-select' }}
                                    data-testid="addsla-select"
                                    value={deviceId}
                                    onChange={handleSetsystemSelection}
                                    disabled={!buildingId}

                                >
                                    {systemList.map((data) => {
                                        return (
                                            <MenuItem id="addsla-list" key={data.id} value={data.id}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'left', fontSize: 14,
                                                    lineHeight: 1.5, fontWeight: 400,
                                                    overflow: 'hidden',
                                                    boxSizing: 'border-box',
                                                    whiteSpace: 'nowrap',
                                                    minHeight: 48,
                                                    paddingTop: 6,
                                                    paddingBottom: 6,
                                                    width: 'auto',
                                                    paddingLeft: 16,
                                                    paddingRight: 16
                                                }}>
                                                {data.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        <div >
                            <FormControl variant={'filled'} fullWidth required>
                                <InputLabel data-testid='inputLabel3' >
                                    {t('USERS.CUSTOMER')}
                                </InputLabel>
                                <Select
                                    variant={'filled'}
                                    fullWidth style={{ marginBottom: 24, backgroundColor: PXBColors.white[500] }}
                                    labelId="org-select-label"
                                    inputProps={{ 'data-testid': 'addsla-select' }}
                                    data-testid="addsla-select"
                                    value={customerId}
                                    onChange={handleSetcustomerSelection}
                                    disabled={!deviceId}

                                >

                                    <Typography data-testid="create-customer"
                                        style={{

                                            backgroundColor: PXBColors.white[50],
                                            color: PXBColors.black[400],
                                            flexDirection: 'row',
                                            textTransform: 'none',
                                            paddingLeft: 16,
                                            paddingRight: 16,
                                            minHeight: 48,
                                            paddingTop: 6,
                                            paddingBottom: 6
                                        }}
                                        id="create-customer"
                                        color="primary"
                                    >

                                        {t('ASSETS_DETAILS.CREATE_NEW_CUSTOMER_BUTTON')}
                                    </Typography>

                                    <Divider></Divider>

                                    {customers.map((data) => {
                                        return (
                                            <MenuItem id="customer-list" key={data.id} value={data.id}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'left', fontSize: 14,
                                                    lineHeight: 1.5, fontWeight: 400,
                                                    overflow: 'hidden',
                                                    boxSizing: 'border-box',
                                                    whiteSpace: 'nowrap',
                                                    minHeight: 48,
                                                    paddingTop: 6,
                                                    paddingBottom: 6,
                                                    width: 'auto',
                                                    paddingLeft: 16,
                                                    paddingRight: 16
                                                }}>
                                                {data.name}

                                            </MenuItem>

                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                    </>
                    <div data-testid='customer-detail-right-container' >
                        <TextField
                            style={{ fontSize: 16, color: PXBColors.black[500] }}
                            id="slaCustomerNumber"
                            data-testid='slaCustomerNumberLabelId'
                            variant={'filled'}
                            label={t('CUSTOMER_DETAILS.SLA_NUMBER')}
                            error={slaNumber.length > 0 && !validSlaNumber(slaNumber)}
                            helperText={slaNumber.length > 0 && !validSlaNumber(slaNumber) ? (t('FORMS.INVALID_SLA_NUMBER')) : ''}
                            fullWidth
                            required
                            value={slaNumber}
                            onChange={(e): void => onSlaNumberChange(e)}
                            inputProps={{ 'data-testid': 'slaCustomerNumberLabelId2', maxLength: CUSTOMER_SLA_LIMIT }}
                        />

                        <div data-testid='slaCustomerNumberhelperTextId'
                            style={{ color: PXBColors.gray[500] }}>
                            <Typography data-testid='customerSLAcharLimitId' style={{ width: 'auto', marginLeft: 15, fontSize: 12, color: PXBColors.gray[500], marginBottom: 24 }}>
                                {slaNumber.length}/{CUSTOMER_SLA_LIMIT}
                            </Typography>
                        </div>
                        <div data-testid='calendarDivId' style={{ display: 'flex', width: '100%', flexDirection: 'column', marginBottom: 24 }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} >
                                <DatePicker
                                    minDate={new Date()}
                                    value={slaExpiryDate}
                                    onChange={(newValue) => setSlaExpiryDate(newValue)}
                                    slotProps={{
                                        textField: {
                                            required: true,
                                            helperText: (!validExpirationDate(slaExpiryDate)? (t('FORMS.SLA_EXPIRATION_DATE_INVALID')) : t('CUSTOMER_DETAILS.CALENDAR_HELPER_TEXT')),
                                            variant: 'filled',
                                            label: t('CUSTOMER_DETAILS.SLA_EXPIRATION_DATE'),
                                            error: (!validExpirationDate(slaExpiryDate)),
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                        </div>

                        <FormControlLabel style={{ marginLeft: 5, marginBottom: 5, fontWeight: 600 }} control={<Checkbox onChange={handleCheck} />} label={<Typography style={{ fontWeight: 600 }} data-testid="checkbox-labelId">Include luminaire monitoring</Typography>} />

                    </div>
                </DialogContent>



                <Divider style={{ color: 'grey' }} ></Divider>
                <DialogActions style={{ width: 450, height: 75 }}>
                    <Button style={{ textTransform: 'none', marginRight: 16 }} id="btnCancel" data-testid="btnCancel1" variant="outlined" color="primary" disabled={true} onClick={(): void => onCancel()}>
                        {t('ACTIONS.SAVE_ADD_ANOTHER')}
                    </Button>
                    <Button disabled={!projectId && !buildingId && !deviceId && !slaNumber && !slaExpiryDate && !customerId} style={{
                        backgroundColor: disabled ? PXBColors.blue[500] : PXBColors.blue[50],
                        color: disabled ? PXBColors.white[50] : PXBColors.blue[200],
                        opacity: (projectId && buildingId && deviceId && slaNumber && slaExpiryDate && customerId) ? 1 : 0.5,
                        textTransform: 'none'
                    }} id="btnCancel" data-testid="btnSave" variant="contained" onClick={handleSave}>
                        <Done style={{ marginRight: 5 }} />  {t('ACTIONS.SAVE')}

                    </Button>




                </DialogActions>

            </Dialog >
            {
                displaySuccessMessage &&
                <Snackbar
                    open={true}
                    onClose={() => setDisplaySuccessMessage(false)}
                    autoHideDuration={5000}
                    title={''}
                    message={alertSuccessMessage}
                    action={
                        <Button color="inherit" size="small" onClick={() => setDisplaySuccessMessage(false)}>
                            {t('ACTIONS.OKAY')}
                        </Button>
                    }
                    ContentProps={{ sx: { background: PXBColors.black[900] } }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    sx={{ bottom: { xs: 90, sm: 0 } }}
                    style={{ height: 200, width: 200, flexGrow: 0 }}
                />
            }

            {
                displayErrorMessage &&
                <Snackbar
                    open={true}
                    onClose={() => setDisplayErrorMessage(false)}
                    autoHideDuration={5000}
                    title={''}
                    message={alertErrorMessage}
                    action={
                        <Button color="inherit" size="small" onClick={() => setDisplayErrorMessage(false)}>
                            {t('ACTIONS.OKAY')}
                        </Button>
                    }
                    ContentProps={{ sx: { background: PXBColors.red[500] } }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    sx={{ bottom: { xs: 90, sm: 0 } }}

                    style={{ height: 200, width: 200, flexGrow: 0 }}
                />
            }

        </>
    );

};





