// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
import { Divider, Card, ListSubheader, Typography, Collapse, CircularProgress } from '@mui/material';
import List from '@mui/material/List';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import * as MuiCore from '@mui/material';
import * as MuiIcons from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import * as PXBColors from '@brightlayer-ui/colors';
import ListItemButton from '@mui/material/ListItemButton';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { EmptyState } from '@brightlayer-ui/react-components';
import { MaintenanceServiceLogListItem } from '../../components/MaintenanceServiceLog/MaintenanceServiceLogListItem';
import { MaintenanceDetailsList } from '../../types/maintenance';

type Props = {
    userId?: string;
    projectId?: string;
    buildingId?: string;
    isDashboard?: boolean;
    isService?: boolean;
    defaultOpenState: boolean;
    title?: string;
    OnTicketItemSelected: Function;
    onLimitOffSetChange?: Function;
    OnViewAllClicked: Function;
    isFilterApplied?: boolean;
    resetFilterFlag?: Function;
};

export const OverviewMaintenanceServiceDueLogs: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const [open, setOpen] = React.useState(props.defaultOpenState);
    const { t } = useLanguageLocale();
    const user = useSelector(Selectors.Usermanagement.usermanagement);
    const [isDataLoaded, setIsDataLoaded] = React.useState(false);
    const [maintenanceActivityList, setMaintenanceActivityList] = useState<MaintenanceDetailsList>([]);

    const handleClick = () => {
        setOpen(!open);
    };

    useEffect(() => {
        if (props.isFilterApplied) {
            if (props.resetFilterFlag) {
                props.resetFilterFlag(false);
            }
        }
    }, [props.isFilterApplied]); // eslint-disable-line react-hooks/exhaustive-deps

    const getMaintenanceList = (list: MaintenanceDetailsList): MaintenanceDetailsList => {
        return list.map((data, maintenanceActivity) => {
            return (
                {
                    customerName: data.customerName,
                    ticketNumber: data.ticketNumber,
                    systemName: data.systemName,
                    technicianName: data.technicianName,
                    serviceType: data.serviceType,
                    serviceDate: data.serviceDate,
                    sLANumber: data.sLANumber,
                    alarmLink: data.alarmLink,
                    assignedTo: data.assignedTo,
                    customerId: data.customerId,
                    description: data.description,
                    id: data.id,
                    isFavorite: data.isFavorite === 'true' ? 'true' : 'false',
                    lastUpdatedDateTime: data.lastUpdatedDateTime,
                    organizationId: data.organizationId,
                    organizationName: data.organizationName,
                    requestPriority: data.requestPriority,
                    systemId: data.systemId,
                    systemLocation: data.systemLocation,
                    systemStatus: data.systemStatus,
                    ticketStatus: data.ticketStatus,
                    systemType: data.systemType,
                    notes: [],
                    resolutions: [],
                    buildingId: data.buildingId,
                    buildingName: data.buildingName,
                }
            );
        })
    };

    useEffect(() => {
        if (user.maintenanceDetailLoading) {
            setIsDataLoaded(false);
        }
        else {
            if (!user.maintenanceDetailLoading && user.maintenanceDetailSuccess) {
                if (user.maintenanceDetail.detail.length > 0) {
                    const MaintenanceList = getMaintenanceList(user.maintenanceDetail.detail);
                    setMaintenanceActivityList(MaintenanceList);
                    setIsDataLoaded(false);
                } else {
                    setMaintenanceActivityList([]);
                    setIsDataLoaded(true);
                }
                
            } else if (!user.maintenanceDetailLoading && user.maintenanceDetailFail) {
                setMaintenanceActivityList([]);
                setIsDataLoaded(true);
            }
        }

    }, [user.maintenanceDetailLoading, user.maintenanceDetailSuccess, user.maintenanceDetailFail]); // eslint-disable-line react-hooks/exhaustive-deps

    const OnTicketItemSelected = (maintenanceId: string): void => {
        props.OnTicketItemSelected(maintenanceId);
    };

    return (
        <>
            <Card style={{ flex: '1 1 0px', overflow: 'auto' }} data-testid="system-log-list-container">
                <List
                    component="div"
                    disablePadding
                    id="system-log-list"
                    data-testid="system-log-list"
                    style={props.isDashboard ? { minHeight: 'auto' } : {}}
                    subheader={
                        <>
                            <ListSubheader
                                color="primary"
                                component="div"
                                data-testid="system-log-list-header"
                                id="system-log-list-header"
                                style={{
                                    display: 'flex',
                                    fontWeight: 600,
                                    fontSize: 14,
                                    alignItems: 'center',
                                    overflow: 'auto',
                                    whiteSpace: 'nowrap',
                                    marginLeft: 10,
                                    height: 45,
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    style={{
                                        alignItems: 'left',
                                        fontSize: 14,
                                        fontWeight: 600,
                                        color: PXBColors.blue[400],
                                        width: '92.3%',
                                        paddingLeft: '10',
                                    }}
                                    id="system-log-title"
                                    data-testid="system-log-title"
                                >
                                    {props.title}
                                </Typography>

                                <ListItemButton
                                    onClick={handleClick}
                                    id="list-header-item-button"
                                    style={{
                                        width: '7.41%',
                                    }}
                                >
                                    {open ? (
                                        <KeyboardArrowUp data-testid="arrow-up" id="list-header-up-icon" />
                                    ) : (
                                        <KeyboardArrowDown data-testid="arrow-down" id="list-header-down-icon" />
                                    )}
                                </ListItemButton>
                            </ListSubheader>
                            <Divider />
                        </>
                    }
                >
                    {(user.maintenanceDetailLoading) && <EmptyState style={{ flex: 1, minHeight: 300, backgroundColor: PXBColors.white[50] }}
                        icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}

                    {!(user.maintenanceDetailLoading) && <Collapse in={open} timeout="auto" unmountOnExit>
                        {isDataLoaded && <EmptyState style={{ flex: 1, height: 200 }}
                            title={t('DASHBOARD.NO_DATA')} icon={undefined} placeholder={undefined} />}

                        {maintenanceActivityList?.map((data, logs) => {
                            return (
                                <MaintenanceServiceLogListItem
                                    key={'maintanance-log-key-' + data.id}
                                    OnTicketItemSelected={(id: string): void => OnTicketItemSelected(id)}
                                    value={{
                                        customerName: data.customerName,
                                        ticketNumber: data.ticketNumber,
                                        systemName: data.systemName,
                                        technicianName: data.technicianName,
                                        serviceType: data.serviceType,
                                        serviceDate: data.serviceDate,
                                        sLANumber: data.sLANumber,
                                        alarmLink: data.alarmLink,
                                        assignedTo: data.assignedTo,
                                        customerId: data.customerId,
                                        description: data.description,
                                        id: data.id,
                                        isFavorite: data.isFavorite === 'true' ? 'true' : 'false',
                                        lastUpdatedDateTime: data.lastUpdatedDateTime,
                                        organizationId: data.organizationId,
                                        organizationName: data.organizationName,
                                        requestPriority: data.requestPriority,
                                        systemId: data.systemId,
                                        systemLocation: data.systemLocation,
                                        systemStatus: data.systemStatus,
                                        ticketStatus: data.ticketStatus,
                                        systemType: data.systemType,
                                        buildingId: data.buildingId,
                                        buildingName: data.buildingName,
                                    }}
                                ></MaintenanceServiceLogListItem>
                            );
                        })}
                    </Collapse>}
                </List>
                <div data-testid='viewScheduleJobLink'>
                    {props.isDashboard && <Divider />}

                    {/* //TODO- Hide for SM untill UI/API available */}
                    {props.isDashboard && (
                        <>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 16, }}>
                                <Typography
                                    variant="subtitle2"
                                    data-testid="view-all"
                                    style={{
                                        color: PXBColors.blue[400],
                                    }}
                                    id="maintenance-dashboard-viewall"
                                >
                                    {t('LABELS.VIEW_ALL')}
                                </Typography>
                                <MuiCore.IconButton style={{ alignItems: 'right', padding: '0' }} onClick={():void=>props.OnViewAllClicked()}>
                                    {' '}
                                    <MuiIcons.ChevronRight />{' '}
                                </MuiCore.IconButton>
                            </div>
                        </>
                    )}
                </div>
            </Card>
        </>
    );
};
