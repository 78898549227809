export const SYSTEM_LOG_TIMELINE = '/api/device/v3/getlogs';
export const PREVIOUS_LOG_TIMELINE = '/api/device/v2/getpreviouslog';

export const FEATURE_FLAG = '/api/security/featureflags';
export const CHANGE_PASSWORD = '/api/security/user/password';
export const LOGIN = '/api/v2/Security/login';
export const LOGOFF = '/api/security/logoff/:userId';
export const REFRESH = '/api/security/token/refresh';
export const RESET_PASSWORD = '/api/security/reset/password';
export const RESET_PASSWORD_REQUEST = '/api/security/reset/:email/password';
export const RESET_PASSWORD_VALIDATION = '/api/security/reset/validate';
export const SIGNALR = '/api/security/notification';

export const ORGANIZATION_LIST = '/api/site/:userId/organizations';
export const PROJECT_LIST = '/api/project/getprojectlist';
export const ORGANIZATION_DETAILS = '/api/site/v2/myorganizationlist';
export const BUILDING_DETAILS = '/api/site/getbuildingdetails';
export const TRANSFER_BUILDING = '/api/site/transferbuilding';
export const LINK_CUSTOMER = '/api/user/linkcustomer';
export const ORGANIZATION_UPDATE = '/api/site/organization';
export const BUILDING_UPSERT = '/api/site/managebuilding';

export const UPDATE_USER_PROFILE = '/api/user/:userId/role';
export const USER_SETTING_NOTIFICATION = '/api/v4/user/updateuserdetails';
export const USER_LIST = '/api/user/:siteId/users';
export const USER_DELETE = '/api/user/registration';
export const USER_GET_INVITATION = '/api/user/inviteUserType/:invitationId';
export const USER_GET_INVITATION_HISTORY = '/api/user/invitationhistory';
export const GET_ADMIN_INVITATION_APPROVAL_HISTORY = '/api/user/getinvitationapprovalist';
export const SEND_INVITATION_ACCESS = '/api/user/requestinvitation';
export const UPDATE_USER_INVITATION = '/api/user/acceptinvitation';
export const UPDATE_INVITATION_REQUEST = '/api/user/respondtoinvitationrequest';
export const UPSERT_USER_ACCESS_PROFILE = '/api/user/upsertuseraccessprofile/:emailId';
export const USER_REGISTRATION = '/api/user/updatecompanydetail';
export const USER_SELF_REGISTRATION = '/api/v3/user/selfregistration';
export const USER_REGISTRATION_REQUEST = '/api/user/registration/invite';
export const USER_REGISTRATION_VERIFICATION_CODE_REQUEST = '/api/user/registration/sendverificationemail';
export const USER_REGISTRATION_VERIFY_CODE_REQUEST = '/api/user/selfregistration/validate';
export const USER_REGISTRATION_EMAIL_VALIDATION = '/api/user/isRegistered/:email';
export const USER_REGISTRATION_VALIDATION = '/api/user/registration/validate';
export const USER_REGISTRATION_ROLES = '/api/user/registration/roles';
export const USER_ROLES = '/api/user/:userId/roles';
export const USER_ROLES_OPERATIONS = '/api/user/:roleId/operations';
export const USER_SET_SETTING = '/api/user/settings';
export const USER_SETTING = '/api/v4/user/getuserdetails';
export const GET_CUSTOMER_DETAIL = '/api/user/customerinfo';
export const GET_SERVICE_PARTNER_DETAIL = '/api/user/getservicepartnerdetails';
export const UPDATE_CUSTOMER_DETAIL = '/api/user/v2/customerinfo';
export const ADD_SERVICEPARTNER_CUSTOMER_DETAIL = '/api/user/servicepartnercustomer';
export const GET_SERVICEPARTNER_CUSTOMER_LIST = '/api/user/servicepartnercustomerlist';
export const GET_COUNTRY_LIST = '/api/user/countrylist';
export const GET_BUILDING_LIST = '/api/building/v2/getbuildinglist';
export const GET_DEVICE_LIST = '/api/device/v2/systemdetail';
export const GET_LUMINARY_LIST = '/api/device/v2/getluminarydetails';
export const GET_GATEWAY_DETAILS_LIST = '/api/site/v2/getgatewaydetails';
export const GET_SLA_LIST = '/api/user/getsladetaillist';
export const GET_SLA_CUSTOMER_LIST = '/api/user/getcustomerlist';
export const GET_SYSTEM_STATISTIC = '/api/device/statisticalsummary';
export const GET_ORG_USERS = '/api/site/usersDetail';
export const GET_TECHNICIANS_LIST = '/api/user/gettechnicianslist';
export const GET_PLATFORM_LIST = '/api/site/getplatformlist';
export const GET_SERVICEMANAGERS_LIST = '/api/user/getservicemanagerslist';
export const INVITE_USER = '/api/user/invite';
export const ADD_PLATFORM = '/api/site/manageplatform';
export const ADD_SERVICE_MANAGER = '/api/user/addservicemanager';
export const UPSERT_LOG_COMMENTS = '/api/logs/updateLogDetail';
export const LOG_ACKNOWLEDGE = '/api/device/acknowledgelog';
export const MARK_ENTITY_AS_FAV = '/api/user/addfavorite';
export const REMOVE_MARK_AS_FAV = '/api/user/removefavorite';
export const LOGBOOK_DOWNLOAD_REPORT = '/api/device/downloadlogbookreport';
export const GET_HEALTH_REPORT = '/api/device/gethealthreport';
export const HEALTH_REPORT_DOWNLOAD = '/api/device/downloadhealthreport';
export const GET_INVITE_ACCESS_DETAIL = '/api/user/inviteEntityAccessDetail';

export const GET_DASHBOARD_CUSTOMER_LIST = "/api/user/getdashboardcustomerlist";
export const GET_CUSTOMER_LIST = "/api/user/v2/getdashboardcustomerlist";
export const GET_DASHBOARD_ORGANIZATION_LIST = "/api/site/v2/serviceorganizationlist";
export const GET_SERVICE_PARTNER_CUSTOMER_DETAIL_LIST = "/api/user/getservicepartnerdetaillist";
export const GET_SERVICE_MANAGER_CUSTOMER_DETAIL_LIST = "/api/user/getcustomerdetaillist";
export const DELETE_USER = "/api/user/deleteuseraccount";
export const REMOVE_ACCESS = "/api/user/removeuseraccess";
export const MERGE_BUILDING_ASSETS = "/api/site/mergebuildingassets";
export const PLATFORM_SYSTEM_INFO = "/api/device/v1/platformsysteminfo";
export const GET_PLATFORM_DETAIL = "/api/user/getplatformdetails";
export const REMOVE_SERVICE_USER = "/api/v1/user/removeserviceuser";
export const GET_MAINTENANCE_ACTIVITY = "/api/user/getmaintenanceactivity";
export const GET_MAINTENANCE_LOGS = "/api/device/getmaintenancelogs";
export const CREATE_MAINTAINANCE_ACTIVITY = "/api/user/managemaintenanceactivity";
export const ADD_NOTES_ACTIVITY = "/api/device/addnotetomaintenance";
export const UPDTAE_MAINTENANCE_ACTIVITY = "/api/device/updatemaintenancestatus";
export const DOWNLOAD_DOCUMENT = '/api/user/v1/documentdownload';