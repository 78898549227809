// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
import {
    CardContent,
    Typography,
    Divider,
    IconButton,
    TextField,
    Box,
} from '@mui/material';
import { OverviewStatusList } from '../../components/OverviewList/OverviewStatusList';
import * as PXBColors from '@brightlayer-ui/colors';
import * as MuiIcons from '@mui/icons-material';
import * as MuiCore from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { OverviewList, OverviewSelectedData } from '../../types/overview-type';
import { ORGANIZATTION_NAME } from '../../constants';
import { TooltipItem } from '../../components/TooltipItem/TooltipItem';
import { EmptyState } from '@brightlayer-ui/react-components';
import { Close, Search } from '@mui/icons-material';
import { ENTITY_TYPE, USER_ROLE_TYPE } from '../enum/ENUM';
import { OrgBuildingData } from '../../types/organization';
import { vh_18, vh_65, vh_900, vw_10, vw_300 } from '../../constants/dimentions';
import { BuildingContactDetails } from '../assets/BuildingContactDetails';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getControlledText } from '../enum/GenericFormat';
import { getUserRoleType, useAuthState } from '../../components/AuthContextProvider';


type Props = {
    userId?: string;
    selectId: string;
    buildingID: string;
    selectName: string;
    selectLocation: string;
    list: OverviewList;
    listReady: OverviewList;
    listAnomaly: OverviewList;
    listBlock: OverviewList;
    listEmpty: OverviewList;
    users: string;
    displayAllList: boolean;
    listType: number;
    OnItemSelected?: Function;
    OnUserSelected?: Function;
    OnEditSelected?: Function;
    OnSearchTextChanged?: Function;
    isDataLoading: boolean;
    isDeviceList: boolean
    buildingContactName?: string;
    customerId?: string;
};
export const buildingDetailsDefault: OrgBuildingData = {
    buildingId: "",
    name: "Loading...",
    parentProjectId: "",
    projectName: "",
    address: "",
    postalCode: "",
    city: "",
    country: "",
    contact: 'Shanti',
    email: "",
    countryCode: "",
    phone: "",
    description: "",
    adopterId: '',
    latitude: "",
    longitude: "",
    gatewayCount: 0,
    systemCount: 0,
    adminCount: 0,
    viewerCount: 0,
    isAdmin: false,
    isFavourite: false,
    address2: '',
    secondaryEmail: '',
    secondaryCountryCode: '',
    secondaryPhone: '',
    primaryFirstName: 'TestData',
    primaryLastName: 'TestData',
    secondaryFirstName: 'TestData',
    secondaryLastName: 'TestData'
};

export const OverviewListComponent: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const [searchActive, setSearchActive] = useState(false);
    const [isbuildingContactDrawerOpen, setIsbuildingContactDrawerOpen] = React.useState(false);
    const BUILDING_SEARCH_LIMIT = 24;
    const [searchText, setSearchText] = useState('');
    const primaryContact = props.buildingContactName;
    const { t } = useLanguageLocale();
    const theme = useTheme();
    const authState = useAuthState();
    const usertype = getUserRoleType(authState) || '';
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const [selectName, setSelectName] = React.useState(props.selectName);
    const [selectLocation, setSelectLocation] = React.useState(props.selectLocation);
    const [displayAllList, setDisplayAllProject] = useState(props.displayAllList);
    const [users, setUsers] = React.useState(props.users);
    const { OnItemSelected, OnUserSelected, OnSearchTextChanged, OnEditSelected } = props;

    const [list, setList] = useState<OverviewList>(props.list);
    const [listReady, setListReady] = useState<OverviewList>(props.listReady);
    const [listEmpty, setListEmpty] = useState<OverviewList>(props.listEmpty);
    const [listAnomaly, setListAnomaly] = useState<OverviewList>(props.listAnomaly);
    const [listBlock, setListBlock] = useState<OverviewList>(props.listBlock);

    const listTitle = props.listType === ENTITY_TYPE.BUILDING ? t('DASHBOARD.ALL_BUILDINGS_COUNT', { count: props.isDataLoading ? 0 : list.length }) : t('DASHBOARD.ALL_DEVICES_COUNT', { count: props.isDataLoading ? 0 : list.length })
    const searchTextHint = props.listType === ENTITY_TYPE.BUILDING ? t('ORGANIZATION_MANAGE.SEARCH_BUILDINGS_DASHBOARD') : t('ORGANIZATION_MANAGE.SEARCH_SYSTEM_DASHBOARD')

    useEffect(() => {
        setList(props.list);
        setListReady(props.listReady);
        setListAnomaly(props.listAnomaly);
        setListEmpty(props.listEmpty);
        setListBlock(props.listBlock);
        setSelectName(props.selectName);
        setSelectLocation(props.selectLocation);
        setDisplayAllProject(props.displayAllList);
        setUsers(props.isDataLoading ? t('MESSAGES.LOADING') : props.users);
    }, [props.selectId, props.list, props.users, props.selectName, props.selectLocation]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setSearchActive(false);
        setSearchText('');
    }, [props.selectId, props.customerId]); // eslint-disable-line react-hooks/exhaustive-deps

    const buildingContactDrawerClose = (): void => {
        setIsbuildingContactDrawerOpen(false);
    };

    const setSelected = (selectedData: OverviewSelectedData): void => {
        if (OnItemSelected) OnItemSelected(selectedData);
    };

    const OnUserSelectedAction = (): void => {
        if (OnUserSelected) OnUserSelected();
    };

    const OnEditSelectedAction = (): void => {
        if (OnEditSelected) OnEditSelected();
    };

    const handleSearchChange = (e: { target: { value: string; }; }) => {
        if (e.target.value === '' || ORGANIZATTION_NAME.test(e.target.value as string))
            setSearchText(e.target.value);
        if (OnSearchTextChanged) OnSearchTextChanged(e.target.value);
    };

    const onBuildingContactSelect = (): void => {
        setIsbuildingContactDrawerOpen(true);
    };

    return (
        <>
            <div style={{ flex: '1 1 0px', zIndex: 5, overflow: (usertype === USER_ROLE_TYPE.SERVICE_MANAGER || usertype === USER_ROLE_TYPE.TECHNICIAN) ? '' : 'auto', marginTop: displayAllList || (usertype === USER_ROLE_TYPE.SERVICE_MANAGER || usertype === USER_ROLE_TYPE.TECHNICIAN) ? '' : -40, }} data-testid="building-list-container">

                {usertype !== USER_ROLE_TYPE.SERVICE_MANAGER && displayAllList && <Box style={{ backgroundColor: '#ffffff', marginBottom: 8, boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)', borderRadius: 4 }}>

                    <div style={{ display: 'flex', alignItems: 'center', overflow: 'auto', whiteSpace: 'nowrap', marginRight: 16 }}>
                        <CardContent style={{ flex: '1 1 0px', overflow: 'auto', alignItems: 'left' }}>
                            <TooltipItem
                                title={selectName}
                                isTopPlacement={true}
                                lenghtLimit={22}
                                component={
                                    <Typography variant="body2" style={{ fontSize: 24 }} id="project-name" data-testid="project-name" >{getControlledText(selectName, 22)}</Typography>
                                }>
                            </TooltipItem>

                            <TooltipItem
                                title={selectLocation}
                                isTopPlacement={false}
                                lenghtLimit={30}
                                component={
                                    <Typography variant="body2" style={{ fontSize: 14 }} id="project-location" >{getControlledText(selectLocation, 30)}</Typography>
                                }>
                            </TooltipItem>
                        </CardContent>
                        <MuiCore.IconButton style={{ alignItems: 'right', width: '10%', paddingRight: 16 }} data-testid="project-edit" onClick={() => OnEditSelectedAction()} > <MuiIcons.Edit /> </MuiCore.IconButton>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', overflow: 'auto', whiteSpace: 'nowrap', marginLeft: 16 }}>
                        <Typography variant="body2" style={{ alignItems: 'left', fontSize: 16, fontWeight: 600, color: PXBColors.black[500] }}>{t('DASHBOARD.USERS_TITLE')}</Typography>
                        <Typography variant="body2" style={{ alignItems: 'right', fontSize: 14, textAlign: 'right', display: 'flex', flex: 1, justifyContent: 'flex-end' }} id="project-admin" data-testid="project-admin">{users}</Typography>
                        <MuiCore.IconButton style={{ alignItems: 'right', paddingRight: 16 }} onClick={() => OnUserSelectedAction()}> <MuiIcons.ChevronRight /> </MuiCore.IconButton>
                    </div>

                    {props.isDeviceList && <div style={{ display: 'flex', alignItems: 'center', overflow: 'auto', whiteSpace: 'nowrap', marginLeft: 16 }}>
                        <Typography variant="body2" style={{ alignItems: 'left', fontSize: 16, fontWeight: 600, fontStyle: 'normal', color: PXBColors.black[500] }}>{t('DASHBOARD.BUILDING_CONTACT')}</Typography>

                        <TooltipItem
                            title={primaryContact ? primaryContact : ''}
                            isTopPlacement={true}
                            lenghtLimit={md ? 22 : 16}
                            component={<Typography variant="body2" style={{ alignItems: 'right', fontSize: 14, textAlign: 'right', fontWeight: 400, display: 'flex', flex: 1, justifyContent: 'flex-end' }} id="buildingContactPerson" data-testid="buildingContactPersonTestId">{getControlledText(primaryContact ? primaryContact : '', md ? 22 : 16)}
                            </Typography>
                            }>
                        </TooltipItem>
                        <Box
                            display="flex"
                            style={{
                                flexDirection: 'column',
                                top: vh_65,
                                justifyContent: 'flex-end',
                                right: vh_18,
                                width: md ? vw_300 : '',
                                zIndex: 100,
                                minHeight: vh_900,
                                backgroundColor: PXBColors.white[500],
                                position: 'absolute',
                                transitionDelay: '0.2s',
                                visibility: isbuildingContactDrawerOpen ? 'visible' : 'hidden',
                            }}
                            data-testid="buildingDetails"
                        >
                            <BuildingContactDetails
                                closeClick={buildingContactDrawerClose} buildingID={props.buildingID} />
                        </Box>
                        <MuiCore.IconButton style={{ alignItems: 'right', paddingRight: 16 }} onClick={(): void => onBuildingContactSelect()}> <MuiIcons.ChevronRight /> </MuiCore.IconButton>
                    </div>}
                    <Divider />

                </Box>}

                {!searchActive && <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8, backgroundColor: PXBColors.white[50], borderBottom: '1px solid rgba(66, 78, 84, 0.12)', borderRadius: 32, boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)' }}>
                    {!searchActive && <Typography variant="body2" style={{ alignItems: 'left', fontSize: 14, fontWeight: 600, color: PXBColors.blue[500], marginLeft: 16, display: 'flex', width: '84%' }} data-testid='building-count-title' id="building-count-title">{listTitle}</Typography>}
                    {!searchActive && <IconButton
                        style={{ alignItems: 'right', paddingRight: 16, display: 'flex', flex: 1, justifyContent: 'flex-end', color: PXBColors.blue[500] }}
                        onClick={(): void => setSearchActive(true)}
                        data-cy="search-btn"
                    >
                        <Search />
                    </IconButton>}
                </div>
                }
                {searchActive && <div style={{
                    backgroundColor: PXBColors.lightBlue[50], borderBottom: '1px solid rgba(66, 78, 84, 0.12)', borderRadius: 32, marginBottom: 8, width: '94%', marginLeft: vw_10
                }}>
                    <div style={{ display: 'flex', width: '100%', height: 48 }}>
                        {searchActive && (
                            <TextField
                                style={{ verticalAlign: 'middle', fontSize: 14, fontWeight: 400, alignItems: 'left', marginLeft: 16, paddingTop: 8, width: '90%', caretColor: PXBColors.blue[500] }}
                                value={searchText}
                                placeholder={searchTextHint}
                                onChange={handleSearchChange}
                                InputProps={{ disableUnderline: true }}
                                inputProps={{ maxLength: BUILDING_SEARCH_LIMIT, sx: { '&::placeholder': { fontStyle: 'italic' }, }, }}
                                autoFocus
                                id={'#search-field'}
                                data-testid='searchField'
                                data-cy={'search-field'}
                                variant="standard"
                            />
                        )}
                        {searchText && (
                            <IconButton
                                style={{ width: '10%', color: PXBColors.blue[500], marginRight: 16 }}
                                color={'inherit'}
                                onClick={(): void => {
                                    setSearchText('')
                                    setSearchActive(false);
                                    if (OnSearchTextChanged) OnSearchTextChanged('');
                                }
                                }
                                data-cy="clear-search-field"
                                size="small"
                            >
                                <Close />
                            </IconButton>
                        )}
                    </div>
                </div>}
                {props.isDataLoading && <EmptyState style={{ flex: 1, backgroundColor: PXBColors.white[50] }}
                    icon={<MuiCore.CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />
                }
                {!props.isDataLoading && <>
                    <Box display="flex"
                        style={{
                            flexDirection: 'column',
                            marginBottom: 8,
                            padding: 0,
                            alignContent: 'center',
                            display: 'absolute',
                            borderRadius: 4,
                            flex: 'none',
                            order: 1,
                            flexGrow: 0,
                            alignSelf: 'stretch',
                            overflow: 'hidden',
                            boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)'

                        }}>
                        <OverviewStatusList
                            header={listAnomaly.length != 0 ? t('DASHBOARD.STATUS_COUNT_ANOMALY', { count: listAnomaly.length }) : t('DASHBOARD.STATUS_ANOMALY')}
                            list={listAnomaly}
                            data-testid='Anomaly'
                            defaultOpenState={true}
                            OnItemSelected={(selectedData: OverviewSelectedData): void => setSelected(selectedData)}>
                        </OverviewStatusList>
                    </Box>
                    <Box display="flex"
                        style={{
                            flexDirection: 'column',
                            marginBottom: 8,
                            alignContent: 'center',
                            flex: 'none',
                            display: 'absolute',
                            order: 2,
                            flexGrow: 0,
                            alignSelf: 'stretch',
                            borderRadius: 4,
                            overflow: 'hidden',
                            boxShadow: '0px 0px 8px  rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)'
                        }} >
                        <OverviewStatusList
                            header={listBlock.length != 0 ? t('DASHBOARD.STATUS_COUNT_BLOCKED', { count: listBlock.length }) : t('DASHBOARD.STATUS_BLOCKED')}
                            list={listBlock}
                            data-testid='Blocked'
                            defaultOpenState={true}
                            OnItemSelected={(selectedData: OverviewSelectedData): void => setSelected(selectedData)}>
                        </OverviewStatusList>
                    </Box>
                    <Box display="flex"
                        style={{
                            flexDirection: 'column',
                            marginBottom: 8,
                            alignContent: 'center',
                            display: 'absolute',
                            borderRadius: 4,
                            overflow: 'hidden',
                            boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)'
                        }} >
                        <OverviewStatusList
                            header={listReady.length != 0 ? t('DASHBOARD.STATUS_COUNT_READY', { count: listReady.length }) : t('DASHBOARD.STATUS_READY')}
                            list={listReady}
                            data-testid='Ready'
                            defaultOpenState={true}
                            OnItemSelected={(selectedData: OverviewSelectedData): void => setSelected(selectedData)}>
                        </OverviewStatusList>
                    </Box>
                    <Box display="flex"
                        style={{
                            flexDirection: 'column',
                            marginBottom: 8,
                            alignContent: 'center',
                            display: 'absolute',
                            borderRadius: 4,
                            overflow: 'hidden',
                            boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)'
                        }} >

                        {props.listType === ENTITY_TYPE.BUILDING && <OverviewStatusList
                            header={listEmpty.length != 0 ? t('DASHBOARD.STATUS_COUNT_EMPTY', { count: listEmpty.length }) : t('DASHBOARD.STATUS_EMPTY')}
                            list={listEmpty}
                            data-testid='Empty'
                            defaultOpenState={true}
                            OnItemSelected={(selectedData: OverviewSelectedData): void => setSelected(selectedData)}>
                        </OverviewStatusList>}
                    </Box>
                </>}
            </div>
        </>
    );
};
