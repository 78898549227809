import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { DOWNLOAD_DOCUMENT } from './url-constants';

type DownloadDocumentEndpointSpecifications = ApiEndpointSpecification;
export const DownloadDocumentEndpointSpecification: DownloadDocumentEndpointSpecifications = {
    url: DOWNLOAD_DOCUMENT,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'DownloadDocumentRequestParams',
    requestBodySchemaName: 'DownloadDocumentRequestBody',
    okResponseSchemaName: 'DownloadDocumentOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeDownloadDocument = Helpers.makeApiRequestFn<
    Types.DownloadDocumentRequestParams,
    Types.DownloadDocumentRequestBody,
    Types.DownloadDocumentOkResponse
>(DownloadDocumentEndpointSpecification);