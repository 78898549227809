import { ActionType } from '../../types';
import { SystemLogData, SystemLogListData } from '../../types/system-log';

export const DownloadDocumentAction = {

    downloadDocumentStarted: () =>
    ({
        type: 'DocumentDownload/downloadDocument/Started',
    } as const),

    downloadDocumentSucceeded: (data: string) =>
    ({
        type: 'DocumentDownload/downloadDocument/Succeeded',
        payload: { data },
    } as const),

    downloadDocumentFailed: (errorCode: string) =>
    ({
        type: 'DocumentDownload/downloadDocument/Failed',
        payload: { errorCode },
    } as const),

    downloadDocumentUnmounted: () =>
    ({
        type: 'DocumentDownload/downloadDocument/Unmounted',
    } as const),

};

export type DocumentDownloadAction = ActionType<typeof DownloadDocumentAction>;
