// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { GenericToolbar } from '../../components/GenericToolbar';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Button, Checkbox, CircularProgress, Collapse, List, ListSubheader, MenuItem, Select, SelectChangeEvent, Snackbar, TablePagination, TextField, Typography } from '@mui/material';
import * as PXBColors from '@brightlayer-ui/colors';
import { ListItemButton } from '@mui/material';
import { OrgDetailsCard } from './OrgDetailsCard';
import { OrganizationList, OrganizationListSort } from '../../types/organization';
import { Close, Done, KeyboardArrowDown, KeyboardArrowUp, Search, SwapVert } from '@mui/icons-material';
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { useDispatch, useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import { OrganizationThunks } from '../../actions/thunks/organization-thunks';
import { EmptyState } from '@brightlayer-ui/react-components';
import { getComparator, stableSort } from '../../lib/table-helpers';
import { withStyles } from '@mui/styles';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ORGANIZATTION_NAME, RECORD_PER_PAGE_LIMIT } from '../../constants';
import { useToasts } from '../../data/DataProviders/ToastProvider';
import { OrganizationActions } from '../../actions/actions/organization-actions';
import { USER_ROLE_TYPE } from '../enum/ENUM';
import { getUserRoleType, useAuthState } from '../../components/AuthContextProvider';
import { pushAppRoute } from '../../data/domain/route-manager';

type Props = {
    userId: string;
};
type SortDataModel = { id: string; name: string };

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

export const Organizations: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const history = useHistory();
    const theme = useTheme();
    const authState = useAuthState();
    const usertype = getUserRoleType(authState) || '';
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const [organizationList, setOrganizationList] = useState<OrganizationListSort>([]);
    const [organizationFavoriteList, setOrganizationFavoriteList] = useState<OrganizationListSort>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [openSort, setOpenSort] = useState(false);
    const [delayDebounceTime, setDelayDebounceTime] = useState(0);
    const dashboard = useSelector(Selectors.Organization.organization);
    const [emptyOrgList, setEmptyOrgList] = React.useState(false);
    const [firstCall, setFirstCall] = React.useState(true);
    const { addToast } = useToasts();

    const sortTypeList: SortDataModel[] = [
        { id: 'name', name: t('ORGANIZATION_MANAGE.SORT_TYPE_NAME') },
        { id: 'SystemCount', name: t('ORGANIZATION_MANAGE.SORT_TYPE_NUMBER_OF_SYSTEMS') },
        { id: 'city', name: t('ORGANIZATION_MANAGE.SORT_TYPE_CITY') },
    ];
    const [sortType, setSortType] = useState(sortTypeList[0].id);

    const organization = useSelector(Selectors.Organization.organization);
    const dispatch = useDispatch();

    const [openAll, setOpenAll] = React.useState(true);
    const [openFavorite, setOpenFavorite] = React.useState(true);

    const [totalRecords, setTotalRecords] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(RECORD_PER_PAGE_LIMIT);
    const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false);
    const [alertSuccessMessage, setAlertSuccessMessage] = useState<string>('');
    const [isFav, setIsFav] = React.useState(false);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const getOrganizationListSort = (list: OrganizationList): OrganizationListSort => {
        return list.map((data, organization) => {
            return (
                {
                    projectId: data.projectId,
                    name: data.name,
                    description: data.description ? data.description : '',
                    country: data.country,
                    address: data.address ? data.address : '',
                    address2: data.address2 ? data.address2 : '',
                    postalCode: data.postalCode ? data.postalCode : '',
                    city: data.city,
                    countryCode: data.countryCode,
                    Phone: data.phone ? data.phone : '',
                    email: data.email ? data.email : '',
                    SystemCount: data.systemCount,
                    buildingCount: data.buildingCount,
                    adminCount: data.adminCount,
                    viewerCount: data.viewerCount,
                    isAdmin: data.isAdmin ? 1 : 0,
                    isFavourite: data.isFavourite ? 1 : 0,
                }
            );
        })
    };

    const getSortedList = (orderBy: any, organizationList: OrganizationListSort): OrganizationListSort => {
        return stableSort(organizationList, getComparator('asc', orderBy));
    };

    const handleAllCollapseClick = () => {
        setOpenAll(!openAll);
    };

    const handleFavoriteCollapseClick = () => {
        setOpenFavorite(!openFavorite)
    };

    const goToOrganizationDetails = (value: { orgId: string, orgName: string }): void => {
        pushAppRoute(history,
            { type: 'OrganizationDetails', orgId: value.orgId },
            { backRoute: 'Organizations', orgId: value.orgId });
    };

    const goToCreateOrganization = (): void => {
        pushAppRoute(history,
            { type: 'CreateOrganization' },
            { backRoute: 'Organizations' });
    };

    const goToInvitationHistory = (): void => {
        pushAppRoute(history,
            { type: 'InvitationHistory' },
            { backRoute: 'Organizations' });
    };

    const onChangeSearchText = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || ORGANIZATTION_NAME.test(e.target.value as string))
            setSearchQuery(e.target.value);
    };

    const resetSearch = (): void => {
        setSearchQuery('');
    };

    useEffect(() => {
        if (firstCall)
            setFirstCall(false);
        else {
            /* Debouncing to override user typing */
            const delayDebounceFn = setTimeout(() => {
                dispatch(OrganizationThunks.getOrganizationDetailsList('', searchQuery, rowsPerPage.toString(), (currentPage + 1).toString(), isFav));
            }, delayDebounceTime)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [searchQuery]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(OrganizationThunks.getOrganizationDetailsList('', searchQuery, rowsPerPage.toString(), (currentPage + 1).toString(), isFav));
    }, [currentPage, rowsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!organization.orgListLoading && organization.orgListSuccess) {
            setEmptyOrgList(organization.orgList.detail.length <= 0)
            const orgListSorted = getSortedList(sortType, getOrganizationListSort(organization.orgList.detail));
            setOrganizationList(orgListSorted);
            setOrganizationFavoriteList(orgListSorted.filter((org) => org.isFavourite === 1));
            if (organization.orgList.totalRecordCount)
                setTotalRecords(organization.orgList.totalRecordCount);
            setDelayDebounceTime(3000);
        } else if (!organization.orgListLoading && organization.orgListFail) {
            setOrganizationList([]);
            setEmptyOrgList(true);
        }
    }, [organization.orgListLoading, organization.orgListSuccess, organization.orgListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    const onSortTypeChangeHandler = (event: SelectChangeEvent) => {
        const sortTypeSelected = event.target.value as string;
        if (sortTypeSelected !== sortType) {
            setSortType(sortTypeSelected);
            setOrganizationList(getSortedList(sortTypeSelected, organizationList));
            setOrganizationFavoriteList(getSortedList(sortTypeSelected, organizationFavoriteList));
        }
    }
    useEffect(() => {
        if (!organization.markasFavLoading && organization.markasFavSuccess) {
            addToast(t('TOAST_MSG.MARK_AS_FAV_API_SUCCESS'));
            dispatch(OrganizationThunks.getOrganizationDetailsList('', '', rowsPerPage.toString(), (currentPage + 1).toString(), isFav));
        }
        else if (!organization.markasFavLoading && organization.markasFavFail) {
            if (organization.errorCode === '9183') {
                addToast(t('ORGANIZATION_MANAGE.MARK_AS_FAV_API_FAILURE_MESSAGE'));
            }
            else if (organization.errorCode === '9001') {
                addToast(t('TOAST_MSG.REMOVE_ACCESS_ERROR_CODE_9001'));
            }
            else {
                addToast(t('TOAST_MSG.MARK_AS_FAV_API_FAILED'));
            }
            dispatch(OrganizationActions.markasFavUnmounted());
        }
    }, [organization.markasFavLoading, organization.markasFavSuccess, organization.markasFavFail]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!organization.removeMarkAsFavLoading && organization.removeMarkAsFavSuccess) {
            addToast(t('TOAST_MSG.REMOVE_MARK_AS_FAV_API_SUCCESS'));
            dispatch(OrganizationThunks.getOrganizationDetailsList('', '', rowsPerPage.toString(), (currentPage + 1).toString(), isFav));
        }
        else if (!organization.removeMarkAsFavLoading && organization.removeMarkAsFavFail) {
            if (organization.errorCode === '9001')
                addToast(t('TOAST_MSG.REMOVE_ACCESS_ERROR_CODE_9001'));
            else
                addToast(t('TOAST_MSG.REMOVE_MARK_AS_FAV_API_FAILED'));
            dispatch(OrganizationActions.removeMarkAsFavUnmounted());
        }

    }, [organization.removeMarkAsFavLoading, organization.removeMarkAsFavSuccess, organization.removeMarkAsFavFail]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (organization.updateOrgDetailSuccess && !organization.updateOrgDetailLoading) {
            setAlertSuccessMessage(t('TOAST_MSG.TOAST_SUCCESS_ORG_CREATE'));
            setDisplaySuccessMessage(true);
            dispatch(OrganizationActions.updateOrgUnmounted());
        }
    }, [organization.updateOrgDetailSuccess])// eslint-disable-line react-hooks/exhaustive-deps

    const handleFavoriteChange = (
        event: React.ChangeEvent<HTMLElement>,
        checked: boolean,
    ) => {
        if (checked) {
            setIsFav(true);
            dispatch(OrganizationThunks.getOrganizationDetailsList('', searchQuery, rowsPerPage.toString(), '1', true));
            setCurrentPage(0);
        } else {
            setIsFav(false);
            dispatch(OrganizationThunks.getOrganizationDetailsList('', searchQuery, rowsPerPage.toString(), '1', false));
        }
    };

    return (
        <div style={{ backgroundColor: PXBColors.white[50], minHeight: 800 }}>
            <GenericToolbar title={t('DASHBOARD.DASHBOARD_MANAGE_ORGANIZATION_TITLE')} />
            <div data-testid="organizations-container" style={{ marginLeft: md ? 120 : 15, marginRight: 15 }}>

                <div data-testid="search-panel" style={{ display: 'flex', marginTop: 20 }}>

                    <TextField
                        inputProps={{ 'data-testid': 'searchText-field', maxLength: 100 }}
                        value={searchQuery}
                        style={{
                            width: '95%',
                            borderRadius: 5,
                            backgroundColor: PXBColors.white[50],
                            fontSize: 12,
                            fontWeight: 400,
                        }}
                        placeholder={t('ORGANIZATION_MANAGE.SEARCH')}
                        onChange={(e): void => onChangeSearchText(e)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search data-testid="search-icon" style={{ color: PXBColors.gray[500] }} />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    {searchQuery !== '' && (
                                        <IconButton onClick={resetSearch}>
                                            <Close data-testid="close-search-icon" fontSize="small" />
                                        </IconButton>
                                    )}
                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                    />
                    <SwapVert data-testid='swapvertId' style={{ width: '5%', alignSelf: 'center', color: PXBColors.gray[500], marginLeft: 0 }}
                        onClick={() => setOpenSort(!openSort)} />
                    {openSort && <Select
                        disableUnderline
                        variant="filled"
                        label={t('DASHBOARD.ORGANIZATION_TITLE')}
                        labelId="organization-dd-control-label"
                        id="organization-dd-control"
                        data-testid="organization-dd-control"
                        open={true}
                        onChange={onSortTypeChangeHandler}
                        style={{ width: 20, color: '#00000000', marginLeft: 0 }}
                        onClick={() => setOpenSort(!openSort)}
                    >
                        <Typography style={{ paddingLeft: 16, fontWeight: 600, fontSize: 12, textTransform: 'uppercase', color: PXBColors.gray[500], fontStyle: 'normal', paddingTop: 12, paddingBottom: 10, letterSpacing: '2px' }}>{t('ORGANIZATION_MANAGE.SORT_BY_HEADER')}</Typography>
                        {sortTypeList.map((item) => (
                            <StyledMenuItem
                                data-testid="organization-dropdown-item"
                                key={item.id}
                                value={item.id}
                                divider={false}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'left', fontSize: 14,
                                    lineHeight: 1.5, fontWeight: 400,
                                    overflow: 'hidden',
                                    boxSizing: 'border-box',
                                    whiteSpace: 'nowrap',
                                    minHeight: 48,
                                    paddingTop: 6,
                                    paddingBottom: 6,
                                    width: 'auto',
                                    paddingLeft: 16,
                                    paddingRight: 16
                                }}
                            >
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <Checkbox icon={item.id === sortType ? <Done style={{ color: PXBColors.gray[500], width: 20, height: 20 }} /> : <Done style={{ color: '#00000000', width: 20, height: 20 }} />} />
                                    <Typography variant="subtitle1" style={{ fontSize: 14, fontWeight: 400 }}>
                                        {item.name}
                                    </Typography>
                                </div>
                            </StyledMenuItem>
                        ))}
                    </Select>}


                </div>

                <Box sx={{ width: '100%', typography: 'body1', display: 'flex' }}>
                    <Button
                        id="button-create-organization"
                        data-testid="button-create-organization"
                        style={{
                            flexDirection: 'row',
                            backgroundColor: PXBColors.blue[500],
                            color: PXBColors.white[50],
                            marginTop: '2%',
                            textTransform: 'none'
                        }}
                        onClick={(): void => goToCreateOrganization()}
                    >
                        + {t('DASHBOARD.ADD_ORGANIZATION')}
                    </Button>

                    {usertype !== USER_ROLE_TYPE.SERVICE_MANAGER && <Button
                        style={{
                            borderColor: PXBColors.blue[500],
                            backgroundColor: PXBColors.white[50],
                            color: PXBColors.blue[500],
                            flexDirection: 'row',
                            marginTop: '2%',
                            marginLeft: 20,
                            textTransform: 'none'
                        }}
                        id="button-invitation-history"
                        data-testid="button-invitation-history"
                        variant="outlined"
                        color="primary"
                        onClick={() => goToInvitationHistory()}>
                        {t('USER_INVITATION_MANAGEMENT.INVITE_HISTORY')}
                    </Button>}
                    <div style={{ display: 'flex', marginLeft: 'auto', marginTop: 'auto' }}>
                        <Typography
                            id="users-organizations-favorite-filter-checkbox-lable"
                            data-testid="users-organizations-favorite-filter-checkbox-lable-text"
                            style={{ alignSelf: 'center' }} variant="body2">
                            {t('DASHBOARD.STARRED_ORGANIZATIONS')}
                        </Typography>
                        <Checkbox id="users-organizations-favorite-filter-checkbox"
                            data-testid='users-organizations-favorite-filter-checkbox'
                            onChange={handleFavoriteChange}
                        />
                    </div>
                </Box>

                {organization.orgListLoading && !organization.orgListSuccess && !organization.orgListFail && <EmptyState style={{ flex: 1, minHeight: 400, backgroundColor: PXBColors.white[50] }}
                    icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}
                {!organization.orgListLoading && emptyOrgList && <EmptyState style={{ flex: 1, height: 200 }}
                    title={t('ORGANIZATION_MANAGE.ERROR_NO_ORG_FOUND')} icon={undefined} placeholder={undefined} />}
                {!organization.orgListLoading && organizationFavoriteList.length > 0 && !emptyOrgList && isFav &&
                    <List
                        id="org-fav-list-header"
                        data-testid="org-fav-list-header"
                        subheader={
                            <ListSubheader
                                style={{
                                    marginLeft: -15,
                                    marginRight: -15,
                                    display: 'flex',
                                    height: 45,
                                    marginTop: md ? '2%' : '5%',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography
                                    variant="h5"
                                    style={{
                                        color: PXBColors.blue[400],
                                        width: '95%',
                                    }}
                                    id="org-fav-list-sub-header"
                                    data-testid="org-fav-list-sub-header"
                                >
                                    {t('ORGANIZATION_MANAGE.STARRED_ORGANIZATIONS')}
                                </Typography>

                                <ListItemButton
                                    onClick={handleFavoriteCollapseClick}
                                    id="org-fav-list-header-button"
                                    data-testid="org-fav-list-header-button"
                                    style={{ width: '5%' }}
                                >
                                    {openFavorite ? (<KeyboardArrowUp data-testid="list-fav-header-up-icon" id="list-fav-header-up-icon" style={{ color: PXBColors.blue[500] }} />) : (<KeyboardArrowDown id="list-fav-header-down-icon" style={{ color: PXBColors.blue[500] }} />)}
                                </ListItemButton>
                            </ListSubheader>
                        }
                    >
                        <Collapse in={openFavorite} timeout="auto" unmountOnExit>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {organizationFavoriteList.map((org, index) => (
                                    <OrgDetailsCard
                                        id={org.projectId}
                                        title={org.name}
                                        loadingComponent={'symbol'}
                                        subtitle={org.address}
                                        headerInfo={org.city}
                                        systemCount={org.SystemCount}
                                        buildingCount={org.buildingCount}
                                        orgAdminCount={org.adminCount}
                                        orgViewerCount={org.viewerCount}
                                        isFavorite={org.isFavourite}
                                        onClick={goToOrganizationDetails}>
                                    </OrgDetailsCard>
                                ))}
                            </div>
                        </Collapse>
                    </List>}

                {!organization.orgListLoading && organizationList.length > 0 && !isFav && <List
                    id="org-list-header"
                    data-testid="org-list-header"
                    subheader={
                        <ListSubheader
                            style={{
                                marginTop: 20,
                                marginBottom: 7,
                                marginLeft: -15,
                                marginRight: -15,
                                display: 'flex',
                                height: 45,
                            }}
                        >
                            <Typography
                                data-testid='subHeaderText'
                                variant="h5"
                                style={{
                                    color: PXBColors.blue[400],
                                    width: '95%',
                                }}
                                id="org-list-sub-header"
                            >
                                {t('ORGANIZATION_MANAGE.ALL')}
                            </Typography>

                            <ListItemButton
                                data-testid='list-item-button'
                                onClick={handleAllCollapseClick}
                                id="org-list-header-button"
                                style={{ width: '5%' }}
                            >
                                {openAll ? (<KeyboardArrowUp id="list-header-up-icon" style={{ color: PXBColors.blue[500] }} />) : (<KeyboardArrowDown id="list-header-down-icon" style={{ color: PXBColors.blue[500] }} />)}
                            </ListItemButton>
                        </ListSubheader>
                    }
                >
                    <Collapse in={openAll} timeout="auto" unmountOnExit>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {organizationList.map((org, index) => (
                                <OrgDetailsCard
                                    id={org.projectId}
                                    title={org.name}
                                    loadingComponent={'symbol'}
                                    subtitle={org.address}
                                    headerInfo={org.city}
                                    systemCount={org.SystemCount}
                                    buildingCount={org.buildingCount}
                                    orgAdminCount={org.adminCount}
                                    orgViewerCount={org.viewerCount}
                                    isFavorite={org.isFavourite}
                                    onClick={goToOrganizationDetails}>
                                </OrgDetailsCard>
                            ))}
                        </div>
                        <TablePagination
                            component="div"
                            data-testid="pagination-bar"
                            count={totalRecords}
                            page={currentPage}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={t("LABELS.ITEMS_PER_PAGE")}
                            showFirstButton showLastButton
                        />
                    </Collapse>
                </List>}
                {
                    displaySuccessMessage &&
                    <Snackbar
                        open={true}
                        onClose={() => setDisplaySuccessMessage(false)}
                        autoHideDuration={5000}
                        title={''}
                        message={alertSuccessMessage}
                        action={
                            <Button color="inherit" size="small" onClick={() => setDisplaySuccessMessage(false)}>
                                {t('ACTIONS.OKAY')}
                            </Button>
                        }
                        ContentProps={{ sx: { background: PXBColors.black[900] } }}
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                        sx={{ bottom: { xs: 90, sm: 0 } }}
                        style={{ height: 200, width: 200, flexGrow: 0 }}
                    />
                }
            </div>
        </div>
    );
};
