import React, { useEffect, useState } from 'react';
import { useToasts } from "../../data/DataProviders/ToastProvider";
import { useDispatch, useSelector } from "react-redux";
import { useLanguageLocale } from "../../hooks/language-locale-hooks";
import { Card, CircularProgress, Dialog, IconButton, InputBase, List, ListItem, MenuItem, Select, Typography, styled } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Selectors } from "../../selectors";
import * as PXBColors from '@brightlayer-ui/colors';
import { Divider, Link, useMediaQuery, useTheme, Button } from '@mui/material';
import { AlarmBadgeStatus } from '../AlarmBadge/AlarmBadge';
import { getUserEmail, useAuthHelper, useAuthState } from '../AuthContextProvider';
import { TooltipItem } from '../TooltipItem/TooltipItem';
import { getControlledText } from '../../app/enum/GenericFormat';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { Thunks } from '../../actions';
import { defaultUserDetail } from '../UserDetails/UserDetails';
import { UserDetailsData } from '../../types';
import { getUserRole, USER_ROLE_TYPE, userRoles } from '../../app/enum/ENUM';
import { COLOR_GRAY } from '../../constants/color-codes';
import { SelectionData } from '../UserDetails/SelectionListData';
import { RemoveServiceUserDialog } from '../../app/user-management/RemoveServiceUserDialog';
import { EmptyState } from '@brightlayer-ui/react-components';
import { ViewAccessDetailsDialog } from '../../app/manage-user/ViewAccessDetailsDialog';
import { UserAdvanceAccessDetail } from '../../types/manage-users';
import { ItemList } from '../../types/logbook-type';

type Props = {
    selectedId: string;
    open: boolean;
    onSuccess: Function;
    userType: number;
};

export const UserDetailsCard: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const { addToast } = useToasts();
    const dispatch = useDispatch();
    const { t } = useLanguageLocale();
    const asset = useSelector(Selectors.Dashboard.asset);
    const emailId = getUserEmail(useAuthState());
    const authHelper = useAuthHelper();
    const entityUserData = useSelector(Selectors.Users.users);
    const usersSelector = useSelector(Selectors.Users.users);
    const [userAccessDetailData, setUserAccessDetailData] = React.useState<UserAdvanceAccessDetail>();
    const [userDetail, setUserDetail] = useState<UserDetailsData>(defaultUserDetail);
    const isLoggedInUser: boolean = emailId === userDetail.email_id;
    const [openRemoveServiceUserDialog, setOpenRemoveServiceUserDialog] = React.useState(false);
    const [dataLoaded, setDataLoaded] = React.useState(false);
    const [openAccessDialog, setOpenAccessDialog] = React.useState(false);
    const [organizationCheckList, setOrganizationCheckList] = React.useState<ItemList>([]);
    const orgSelectAllId = '-1';
    const [isUserAdvanceInviteCallStatus, setUserAdvanceInviteCallStatus] = useState(true);

    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
            marginTop: theme.spacing(3),
        },
        '& .MuiInputBase-input': {
            borderRadius: 4,
            position: 'relative',
            borderBottom: '1px solid #ced4da',
            fontSize: 16,
            color: '#424E54',
            padding: '10px 26px 10px 12px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            '&:focus': {
                borderRadius: 4,
            },
        },
    }));
    const closeSLADetail = (): any => {
        props.onSuccess(false)

    }

    const handleOpendialog = () => {
        setOpenRemoveServiceUserDialog(true);
    }

    useEffect(() => {
        dispatch(Thunks.Users.getEntityUserData(props.selectedId, authHelper));
    }, [props.selectedId]); // eslint-disable-line react-hooks/exhaustive-deps

    const getUserAdvanceAccessDetails = (userEmailId: string) => {
        const selectAll = organizationCheckList.filter((org) => org.id === orgSelectAllId);
        var selectedOrganizations: string[] = [];
        if (selectAll.length > 0) {
            const allOrganizations: string[] = organizationCheckList.map((organization) => organization.id);
            selectedOrganizations = allOrganizations.filter((organization) => organization !== orgSelectAllId);
        } else {
            selectedOrganizations = organizationCheckList.map((organization) => organization.id);
        }

        if (userEmailId === emailId) {
            dispatch(Thunks.Users.getUserAdvanceAccessDetail('', selectedOrganizations));
        } else {
            dispatch(Thunks.Users.getUserAdvanceAccessDetail(userEmailId, selectedOrganizations));
        }
    }

    useEffect(() => {
        if (!entityUserData.getEntityUserDetailLoading && entityUserData.getEntityUserDetailSuccess) {
            setDataLoaded(true);
            setUserDetail(entityUserData.entityUserDetail);
            getUserAdvanceAccessDetails(entityUserData.entityUserDetail.email_id)
        } else if (!entityUserData.getEntityUserDetailLoading && entityUserData.getEntityUserDetailFail) {
            setDataLoaded(false);
            setUserDetail(defaultUserDetail);
            getUserAdvanceAccessDetails(defaultUserDetail.email_id);
        }
    }, [entityUserData.getEntityUserDetailLoading, entityUserData.getEntityUserDetailSuccess, entityUserData.getEntityUserDetailFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!usersSelector.getuserAdvanceAccessLoading && usersSelector.getuserAdvanceAccessSuccess) {
            setUserAccessDetailData(usersSelector.getuserAdvanceAccess);
            setUserAdvanceInviteCallStatus(true);
        } else if (usersSelector.getuserAdvanceAccessFail && !usersSelector.getuserAdvanceAccessLoading) {
            setUserAdvanceInviteCallStatus(false);
        }
    }, [usersSelector.getuserAdvanceAccessFail, usersSelector.getuserAdvanceAccessLoading, usersSelector.getuserAdvanceAccessSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleOpenSidedialog = () => {
        setOpenAccessDialog(true);
    }
    
    const onCancelAccessInvite = (): void => {
        setOpenAccessDialog(false);
    };

    const selectedUserRoles: SelectionData[] = userRoles.filter(role => role.key === userDetail.roleTypeId);
    const selectedUserRole: SelectionData = selectedUserRoles.length > 0 ? selectedUserRoles[0] : getUserRole(USER_ROLE_TYPE.LIMITED);
    const userRoleSelection = getUserRole(selectedUserRole.key);

    return (
        <>
            <Card style={{ flex: '1 1 0px', height: '100%', width: '100%' }} data-testid="assets-sla-details-container">
                <ListItem style={{

                }}
                    secondaryAction={
                        <IconButton id="entityUser-detail-close" data-testid="entityUser-detail-close" style={{ top: -20, right: -10, marginLeft: "auto", justifyContent: 'start' }} onClick={(): void => closeSLADetail()}>
                            <Close />
                        </IconButton>
                    }>
                    {!dataLoaded && <EmptyState style={{ flex: 1, minHeight: 400, backgroundColor: PXBColors.white[50] }}
                        icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}
                    {dataLoaded && <div style={{ paddingTop: '10' }}>
                        <TooltipItem
                            title={userDetail.firstname + " " + userDetail.lastname}
                            isTopPlacement={true}
                            lenghtLimit={22}
                            component={<Typography
                                variant="h6"
                                id="user-detail-name" data-testid="user-detail-name"
                                style={{ fontSize: 20, fontWeight: 600, fontStyle: 'normal', color: PXBColors.black[500], top: 24, left: 16 }}>
                                {getControlledText(userDetail.firstname + " " + userDetail.lastname, 24)}
                            </Typography>}>
                        </TooltipItem>

                        <div style={{ display: 'flex' }}>
                            <TooltipItem
                                title={userDetail.email_id}
                                isTopPlacement={true}
                                lenghtLimit={22}
                                component={<Typography variant="body2" style={{ fontSize: 15 }} id="user-detail-email" data-testid="user-detail-email" > {getControlledText(userDetail.email_id, 24)}
                                </Typography>}>
                            </TooltipItem>

                        </div>
                        <div style={{ display: 'flex' }}>
                            <Typography variant="body2" style={{ fontSize: 15 }} id="user-detail-phone" data-testid="user-detail-phone" >{'+' + userDetail.countryCode + " " + userDetail.phone}</Typography>
                        </div>
                    </div>
                    }
                </ListItem>

                {dataLoaded && <List>
                    <ListItem style={{ padding: '8px 16px 0px 16px' }}>
                        {t('DEVICE_DETAILS.STATUS')}
                    </ListItem>
                    <ListItem style={{ padding: '0px 16px 8px 16px' }}>
                        <AlarmBadgeStatus
                            statusName={userDetail.userStatus ? t('DASHBOARD.TIMELINE_DETAILS.INACTIVE') : t('DASHBOARD.TIMELINE_DETAILS.OPEN')}
                            statusColor={userDetail.userStatus ? PXBColors.yellow[500] : PXBColors.green[500]}
                            foregroundColor={userDetail.userStatus ? PXBColors.black[500] : PXBColors.white[50]} alarmStatus={''} />
                        <Typography variant="body1" style={{ fontSize: 14, fontWeight: 400, fontStyle: 'normal', color: PXBColors.black[500] }}>{userDetail.lastSeen}</Typography>
                    </ListItem>

                    <div style={{ padding: '10px 16px 10px 16px', marginBottom: 12 }}>
                        <Select
                            variant='standard'
                            style={{ width: 200, backgroundColor: PXBColors.white[200], fontSize: 16 }}
                            labelId="role-select-label"
                            id="role-select-label"
                            value={userRoleSelection.value}
                            disabled={true}
                            input={<BootstrapInput />

                            }
                        >
                            {userRoles.map((option) => (
                                <MenuItem style={{
                                    display: 'flex',
                                    lineHeight: 1.5,
                                    fontWeight: 400,
                                    overflow: 'hidden',
                                    boxSizing: 'border-box',
                                    whiteSpace: 'nowrap',
                                    minHeight: 48,
                                    width: 'auto',
                                    textAlign: 'center',
                                    fontSize: md ? 16 : 10, color: COLOR_GRAY
                                }}
                                    key={option.key} value={option.value}>
                                    {option.description}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>

                    <div style={{ padding: '8px 16px 8px 16px' }}>
                        <Typography data-testid="companyTitle" style={{ fontWeight: 400, fontSize: 14 }}>{t('USER_INVITATION_MANAGEMENT.COMPANY')}</Typography>
                        <TooltipItem
                            title={userDetail.company_name}
                            isTopPlacement={true}
                            lenghtLimit={30}
                            component={
                                <Typography data-testid="companyName" variant='subtitle1' style={{ fontWeight: 600, fontSize: 16 }}>{getControlledText(userDetail.company_name, 24)}</Typography>
                            }>
                        </TooltipItem>

                        <TooltipItem
                            title={userDetail.company_address}
                            isTopPlacement={true}
                            lenghtLimit={30}
                            component={
                                <Typography data-testid="companyAddress" variant='subtitle1' style={{ fontWeight: 600, fontSize: 16 }}>{getControlledText(userDetail.company_address, 24)}</Typography>
                            }>
                        </TooltipItem>

                        <TooltipItem
                            title={userDetail.company_postalcode + " " + userDetail.company_city}
                            isTopPlacement={true}
                            lenghtLimit={30}
                            component={
                                <Typography data-testid="companyCity" style={{ fontWeight: 600, fontSize: 16 }}>{getControlledText(userDetail.company_postalcode + " " + userDetail.company_city, 24)}</Typography>
                            }>
                        </TooltipItem>
                        <Typography data-testid="companyCountry" style={{ fontWeight: 600, fontSize: 16 }}>{userDetail.company_country}</Typography>
                        <Typography data-testid="companyPhoneNumber" style={{ fontWeight: 600, paddingBottom: 20, fontSize: 16 }}>{(userDetail.company_countrycode && userDetail.company_phoneNumner) ? ('+' + userDetail.company_countrycode + " " + userDetail.company_phoneNumner) : '--'}</Typography>
                    </div>     
                    <Link data-testid="viewAllAccessRightsLink" onClick={handleOpenSidedialog} style={{ paddingLeft: 16, fontWeight: 500 }}>{t('USER_INVITATION_MANAGEMENT.VIEW_ALL_ACCESS_RIGHTS')}</Link>
                    <Divider style={{ paddingTop: 20 }} />
                    <Button startIcon={<RemoveCircleOutlineOutlinedIcon />} style={{ marginTop: 16, width: '80%', alignItems: 'center', textTransform: 'capitalize', fontWeight: 600, fontStyle: 'normal', fontSize: '14px', display: 'flex', top: '3%', marginLeft: 35, border: '1px solid', borderColor: PXBColors.red[500], opacity: 1, color: PXBColors.red[500] }}
                        id="removeUser"
                        data-testid="removeUserButton"
                        variant="outlined"
                        color='error'
                        onClick={(): void => handleOpendialog()}
                    >
                        {t('USER_INVITATION_MANAGEMENT.REMOVE_USER')}
                    </Button>
                </List>
                }          
            </Card >
            <Dialog id="invite-user-dialog" data-testid="invite-user-dialog"
                maxWidth="md" open={openAccessDialog}
                sx={{ maxHeight: 900, overflow: 'hidden' }}
            >
                <ViewAccessDetailsDialog
                    isLoggedInUser={isLoggedInUser}
                    fullname={`${userDetail.firstname} ${userDetail.lastname}`}
                    firstName={`${userDetail.firstname}`}
                    userAccessDetailData={userAccessDetailData ? userAccessDetailData : {
                        inviteByUserEntityAccessDetail: [],
                        inviteUserEntityAccessDetail: []
                    }}
                    onCancelAdvance={onCancelAccessInvite}
                    isAdvanceTreeDataAvailable={true}
                ></ViewAccessDetailsDialog>
            </Dialog>
            <RemoveServiceUserDialog username={userDetail.firstname + " " + userDetail.lastname} platformName={userDetail.platformName} userType={props.userType} userId={props.selectedId}
                openDialogFlag={openRemoveServiceUserDialog} closeClick={() => setOpenRemoveServiceUserDialog(false)} ></RemoveServiceUserDialog>
        </>


    );
};