
import * as ApiRequests from '../../api/requests';
import { AppThunk } from '../actions';
import { DownloadDocumentAction } from '../actions/download-document-actions';

export const DownloadDocumentThunks = {

    downloadDocument: (
        fileType: number
    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(DownloadDocumentAction.downloadDocumentStarted());
        const payload = await ApiRequests.downloadDocument(
            {
                fileType: fileType
            },
            { authHelper: undefined }
        );
        if (payload.ok) {
            return;
        }
        dispatch(DownloadDocumentAction.downloadDocumentFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
    }
};
