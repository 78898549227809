// eslint-disable-next-line
import React, { } from 'react';
import {
    Typography,
    Card,
    ListSubheader,
    Button,
    useMediaQuery,
    useTheme,
    Divider,
} from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import * as PXBColors from '@brightlayer-ui/colors';
import { DeviceCustomerDetail } from '../../types/customer-detail';
import { vw_15 } from '../../constants/dimentions';
import * as MuiCore from '@mui/material';
import * as MuiIcons from '@mui/icons-material';
import { AssetAddSLADialog } from './AssetAddSLADialog';
import { getUserRoleType, useAuthState } from '../../components/AuthContextProvider';
import { USER_ROLE_TYPE } from '../enum/ENUM';
import { pushAppRoute } from '../../data/domain/route-manager';
import { TAB_SLA_KEY, updateTabSession } from './Assets';
import { useHistory } from 'react-router';

type Props = {
    customerInfo: DeviceCustomerDetail;
};

export const CustomerSLADetails: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const authState = useAuthState();
    const theme = useTheme();
    const history = useHistory();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const [openOrgSelectDialog, setOpenOrgSelectDialog] = React.useState(false);
    const usertype = getUserRoleType(authState) || '';

    const navigateToSLAList = (): void => {
        pushAppRoute(
            history,
            { type: 'Assets' },
            { backRoute: 'CustomerDetails', customerNo: props.customerInfo?.customerNumber, projectID: '' }
        );
        updateTabSession(TAB_SLA_KEY);
    };

    return (
        <>
            <Card style={{ flex: '1 1 0px', overflow: 'auto' }} id="customer-sla-details" data-testid="customer-sla-details-container">

                <ListSubheader

                    component="div"
                    data-testid="sla-details-header"
                    id="sla-details-header"
                    style={{
                        display: 'flex',
                        fontWeight: 600,
                        fontSize: 14,
                        alignItems: 'center',
                        overflow: 'auto',
                        whiteSpace: 'nowrap',
                        marginLeft: 10,
                        height: 60,
                    }}

                >

                    <Typography
                        variant="subtitle1"
                        style={{
                            alignItems: 'left',
                            color: PXBColors.blue[600],
                            width: '92.3%',
                            paddingLeft: '10',
                        }}
                        id="sla-details-title"
                        data-testid="sla-details-title"
                    >
                        {t('CUSTOMER_DETAILS.SLA_MANAGEMENT_HEADER')}
                    </Typography>

                    {(usertype === USER_ROLE_TYPE.SERVICE_MANAGER) &&
                        <Button
                            id="users-customers-add-sla"
                            data-testid="users-customers-add-sla"
                            style={{
                                flexDirection: 'row',
                                backgroundColor: PXBColors.white[50],
                                textTransform: 'none',
                                marginLeft: vw_15
                            }}
                            variant="outlined"
                            color="primary"
                            onClick={() => { setOpenOrgSelectDialog(true) }}
                        >
                            {md ? t('USERS.ADD_SLA') : <MuiIcons.Add></MuiIcons.Add>}
                        </Button>
                    }
                </ListSubheader>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', height: 62, overflow: 'auto', paddingLeft: 10 }}>
                    <ListSubheader

                        component="div"
                        data-testid="sla-details-header"
                        id="sla-details-header"
                        style={{
                            display: 'flex',
                            fontWeight: 600,
                            fontSize: 14,
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            overflow: 'auto',
                            whiteSpace: 'nowrap',
                            width: 192.67,
                            color: PXBColors.black[500]
                        }}

                    >

                        <Typography
                            variant="h6"
                        >
                            {props.customerInfo?.sLACount}
                        </Typography>

                        <Typography
                            style={{ fontSize: '16', color: PXBColors.black[500], fontStyle: 'normal', width: '92.3%' }}

                        >
                            {t('ASSETS_DETAILS.TOTAL_SLA')}
                        </Typography>
                    </ListSubheader>
                    <ListSubheader
                        component="div"
                        data-testid="sla-details-header"
                        id="sla-details-header"
                        style={{
                            display: 'flex',
                            fontWeight: 600,
                            fontSize: 14,
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            overflow: 'auto',
                            whiteSpace: 'nowrap',
                            width: 192.67,
                            color: PXBColors.black[500]
                        }}

                    >

                        <Typography
                            variant="h6"

                        >
                            {props.customerInfo?.buildingCount}
                        </Typography>

                        <Typography
                            style={{ fontSize: '16', color: PXBColors.black[500], fontStyle: 'normal', width: '92.3%' }}
                        >
                            {t('ASSETS_DETAILS.FOR_BUILDING')}
                        </Typography>
                    </ListSubheader>
                    <ListSubheader
                        component="div"
                        data-testid="sla-details-header"
                        id="sla-details-header"
                        style={{
                            display: 'flex',
                            fontWeight: 600,
                            fontSize: 14,
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            overflow: 'auto',
                            width: 192.67,
                            whiteSpace: 'nowrap',

                            height: 60,
                            color: PXBColors.black[500]
                        }}

                    >

                        <Typography
                            variant="h6"

                        >
                            {props.customerInfo?.systemCount}
                        </Typography>

                        <Typography style={{ fontSize: '16', color: PXBColors.black[500], fontStyle: 'normal', width: '92.3%' }}
                        >
                            {t('ASSETS_DETAILS.FOR_SYSTEM')}
                        </Typography>
                    </ListSubheader>
                </div>



                <Divider></Divider>

                <div style={{ display: 'flex', justifyContent: 'space-between', height: 48 }}>
                    <Typography
                        variant="subtitle2"
                        data-testid="view-all"
                        style={{
                            color: PXBColors.blue[600],
                            alignItems: 'center',
                            paddingTop: 12,
                            paddingBottom: 10,
                            marginLeft: 25,
                            fontWeight: 600,
                            fontSize: 14
                        }}
                        id="view-admin"
                    >
                        {t('LABELS.VIEW_ALL')}
                    </Typography>
                    <MuiCore.IconButton style={{ alignItems: 'right', width: '8%', color: PXBColors.blue[600] }} data-testid='viewAllNavigation' onClick={navigateToSLAList}>
                        {' '}
                        <MuiIcons.ChevronRight />{' '}
                    </MuiCore.IconButton>
                </div>
                {(usertype === USER_ROLE_TYPE.SERVICE_MANAGER || usertype === USER_ROLE_TYPE.TECHNICIAN) &&
                    <AssetAddSLADialog openDialogFlag={openOrgSelectDialog} closeClick={() => setOpenOrgSelectDialog(false)} ></AssetAddSLADialog>}

            </Card >
        </>
    );
};
