// eslint-disable-next-line
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { Box, Button, Card, Dialog, DialogActions, DialogContent, Divider, Snackbar, TextField, Typography } from '@mui/material';
import * as PXBColors from '@brightlayer-ui/colors';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { Check, Close, ConfirmationNumber, DeleteForever, Edit, Person, ReceiptLong } from '@mui/icons-material';
import { COLOR_BLUE_ICON, COLOR_GRAY_ICON, COLOR_RED_ICON } from '../../constants/color-codes';
import { MaintenanceDetail, ResolutionListDataGet } from '../../types/maintenance';
import { useHistory } from 'react-router';
import { ActionType, DESCRIPTION_LIMIT, RECORD_PER_PAGE_LIMIT } from '../../constants';
import { TICKET_STATUS } from '../enum/ENUM';
import { useToasts } from '../../data/DataProviders/ToastProvider';
import { UsermanagementThunks } from '../../actions/thunks/user-management-thunks';
import { useDispatch, useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import { UserManagementActions } from '../../actions/actions/usermanagement-actions';
import { Thunks } from '../../actions';
import { vh_10 } from '../../constants/dimentions';
import { formatDate } from '../enum/DateTimeFormat';
import { formatDateShort, formatTimeShort } from '../../lib/date-time-formatter';
import { pushAppRoute } from '../../data/domain/route-manager';


type Props = {
    maintenanceDetail: MaintenanceDetail;
};

export const MaintenanceCustomerDetail: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const maintenanceDetail = props.maintenanceDetail;
    const [maintainanceId, setMaintenanceId] = useState(props.maintenanceDetail.id);
    const onCancel = () => { }
    const history = useHistory();
    const [openCommentDialog, setOpenCommentDialog] = React.useState(false);
    const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
    const [alertErrorMessage, setAlertErrorMessage] = useState<string>('');
    const [description, setDescription] = React.useState('');
    const { addToast } = useToasts();
    const dispatch = useDispatch();
    const userManagement = useSelector(Selectors.Usermanagement.usermanagement);
    const [rowsPerPage, setRowsPerPage] = React.useState(RECORD_PER_PAGE_LIMIT);
    const [offset, setOffset] = React.useState(1);
    const [resolution, setResolution] = useState<ResolutionListDataGet>();

    useEffect(() => {
        if (maintenanceDetail?.resolutions !== undefined) {
            setResolution(maintenanceDetail?.resolutions[maintenanceDetail?.resolutions.length - 1])
        } else {
            setResolution(undefined)
        }
        // eslint-disable-next-line
    }, [maintenanceDetail?.resolutions]);

    const goToEditServiceTicket = (): void => {
        pushAppRoute(history,
            { type: 'CreateMaintainence', systemId: maintenanceDetail.systemId, buildingId: maintenanceDetail.buildingId, actionType: ActionType.EDIT },
            { backRoute: 'MaintenanceDetails', maintenanceId: maintenanceDetail.id });
    };

    const handleOpenCommentDialog = () => {
        setOpenCommentDialog(true);
    };

    const handleCloseCommentDialog = () => {
        setOpenCommentDialog(false);
        onCancel();
        setDescription('');
    };

    const onCommentsSave = (id: string): void => {
        dispatch(UsermanagementThunks.updateMaintainance(
            id,
            description,
            TICKET_STATUS.STATUS_COMPLETED
        ));
        setOpenCommentDialog(false);
        onCancel();
        setDescription('');
    }

    const handleUpdateMaintainanceResponse = (): void => {
        if (userManagement.updateMaintenanceErrorCode !== '') {
            if (userManagement.updateMaintenanceErrorCode === '9001')
                setAlertErrorMessage(t('TOAST_MSG.UPDATE_MAINTAINANCE_ERROR_CODE_9001'),);
            else if (userManagement.updateMaintenanceErrorCode === '9009')
                setAlertErrorMessage(t('TOAST_MSG.UPDATE_MAINTAINANCE_ERROR_CODE_9009'));
            else if (userManagement.updateMaintenanceErrorCode === '9078')
                setAlertErrorMessage(t('TOAST_MSG.UPDATE_MAINTAINANCE_ERROR_CODE_9078'));
            else if (userManagement.updateMaintenanceErrorCode === '9222')
                setAlertErrorMessage(t('TOAST_MSG.UPDATE_MAINTAINANCE_ERROR_CODE_9222'));
            else if (userManagement.updateMaintenanceErrorCode === '9227')
                setAlertErrorMessage(t('TOAST_MSG.UPDATE_MAINTAINANCE_ERROR_CODE_9227'));
            else if (userManagement.updateMaintenanceErrorCode === '9245')
                setAlertErrorMessage(t('TOAST_MSG.UPDATE_MAINTAINANCE_ERROR_CODE_9245'));
            else if (userManagement.updateMaintenanceErrorCode === '9246')
                setAlertErrorMessage(t('TOAST_MSG.UPDATE_MAINTAINANCE_ERROR_CODE_9246'));
            else
                setAlertErrorMessage(t('TOAST_MSG.UPDATE_MAINTAINANCE_API_FAILURE'));
        }
    };

    useEffect(() => {
        if (!userManagement.updateMaintenanceLoading && userManagement.updateMaintenanceSuccess) {
            addToast(t('TOAST_MSG.TICKET_CLOSED_SUCCESS'), 'success');
            dispatch(Thunks.UserManagement.getMaintenanceDetail('', maintenanceDetail.id, '', '', '', '', '', '', offset.toString(), rowsPerPage.toString()));
        }
        else if (!userManagement.updateMaintenanceLoading && userManagement.updateMaintenanceFail) {
            setDisplayErrorMessage(true);
            handleUpdateMaintainanceResponse();
        }
        dispatch(UserManagementActions.updateMaintenanceUnmounted())
    }, [userManagement.updateMaintenanceSuccess, userManagement.updateMaintenanceFail, userManagement.updateMaintenanceLoading]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Card style={{ height: 'fit-content', flex: '1 1 0px', overflow: 'auto' }}>
                <InfoListItem data-testid='customer-name' style={{ height: 56 }} title={''} subtitle={t('CUSTOMER_DETAILS.CUSTOMER_HEADER_SUBTITLE')}
                    icon={<Person style={{ color: COLOR_GRAY_ICON }} />}
                    iconAlign={'center'}
                    divider={'full'} placeholder={undefined}
                    rightComponent={<div>
                        <Typography data-testid="list-item-text-customer-name" style={{ fontWeight: 600, fontSize: 15, lineHeight: 2, fontStyle: 'normal', color: PXBColors.black[500] }}  >
                            {maintenanceDetail.customerName}
                        </Typography>
                    </div>} />
                <InfoListItem data-testid='customer-sla' style={{ height: 56 }} title={''} subtitle={t('USERS.SLA_MANDATORY_HEADER')}
                    icon={<ReceiptLong style={{ color: COLOR_GRAY_ICON }} />}
                    iconAlign={'center'}
                    divider={'full'} placeholder={undefined}
                    rightComponent={<div>
                        <Typography data-testid="list-item-text-customer-sla" style={{ fontWeight: 600, fontSize: 15, lineHeight: 2, fontStyle: 'normal', color: PXBColors.black[500] }}  >
                            {maintenanceDetail.sLANumber}
                        </Typography>
                    </div>}
                />
                <InfoListItem data-testid='service-ticket-number' style={{ height: 56 }} title={''} subtitle={t('MAINTENANCE_DETAILS.SERVICE_TICKET_NO')}
                    icon={<ConfirmationNumber style={{ color: COLOR_GRAY_ICON }} />}
                    iconAlign={'center'}
                    divider={'full'} placeholder={undefined}
                    rightComponent={<div>
                        <Typography data-testid="list-item-text-service-ticket-number" style={{ fontWeight: 600, fontSize: 15, lineHeight: 2, fontStyle: 'normal', color: PXBColors.black[500] }}  >
                            {maintenanceDetail.ticketNumber}
                        </Typography>
                    </div>}
                />

                {resolution !== undefined && resolution!?.description !== '' &&
                    <Box
                        display="flex"
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            height: 'wrap',
                            display: 'flex',
                            flexWrap: 'wrap',
                            width: '100%',
                            alignItems: 'center',
                            marginBottom: vh_10,
                        }}
                        data-testid="maintenance-close-description-card"
                    >
                        <Card style={{ height: 'fit-content', flex: '1 1 0px', overflow: 'auto' }}>
                            <div style={{ paddingTop: '2%' }}>
                                <div style={{ marginLeft: '5%', display: 'inline-block' }}>
                                    <Typography style={{ fontWeight: 600, fontSize: 15, fontStyle: 'normal' }}>{t('MAINTENANCE_DETAILS.TICKET_RESOLUTION')}</Typography>
                                </div>
                                <div style={{ display: 'inline-block', float: 'right', marginRight: '3%', fontWeight: 600, fontStyle: 'normal' }}>
                                    <Typography style={{ fontWeight: 600, fontSize: 15, fontStyle: 'normal' }}>{formatTimeShort(resolution.createdDateTime)}</Typography>
                                </div>
                            </div>
                            <div>
                                <div style={{ marginLeft: '5%', display: 'inline-block' }}>
                                    <Typography style={{ fontSize: 14, fontStyle: 'normal' }}>{t('MAINTENANCE_DETAILS.CLOSED_BY')} {resolution.createdBy}</Typography>
                                </div>
                                <div style={{ marginRight: '3%', display: 'inline-block', float: 'right' }}>
                                    <Typography style={{ fontSize: 14, fontStyle: 'normal' }}>{formatDateShort(resolution.createdDateTime)}</Typography>
                                </div>
                            </div>
                            <br></br>
                            <div style={{ marginLeft: '5%', marginBottom: '2%' }}>
                                <Typography variant='body1'>{resolution!?.description}</Typography>
                            </div>
                        </Card>
                    </Box>
                }
                <div >
                    <Button style={{
                        width: '95%', textTransform: 'none', marginLeft: 16, backgroundColor: PXBColors.blue[500],
                        color: PXBColors.white[50], marginBottom: 10, marginTop: 10
                    }} id="btnEditServiceTicketId" data-testid="btnEditServiceTicket" variant="outlined" disabled={false} onClick={goToEditServiceTicket} startIcon={<Edit style={{ color: PXBColors.white[500] }} />}>
                        {t('MAINTENANCE_DETAILS.EDIT_SERVICE_TICKET')}
                    </Button>
                </div>
                {maintenanceDetail.ticketStatus === TICKET_STATUS.STATUS_SCHEDULED || maintenanceDetail.ticketStatus === TICKET_STATUS.STATUS_DUE || maintenanceDetail.ticketStatus === TICKET_STATUS.STATUS_EXPIRED ?
                    <div>
                        <Button style={{ width: '95%', textTransform: 'none', marginLeft: 16, marginBottom: 10, marginTop: 10 }} id="btnCancel" data-testid="btnCancel1" variant="outlined" color="primary" disabled={false} onClick={handleOpenCommentDialog} startIcon={<Check style={{ color: COLOR_BLUE_ICON }} />}>
                            {t('MAINTENANCE_DETAILS.CLOSE_SERVICE_TICKET')}
                        </Button>
                    </div>
                    :
                    <div>
                        <Button style={{ width: '95%', textTransform: 'none', marginLeft: 16, marginBottom: 10, marginTop: 10 }} id="btnCancel" data-testid="btnCancel1" variant="outlined" color="primary" disabled={true} startIcon={<Check style={{ color: COLOR_BLUE_ICON }} />}>
                            {t('MAINTENANCE_DETAILS.REOPEN_SERVICE_TICKET')}
                        </Button>
                    </div>
                }
                <div>
                    <Button style={{ width: '95%', textTransform: 'none', marginLeft: 16, marginBottom: 10, borderColor: COLOR_RED_ICON }} id="btnCancel" data-testid="btnCancel1" variant="outlined" color="error" disabled={false} onClick={(): void => onCancel()} startIcon={<DeleteForever style={{ color: COLOR_RED_ICON }} />}>
                        {t('MAINTENANCE_DETAILS.DELETE_REQ')}

                    </Button>
                </div>
            </Card>
            {
                displayErrorMessage &&
                <Snackbar
                    open={true}
                    onClose={() => setDisplayErrorMessage(false)}
                    autoHideDuration={5000}
                    title={''}
                    message={alertErrorMessage}
                    action={
                        <Button color="inherit" size="small" onClick={() => setDisplayErrorMessage(false)}>
                            {t('ACTIONS.OKAY')}
                        </Button>
                    }
                    ContentProps={{ sx: { background: PXBColors.red[500] } }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    sx={{ bottom: { xs: 90, sm: 0 } }}

                    style={{ height: 200, width: 300, flexGrow: 0 }}
                />
            }
            <Dialog id='commentDialogBox' fullWidth open={openCommentDialog} onClose={handleCloseCommentDialog} style={{ borderRadius: 4, boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)' }} >
                <div id='dialogTitleDiv' style={{ marginTop: 10 }}>
                    <Button id="close-ticket-closeBtn" data-testid="close-ticket-closeBtn" style={{ float: 'left', color: 'rgba(0, 0, 0, 0.54)', backgroundColor: 'white' }} onClick={handleCloseCommentDialog}>
                        <Close />
                    </Button>
                    <Typography variant='subtitle1' style={{ width: 'auto', paddingTop: '0.6%' }}>
                        {t('MAINTENANCE_DETAILS.CLOSE_TICKET')}
                    </Typography>
                </div>
                <Divider></Divider>
                <DialogContent id='commentBoxContent' style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 20, paddingBottom: 5 }}>
                    <TextField
                        style={{ width: '100%', float: 'left', wordWrap: 'break-word', color: PXBColors.black[500] }}
                        id="description"
                        data-testid='description'
                        variant={'filled'}
                        label='Description'
                        rows={2}
                        multiline
                        fullWidth
                        value={description}
                        onChange={(evt: ChangeEvent<HTMLInputElement>): void => setDescription(evt.target.value)}
                        inputProps={{ 'data-testid': 'close-ticket-description', maxLength: DESCRIPTION_LIMIT }}
                    />
                    <Typography style={{ width: 'auto', float: 'left', fontSize: 13, color: PXBColors.gray[500] }}>
                        {t('MAINTENANCE_DETAILS.DESCRIBE_ACTION')}
                    </Typography>
                    <Typography style={{ width: 'auto', float: 'right', fontSize: 13, color: PXBColors.gray[500] }}>
                        {description.length}/{DESCRIPTION_LIMIT}
                    </Typography>
                </DialogContent>
                <Button
                    style={{
                        marginBottom: 10,
                        marginTop: 15,
                        width: 'auto',
                        height: 36,
                        flexDirection: 'row',
                        marginRight: 10,
                        marginLeft: 10,
                        backgroundColor: PXBColors.blue[500],
                        color: PXBColors.white[50],
                    }}
                    fullWidth
                    id="cancelButton"
                    variant="outlined"
                    color="primary"
                    data-testid="cancelButton"
                    onClick={() => onCommentsSave(maintenanceDetail.id)}
                    startIcon={<Check style={{ color: PXBColors.white[500] }} />}>
                    {t('MAINTENANCE_DETAILS.CLOSE_TICKET')}
                </Button>
            </Dialog>
        </>
    );
};
