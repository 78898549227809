// eslint-disable-next-line
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Snackbar, TextField, Typography } from '@mui/material';
import * as PXBColors from '@brightlayer-ui/colors';
import { Country, CountryList } from '../../state/registration-state';
import { useDispatch, useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import { ALPHA_NUMBERIC, BUILDING_CHARACTER_LIMIT, BUILDING_COMMENTS_LIMIT, BUILDING_NAME, COMPANY_ADDRESS, COMPANY_ADDRESS_MAX_LENGTH, COMPANY_CITY, COMPANY_CITY_MAX_LENGTH, COMPANY_POSTALCODE_MAX_LENGTH, COUNTRY_CODE_MAX_LENGTH, EMPTY_STRING, FIRST_NAME_MAX_LENGTH, LAST_NAME_MAX_LENGTH, NAME_VALIDATION, ORG_EMAIL_MAX_LENGTH, PHONE_MAX_LENGTH, PHONE_MIN_LENGTH, PHONE_NUMBER } from '../../constants';
import { Thunks } from '../../actions';
import { OrganizationThunks } from '../../actions/thunks/organization-thunks';
import { useAuthHelper } from '../../components/AuthContextProvider';
import { useToasts } from '../../data/DataProviders/ToastProvider';
import { GenericToolbar } from '../../components/GenericToolbar';
import { ToolbarColor } from '../../components/GenericToolbar/GenericToolbar';
import { OrganizationData, OrgBuildingData } from '../../types/organization';
import { Checkbox } from '@mui/material';
import { BuildingMap } from './BuildingMap';
import { validEmail, validPhoneNumberLength, validPostalCode } from '../../lib/form-validator';
import { EmptyState } from '@brightlayer-ui/react-components';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import { GeoCodingBuildingAddress } from '../../types/building';
import { OrganizationActions } from '../../actions/actions/organization-actions';
import { vw_120 } from '../../constants/dimentions';
import { pushAppRoute } from '../../data/domain/route-manager';


export const orgDetailsDefault: OrganizationData = {
    projectId: "",
    name: "",
    description: "",
    country: "",
    address: "",
    postalCode: "",
    city: "",
    countryCode: "",
    phone: "",
    email: "",
    systemCount: 0,
    buildingCount: 0,
    gatewayCount: 0,
    adminCount: 0,
    viewerCount: 0,
    isAdmin: false,
    isFavourite: false,
    address2: '',
    primaryFirstName: null,
    primaryLastName: null,
    secondaryFirstName: null,
    secondaryLastName: null,
    secondaryEmail: null,
    secondaryPhone: null,
    secondaryCountryCode: null
};
const buildingDetailsDefault: OrgBuildingData = {
    buildingId: "",
    name: "",
    parentProjectId: "",
    projectName: "",
    address: "",
    postalCode: "",
    city: "",
    country: "",
    contact: '',
    email: "",
    countryCode: "",
    phone: "",
    description: "",
    adopterId: '',
    latitude: "",
    longitude: "",
    gatewayCount: 0,
    systemCount: 0,
    adminCount: 0,
    viewerCount: 0,
    isAdmin: false,
    isFavourite: false,
    address2: '',
    secondaryEmail: '',
    secondaryCountryCode: '',
    secondaryPhone: '',
    primaryFirstName: '',
    primaryLastName: '',
    secondaryFirstName: null,
    secondaryLastName: null
};
type Props = {
    location: any;
};
export const UpsertBuilding: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const history = useHistory();
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const [isUpsertBuildingPage, setUpsertBuildingPage] = useState(false);
    const [isOrgAddressCheckBox, setIsOrgAddressCheckBox] = useState(false);
    const [isContactDetailsChecked, setIsContactDetailsChecked] = useState(false);
    const [isOrgAddressAvailable, setIsOrgAddressAvailable] = useState(false);
    const [isContactDetailsAvailable, setIsContactDetailsAvailable] = useState(false);
    const { t } = useLanguageLocale();
    const authHelper = useAuthHelper();
    const { addToast } = useToasts();
    const { backRoute, orgId, buildingId, assetId, gatewayId, deviceId } = props.location.state;
    const dispatch = useDispatch();
    const registration = useSelector(Selectors.Registration.registration);
    const countryListLoading = useSelector(Selectors.Registration.countryListLoading);
    const countryListSuccess = useSelector(Selectors.Registration.countryListSuccess);
    const [isUpsertDone, setIsUpsertDone] = useState(false);
    const [isFormField, setFormField] = useState(false);
    const [isFormEdit, setFormEdit] = useState(false);
    const [comments, setComments] = React.useState('');
    const [email, setEmail] = useState('');
    const [buildingName, setBuildingName] = useState('');
    const [countryName, setCountryName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [companyAddress, setCompanyAddress] = useState('');
    const [companyAddress2, setCompanyAddress2] = useState('');
    const [companyPostalCode, setCompanyPostalCode] = useState('');
    const [companyCity, setCompanyCity] = useState('');
    const [countryList, setCountryList] = useState<CountryList>([]);
    const [selectedCountry, setSelectedCountry] = useState<Country>({ name: '', code: '' });
    const [countryCode, setCountryCode] = useState('');
    const [latitude, setlatitude] = useState('');
    const [longitude, setlongitude] = useState('');
    const [appBarColor, setAppBarColor] = useState(ToolbarColor.Default);
    const [header, setHeader] = React.useState(t('DASHBOARD.ADD_BUILDING'));
    const organization = useSelector(Selectors.Organization.organization);
    const [organizationDetails, setOrganizationDetails] = useState<OrganizationData>(orgDetailsDefault);
    const [buildingDetails, setBuildingDetails] = useState<OrgBuildingData>(buildingDetailsDefault);
    const [buildingEditDetails, setBuildingEditDetails] = useState<OrgBuildingData>(buildingDetailsDefault);
    const [openQuitDialog, setOpenQuitDialog] = React.useState(false);
    const [orgLoaded, setOrgLoaded] = React.useState(false);
    const [buildingLoaded, setBuildingLoaded] = React.useState(false);
    const [countryLoaded, setCountryLoaded] = React.useState(false);
    const [dataLoaded, setDataLoaded] = React.useState(false);
    const [isOpenMap, setOpenMap] = React.useState(true);
    const [isMapButtonEnable, setMapButtonEnable] = React.useState(false);
    const [isLocationAvailable, setLocationAvailable] = React.useState(false);
    const [primaryFirstName, setPrimaryFirstName] = useState('');
    const [primaryLastName, setPrimaryLastName] = useState('');
    const [secondaryEmail, setSecondaryEmail] = useState('');
    const [secondaryFirstName, setSecondaryFirstName] = useState('');
    const [secondaryLastName, setSecondaryLastName] = useState('');
    const [secondaryPhoneNumber, setSecondaryPhoneNumber] = useState('');
    const [secondaryCountryCode, setSecondaryCountryCode] = useState('');
    const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
    const [alertErrorMessage, setAlertErrorMessage] = useState<string>('');

    const handleLinkCustomerResponse = (): void => {
        if (!organization.upsertBuildingDetailLoading && organization.upsertBuildingDetailFail) {
            if (organization.upsertBuildingDetailErrorMessage === '9122')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9122'));
            else if (organization.upsertBuildingDetailErrorMessage === '9085')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_BUILDING_NAME_ALREADY_EXISTS'));
            else if (organization.upsertBuildingDetailErrorMessage === '9069')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9069'));
            else if (organization.upsertBuildingDetailErrorMessage === '9070')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9070'));
            else if (organization.upsertBuildingDetailErrorMessage === '9071')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_ORG_9071'));
            else if (organization.upsertBuildingDetailErrorMessage === '9072')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9072'));
            else if (organization.upsertBuildingDetailErrorMessage === '9043')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9043'));
            else if (organization.upsertBuildingDetailErrorMessage === '9073')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9073'));
            else if (organization.upsertBuildingDetailErrorMessage === '9074')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9074'));
            else if (organization.upsertBuildingDetailErrorMessage === '9079')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9079'));
            else if (organization.upsertBuildingDetailErrorMessage === '9149')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9149'));
            else if (organization.upsertBuildingDetailErrorMessage === '9077')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9077'));
            else if (organization.upsertBuildingDetailErrorMessage === '9075')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9075'));
            else if (organization.upsertBuildingDetailErrorMessage === '9076')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9076'));
            else if (organization.upsertBuildingDetailErrorMessage === '9078')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9078'));
            else if (organization.upsertBuildingDetailErrorMessage === '9001')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_LINK_CUSTOMER_ERROR_INVAILD_TOKEN'));
            else if (organization.upsertBuildingDetailErrorMessage === '9086')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9086'));
            else if (organization.upsertBuildingDetailErrorMessage === '9081')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9081'));
            else if (organization.upsertBuildingDetailErrorMessage === '9082')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9082'));
            else if (organization.upsertBuildingDetailErrorMessage === '9083')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9083'));
            else if (organization.upsertBuildingDetailErrorMessage === '9068')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9068'));
            else if (organization.upsertBuildingDetailErrorMessage === ' 9150')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9150'));
            else if (organization.upsertBuildingDetailErrorMessage === '9151')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9151'));
            else if (organization.upsertBuildingDetailErrorMessage === '9152')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9152'));
            else if (organization.upsertBuildingDetailErrorMessage === '9153')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9153'));
            else if (organization.upsertBuildingDetailErrorMessage === '9146')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9146'));
            else if (organization.upsertBuildingDetailErrorMessage === '9147')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_ORG_9147'));
            else
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_BUILDING_UPDATE'));
        }

    }

    useEffect(() => {
        dispatch(Thunks.Registration.countryCodeRequest());
    }, [orgId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (buildingId ? orgLoaded && countryLoaded && buildingLoaded : orgLoaded && countryLoaded) {
            if (buildingId)
                setBuildDetails(buildingDetails);
            if (buildingId ? !countryListLoading && !organization.orgListLoading && !organization.buildingListLoading : !countryListLoading && !organization.orgListLoading) {
                setDataLoaded(true);
            }
        }
        else
            setDataLoaded(false);
    }, [orgLoaded, buildingLoaded, countryLoaded, countryListLoading, organization.orgListLoading, organization.buildingListLoading]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!countryListLoading && countryListSuccess) {
            setCountryList(registration.countryList);
            setCountryLoaded(true);
            if (orgId && buildingId) {
                if (organization.buildingListSuccess) {
                    const country: Country = registration.countryList.filter((country) => country.name.trim() === organization.buildingList[0].country.trim())[0];
                    setSelectedCountry(country);
                    setCountryName(country.name);
                }
            }
        }
    }, [countryListLoading, countryListSuccess, organization.buildingListSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(Thunks.Organization.getOrganizationDetailsList(orgId));
    }, [orgId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (buildingId)
            dispatch(Thunks.Organization.getBuildingDetailsList('', buildingId));
    }, [buildingId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (backRoute === 'OrgBuildingDetails' || backRoute === 'AssetDetails') {
            setBuildingName(buildingDetails.name);
            setHeader(t('ACTIONS.EDIT') + " \"" + buildingName + "\"");
        }
        else {
            setHeader((t('DASHBOARD.ADD_BUILDING')) + organizationDetails.name);
            setBuildDetails(buildingDetailsDefault);

        }
    }, [buildingDetails, organizationDetails]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!organization.upsertBuildingDetailLoading && organization.upsertBuildingDetailFail) {
            handleLinkCustomerResponse();
            setDisplayErrorMessage(true);
            setLocationAvailable(false);
            dispatch(OrganizationActions.upsertBuildingUnmounted());
        }
        OrganizationActions.buildingListUnmounted();
    }, [organization.upsertBuildingDetailLoading, organization.upsertBuildingDetailSuccess, organization.upsertBuildingDetailFail])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!organization.orgListLoading && organization.orgListSuccess) {
            const orgDetail = organization.orgList.detail[0];
            setOrganizationDetails(orgDetail);
            setOrgLoaded(true);
            if (fieldsComplete([orgDetail.address ? orgDetail.address : '', orgDetail.city, orgDetail.country, orgDetail.postalCode ? orgDetail.postalCode : ''])) {
                setIsOrgAddressAvailable(true);
            }
            if (fieldsComplete([orgDetail.email ? orgDetail.email : '', orgDetail.phone ? orgDetail.phone : '', orgDetail.countryCode ? orgDetail.countryCode : '', orgDetail.primaryFirstName ? orgDetail.primaryFirstName : '', orgDetail.primaryLastName ? orgDetail.primaryLastName : ''])) {
                setIsContactDetailsAvailable(true);
            }
        } else if (!organization.orgListLoading && organization.orgListFail) {
            setOrganizationDetails(orgDetailsDefault);
            setIsOrgAddressAvailable(false);
            setIsContactDetailsAvailable(false);
        }
        dispatch(OrganizationActions.orgListUnmounted());
    }, [organization.orgListLoading, organization.orgListSuccess, organization.orgListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!organization.buildingListLoading && organization.buildingListSuccess) {
            setBuildingDetails(organization.buildingList[0]);
            setBuildingEditDetails(organization.buildingList[0]);
            setBuildingName(organization.buildingList[0].name);
            setBuildingLoaded(true);
        } else if (!organization.buildingListLoading && organization.buildingListFail) {
            setBuildingDetails(buildingDetailsDefault);
            setBuildingEditDetails(buildingDetailsDefault);
        }
    }, [organization.buildingListLoading, organization.buildingListSuccess, organization.buildingListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (organization.upsertBuildingDetailSuccess && isUpsertDone) {
            onQuitClick(true);//true => To identify save button action and navigate user to building details screen
            setFormField(false);
            setAppBarColor(ToolbarColor.Default);
            setFormEdit(false);
            setHeader(buildingName);
        }
        else if (organization.upsertBuildingDetailFail)
            setIsUpsertDone(false);
        else {
            //todo false state
        }
    }, [organization.upsertBuildingDetailSuccess, organization.upsertBuildingDetailFail, organization.upsertBuildingDetailLoading, isUpsertDone]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleAddressCheckbox = (
        event: React.ChangeEvent<HTMLElement>,
        checked: boolean,
    ) => {
        if (checked) {
            setIsOrgAddressCheckBox(true);
            setCompanyAddress(organizationDetails.address ? organizationDetails.address : '');
            setCompanyAddress2(organizationDetails.address2 ? organizationDetails.address2 : '');
            setCompanyPostalCode(organizationDetails.postalCode ? organizationDetails.postalCode : '');
            setCompanyCity(organizationDetails.city);
            setCountryName(organizationDetails.country);
            setUpsertBuildingPage(true);
            setMapButtonEnable(false);
        }
        else if (!checked) {
            setCompanyAddress(EMPTY_STRING);
            setCompanyAddress2(EMPTY_STRING);
            setCompanyCity(EMPTY_STRING);
            setCompanyPostalCode(EMPTY_STRING);
            setCountryName(EMPTY_STRING);
            setIsOrgAddressCheckBox(false);
            setUpsertBuildingPage(false);
            setMapButtonEnable(false);
        }
    };
    const handleContactDetailCheckbox = (
        event: React.ChangeEvent<HTMLElement>,
        checked: boolean,
    ) => {
        if (checked) {
            setIsContactDetailsChecked(true);
            setEmail(organizationDetails.email ? organizationDetails.email : '');
            setPrimaryFirstName(organizationDetails.primaryFirstName ? organizationDetails.primaryFirstName : '');
            setPrimaryLastName(organizationDetails.primaryLastName ? organizationDetails.primaryLastName : '');
            setCountryCode(organizationDetails.countryCode);
            setPhoneNumber(organizationDetails.phone ? organizationDetails.phone : '');
            setSecondaryEmail(organizationDetails.secondaryEmail ? organizationDetails.secondaryEmail : '');
            setSecondaryFirstName(organizationDetails.secondaryFirstName ? organizationDetails.secondaryFirstName : '');
            setSecondaryLastName(organizationDetails.secondaryLastName ? organizationDetails.secondaryLastName : '');
            setSecondaryCountryCode(organizationDetails.secondaryCountryCode ? organizationDetails.secondaryCountryCode : '');
            setSecondaryPhoneNumber(organizationDetails.secondaryPhone ? organizationDetails.secondaryPhone : '');
        }
        else if (!checked) {
            setEmail(EMPTY_STRING);
            setCountryCode(EMPTY_STRING);
            setPhoneNumber(EMPTY_STRING);
            setSecondaryCountryCode(EMPTY_STRING);
            setSecondaryPhoneNumber(EMPTY_STRING);
            setPrimaryFirstName(EMPTY_STRING);
            setPrimaryLastName(EMPTY_STRING);
            setSecondaryEmail(EMPTY_STRING);
            setSecondaryFirstName(EMPTY_STRING);
            setSecondaryLastName(EMPTY_STRING);
            setIsContactDetailsChecked(false);
        }
    };
    useEffect(() => {
        if (orgId && buildingId) {
            setFormField(false);
            setMapButtonEnable(true);
            setAppBarColor(ToolbarColor.Default);
            if (email === buildingEditDetails.email &&
                primaryFirstName === buildingDetails.primaryFirstName &&
                primaryLastName === buildingDetails.primaryLastName &&
                secondaryFirstName === buildingDetails.secondaryFirstName &&
                secondaryLastName === buildingDetails.secondaryLastName &&
                secondaryEmail === buildingDetails.secondaryEmail &&
                secondaryCountryCode === buildingDetails.secondaryCountryCode &&
                secondaryPhoneNumber === buildingDetails.secondaryPhone &&
                phoneNumber === buildingEditDetails.phone &&
                buildingName === buildingEditDetails.name &&
                comments === buildingEditDetails.description &&
                countryName.trim() === buildingEditDetails.country.trim() &&
                companyAddress === buildingEditDetails.address &&
                companyAddress2 === buildingEditDetails.address2 &&
                countryCode === buildingEditDetails.countryCode &&
                companyCity === buildingEditDetails.city &&
                companyPostalCode === buildingEditDetails.postalCode
            ) {
                setFormField(false);
                setAppBarColor(ToolbarColor.Default);
                setFormEdit(false);
            }
            else {
                //When country are non empty, then only show map
                if (!isOrgAddressCheckBox)
                    setMapButtonEnable(fieldsComplete([countryName]));
                //When all field are non empty and valid only then details can be saved
                if (!countryName)
                    setOpenMap(false);
                if (fieldsComplete([buildingName, email, phoneNumber, countryName, countryCode, companyAddress, companyCity, companyPostalCode, primaryFirstName, primaryLastName])
                    &&
                    (!companyPostalCode || (companyPostalCode && validPostalCode(companyPostalCode))) &&
                    (!email || (email && validEmail(email))) &&
                    (!phoneNumber || (phoneNumber && validPhoneNumberLength(phoneNumber))) &&
                    (!secondaryEmail || (secondaryEmail && validEmail(secondaryEmail))) &&
                    (!secondaryPhoneNumber || (secondaryPhoneNumber && validPhoneNumberLength(secondaryPhoneNumber)))) {
                    setFormField(true);
                    setAppBarColor(ToolbarColor.Other);
                    setFormEdit(true);
                }
                else {
                    setFormField(false);
                    setAppBarColor(ToolbarColor.Default);
                    setFormEdit(false);
                }
            }
        }
        else {
            if (!countryName)
                setOpenMap(false);
            if (!isOrgAddressCheckBox)
                setMapButtonEnable(fieldsComplete([countryName]));
            if (
                fieldsComplete([buildingName, countryName, countryCode, companyAddress, companyCity, companyPostalCode, primaryFirstName, primaryLastName, email, phoneNumber]) &&
                (!companyPostalCode || (companyPostalCode && validPostalCode(companyPostalCode))) &&
                (!email || (email && validEmail(email))) &&
                (!phoneNumber || (phoneNumber && validPhoneNumberLength(phoneNumber))) &&
                (!secondaryEmail || (secondaryEmail && validEmail(secondaryEmail))) &&
                (!secondaryPhoneNumber || (secondaryPhoneNumber && validPhoneNumberLength(secondaryPhoneNumber)))) {
                setFormField(true);
                setAppBarColor(ToolbarColor.Other);
            }
            else {
                setFormField(false);
                setAppBarColor(ToolbarColor.Default);
            }
        }
        if (isOrgAddressCheckBox)
            setMapButtonEnable(false);
    }, [email, countryName, phoneNumber, companyAddress, companyPostalCode, companyCity, countryCode, buildingName, companyAddress2, comments, primaryFirstName, primaryLastName, secondaryFirstName, secondaryLastName, secondaryEmail, secondaryCountryCode, secondaryPhoneNumber, isOrgAddressCheckBox]) // eslint-disable-line react-hooks/exhaustive-deps

    const onCompanyAddressChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || COMPANY_ADDRESS.test(e.target.value)) {
            setCompanyAddress(e.target.value);
        }
    };

    const onCompanyAddressChange2 = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || COMPANY_ADDRESS.test(e.target.value)) {
            setCompanyAddress2(e.target.value);
        }
    };

    const onCompanyPostalCodeChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || ALPHA_NUMBERIC.test(e.target.value)) {
            setCompanyPostalCode(e.target.value as string);
        }
    };

    const onCompanyCityChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || COMPANY_CITY.test(e.target.value)) {
            setCompanyCity(e.target.value as string);
        }
    };

    const onChangePhoneNumber = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || PHONE_NUMBER.test(e.target.value)) {
            setPhoneNumber(e.target.value);
        }
    };

    const onChangeCountryCode = (event: SelectChangeEvent) => {
        setCountryCode(event.target.value as string);
    };
    const onChangeSecondaryPhoneNumber = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || PHONE_NUMBER.test(e.target.value)) {
            setSecondaryPhoneNumber(e.target.value);
        }
    };

    const onChangeSecondaryCountryCode = (event: SelectChangeEvent) => {
        setSecondaryCountryCode(event.target.value as string);
    };

    const onPrimaryFirstNameChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || NAME_VALIDATION.test(e.target.value)) {
            setPrimaryFirstName(e.target.value);
        }
    };
    const onPrimaryLastNameChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || NAME_VALIDATION.test(e.target.value)) {
            setPrimaryLastName(e.target.value);
        }
    };
    const onSecondaryFirstNameChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || NAME_VALIDATION.test(e.target.value)) {
            setSecondaryFirstName(e.target.value);
        }
    };
    const onSecondaryLastNameChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || NAME_VALIDATION.test(e.target.value)) {
            setSecondaryLastName(e.target.value);
        }
    };

    const onChangeCountryName = (event: SelectChangeEvent) => {
        const countryNameData = event.target.value as string
        setCountryName(countryNameData);
        // eslint-disable-next-line
        const countryCodeData = countryList.filter(function (countryData) {
            if (countryData.name === countryNameData)
                return countryData
        });
        setCountryCode(countryCodeData?.[0].code);
    };

    const onBuildingNameChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
        if (event.target.value === '' || BUILDING_NAME.test(event.target.value as string)) {
            setBuildingName(event.target.value as string);
        }
    };

    const handleQuitDialog = () => {
        setOpenQuitDialog(true);
    };

    const handleCancel = () => {
        setOpenQuitDialog(false);
    }

    const showMap = () => {
        setOpenMap(true);
    }

    const updatePosition = (lat: number, lng: number) => {
        setlatitude(lat.toString());
        setlongitude(lng.toString());
    }

    //Geocode Address form update according to map location
    const updatedAddress = (value: GeoCodingBuildingAddress) => {
        let stnumber = value.streetNumber ? value.streetNumber + " " : '';
        let staddress = value.route ? value.route : '';
        let companyAdd = stnumber + staddress;
        setCompanyAddress(companyAdd ? companyAdd : value.establishment ? value.establishment : '');
        setCompanyCity(value.city ? value.city : value.postalTown ? value.postalTown : "");
        setCompanyPostalCode(value.postalCode ? value.postalCode.replace(/\s+/g, '') : "");
        const countryA: Country = registration.countryList.filter((country) => country.name.trim() === value.country)[0];
        if (countryA && countryA.name) {
            setSelectedCountry(countryA);
            setCountryName(countryA.name);
            setCountryCode(countryA.code);
        }
    }

    const onQuitClick = (saveActionState?: boolean) => {
        if (backRoute === 'OrganizationDetails') {
            pushAppRoute(history,
                { type: 'OrganizationDetails', orgId: orgId },
                { backRoute: 'Organizations', orgId: orgId });
        } else if (backRoute === 'OrgBuildingDetails' || saveActionState === true) {
            pushAppRoute(history,
                { type: 'OrgBuildingDetails', buildingId: assetId },
                { backRoute: 'OrganizationDetails', orgId: orgId, buildingId: buildingId });
        } else if (backRoute === 'AssetDetails') {
            pushAppRoute(history,
                { type: 'AssetDetails', deviceId: deviceId, gatewayId: gatewayId, projectId: orgId, buildingId: buildingId },
                { backRoute: 'Assets', deviceId: deviceId, gatewayId: gatewayId, projectId: orgId, buildingId: buildingId });
        }
        else {
            history.goBack();
        }
        setIsUpsertDone(false);
    }

    const fieldsComplete = (fields: string[]): boolean => {
        for (let i = 0; i < fields.length; i++) {
            if (fields[i].length < 1) {
                return false;
            }
        }
        return true;
    };

    const setBuildDetails = (buildingDetails: OrgBuildingData) => {
        setBuildingName(buildingDetails.name);
        setComments(buildingDetails.description);
        setCompanyAddress(buildingDetails.address);
        setCompanyAddress2(buildingDetails.address2);
        setCountryCode(buildingDetails.countryCode);
        setCompanyCity(buildingDetails.city);
        setCompanyPostalCode(buildingDetails.postalCode);
        setCountryName(buildingDetails.country);
        setEmail(buildingDetails.email);
        setPrimaryFirstName(buildingDetails.primaryFirstName ? buildingDetails.primaryFirstName : '');
        setPrimaryLastName(buildingDetails.primaryLastName ? buildingDetails.primaryLastName : '');
        setSecondaryEmail(buildingDetails.secondaryEmail ? buildingDetails.secondaryEmail : '');
        setSecondaryFirstName(buildingDetails.secondaryFirstName ? buildingDetails.secondaryFirstName : '');
        setSecondaryLastName(buildingDetails.secondaryLastName ? buildingDetails.secondaryLastName : '');
        setSecondaryCountryCode(buildingDetails.secondaryCountryCode ? buildingDetails.secondaryCountryCode : '');
        setSecondaryPhoneNumber(buildingDetails.secondaryPhone ? buildingDetails.secondaryPhone : '')
        setPhoneNumber(buildingDetails.phone);
        setlatitude((buildingDetails.latitude));
        setlongitude((buildingDetails.longitude));
    }

    // Map geocoding call save after geting address position
    useEffect(() => {
        if (isLocationAvailable) {
            setIsUpsertDone(true);
            dispatch(OrganizationThunks.upsertBuilding(
                addToast,
                authHelper,
                orgId,
                buildingId,
                buildingName,
                email,
                countryCode,
                phoneNumber,
                companyAddress,
                companyPostalCode,
                companyCity,
                comments,
                companyAddress2,
                countryName,
                latitude,
                longitude,
                primaryFirstName,
                primaryLastName,
                secondaryFirstName,
                secondaryLastName,
                secondaryEmail,
                secondaryCountryCode,
                secondaryPhoneNumber
            ));
        }
    }, [isLocationAvailable]); // eslint-disable-line react-hooks/exhaustive-deps

    const saveData = (): void => {
        if (fieldsComplete([buildingName, countryName, countryCode, companyAddress, email, phoneNumber, companyCity, companyPostalCode, primaryFirstName, primaryLastName])) {
            let currentAddress = companyAddress + ' ' + companyCity + ' ' + countryName;
            let url = `https://maps.googleapis.com/maps/api/geocode/json?address=+${currentAddress}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
            fetch(url)
                .then(res => res.json())
                .then(res => {
                    if (res.status === "OK") {
                        setlatitude(res.results[0].geometry.location.lat);
                        setlongitude(res.results[0].geometry.location.lng);
                        setLocationAvailable(true);
                    } else if (res.status === "ZERO_RESULTS") {
                        //ToDO- unable to get user geolocation
                    }
                })
        }
        else {
            addToast("Please fill all the details properly before submission.");
        }
    }

    const onCancel = () => {
        if (buildingId) {
            setBuildDetails(buildingDetails);
            setFormField(false);
            setAppBarColor(ToolbarColor.Default);
            setIsOrgAddressCheckBox(false);
            setIsContactDetailsChecked(false);
        }
        else {
            setIsOrgAddressCheckBox(false);
            setIsContactDetailsChecked(false);
            setBuildingName(EMPTY_STRING);
            setComments(EMPTY_STRING);
            setCompanyAddress(EMPTY_STRING);
            setCompanyAddress2(EMPTY_STRING);
            setCountryCode(EMPTY_STRING);
            setCompanyCity(EMPTY_STRING);
            setCompanyPostalCode(EMPTY_STRING);
            setCountryName(EMPTY_STRING);
            setEmail(EMPTY_STRING);
            setPhoneNumber(EMPTY_STRING);
            setPrimaryFirstName(EMPTY_STRING);
            setPrimaryLastName(EMPTY_STRING);
            setSecondaryEmail(EMPTY_STRING);
            setSecondaryFirstName(EMPTY_STRING);
            setSecondaryLastName(EMPTY_STRING);
            setlatitude(EMPTY_STRING);
            setlongitude(EMPTY_STRING);
            setSecondaryCountryCode(EMPTY_STRING);
            setSecondaryPhoneNumber(EMPTY_STRING);
        }
    }

    return (
        <div data-testid="buildings-details-container">
            <GenericToolbar
                title={header}
                showBackButton={!isFormEdit}
                showCloseButton={isFormEdit}
                actionUIState={isFormField && !organization.buildingListLoading}
                isButtonClicked={isUpsertDone}
                backButtonOnClick={(): void => {
                    if (!isFormEdit) {
                        onQuitClick()
                    }
                    else {
                        handleQuitDialog()
                    }
                }}
                saveOnClick={(): void => {
                    saveData()
                }}
                cancelOnClick={(): void => {
                    onCancel()
                }}
                color={appBarColor}
            />
            {!dataLoaded && <EmptyState style={{ flex: 1, minHeight: 400, backgroundColor: PXBColors.white[50] }}
                icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}
            {dataLoaded && <div data-testid="container-create-building" style={{ backgroundColor: PXBColors.white[50], height: md ? 1250 : 1600 }}>
                <div style={{ height: 800, width: '100%', padding: '10%', paddingTop: '4%', display: md ? 'flex' : '' }}>
                    <div style={{ float: 'left', width: md ? '49%' : '', paddingRight: '5%' }}>
                        <div style={{ display: 'flex', gap: 15 }}>
                            <InfoIcon style={{ color: PXBColors.blue[500] }} />
                            <Typography variant='body1' style={{ fontWeight: 400, fontSize: 16 }}>{t('FORMS.HELPING_TEXT')}</Typography>
                        </div>
                        <div style={{ display: 'flex', marginLeft: 'auto', marginTop: 15 }}>
                            <Typography
                                id="org-address-check-lable"
                                data-testid="org-address-check-lable"
                                style={{ fontSize: '16', fontWeight: 400, alignSelf: 'center', color: PXBColors.black[500] }}>
                                {t('FORMS.BUILDING_DETAILS')}
                            </Typography>
                        </div>
                        <TextField
                            style={{ marginTop: 24, width: '100%', fontSize: '16px', color: PXBColors.black[500] }}
                            id="orgname"
                            variant={'filled'}
                            label={t('USER_SETTINGS.BUILDING_NAME')}
                            fullWidth
                            required
                            value={buildingName}
                            onChange={(e): void => onBuildingNameChange(e)}
                            inputProps={{ 'data-testid': 'buildingnameLabelId2', maxLength: BUILDING_CHARACTER_LIMIT }}
                        />
                        <div data-testid='helperTextId'
                            style={{ height: 17, width: '100%', color: PXBColors.gray[500] }}>
                            <small className="form-text" style={{ width: '85%', float: 'left', paddingLeft: '2%' }}>
                                {t('ORGANIZATION_MANAGE.ORGANIZATION_NAME_HELPER')}</small>
                            <Typography data-testid='charLimitId' style={{ width: 'auto', float: 'right', fontSize: '12px', color: PXBColors.gray[500] }}>
                                {buildingName.length}/{BUILDING_CHARACTER_LIMIT}
                            </Typography>
                        </div>
                        <TextField
                            style={{ marginTop: 24, width: '100%', float: 'left', wordWrap: 'break-word', color: PXBColors.black[500] }}
                            id="companycomments"
                            data-testid='companycommentsId'
                            variant={'filled'}
                            label={t('LOGBOOK_DETAILS.COMMENTS')}
                            rows={2}
                            multiline
                            fullWidth
                            onChange={(evt: ChangeEvent<HTMLInputElement>): void => setComments(evt.target.value)}
                            value={comments}
                            inputProps={{ 'data-testid': 'company-comments', maxLength: BUILDING_COMMENTS_LIMIT }}
                        />
                        <div
                            style={{ height: 17, width: '100%', color: PXBColors.gray[500], marginBottom: 20 }}>
                            <small data-testid='helperTextIdTechnician' className="form-text" style={{ width: '86%', float: 'left', paddingLeft: '2%', marginBottom: '35px' }}>
                                {t('ORGANIZATION_MANAGE.COMMENTS_HELPER')}</small>
                            <Typography data-testid='orgCommentsLimit' style={{ width: 'auto', float: 'right', fontSize: '12px', color: PXBColors.gray[500] }}>
                                {comments.length}/{BUILDING_COMMENTS_LIMIT}
                            </Typography>
                        </div>
                        {!isOrgAddressCheckBox && <div>   <Button
                            style={{
                                marginBottom: 10,
                                width: '100%',
                                height: 36,
                                flexDirection: 'row',
                                marginRight: 10,
                                backgroundColor: PXBColors.white[50],
                            }}
                            id="cancelButton"
                            color="primary"
                            data-testid="cancelButton"
                            disabled={!isMapButtonEnable}
                            onClick={showMap}
                        >
                            {isMapButtonEnable ? t('MAP.MAP_ADDRESS_DISPLAY') : t('MAP.MAP_ENABLE_ON_COUNTRY')}
                        </Button></div>}
                        {isOpenMap && <div style={{ display: 'flex', minHeight: md ? 700 : 0, width: '100%', marginRight: 50 }}>
                            <BuildingMap buildingId={buildingId} isUpsertBuilding={!isUpsertBuildingPage} geocodePosition={updatePosition} modifiedAddress={updatedAddress} address={companyAddress} country={countryName} city={companyCity} postalCode={companyPostalCode}/>
                        </div>}
                    </div>
                    <div style={{ float: 'right', width: md ? '49%' : '' }}>
                        {isOrgAddressAvailable && <div style={{ display: 'flex', marginLeft: 'auto', marginTop: 40 }}>
                            <Checkbox id="address-checkbox"
                                onChange={handleAddressCheckbox}
                                checked={isOrgAddressCheckBox}
                            />
                            <Typography
                                id="org-address-check-lable"
                                data-testid="org-address-check-lable"
                                style={{ fontSize: '16', fontWeight: 400, alignSelf: 'center', color: PXBColors.black[500] }}>
                                {t('USER_SETTINGS.ORG_ADDRESS')}
                            </Typography>
                        </div>}
                        {!isOrgAddressAvailable && <div style={{ display: 'flex', marginLeft: 'auto', marginTop: 30 }}>
                            <Typography
                                id="org-address-check-lable"
                                data-testid="org-address-check-lable"
                                style={{ fontSize: '16', fontWeight: 400, alignSelf: 'center', color: PXBColors.black[500] }}>
                                {t('FORMS.BUILDING_ADDRESS')}
                            </Typography>
                        </div>}
                        <TextField
                            data-testid='companyAddressId'
                            style={{ marginTop: 24, float: 'right', width: '100%' }}
                            id="companyaddress"
                            variant={'filled'}
                            label={t('FORMS.BUILDING_COMPANY_ADDRESS')}
                            required
                            fullWidth
                            inputProps={{ 'data-testid': 'company-address', maxLength: COMPANY_ADDRESS_MAX_LENGTH }}
                            value={companyAddress}
                            disabled={isOrgAddressCheckBox}
                            onChange={(e): void => onCompanyAddressChange(e)}
                        />
                        <TextField
                            style={{ marginTop: 24, float: 'right', width: '100%' }}
                            id="companyaddress2"
                            data-testid='companyAddressId2'
                            variant={'filled'}
                            label={t('FORMS.BUILDING_COMPANY_ADDRESS2')}
                            fullWidth
                            inputProps={{ 'data-testid': 'company-address2', maxLength: COMPANY_ADDRESS_MAX_LENGTH }}
                            value={companyAddress2}
                            disabled={isOrgAddressCheckBox}
                            onChange={(e): void => onCompanyAddressChange2(e)}
                        />
                        <TextField
                            style={{ marginTop: 24, width: '35%', marginRight: '2%', textAlign: 'center' }}
                            id="companypostalcode"
                            data-testid='company-postal-codeID'
                            variant={'filled'}
                            label={t('FORMS.POSTAL_CODE')}
                            required
                            error={companyPostalCode.length > 0 && !validPostalCode(companyPostalCode)}
                            helperText={companyPostalCode.length > 0 && !validPostalCode(companyPostalCode) ? (t('FORMS.INVALID_POSTAL_CODE')) : ''}
                            inputProps={{ 'data-testid': 'company-postal-code', maxLength: COMPANY_POSTALCODE_MAX_LENGTH }}
                            fullWidth
                            value={companyPostalCode}
                            disabled={isOrgAddressCheckBox}
                            onChange={(e): void => onCompanyPostalCodeChange(e)}
                        />
                        <TextField
                            style={{ marginTop: 24, width: '63%', textAlign: 'center' }}
                            id="companycity"
                            data-testid='companyCityTestId'
                            variant={'filled'}
                            label={t('FORMS.CITY')}
                            required
                            inputProps={{ 'data-testid': 'company-city', maxLength: COMPANY_CITY_MAX_LENGTH }}
                            fullWidth
                            value={companyCity}
                            disabled={isOrgAddressCheckBox}
                            onChange={(e): void => onCompanyCityChange(e)}
                        />
                        <FormControl variant={'filled'} style={{ display: 'flex', marginTop: 24, marginBottom: 40 }}>
                            <InputLabel disabled={isOrgAddressCheckBox} data-testid='countryNameLabelId' id="country-name-label">{t('FORMS.COUNTRY')}</InputLabel>
                            <Select
                                labelId="country-name-label"
                                id="country-name-label"
                                fullWidth
                                disabled={isOrgAddressCheckBox}
                                inputProps={{ 'data-testid': 'country-name' }}
                                defaultValue={selectedCountry.name !== '' ? selectedCountry.name : ''}
                                value={countryName}
                                required
                                onChange={onChangeCountryName}
                            >
                                {countryList?.map((e, code) => {
                                    return (
                                        <MenuItem id="country-name-list" key={code} value={e.name}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'left', fontSize: 14,
                                                lineHeight: 1.5, fontWeight: 400,
                                                overflow: 'hidden',
                                                boxSizing: 'border-box',
                                                whiteSpace: 'nowrap',
                                                minHeight: 48,
                                                paddingTop: 6,
                                                paddingBottom: 6,
                                                width: 'auto',
                                                paddingLeft: 16,
                                                paddingRight: 16
                                            }}>
                                            {e.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        {isContactDetailsAvailable && <div style={{ display: 'flex', marginBottom: -15 }}>
                            <Checkbox id="contact-detail-check"
                                onChange={handleContactDetailCheckbox}
                                checked={isContactDetailsChecked}
                            />
                            <Typography
                                id="org-address-check-lable"
                                data-testid="org-address-check-lable"
                                style={{ fontSize: '16', fontWeight: 400, alignSelf: 'center', color: PXBColors.black[500] }}>
                                {t('USER_SETTINGS.ORG_CONTACT_DETAILS')}
                            </Typography>
                        </div>}
                        {!isContactDetailsAvailable && <div style={{ display: 'flex', marginBottom: 16 }}>
                            <Typography
                                id="org-address-check-lable"
                                data-testid="org-address-check-lable"
                                style={{ fontSize: '16', fontWeight: 400, alignSelf: 'center', color: PXBColors.black[500] }}>
                                {t('FORMS.LOCAL_PRIMARY_CONTACT_INFO')}
                            </Typography>
                        </div>}
                        <TextField
                            data-testid='companyEmailTestId'
                            style={{ marginTop: 24 }}
                            id="companyemail"
                            variant={'filled'}
                            label={t('FORMS.LOCAL_PRIMARY_EMAIL')}
                            error={email.length > 0 && !validEmail(email)}
                            helperText={email.length > 0 && !validEmail(email) ? (t('FORMS.INVALID_EMAIL_ERROR')) : ''}
                            inputProps={{ 'data-testid': 'company-email', maxLength: ORG_EMAIL_MAX_LENGTH }}
                            fullWidth
                            required
                            value={email}
                            disabled={isContactDetailsChecked}
                            onChange={(e): void => setEmail(e.target.value)}
                        />
                        <TextField
                            data-testid='companyFirstName'
                            style={{ marginTop: 24, float: 'right', width: '100%' }}
                            id="companyFirstNameId"
                            variant={'filled'}
                            label={t('FORMS.FIRST_NAME')}
                            fullWidth
                            required
                            disabled={isContactDetailsChecked}
                            inputProps={{ 'data-testid': 'companyFirstNameLabel', maxLength: FIRST_NAME_MAX_LENGTH }}
                            value={primaryFirstName}
                            onChange={(e): void => onPrimaryFirstNameChange(e)}
                        />
                        <TextField
                            data-testid='companyLastName'
                            style={{ marginTop: 24, float: 'right', width: '100%' }}
                            id="companyLastNameId"
                            variant={'filled'}
                            label={t('FORMS.LAST_NAME')}
                            fullWidth
                            required
                            disabled={isContactDetailsChecked}
                            inputProps={{ 'data-testid': 'companyLastNameLabel', maxLength: LAST_NAME_MAX_LENGTH }}
                            value={primaryLastName}
                            onChange={(e): void => onPrimaryLastNameChange(e)}

                        />

                        <div style={{ paddingBottom: '10%' }}>
                            <FormControl variant={'filled'} style={{ marginTop: 24, marginRight: '2%' }}>
                                <InputLabel data-testid='countryCodeRequiredId' id="country-name-label">{'*'}</InputLabel>
                                <Select

                                    style={{ width: md ? vw_120 : '33%', marginRight: '2%', textAlign: 'center' }}
                                    variant={'filled'}
                                    labelId="countrycode-label"
                                    inputProps={{ 'data-testid': 'country-code', maxLength: COUNTRY_CODE_MAX_LENGTH }}
                                    id="countrycode-testid"
                                    defaultValue={countryCode}
                                    required
                                    value={countryCode}
                                    disabled={isContactDetailsChecked}
                                    onChange={onChangeCountryCode}
                                >
                                    {countryList?.map((e, code) => {
                                        return (
                                            <MenuItem id="country-code-list" key={code} value={e.code}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'left', fontSize: 14,
                                                    lineHeight: 1.5, fontWeight: 400,
                                                    overflow: 'hidden',
                                                    boxSizing: 'border-box',
                                                    whiteSpace: 'nowrap',
                                                    minHeight: 48,
                                                    paddingTop: 6,
                                                    paddingBottom: 6,
                                                    width: 'auto',
                                                    paddingLeft: 16,
                                                    paddingRight: 16
                                                }}>
                                                {'+' + e.code}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            <TextField
                                data-testid='companyPhoneNumberTestId'
                                style={{ marginTop: 24, width: md ? '79%' : '65%', marginBottom: 40 }}
                                variant={'filled'}
                                label={t('FORMS.PHONE_NUMBER')}
                                inputProps={{ 'data-testid': 'phone-number', maxLength: PHONE_MAX_LENGTH, minLength: PHONE_MIN_LENGTH }}
                                fullWidth
                                required
                                value={phoneNumber}
                                error={phoneNumber.length > 0 && !validPhoneNumberLength(phoneNumber)}
                                helperText={phoneNumber.length > 0 && !validPhoneNumberLength(phoneNumber) ? (t('FORMS.INVALID_PHONE_NUMBER')) : ''}
                                disabled={isContactDetailsChecked}
                                onChange={(e): void => onChangePhoneNumber(e)}
                            />
                            {!isContactDetailsAvailable && <div style={{ display: 'flex', marginBottom: 30 }}>
                                <Typography
                                    id="org-address-check-lable"
                                    data-testid="org-address-check-lable"
                                    style={{ fontSize: '16', fontWeight: 400, alignSelf: 'center', color: PXBColors.black[500] }}>
                                    {t('FORMS.LOCAL_SECONDARY_CONTACT_INFO')}
                                </Typography>
                            </div>}
                            <TextField
                                data-testid='companySecondaryEmailTestId'
                                id="companySecondaryemail"
                                variant={'filled'}
                                disabled={isContactDetailsChecked}
                                label={t('FORMS.LOCAL_SECONDARY_EMAIL')}
                                error={secondaryEmail.length > 0 && !validEmail(secondaryEmail)}
                                helperText={secondaryEmail.length > 0 && !validEmail(secondaryEmail) ? (t('FORMS.INVALID_EMAIL_ERROR')) : ''}
                                inputProps={{ 'data-testid': 'companySecondaryemailTestId', maxLength: ORG_EMAIL_MAX_LENGTH }}
                                fullWidth
                                value={secondaryEmail}
                                onChange={(e): void => setSecondaryEmail(e.target.value)}
                            />
                            <TextField
                                data-testid='secondaryFirstName'
                                style={{ marginTop: 24, float: 'right', width: '100%' }}
                                id="secondaryFirstNameId"
                                variant={'filled'}
                                disabled={isContactDetailsChecked}
                                label={t('FORMS.FIRST_NAME')}
                                fullWidth
                                inputProps={{ 'data-testid': 'secondaryFirstNameTestId', maxLength: COMPANY_ADDRESS_MAX_LENGTH }}
                                value={secondaryFirstName}
                                onChange={(e): void => onSecondaryFirstNameChange(e)}
                            />
                            <TextField
                                data-testid='secondaryLastName'
                                style={{ marginTop: 24, float: 'right', width: '100%' }}
                                id="secondaryLastNameId"
                                variant={'filled'}
                                label={t('FORMS.LAST_NAME')}
                                disabled={isContactDetailsChecked}
                                fullWidth
                                inputProps={{ 'data-testid': 'secondaryLastNameTestId', maxLength: COMPANY_ADDRESS_MAX_LENGTH }}
                                value={secondaryLastName}
                                onChange={(e): void => onSecondaryLastNameChange(e)}

                            />
                            <div>
                                <Select
                                    style={{ marginTop: 24, width: md ? '23%' : '33%', marginRight: '2%', textAlign: 'center' }}
                                    variant={'filled'}
                                    required
                                    disabled={isContactDetailsChecked}
                                    labelId="secondaryCountryCode"
                                    inputProps={{ 'data-testid': 'secondaryCountryCodeTestId', maxLength: COUNTRY_CODE_MAX_LENGTH }}
                                    id="countrycodetestid"
                                    defaultValue={secondaryCountryCode}
                                    value={secondaryCountryCode}
                                    onChange={(e) => onChangeSecondaryCountryCode(e)}
                                >
                                    {countryList?.map((e, code) => {
                                        return (
                                            <MenuItem id="country-code-list" key={code} value={e.code}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'left', fontSize: 14,
                                                    lineHeight: 1.5, fontWeight: 400,
                                                    overflow: 'hidden',
                                                    boxSizing: 'border-box',
                                                    whiteSpace: 'nowrap',
                                                    minHeight: 48,
                                                    paddingTop: 6,
                                                    paddingBottom: 6,
                                                    width: 'auto',
                                                    paddingLeft: 16,
                                                    paddingRight: 16
                                                }}>
                                                {'+' + e.code}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>

                                <TextField
                                    data-testid='companySecondaryPhoneNumberTestId'
                                    style={{ marginTop: 24, marginBottom: 24, width: md ? '75%' : '65%' }}
                                    variant={'filled'}
                                    disabled={isContactDetailsChecked}
                                    error={secondaryPhoneNumber.length > 0 && !validPhoneNumberLength(secondaryPhoneNumber)}
                                    helperText={secondaryPhoneNumber.length > 0 && !validPhoneNumberLength(secondaryPhoneNumber) ? (t('FORMS.INVALID_PHONE_NUMBER')) : ''}
                                    label={t('FORMS.PHONE_NUMBER')}
                                    inputProps={{ 'data-testid': 'secondary-phone-number', maxLength: PHONE_MAX_LENGTH, minLength: PHONE_MIN_LENGTH }}
                                    fullWidth
                                    value={secondaryPhoneNumber}
                                    onChange={(e): void => onChangeSecondaryPhoneNumber(e)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            <Dialog id="dialog" maxWidth="md" open={openQuitDialog} onClose={handleQuitDialog}>
                <DialogTitle id="dialog-header">{t('ORGANIZATION_MANAGE.QUIT_TITLE', { replace: { orgName: buildingDetails.name } })}</DialogTitle>
                <DialogContent>
                    <DialogContentText data-testid="dialog-header-text">
                        {t('ORGANIZATION_MANAGE.QUIT_MESSAGE')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        style={{
                            marginBottom: 10,
                            width: 77,
                            height: 36,
                            flexDirection: 'row',
                            marginRight: 10,
                            backgroundColor: PXBColors.white[50],
                        }}
                        id="cancelButton"
                        color="primary"
                        data-testid="cancelButton"
                        onClick={handleCancel}
                    >
                        {t('ACTIONS.CANCEL')}
                    </Button>
                    <Button
                        style={{
                            marginBottom: 10,
                            width: 64,
                            height: 36,
                            flexDirection: 'row',
                            backgroundColor: PXBColors.white[50],
                            color: PXBColors.red[500],
                        }}
                        id="saveButton"
                        variant="text"
                        data-testid="quitButton"
                        onClick={(): void => onQuitClick()}
                    >
                        {t('ACTIONS.QUIT')}
                    </Button>

                </DialogActions>
            </Dialog>
            {
                displayErrorMessage &&
                <Snackbar
                    open={true}
                    onClose={() => setDisplayErrorMessage(false)}
                    autoHideDuration={5000}
                    title={''}
                    message={alertErrorMessage}
                    action={
                        <Button color="inherit" size="small" onClick={() => setDisplayErrorMessage(false)}>
                            {t('ACTIONS.OKAY')}
                        </Button>
                    }
                    ContentProps={{ sx: { background: PXBColors.red[500] } }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    sx={{ bottom: { xs: 90, sm: 0 } }}

                    style={{ height: 200, width: 200, flexGrow: 0 }}
                />
            }
        </div >
    );
};
