// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
import {
    Typography,
    Card,
    IconButton,
    CircularProgress,
} from '@mui/material';
import { Luminary } from '../../types/luminary';
import { useDispatch, useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import ListItem from '@mui/material/ListItem';
import { Button } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { AssetListItem } from '../../components/AssetListItem/AssetListItem';
import { ACTION_TYPE_NEXT } from '../../constants';
import { DeviceState, DEVICE_STATUS, getLuminaryStatusState } from '../enum/ENUM';
import { LuminaryInfo } from '../assets/LuminaryInfo';
import { Thunks } from '../../actions';
import { EmptyState } from '@brightlayer-ui/react-components';
import * as PXBColors from '@brightlayer-ui/colors';

const luminaryDefault: Luminary = {
    luminaryId: "",
    luminaryName: "",
    type: "",
    circuit: "",
    ATSD: 123,
    lmStatus: 1,
    lmStatusName: "",
    systemEventTime: "2022-07-11T04:03:58Z",
    systemEvent: "",
    lmAddress: 19,
    serialNumber: "",
    model: "",
    luminaryDescription: "",
    functionalTestDelay: "",
    testDuration: "",
    testDurationDescription: "",
    durationTestNext: "2022-07-01T04:03:58Z",
    durationTestPrevious: "2022-06-01T04:03:58Z",
    functionalTestNext: "2022-07-01T04:03:58Z",
    functionalTestPrevious: "2022-06-01T04:03:58Z",
    dtStatus: true,
    dtTestDescription: "",
    functionalTestStatus: true,
    functionalTestDescription: "",
    luminaryEPAS: '',
    luminaryFwVersion: 0,
    luminaryFwReleaseDate: "",
    luminaryDTDuration: 0,
    luminaryLastDT: '',
    luminaryPartNumber: '',
    luminaryManufacturingDate: '',
    luminaryBatteryEPAS: '',
    luminaryBatteryChangeDate: '',
    luminaryProtocolVersion: '',
    luminaryInstallationArea: '',
    luminaryMNMConfiguration: '',
    luminaryDurationTestOddEven: '',
    modelDescription: '',
    profileId: ''
}

type Props = {
    systemId: string;
    luminaryId: string;
    closeClick: Function;
};

export const LogbookLuminaryDetails: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {

    const { t } = useLanguageLocale();
    const dispatch = useDispatch();
    const asset = useSelector(Selectors.Dashboard.asset);
    const [luminaryDetails, setLuminaryDetails] = useState<Luminary>(luminaryDefault);
    const [showLuminaryCard, setShowLuminaryCard] = useState(false);

    useEffect(() => {
        dispatch(Thunks.Asset.getLuminaryList(props.systemId, props.luminaryId));
    }, [props.luminaryId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!asset.luminaryListLoading && asset.luminaryListSuccess) {
            if (asset.luminaryList.detail.length > 0) {
                setShowLuminaryCard(true);
                setLuminaryDetails(asset.luminaryList.detail[0])
            }
        } else if (!asset.luminaryListLoading && asset.luminaryListFail) {
            console.log('Luminary list loading failed');
            setShowLuminaryCard(false);
            setLuminaryDetails(luminaryDefault);
        }
    }, [asset.luminaryListLoading, asset.luminaryListSuccess, asset.luminaryListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    //To-do Remove 0 once the data would be correct as currenlty we have dummy data which has luminary type but no details
    const luminaryStatus = showLuminaryCard ? luminaryDetails.lmStatus : 0;
    const deviceState: DeviceState = getLuminaryStatusState(luminaryStatus);

    const handleClose = (): void => {
        props.closeClick({ luminaryId: props.luminaryId });
    };

    const handleErrorNext = (): void => {
    };

    return (
        <>{asset.luminaryListLoading && <EmptyState style={{ flex: 1, minHeight: 800, backgroundColor: PXBColors.white[50] }}
        icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}
            <div>
                {!showLuminaryCard && !asset.luminaryListLoading && <div><EmptyState style={{ height: 0, minHeight: 0 }}
                title="" icon={undefined} placeholder={undefined} /><IconButton style={{ marginLeft: 80 }} id="logbook-luminary-close-icon" data-testid="logbook-luminary-close-icon" onClick={(): void => handleClose()}>
                        <Close />
                    </IconButton>
                    <Typography style={{ paddingTop: 250, fontWeight: 400 }}>No data found</Typography>
                </div>
                }
            </div>

            {showLuminaryCard && !asset.luminaryListLoading && asset.luminaryListSuccess &&
                <Card style={{ flex: '1 1 0px', overflow: 'auto' }} data-testid="logbook-luminary-details-container">

                    <ListItem style={{
                        width: '100%',
                        marginTop: 10,
                    }}
                        secondaryAction={
                            <IconButton id="logbook-luminary-close-icon" data-testid="logbook-luminary-close-icon" style={{ top: -17, right: -5, marginLeft: "auto", justifyContent: 'start' }} onClick={(): void => handleClose()}>
                                <Close />
                            </IconButton>
                        }>

                        <div>
                            <Typography variant="body2" style={{ marginLeft: 5, fontSize: 18, fontWeight: 600 }} id="luminary-name" data-testid="luminary-name" >{luminaryDetails.luminaryName}</Typography>

                            <Button variant="text"
                                id="luminary-status-button"
                                data-testid="luminary-status-button"
                                style={{ marginTop: 5, fontSize: 14, color: '#424E54', textTransform: 'none' }}
                                startIcon={<img
                                    src={deviceState.statusImage}
                                    alt="logo"
                                    style={{ display: 'flex', alignItems: 'right' }}
                                    id="list-item-status-icon"
                                />}>
                                {t(deviceState.statusTitle)}
                            </Button>
                        </div>

                    </ListItem>

                    {luminaryDetails.lmStatus === DEVICE_STATUS.STATUS_ERROR && <div style={{ borderRadius: 5, backgroundColor: '#F9E8E8', marginLeft: 10, marginRight: 10 }}>

                        <AssetListItem
                            key={'luminary-info-system-event'}
                            subtitle={luminaryDetails.systemEvent ? luminaryDetails.systemEvent : '--'}
                            title={(luminaryDetails.systemEventTime)}
                            imageType={ACTION_TYPE_NEXT}
                            OnItemSelected={(): void =>
                                handleErrorNext()
                            }
                            divider={false}
                            titleBold={false}
                        ></AssetListItem>

                    </div>
                    }
                    <LuminaryInfo
                        luminary={luminaryDetails} />

                </Card >}
        </>
    );
};
//Todo- using API date time as of now (no formating)
// const getFormattedDateTime = (dateTime: string): string => {
//     return moment(dateTime).format('DD MMM YYYY') + ' ' + moment(dateTime).format('LT');
// };
