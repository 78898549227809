import { InitialState } from '../state';
import { AppReducer } from './app-reducer';

export const DocumentDownloadReducer: AppReducer<'DocumentDownload'> = (state = InitialState.DocumentDownload, action): typeof state => {
    switch (action.type) {
        
        case 'DocumentDownload/downloadDocument/Started':
            return {
                ...state,
                downloadDocumentLoading: true,
                downloadDocumentSuccess: false,
                downloadDocumentFail: false,
                downloadDocumentErrorMessage: '',
            };
        case 'DocumentDownload/downloadDocument/Succeeded':
            return {
                ...state,
                downloadDocumentLoading: false,
                downloadDocumentSuccess: true,
                downloadDocumentFail: false,
                downloadDocumentErrorMessage: '',
                downloadDocumentData: action.payload.data,
            };
        case 'DocumentDownload/downloadDocument/Failed':
            return {
                ...state,
                downloadDocumentLoading: false,
                downloadDocumentSuccess: false,
                downloadDocumentFail: true,
                downloadDocumentErrorMessage: action.payload.errorCode,
            };
        case 'DocumentDownload/downloadDocument/Unmounted':
            return {
                ...state,
                downloadDocumentLoading: false,
                downloadDocumentSuccess: false,
                downloadDocumentFail: false,
                downloadDocumentErrorMessage: '',
            };

        default:
            return state;
    }
};
