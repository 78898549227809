// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { Avatar, Card, CircularProgress, Divider, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import * as PXBColors from '@brightlayer-ui/colors';
import { EmptyState } from '@brightlayer-ui/react-components';
import { ChevronRight } from '@mui/icons-material';
import { COLOR_BLUE_ICON, COLOR_GRAY_ICON } from '../../constants/color-codes';
import { useDispatch } from 'react-redux';
import { DeviceCustomerDetail } from '../../types/customer-detail';

type Props = {
    deviceCustomerData: DeviceCustomerDetail
    isLoadingstate: boolean
};

export const MaintenanceCustomerCard: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const dispatch = useDispatch();
    const deviceCustomerData: DeviceCustomerDetail = props.deviceCustomerData

    const getInitials = (firstName: string, lastName: string) => {
        const firstInitial = firstName ? firstName.charAt(0) : '';
        const lastInitial = lastName ? lastName.charAt(0) : '';
        return `${firstInitial}${lastInitial}`;
    };

    return (
        <>
            <Card style={{ height: 'fit-content', flex: '1 1 0px', overflow: 'auto' }}>
                <div>
                    <Typography data-testid="customer-contact" style={{ fontWeight: 600, fontSize: 14, lineHeight: 3.5, fontStyle: 'normal', color: COLOR_BLUE_ICON, marginLeft: 16 }}  >
                        {t('MAINTENANCE_DETAILS.CUSTOMER_CONTACT')}
                    </Typography>
                </div>
                <Divider />
                {props.isLoadingstate ? <EmptyState style={{ flex: 1, minHeight: 200, backgroundColor: PXBColors.white[50], }}
                    icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} /> :
                    <>
                        <ListItem alignItems="flex-start">
                            <ListItemAvatar style={{ display: 'flex', paddingRight: 16, justifyContent: 'center', alignItems: 'center', alignSelf: 'stretch' }}>
                                <Avatar>{getInitials(deviceCustomerData.primaryFirstName, deviceCustomerData.primaryLastName)}</Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={deviceCustomerData.primaryFirstName + " " + deviceCustomerData.primaryLastName}
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            data-testid='maintainence-primaryEmailId'
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            {deviceCustomerData.primaryEmail}
                                        </Typography>
                                        <Typography>{t('ORGANIZATION_DETAILS.PRIMARY_CONTACT')}</Typography>
                                    </React.Fragment>
                                }
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    width: 'auto',
                                    marginTop: '5.5%'
                                }}
                            >
                                <ChevronRight style={{ color: COLOR_GRAY_ICON, marginRight: '5%' }} />
                            </div>
                        </ListItem>
                        <Divider />
                        <ListItem alignItems="flex-start">
                            <ListItemAvatar style={{ display: 'flex', paddingRight: 16, justifyContent: 'center', alignItems: 'center', alignSelf: 'stretch' }}>
                                <Avatar> {getInitials(deviceCustomerData.secondaryFirstName, deviceCustomerData.secondaryLastName)}</Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={deviceCustomerData.secondaryFirstName + " " + deviceCustomerData.secondaryLastName}
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            data-testid='maintainence-secondaryEmailId'
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            {deviceCustomerData.secondaryEmail}
                                        </Typography>
                                        <Typography>{t('ORGANIZATION_DETAILS.SECONDARY_CONTACT')}</Typography>
                                    </React.Fragment>
                                }
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    width: 'auto',
                                    marginTop: '5.5%'
                                }}
                            >
                                <ChevronRight style={{ color: COLOR_GRAY_ICON, marginRight: '5%' }} />
                            </div>
                        </ListItem>
                    </>
                }
            </Card>
        </>
    );
};