// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import {
    Typography,
    Card,
    Divider,
    CardContent,
    Button,
    CircularProgress,
    Dialog,
    MobileStepper,
    Snackbar,
} from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { ACTION_TYPE_COPY, ACTION_TYPE_NEXT, ACTION_TYPE_NONE } from '../../constants';
import * as PXBColors from '@brightlayer-ui/colors';
import { AssetListItem } from '../../components/AssetListItem/AssetListItem';
import { pushAppRoute } from '../../data/domain/route-manager';
import { useHistory, useParams } from 'react-router';
import { Selectors } from '../../selectors';
import { useDispatch, useSelector } from 'react-redux';
import { DeviceCustomerDetail } from '../../types/customer-detail';
import { customerData } from '../../state/assets-state';
import { Thunks } from '../../actions';
import { EmptyState } from '@brightlayer-ui/react-components';
import { LinkCustomerDialog } from './LinkCustomerDialog';
import { LinkCustomerDialogSecondPage } from './LinkCustomerDialogSecondPage';
import { CustomerThunks } from '../../actions/thunks/customer-thunks';
import { getUserRoleType, useAuthHelper, useAuthState } from '../../components/AuthContextProvider';
import { CustomerActions } from '../../actions/actions/customer-actions';
import { USER_ROLE_TYPE } from '../enum/ENUM';
import { AssetActions } from '../../actions/actions/asset-actions';

type Props = {
    projectId: string;
    deviceId: string;
    openDialogForCustomerLink: boolean;
    customerId: string;
    closeDialogClick: Function;
};
function copyToClipboard(valueToCopy: string) {
    const tempInput = document.createElement('input')
    tempInput.value = valueToCopy
    document.body.appendChild(tempInput)
    tempInput.select()
    document.execCommand('copy')
    document.body.removeChild(tempInput)
}

export const DeviceCustomerInfoCard: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const dispatch = useDispatch();
    // @ts-ignore
    const { projectId } = useParams();
    const [deviceCustomerData, setDeviceCustomerData] = useState<DeviceCustomerDetail>(customerData);
    const [openOrgSelectDialog, setOpenOrgSelectDialog] = React.useState(false);
    const [selectLumMonitoring, setSelectLumMonitoring] = React.useState(false);
    const [alertErrorMessage, setAlertErrorMessage] = useState<string>('');
    const [alertSuccessMessage, setAlertSuccessMessage] = useState<string>('');
    const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false);
    const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
    const [calendarSelected, setCalendarSelected] = React.useState(true);
    const [nextLabel, setNextLabel] = useState<string>(t('ACTIONS.NEXT'));
    const [activeStep, setActiveStep] = React.useState(0);
    const [customerNumber, setCustomerNumber] = React.useState('');
    const [slaNumber, setSlaNumber] = useState('');
    const [slaExpiryDate, setSlaExpiryDate] = React.useState('');
    const [openLinkCustomerDialog, setOpenLinkCustomerDialog] = React.useState(false);
    const backLabel = t('ACTIONS.BACK');
    const asset = useSelector(Selectors.Asset.assets);
    const history = useHistory();
    const inviteAdvanceUserFirstPage1 = 0;
    const inviteAdvanceUserSecondPage2 = 1;
    const customer = useSelector(Selectors.Customer.customer);
    var disabled = (activeStep === inviteAdvanceUserFirstPage1) ? !customerNumber : !slaNumber || calendarSelected;
    const authState = useAuthState();
    const usertype = getUserRoleType(authState) || '';

    const authHelper = useAuthHelper();
    const NavigateToCustomerDetails = (): void => {
        pushAppRoute(
            history,
            { type: 'CustomerDetails' },
            { backRoute: 'AssetDetails', deviceId: props.deviceId, projectId: props.projectId }

        );
    };
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSlaNumber('');
        setSlaExpiryDate('');
    };

    const handleSave = () => {
        dispatch(CustomerThunks.linkCustomer(customerNumber, props.deviceId, slaNumber, slaExpiryDate, selectLumMonitoring, authHelper));
    };


    const handleLinkCustomerResponse = (): void => {
        if (!customer.linkCustomerLoading && customer.linkCustomerFail) {
            if (customer.linkCustomerErrorCode === '9162')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_LINK_CUSTOMER_ERROR_INVALID_SLA'));
            else if (customer.linkCustomerErrorCode === '9169')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_LINK_CUSTOMER_ERROR_INVALID_EXPIRY_DATE'));
            else if (customer.linkCustomerErrorCode === '9001')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_LINK_CUSTOMER_ERROR_INVAILD_TOKEN'));
            else if (customer.linkCustomerErrorCode === '9004')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_LINK_CUSTOMER_SYSTEM_DETAIL_NOT_AVAILABLE'));
            else if (customer.linkCustomerErrorCode === '9050')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_LINK_CUSTOMER_INVALID_SYSTEM_ID'));
            else if (customer.linkCustomerErrorCode === '9172')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_LINK_CUSTOMER_INVALID_CUSTOMER_NUMBER'));
            else if (customer.linkCustomerErrorCode === '9186')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_LINK_CUSTOMER_CUSTOMER_DETAIL_NOT_AVAILABLE'));
            else if (customer.linkCustomerErrorCode === '9171')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_LINK_CUSTOMER_SLA_ALREADY_AVAILABLE'));
            else
                setAlertErrorMessage(t('REGISTRATION.STEPS_ERROR_MESSAGE.GENERIC_ERROR_MESSAGE'));
        }

    };

    useEffect(() => {
        setOpenOrgSelectDialog(props.openDialogForCustomerLink);
    }, [props.openDialogForCustomerLink]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!customer.linkCustomerLoading && customer.linkCustomerSuccess) {
            setAlertSuccessMessage(t('TOAST_MSG.TOAST_SUCCESS_LINK_CUSTOMER_DIALOG'));
            setOpenOrgSelectDialog(false);
            setDisplaySuccessMessage(true);
            dispatch(Thunks.Asset.getDeviceCustomerData(props.deviceId));
        } else if (!customer.linkCustomerLoading && customer.linkCustomerFail) {
            handleLinkCustomerResponse();
            setDisplayErrorMessage(true);
            setOpenOrgSelectDialog(false);

        }
        dispatch(CustomerActions.linkCustomerUnmounted());
    }, [customer.linkCustomerLoading, customer.linkCustomerSuccess, customer.linkCustomerFail]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleBack = () => {
        setCustomerNumber('');
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setSlaNumber('');
    };

    useEffect(() => {
        if (!asset.deviceCustomerDataLoading && asset.deviceCustomerDataSuccess) {
            setOpenLinkCustomerDialog(true);
            setDeviceCustomerData(asset.deviceCustomerData);
            dispatch(AssetActions.updateCustomerUnmounted());
        } else if (!asset.deviceCustomerDataLoading && asset.deviceCustomerDataFail) {
            setOpenLinkCustomerDialog(false);
        }
        dispatch(AssetActions.deviceCustomerDataUnmounted());
    }, [asset.deviceCustomerDataLoading, asset.deviceCustomerDataSuccess, asset.deviceCustomerDataFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setNextLabel(activeStep === inviteAdvanceUserFirstPage1 ? t('ACTIONS.NEXT') : t('ACTIONS.LINK_SYSTEM'));
    }, [activeStep]); // eslint-disable-line react-hooks/exhaustive-deps

    const renderStepComponent = (step: number) => {
        switch (step) {
            case 0:
                return (
                    <LinkCustomerDialog customerNameUpdate={(customerNumber: string): void => { setCustomerNumber(customerNumber) }} customerId={props.customerId} deviceId={props.deviceId} projectId={projectId} openDialogFlag={openOrgSelectDialog} closeClick={() => { setCustomerNumber(''); setOpenOrgSelectDialog(false); props.closeDialogClick({}); }} ></LinkCustomerDialog>
                )
            case 1:
                return (
                    <LinkCustomerDialogSecondPage onSlaDetailsUpdate=
                        {(slaNumber: string, slaExpiryDate: string, selectLumMonitoring: boolean, slaExpiryDateFlag: boolean) => {
                            setSlaNumber(slaNumber); setSlaExpiryDate(slaExpiryDate); setSelectLumMonitoring(selectLumMonitoring); setCalendarSelected(slaExpiryDateFlag);
                        }} deviceId={props.deviceId} projectId={projectId} openDialogFlag={openOrgSelectDialog} closeClick={() => { setOpenOrgSelectDialog(false); setActiveStep(inviteAdvanceUserFirstPage1); props.closeDialogClick({}); setCustomerNumber(''); }} ></LinkCustomerDialogSecondPage>
                )
            default:
                return null;
        }
    };

    return (
        <>
            {asset.deviceCustomerDataLoading && <EmptyState style={{ flex: 1, maxHeight: 500, backgroundColor: PXBColors.white[50] }}
                icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}
            {openLinkCustomerDialog && <Card style={{ flex: '1 1 0px', overflow: 'auto' }} data-testid="device-customerInfo-card">
                <CardContent style={{ flex: '1 1 0px', overflow: 'auto' }}>
                    <Typography
                        variant="body2"
                        style={{
                            alignItems: 'left',
                            fontSize: 14,
                            fontWeight: 600,
                            color: PXBColors.blue[400],
                            width: '92.3%',
                        }}
                        id="customer-info-titleId"
                        data-testid="customer-info-title-data-testId"
                    >{t('ASSETS_DETAILS.CUSTOMER_INFO_HEADER')}</Typography>

                </CardContent>

                <Divider data-testid='dividerId1' style={{ borderColor: PXBColors.gray[50] }} />
                <AssetListItem
                    key={'company-detail-id'}
                    data-testid='company-detail-data-testid'
                    subtitle={t('REGISTRATION.STEPS.COMPANY_DETAILS')}
                    title={deviceCustomerData.companyName}
                    imageType={ACTION_TYPE_NEXT}
                    OnItemSelected={(): void => NavigateToCustomerDetails()}
                    divider={true}
                    titleBold={true}
                ></AssetListItem>
                <AssetListItem
                    key={'customer-sla-id'}
                    data-testid='customer-sla-data-testid'
                    title={deviceCustomerData?.sLANumber ? deviceCustomerData.sLANumber : '--'}
                    subtitle={t('ASSETS_DETAILS.SLA_SUBTITLE')}
                    imageType={ACTION_TYPE_NONE}
                    divider={true}
                    titleBold={true}
                ></AssetListItem>
                <AssetListItem
                    key={'customer-sla-id'}
                    data-testid='customer-sla-data-testid'
                    title={deviceCustomerData?.expiryDate ? deviceCustomerData.expiryDate : '--'}
                    subtitle={t('ASSETS_DETAILS.EXPIRY_DATE')}
                    imageType={ACTION_TYPE_NONE}
                    divider={true}
                    titleBold={true}
                ></AssetListItem>

                <AssetListItem
                    data-testid='customer-detail-contactName'
                    key={'customer-detail-contactName-data-testid'}
                    title={deviceCustomerData.primaryFirstName + ' ' + deviceCustomerData.primaryLastName}
                    subtitle={t('ASSETS_DETAILS.MAIN_CONTACT')}
                    imageType={ACTION_TYPE_NONE}
                    OnItemSelected={(): void => { }}
                    divider={false}
                    titleBold={true}
                ></AssetListItem>

                <AssetListItem
                    data-testid='customerInfoPhoneId'
                    key={'customerPhone'}
                    subtitle={t('DASHBOARD.BUILDING_DETAIL_PHONE')}
                    title={'+' + (deviceCustomerData.primaryCountryCode ? deviceCustomerData.primaryCountryCode : '') + ' ' + (deviceCustomerData.primaryPhone ? deviceCustomerData.primaryPhone : '')}
                    imageType={ACTION_TYPE_NONE}
                    divider={false}
                    titleBold={true}
                ></AssetListItem>

                <AssetListItem
                    data-testid='gateway-detail-name'
                    key={'gateway-detail-name'}
                    title={deviceCustomerData.primaryEmail}
                    subtitle={t('DASHBOARD.BUILDING_DETAIL_EMAIL')}
                    imageType={ACTION_TYPE_COPY}
                    OnItemSelected={(): void => copyToClipboard(deviceCustomerData.primaryEmail)}
                    divider={true}
                    titleBold={true}
                ></AssetListItem>
            </Card >}
            {!openLinkCustomerDialog && <Card style={{ flex: '1 1 0px', overflow: 'auto', height: 'fit-content' }}>
                <CardContent style={{ flex: '1 1 0px', overflow: 'auto', padding: 0 }}>
                    <Typography
                        variant="body2"
                        style={{
                            alignItems: 'left',
                            fontSize: 14,
                            fontWeight: 600,
                            color: PXBColors.blue[500],
                            width: '92.3%',
                            paddingLeft: 16,
                            paddingTop: 15,
                            paddingBottom: 16,

                        }}
                        id="customer-info-titleId"
                        data-testid="customer-info-title-data-testId"
                    >{t('ASSETS_DETAILS.CUSTOMER_INFO_HEADER')}</Typography>
                    <Divider data-testid='dividerId1' />
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', padding: 16 }}>
                        <Typography style={{ display: 'flex', width: 197, height: 75, justifyContent: 'center', fontWeight: 600, fontSize: 14 }}>{t('ASSETS_DETAILS.NO_CUSTOMER_TEXT')}</Typography>
                        {(usertype === USER_ROLE_TYPE.SERVICE_MANAGER) &&
                            <Button
                                id="button-link-to-customer"
                                data-testid="button-link-to-customer"
                                style={{
                                    flexDirection: 'row',
                                    backgroundColor: PXBColors.blue[500],
                                    color: PXBColors.white[50],
                                    marginTop: '2%',
                                    textTransform: 'none'
                                }}
                                onClick={() => { setOpenOrgSelectDialog(true) }}
                            >
                                + {t('ASSETS_DETAILS.LINK_TO_CUSTOMER_BUTTON')}
                            </Button>
                        }
                    </div>
                </CardContent>
            </Card>}
            <Dialog id="invite-user-dialog" data-testid="invite-user-dialog"
                maxWidth="md" open={openOrgSelectDialog}
                sx={{ maxHeight: 900, overflow: 'hidden' }}
            >
                {renderStepComponent(activeStep)}

                <Divider />
                <MobileStepper
                    variant="dots"
                    steps={2}
                    position="static"
                    activeStep={activeStep}
                    sx={{ maxWidth: "auto", backgroundColor: PXBColors.white[50], flexGrow: 1, height: 84, padding: 3 }}
                    nextButton={
                        <Button
                            style={{
                                minWidth: 120, backgroundColor: disabled ? PXBColors.blue[50] : PXBColors.blue[500],
                                color: disabled ? PXBColors.blue[200] : PXBColors.white[50], textTransform: 'none',
                                padding: 16,
                                paddingTop: 6,
                                paddingBottom: 6,
                                borderRadius: 4,
                            }}
                            startIcon=''
                            disabled={(activeStep === inviteAdvanceUserFirstPage1) ? !customerNumber : !slaNumber || calendarSelected}
                            variant={'contained'}
                            color={'primary'}
                            onClick={activeStep === inviteAdvanceUserSecondPage2 ? handleSave : handleNext}
                            data-testid="invite-user-advance-next-button"
                        >
                            {nextLabel}
                        </Button>
                    }
                    backButton={
                        <Button
                            style={{
                                minWidth: 120,
                                visibility: activeStep === inviteAdvanceUserFirstPage1 ? 'hidden' : 'visible',
                                textTransform: 'none',
                                border: '1px solid  #727E84',
                                borderColor: PXBColors.blue[500],
                                borderRadius: 4,
                            }}
                            variant="outlined"
                            color={'primary'}
                            onClick={handleBack}
                            data-testid="invite-user-advance-back-button"
                        >
                            {backLabel}
                        </Button>
                    }
                />
            </Dialog >
            {
                displaySuccessMessage &&
                <Snackbar
                    open={true}
                    onClose={() => setDisplaySuccessMessage(false)}
                    autoHideDuration={5000}
                    title={''}
                    message={alertSuccessMessage}
                    action={
                        <Button color="inherit" size="small" onClick={() => setDisplaySuccessMessage(false)}>
                            {t('ACTIONS.OKAY')}
                        </Button>
                    }
                    ContentProps={{ sx: { background: PXBColors.black[900] } }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    sx={{ bottom: { xs: 90, sm: 0 } }}
                    style={{ height: 200, width: 200, flexGrow: 0 }}
                />
            }

            {
                displayErrorMessage &&
                <Snackbar
                    open={true}
                    onClose={() => setDisplayErrorMessage(false)}
                    autoHideDuration={5000}
                    title={''}
                    message={alertErrorMessage}
                    action={
                        <Button color="inherit" size="small" onClick={() => setDisplayErrorMessage(false)}>
                            {t('ACTIONS.RETRY')}
                        </Button>
                    }
                    ContentProps={{ sx: { background: PXBColors.red[500] } }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    sx={{ bottom: { xs: 90, sm: 0 } }}

                    style={{ height: 200, width: 200, flexGrow: 0 }}
                />
            }

        </>
    );
};

