// eslint-disable-next-line
import React, { useEffect } from 'react';
import {
    Typography,
    Card,
    Divider,
    CardContent,
    CircularProgress,
} from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { ACTION_TYPE_COPY, ACTION_TYPE_NEXT } from '../../constants';
import { GatewayDetailsData, GatewayDetailsList } from '../../types/gateway';
import { useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import * as PXBColors from '@brightlayer-ui/colors';
import { AssetListItem } from '../../components/AssetListItem/AssetListItem';
import { EmptyState } from '@brightlayer-ui/react-components';
import { DeviceData } from '../../types/device';
type Props = {
    onGatewaySelect: Function;
    onSelect?: boolean;
    deviceId: string;
};
export const DeviceNetworkProfileCard: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const asset = useSelector(Selectors.Dashboard.asset);
    const [gatewayDetail, setGatewayDetailState] = React.useState<GatewayDetailsData>(gatewayListDefault[0]);
    const [systemDetail, setSystemDetailState] = React.useState<DeviceData>(systemDetailDefault);

    function copyToClipboard(valueToCopy: string) {
        const tempInput = document.createElement('input')
        tempInput.value = valueToCopy
        document.body.appendChild(tempInput)
        tempInput.select()
        document.execCommand('copy')
        document.body.removeChild(tempInput)
    }

    useEffect(() => {
        if (!asset.gatewayDetailsListLoading && asset.gatewayDetailsListSuccess) {
            setGatewayDetailState(asset.gatewayDetailsList.detail.length > 0 ? asset.gatewayDetailsList.detail[0] : gatewayListDefault[0]);
        } else if (!asset.gatewayDetailsListLoading && asset.gatewayDetailsListFail) {
            console.log('Device list loading failed');
        }
    }, [asset.gatewayDetailsListLoading, asset.gatewayDetailsListSuccess, asset.gatewayDetailsListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!asset.deviceListLoading && asset.deviceListSuccess) {
            setSystemDetailState(asset.deviceList.detail.filter((device) => device.systemId === props.deviceId)[0]);
        } else if (!asset.deviceListLoading && asset.deviceListFail) {
            console.log('Device list loading failed');
        }
    }, [asset.deviceListLoading, asset.deviceListSuccess, asset.deviceListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    const onGatewaySelect = (gatewayId: string): void => {
        props.onGatewaySelect({ gatewayId });
    };

    return (
        <Card style={{ flex: '1 1 0px', overflow: 'auto' }} data-testid="device-network-profile-card-container">

            {asset.gatewayDetailsListLoading && <EmptyState style={{ flex: 1, maxHeight: 300, backgroundColor: PXBColors.white[50] }}
            icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}

            {!asset.gatewayDetailsListLoading && <>
                <CardContent style={{ flex: '1 1 0px', overflow: 'auto' }}>
                    <Typography
                        variant="body2"
                        style={{
                            alignItems: 'left',
                            fontSize: 14,
                            fontWeight: 600,
                            color: PXBColors.blue[400],
                            width: '92.3%',
                            paddingLeft: '10',
                        }}
                        id="network-title"
                        data-testid="network-title"
                    >{t('DEVICE_DETAILS.NETWORK_DETAILS')}</Typography>

                </CardContent>

                <Divider style={{ border: '0px solid #424E54' }} />

                <AssetListItem
                    key={'gateway-detail-mac-address'}
                    data-testid='gateway-detail-mac-address'
                    subtitle={t('DEVICE_DETAILS.MAC_ADDRESS')}
                    title={systemDetail.macAddress ? systemDetail.macAddress : '--'}
                    imageType={ACTION_TYPE_COPY}
                    OnItemSelected={(): void => copyToClipboard(systemDetail.macAddress ? systemDetail.macAddress : '')}
                    divider={true}
                    titleBold={true}
                ></AssetListItem>
                <AssetListItem
                    key={'gateway-detail-ip-address'}
                    data-testid='gateway-detail-ip-address'
                    title={systemDetail.ip ? systemDetail.ip : '--'}
                    subtitle={t('DEVICE_DETAILS.IPADDRESS')}
                    imageType={ACTION_TYPE_COPY}
                    OnItemSelected={(): void => copyToClipboard(systemDetail.ip ? systemDetail.ip : '')}
                    divider={true}
                    titleBold={true}
                ></AssetListItem>

                <AssetListItem
                    data-testid='gateway-detail-subnet-mask'
                    key={'gateway-detail-subnet-mask'}
                    title={systemDetail.subnet ? systemDetail.subnet : '--'}
                    subtitle={t('DEVICE_DETAILS.SUBNET_MASK')}
                    imageType={ACTION_TYPE_COPY}
                    OnItemSelected={(): void => copyToClipboard(systemDetail.subnet ? systemDetail.subnet : '')}
                    divider={true}
                    titleBold={true}
                ></AssetListItem>

                <AssetListItem
                    data-testid='gateway-detail-address'
                    key={'gateway-detail-address'}
                    subtitle={t('DEVICE_DETAILS.GATEWAY_ADDRESS')}
                    title={(gatewayDetail.serialNumber ? gatewayDetail.serialNumber : '--')}
                    imageType={ACTION_TYPE_COPY}
                    OnItemSelected={(): void => copyToClipboard(gatewayDetail.serialNumber ? gatewayDetail.serialNumber : '')}
                    divider={true}
                    titleBold={true}
                ></AssetListItem>

                <AssetListItem
                    data-testid='gateway-detail-name'
                    key={'gateway-detail-name'}
                    title={gatewayDetail.gateWayName}
                    subtitle={t('DEVICE_DETAILS.GATEWAY_NAME')}
                    imageType={ACTION_TYPE_NEXT}
                    OnItemSelected={(): void => onGatewaySelect(gatewayDetail.gateWayId)}
                    onSelect={props?.onSelect}
                    divider={true}
                    titleBold={true}
                ></AssetListItem>
            </>}
        </Card >
    );
};

const gatewayListDefault: GatewayDetailsList = [
    {
        projectId: "",
        projectName: "",
        buildingId: "",
        buildingName: "",
        gateWayId: "",
        gateWayName: "Loading",
        firmwareVersion: "",
        connectionType: 1,
        controllerType: 1,
        partNumber: "",
        epasNumber: "",
        ipAddress: "",
        subNetMask: "",
        serialNumber: "",
        macAddress: "",
        hostName: "",
        gatewayStatus: 5,
        gatewayStatusName: "",
        systemCount: 1,
        isFavourite: true,
        connectedSystems: [],
        newFirmwareVersion: ""
    }];

const systemDetailDefault = {
    systemId: "",
    systemName: "Loading",
    range: "",
    type: 1,
    firmwareVersion: "",
    partNumber: "",
    epasNumber: "",
    controlCommands: "",
    dateCommissioned: "2022-06-01T04:03:58Z",
    systemDescription: "",
    subLocation: "",
    testDuration: "",
    durationTestPrevious: "2022-06-01T04:03:58Z",
    durationTestNext: "2022-07-01T04:03:58Z",
    functionalTestNext: "2022-07-01T04:03:58Z",
    functionalTestPrevious: "2022-06-01T04:03:58Z",
    durationTestStatus: true,
    durationTestDescription: "",
    functionalTestStatus: true,
    functionalTestDescription: "",
    macAddress: "",
    ip: "",
    subnet: "",
    systemStatus: 5,
    gateWayId: "",
    gateWayName: "",
    gateWayIpAddress: "",
    gateWayHostName: "",
    projectId: "",
    projectName: "",
    buildingName: "",
    buildingId: "",
    luminaryCount: 3,
    isFavourite: true,
    isAdmin: false,
    errorCount: 0,
    dtStatus: false,
    isNotLinkedToGateway: false,
    entityType: 0,
    DGS_Ready: false,
    DGS_Warning: false,
    DGS_Error: false,
    DGS_Blocked: false,
    DGS_Offline: false,
    System_DT: false,
    StatusUpdateDateTimeUTC: "",
    activationStatus: 0,
    gatewayConnectionStatus: 0,
    configurationAlertSettings: 0,
    configurationFaultThresholdOtherAlerts: 0,
    noOfLuminairesBatteryFailure: 0,
    noOfLuminairesLampFailure: 0,
    noOfLuminairesCommunicationFailure: 0,
    noOfLuminairesEmergency: 0,
    noOfLuminairesTest: 0,
    noOfLumianiresRest: 0,
    noOfLuminairesTotalFailure: 0,
    noOfLuminairesReady: 0,
    IsChanged: false,
    DurationTestStatusName: "",
    FunctionalTestStatusName: "",
    LatestStatusUpdate: "",
    SLANumber: "",
    TotalNumberOfLuminaires: "",
    FirmwareReleaseDate: "",
    ConnectionString: "",
    SerialNumber: "",
    ModelName: "",
    ManufacturingDate: "",
    ConfigurationDryContactInput: "",
    ConfigurationDryContactOutput: "",
    StatusDryContactInput: "",
    StatusDryContactOutput: "",
    newFirmwareVersion: ""
};
