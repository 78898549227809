// eslint-disable-next-line
import React from "react";
import Button from "@mui/material/Button";
import ImageUploading, { ErrorsType, ImageListType } from "react-images-uploading";
import * as PXBColors from '@brightlayer-ui/colors';
import { AccountCircle, Delete, FileUpload } from "@mui/icons-material";
import { useLanguageLocale } from "../../hooks/language-locale-hooks";
import { Alert, Typography } from "@mui/material";
import { COLOR_GRAY_ICON } from "../../constants/color-codes";

type Props = {
    image: string | null | undefined;
    onRemoveExistingImage: () => void;
    onImageUploadLocal: (image: string | '' | undefined, imageString: string | undefined) => void;
};

export const CustomerLogo: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const [images, setImages] = React.useState([]);
    const [error, setError] = React.useState(false);
    const { t } = useLanguageLocale();
    const customerImage = props.image !== '' ? "data:image/jpeg;base64," + props.image : ''

    const onChange = (
        imageList: ImageListType,
        addUpdateIndex: number[] | undefined,
    ) => {
        props.onRemoveExistingImage();
        var imageString = imageList.length > 0 ? imageList[0].dataURL : '';
        var chars = imageString ? imageString.split(',')[1] : '';
        props.onImageUploadLocal(chars, imageString);

        setError(false);
        setImages(imageList as never[]);
    };

    return (
        <div data-testid="customer-logo-id">
            <ImageUploading
                multiple={false}
                value={images}
                onChange={onChange}
                maxNumber={2}
                acceptType={['jpg', 'png']}
                //size is in BYTES
                maxFileSize={2000000}
                resolutionHeight={100}
                resolutionWidth={100}
                resolutionType="less"
            >
                {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    errors,
                    dragProps
                }) => (
                    <div >
                        {
                            imageList.length === 0 && (props.image !== '' ?
                                <div key={'index'} style={{ display: 'flex', justifyContent: 'center', padding: 16 }}>
                                    <img style={{ display: 'flex', width: 100, height: 100 }} src={customerImage} alt="" />
                                </div> : <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 10, paddingBottom: 10 }}>
                                    <AccountCircle style={{ display: 'flex', width: 96, height: 96, justifyContent: 'center', alignItems: 'center', flexShrink: 0, color: COLOR_GRAY_ICON }} />
                                </div>)
                        }
                        {(imageList.length > 0) && imageList.map((image, index) => (
                            <div key={index} style={{ display: 'flex', justifyContent: 'center', padding: 16 }}>
                                <img style={{ display: 'flex', width: 100, height: 100 }} src={image.dataURL} alt="" />
                            </div>
                        ))}
                        <Typography data-testid='imageRequirementsId' style={{ display: 'flex', justifyContent: 'center', paddingBottom: 16 }}>{t('CUSTOMER_DETAILS.IMAGE_REQUIREMENTS')}</Typography>
                        <div data-testid='file-upload-button' style={{ display: 'flex', justifyContent: 'center', paddingBottom: 16 }}>
                            <Button data-testid='imageUpload' onClick={onImageUpload}
                                {...dragProps} variant="contained"
                                style={{
                                    display: 'flex',
                                    height: 36,
                                    width: 187,
                                    paddingRight: 16,
                                    paddingLeft: 12,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: PXBColors.white[50],
                                    color: PXBColors.blue[500]
                                }}
                                startIcon={<FileUpload />}>
                                {t('CUSTOMER_DETAILS.UPLOAD_AVATAR_LOGO')}

                            </Button>

                        </div>
                        <div data-testid='image-remove-button' style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button data-testid='imageRemove' onClick={
                                (imageList.length === 0) ?
                                    props.onRemoveExistingImage
                                    : onImageRemoveAll
                            } variant="text"
                            disabled={(imageList.length === 0)}
                                style={{
                                    display: 'flex',
                                    height: 36,
                                    width: 187,
                                    paddingRight: 16,
                                    paddingLeft: 12,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: PXBColors.white[50],
                                    color: PXBColors.gray[500]
                                }}
                                startIcon={<Delete />}>
                                {t('CUSTOMER_DETAILS.REMOVE_IMAGE')}
                            </Button>
                        </div>
                        {
                            errors && <div>
                                {errors.resolution && <Alert data-testid='alert' severity="error">{t('CUSTOMER_DETAILS.FILE_RESOLUTION_ERROR')}</Alert>}
                                {errors.maxFileSize && <Alert data-testid='alert' severity="error">{t('CUSTOMER_DETAILS.FILE_SIZE_ERROR')}</Alert>}
                            </div>
                        }
                    </div>
                )}
            </ImageUploading>
        </div>

    );
}
