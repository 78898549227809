// eslint-disable-next-line
import React, { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Material-UI components
import ButtonLink from '@mui/material/Link';
import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    Typography,
    TextField,
    IconButton,
    InputAdornment,
    CircularProgress,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { BrandedCardContainer } from '../../components/BrandedCardContainer/BrandedCardContainer';

// Constants
import * as PXBColors from '@brightlayer-ui/colors';
import BrandLogo from '../../assets/images/eaton_logo.svg';
import ProjectName from '../../assets/images/Login_Panel_EL_Digital.svg';
import BackgroundImage from '../../assets/images/Login_Background.png';
import cyberCertifyLogo from '../../assets/images/cybersecurity_certified.svg';
import {
    useAuthHelper,
} from '../../components/AuthContextProvider';
import { Thunks } from '../../actions';
import { Selectors } from '../../selectors';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { EmptyState } from '@brightlayer-ui/react-components';
import { validEmail } from '../../lib/form-validator';
import { useTheme } from '@mui/material/styles';
import { LOGIN_PLATFORM } from '../../constants';


type Props = {
    email: string;
    changeNextEnabled: Function;
    singUpClick: Function;
};

const RegisterLogin: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    // const classes = useStyles();
    const theme = useTheme();
    const { t } = useLanguageLocale();
    const authHelper = useAuthHelper();
    const dispatch = useDispatch();
    const inActiveLogoutMessage = useSelector(Selectors.Dashboard.inActiveLogOut);
    const loginState = useSelector(Selectors.Authentication.login);
    const [inActiveLogout, setInactiveLogOut] = useState(inActiveLogoutMessage);
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState<string>(props.email);
    const [password, setPassword] = useState('');
    const [remember, setRemember] = useState(false);
    const canLogIn = (): boolean => Boolean(validEmail(email) && password.length > 0);
    const onLogIn = (): void => {
        setInactiveLogOut(false);
        dispatch(Thunks.Authentication.login(email, password, remember, authHelper.onUserAuthenticated, LOGIN_PLATFORM,''));
    };
    const handleClickShowPassword = (): void => {
        setShowPassword(!showPassword);
    };

    const goToForgetPassword = (): void => {
        if (props.changeNextEnabled) {
            props.changeNextEnabled(true);
        }
    };

    const goToSignUp = (): void => {
        if (props.singUpClick) {
            props.singUpClick();
        }
    };

    return (
        <>
            <BrandedCardContainer>
                <>
                    {loginState.loading && <EmptyState style={{ flex: 1, backgroundColor: PXBColors.white[50] }}
                        icon={<CircularProgress id="progress-spinner" />} title={t('LABELS.LOGGING_IN')} placeholder={undefined} />
                    }
                    {!loginState.loading &&
                        <div>
                            <div style={{ padding: '0px' }} data-testid="login-form">
                                <div
                                    className="bg_image"
                                    style={{
                                        backgroundImage: `url(${BackgroundImage})`,
                                        backgroundSize: 'cover',
                                        top: '150px',
                                        height: '150px',
                                        color: '#FFFFFF',
                                    }}
                                >
                                    <img
                                        style={{ padding: '50px', display: 'flex', flexDirection: 'column' }}
                                        src={BrandLogo}
                                        alt="logo"
                                    />
                                    <img
                                        style={{
                                            padding: '50px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            marginTop: '-80px',
                                        }}
                                        src={ProjectName}
                                        alt="logo"
                                    />
                                </div>
                                {inActiveLogout && (
                                    <Typography
                                        variant="subtitle1"
                                        color="error"
                                        style={{ paddingBottom: 10, paddingLeft: 25 }}
                                    >
                                        {t('INACTIVITY.INACTIVE_LOGOUT')}
                                    </Typography>
                                )}
                            </div>
                            <div style={{ padding: '0px 40px', display: 'flex', flexDirection: 'column' }}>
                                <TextField
                                    label={t('LABELS.EMAIL')}
                                    id="email"
                                    name="email"
                                    type="email"
                                    sx={{ mb: 5 }}
                                    value={email}
                                    onChange={(evt: ChangeEvent<HTMLInputElement>): void => setEmail(email)}
                                    variant="filled"
                                    error={Boolean(loginState.errorMessage)}
                                    helperText={t(loginState.errorMessage)}
                                    inputProps={{
                                        'data-testid': 'email',
                                    }}
                                />
                                <TextField
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    name="password"
                                    label={t('LABELS.PASSWORD')}
                                    sx={{ mb: 5 }}
                                    value={password}
                                    onChange={(evt: ChangeEvent<HTMLInputElement>): void => setPassword(evt.target.value)}
                                    variant="filled"
                                    error={Boolean(loginState.errorMessage)}
                                    helperText={t(loginState.errorMessage)}
                                    inputProps={{
                                        'data-testid': 'password',
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="Toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    sx={{
                                        buttonRow: {
                                            mb: 5,
                                            flexWrap: 'nowrap',
                                            [theme.breakpoints.down('xs')]: {
                                                flexWrap: 'wrap',
                                                flexDirection: 'column-reverse',
                                                justifyContent: 'center',
                                            },
                                        },
                                    }}
                                >
                                    <FormControlLabel
                                        data-testid='rememberMe'
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={remember}
                                                data-testid="remember-me"
                                                onChange={(evt): void => setRemember(evt.target.checked)}
                                            />
                                        }
                                        label={t('ACTIONS.REMEMBER')}
                                    />
                                    <Button
                                        variant="contained"
                                        disabled={!canLogIn()}
                                        color="primary"
                                        style={{ width: 150 }}
                                        data-testid="login"
                                        onClick={(evt): void => {
                                            evt.preventDefault();
                                            onLogIn();
                                        }}
                                    >
                                        {t('ACTIONS.LOG_IN')}
                                    </Button>
                                </Grid>

                                <div data-testid='forgotpasswordId' style={{ textAlign: 'center', paddingBottom: 32 }}>
                                    <Typography variant="body2">
                                        <ButtonLink
                                            component='button'
                                            variant="body2"
                                            data-testid="forgot-password-link"
                                            onClick={(): void => {
                                                goToForgetPassword()
                                            }}
                                        >
                                            {t('LABELS.FORGOT_PASSWORD')}
                                        </ButtonLink>
                                    </Typography>

                                    <br />
                                    <Typography data-testid='needAnAccountId' variant="body2">
                                        {t('LABELS.NEED_ACCOUNT')}{' '}
                                        <ButtonLink
                                            component='button'
                                            variant="body2"
                                            data-testid="sign-up-link"
                                            onClick={(): void => {
                                                goToSignUp()
                                            }}
                                        >
                                            {t('LABELS.SIGN_UP')}
                                        </ButtonLink>
                                    </Typography>
                                    <br />
                                </div>
                                <div style={{ textAlign: 'center' }} data-testid="cyber-logo">
                                    <img src={cyberCertifyLogo} alt="logo" />
                                </div>
                            </div>
                        </div>
                    }
                </>
            </BrandedCardContainer>
        </>
    );
};
export { RegisterLogin };
