import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { CREATE_MAINTAINANCE_ACTIVITY } from './url-constants';

type CreateMaintainenceEndpointSpecifications = ApiEndpointSpecification;
export const CreateMaintainenceEndpointSpecification: CreateMaintainenceEndpointSpecifications = {
    url: CREATE_MAINTAINANCE_ACTIVITY,
    method: HttpMethod.PUT,
    requestParamsSchemaName: 'CreateMaintainanceRequestParams',
    requestBodySchemaName: 'CreateMaintainanceRequestBody',
    okResponseSchemaName: 'CreateMaintainanceRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeCreateMaintainaceRequest = Helpers.makeApiRequestFn<
    Types.CreateMaintainanceRequestParams,
    Types.CreateMaintenanceRequestBody,
    Types.CreateMaintenanceOkResponse
>(CreateMaintainenceEndpointSpecification);
