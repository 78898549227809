// eslint-disable-next-line
import React, { useEffect } from 'react';
import {
    Typography,
    Card,
    IconButton,
    Button,
    Divider,
} from '@material-ui/core';
import ListItem from '@mui/material/ListItem';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { Close, FilterNone } from '@mui/icons-material';
import { ACTION_TYPE_COPY, ACTION_TYPE_NONE } from '../../constants';
import * as PXBColors from '@brightlayer-ui/colors';
import { useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import { GatewayDetailsList } from '../../types/gateway';
import { AssetListItem } from '../../components/AssetListItem/AssetListItem';
import { DeviceState, getDeviceStatusState, getGatewayConnectionType } from '../enum/ENUM';
import { getControlledText } from '../enum/GenericFormat';
import { TooltipItem } from '../../components/TooltipItem/TooltipItem';
import { AlarmBadgeStatus } from '../../components/AlarmBadge/AlarmBadge';
import { AlarmStatus } from '../../lib/alarm-helpers';

type Props = {
    closeClick: Function;
    gatewayId: string;
    isAssetGateway: boolean;
    displayClick: Function;
    isShowAllShow: boolean;
};
export const compareVersions = (v1: string, v2: string): number => {
    const v1Parts = v1?.split('.').map(Number);
    const v2Parts = v2?.split('.').map(Number);

    for (let i = 0; i < Math.max(v1Parts?.length, v2Parts?.length); i++) {
        const v1Part = v1Parts[i] || 0;
        const v2Part = v2Parts[i] || 0;

        if (v1Part > v2Part) return 1;
        if (v1Part < v2Part) return -1;
    }

    return 0;
};

export const DeviceGatewayDetails: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {

    const { t } = useLanguageLocale();
    const asset = useSelector(Selectors.Dashboard.asset);



    const gatewayDetailsDefault: GatewayDetailsList = [
        {
            projectId: "",
            projectName: "Loading",
            buildingId: "",
            buildingName: "Loading",
            gateWayId: "",
            gateWayName: "Loading",
            firmwareVersion: "",
            connectionType: 1,
            controllerType: 1,
            partNumber: "",
            epasNumber: "",
            ipAddress: "0.0.0.0",
            subNetMask: "",
            serialNumber: "",
            macAddress: "",
            hostName: "",
            gatewayStatus: 5,
            gatewayStatusName: "",
            systemCount: 1,
            isFavourite: true,
            connectedSystems: [],
            newFirmwareVersion: '3.0'
        }]
    const [gatewayList, setGatewayList] = React.useState<GatewayDetailsList>(gatewayDetailsDefault);
    const [gatewayDetail, setGatewayDetailState] = React.useState(gatewayList[0]);
    const systemStatus = (gatewayDetail.gatewayStatus);//

    useEffect(() => {
        if (!asset.gatewayDetailsListLoading && asset.gatewayDetailsListSuccess) {
            if (asset.gatewayDetailsList.detail.length > 0) {
                setGatewayList(asset.gatewayDetailsList.detail);
                setGatewayDetailState(asset.gatewayDetailsList.detail[0]);
            } else {
                setGatewayList(gatewayDetailsDefault);
                setGatewayDetailState(gatewayDetailsDefault[0]);
            }
        } else if (!asset.gatewayDetailsListLoading && asset.gatewayDetailsListFail) {
            console.log('Device list loading failed');
        }
    }, [asset.gatewayDetailsListLoading, asset.gatewayDetailsListSuccess, asset.gatewayDetailsListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.isAssetGateway && props.gatewayId !== '') {
            const gatewayDetails = gatewayList.filter((gateway) => gateway.gateWayId === props.gatewayId)
            if (gatewayDetails.length > 0) {
                setGatewayDetailState(gatewayDetails[0]);
            } else {
                setGatewayDetailState(gatewayDetailsDefault[0]);
            }
        }
    }, [props.isAssetGateway, props.gatewayId, gatewayList]); // eslint-disable-line react-hooks/exhaustive-deps

    function copyToClipboard(valueToCopy: string) {
        const tempInput = document.createElement('input')
        tempInput.value = valueToCopy
        document.body.appendChild(tempInput)
        tempInput.select()
        document.execCommand('copy')
        document.body.removeChild(tempInput)
    }

    const deviceState: DeviceState = getDeviceStatusState(systemStatus);

    function closeGatewayDetail(): void {
        props.closeClick({});
    }
    const handleShowAll = (): void => {
        props.displayClick({ projectId: gatewayDetail.projectId, buildingId: gatewayDetail.buildingId, gatewayId: gatewayDetail.gateWayId });
    };

    const isUpdateRequired = !gatewayDetail.firmwareVersion || gatewayDetail.firmwareVersion === '--' || compareVersions(gatewayDetail.firmwareVersion, gatewayDetail.newFirmwareVersion) < 0;



    return (
        <>
            <Card style={{ flex: '1 1 0px', overflow: 'auto' }} data-testid="device-gateway-details-container">
                <ListItem style={{
                    width: '100%',
                }}
                    secondaryAction={
                        <IconButton id="gatway-detail-close" data-testid="gatway-detail-close" style={{ top: -20, right: -10, marginLeft: "auto", justifyContent: 'start' }} onClick={(): void => closeGatewayDetail()}>
                            <Close />
                        </IconButton>
                    }>
                    <div>
                        <TooltipItem
                            title={gatewayDetail.gateWayName}
                            isTopPlacement={true}
                            lenghtLimit={22}
                            component={
                                <Typography
                                    id="gateway-name"
                                    data-testid="gateway-name"
                                    variant="body2"
                                    display="block"
                                    style={{ fontSize: 20, fontWeight: 600 }}>
                                    {getControlledText(gatewayDetail.gateWayName, 22)}
                                </Typography>}>
                        </TooltipItem>
                        <div style={{ display: 'inline-block' }}>
                            <img
                                src={deviceState.statusImage}
                                alt="logo"
                                style={{ float: 'left', paddingRight: 8 }}
                                id="list-item-status-icon"
                            />{t(deviceState.statusTitle)}
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Typography variant="body2" style={{ fontSize: 12 }} id="gateway-count" data-testid="gateway-count" >{t('DEVICE_DETAILS.GATEWAY_COUNT', { replace: { systemCount: gatewayDetail.systemCount } })}</Typography>
                            {props.isShowAllShow &&
                                <Button style={{ marginLeft: 5, fontSize: 12, fontWeight: 100, textDecorationLine: 'underline', color: PXBColors.blue[500] }} id="gateway-show-all" data-testid="gateway-show-all" onClick={() => handleShowAll()}  >({t('DEVICE_DETAILS.GATEWAY_SHOW_ALL')})</Button>
                            }
                        </div>
                    </div>
                </ListItem>
                <AssetListItem
                    data-testid="gatway-detail-project-name"
                    key={'gateway-detail-project-name'}
                    subtitle={gatewayDetail.projectName}
                    title={t('USER_SETTINGS.ORGANIZATION')}
                    imageType={ACTION_TYPE_NONE}
                    divider={false}
                    titleBold={false}
                ></AssetListItem>
                <AssetListItem
                    data-testid="gatway-detail-building-name"
                    key={'gateway-detail-building-name'}
                    subtitle={gatewayDetail.buildingName}
                    title={t('DEVICE_DETAILS.BUILDING')}
                    imageType={ACTION_TYPE_NONE}
                    divider={false}
                    titleBold={false}
                ></AssetListItem>
                <ListItem style={{
                    display: 'flex', justifyContent: 'flex-end', height: 'wrap', width: '100%'
                }}
                    secondaryAction={
                        <IconButton id="device-firmware-copy-action" style={{ right: -10, marginLeft: "auto", justifyContent: 'start' }} onClick={(): void => copyToClipboard(gatewayDetail.firmwareVersion != null || gatewayDetail.firmwareVersion != undefined ? gatewayDetail.firmwareVersion + '' : '')}>
                            <FilterNone />
                        </IconButton>
                    }>

                    <div style={{ flex: '1 1 0px', overflow: 'auto', alignItems: 'left', width: '100%' }}>

                        <Typography variant="body2" style={{ fontSize: 14, fontWeight: 600 }} id="device-firmware-version" data-testid="device-firmware-version" >{gatewayDetail.firmwareVersion ? gatewayDetail.firmwareVersion + '' : '--'}</Typography>
                        <Typography variant="body2" style={{ fontSize: 13, fontWeight: 50 }} >{t('DEVICE_DETAILS.FIRMWARE_VERSION')}</Typography>
                        <div style={{ display: 'flex' }}>
                            <AlarmBadgeStatus
                                alarmStatus={AlarmStatus.Open}
                                statusName={isUpdateRequired ? t('DEVICE_DETAILS.UPDATE_REQUIRED') : t('DEVICE_DETAILS.UP_TO_DATE')}
                                statusColor={isUpdateRequired ? '#CA3C3D' : '#39B620'}
                                foregroundColor={PXBColors.white[500]}
                            />

                            <Typography variant="body2" style={{ fontSize: 13, fontWeight: 100 }} id="device-firmware-version-available" data-testid="device-firmware-version-available" >{gatewayDetail.newFirmwareVersion + ' ' + t('DEVICE_DETAILS.FIRMWARE_AVAILABLE_GUIDE')}</Typography>
                        </div>
                    </div>

                </ListItem>
                <Divider style={{ marginLeft: 10 }} />
                <AssetListItem
                    data-testid="gateway-detail-type"
                    key={'gateway-detail-type'}
                    subtitle={t(getGatewayConnectionType(gatewayDetail.connectionType))}
                    title={t('DEVICE_DETAILS.CONNECTION_TYPE')}
                    imageType={ACTION_TYPE_NONE}
                    divider={false}
                    titleBold={false}
                ></AssetListItem>

                <AssetListItem
                    data-testid="gateway-detail-partnumber"
                    key={'gateway-detail-partNumber'}
                    subtitle={gatewayDetail.partNumber ? gatewayDetail.partNumber : '--'}
                    title={t('DEVICE_DETAILS.PART_NUMBER')}
                    imageType={ACTION_TYPE_COPY}
                    OnItemSelected={(): void =>
                        copyToClipboard(gatewayDetail.partNumber ? gatewayDetail.partNumber : '')
                    }
                    divider={false}
                    titleBold={false}
                ></AssetListItem>

                <AssetListItem
                    data-testid="gateway-detail-epasnumber"
                    key={'gateway-detail-epasNumber'}
                    subtitle={gatewayDetail.serialNumber ? gatewayDetail.serialNumber : '--'}
                    title={t('DEVICE_DETAILS.SERIAL_NUMBER')}
                    imageType={ACTION_TYPE_COPY}
                    OnItemSelected={(): void =>
                        copyToClipboard(gatewayDetail.serialNumber ? gatewayDetail.serialNumber : '')
                    }
                    divider={false}
                    titleBold={false}
                ></AssetListItem>
                <AssetListItem
                    data-testid="gateway-detail-mac-address"
                    key={'gateway-detail-mac-address'}
                    subtitle={gatewayDetail.macAddress ? gatewayDetail.macAddress : '--'}
                    title={t('DEVICE_DETAILS.MAC_ADDRESS')}
                    imageType={ACTION_TYPE_COPY}
                    OnItemSelected={(): void =>
                        copyToClipboard(gatewayDetail.macAddress ? gatewayDetail.macAddress : '')
                    }
                    divider={false}
                    titleBold={false}
                ></AssetListItem>
                <AssetListItem
                    data-testid="gateway-detail-ipaddress"
                    key={'gateway-detail-ipaddress'}
                    subtitle={gatewayDetail.ipAddress ? gatewayDetail.ipAddress : '--'}
                    title={t('DEVICE_DETAILS.IPADDRESS')}
                    imageType={ACTION_TYPE_COPY}
                    OnItemSelected={(): void =>
                        copyToClipboard(gatewayDetail.ipAddress ? gatewayDetail.ipAddress : '')
                    }
                    divider={false}
                    titleBold={false}
                ></AssetListItem>
                <AssetListItem
                    data-testid="gateway-detail-subnet"
                    key={'gateway-detail-subnet'}
                    subtitle={gatewayDetail.subNetMask ? gatewayDetail.subNetMask : '--'}
                    title={t('DEVICE_DETAILS.SUBNET_MASK')}
                    imageType={ACTION_TYPE_COPY}
                    OnItemSelected={(): void =>
                        copyToClipboard(gatewayDetail.subNetMask ? gatewayDetail.subNetMask : '')
                    }
                    divider={false}
                    titleBold={false}
                ></AssetListItem>
            </Card >
        </>
    );
};
