// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { Card, Typography } from '@mui/material';
import * as PXBColors from '@brightlayer-ui/colors';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { AccessTime, AssignmentTurnedIn, CalendarMonth, CheckCircleOutline, LocationOn, NotificationsActive } from '@mui/icons-material';
import { COLOR_GRAY_ICON } from '../../constants/color-codes';
import { MaintenanceDetail, ResolutionListDataGet, ResolutionsArray } from '../../types/maintenance';
import { getDeviceType, getRequestPriorityTitle, getServiceDateStatusState, getServiceType, TICKET_STATUS, ticketStatus } from '../enum/ENUM';
import { formatTimeShort } from '../../lib/date-time-formatter';
import { daysBetweentwoDates, formatDate, getDaysDifferenceFromDates, getDaysFromDate, getShortDate } from '../enum/DateTimeFormat';
import { DistributionBox, Priority } from '@brightlayer-ui/icons-mui';


type Props = {
    maintenanceDetail: MaintenanceDetail;
    resolutions: ResolutionsArray;
};

export const MaintenanceInfoCard: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const maintenanceDetail: MaintenanceDetail  = props.maintenanceDetail;
    const ticketStatusNumber = maintenanceDetail.ticketStatus !== 0 ? maintenanceDetail.ticketStatus - 1 : maintenanceDetail.ticketStatus;
    const ticketStatusName = ticketStatus[ticketStatusNumber].name !== null ? (ticketStatus[ticketStatusNumber].name) : 'MAINTENANCE_DETAILS.STATUS_SCHEDULED';
    const ticketStatusState = getServiceDateStatusState(ticketStatusName).statusTitle === 'MAINTENANCE_DETAILS.STATUS_SCHEDULED' ? true : false ;
    const systemName = '"'+ maintenanceDetail.systemName +',' + '" '+ getDeviceType(maintenanceDetail.systemType) ;
    const [resolution, setResolution] = useState<ResolutionListDataGet>();
    
    useEffect(() => {
        if (props.resolutions !== undefined) {
            const resolutionsLength = props.resolutions.length;
            setResolution(props.resolutions[resolutionsLength - 1])
        }
        // eslint-disable-next-line
    },[props.resolutions]);

    const getScheduledDaysFromDate = (date: any) => {
        const startDate = getShortDate(new Date().toString());
        const endDate = getShortDate(new Date(date).toString());
         const differenceInDays = daysBetweentwoDates(startDate,endDate )
        return (differenceInDays <= 40 && differenceInDays >= 0) ? (differenceInDays === 0 ? ('MAINTENANCE_DETAILS.TODAY') : t('MAINTENANCE_DETAILS.IN_DUE', { replace: { differenceInDays: differenceInDays } })) : differenceInDays > 40 ? ('MAINTENANCE_DETAILS.SCHEDULED') : ('MAINTENANCE_DETAILS.PAST_DUE')
    }

    return (
        <>
            <Card style={{ height: 'fit-content', flex: '1 1 0px', overflow: 'auto' }}>
                <InfoListItem data-testid='status' style={{ height: 56 }} title={''} subtitle={t('USERS.STATUS')}
                    icon={<AssignmentTurnedIn style={{ color: COLOR_GRAY_ICON }} />}
                    iconAlign={'center'}
                    divider={'full'} placeholder={undefined}
                    rightComponent={<div>
                        <Typography data-testid="list-item-status" style={{ fontWeight: 600, fontSize: 15, lineHeight: 2, fontStyle: 'normal', color: PXBColors.black[500] }}  >
                            { ticketStatusState ? t(getScheduledDaysFromDate(maintenanceDetail.serviceDate)) : t(getServiceDateStatusState(ticketStatus[ticketStatusNumber].name).statusTitle)}
                        </Typography>
                    </div>} />
                <InfoListItem data-testid='system' style={{ height: 56 }} title={''} subtitle={t('LOGBOOK_DETAILS.SYSTEM')}
                    icon={<DistributionBox style={{ color: COLOR_GRAY_ICON }} />}
                    iconAlign={'center'}
                    divider={'full'} placeholder={undefined}
                    rightComponent={<div>
                        <Typography data-testid="list-item-system-name" style={{ fontWeight: 600, fontSize: 15, lineHeight: 2, fontStyle: 'normal', color: PXBColors.black[500] }}  >
                            {systemName}
                        </Typography>
                    </div>}
                />
                <InfoListItem data-testid='priority' style={{ height: 56 }} title={''} subtitle={t('MAINTENANCE_DETAILS.PRIORITY')}
                    icon={<Priority style={{ color: COLOR_GRAY_ICON }} />}
                    iconAlign={'center'}
                    divider={'full'} placeholder={undefined}
                    rightComponent={<div>
                        <Typography data-testid="list-item-priority" style={{ fontWeight: 600, fontSize: 15, lineHeight: 2, fontStyle: 'normal', color: PXBColors.black[500] }}  >
                            {t(getRequestPriorityTitle(maintenanceDetail.requestPriority))}
                        </Typography>
                    </div>}
                />
                <InfoListItem data-testid='type' style={{ height: 56 }} title={''} subtitle={t('DEVICE_DETAILS.TYPE')}
                    icon={<NotificationsActive style={{ color: COLOR_GRAY_ICON }} />}
                    iconAlign={'center'}
                    divider={'full'} placeholder={undefined}
                    rightComponent={<div>
                        <Typography data-testid="list-item-service-type" style={{ fontWeight: 600, fontSize: 15, lineHeight: 2, fontStyle: 'normal', color: PXBColors.black[500] }}  >
                            {t(getServiceType(maintenanceDetail.serviceType))}
                        </Typography>
                    </div>}
                />
                <InfoListItem data-testid='date-created' style={{ height: 56 }} title={''} subtitle={t('MAINTENANCE_DETAILS.DATE_CREATED')}
                    icon={<AccessTime style={{ color: COLOR_GRAY_ICON }} />}
                    iconAlign={'center'}
                    divider={'full'} placeholder={undefined}
                    rightComponent={<div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography data-testid="list-item-date-created" style={{ fontWeight: 600, fontSize: 15, fontStyle: 'normal', color: PXBColors.black[500] }}  >
                            {formatDate(maintenanceDetail.lastUpdatedDateTime)}
                        </Typography>
                        <Typography data-testid="list-item-text" style={{ fontWeight: 400, fontSize: 14, fontStyle: 'normal', color: PXBColors.black[500] }}  >
                            {formatTimeShort(maintenanceDetail.lastUpdatedDateTime)}
                        </Typography>
                    </div>}
                />
                <InfoListItem data-testid='scheduled-service-date' style={{ height: 56 }} title={''} subtitle={t('MAINTENANCE_DETAILS.SCH_SERVICE_DATE')}
                    icon={<CalendarMonth style={{ color: COLOR_GRAY_ICON }} />}
                    iconAlign={'center'}
                    divider={'full'} placeholder={undefined}
                    rightComponent={<div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography data-testid="list-item-scheduled-service-date" style={{ fontWeight: 600, fontSize: 15, fontStyle: 'normal', color: PXBColors.black[500] }}  >
                            {formatDate(maintenanceDetail.serviceDate)}
                        </Typography>
                        <Typography data-testid="list-item-scheduled-service-time" style={{ fontWeight: 400, fontSize: 14, fontStyle: 'normal', color: PXBColors.black[500] }}  >
                            {formatTimeShort(maintenanceDetail.serviceDate)}
                        </Typography>
                    </div>}
                />
                <InfoListItem data-testid='ticket-closed' style={{ height: 56 }} title={''} subtitle={t('MAINTENANCE_DETAILS.TICKET_CLOSED')}
                    icon={<CheckCircleOutline style={{ color: COLOR_GRAY_ICON }} />}
                    iconAlign={'center'}
                    divider={'full'} placeholder={undefined}
                    rightComponent={<div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography data-testid="list-item-scheduled-service-date" style={{ fontWeight: 600, fontSize: 15, fontStyle: 'normal', color: PXBColors.black[500] }}  >
                            {resolution?.createdDateTime !== undefined && resolution.ticketStatus === TICKET_STATUS.STATUS_COMPLETED ? formatDate(resolution?.createdDateTime) : '--'}
                        </Typography>
                        <Typography data-testid="list-item-scheduled-service-time" style={{ fontWeight: 400, fontSize: 14, fontStyle: 'normal', color: PXBColors.black[500] }}  >
                            {resolution?.createdDateTime !== undefined && resolution.ticketStatus === TICKET_STATUS.STATUS_COMPLETED ? formatTimeShort(resolution?.createdDateTime) : ''}
                        </Typography>
                    </div>}
                />
                <InfoListItem data-testid='location' style={{ height: 56 }} title={''} subtitle={t('MAINTENANCE_DETAILS.LOCATION')}
                    icon={<LocationOn style={{ color: COLOR_GRAY_ICON }} />}
                    iconAlign={'center'}
                    divider={'full'} placeholder={undefined}
                    rightComponent={<div>
                        <Typography data-testid="list-item-location" style={{ fontWeight: 600, fontSize: 15, lineHeight: 2, fontStyle: 'normal', color: PXBColors.black[500] }}  >
                            {maintenanceDetail.systemLocation}
                        </Typography>
                    </div>}
                />
            </Card>
        </>
    );
};


