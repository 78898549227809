import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { ADD_NOTES_ACTIVITY } from './url-constants';

type AddNotesEndpointSpecifications = ApiEndpointSpecification;
export const AddNotesEndpointSpecification: AddNotesEndpointSpecifications = {
    url: ADD_NOTES_ACTIVITY,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'AddNotesRequestParams',
    requestBodySchemaName: 'AddNotesRequestBody',
    okResponseSchemaName: 'AddNotesRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeAddNotesRequest = Helpers.makeApiRequestFn<
    Types.AddNotesRequestParams,
    Types.AddNotesRequestBody,
    Types.AddNotesOkResponse
>(AddNotesEndpointSpecification);
