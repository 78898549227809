// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { GenericToolbar } from '../../components/GenericToolbar';
import { AppBar } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import * as PXBColors from '@brightlayer-ui/colors';
import Box from '@mui/material/Box';
import { MaintenanceList } from './MaintenanceList';
import { MaintenanceDetail } from '../../types/maintenance';
import { useHistory } from 'react-router';
import { pushAppRoute } from '../../data/domain/route-manager';

type Props = {
    location?: any;
};

export const TAB_STATE_KEY = 'backState';
export const TAB_SCHEDULED = 0;
export const TAB_SCHEDULED_KEY = 'Scheduled';
export const TAB_MAINTENANCE_HISTORY = 1;


export const getTabState = (): number => {
    const tabState = sessionStorage.getItem(TAB_STATE_KEY);
    switch (tabState) {
        case TAB_SCHEDULED_KEY:
            return TAB_SCHEDULED;
        default:
            return TAB_SCHEDULED;
    }
};
export const updateTabSession = (tab: string): void => {
    sessionStorage.setItem(TAB_STATE_KEY, tab);
};

export const Maintenance: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const history = useHistory();

    function TabPanel(props: { [x: string]: any; children: any; value: any; index: any }) {
        const { children, value, index, ...other } = props;
        return <div {...other}>{value === index && <Box p={3}>{children}</Box>}</div>;

    }

    const [value, setValue] = React.useState(getTabState());

    updateTabSession(TAB_SCHEDULED_KEY);

    const handleTabChange = (_event: any, newValue: React.SetStateAction<number>) => {
        setValue(newValue);
    };
    const setSelectedTab = (value: { maintenance: MaintenanceDetail; }): void => {
        updateTabSession(TAB_SCHEDULED_KEY);
        const maintenanceData: MaintenanceDetail = value.maintenance;
        pushAppRoute(history,
            { type: 'MaintenanceDetails', maintenanceId: maintenanceData.id},
            { backRoute: 'Maintenance', maintenanceId: maintenanceData.id, sourcePath: 'Maintenance' });
    }

    return (
        <>
            <div data-testid="maintenance-container">
                <GenericToolbar title={t('DRAWER.MAINTENANCE')} />
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <AppBar position="static" style={{ backgroundColor: PXBColors.blue[700] }}>
                        <Tabs indicatorColor='secondary' variant="scrollable"
                            scrollButtons={false} textColor="inherit" value={value} onChange={handleTabChange} sx={{
                                backgroundColor: PXBColors.blue[700],
                                height: 2,
                            }} >
                            <Tab data-testid="tabschedule" label={t('MAINTENANCE_DETAILS.SCHEDULED')} style={{ textTransform: 'none', marginLeft: 35, color: PXBColors.white[500] }} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={TAB_SCHEDULED} data-testid="tabschedule-container">
                        <MaintenanceList onClick={setSelectedTab} ></MaintenanceList>
                    </TabPanel>
                </Box>
            </div >
        </>
    );
};
