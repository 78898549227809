// eslint-disable-next-line
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AppRoutes } from '../../routes';
import { Maintenance } from './Maintenance';
import { MaintenanceDetails } from './MaintenanceDetails';

export const MaintenanceManagement: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => (
    <Switch>
        <Route exact path={AppRoutes.Maintenance} component={Maintenance} />
        <Route exact path={AppRoutes.MaintenanceDetails} component={MaintenanceDetails} />
    </Switch>
);
