// eslint-disable-next-line
import React, { useEffect, useState, ChangeEvent } from 'react';
import { Card, Divider, Button, CircularProgress, InputAdornment, IconButton, MenuItem, FormControl, InputLabel, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Select, SelectChangeEvent } from '@mui/material';
import { Call, ContactPhone, DeleteForever, Edit, Email, Group, Notes, Room, SwapHoriz, Visibility, VisibilityOff } from '@mui/icons-material';
import * as PXBColors from '@brightlayer-ui/colors';
import { Typography } from '@mui/material';
import { OrgDetailListItem } from '../../components/OverviewList/OrgDetailListItem';
import { COLOR_GRAY_ICON } from '../../constants/color-codes';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { OrganizationData } from '../../types/organization';
import { Thunks } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import { EmptyState } from '@brightlayer-ui/react-components';
import TextField from '@mui/material/TextField';
import { ItemList } from '../../types/logbook-type';
import { useOrganizationList } from '../../data/DataProviders/OrganizationListProvider';
import { useAuthHelper } from '../../components/AuthContextProvider';
import { useToasts } from '../../data/DataProviders/ToastProvider';
import { OrganizationThunks } from '../../actions/thunks/organization-thunks';
import { pushAppRoute } from '../../data/domain/route-manager';
import { useHistory } from 'react-router';
import { DashboardThunks } from '../../actions/thunks/dashboard-thunks';
import { OrganizationActions } from '../../actions/actions/organization-actions';
import { EMPTY_STRING, LOGIN_ATTEMPTS } from '../../constants';

const orgDetailsDefault: OrganizationData = {
    projectId: "04f81684-64cd-4912-a7c3-974e1cffc08e",
    name: "Haus Deutch",
    description: 'I am some notes left by other people. The size of this div shoud grow and shrink depends on how many lines we have here. If there are no notes at all, then don’t show this InfoListItem here, or maybe show some gray text saying “no notes available”. Absolutely do not show a blank list item, that is just way to confusing.',
    country: "Germany ",
    address: "Munich, 1234",
    postalCode: "1234",
    city: "Berlin",
    countryCode: "39",
    phone: "123456789",
    email: "Test@gmail.com",
    systemCount: 11,
    buildingCount: 22,
    gatewayCount: 0,
    adminCount: 2,
    viewerCount: 14,
    isAdmin: false,
    isFavourite: false,
    address2: '',
    primaryFirstName: null,
    primaryLastName: null,
    secondaryFirstName: null,
    secondaryLastName: null,
    secondaryEmail: null,
    secondaryPhone: null,
    secondaryCountryCode: null
};

type Props = {
    orgId: string;
    orgNameUpdate: Function;
    userOnClick: Function;

};

export const OrgInfo: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const [openTransferBuildingDialog, setOpenTransferBuildingDialog] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const authHelper = useAuthHelper();
    const [showPassword, setShowPassword] = useState(false);
    const [orgSelection, setOrgSelection] = React.useState('');
    const { t } = useLanguageLocale();
    const dispatch = useDispatch();
    const history = useHistory();
    const [organizationDetails, setOrganizationDetails] = useState<OrganizationData>(orgDetailsDefault);
    const organization = useSelector(Selectors.Organization.organization);
    const [organizationListData, setOrganizationListData] = React.useState<ItemList>([]);
    const [passwordAttempt, setPasswordAttempt] = React.useState(parseInt(sessionStorage.getItem(LOGIN_ATTEMPTS) || ''));
    const [isTransferBuildingDone, setTransferBuildingDone] = React.useState(false);
    const { addToast } = useToasts();

    const fieldsComplete = (fields: string[]): boolean => {
        for (let i = 0; i < fields.length; i++) {
            if (fields[i].length < 1) {
                return false;
            }
        }
        return true;
    };
    const onTransferClick = (): void => {
        setTransferBuildingDone(true);
        dispatch(OrganizationThunks.transferBuilding(
            orgSelection,
            props.orgId,
            password,
            addToast,
            authHelper,
            ''
        ));
    }

    const handleOpenTransferBuildingDialog = () => {
        setOpenTransferBuildingDialog(true);
    }

    const editOrganization = (): void => {
        pushAppRoute(history,
            { type: 'EditOrganization', orgId: props.orgId },
            { backRoute: 'OrganizationDetails', orgId: props.orgId });
    };

    const handleCloseTransferBuildingDialog = () => {
        setOpenTransferBuildingDialog(false);
        onCancel();
    };

    const deleteForever = (): void => {
    };

    const goToUserDetails = (): void => {
        props.userOnClick();
    };

    useEffect(() => {
        dispatch(Thunks.Organization.getOrganizationDetailsList(props.orgId));
    }, [props.orgId]); // eslint-disable-line react-hooks/exhaustive-deps
    const useOrganizationDataList = useOrganizationList();

    useEffect(() => {
        setOrganizationListData(useOrganizationDataList.organizationList);
    }, [useOrganizationDataList, useOrganizationDataList.organizationList]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!organization.orgListLoading && organization.orgListSuccess) {
            setOrganizationDetails(organization.orgList.detail[0]);
            if (props.orgNameUpdate) { props.orgNameUpdate(organization.orgList.detail[0].name) }
        } else if (!organization.orgListLoading && organization.orgListFail) {
            setOrganizationDetails(orgDetailsDefault);
        }
    }, [organization.orgListLoading, organization.orgListSuccess, organization.orgListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    const usersAdminText = organizationDetails.adminCount > 1 ? organizationDetails.adminCount + ' ' + t('DASHBOARD.USERS_ADMIN_S') : organizationDetails.adminCount + ' ' + t('DASHBOARD.USERS_ADMIN');
    const usersVieweText = organizationDetails.viewerCount > 1 ? organizationDetails.viewerCount + ' ' + t('DASHBOARD.USERS_VIEWER_S') : organizationDetails.viewerCount + ' ' + t('DASHBOARD.USERS_VIEWER');

    const handleClickShowPassword = (): void => {
        setShowPassword(!showPassword);
    };

    const handleSetOrganization = (event: SelectChangeEvent) => {
        setOrgSelection(event.target.value);
    };

    const onCancel = () => {
        setOrgSelection(EMPTY_STRING);
        setPassword('');
    }
    useEffect(() => {
        if (!organization.transferBuildingLoading && organization.transferBuildingSuccess) {
            addToast(t('TOAST_MSG.BUILDING_TRANSFER_SUCCESS'), 'success');
            dispatch(OrganizationActions.transferBuildingUnmounted());
            handleCloseTransferBuildingDialog();
            dispatch(DashboardThunks.getBuildingList(
                organizationDetails.projectId,
            ));
            setTransferBuildingDone(false);
        }
        else if (!organization.transferBuildingLoading && organization.transferBuildingFail) {
            if (organization.transferBuildingErrorMessage === '9044') {
                setPasswordAttempt(passwordAttempt + 1);
                sessionStorage.setItem(LOGIN_ATTEMPTS, passwordAttempt.toString());
                passwordAttempt > 3 ? addToast(t('TRANSFER_BUILDINGS.ERRORMESSAGE'), 'error') : addToast(t('TOAST_MSG.TOAST_ERROR_MERGE_ASSET_9044'), 'error');
            }
            setTransferBuildingDone(false);
        }
        dispatch(OrganizationActions.transferBuildingUnmounted());
    }, [organization.transferBuildingLoading, organization.transferBuildingSuccess, organization.transferBuildingFail]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Card style={{ flex: '1 1 0px', overflow: 'auto' }}
                id="org-detail-info-container"
                data-testid="org-detail-info-container">

                {organization.orgListLoading && <EmptyState style={{ flex: 1, minHeight: 400 }}
                    icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}

                {!organization.orgListLoading && <div>
                    <Typography variant="body2" style={{ alignItems: 'left', fontSize: 20, fontWeight: 600, width: '90%', margin: 20, marginBottom: 5 }}>
                        {organizationDetails.name}
                    </Typography>

                    <OrgDetailListItem
                        title1={organizationDetails.address + ' ' + organizationDetails.address2}
                        title2={organizationDetails.postalCode + ' ' + organizationDetails.city}
                        title3={organizationDetails.country}
                        subtitle={t('ORGANIZATION_DETAILS.ADDRESS')}
                        icon={<Room style={{ color: COLOR_GRAY_ICON }} />}
                        iconAlignment={'center'}
                        displayAction={false}
                    />

                    <OrgDetailListItem
                        title1={'+' + organizationDetails.countryCode + ' ' + organizationDetails.phone}
                        title2={''}
                        title3={''}
                        subtitle={t('ORGANIZATION_DETAILS.PHONE_NUMBER')}
                        icon={<Call style={{ color: COLOR_GRAY_ICON }} />}
                        iconAlignment={'center'}
                        displayAction={false}
                    />

                    <OrgDetailListItem
                        title1={organizationDetails.email ? organizationDetails.email : ''}
                        title2={''}
                        title3={''}
                        subtitle={t('ORGANIZATION_DETAILS.EMAIL_ADDRESS')}
                        icon={<Email style={{ color: COLOR_GRAY_ICON }} />}
                        iconAlignment={'center'}
                        displayAction={false}
                    />
                    <OrgDetailListItem
                        title1={t('ORGANIZATION_DETAILS.PRIMARY_CONTACT')}
                        title2={''}
                        title3={''}
                        subtitle={(organizationDetails.primaryFirstName ? organizationDetails.primaryFirstName : '') + ' ' + (organizationDetails.primaryLastName ? organizationDetails.primaryLastName : '')}
                        icon={<ContactPhone style={{ color: COLOR_GRAY_ICON }} />}

                        displayAction={false}
                        dividerDisable={true} iconAlignment={'center'} />
                    <OrgDetailListItem
                        title1={organizationDetails.phone ? '+' + organizationDetails.countryCode + ' ' + organizationDetails.phone : ''}
                        title2={''}
                        title3={''}
                        subtitle={t('ORGANIZATION_DETAILS.PRIMARY_CONTACT_PHONE_NUMBER')}

                        displayAction={false}
                        dividerDisable={true} icon={undefined} iconAlignment={''} />
                    <OrgDetailListItem
                        title1={organizationDetails.email ? organizationDetails.email : ''}
                        title2={''}
                        title3={''}
                        subtitle={t('ORGANIZATION_DETAILS.PRIMARY_CONTACT_EMAIL_ADDRESS')}
                        displayAction={false} icon={undefined} iconAlignment={''} />
                    <OrgDetailListItem
                        title1={t('ORGANIZATION_DETAILS.SECONDARY_CONTACT')}
                        title2={''}
                        title3={''}
                        subtitle={(organizationDetails.secondaryFirstName ? organizationDetails.secondaryFirstName : '') + ' ' + (organizationDetails.secondaryLastName ? organizationDetails.secondaryLastName : '')}
                        icon={<ContactPhone style={{ color: COLOR_GRAY_ICON }} />}

                        displayAction={false}
                        dividerDisable={true} iconAlignment={'center'} />
                    <OrgDetailListItem
                        title1={organizationDetails.secondaryPhone ? '+' + organizationDetails.secondaryCountryCode + ' ' + organizationDetails.secondaryPhone : ''}
                        title2={''}
                        title3={''}
                        subtitle={t('ORGANIZATION_DETAILS.SECONDARY_CONTACT_PHONE_NUMBER')}

                        displayAction={false}
                        dividerDisable={true} icon={undefined} iconAlignment={''} />
                    <OrgDetailListItem
                        title1={organizationDetails.secondaryEmail ? organizationDetails.secondaryEmail : ''}
                        title2={''}
                        title3={''}
                        subtitle={t('ORGANIZATION_DETAILS.SECONDARY_CONTACT_EMAIL_ADDRESS')}
                        displayAction={false} icon={undefined} iconAlignment={''} />
                    <OrgDetailListItem
                        title1={usersAdminText + ', ' + usersVieweText}
                        title2={''}
                        title3={''}
                        subtitle={t('ORGANIZATION_DETAILS.USERS')}
                        icon={<Group style={{ color: COLOR_GRAY_ICON }} />}
                        iconAlignment={'center'}
                        displayAction={true}
                        OnItemSelected={goToUserDetails}
                    />

                    <OrgDetailListItem
                        title1={''}
                        title2={''}
                        title3={''}
                        subtitle={''}
                        subtitle2={organizationDetails.description ? organizationDetails.description : ''}
                        icon={<Notes style={{ color: COLOR_GRAY_ICON }} />}
                        iconAlignment={'center'}
                        displayAction={false}
                        dividerDisable={true}
                    />

                    <Divider />

                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 15, marginRight: 15, marginBottom: 15 }} >

                        <Button
                            style={{
                                backgroundColor: PXBColors.blue[700],
                                color: PXBColors.white[50],
                                marginTop: 15,
                                opacity: organizationDetails.isAdmin ? 1 : 0.5,
                                textTransform: 'none',
                            }}
                            id="org-edit-button"
                            data-testid="org-edit-button"
                            variant="contained"
                            disabled={!organizationDetails.isAdmin}
                            onClick={(): void => editOrganization()}
                        >
                            <Edit style={{ padding: 3 }} />
                            {t('ORGANIZATION_DETAILS.EDIT_ORGANIZATION')}
                        </Button>
                        {<Button
                            style={{
                                backgroundColor: PXBColors.white[50],
                                borderColor: PXBColors.blue[700],
                                marginTop: 15,
                                opacity: organizationDetails.isAdmin ? 1 : 0.5,
                                color: PXBColors.blue[500],
                                textTransform: 'none',
                                fontWeight: 500
                            }}
                            disabled={!organizationDetails.isAdmin || !Boolean(organizationDetails.buildingCount)}
                            id="org-transfer-button"
                            data-testid="org-transfer-button"
                            variant="outlined"
                            onClick={handleOpenTransferBuildingDialog}
                        >
                            <SwapHoriz style={{ padding: 3 }} />
                            {t('ORGANIZATION_DETAILS.TRANSFER_BUILDINGS')}
                        </Button>
                        }

                        <Button
                            style={{
                                backgroundColor: PXBColors.white[50],
                                color: PXBColors.red[500],
                                borderColor: PXBColors.red[500],
                                marginTop: 15,
                                opacity: organizationDetails.isAdmin ? 1 : 0.5,
                                textTransform: 'none',
                            }}
                            id="org-delete-button"
                            data-testid="org-delete-button"
                            disabled={true}
                            variant="outlined"
                            onClick={(): void => deleteForever()}
                        >
                            <DeleteForever style={{ padding: 3 }} />
                            {t('ORGANIZATION_DETAILS.DELETE_FOREVER')}
                        </Button>
                    </div>
                </div>}
            </Card>


            <Dialog id="transfer-building-dialog" maxWidth="xs" open={openTransferBuildingDialog} onClose={handleCloseTransferBuildingDialog}>
                <DialogTitle id="dialog-header" style={{ fontSize: 20 }}>{t('ORGANIZATION_DETAILS.TRANSFER_BUILDINGS')}</DialogTitle>
                {isTransferBuildingDone && <EmptyState style={{ flex: 1, minHeight: 400 }}
                    icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}
                {!isTransferBuildingDone && <>
                    <DialogContent>
                        <DialogContentText data-testid="dialog-header-text" style={{ marginBottom: 20 }}>
                            {t('TRANSFER_BUILDINGS.HEADER', { replace: { buildingCount: organizationDetails.buildingCount, gatewayCount: organizationDetails.gatewayCount, systemCount: organizationDetails.systemCount } })}
                        </DialogContentText>
                        <>
                            <div>
                                <FormControl variant={'filled'} fullWidth>
                                    <InputLabel >
                                        {t('TRANSFER_BUILDINGS.TRANSFER_TO')}
                                    </InputLabel>
                                    <Select
                                        variant={'filled'}
                                        fullWidth style={{ marginBottom: 36, backgroundColor: PXBColors.white[500] }}
                                        labelId="transfer-building-label"
                                        inputProps={{ 'data-testid': 'transfer-building' }}
                                        data-testid="transfer-building"
                                        value={orgSelection}
                                        onChange={handleSetOrganization}
                                    >
                                        {organizationListData.map((data) => {
                                            return (
                                                <MenuItem id="org-list" key={data.id} value={data.id}
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'left', fontSize: 14,
                                                        lineHeight: 1.5, fontWeight: 400,
                                                        overflow: 'hidden',
                                                        boxSizing: 'border-box',
                                                        whiteSpace: 'nowrap',
                                                        minHeight: 48,
                                                        paddingTop: 6,
                                                        paddingBottom: 6,
                                                        width: 'auto',
                                                        paddingLeft: 16,
                                                        paddingRight: 16
                                                    }}>
                                                    {data.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                                <Typography style={{ marginBottom: 16 }}>{t('TRANSFER_BUILDINGS.ACTION')} </Typography>
                                <TextField
                                    fullWidth style={{ marginBottom: 35 }}
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    name="password"
                                    label={t('TRANSFER_BUILDINGS.PASSWORD')}
                                    value={password}
                                    onChange={(evt: ChangeEvent<HTMLInputElement>): void => setPassword(evt.target.value)}
                                    variant="filled"
                                    error={Boolean(organization.transferBuildingErrorMessage)}
                                    helperText={t(organization.transferBuildingErrorMessage)}
                                    inputProps={{
                                        'data-testid': 'password',
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="Toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                        </>
                    </DialogContent>
                    <Divider style={{ color: 'grey' }} />
                    <DialogActions style={{ justifyContent: 'space-between', marginTop: 15, marginBottom: 15, marginLeft: 16, marginRight: 17 }}>
                        <Button style={{ textTransform: 'none' }} id="btnCancel" data-testid="btnCancel" variant="outlined" color="primary" onClick={handleCloseTransferBuildingDialog}>
                            {t('ACTIONS.CANCEL')}
                        </Button>
                        <Button
                            style={{
                                background: PXBColors.red[500], color: PXBColors.white[50],
                                opacity: fieldsComplete([orgSelection, password]) ? 1 : 0.5,
                                textTransform: 'none'
                            }}
                            disabled={!fieldsComplete([orgSelection, password]) && organization.transferBuildingLoading}
                            id="btnTransfer"
                            data-testid="btnTransfer"
                            variant="contained"
                            onClick={onTransferClick}
                        >
                            {t('ACTIONS.TRANSFER')}
                        </Button>
                    </DialogActions>
                </>}
            </Dialog>
        </>
    );
};
