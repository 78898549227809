// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { Close, Search } from '@mui/icons-material';
import { RichTreeView, SimpleTreeView, TreeItem, TreeItemSlots, TreeViewBaseItem } from '@mui/x-tree-view';
import { DialogContent, IconButton, DialogTitle, Typography, TextField, InputAdornment, Box, useMediaQuery, useTheme, CircularProgress } from '@mui/material';
import CustomTreeItem, { NodeDetails } from '../../components/CustomTree/CustomTreeContent';
import { COLOR_GRAY } from '../../constants/color-codes';
import { ENTITY_TYPE, getDeviceType, USER_ROLE_TYPE } from '../enum/ENUM';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { EntityAccessDetailEntity, RenderTree, UserAdvanceAccessDetail } from '../../types/manage-users';
import { ORGANIZATTION_NAME } from '../../constants';
import * as PXBColors from '@brightlayer-ui/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { EmptyState } from '@brightlayer-ui/react-components';
import { useTreeViewApiRef } from '@mui/x-tree-view/hooks';
import { getEntityRole } from './InviteUser';
import { useSelector } from 'react-redux';
import { Selectors } from '../../selectors';


type Props = {
    emailAdvance: string;
    userAccessDetailData: UserAdvanceAccessDetail;
    onCancelAdvance: Function;
    isAdvanceTreeDataAvailable: boolean;
    onItemSelected?: Function;
    updateTreeData?: RenderTree[];
};


export const InviteUserAdvanceDialogSecondPage: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();

    const usersSelector = useSelector(Selectors.Users.users);
    const [isDataAvailable, setDataAvailable] = useState(false);
    const [query, setQuery] = useState('');
    const [treeData, setTreeData] = useState<RenderTree[]>(props.updateTreeData || []);
    const [treeDataUpdate, setTreeDataUpdate] = useState<RenderTree[]>([]);
    const apiRef = useTreeViewApiRef();

    useEffect(() => {
        if ((props.userAccessDetailData.inviteByUserEntityAccessDetail && props.userAccessDetailData.inviteByUserEntityAccessDetail.length > 0) ||
            (props.userAccessDetailData.inviteUserEntityAccessDetail && props.userAccessDetailData.inviteUserEntityAccessDetail.length > 0)) {
            setDataAvailable(true);
            if (props.userAccessDetailData.inviteByUserEntityAccessDetail) {
                createTree(props.userAccessDetailData.inviteByUserEntityAccessDetail);
            }
        }
        else {
            setDataAvailable(false);
        }
    }, [props.userAccessDetailData]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.updateTreeData && props.updateTreeData.length > 0) {
            setTreeData(props.updateTreeData || [] as RenderTree[]);
        }
    }, [props.updateTreeData]); // eslint-disable-line react-hooks/exhaustive-deps

    const onCancelAdvanceInviteCall = () => {
        props.onCancelAdvance({});
    }

    const onChangeSearchText = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || ORGANIZATTION_NAME.test(e.target.value as string))
            setQuery(e.target.value);
    };

    const resetSearch = (): void => {
        setQuery('');
    };

    // Function to update the select property of a node based on a condition
    const updateSelectProperty = (nodes: RenderTree[], status: boolean, role?: string, disabled?: boolean): RenderTree[] => {
        return nodes.map(node => {
            // If the current node meets the condition, update select to true
            node.selected = status;
            node.role = role;
            node.disabled = disabled;
            // If the node has children, recursively update them as well
            if (node.children) {
                node.children = updateSelectProperty(node.children, status, role, disabled);
            }
            return node;
        });
    };

    const getNodeDetails = (data: NodeDetails) => {

        let role: string;
        let isNodeSelected: boolean;
        let disabled: boolean;
        let tempTreeArray = treeDataUpdate;

        //handle disable for child and selection
        const entityTypeSelected = getEntityRole(parseInt(data.entityType), parseInt(data.newRole)).toString();

        //Organization Admin and Viewer- child nodes isNodeSelected set
        if (parseInt(data.entityType) === ENTITY_TYPE.ORGANIZATION) {

            const orgTreeItem = treeDataUpdate.findIndex((item: RenderTree) => item.id === data.itemId);
            if (parseInt(entityTypeSelected) === USER_ROLE_TYPE.EL_ORG_ADMIN) {
                isNodeSelected = true;
                role = USER_ROLE_TYPE.EL_ORG_ADMIN.toString();
                disabled = true;
            }
            else if (parseInt(entityTypeSelected) === USER_ROLE_TYPE.EL_ORG_VIEWER) {
                isNodeSelected = true;
                role = USER_ROLE_TYPE.EL_ORG_VIEWER.toString();
                disabled = false;
            }
            else {
                isNodeSelected = false;
                role = "";
                disabled = false;
            }
            if (tempTreeArray[orgTreeItem]) {
                tempTreeArray[orgTreeItem].selected = isNodeSelected;
                tempTreeArray[orgTreeItem].role = role;
            }
            let buildingdata = tempTreeArray[orgTreeItem]?.children || [] as RenderTree[];
            buildingdata = updateSelectProperty(buildingdata, isNodeSelected, role, disabled);
            tempTreeArray[orgTreeItem].children = buildingdata;
            setTreeDataUpdate(tempTreeArray);
            setTreeData(tempTreeArray);

        }
        // Building Admin and Viewer- child nodes isNodeSelected set
        else if (parseInt(data.entityType) === ENTITY_TYPE.BUILDING) {

            const tempTree = [...treeDataUpdate];
            tempTree.filter((item: RenderTree) => item.id === data.parentOrgId);

            const buildingTreeItem = treeDataUpdate.findIndex((item: RenderTree) => item.id == data.parentOrgId);
            const systemChildTreeItem = treeDataUpdate[buildingTreeItem]?.children?.findIndex((item) => item.id === data.itemId);
            if (parseInt(entityTypeSelected) === USER_ROLE_TYPE.EF_BLDG_ADMIN_ORG_VIEWER) {
                role = USER_ROLE_TYPE.EF_BLDG_ADMIN_ORG_VIEWER.toString();
                isNodeSelected = true;
                disabled = true;
            }
            else if (parseInt(entityTypeSelected) === USER_ROLE_TYPE.EL_BLDG_VIEWER) {
                isNodeSelected = true;
                disabled = false;
                role = USER_ROLE_TYPE.EL_BLDG_VIEWER.toString();
            }
            else {
                isNodeSelected = false;
                role = "";
                disabled = false;
            }
            if (tempTreeArray[buildingTreeItem] && tempTreeArray[buildingTreeItem].children && tempTreeArray[buildingTreeItem].children![systemChildTreeItem!]) {
                tempTreeArray[buildingTreeItem].children![systemChildTreeItem!].selected = isNodeSelected;
                tempTreeArray[buildingTreeItem].children![systemChildTreeItem!].role = role;
            }
            let childData = tempTreeArray[buildingTreeItem]?.children![systemChildTreeItem!].children || [] as RenderTree[];
            childData = updateSelectProperty(childData, isNodeSelected, role, disabled);
            if (tempTreeArray[buildingTreeItem]?.children![systemChildTreeItem!].children) {
                tempTreeArray[buildingTreeItem].children![systemChildTreeItem!].children = childData;
            }
            setTreeDataUpdate(tempTreeArray);
            setTreeData(tempTreeArray);
        }
        else if (parseInt(data.entityType) === ENTITY_TYPE.SYSTEM) {

            const orgTreeItemIndex = treeDataUpdate.findIndex((item: RenderTree) => item.id === data.parentOrgId);
            const buildingChildTreeItemIndex = treeDataUpdate[orgTreeItemIndex].children?.findIndex((item) => item.id === data.parentBuildingId);
            const systemChildTreeItemIndex = treeDataUpdate[orgTreeItemIndex].children![buildingChildTreeItemIndex!].children?.findIndex((item) => item.id === data.itemId);
            if (parseInt(entityTypeSelected) === USER_ROLE_TYPE.EF_SYSTEM_ADMIN_GATEWAY_VIEWER) {
                isNodeSelected = true;
                role = USER_ROLE_TYPE.EF_SYSTEM_ADMIN_GATEWAY_VIEWER.toString();
            }
            else if (parseInt(entityTypeSelected) === USER_ROLE_TYPE.EF_SYSTEM_VIEWER) {
                isNodeSelected = true;
                role = USER_ROLE_TYPE.EF_SYSTEM_VIEWER.toString();
            }
            else {
                isNodeSelected = false;
                role = '';
            }
            if (tempTreeArray[orgTreeItemIndex] && tempTreeArray[orgTreeItemIndex].children && tempTreeArray[orgTreeItemIndex].children![buildingChildTreeItemIndex!]
                && tempTreeArray[orgTreeItemIndex].children![buildingChildTreeItemIndex!].children && tempTreeArray[orgTreeItemIndex].children![buildingChildTreeItemIndex!].children![systemChildTreeItemIndex!]) {
                tempTreeArray[orgTreeItemIndex].children![buildingChildTreeItemIndex!].children![systemChildTreeItemIndex!].selected = isNodeSelected;
                tempTreeArray[orgTreeItemIndex].children![buildingChildTreeItemIndex!].children![systemChildTreeItemIndex!].role = role;
            }
            setTreeDataUpdate(tempTreeArray);
            setTreeData(tempTreeArray);
        }

        //Send data to parent
        if (props.onItemSelected)
            props.onItemSelected(data, tempTreeArray);
    };

    const createTree = (data: EntityAccessDetailEntity[] | undefined): void => {
        if (data !== undefined) {
            const tree =
                data.map((itemsListOrg: EntityAccessDetailEntity, index: number) => {
                    let objOrg: RenderTree = {
                        id: "",
                        label: "",
                        children: [] as RenderTree[],
                        selected: false,
                        index: 0,
                        parentId: '',
                        entityType: '',
                    }
                    objOrg.id = itemsListOrg.organizationId;
                    objOrg.label = itemsListOrg.organizationName + '#' + itemsListOrg.buildingCount + ' ' + ((itemsListOrg.buildingCount > 1) ? t('ASSETS_DETAILS.BUILDINGS') : t('ENTITY_TYPE.BUILDING')) + '#' + ENTITY_TYPE.ORGANIZATION + '#' + index;
                    objOrg.index = index;
                    objOrg.parentId = '';
                    objOrg.entityType = ENTITY_TYPE.ORGANIZATION.toString();
                    objOrg.children = itemsListOrg.buildings ? itemsListOrg.buildings.map((itemsListBuilding, index) => {
                        let childObjBuilding: RenderTree = {
                            id: "",
                            label: "",
                            children: [] as RenderTree[],
                            selected: false,
                            index: 0,
                            parentId: '',
                            entityType: '',
                            disabled: false
                        }
                        childObjBuilding.id = itemsListBuilding.buildingId;
                        childObjBuilding.label = itemsListBuilding.buildingName + '#' + itemsListBuilding.systemCount + ' ' + ((itemsListBuilding.systemCount > 1) ? t('DEVICE_DETAILS.SYSTEMS') : t('ENTITY_TYPE.SYSTEM')) + '#' + ENTITY_TYPE.BUILDING + '#' + objOrg.id;
                        childObjBuilding.index = index;
                        childObjBuilding.parentId = objOrg.id;
                        childObjBuilding.entityType = ENTITY_TYPE.BUILDING.toString();
                        childObjBuilding.children = itemsListBuilding.systems ? itemsListBuilding.systems.map((objsystem, index) => {
                            let childObjSystem: RenderTree = {
                                id: "",
                                label: "",
                                children: [] as RenderTree[],
                                selected: false,
                                index: 0,
                                parentId: '',
                                entityType: '',
                                disabled: false,
                            }
                            childObjSystem.id = objsystem.systemId;
                            childObjSystem.label = "\"" + objsystem.systemName + "\", " + getDeviceType(objsystem.systemType) + '#' + objsystem.luminaryCount + ' ' + ((objsystem.luminaryCount > 1) ? t('DEVICE_DETAILS.LUMINAIRES') : t('DEVICE_DETAILS.LUMINAIRE')) + '#' + ENTITY_TYPE.SYSTEM + '#' + objOrg.id + '#' + childObjBuilding.id;
                            childObjSystem.index = index;
                            childObjSystem.parentId = childObjBuilding.id;
                            childObjSystem.entityType = ENTITY_TYPE.SYSTEM.toString();
                            return childObjSystem;
                        }) : []
                        return childObjBuilding;
                    }) : []
                    return objOrg;
                })
            setTreeData(tree);
            setTreeDataUpdate(tree);
        }
    };

    const onNodeSelected = () => {
        //ToDo- Node selection manupilation can be done here
    }

    const CustomTreeRender = (data: RenderTree) => {
        const isChildren = data.children !== null;
        if (isChildren) {
            return (
                <CustomTreeItem key={data.id} itemId={data.id} label={data.label + '$' + data.role + '$' + data.disabled} ContentProps={{ getNodeDetails }} tabIndex={data.index} onSelect={onNodeSelected} itemProp={data.role} >
                    {data.children?.map((items, idx) => items ? CustomTreeRender(items) : '')}
                </CustomTreeItem>
            );
        }
        return <CustomTreeItem key={data.id} itemId={data.id} label={data.label} ContentProps={{ getNodeDetails }} tabIndex={data.index} onSelect={onNodeSelected} />;
    };

    const [selectedItems, setSelectedItems] = React.useState<string[]>([]);
    const toggledItemRef = React.useRef<{ [itemId: string]: boolean }>({});
    const handleItemSelectionToggle = (
        event: React.SyntheticEvent,
        itemId: string,
        isSelected: boolean,
    ) => {
        toggledItemRef.current[itemId] = isSelected;
    };

    const handleSelectedItemsChange = (
        event: React.SyntheticEvent,
        newSelectedItems: string[],
    ) => {
        setSelectedItems(newSelectedItems);
    };

    return (
        <div data-testid="advance-page-container"
            style={{ overflow: 'scroll' }}
        >
            <DialogContent>
                <div>
                    <IconButton style={{
                        textAlign: 'right',
                        float: 'right',
                        margin: '8px 16px'
                    }}
                        data-testid="close-icon"
                        onClick={() => onCancelAdvanceInviteCall()}>
                        <Close style={{ width: 24, height: 24, }} fontSize="small" />
                    </IconButton>
                    <DialogTitle data-testid="invite-user-dialog-header-2" id="invite-user-dialog-header-2" sx={{ padding: '32px 24px 0px 24px', mb: 2, alignSelf: "stretch" }}>{props.emailAdvance}</DialogTitle>
                    <Typography
                        id="invite-user-dialog-header-subtitle-2"
                        data-testid="invite-user-dialog-header-subtitle-2"
                        style={{ alignSelf: 'left', color: COLOR_GRAY, fontSize: 16, paddingLeft: 24, paddingBottom: 8 }} variant="body2">
                        {t('MANAGE_USERS.ADVANCED_INVITE_PLEASE_ASSIGN_ROLES', { replace: { email: props.emailAdvance } })}
                    </Typography>
                    <Typography component="div">
                        <ul>
                            <li >
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Typography
                                        id="invite-user-dialog-admin"
                                        data-testid="invite-user-dialog-admin"
                                        style={{ alignSelf: 'left', color: COLOR_GRAY, fontWeight: 700, fontSize: 14, paddingLeft: 8, paddingBottom: 16 }} variant="body2">
                                        {t('MANAGE_USERS.ADVANCED_INVITE_ADMIN')}
                                    </Typography>
                                    <Typography
                                        id="invite-user-dialog-admin-subtitle"
                                        data-testid="invite-user-dialog-admin-subtitle"
                                        style={{ alignSelf: 'left', color: COLOR_GRAY, fontSize: 14, paddingLeft: 8, paddingBottom: 16 }} variant="body2">
                                        {t('MANAGE_USERS.ADVANCED_INVITE_ADMIN_DESCRIPTION')}
                                    </Typography>
                                </div>
                            </li>
                            <li> <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography
                                    id="invite-user-dialog-viewer"
                                    data-testid="invite-user-dialog-viewer"
                                    style={{ alignSelf: 'left', color: COLOR_GRAY, fontWeight: 700, fontSize: 14, paddingLeft: 8, paddingBottom: 16 }} variant="body2">
                                    {t('MANAGE_USERS.ADVANCED_INVITE_VIEWER')}
                                </Typography>
                                <Typography
                                    id="invite-user-dialog-viewer-subtitle"
                                    data-testid="invite-user-dialog-viewer-subtitle"
                                    style={{ alignSelf: 'left', color: COLOR_GRAY, fontSize: 14, paddingLeft: 8, paddingBottom: 16 }} variant="body2">
                                    {t('MANAGE_USERS.ADVANCED_INVITE_VIEWER_DESCRIPTION')}
                                </Typography>
                            </div></li>
                            <li> <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography
                                    id="invite-user-dialog-limited"
                                    data-testid="invite-user-dialog-limited"
                                    style={{ color: COLOR_GRAY, fontWeight: 700, fontSize: 14, paddingLeft: 8, }} variant="body2">
                                    {t('MANAGE_USERS.ADVANCED_INVITE_LIMITED')}
                                </Typography>
                                <Typography
                                    id="invite-user-dialog-limited-subtitle"
                                    data-testid="invite-user-dialog-limited-subtitle"
                                    style={{ alignSelf: 'left', color: COLOR_GRAY, fontSize: 14, paddingLeft: 8, paddingRight: 24 }} variant="body2">
                                    {t('MANAGE_USERS.ADVANCED_INVITE_LIMITED_DESCRIPTION')}
                                </Typography>
                            </div></li>
                        </ul>
                    </Typography>
                </div>
                <div style={{ padding: 24, paddingTop: 8, paddingBottom: 8, }}>
                    <Box data-testid="organization-hierarchy-container" id="organization-hierarchy-container"
                        sx={{
                            maxHeight: '60%', flexGrow: 1, maxWidth: '100%', overflowY: 'auto',
                            padding: 2, backgroundColor: PXBColors.white[200],
                        }}
                        px={2}>
                        {!props.isAdvanceTreeDataAvailable && isDataAvailable && !usersSelector.inviteUserLoading && usersSelector.inviteUserSuccess  && <Typography
                            id="no-data"
                            data-testid="no-data"
                            style={{ fontSize: 16, alignContent: 'center' }} variant="body2">
                            {t('DASHBOARD.NO_DATA')}
                        </Typography>}
                        {usersSelector.getuserAdvanceAccessLoading && <EmptyState style={{ flex: 1, height: '100%', backgroundColor: PXBColors.white[50] }}
                            icon={<CircularProgress id="progress-spinner" />} title={t('MESSAGES.LOADING')} placeholder={undefined} />}

                        {props.isAdvanceTreeDataAvailable && <SimpleTreeView
                            multiSelect
                            selectedItems={selectedItems}
                            apiRef={apiRef}
                            onItemSelectionToggle={handleItemSelectionToggle}
                            onSelectedItemsChange={handleSelectedItemsChange}
                            data-testid="advance-tree-view"
                            aria-label="icon expansion"
                            slots={{ expandIcon: ChevronRightIcon, collapseIcon: ExpandMoreIcon }}
                            sx={{ minHeight: 200, flexGrow: 1, maxWidth: '100%', position: 'relative' }}
                        >
                            {treeData.map((item) => CustomTreeRender(item))}
                        </SimpleTreeView>}
                    </Box>
                </div>
            </DialogContent>
        </div >

    )
}
