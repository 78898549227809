import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { GET_MAINTENANCE_ACTIVITY } from './url-constants';

type MaintenanceDetailEndpointSpecification = ApiEndpointSpecification;
export const MaintenanceDetailEndpointSpecifications: MaintenanceDetailEndpointSpecification = {
    url: GET_MAINTENANCE_ACTIVITY,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'MaintenanceDetailRequestParams',
    requestBodySchemaName: 'MaintenanceDetailRequestBody',
    okResponseSchemaName: 'MaintenanceDetailRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeMaintenanceDetail = Helpers.makeApiRequestFn<
    Types.GetMaintenanceDetailRequestParams,
    Types.GetMaintenanceDetailRequestBody,
    Types.GetMaintenanceDetailOkResponse
>(MaintenanceDetailEndpointSpecifications);
