// eslint-disable-next-line
import React from 'react';
import * as PXBColors from '@brightlayer-ui/colors';
import { Typography, IconButton, ListItemText, Divider } from '@mui/material';
import * as MuiIcons from '@mui/icons-material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { TooltipItem } from '../TooltipItem/TooltipItem';
import {  USER_ROLE_TYPE, getIssueTypeListKey, getMaintainenceRequestPriorityListKey, getServiceDateStatusState, ticketStatus } from '../../app/enum/ENUM';
import { vw_10 } from '../../constants/dimentions';
import { GenericStatusBorderLabel } from '../GenericStatusLabel/GenericStatusBorderLabel';
import { Maintenance } from '@brightlayer-ui/icons-mui';
import { COLOR_GRAY_ICON } from '../../constants/color-codes';
import { getUserRoleType, useAuthState } from '../AuthContextProvider';
import { InfoListItem } from '@brightlayer-ui/react-components/core';
import { getControlledText } from '../../app/enum/GenericFormat';
import { MaintenanceDetail } from '../../types/maintenance';

import { AlarmBadgeStatus } from '../AlarmBadge/AlarmBadge';
import { daysBetweentwoDates, getShortDate } from '../../app/enum/DateTimeFormat';

type Props = {
    value: MaintenanceDetail;
    OnTicketItemSelected: Function;
};

const DateTime: React.FC<React.PropsWithChildren<React.PropsWithChildren<{ dateTime: string }>>> = (props) => {
    const languageLocale = useLanguageLocale();

    return (
        <div style={{ marginRight: '10px' }}>
            <Typography variant="caption" display="block" style={{ whiteSpace: 'pre' }}>
                {languageLocale.formatTimeShort(props.dateTime)}
            </Typography>
            <Typography variant="caption" display="block">
                {languageLocale.formatDateShort(props.dateTime)}
            </Typography>
        </div>
    );
};
export const MaintenanceServiceLogListItem: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const ticketStatusNumber = props.value.ticketStatus !== 0 ? props.value.ticketStatus - 1 : props.value.ticketStatus;
    const ticketStatusName = ticketStatus[ticketStatusNumber].name !== null ? ticketStatus[ticketStatusNumber].name : 'MAINTENANCE_DETAILS.STATUS_SCHEDULED';
    const ticketStatusState = getServiceDateStatusState(ticketStatusName).statusTitle === 'MAINTENANCE_DETAILS.STATUS_SCHEDULED' ? true : false;

    const logRootText = props.value.organizationName + ' > ' + props.value.buildingName + ' > ' + props.value.systemName;
    const authState = useAuthState();
    const usertype = getUserRoleType(authState) || '';

    const OnTicketItemSelected = (maintenanceId: string): void => {
        props.OnTicketItemSelected(maintenanceId);
    };

    const logDate: string = props.value.serviceDate || '';
    const isDisplayStartDate: boolean = logDate.includes('T');
    const logStartDate: string[] = isDisplayStartDate ? logDate.split('T') : [];

    const requestPriorityValue = getMaintainenceRequestPriorityListKey(props.value.requestPriority.toString());
    const requestPriorityText = (props.value.requestPriority.toString() !== '' && props.value.requestPriority !== undefined) ? requestPriorityValue.value : "";

    const issueTypeValue = getIssueTypeListKey(props.value.serviceType.toString());
    const issueTypeText = (props.value.serviceType.toString() !== '' && props.value.serviceType !== undefined) ? issueTypeValue.value : "";

    const ticketStatusColor = (date: any) => {
        const startDate = getShortDate(new Date().toString());
        const endDate = getShortDate(new Date(date).toString());
        const differenceInDays = daysBetweentwoDates(startDate, endDate);
        return (differenceInDays <= 7 && differenceInDays >= 0) ? true : false;
    }

    const getScheduledDaysFromDate = (date: any) => {
        const startDate = getShortDate(new Date().toString());
        const endDate = getShortDate(new Date(date).toString());
        const differenceInDays = daysBetweentwoDates(startDate, endDate);
        return (differenceInDays <= 40 && differenceInDays >= 0) ? (differenceInDays === 0 ? ('MAINTENANCE_DETAILS.TODAY') : t('MAINTENANCE_DETAILS.IN_DUE', { replace: { differenceInDays: differenceInDays } })) : differenceInDays > 40 ? ('MAINTENANCE_DETAILS.SCHEDULED') : ('MAINTENANCE_DETAILS.PAST_DUE')
    }

    return (
        <div data-testid="system-log-id">
            <InfoListItem
                id="system-log-list-item"
                style={{ height: 'auto', flexFlow: 'row wrap' }}
                translate="no"
                avatar={false}
                statusColor={PXBColors.white[50]}
                title=""
                backgroundColor={PXBColors.white[50]}
                icon={<Maintenance style={{ color: COLOR_GRAY_ICON }}></Maintenance>}
                onClick={(usertype !== USER_ROLE_TYPE.SERVICE_MANAGER) ?
                    () => OnTicketItemSelected(props.value.id) : () => {
                        OnTicketItemSelected(props.value.id);
                     }}
                leftComponent={<>
                    <div data-testid='date-time-logs'>
                        {!isDisplayStartDate && <DateTime dateTime={props.value.serviceDate} />}
                        {isDisplayStartDate &&
                            <div style={{ marginRight: vw_10 }}>
                                <Typography variant="caption" data-testid="date-time-logs-date" display="block" style={{ whiteSpace: 'pre' }}>
                                    {logStartDate[0]}
                                </Typography>
                                <Typography variant="caption" data-testid="date-time-logs-days" display="block">
                                    {ticketStatusColor(props.value.serviceDate) ?
                                        <AlarmBadgeStatus
                                            statusName={ticketStatusState ? t(getScheduledDaysFromDate(props.value.serviceDate)) : t(getServiceDateStatusState(ticketStatusName).statusTitle)}
                                            statusColor={PXBColors.gold[500]}
                                            foregroundColor={ticketStatusState ? getServiceDateStatusState(getScheduledDaysFromDate(props.value.serviceDate)).foregroundColor : getServiceDateStatusState(ticketStatusName).foregroundColor} alarmStatus={''} />
                                        : ticketStatusState ? t(getScheduledDaysFromDate(props.value.serviceDate)) : t(getServiceDateStatusState(ticketStatusName).statusTitle)}
                                </Typography>
                            </div>}
                    </div>
                    <ListItemText>
                        <Typography data-testid="alarmDescription"
                            display="inline"
                            id="system-log-list-item-name"
                            style={{ fontWeight: 600, fontSize: 16 }}
                        >
                            {t(issueTypeText)}: {' '}
                        </Typography>{' '}
                        <Typography data-testid="logs-title"
                            variant="body1"
                            display="inline"
                            id="system-log-list-item-title"
                            style={{
                                lineHeight: 1.25,
                                fontSize: 16,
                            }}
                        >
                            {t(requestPriorityText)}
                        </Typography>
                        <Typography
                            data-testid="system-log-list-item-description"
                            id="system-log-list-item-description"
                            variant="body2"
                            display="block"
                            sx={{
                                lineHeight: 1.25,
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                                wordWrap: 'break-word'
                            }}
                            style={{
                                fontSize: 14,
                                fontWeight: 400
                            }}
                        >
                            {getControlledText(props.value.description, 50)}
                        </Typography>
                        <TooltipItem
                            title={logRootText}
                            isTopPlacement={false}
                            lenghtLimit={100}
                            component={<Typography
                                data-testid="path"
                                id="system-log-list-item-log-root"
                                variant="body2"
                                display="block"
                                sx={{
                                    lineHeight: 1.25,
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: 1,
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden',
                                    wordWrap: 'break-word'
                                }}
                                style={{
                                    fontSize: 14,
                                    marginTop: 5,
                                    marginBottom: 5,
                                    fontWeight: 300
                                }}
                            >
                                {getControlledText(logRootText, 100)}
                            </Typography>}>
                        </TooltipItem>
                    </ListItemText>
                </>}
                rightComponent={<div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <GenericStatusBorderLabel label={t(getServiceDateStatusState(props.value.ticketStatus.toString()).statusTitle).toUpperCase()}></GenericStatusBorderLabel>
                    <IconButton
                        data-testid="next-button-ticker"
                    >
                        <MuiIcons.ChevronRight id="maintenance-log-list-item-next-icon" />
                    </IconButton> 
                    
                </div>} placeholder={undefined} />
            <Divider style={{ marginLeft: 72 }} />
        </div>
    );
};
