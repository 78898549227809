// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
import { CircularProgress, Card, Typography, ListSubheader, Divider, Box, useTheme, useMediaQuery, Snackbar, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import List from '@mui/material/List';
import { EmptyState } from '@brightlayer-ui/react-components';
import { UserList } from '../../types/manage-users';
import { AssetUserListItem } from '../../components/ManageUsers/AssetUserListItem';
import { USER_ROLE_TYPE, userRoles } from '../enum/ENUM';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { getUserId, getUserRoleType, useAuthState } from '../../components/AuthContextProvider';
import * as PXBColors from '@brightlayer-ui/colors';
import { vh_18, vh_65, vh_900, vw_300 } from '../../constants/dimentions';
import { ManageUserDetails } from './ManageUserDetails';
import { UsersActions } from '../../actions/actions/users-actions';

type Props = {
    onClick: Function;
    entityName: string;
    entityType: number;
};

export const AssetUserList: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const authState = useAuthState();
    const dispatch = useDispatch();
    const usertype = getUserRoleType(authState) || '';
    const usersSelector = useSelector(Selectors.Users.users);
    const [userList, setUserList] = useState<UserList>([]);
    const [emptyUserList, setEmptyUserList] = React.useState(false);
    const userId = getUserId(authState) || '';
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const [userDetailDrawer, setUserDetailDrawer] = React.useState(false);
    const [selectedUserId, setSelectedUserId] = React.useState('');
    const [selectedRole, setSelectedRole] = React.useState('');
    const [selectedEntityId, setSelectedEntityId] = React.useState('');
    const [alertErrorMessage, setAlertErrorMessage] = useState<string>('');
    const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
    const [alertSuccessMessage, setAlertSuccessMessage] = useState<string>('');
    const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false);
    //ToDo - To update user role
    //const updateUserRole = (userId: string, role: string): void => {};

    useEffect(() => {
        if (!usersSelector.userListLoading && usersSelector.userListSuccess) {
            setEmptyUserList(usersSelector.userList.usersList.length <= 0)
            setUserList(usersSelector.userList.usersList);
        } else if (!usersSelector.userListLoading && usersSelector.userListFail) {
            setEmptyUserList(true);
            console.log('User list loading failed');
        }
    }, [usersSelector.userListLoading, usersSelector.userListSuccess, usersSelector.userListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    const myDetails = userList.filter((obj) => {
        return obj.userId === userId;
    });

    const otherUserDetailList = userList.filter((obj) => {
        return obj.userId !== userId;
    });
    const userDetailDrawerClose = (): void => {
        setUserDetailDrawer(false);
        setSelectedUserId('');
    };

    const goToUserDetails = (userId: string, emailId: string, role: string, entityId?: string): void => {
        setSelectedUserId(userId);
        setSelectedRole(role);
        entityId?setSelectedEntityId(entityId):'';
        props.onClick({ userId: userId, emailId: emailId, role: role, entityId: entityId });
        setUserDetailDrawer(true);
    };

    return (
        <div style={{ flexFlow: 'row-wrap' }}>
            <Card style={{ flex: '1 1 0px', overflow: 'auto' }}
                id="user-list-container"
                data-testid="user-list-container">
                {usersSelector.userListLoading && <EmptyState style={{ flex: 1, minHeight: 400 }}
                    icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}
                {!usersSelector.userListLoading && emptyUserList && <EmptyState style={{ flex: 1, height: 200 }}
                    title={t('ORGANIZATION_MANAGE.ERROR_NO_USER_FOUND')} icon={undefined} placeholder={undefined} />}

                {!usersSelector.userListLoading && !emptyUserList && <List component="div" data-testid="myDetails-data-list" id="myDetails-user-list"

                    subheader={
                        <AssetUserListHeader
                            header={t('MANAGE_USERS.MY_DETAILS_HEADER')}
                        />
                    }
                >
                    {myDetails?.map((data, user) => {
                        return (
                            <AssetUserListItem
                                key={'user-key-' + data.userId}
                                id={data.userId}
                                emailId={data.emailId}
                                data-testid="asset-list-item"
                                title={data.firstName + ' ' + data.lastName}
                                subtitle={data.emailId}
                                role={data.role}
                                userStatus={data.userStatus}
                                lastSeen={data.lastSeen}
                                OnItemSelected={(id: string, name: string, emailId: string, role: string, entityId: string): void => goToUserDetails(id, emailId, role)}
                                userRoles={userRoles} hideDivider={false}                            ></AssetUserListItem>
                        );
                    })}
                </List>}
            </Card>
            {!usersSelector.userListLoading && !emptyUserList && <List component="div" disablePadding data-testid="otherUser-data-list" id="other-user-list"
                style={{ marginTop: 20 }}
                subheader={
                    <AssetUserListHeader
                        header={t('MANAGE_USERS.OTHER_USER_LIST_HEADER') + '(' + otherUserDetailList.length + ')'}
                    />
                }
            >
                {otherUserDetailList?.map((data, user) => {
                    return (
                        <AssetUserListItem
                            key={'user-key-' + data.userId}
                            id={data.userId}
                            emailId={data.emailId}
                            data-testid="asset-list-item"
                            title={data.firstName + ' ' + data.lastName}
                            subtitle={data.emailId}
                            role={data.role}
                            userStatus={data.userStatus}
                            lastSeen={data.lastSeen}
                            OnItemSelected={(id: string, name: string, emailId: string, role: string, userStatus: number, lastSeen: string): void => goToUserDetails(id, emailId, role, data.id)}
                            userRoles={userRoles} hideDivider={true}>
                        </AssetUserListItem>
                    );
                })}
            </List>}

            {<Box
                display="flex"
                style={{
                    flexDirection: 'column',
                    top: vh_65,
                    justifyContent: 'flex-end',
                    right: vh_18,
                    zIndex: 100,
                    minHeight: vh_900,
                    backgroundColor: PXBColors.white[500],
                    position: 'fixed',
                    transitionDelay: '0.2s',
                    visibility: userDetailDrawer ? 'visible' : 'hidden',
                }}
                data-testid="userDetails"
            >
                <ManageUserDetails
                    closeClick={userDetailDrawerClose} otherUserId={selectedUserId} loggedInUserData={myDetails} userRole={selectedRole} entityId={selectedEntityId} entityName= {props.entityName} entityType= {props.entityType}/>
            </Box>
            }
        </div>
    );
};
export const AssetUserListHeader: React.FC<React.PropsWithChildren<React.PropsWithChildren<{ header: string }>>> = (props) => {
    return (
        <>
            <ListSubheader
                color="primary"
                component="div"
                data-testid="myDetails-list-header"
                id="myDetails-list-header"
                style={{
                    display: 'flex',
                    fontWeight: 600,
                    fontSize: 14,
                    alignItems: 'center',
                    overflow: 'auto',
                    whiteSpace: 'nowrap',
                    height: 45,
                }}
            >
                <Typography
                    variant="body2"
                    style={{
                        alignItems: 'left',
                        fontSize: 14,
                        fontWeight: 600,
                        color: PXBColors.blue[400],
                        width: '92.3%',
                        paddingLeft: '10',
                    }}
                    id="my-detail-title"
                    data-testid="my-detail-title"
                >
                    {props.header}
                </Typography>
            </ListSubheader>

            <Divider />
        </>
    );
};

