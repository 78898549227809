import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { UPDTAE_MAINTENANCE_ACTIVITY } from './url-constants';

type UpdateMaintenanceEndpointSpecifications = ApiEndpointSpecification;
export const UpdateMaintainenceEndpointSpecification: UpdateMaintenanceEndpointSpecifications = {
    url: UPDTAE_MAINTENANCE_ACTIVITY,
    method: HttpMethod.PUT,
    requestParamsSchemaName: 'UpdateMaintenanceRequestParams',
    requestBodySchemaName: 'UpdateMaintenanceRequestBody',
    okResponseSchemaName: 'UpdateMaintenanceRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeUpdateMaintenanceRequest = Helpers.makeApiRequestFn<
    Types.UpdateMaintenanceRequestParams,
    Types.UpdateMaintenanceRequestBody,
    Types.UpdateMaintenanceOkResponse
>(UpdateMaintainenceEndpointSpecification);
