// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { GenericToolbar } from '../../components/GenericToolbar';
import { useHistory } from 'react-router-dom';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { ToolbarColor } from '../../components/GenericToolbar/GenericToolbar';
import { AssetUserList } from './AssetUserList';
import { InviteUser } from './InviteUser';
import { useDispatch, useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import { Thunks } from '../../actions';
import { SEPERATOR_COMMA } from '../../constants';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { pushAppRoute } from '../../data/domain/route-manager';

type Props = {
    location: any;
};

export const ManageAssetUsers: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const history = useHistory();
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const { t } = useLanguageLocale();
    const dispatch = useDispatch();
    const usersSelector = useSelector(Selectors.Users.users);
    const [subTitle, setSubTitle] = useState('');

    const { backRoute, assetId, assetType, title, orgId, buildingId, gateWayId, sourcePath } = props.location.state;

    const goToUserDetails = (value: { userId: string }): void => {
    };

    useEffect(() => {
        dispatch(Thunks.Users.getOrgUserList(assetId, assetType));
    }, [assetId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(Thunks.Users.getOrgUserList(assetId, assetType));
    }, [usersSelector.removeAccessSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!usersSelector.userListLoading && usersSelector.userListSuccess) {
            updateSubTitle(usersSelector.userList.AdminCount, usersSelector.userList.viewersCount);
        } else if (!usersSelector.userListLoading && usersSelector.userListFail) {
            console.log('User list loading failed');
        }
    }, [usersSelector.userListLoading, usersSelector.userListSuccess, usersSelector.userListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    const updateSubTitle = (adminCount: number, viewerCount: number): void => {
        const adminsText = adminCount > 0 ? (adminCount > 1 ? adminCount + ' ' + t('DASHBOARD.USERS_ADMIN_S') : adminCount + ' ' + t('DASHBOARD.USERS_ADMIN')) : '';
        const seperatorText = viewerCount > 0 ? SEPERATOR_COMMA : '';
        const viewersText = viewerCount > 0 ? (viewerCount > 1 ? viewerCount + ' ' + t('DASHBOARD.USERS_VIEWER_S') : viewerCount + ' ' + t('DASHBOARD.USERS_VIEWER')) : '';
        setSubTitle(adminsText + seperatorText + viewersText)
    }

    return (
        <div data-testid="organizations-details-container">
            <GenericToolbar
                title={t('MANAGE_USERS.USERS_IN_HEADER') + '"' + title + '"'}
                subtitle={subTitle}
                showBackButton={true}
                backButtonOnClick={(): void => {
                    if (backRoute === 'OrganizationDetails') {
                        pushAppRoute(history,
                            { type: 'OrganizationDetails', orgId: assetId },
                            { backRoute: 'Organizations', orgId: assetId });
                    } else if (backRoute === 'OrgBuildingDetails') {
                        pushAppRoute(history,
                            { type: 'OrgBuildingDetails', buildingId: assetId },
                            { backRoute: 'OrganizationDetails', orgId: orgId, buildingId: buildingId });
                    } else if (backRoute === 'AssetDetails') {
                        pushAppRoute(history,
                            { type: 'AssetDetails', deviceId: assetId, gatewayId: gateWayId },
                            { backRoute: sourcePath, deviceId: assetId, buildingId: buildingId, projectId: orgId, gatewayId: gateWayId, sourcePath: sourcePath });
                    } else if (backRoute === 'Dashboard') {
                        pushAppRoute(history,
                            { type: 'Dashboard' });
                    } else if (backRoute === 'OverviewBuildingDetails') {
                        pushAppRoute(history,
                            { type: 'OverviewBuildingDetails' },
                            { backRoute: 'Dashboard', buildingId: assetId, projectId: orgId });
                    } else if (backRoute === 'ManageUsers') {
                        pushAppRoute(history, {
                            type: backRoute,
                        });
                    }
                }}
                color={ToolbarColor.Default}
            />

            <div style={{ flex: '1 1 0px', overflow: 'auto', marginTop: 20, marginLeft: 'auto', marginRight: 'auto', maxWidth: md ? '70%' : '95%' }}>

                <div style={{ display: 'flex', minHeight: 200 }}>
                    <InviteUser
                        entityId={assetId}
                        entityName={title}
                        entityType={assetType} />
                </div>

                <div style={{ minHeight: 200, marginTop: 20 }}>
                    <AssetUserList
                        onClick={goToUserDetails}
                        entityName={title}
                        entityType={assetType}                    />
                </div>
            </div>
        </div >
    );
};
