// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { GenericToolbar } from '../../components/GenericToolbar';
import { useHistory } from 'react-router-dom';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { ToolbarColor } from '../../components/GenericToolbar/GenericToolbar';
import { BuildingInfo } from './BuildingInfo';
import { BuildingMap } from './BuildingMap';
import { useDispatch, useSelector } from 'react-redux';
import { Thunks } from '../../actions';
import { Selectors } from '../../selectors';
import { ENTITY_TYPE } from '../enum/ENUM';
import { Button, Snackbar, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { OrganizationActions } from '../../actions/actions/organization-actions';
import * as PXBColors from '@brightlayer-ui/colors';
import { pushAppRoute } from '../../data/domain/route-manager';

type Props = {
    location: any;
};

export const OrgBuildingDetails: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useLanguageLocale();
    const [buildingName, setBuildingName] = useState('');
    const { backRoute, orgId, buildingId } = props.location.state;
    const organization = useSelector(Selectors.Organization.organization);
    const [latitude, setLatitude] = useState(0.0);
    const [longitude, setLongitude] = useState(0.0);

    useEffect(() => {
        dispatch(Thunks.Organization.getBuildingDetailsList('', buildingId));
    }, [buildingId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!organization.buildingListLoading && organization.buildingListSuccess) {
            setLatitude(parseFloat(organization.buildingList[0].latitude));
            setLongitude(parseFloat(organization.buildingList[0].longitude));
        } else if (!organization.buildingListLoading && organization.buildingListFail) {
            setLatitude(0.0);
            setLongitude(0.0);
        }
    }, [organization.buildingListLoading, organization.buildingListSuccess, organization.buildingListFail]); // eslint-disable-line react-hooks/exhaustive-deps


    const goToOrgnizationDetails = (value: { orgId: string }): void => {
        pushAppRoute(history,
            { type: 'OrganizationDetails', orgId: value.orgId },
            { backRoute: 'Organizations', orgId: value.orgId });
    };

    const goToManageUsers = (): void => {
        pushAppRoute(history,
            { type: 'ManageAssetUsers', assetId: buildingId },
            { backRoute: 'OrgBuildingDetails', assetId: buildingId, assetType: ENTITY_TYPE.BUILDING, title: buildingName, orgId: orgId, buildingId: buildingId });
    };

    return (
        <div data-testid="building-details-container">
            <GenericToolbar
                title={buildingName}
                subtitle={t('ORGANIZATION_DETAILS.BUILDING_DETAILS')}
                showBackButton={true}
                backButtonOnClick={(): void => {
                    if (backRoute === "Dashboard") {
                        history.goBack();
                    }
                    else {
                        goToOrgnizationDetails({ orgId: orgId })
                    }
                }}
                color={ToolbarColor.Default}
            />

            <div data-testid="container-building-details" style={{ display: md ? 'flex' : '', marginTop: 40 }}>

                <div style={{ display: 'flex', minHeight: 700, width: md ? '40%' : '', marginLeft: md ? 50 : 10, marginRight: md ? 0 : 10 }}>
                    <BuildingInfo
                        nameUpdate={(buildingName: string): void => setBuildingName(buildingName)}
                        onClick={goToOrgnizationDetails}
                        onUserClick={goToManageUsers}
                        buildingId={buildingId}
                        orgId={orgId} />
                </div>

                <div style={{ display: 'flex', minHeight: 700, width: md ? '60%' : '', marginLeft: md ? 50 : 10, marginRight: md ? 50 : 10 }}>
                    <BuildingMap buildingId={buildingId} isUpsertBuilding={false} latitude={latitude} longitude={longitude} />
                </div>
            </div>
        </div>
    );
};
