// eslint-disable-next-line
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { Button, Checkbox, Divider, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Snackbar, TextField, Typography } from '@mui/material';
import * as PXBColors from '@brightlayer-ui/colors';
import { Country, CountryList } from '../../state/registration-state';
import { ActionType, ALPHA_NUMBERIC, COMPANY_ADDRESS, COMPANY_ADDRESS_MAX_LENGTH, COMPANY_CITY, COMPANY_CITY_MAX_LENGTH, COMPANY_POSTALCODE_MAX_LENGTH, COUNTRY_CODE_MAX_LENGTH, CUSTOMER_NUMBER_LIMIT, CUSTOMER_SLA_LIMIT, FIRST_NAME_MAX_LENGTH, LAST_NAME_MAX_LENGTH, NAME_VALIDATION, ORGANIZATTION_NAME, ORG_CHARACTER_LIMIT, ORG_COMMENTS_LIMIT, ORG_EMAIL_MAX_LENGTH, PHONE_MAX_LENGTH, PHONE_MIN_LENGTH, PHONE_NUMBER } from '../../constants';
import { GenericToolbar } from '../../components/GenericToolbar';
import { ToolbarColor } from '../../components/GenericToolbar/GenericToolbar';
import { validEmail, validPhoneNumberLength, validPostalCode, validSlaNumber,validExpirationDate } from '../../lib/form-validator';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import { vh_26, vw_133, vw_90 } from '../../constants/dimentions';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useDispatch, useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import { Thunks } from '../../actions';
import { CustomerLogo } from './CustomerLogo';
import { contactTypeSelectionList, contactUpdateList, getContactTypeNumber, getcontactUpdateList } from '../enum/ENUM';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DeviceCustomerDetail } from '../../types/customer-detail';
import { customerData } from '../../state/assets-state';
import { useToasts } from '../../data/DataProviders/ToastProvider';
import { useParams } from 'react-router';
import moment from 'moment';
import { UserManagementActions } from '../../actions/actions/usermanagement-actions';
import { defaultServicePartnerDetail } from '../../state/usermanagement-state';
import { servicePartnerDetail } from '../../types/add-ServicePartnerCustomer';
import { formatDate, formatDateTime } from '../../lib/date-time-formatter';
import { INVALID_DATE, getDate } from '../enum/DateTimeFormat';

type Props = {
    location: any;
};

export const CreateServicePartner: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const { t } = useLanguageLocale();
    // @ts-ignore
    const { actionType = ActionType.ADD } = useParams();
    const { addToast } = useToasts();
    const userManagement = useSelector(Selectors.Usermanagement.usermanagement);
    const organization = useSelector(Selectors.Organization.organization);
    const [servicePartnerDetail, setServicePartnerDetail] = useState<servicePartnerDetail>(defaultServicePartnerDetail);
    const [appBarColor, setAppBarColor] = useState(ToolbarColor.Default);
    const [isInternalTeam, setIsInternal] = useState(true)
    const [customerName, setCustomerName] = useState('');
    const [customerNumber, setCustomerNumber] = useState('');
    const [slaNumber, setSlaNumber] = useState('');
    const [customerComments, setCustomerComments] = React.useState('');
    const [primaryComments, setPrimaryComments] = React.useState('');
    const [secondaryComments, setSecondaryComments] = React.useState('');
    const [companyAddress, setCompanyAddress] = useState('');
    const [companyAddress2, setCompanyAddress2] = useState('');
    const [companyPostalCode, setCompanyPostalCode] = useState('');
    const [companyCity, setCompanyCity] = useState('');
    const [countryName, setCountryName] = useState('');
    const [serviceManagerEmail, setServiceManagerEmail] = useState('');
    const [serviceManagerFirstName, setServiceManagerFirstName] = useState('');
    const [serviceManagerLastName, setServiceManagerLastName] = useState('');
    const [serviceManagerCountryCode1, setServiceManagerCountrycode1] = useState('');
    const [serviceManagerPhone1, setServiceManagerPhone1] = useState('');
    const [serviceManagerCountryCode2, setServiceManagerCountrycode2] = useState('');
    const [serviceManagerPhone2, setServiceManagerPhone2] = useState('');
    const [serviceManagerComments, setServiceManagerComments] = useState('');
    const [countryList, setCountryList] = useState<CountryList>([]);
    const [countryCodeList, setCountryCodeList] = useState<CountryList>([]);
    const [selectedCountry, setSelectedCountry] = useState<Country>({ name: '', code: '' });
    const [countryCode, setCountryCode] = useState('');
    const [primarycountryCode, setPrimaryCountryCode] = useState('');
    const [primaryBackUpcountryCode, setPrimaryBackUpCountryCode] = useState('');
    const [secondarycountryCode, setSecondaryCountryCode] = useState('');
    const [secondaryBackUpcountryCode, setSecondaryBackUpCountryCode] = useState('');
    const [companyPhoneNumber, setCompanyPhoneNumber] = useState('');
    const [primaryPhoneNumber, setPrimaryPhoneNumber] = useState('');
    const [primaryBackUpPhoneNumber, setPrimaryBackUpPhoneNumber] = useState('');
    const [primaryBackUpPhoneNumber2, setPrimaryBackUpPhoneNumber2] = useState('');
    const [secondaryPhoneNumber, setSecondaryPhoneNumber] = useState('');
    const [secondaryBackUpPhoneNumber, setSecondaryBackUpPhoneNumber] = useState('');
    const [primaryContactType, setPrimaryContactType] = React.useState('');
    const [secondaryContactType, setSecondaryContactType] = React.useState('');
    const [primaryEmail, setPrimaryEmail] = useState('');
    const [secondaryEmail, setSecondaryEmail] = useState('');
    const [primaryFirstName, setPrimaryFirstName] = useState('');
    const [primaryLastName, setPrimaryLastName] = useState('');
    const [secondaryFirstName, setSecondaryFirstName] = useState('');
    const [secondaryLastName, setSecondaryLastName] = useState('');
    const [isChecked, setIsChecked] = useState(true);
    const registration = useSelector(Selectors.Registration.registration);
    const countryListLoading = useSelector(Selectors.Registration.countryListLoading);
    const countryListSuccess = useSelector(Selectors.Registration.countryListSuccess);
    const [slaExpiryDate, setSlaExpiryDate] = React.useState<Date | null>();
    const [deviceCustomerData, setDeviceCustomerData] = useState<DeviceCustomerDetail>(customerData);
    const [customerLogo, setCustomerLogo] = useState<string | null | undefined>('');
    const [imageString, setImageString] = useState<string | undefined>('');
    const customerPrimaryContactType: contactTypeSelectionList[0] = getcontactUpdateList(deviceCustomerData.primaryContactType);
    const customerSecondaryContactType: contactTypeSelectionList[0] = getcontactUpdateList(deviceCustomerData.secondaryContactType);
    const [orgLoaded, setOrgLoaded] = React.useState(false);
    const [customerDetailLoaded, setCustomerDetailLoaded] = React.useState(false);
    const [countryLoaded, setCountryLoaded] = React.useState(false);
    const [dataLoaded, setDataLoaded] = React.useState(false);
    const [savebuttonState, setSavebuttonState] = React.useState(false);
    const [header, setHeader] = React.useState('')
    const [toShow, setToShow] = React.useState(false)
    const [alertErrorMessage, setAlertErrorMessage] = useState<string>('');
    const [alertSuccessMessage, setAlertSuccessMessage] = useState<string>('');
    const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false);
    const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
    const [isdateValid, setIsDateValid] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const handleToolBar = (flag: boolean) => {
        setSavebuttonState(flag);
        setAppBarColor(flag ? ToolbarColor.Other : ToolbarColor.Default);
    };



    var primaryContactValue = getContactTypeNumber(primaryContactType);
    var secondaryContactValue = getContactTypeNumber(secondaryContactType);

    var primaryContactNumber = (primaryContactType !== '' && primaryContactType !== undefined) ? primaryContactValue.key : 0;
    var secondaryContactNumber = (secondaryContactType !== '' && secondaryContactType !== undefined) ? secondaryContactValue.key : 0;

    var slaExpiry = deviceCustomerData.expiryDate ? deviceCustomerData.expiryDate : moment.now();
    var slaExpiryDateFormatCalendar = moment(slaExpiry).format('MM/DD/YYYY');
    var apiSlaExpiryDateFormat = moment(slaExpiry).format('DD/MM/YYYY');


    useEffect(() => {
        dispatch(Thunks.Registration.countryCodeRequest());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (orgLoaded && countryLoaded) {
            if (!countryListLoading && !organization.orgListLoading) {
                setDataLoaded(true);
            }
        }
        else
            setDataLoaded(false);
    }, [orgLoaded, countryLoaded, customerDetailLoaded, countryListLoading, organization.orgListLoading]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isChecked && fieldsComplete([customerName, customerNumber, companyAddress, companyAddress2, companyPostalCode, companyCity, countryName, companyPhoneNumber, serviceManagerEmail, serviceManagerFirstName, serviceManagerPhone1])) {
            handleToolBar(true);
        }
        else if (!isChecked && fieldsComplete([customerName, customerNumber, companyAddress, companyAddress2, companyPostalCode, companyCity, countryName, companyPhoneNumber, serviceManagerEmail, serviceManagerFirstName, serviceManagerPhone1, primaryEmail, primaryFirstName, primaryLastName, primaryPhoneNumber, slaNumber]) && slaExpiryDate) {
            handleToolBar(true);
        }
        else {
            handleToolBar(false);
            setIsEdit(false);
        }
    }, [customerName, customerNumber, companyAddress, companyAddress2, companyPostalCode, companyCity, countryName, companyPhoneNumber, serviceManagerEmail, serviceManagerFirstName, serviceManagerPhone1, slaNumber, primaryEmail, primaryFirstName, primaryLastName, primaryPhoneNumber, isChecked, slaExpiryDate, primaryContactType, secondaryContactType, countryCode, serviceManagerCountryCode1, primarycountryCode, primaryBackUpPhoneNumber, serviceManagerPhone2, secondaryPhoneNumber, secondaryBackUpPhoneNumber, serviceManagerLastName, secondaryFirstName, secondaryLastName, primaryBackUpcountryCode, serviceManagerCountryCode2, secondarycountryCode, secondaryBackUpcountryCode, customerComments, customerLogo, secondaryComments, primaryComments, serviceManagerComments, secondaryEmail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!countryListLoading && countryListSuccess) {
            const cloneCountryObj = registration.countryList?.map((data) => data);
            setCountryList(registration.countryList.sort((a, b) => (a.name > b.name) ? 1 : -1));
            setCountryCodeList(cloneCountryObj.sort((a, b) => (a.code > b.code) ? 1 : -1));
            setCountryLoaded(true);
        }
    }, [countryListLoading, countryListSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setServicePartnerDetails(servicePartnerDetail);
    }, [servicePartnerDetail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (actionType == ActionType.EDIT) {
            if (!userManagement.servicePartnerDetailLoading && userManagement.servicePartnerDetailSuccess) {
                setServicePartnerDetail(userManagement.servicePartnerDetail);
                setServicePartnerDetails(servicePartnerDetail);
                dispatch(UserManagementActions.servicePartnerDetailUnmounted());
            } else if (!userManagement.servicePartnerDetailLoading && userManagement.servicePartnerDetailFail) {
                addToast(t('TOAST_MSG.TOAST_ERROR_GET_CUSTOMER_INFO'), 'error');
                setServicePartnerDetail(defaultServicePartnerDetail);
                dispatch(UserManagementActions.servicePartnerDetailUnmounted());
            }
        }
    }, [userManagement.servicePartnerDetailLoading, userManagement.servicePartnerDetailSuccess, userManagement.servicePartnerDetailFail]); // eslint-disable-line react-hooks/exhaustive-deps

    const setServicePartnerDetails = (servicePartnerDetail: servicePartnerDetail) => {
        if (actionType == ActionType.EDIT) {
            setIsChecked(servicePartnerDetail.isInternalTeam);
            setPrimaryContactType(getcontactUpdateList(servicePartnerDetail.primaryContactType).value)
            setSecondaryContactType(getcontactUpdateList(servicePartnerDetail.secondaryContactType).value);
            setCustomerName(servicePartnerDetail.companyName ? servicePartnerDetail.companyName : '');
            setCustomerNumber(servicePartnerDetail.customerNumber ? servicePartnerDetail.customerNumber : '');
            setSlaNumber(servicePartnerDetail.sLANumber ? servicePartnerDetail.sLANumber : '');
            setCompanyAddress(servicePartnerDetail.companyAddress1 ? servicePartnerDetail.companyAddress1 : '');
            setCompanyAddress2(servicePartnerDetail.companyAddress2 ? servicePartnerDetail.companyAddress2 : '');
            setCompanyPostalCode(servicePartnerDetail.postalCode ? servicePartnerDetail.postalCode : '');
            setCompanyCity(servicePartnerDetail.city);
            setCountryName(servicePartnerDetail.country);
            setCountryCode(servicePartnerDetail.companyCountryCode ? servicePartnerDetail.companyCountryCode : '');
            setServiceManagerCountrycode1(servicePartnerDetail.serviceManagerPrimaryCountryCode ? servicePartnerDetail.serviceManagerPrimaryCountryCode : '');
            setPrimaryCountryCode(servicePartnerDetail.primaryCountryCode ? servicePartnerDetail.primaryCountryCode : '');
            setCompanyPhoneNumber(servicePartnerDetail.companyPhone ? servicePartnerDetail.companyPhone : '');
            setPrimaryPhoneNumber(servicePartnerDetail.primaryPhone ? servicePartnerDetail.primaryPhone : '');
            setServiceManagerPhone1(servicePartnerDetail.serviceManagerPrimaryPhone ? servicePartnerDetail.serviceManagerPrimaryPhone : '');
            setPrimaryBackUpPhoneNumber(servicePartnerDetail.primaryPhone2 ? servicePartnerDetail.primaryPhone2 : '');
            setServiceManagerPhone2(servicePartnerDetail.serviceManagerPhone2 ? servicePartnerDetail.serviceManagerPhone2 : '');
            setSecondaryPhoneNumber(servicePartnerDetail.secondaryPhone ? servicePartnerDetail.secondaryPhone : '');
            setSecondaryBackUpPhoneNumber(servicePartnerDetail.secondaryPhone2 ? servicePartnerDetail.secondaryPhone2 : '');
            setPrimaryFirstName(servicePartnerDetail.primaryFirstName ? servicePartnerDetail.primaryFirstName : '');
            setServiceManagerFirstName(servicePartnerDetail.serviceManagerFirstName ? servicePartnerDetail.serviceManagerFirstName : '');
            setPrimaryLastName(servicePartnerDetail.primaryLastName ? servicePartnerDetail.primaryLastName : '');
            setServiceManagerLastName(servicePartnerDetail.serviceManagerLastName ? servicePartnerDetail.serviceManagerLastName : '');
            setSecondaryFirstName(servicePartnerDetail.secondaryFirstName ? servicePartnerDetail.secondaryFirstName : '');
            setSecondaryLastName(servicePartnerDetail.secondaryLastName ? servicePartnerDetail.secondaryLastName : '');
            setPrimaryBackUpCountryCode(servicePartnerDetail.primaryCountryCode2 ? servicePartnerDetail.primaryCountryCode2 : '');
            setServiceManagerCountrycode2(servicePartnerDetail.serviceManagerCountryCode2 ? servicePartnerDetail.serviceManagerCountryCode2 : '');
            setSecondaryCountryCode(servicePartnerDetail.secondaryCountryCode ? servicePartnerDetail.secondaryCountryCode : '');
            setSecondaryBackUpCountryCode(servicePartnerDetail.secondaryCountryCode2 ? servicePartnerDetail.secondaryCountryCode2 : '');
            setCustomerComments(servicePartnerDetail.comments);
            setCustomerLogo(servicePartnerDetail.imageBase64 ? servicePartnerDetail.imageBase64 : '');
            setSlaExpiryDate(servicePartnerDetail.expiryDate ? new Date(servicePartnerDetail.expiryDate) : new Date(''));
            setSecondaryComments(servicePartnerDetail.secondaryContactComments);
            setPrimaryEmail(servicePartnerDetail.primaryEmail ? servicePartnerDetail.primaryEmail : '');
            setPrimaryComments(servicePartnerDetail.primaryContactComments);
            setServiceManagerComments(servicePartnerDetail.serviceManagerComments);
            setServiceManagerEmail(servicePartnerDetail.serviceManagerEmail ? servicePartnerDetail.serviceManagerEmail : '');
            setSecondaryEmail(servicePartnerDetail.secondaryEmail ? servicePartnerDetail.secondaryEmail : '');
            setIsEdit(true);
        }

    }

    const handleAddServiceManagerResponse = (): void => {
        if (userManagement.addServicePartnerCustomerErrorCode !== '') {
            if (userManagement.addServicePartnerCustomerErrorCode === '9162')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9162'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9169')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9169'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9163')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9163'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9068')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9068'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9069')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9069'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9070')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9070'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9071')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9071'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9072')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9072'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9164')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9164'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9043')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9043'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9073')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9073'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9074')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9074'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9167')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9167'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9077')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9077'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9147')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9147'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9076')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9076'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9001')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9001'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9170')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9170'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9171')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9171'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9150')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9150'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9151')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9151'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9152')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9152'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9153')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9153'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9165')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9165'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9166')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9166'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9004')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9004'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9050')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9050'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9172')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9172'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9173')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9173'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9174')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9174'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9175')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9175'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9176')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9176'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9040')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9040'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9023')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9023'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9193')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9193'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9194')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9194'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9195')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9195'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9196')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9196'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9197')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9197'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9198')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9198'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9199')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9199'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9201')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9201'));
            else if (userManagement.addServicePartnerCustomerErrorCode === '9000')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9000'));
            else
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_UPDATE_PAAS_DETAILS'));
        }
    };

    useEffect(() => {
        if (!userManagement.addServicePartnerCustomerLoading && userManagement.addServicePartnerCustomerSuccess) {
            history.goBack();
            setSavebuttonState(false);
            setAppBarColor(ToolbarColor.Default);
            actionType == ActionType.ADD ? addToast(t('TOAST_MSG.TOAST_SUCCESS_CUSTOMER_CREATED'), 'success') : addToast(t('TOAST_MSG.TOAST_SUCCESS_UPDATE_CUSTOMER_DETAILS'), 'success')
        }
        else if (!userManagement.addServicePartnerCustomerLoading && userManagement.addServicePartnerCustomerFail) {
            setDisplayErrorMessage(true);
            handleAddServiceManagerResponse();
        }
        dispatch(UserManagementActions.addServicePartnerCustomerUnmounted());
    }, [userManagement.addServicePartnerCustomerSuccess, userManagement.addServicePartnerCustomerFail, userManagement.addServicePartnerCustomerLoading]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleContactDetailCheckbox = (
        event: React.ChangeEvent<HTMLElement>,
        checked: boolean,
    ) => {
        if (checked) {
            setIsChecked(true);
            setIsInternal(true);
            setToShow(false);
        }
        else if (!checked) {
            setIsChecked(false);
            setIsInternal(false)
            setToShow(true);
        }
    };

    const handlePrimaryContactTypeChange = (event: SelectChangeEvent) => {
        setPrimaryContactType(event.target.value);
    };
    const handleSecondaryContactTypeChange = (event: SelectChangeEvent) => {
        setSecondaryContactType(event.target.value);
    };
    const onCustomerNameChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
        if (event.target.value === '' || ORGANIZATTION_NAME.test(event.target.value as string)) {
            setCustomerName(event.target.value as string);
        }
    };
    const onCustomerNumberChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || PHONE_NUMBER.test(e.target.value)) {
            setCustomerNumber(e.target.value);
        }
    };
    const onSlaNumberChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || ALPHA_NUMBERIC.test(e.target.value)) {
            setSlaNumber(e.target.value);
        }
    };
    const onCompanyAddressChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || COMPANY_ADDRESS.test(e.target.value)) {
            setCompanyAddress(e.target.value);
        }
    };
    const onCompanyAddressChange2 = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || COMPANY_ADDRESS.test(e.target.value)) {
            setCompanyAddress2(e.target.value);
        }
    };
    const onCompanyPostalCodeChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || ALPHA_NUMBERIC.test(e.target.value)) {
            setCompanyPostalCode(e.target.value as string);
        }
    };
    const onCompanyCityChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || COMPANY_CITY.test(e.target.value)) {
            setCompanyCity(e.target.value as string);
        }
    };
    const onChangeCountryName = (event: SelectChangeEvent) => {
        const countryNameData = event.target.value as string
        setCountryName(countryNameData);
        // eslint-disable-next-line
        const countryCodeData = countryList.filter(function (countryData) {
            if (countryData.name === countryNameData)
                return countryData
        });
        if (countryCodeData?.length >= 0) {
            setCountryCode(countryCodeData?.[0].code);
            setServiceManagerCountrycode1(countryCodeData?.[0].code);
            setPrimaryCountryCode(countryCodeData?.[0].code);
        }
    };
    const onChangeCompanyPhoneNumber = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || PHONE_NUMBER.test(e.target.value)) {
            setCompanyPhoneNumber(e.target.value);
        }
    };
    const onChangePrimaryPhoneNumber = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || PHONE_NUMBER.test(e.target.value)) {
            setPrimaryPhoneNumber(e.target.value);
        }
    };
    const onChangeSMPhone1 = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || PHONE_NUMBER.test(e.target.value)) {
            setServiceManagerPhone1(e.target.value);
        }
    };
    const onChangePrimaryBackUpPhoneNumber = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || PHONE_NUMBER.test(e.target.value)) {
            setPrimaryBackUpPhoneNumber(e.target.value);
        }
    };
    const onChangeServiceManagerPhone2 = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || PHONE_NUMBER.test(e.target.value)) {
            setServiceManagerPhone2(e.target.value);
        }
    };
    const onChangeSecondaryPhoneNumber = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || PHONE_NUMBER.test(e.target.value)) {
            setSecondaryPhoneNumber(e.target.value);
        }
    };
    const onChangeSecondaryBackUpPhoneNumber = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || PHONE_NUMBER.test(e.target.value)) {
            setSecondaryBackUpPhoneNumber(e.target.value);
        }
    };
    const onPrimaryFirstNameChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || NAME_VALIDATION.test(e.target.value)) {
            setPrimaryFirstName(e.target.value);
        }
    };
    const onsMFirstNameChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || NAME_VALIDATION.test(e.target.value)) {
            setServiceManagerFirstName(e.target.value);
        }
    };
    const onPrimaryLastNameChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || NAME_VALIDATION.test(e.target.value)) {
            setPrimaryLastName(e.target.value);
        }
    };
    const onSMLastNameChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || NAME_VALIDATION.test(e.target.value)) {
            setServiceManagerLastName(e.target.value);
        }
    };
    const onSecondaryFirstNameChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || NAME_VALIDATION.test(e.target.value)) {
            setSecondaryFirstName(e.target.value);
        }
    };
    const onSecondaryLastNameChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || NAME_VALIDATION.test(e.target.value)) {
            setSecondaryLastName(e.target.value);
        }
    };
    const onChangeCountryCode = (event: SelectChangeEvent) => {
        setCountryCode(event.target.value as string);
    };
    const onChangePrimaryCountryCode = (event: SelectChangeEvent) => {
        setPrimaryCountryCode(event.target.value as string);
    };
    const onChangeSMCountryCode1 = (event: SelectChangeEvent) => {
        setServiceManagerCountrycode1(event.target.value as string);
    };
    const onChangePrimaryBackUpCountryCode = (event: SelectChangeEvent) => {
        setPrimaryBackUpCountryCode(event.target.value as string);
    };
    const onChangeServiceManagerCountryCode2 = (event: SelectChangeEvent) => {
        setServiceManagerCountrycode2(event.target.value as string);
    };
    const onChangeSecondaryCountryCode = (event: SelectChangeEvent) => {
        setSecondaryCountryCode(event.target.value as string);
    };
    const onChangeSecondaryBackUpCountryCode = (event: SelectChangeEvent) => {
        setSecondaryBackUpCountryCode(event.target.value as string);
    };

    const editCustomer = (): boolean => {
        if (primaryContactType === getcontactUpdateList(servicePartnerDetail.primaryContactType).value &&
            secondaryContactType === getcontactUpdateList(servicePartnerDetail.secondaryContactType).value &&
            customerName === servicePartnerDetail.companyName &&
            customerNumber === servicePartnerDetail.customerNumber &&
            slaNumber === (servicePartnerDetail.sLANumber == null ? '' : servicePartnerDetail.sLANumber) &&
            customerNumber === servicePartnerDetail.customerNumber &&
            companyAddress === servicePartnerDetail.companyAddress1 &&
            companyAddress2 === servicePartnerDetail.companyAddress2 &&
            companyPostalCode === servicePartnerDetail.postalCode &&
            companyCity === servicePartnerDetail.city &&
            countryName === servicePartnerDetail.country &&
            countryCode === servicePartnerDetail.companyCountryCode &&
            serviceManagerCountryCode1 === servicePartnerDetail.serviceManagerPrimaryCountryCode &&
            primarycountryCode === servicePartnerDetail.primaryCountryCode &&
            companyPhoneNumber === servicePartnerDetail.companyPhone &&
            primaryPhoneNumber === servicePartnerDetail.primaryPhone &&
            serviceManagerPhone1 === servicePartnerDetail.serviceManagerPrimaryPhone &&
            primaryBackUpPhoneNumber === servicePartnerDetail.primaryPhone2 &&
            serviceManagerPhone2 === servicePartnerDetail.serviceManagerPhone2 &&
            secondaryPhoneNumber === servicePartnerDetail.secondaryPhone &&
            secondaryBackUpPhoneNumber === servicePartnerDetail.secondaryPhone2 &&
            primaryFirstName === servicePartnerDetail.primaryFirstName &&
            serviceManagerFirstName === servicePartnerDetail.serviceManagerFirstName &&
            primaryLastName === servicePartnerDetail.primaryLastName &&
            serviceManagerLastName === servicePartnerDetail.serviceManagerLastName &&
            secondaryFirstName === servicePartnerDetail.secondaryFirstName &&
            secondaryLastName === servicePartnerDetail.secondaryLastName &&
            primaryBackUpcountryCode === servicePartnerDetail.primaryCountryCode2 &&
            serviceManagerCountryCode2 === servicePartnerDetail.serviceManagerCountryCode2 &&
            secondarycountryCode === servicePartnerDetail.secondaryCountryCode &&
            secondaryBackUpcountryCode === servicePartnerDetail.secondaryCountryCode2 &&
            customerComments === servicePartnerDetail.comments &&
            customerLogo === servicePartnerDetail.imageBase64 &&
            getDate(slaExpiryDate?.toString()).toLowerCase() === getDate(servicePartnerDetail.expiryDate).toLowerCase() &&
            secondaryComments === servicePartnerDetail.secondaryContactComments &&
            primaryEmail === servicePartnerDetail.primaryEmail &&
            primaryComments === servicePartnerDetail.primaryContactComments &&
            serviceManagerComments === servicePartnerDetail.serviceManagerComments &&
            serviceManagerEmail === servicePartnerDetail.serviceManagerEmail &&
            secondaryEmail === servicePartnerDetail.secondaryEmail &&
            isChecked === servicePartnerDetail.isInternalTeam) {
            return false;
        }
        return true;
    }
    const fieldsComplete = (fields: string[]): boolean => {
        for (let i = 0; i < fields.length; i++) {
            if (isChecked && (fields[i].length < 1 || (!validEmail(serviceManagerEmail) || !validPhoneNumberLength(companyPhoneNumber) || !validPhoneNumberLength(serviceManagerPhone1) || !validPostalCode(companyPostalCode) || !validSlaNumber(customerNumber)))) {
                return false;
            }
            if (!isChecked && (fields[i].length < 1 || (!validEmail(serviceManagerEmail) || !validPhoneNumberLength(companyPhoneNumber) || !validPhoneNumberLength(serviceManagerPhone1) || !validPostalCode(companyPostalCode) || !validSlaNumber(customerNumber) || !validEmail(primaryEmail) || !validPhoneNumberLength(primaryPhoneNumber) || !validSlaNumber(slaNumber)))) {
                return false;
            }
        }
        if (isChecked && (slaNumber.length >= 1) && !validSlaNumber(slaNumber)) {
            return false;
        }
        if (isChecked && (!(moment(slaExpiryDate, "YYYY-MM-DD", true).isValid() && moment().diff(moment(slaExpiryDate, "DD/MM/YYYY")) < 0)) && slaExpiryDate != undefined && isdateValid) {
            return false;
        }
        if (isChecked && (serviceManagerPhone2.length >= 1) && !validPhoneNumberLength(serviceManagerPhone2)) {
            return false;
        }
        if (!isChecked && (((primaryBackUpPhoneNumber2.length >= 1) && (!validPhoneNumberLength(primaryBackUpPhoneNumber2))) || ((secondaryEmail.length >= 1) && (!validEmail(secondaryEmail))) || ((secondaryPhoneNumber.length >= 1) && (!validPhoneNumberLength(secondaryPhoneNumber))) || ((secondaryEmail.length >= 1) && (!validEmail(secondaryEmail))) || ((secondaryBackUpPhoneNumber.length >= 1) && (!validPhoneNumberLength(secondaryBackUpPhoneNumber))) || ((serviceManagerPhone2.length >= 1) && (!validPhoneNumberLength(serviceManagerPhone2))) || ((primaryBackUpPhoneNumber.length >= 1) && (!validPhoneNumberLength(primaryBackUpPhoneNumber))) || (!(moment(slaExpiryDate, "YYYY-MM-DD", true).isValid() && moment().diff(moment(slaExpiryDate, "DD/MM/YYYY")) < 0) && slaExpiryDate != undefined ) )) {
            return false;
        }
        if (actionType == ActionType.EDIT) {
            return editCustomer();
        }
        return true;
    };

    const onCancel = () => {
        history.goBack();
        dispatch(UserManagementActions.servicePartnerDetailUnmounted());
        setServicePartnerDetail(defaultServicePartnerDetail)
    }

    const dispatchPAASData = (customerName: string,
        companyAddress: string, companyPostalCode: string,
        companyCity: string, countryName: string, countryCode: string,
        companyPhoneNumber: string,
        serviceManagerEmail: string, serviceManagerFirstName: string,
        serviceManagerCountryCode1: string, serviceManagerPhone1: string, serviceManagerCountryCode2: string, serviceManagerPhone2: string, primaryEmail: string, primaryFirstName: string,
        primarycountryCode: string, primaryPhoneNumber: string,
        primaryBackUpPhoneNumber: string, primaryBackUpcountryCode: string,
        secondaryEmail: string, secondaryFirstName: string, secondarycountryCode: string,
        secondaryPhoneNumber: string, secondaryBackUpPhoneNumber: string, secondaryBackUpcountryCode: string, slaExpiryDate: Date | null | undefined) => {
        dispatch(Thunks.UserManagement.addServicePartnerCustomer(
            actionType == ActionType.EDIT ? servicePartnerDetail.id : '',
            customerName,
            customerNumber,
            companyAddress,
            companyAddress2,
            companyPostalCode,
            companyCity,
            countryName,
            countryCode,
            companyPhoneNumber,
            customerComments,
            serviceManagerEmail,
            serviceManagerFirstName,
            serviceManagerLastName,
            serviceManagerCountryCode1,
            serviceManagerPhone1,
            serviceManagerCountryCode2,
            serviceManagerPhone2,
            serviceManagerComments,
            primaryEmail,
            primaryFirstName,
            primaryLastName,
            primarycountryCode,
            primaryPhoneNumber,
            primaryBackUpcountryCode,
            primaryBackUpPhoneNumber,
            primaryContactValue !== undefined ? primaryContactValue.key : 0,
            primaryComments,
            secondaryFirstName,
            secondaryLastName,
            secondarycountryCode,
            secondaryPhoneNumber,
            secondaryBackUpcountryCode,
            secondaryBackUpPhoneNumber,
            secondaryEmail,
            secondaryContactValue !== undefined ? secondaryContactValue.key : 0,
            secondaryComments,
            isInternalTeam,
            customerLogo ? customerLogo : '',
            '',
            slaNumber,
            slaExpiryDate?.toString() === undefined || slaExpiryDate?.toString().toLowerCase() === INVALID_DATE ? '' : slaExpiryDate!.toISOString()
        ));

    }

    const saveData = (): void => {
        if (isChecked) {
            if (fieldsComplete([customerName,
                companyAddress, companyPostalCode,
                companyCity, countryName, countryCode,
                companyPhoneNumber,
                serviceManagerEmail, serviceManagerFirstName,
                serviceManagerCountryCode1, serviceManagerPhone1])) {
                dispatchPAASData(customerName,
                    companyAddress, companyPostalCode,
                    companyCity, countryName, countryCode,
                    companyPhoneNumber,
                    serviceManagerEmail, serviceManagerFirstName,
                    serviceManagerCountryCode1, serviceManagerPhone1, serviceManagerCountryCode2, serviceManagerPhone2, primaryEmail, primaryFirstName, primarycountryCode, primaryPhoneNumber, primaryBackUpPhoneNumber, primaryBackUpcountryCode,
                    secondaryEmail, secondaryFirstName, secondarycountryCode,
                    secondaryPhoneNumber, secondaryBackUpPhoneNumber, secondaryBackUpcountryCode, slaExpiryDate)
            }
            else {
                addToast(t('TOAST_MSG.TOAST_ERROR_MESSAGE'), 'error');
            }
        } else {
            if (fieldsComplete([customerName,
                companyAddress, companyPostalCode,
                companyCity, countryName, countryCode,
                companyPhoneNumber,
                serviceManagerEmail, serviceManagerFirstName,
                serviceManagerCountryCode1, serviceManagerPhone1, primaryEmail, primaryFirstName, primaryPhoneNumber, primarycountryCode]) && slaExpiryDate) {
                dispatchPAASData(customerName,
                    companyAddress, companyPostalCode,
                    companyCity, countryName, countryCode,
                    companyPhoneNumber,
                    serviceManagerEmail, serviceManagerFirstName,
                    serviceManagerCountryCode1, serviceManagerPhone1, serviceManagerCountryCode2, serviceManagerPhone2, primaryEmail,
                    primaryFirstName, primarycountryCode, primaryPhoneNumber, primaryBackUpPhoneNumber, primaryBackUpcountryCode,
                    secondaryEmail, secondaryFirstName, secondarycountryCode,
                    secondaryPhoneNumber, secondaryBackUpPhoneNumber, secondaryBackUpcountryCode, slaExpiryDate)
            } else {
                addToast(t('TOAST_MSG.TOAST_ERROR_MESSAGE'), 'error');
            }
        }
    }

    const validDate = (date: Date | null | undefined): boolean => {
        if (!isdateValid)
            return true;
        else if (isdateValid && slaExpiryDate?.toDateString() === undefined)
            return true;
        else
            return (moment(date, "YYYY-MM-DD", true).isValid() && moment().diff(moment(date, "DD/MM/YYYY")) < 0);
    }

    return (
        <div data-testid="PAAS-details-container">
            <GenericToolbar
                title={(actionType == ActionType.EDIT) ? t('CUSTOMER_DETAILS.CREATE_CUSTOMER_HEADER') : t('CUSTOMER_DETAILS.CREATE_CUSTOMER')}
                showBackButton={true}
                showCloseButton={false}
                actionUIState={savebuttonState}
                backButtonOnClick={(): void => {
                    history.goBack();
                }}
                saveOnClick={(): void => {
                    saveData();
                }}
                cancelOnClick={(): void => {
                    onCancel()
                }}
                color={appBarColor}
            />
            <div data-testid="container-create-PAAS" style={{ backgroundColor: PXBColors.white[50], height: md ? '100%' : 1600, width: '100%', padding: '10%', paddingTop: '3%', display: md ? 'flex' : '' }}>
                <div data-testid='leftContainerTestId' style={{ float: 'left', width: md ? '49%' : '', paddingRight: '5%' }}>
                    <div data-testid='asteriskHelperText' style={{ display: 'flex', gap: 15, paddingBottom: 28 }}>
                        <InfoIcon style={{ color: PXBColors.blue[500] }} />
                        <Typography data-testid='helpingTextId' variant='body1' style={{ fontWeight: 400, fontSize: 16 }}>{t('FORMS.HELPING_TEXT')}</Typography>
                    </div>
                    <div data-testid='imageContainerId' style={{ height: 340, background: PXBColors.white[50], borderRadius: 4, boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)' }}>
                        <Typography data-testid='imagesTextId' variant='subtitle2' color={PXBColors.blue[500]} style={{ paddingTop: 15, paddingRight: 291, paddingBottom: 14, paddingLeft: 16, height: 48 }}>{t('CUSTOMER_DETAILS.IMAGES')}</Typography>
                        <Divider />
                        <CustomerLogo image={customerLogo} onRemoveExistingImage={(): void => { setCustomerLogo('') }} onImageUploadLocal={(image, imageString): void => { setCustomerLogo(image); setImageString(imageString) }}></CustomerLogo>
                    </div>
                    <div style={{ display: 'flex', marginTop: 24 }}>
                        <Checkbox data-testid='internal-service-team-check'
                            onChange={handleContactDetailCheckbox}
                            checked={isChecked}
                            value={isChecked}
                        />
                        <Typography
                            data-testid="internal-service-team-check-lable"
                            style={{ fontSize: '16', fontWeight: 400, alignSelf: 'center', color: PXBColors.black[500] }}>
                            {t('USERS.INTERNAL_EATON_SERVICE_TEAM_CHECKBOX_TEXT')}
                        </Typography>
                    </div>
                    <div data-testid='PAAS-details-divId' style={{ marginBottom: 125 }}>
                        <Typography data-testid='PAAS-details-textId' variant='body1' style={{ fontWeight: 400, fontSize: 16, color: PXBColors.blue[500], fontStyle: 'normal', marginTop: 24 }}>{t('USERS.CUSTOMER_DETAILS_HEADER')}</Typography>
                        <TextField
                            style={{ marginTop: 24, width: '100%', fontSize: '16px', color: PXBColors.black[500] }}
                            id="customerName"
                            data-testid='customerNameLabelId'
                            variant={'filled'}
                            label={t('CUSTOMER_DETAILS.CUSTOMER_NAME')}
                            fullWidth
                            required
                            value={customerName}
                            onChange={(e): void => onCustomerNameChange(e)}
                            inputProps={{ 'data-testid': 'customerNameId', maxLength: ORG_CHARACTER_LIMIT }}
                        />
                        <div data-testid='customerNameHelperTextId'
                            style={{ height: 17, width: '100%', color: PXBColors.gray[500] }}>
                            <small className="form-text" style={{ width: '85%', float: 'left', paddingLeft: '2%' }}>
                                {t('ORGANIZATION_MANAGE.ORGANIZATION_NAME_HELPER')}</small>
                            <Typography data-testid='CustomerNamecharLimitId' style={{ width: 'auto', float: 'right', fontSize: 12, color: PXBColors.gray[500] }}>
                                {customerName.length}/{ORG_CHARACTER_LIMIT}
                            </Typography>
                        </div>
                        <TextField
                            style={{ marginTop: 24, width: '100%', fontSize: 16, color: PXBColors.black[500] }}
                            id="customerNumber"
                            data-testid='customerNumberLabelId'
                            variant={'filled'}
                            label={t('CUSTOMER_DETAILS.CUSTOMER_NUMBER')}
                            fullWidth
                            required
                            disabled={actionType == ActionType.EDIT}
                            value={customerNumber}
                            error={customerNumber.length > 0 && !validSlaNumber(customerNumber)}
                            helperText={customerNumber.length > 0 && !validSlaNumber(customerNumber) ? (t('FORMS.INVALID_CUSTOMER_NUMBER')) : ''}
                            onChange={(e): void => onCustomerNumberChange(e)}
                            inputProps={{ 'data-testid': 'customerNumberLabelId2', maxLength: CUSTOMER_NUMBER_LIMIT }}
                        />
                        <div data-testid='customerNumberHelperTextId'
                            style={{ height: 17, width: '100%', color: PXBColors.gray[500] }}>
                            <small className="form-text" style={{ width: '85%', float: 'left', paddingLeft: '2%' }}>
                                {t('CUSTOMER_DETAILS.CUSTOMER_NUMBER_HELPER_TEXT')}</small>
                            <Typography data-testid='customerNumberCharLimitId' style={{ width: 'auto', float: 'right', fontSize: 12, color: PXBColors.gray[500] }}>
                                {customerNumber.length}/{CUSTOMER_NUMBER_LIMIT}
                            </Typography>
                        </div>
                        <TextField
                            style={{ marginTop: 24, width: '100%', float: 'left', wordWrap: 'break-word', color: PXBColors.black[500] }}
                            id="customercomments"
                            data-testid='customercommentsId'
                            variant={'filled'}
                            label={t('LOGBOOK_DETAILS.COMMENTS')}
                            rows={2}
                            multiline
                            fullWidth
                            onChange={(evt: ChangeEvent<HTMLInputElement>): void => setCustomerComments(evt.target.value)}
                            value={customerComments}
                            inputProps={{ 'data-testid': 'customer-comments', maxLength: ORG_COMMENTS_LIMIT }}
                        />
                        <div data-testid='customerCommentsDivId'
                            style={{ height: 17, width: '100%', color: PXBColors.gray[500], marginBottom: 24 }}>
                            <small data-testid='helperTextIdTechnician' className="form-text" style={{ width: '86%', float: 'left', paddingLeft: '2%' }}>
                                {t('ORGANIZATION_MANAGE.COMMENTS_HELPER')}</small>
                            <Typography data-testid='customerCommentsLimit' style={{ width: 'auto', float: 'right', fontSize: 12, color: PXBColors.gray[500] }}>
                                {customerComments.length}/{ORG_COMMENTS_LIMIT}
                            </Typography>
                        </div>
                    </div>
                    <div >
                        <TextField
                            data-testid='companyAddressId'
                            style={{ marginTop: 24, float: 'right', width: '100%' }}
                            id="companyaddress"
                            variant={'filled'}
                            required
                            label={t('FORMS.USER_ADDRESS_1')}
                            fullWidth
                            inputProps={{ 'data-testid': 'company-address', maxLength: COMPANY_ADDRESS_MAX_LENGTH }}
                            value={companyAddress}
                            onChange={(e): void => onCompanyAddressChange(e)}
                        />
                        <TextField
                            data-testid='companyAddressId2'
                            style={{ marginTop: 24, float: 'right', width: '100%' }}
                            id="companyaddress2"
                            variant={'filled'}
                            label={t('FORMS.USER_ADDRESS_2')}
                            fullWidth
                            required
                            inputProps={{ 'data-testid': 'company-address2', maxLength: COMPANY_ADDRESS_MAX_LENGTH }}
                            value={companyAddress2}
                            onChange={(e): void => onCompanyAddressChange2(e)}
                        />
                        <TextField
                            style={{ marginTop: 24, width: '35%', marginRight: '2%', textAlign: 'center' }}
                            id="companypostalcode"
                            data-testid='companyPostalCode'
                            variant={'filled'}
                            required
                            label={t('FORMS.POSTAL_CODE')}
                            error={companyPostalCode.length > 0 && !validPostalCode(companyPostalCode)}
                            helperText={companyPostalCode.length > 0 && !validPostalCode(companyPostalCode) ? (t('FORMS.INVALID_POSTAL_CODE')) : ''}
                            inputProps={{ 'data-testid': 'companyPostalCode', maxLength: COMPANY_POSTALCODE_MAX_LENGTH }}
                            fullWidth
                            value={companyPostalCode}
                            onChange={(e): void => onCompanyPostalCodeChange(e)}
                        />
                        <TextField
                            data-testid='companyCityTestId'
                            style={{ marginTop: 24, width: '63%', textAlign: 'center' }}
                            id="companycity"
                            required
                            variant={'filled'}
                            label={t('FORMS.CITY')}
                            inputProps={{ 'data-testid': 'company-city', maxLength: COMPANY_CITY_MAX_LENGTH }}
                            fullWidth
                            value={companyCity}
                            onChange={(e): void => onCompanyCityChange(e)}
                        />
                        <FormControl variant={'filled'} style={{ display: 'flex', marginTop: 24 }}>
                            <InputLabel data-testid='countryNameLabelId' id="country-name-label">{t('FORMS.COUNTRY')}</InputLabel>
                            <Select
                                labelId="country-name-label"
                                id="country-name-label"
                                data-testid='select'
                                fullWidth
                                defaultValue={selectedCountry.name !== '' ? selectedCountry.name : ''}
                                value={countryName}
                                inputProps={{ 'data-testid': 'country-name' }}
                                onChange={onChangeCountryName}
                            >
                                {countryList?.map((e, code) => {
                                    return (
                                        <MenuItem id="country-name-list" key={code} value={e.name}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'left', fontSize: 14,
                                                lineHeight: 1.5, fontWeight: 400,
                                                overflow: 'hidden',
                                                boxSizing: 'border-box',
                                                whiteSpace: 'nowrap',
                                                minHeight: 48,
                                                paddingTop: 6,
                                                paddingBottom: 6,
                                                width: 'auto',
                                                paddingLeft: 16,
                                                paddingRight: 16
                                            }}>
                                            {e.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <div>
                            <FormControl variant={'filled'} style={{ marginTop: 24, marginRight: '2%' }}>
                                <InputLabel data-testid='countryCodeRequiredId' id="country-name-label">{'*'}</InputLabel>
                                <Select
                                    style={{ width: vw_90, textAlign: 'center' }}
                                    variant={'filled'}
                                    labelId="countrycode-label"
                                    inputProps={{ 'data-testid': 'country-code', maxLength: COUNTRY_CODE_MAX_LENGTH }}
                                    id="countrycode-testid"
                                    defaultValue={countryCode}
                                    value={countryCode}
                                    onChange={() => onChangeCountryCode}
                                >
                                    {countryCodeList?.map((e, code) => {
                                        return (
                                            <MenuItem id="country-code-list" key={code} value={e.code}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'left', fontSize: 14,
                                                    lineHeight: 1.5, fontWeight: 400,
                                                    overflow: 'hidden',
                                                    boxSizing: 'border-box',
                                                    whiteSpace: 'nowrap',
                                                    minHeight: 48,
                                                    paddingTop: 6,
                                                    paddingBottom: 6,
                                                    width: 'auto',
                                                    paddingLeft: 16,
                                                    paddingRight: 16
                                                }}>
                                                {'+' + e.code}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            <TextField
                                required
                                data-testid='companyPhoneNumberTestId'
                                style={{ marginTop: 24, width: md ? '81%' : '65%' }}
                                variant={'filled'}
                                label={t('USER_SETTINGS.COMPANY_PHONE_NUMBER')}
                                error={companyPhoneNumber.length > 0 && !validPhoneNumberLength(companyPhoneNumber)}
                                helperText={companyPhoneNumber.length > 0 && !validPhoneNumberLength(companyPhoneNumber) ? (t('FORMS.INVALID_PHONE_NUMBER')) : ''}
                                inputProps={{ 'data-testid': 'phone-number', maxLength: PHONE_MAX_LENGTH, minLength: PHONE_MIN_LENGTH }}
                                fullWidth
                                value={companyPhoneNumber}
                                onChange={(e): void => onChangeCompanyPhoneNumber(e)}
                            />
                        </div>
                    </div>
                </div>
                <div data-testid='PAAS-detail-right-container' style={{ float: 'right', width: md ? '49%' : '', paddingTop: vh_26 }}>
                    {/* SLA Optional */}
                    {isChecked ? (
                        <>
                            <Typography data-testid='PAASDetailsHeader1' variant='body1' style={{ fontWeight: 400, fontSize: 16, color: PXBColors.blue[500] }}>{t('USERS.SLA_OPTIONAL_HEADER')}</Typography>
                            <TextField
                                style={{ marginTop: 24, width: '100%', fontSize: 16, color: PXBColors.black[500] }}
                                id="PAASCustomerNumber"
                                data-testid='PAASCustomerNumberLabelId'
                                variant={'filled'}
                                label={t('USERS.SLA_NUMBER')}
                                error={slaNumber.length > 0 && !validSlaNumber(slaNumber)}
                                helperText={slaNumber.length > 0 && !validSlaNumber(slaNumber) ? (t('FORMS.INVALID_SLA_NUMBER')) : ''}
                                fullWidth
                                value={slaNumber}
                                onChange={(e): void => onSlaNumberChange(e)}
                                inputProps={{ 'data-testid': 'PAASCustomerNumberLabelId2', maxLength: CUSTOMER_SLA_LIMIT }}
                            />
                            <div data-testid='slaSlaNumberHelperTextId'
                                style={{ height: 17, width: '100%', color: PXBColors.gray[500] }}>
                                <small className="form-text" style={{ width: '85%', float: 'left', paddingLeft: '2%' }}>
                                    {t('CUSTOMER_DETAILS.SLA_NUMBER_HELPER_TEXT')}</small>
                                <Typography data-testid='slaNumberCharLimitId' style={{ width: 'auto', float: 'right', fontSize: 12, color: PXBColors.gray[500] }}>
                                    {slaNumber.length}/{CUSTOMER_SLA_LIMIT}
                                </Typography>
                            </div>
                            <div data-testid='calendarDivId' style={{ display: 'flex', width: '100%', flexDirection: 'column', marginTop: 24, marginBottom: 24 }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        minDate={new Date()}
                                        value={slaExpiryDate}
                                        onChange={(newValue) => {
                                            setSlaExpiryDate(newValue)
                                            setIsDateValid(true);
                                        }}
                                        slotProps={{
                                            textField: {
                                                // required: true,
                                                error: !validDate(slaExpiryDate)||(!validExpirationDate(slaExpiryDate)),
                                                helperText: (!validExpirationDate(slaExpiryDate)? (t('FORMS.SLA_EXPIRATION_DATE_INVALID')) : t('CUSTOMER_DETAILS.CALENDAR_HELPER_TEXT')),
                                                variant: 'filled',
                                                label: t('CUSTOMER_DETAILS.SLA_EXPIRATION_DATE')
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </div>
                        </>
                    ) :
                        // SLA Mandatory
                        <>
                            <Typography data-testid='PAASDetailsHeader' variant='body1' style={{ fontWeight: 400, fontSize: 16, color: PXBColors.blue[500] }}>{t('USERS.SLA_MANDATORY_HEADER')}</Typography>
                            <TextField
                                style={{ marginTop: 24, width: '100%', fontSize: 16, color: PXBColors.black[500] }}
                                id="PAASCustomerNumber"
                                data-testid='PAASCustomerNumberLabelId'
                                variant={'filled'}
                                label={t('USERS.SLA_NUMBER')}
                                error={slaNumber.length > 0 && !validSlaNumber(slaNumber)}
                                helperText={slaNumber.length > 0 && !validSlaNumber(slaNumber) ? (t('FORMS.INVALID_SLA_NUMBER')) : ''}
                                fullWidth
                                required
                                value={slaNumber}
                                onChange={(e): void => onSlaNumberChange(e)}
                                inputProps={{ 'data-testid': 'PAASCustomerNumberLabelId2', maxLength: CUSTOMER_SLA_LIMIT }}
                            />
                            <div data-testid='slaSlaNumberHelperTextId'
                                style={{ height: 17, width: '100%', color: PXBColors.gray[500] }}>
                                <small className="form-text" style={{ width: '85%', float: 'left', paddingLeft: '2%' }}>
                                    {t('CUSTOMER_DETAILS.SLA_NUMBER_HELPER_TEXT')}</small>
                                <Typography data-testid='slaNumberCharLimitId' style={{ width: 'auto', float: 'right', fontSize: 12, color: PXBColors.gray[500] }}>
                                    {slaNumber.length}/{CUSTOMER_SLA_LIMIT}
                                </Typography>
                            </div>
                            <div data-testid='calendarDivId' style={{ display: 'flex', width: '100%', flexDirection: 'column', marginTop: 24, marginBottom: 24 }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        minDate={new Date()}
                                        value={slaExpiryDate}
                                        onChange={(newValue) => setSlaExpiryDate(newValue)}
                                        slotProps={{
                                            textField: {
                                                required: true,
                                                helperText: t('CUSTOMER_DETAILS.CALENDAR_HELPER_TEXT'),
                                                variant: 'filled',
                                                label: t('CUSTOMER_DETAILS.SLA_EXPIRATION_DATE')
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </div>
                        </>
                    }

                    {/* Service Manager */}
                    <Typography data-testid='serviceManagerHeader' variant='body1' style={{ fontWeight: 400, fontSize: 16, color: PXBColors.blue[500] }}>{t('USERS.SERVICE_MANAGER')}</Typography>
                    <TextField
                        required
                        style={{ marginTop: 24 }}
                        data-testid='SM-primaryEmailTestId'
                        id="SM-primaryemail"
                        variant={'filled'}
                        label={t('ORGANIZATION_DETAILS.PRIMARY_CONTACT_EMAIL_ADDRESS')}
                        error={serviceManagerEmail.length > 0 && !validEmail(serviceManagerEmail)}
                        helperText={serviceManagerEmail.length > 0 && !validEmail(serviceManagerEmail) ? (t('FORMS.INVALID_EMAIL_ERROR')) : ''}
                        inputProps={{ 'data-testid': 'SM-primary-email', maxLength: ORG_EMAIL_MAX_LENGTH }}
                        fullWidth
                        value={serviceManagerEmail}
                        onChange={(e): void => setServiceManagerEmail(e.target.value)}
                    />
                    <TextField
                        style={{ marginTop: 24, width: '100%', fontSize: 16, color: PXBColors.black[500] }}
                        id="SM-firstName"
                        data-testid='SM-firstnameLabelId'
                        variant={'filled'}
                        label={t('FORMS.FIRST_NAME')}
                        fullWidth
                        required
                        value={serviceManagerFirstName}
                        onChange={(e): void => onsMFirstNameChange(e)}
                        inputProps={{ 'data-testid': 'SM-firstLabelId2', maxLength: FIRST_NAME_MAX_LENGTH }}
                    />
                    <TextField
                        style={{ marginTop: 24, width: '100%', fontSize: 16, color: PXBColors.black[500] }}
                        id="SM-lastname"
                        data-testid='SM-lastnameLabelId'
                        variant={'filled'}
                        label={t('FORMS.LAST_NAME')}
                        fullWidth
                        value={serviceManagerLastName}
                        onChange={(e): void => onSMLastNameChange(e)}
                        inputProps={{ 'data-testid': 'SM-lastnameLabelId2', maxLength: LAST_NAME_MAX_LENGTH }}
                    />
                    <Typography style={{ marginTop: 20 }} data-testid="SM-phone-no-header">{t('CUSTOMER_DETAILS.PHONE_NUMBERS_HEADER')}</Typography>
                    <div>
                        <FormControl variant={'filled'} style={{ marginTop: 24, marginRight: '2%' }}>
                            <InputLabel data-testid='countryCodeRequiredId' id="country-name-label">{'*'}</InputLabel>
                            <Select
                                style={{ textAlign: 'center', width: vw_133 }}
                                variant={'filled'}
                                labelId="SM-countrycode-label"
                                inputProps={{ 'data-testid': 'SM-primary-country-code', maxLength: COUNTRY_CODE_MAX_LENGTH }}
                                id="SM-countrycode-testid"
                                defaultValue={serviceManagerCountryCode1}
                                value={serviceManagerCountryCode1}
                                onChange={(e) => onChangeSMCountryCode1(e)}
                            >
                                {countryCodeList?.map((e, code) => {
                                    return (
                                        <MenuItem id="SM-country-code-list" key={code} value={e.code}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'left', fontSize: 14,
                                                lineHeight: 1.5, fontWeight: 400,
                                                overflow: 'hidden',
                                                boxSizing: 'border-box',
                                                whiteSpace: 'nowrap',
                                                minHeight: 48,
                                                paddingTop: 6,
                                                paddingBottom: 6,
                                                width: 'auto',
                                                paddingLeft: 16,
                                                paddingRight: 16
                                            }}>
                                            {'+' + e.code}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <TextField
                            required
                            data-testid='SM-mobileNumberTestId'
                            style={{ marginTop: 24, width: md ? '75%' : '65%' }}
                            variant={'filled'}
                            label={t('CUSTOMER_DETAILS.MOBILE_NUMBER')}
                            error={serviceManagerPhone1.length > 0 && !validPhoneNumberLength(serviceManagerPhone1)}
                            helperText={serviceManagerPhone1.length > 0 && !validPhoneNumberLength(serviceManagerPhone1) ? (t('FORMS.INVALID_PHONE_NUMBER')) : ''}
                            inputProps={{ 'data-testid': 'SM-mobile-number', maxLength: PHONE_MAX_LENGTH, minLength: PHONE_MIN_LENGTH }}
                            fullWidth
                            value={serviceManagerPhone1}
                            onChange={(e): void => onChangeSMPhone1(e)}
                        />
                    </div>
                    <div>
                        <Select
                            style={{ marginTop: 24, width: md ? '23%' : '33%', marginRight: '2%', textAlign: 'center' }}
                            variant={'filled'}
                            labelId="SM-countrycode-label"
                            inputProps={{ 'data-testid': 'SM-primary-backUp-country-code', maxLength: COUNTRY_CODE_MAX_LENGTH }}
                            id="SM-countrycode-testid"
                            defaultValue={serviceManagerCountryCode2}
                            value={serviceManagerCountryCode2}
                            onChange={(e) => onChangeServiceManagerCountryCode2(e)}
                        >
                            {countryCodeList?.map((e, code) => {
                                return (
                                    <MenuItem id="SM-country-code-list" key={code} value={e.code}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'left', fontSize: 14,
                                            lineHeight: 1.5, fontWeight: 400,
                                            overflow: 'hidden',
                                            boxSizing: 'border-box',
                                            whiteSpace: 'nowrap',
                                            minHeight: 48,
                                            paddingTop: 6,
                                            paddingBottom: 6,
                                            width: 'auto',
                                            paddingLeft: 16,
                                            paddingRight: 16
                                        }}>
                                        {'+' + e.code}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        <TextField
                            data-testid='SMofficeNumberTestId'
                            style={{ marginTop: 24, width: md ? '75%' : '65%' }}
                            variant={'filled'}
                            label={t('CUSTOMER_DETAILS.OFFICE_NUMBER')}
                            error={serviceManagerPhone2.length > 0 && !validPhoneNumberLength(serviceManagerPhone2)}
                            helperText={serviceManagerPhone2.length > 0 && !validPhoneNumberLength(serviceManagerPhone2) ? (t('FORMS.INVALID_PHONE_NUMBER')) : ''}
                            inputProps={{ 'data-testid': 'SM-office-number', maxLength: PHONE_MAX_LENGTH, minLength: PHONE_MIN_LENGTH }}
                            fullWidth
                            value={serviceManagerPhone2}
                            onChange={(e): void => onChangeServiceManagerPhone2(e)}
                        />
                    </div>
                    <TextField
                        style={{ marginTop: 24, width: '100%', float: 'left', wordWrap: 'break-word', color: PXBColors.black[500] }}
                        id="SMcomments"
                        data-testid='SMcommentsId'
                        variant={'filled'}
                        label={t('LOGBOOK_DETAILS.COMMENTS')}
                        rows={2}
                        multiline
                        fullWidth
                        onChange={(evt: ChangeEvent<HTMLInputElement>): void => setServiceManagerComments(evt.target.value)}
                        value={serviceManagerComments}
                        inputProps={{ 'data-testid': 'SM-comments', maxLength: ORG_COMMENTS_LIMIT }}
                    />
                    <div
                        style={{ height: 17, width: '100%', color: PXBColors.gray[500] }}>
                        <small data-testid='SMCommmentsHelperText' className="form-text" style={{ width: '86%', float: 'left', paddingLeft: '2%', marginBottom: 24 }}>
                            {t('ORGANIZATION_MANAGE.COMMENTS_HELPER')}</small>
                        <Typography data-testid='SMCommentsLimit' style={{ width: 'auto', float: 'right', fontSize: 12, color: PXBColors.gray[500] }}>
                            {serviceManagerComments.length}/{ORG_COMMENTS_LIMIT}
                        </Typography>
                    </div>

                    {!isChecked &&
                        <>
                            {/* Primary Contact Info */}
                            <Typography style={{ padding: '12px 16px 12px 0px', marginTop: 20, fontWeight: 400, fontSize: 16, color: PXBColors.blue[500], display: 'inline-block' }} data-testid="primary-info-header">{t('CUSTOMER_DETAILS.PRIMARY_CONTACT_INFO')}</Typography>
                            <FormControl variant="filled" sx={{ width: '100%', marginTop: 2 }}>
                                <InputLabel id="contactType-select-filled-label" data-testid='primaryContactTypeId'>{t('CUSTOMER_DETAILS.CONTACT_TYPE')}</InputLabel>
                                <Select
                                    labelId="contactType-simple-select-filled-label"
                                    id="contactType-simple-select-filled"
                                    value={primaryContactType}
                                    onChange={handlePrimaryContactTypeChange}
                                    inputProps={{ 'data-testid': 'primary-contact-type' }}
                                >
                                    {contactUpdateList.map((option) => (
                                        <MenuItem sx={{ textAlign: 'center' }}
                                            key={option.key} value={option.value} data-testid={option.value} >
                                            {t(option.value)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                required
                                style={{ marginTop: 24 }}
                                data-testid='primaryEmailTestId'
                                id="primaryemail"
                                variant={'filled'}
                                label={t('ORGANIZATION_DETAILS.PRIMARY_CONTACT_EMAIL_ADDRESS')}
                                error={primaryEmail.length > 0 && !validEmail(primaryEmail)}
                                helperText={primaryEmail.length > 0 && !validEmail(primaryEmail) ? (t('FORMS.INVALID_EMAIL_ERROR')) : ''}
                                inputProps={{ 'data-testid': 'primary-email', maxLength: ORG_EMAIL_MAX_LENGTH }}
                                fullWidth
                                value={primaryEmail}
                                onChange={(e): void => setPrimaryEmail(e.target.value)}
                            />
                            <TextField
                                style={{ marginTop: 24, width: '100%', fontSize: 16, color: PXBColors.black[500] }}
                                id="firstName"
                                data-testid='firstnameLabelId'
                                variant={'filled'}
                                label={t('FORMS.FIRST_NAME')}
                                fullWidth
                                required
                                value={primaryFirstName}
                                onChange={(e): void => onPrimaryFirstNameChange(e)}
                                inputProps={{ 'data-testid': 'firstLabelId2', maxLength: FIRST_NAME_MAX_LENGTH }}
                            />
                            <TextField
                                style={{ marginTop: 24, width: '100%', fontSize: 16, color: PXBColors.black[500] }}
                                id="lastname"
                                data-testid='lastnameLabelId'
                                variant={'filled'}
                                label={t('FORMS.LAST_NAME')}
                                fullWidth
                                required
                                value={primaryLastName}
                                onChange={(e): void => onPrimaryLastNameChange(e)}
                                inputProps={{ 'data-testid': 'lastnameLabelId2', maxLength: LAST_NAME_MAX_LENGTH }}
                            />
                            <Typography style={{ marginTop: 20 }} data-testid="phone-no-header">{t('CUSTOMER_DETAILS.PHONE_NUMBERS_HEADER')}</Typography>
                            <div>
                                <FormControl variant={'filled'} style={{ marginTop: 24, marginRight: '2%' }}>
                                    <InputLabel data-testid='countryCodeRequiredId' id="country-name-label">{'*'}</InputLabel>
                                    <Select
                                        style={{ width: vw_133, textAlign: 'center' }}
                                        variant={'filled'}
                                        labelId="countrycode-label"
                                        inputProps={{ 'data-testid': 'primary-country-code', maxLength: COUNTRY_CODE_MAX_LENGTH }}
                                        id="countrycode-testid"
                                        defaultValue={primarycountryCode}
                                        value={primarycountryCode}
                                        onChange={(e) => onChangePrimaryCountryCode(e)}
                                    >
                                        {countryCodeList?.map((e, code) => {
                                            return (
                                                <MenuItem id="country-code-list" key={code} value={e.code}
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'left', fontSize: 14,
                                                        lineHeight: 1.5, fontWeight: 400,
                                                        overflow: 'hidden',
                                                        boxSizing: 'border-box',
                                                        whiteSpace: 'nowrap',
                                                        minHeight: 48,
                                                        paddingTop: 6,
                                                        paddingBottom: 6,
                                                        width: 'auto',
                                                        paddingLeft: 16,
                                                        paddingRight: 16
                                                    }}>
                                                    {'+' + e.code}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                                <TextField
                                    required
                                    data-testid='mobileNumberTestId'
                                    style={{ marginTop: 24, width: md ? '75%' : '65%' }}
                                    variant={'filled'}
                                    label={t('CUSTOMER_DETAILS.MOBILE_NUMBER')}
                                    error={primaryPhoneNumber.length > 0 && !validPhoneNumberLength(primaryPhoneNumber)}
                                    helperText={primaryPhoneNumber.length > 0 && !validPhoneNumberLength(primaryPhoneNumber) ? (t('FORMS.INVALID_PHONE_NUMBER')) : ''}
                                    inputProps={{ 'data-testid': 'mobile-number', maxLength: PHONE_MAX_LENGTH, minLength: PHONE_MIN_LENGTH }}
                                    fullWidth
                                    value={primaryPhoneNumber}
                                    onChange={(e): void => onChangePrimaryPhoneNumber(e)}
                                />
                            </div>
                            <div>
                                <Select
                                    style={{ marginTop: 24, width: md ? '23%' : '33%', marginRight: '2%', textAlign: 'center' }}
                                    variant={'filled'}
                                    labelId="countrycode-label"
                                    inputProps={{ 'data-testid': 'primary-backUp-country-code', maxLength: COUNTRY_CODE_MAX_LENGTH }}
                                    id="countrycode-testid"
                                    defaultValue={primaryBackUpcountryCode}
                                    value={primaryBackUpcountryCode}
                                    onChange={(e) => onChangePrimaryBackUpCountryCode(e)}
                                >
                                    {countryCodeList?.map((e, code) => {
                                        return (
                                            <MenuItem id="country-code-list" key={code} value={e.code}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'left', fontSize: 14,
                                                    lineHeight: 1.5, fontWeight: 400,
                                                    overflow: 'hidden',
                                                    boxSizing: 'border-box',
                                                    whiteSpace: 'nowrap',
                                                    minHeight: 48,
                                                    paddingTop: 6,
                                                    paddingBottom: 6,
                                                    width: 'auto',
                                                    paddingLeft: 16,
                                                    paddingRight: 16
                                                }}>
                                                {'+' + e.code}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <TextField
                                    data-testid='officeNumberTestId'
                                    style={{ marginTop: 24, width: md ? '75%' : '65%' }}
                                    variant={'filled'}
                                    label={t('CUSTOMER_DETAILS.OFFICE_NUMBER')}
                                    error={primaryBackUpPhoneNumber.length > 0 && !validPhoneNumberLength(primaryBackUpPhoneNumber)}
                                    helperText={primaryBackUpPhoneNumber.length > 0 && !validPhoneNumberLength(primaryBackUpPhoneNumber) ? (t('FORMS.INVALID_PHONE_NUMBER')) : ''}
                                    inputProps={{ 'data-testid': 'office-number', maxLength: PHONE_MAX_LENGTH, minLength: PHONE_MIN_LENGTH }}
                                    fullWidth
                                    value={primaryBackUpPhoneNumber}
                                    onChange={(e): void => onChangePrimaryBackUpPhoneNumber(e)}
                                />
                            </div>
                            <TextField
                                style={{ marginTop: 24, width: '100%', float: 'left', wordWrap: 'break-word', color: PXBColors.black[500] }}
                                id="primarycomments"
                                data-testid='primarycommentsId'
                                variant={'filled'}
                                label={t('LOGBOOK_DETAILS.COMMENTS')}
                                rows={2}
                                multiline
                                fullWidth
                                onChange={(evt: ChangeEvent<HTMLInputElement>): void => setPrimaryComments(evt.target.value)}
                                value={primaryComments}
                                inputProps={{ 'data-testid': 'primary-comments', maxLength: ORG_COMMENTS_LIMIT }}
                            />
                            <div
                                style={{ height: 17, width: '100%', color: PXBColors.gray[500] }}>
                                <small data-testid='primaryCommmentsHelperText' className="form-text" style={{ width: '86%', float: 'left', paddingLeft: '2%', marginBottom: 24 }}>
                                    {t('ORGANIZATION_MANAGE.COMMENTS_HELPER')}</small>
                                <Typography data-testid='primaryCommentsLimit' style={{ width: 'auto', float: 'right', fontSize: 12, color: PXBColors.gray[500] }}>
                                    {primaryComments.length}/{ORG_COMMENTS_LIMIT}
                                </Typography>
                            </div>

                            {/* Secondary Contact Info */}
                            <Typography style={{ padding: '12px 16px 12px 0px', marginTop: 20, fontWeight: 400, fontSize: 16, color: PXBColors.blue[500] }} data-testid="secondary-info-header">{t('USERS.SECONDARY_CONTACT_INFO_OPTIONAL')}</Typography>
                            <FormControl variant="filled" sx={{ width: '100%' }}>
                                <InputLabel id="contactType-select-filled-label" data-testid='secondaryContactTypeId'>{t('CUSTOMER_DETAILS.CONTACT_TYPE')}</InputLabel>
                                <Select
                                    labelId="contactType-simple-select-filled-label"
                                    id="contactType-simple-select-filled"
                                    value={secondaryContactType}
                                    onChange={handleSecondaryContactTypeChange}
                                    inputProps={{ 'data-testid': 'secondary-contact-type' }}
                                >
                                    {contactUpdateList.map((option) => (
                                        <MenuItem sx={{ textAlign: 'center' }}
                                            key={option.key} value={option.value} data-testid={option.value} >
                                            {t(option.value)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                style={{ marginTop: 24 }}
                                data-testid='secondaryEmailTestId'
                                id="secondaryemail"
                                variant={'filled'}
                                label={t('ORGANIZATION_DETAILS.SECONDARY_CONTACT_EMAIL_ADDRESS')}
                                error={secondaryEmail.length > 0 && !validEmail(secondaryEmail)}
                                helperText={secondaryEmail.length > 0 && !validEmail(secondaryEmail) ? (t('FORMS.INVALID_EMAIL_ERROR')) : ''}
                                inputProps={{ 'data-testid': 'secondary-email', maxLength: ORG_EMAIL_MAX_LENGTH }}
                                fullWidth
                                value={secondaryEmail}
                                onChange={(e): void => setSecondaryEmail(e.target.value)}
                            />
                            <TextField
                                style={{ marginTop: 24, width: '100%', fontSize: 16, color: PXBColors.black[500] }}
                                id="secondaryFirstname"
                                data-testid='secondaryFirstnameLabelId'
                                variant={'filled'}
                                label={t('FORMS.FIRST_NAME')}
                                fullWidth
                                value={secondaryFirstName}
                                onChange={(e): void => onSecondaryFirstNameChange(e)}
                                inputProps={{ 'data-testid': 'secondaryFirstnameLabelId2', maxLength: FIRST_NAME_MAX_LENGTH }}
                            />
                            <TextField
                                style={{ marginTop: 24, width: '100%', fontSize: 16, color: PXBColors.black[500] }}
                                id="secondarylastname"
                                data-testid='secondarylastNameLabelId'
                                variant={'filled'}
                                label={t('FORMS.LAST_NAME')}
                                fullWidth
                                value={secondaryLastName}
                                onChange={(e): void => onSecondaryLastNameChange(e)}
                                inputProps={{ 'data-testid': 'secondarylastNameLabelId2', maxLength: LAST_NAME_MAX_LENGTH }}
                            />
                            <Typography style={{ padding: '12px 16px 12px 0px' }} data-testid="secondary-phone-no-header">{t('CUSTOMER_DETAILS.PHONE_NUMBERS_HEADER')}</Typography>
                            <div>
                                <Select
                                    style={{ marginTop: 24, width: md ? '23%' : '33%', marginRight: '2%', textAlign: 'center' }}
                                    variant={'filled'}
                                    labelId="secondary-countrycode-label"
                                    inputProps={{ 'data-testid': 'secondary-country-code', maxLength: COUNTRY_CODE_MAX_LENGTH }}
                                    id="secondary-countrycode-testid"
                                    defaultValue={secondarycountryCode}
                                    value={secondarycountryCode}
                                    onChange={(e) => onChangeSecondaryCountryCode(e)}
                                >
                                    {countryCodeList?.map((e, code) => {
                                        return (
                                            <MenuItem id="country-code-list" key={code} value={e.code}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'left', fontSize: 14,
                                                    lineHeight: 1.5, fontWeight: 400,
                                                    overflow: 'hidden',
                                                    boxSizing: 'border-box',
                                                    whiteSpace: 'nowrap',
                                                    minHeight: 48,
                                                    paddingTop: 6,
                                                    paddingBottom: 6,
                                                    width: 'auto',
                                                    paddingLeft: 16,
                                                    paddingRight: 16
                                                }}>
                                                {'+' + e.code}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <TextField
                                    data-testid='secondaryMobileNumberTestId'
                                    style={{ marginTop: 24, width: md ? '75%' : '65%' }}
                                    variant={'filled'}
                                    label={t('CUSTOMER_DETAILS.MOBILE_NUMBER')}
                                    error={secondaryPhoneNumber.length > 0 && !validPhoneNumberLength(secondaryPhoneNumber)}
                                    helperText={secondaryPhoneNumber.length > 0 && !validPhoneNumberLength(secondaryPhoneNumber) ? (t('FORMS.INVALID_PHONE_NUMBER')) : ''}
                                    inputProps={{ 'data-testid': 'secondary-mobile-number', maxLength: PHONE_MAX_LENGTH, minLength: PHONE_MIN_LENGTH }}
                                    fullWidth
                                    value={secondaryPhoneNumber}
                                    onChange={(e): void => onChangeSecondaryPhoneNumber(e)}
                                />
                            </div>
                            <div>
                                <Select
                                    style={{ marginTop: 24, width: md ? '23%' : '33%', marginRight: '2%', textAlign: 'center' }}
                                    variant={'filled'}
                                    labelId="countrycode-label"
                                    inputProps={{ 'data-testid': 'secondary-backup-country-code', maxLength: COUNTRY_CODE_MAX_LENGTH }}
                                    id="countrycode-testid"
                                    defaultValue={secondaryBackUpcountryCode}
                                    value={secondaryBackUpcountryCode}
                                    onChange={(e) => onChangeSecondaryBackUpCountryCode(e)}
                                >
                                    {countryCodeList?.map((e, code) => {
                                        return (
                                            <MenuItem id="country-code-list" key={code} value={e.code}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'left', fontSize: 14,
                                                    lineHeight: 1.5, fontWeight: 400,
                                                    overflow: 'hidden',
                                                    boxSizing: 'border-box',
                                                    whiteSpace: 'nowrap',
                                                    minHeight: 48,
                                                    paddingTop: 6,
                                                    paddingBottom: 6,
                                                    width: 'auto',
                                                    paddingLeft: 16,
                                                    paddingRight: 16
                                                }}>
                                                {'+' + e.code}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <TextField
                                    data-testid='secondaryOfficeNumberTestId'
                                    style={{ marginTop: 24, width: md ? '75%' : '65%' }}
                                    variant={'filled'}
                                    label={t('CUSTOMER_DETAILS.OFFICE_NUMBER')}
                                    error={secondaryBackUpPhoneNumber.length > 0 && !validPhoneNumberLength(secondaryBackUpPhoneNumber)}
                                    helperText={secondaryBackUpPhoneNumber.length > 0 && !validPhoneNumberLength(secondaryBackUpPhoneNumber) ? (t('FORMS.INVALID_PHONE_NUMBER')) : ''}
                                    inputProps={{ 'data-testid': 'secondary-office-number', maxLength: PHONE_MAX_LENGTH, minLength: PHONE_MIN_LENGTH }}
                                    fullWidth
                                    value={secondaryBackUpPhoneNumber}
                                    onChange={(e): void => onChangeSecondaryBackUpPhoneNumber(e)}
                                />
                            </div>
                            <TextField
                                style={{ marginTop: 24, width: '100%', float: 'left', wordWrap: 'break-word', color: PXBColors.black[500] }}
                                id="secondarycomments"
                                data-testid='secondarycommentsId'
                                variant={'filled'}
                                label={t('LOGBOOK_DETAILS.COMMENTS')}
                                rows={2}
                                multiline
                                fullWidth
                                onChange={(evt: ChangeEvent<HTMLInputElement>): void => setSecondaryComments(evt.target.value)}
                                value={secondaryComments}
                                inputProps={{ 'data-testid': 'secondary-comments', maxLength: ORG_COMMENTS_LIMIT }}
                            />
                            <div data-testid='helperTextIdTechnician'
                                style={{ height: 17, width: '100%', color: PXBColors.gray[500] }}>
                                <small className="form-text" style={{ width: '86%', float: 'left', paddingLeft: '2%' }}>
                                    {t('ORGANIZATION_MANAGE.COMMENTS_HELPER')}</small>
                                <Typography data-testid='secondaryCommentsLimit' style={{ width: 'auto', float: 'right', fontSize: 12, color: PXBColors.gray[500] }}>
                                    {secondaryComments.length}/{ORG_COMMENTS_LIMIT}
                                </Typography>
                            </div>
                        </>
                    }
                </div>
            </div>
            {
                displayErrorMessage &&
                <Snackbar
                    open={true}
                    onClose={() => setDisplayErrorMessage(false)}
                    autoHideDuration={5000}
                    title={''}
                    message={alertErrorMessage}
                    action={
                        <Button color="inherit" size="small" onClick={() => setDisplayErrorMessage(false)}>
                            {t('ACTIONS.OKAY')}
                        </Button>
                    }
                    ContentProps={{ sx: { background: PXBColors.red[500] } }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    sx={{ bottom: { xs: 90, sm: 0 } }}

                    style={{ height: 200, width: 200, flexGrow: 0 }}
                />
            }
        </div >
    );
};
