const resources = {
  translation: {
    DASHBOARD: {
      LOCATION_CARD: {
        HEADER: {
          SUBTITLE: {
            NO_DATA: "0",
            ALARM: "{{count}} Alarme",
            ALARM_plural: "{{count}} Alarmes",
            ALARM_noData: "0 Alarme",
            NORMAL: "Normal",
          },
          INFO: {
            SEPARATOR: ",",
            DEVICE: "{{count}} appareil",
            DEVICE_plural: "{{count}} appareils",
            SITE: "{{count}} Site",
            SITE_plural: "{{count}} Sites",
            DEVICE_noData: "0 appareil",
            SITE_noData: "0 Site",
          },
        },
        BODY: {
          ALARMS: "{{count}} Alarme",
          ALARMS_plural: "{{count}} Alarmes",
          EVENTS: "{{count}} événement",
          EVENTS_plural: "{{count}} événements",
          ALARMS_noData: "0 Alarme",
          EVENTS_noData: "0 événement",
        },
        FOOTER: {
          VIEW_LOCATION: "Voir l'emplacement",
        },
      },
      SITE_CARD: {
        HEADER: {
          SUBTITLE: {
            NO_DATA: "--",
          },
          INFO: {
            DEVICE: "{{count}} appareil",
            DEVICE_plural: "{{count}} appareils",
            DEVICE_noData: "0 appareil",
          },
        },
        BODY: {
          ONLINE_noData: "--'",
          ALARMS: "{{count}} Alarme",
          ALARMS_plural: "{{count}} Alarmes",
          EVENTS: "{{count}} événement",
          EVENTS_plural: "{{count}} événements",
          ALARMS_noData: "0 Alarme",
          EVENTS_noData: "0 événement",
          OFFLINE: "Hors ligne",
          ONLINE: "En ligne",
        },
        FOOTER: {
          VIEW_LOCATION: "Voir le site",
        },
      },
      TIMELINE_DETAILS: {
        ACKNOWLEDGED: "Acquitté",
        OPEN: "Actif",
        CLOSED: "Fermer",
        EARLIER: "Plus tôt",
        FUTURE: "Futur",
        INACTIVE: "Inactif",
        LAST_WEEK: "La semaine dernière ",
        THIS_WEEK: "Cette semaine",
        TIMELINE: "Calendrier",
        TODAY: "Aujourd'hui",
        UNACKNOWLEDGED: "Non-acquitté",
        YESTERDAY: "Hier",
      },
      ADD_BUILDING: "Ajouter un Bâtiment à",
      AUTO_REDIRECT_BANNER: {
        ADD_LOCATION_BUTTON: "Ajouter un emplacement",
        DISMISS: "Rejeter",
        MESSAGE: "Vous avez été automatiquement redirigé vers {{location}} puisque vous n'avez aucun autre emplacement de premier niveau.",
      },
      USERS_ADMIN: "Administrateur",
      USERS_ADMIN_S: "Administrateurs",
      ADMINS_TITLE: "Administrateurs",
      SITE_DETAILS: {
        READING_CARDS: {
          ALARM: "Alarme",
          BATTERY: "Batterie",
          FULL: "Complet",
          OFFLINE: "Hors ligne",
          ONLINE: "En ligne",
          PENDING: "en attente",
          UNKNOWN: "Inconnu",
          VIEW_ALL: "Tout voir",
        },
        ALL_DETAILS: "Tous les détails",
        ALL_DEVICES: "Tous les appareils",
        TRENDS_CARD: {
          CHANNELS: "Canaux",
          DAY: "Jour",
          DAYS: "Jours",
          TITLE: "Trends",
          TRENDVIEWER: "Trendviewer",
          VIEW_ALL: "View Full Trendviewer",
        },
        DEVICES_CARD: {
          TITLE: "appareils",
          VIEW_ALL: "Voir tous les appareils",
        },
        TIMELINE_CARD: {
          TITLE: "Calendrier",
          VIEW_ALL_EVENTS: "Voir tous les événements",
        },
      },
      ALL_BUILDINGS_COUNT: "Tous les bâtiments  ({{count}})",
      ALL_LOCATIONS: "Tous les emplacements",
      ALL_ORGANIZATION: "Toutes les Organisations",
      ALL_DEVICES_COUNT: "Tous les systèmes  ({{count}})",
      ADMINS_AND: "and",
      DASHBOARD_ASSETS_TITLE: "Systèmes",
      STATUS_BLOCKED: "Bloqué",
      STATUS_COUNT_BLOCKED: "Bloqué  ({{count}})",
      BUILDING_CONTACT: "Contact du Bâtiment",
      BUILDING_CONTACT_DETAILS_LABEL: "Contacts du Bâtiment",
      CHOOSE_LOCATION: "Choisir l'emplacement",
      CONTACT_MANAGER: "Contacter le gestionnaire de votre compte",
      ADD_ORGANIZATION: "Créer une Organisation",
      OVERVIEW_TITLE: "Tableau de bord",
      DASHBOARD_TITLE: "Tableau de bord",
      DEVICES_TITLE: "appareils",
      EARLIER: "Plus tôt",
      EDIT_CONTACT_BUTTON: "Modifier les informations du contact",
      BUILDING_DETAIL_EMAIL: "E-mail",
      STATUS_EMPTY: "Vide",
      STATUS_COUNT_EMPTY: "Vide ({{count}})",
      ERROR_LOADING_CHANNELS: "Erreur lors du chargement des canaux",
      ERROR_LOADING_DATA: "Erreur lors du chargement des données",
      ERROR_LOADING_DEVICES: "Erreur lors du chargement des appareils",
      ERROR_LOADING_EVENT: "Erreur lors du chargement de l'événement",
      ERROR_LOADING_EVENTS: "Erreur lors du chargement des événements",
      ERROR_LOADING_LOCATION: "Erreur lors du chargement de l'emplacement",
      ERROR_LOADING_LOCATIONS: "Erreur lors du chargement des emplacements",
      ERROR_LOADING_ORGANIZATION: "Erreur lors du chargement de l'organisation",
      ERROR_LOADING_SITE: "Erreur lors du chargement du site",
      ERROR_LOADING_SITES: "Erreur lors du chargement des sites",
      ERROR_LOADING_TRENDS: "Erreur!",
      EVENT_NOT_FOUND: "Aucun événement trouvé",
      STATUS_ANOMALY: "En anomalie",
      STATUS_COUNT_ANOMALY: "En erreur ({{count}})",
      LAST_90_DAYS: "Les 90 derniers jours",
      LATEST_LOGS: "Derniers événements",
      LOCATION_NOT_FOUND: "Aucun emplacement trouvé",
      LOCATIONS_TITLE: "Emplacements",
      LOCATION: "Emplacements",
      DASHBOARD_LOGBOOK_TITLE: "Journal",
      MAIN_CONTACT_LABEL: "Contact principal du Bâtiment",
      ADMINS_MORE: "more",
      DASHBOARD_MANAGE_ORGANIZATION_TITLE: "Mes organisations",
      NO_FOUND: "Aucune {{entity}} trouvé",
      NO_EVENTS: "Aucune alarme",
      NO_CHANNELS: "Aucun canal",
      NO_CHANNELS_ENABLED: "Aucun canal activé",
      NO_DATA: "Aucune donnée",
      NO_DATA_POINTS: "Aucune donnée pour la plage spécifiée",
      NO_DEVICES: "Aucun appareil",
      NO_LOCATION_SELECTED: "Aucun emplacement sélectionné",
      NO_LOCATIONS: "Aucun emplacement",
      NO_ORGANIZATION_SELECTED: "Aucune organisation sélectionnée",
      NO_SITES: "Aucun site",
      NO_TRENDS: "Aucune tendance",
      NO_USER_DATA: "Aucune donnée utilisateur",
      STATUS_COUNT_OFFLINE: "Hors ligne  ({{count}})",
      ORGANIZATION_TITLE: "Organisation",
      ORGANIZATION_NOT_FOUND: "Aucune organisation trouvée",
      ORGANIZATION: {
        ORGANIZATION: "Organisations",
      },
      BUILDING_DETAIL_PHONE: "Téléphone",
      PLATFORM_USER_DASHBOARD_WARNING: "Veuillez utiliser l'application Web pour explorer toutes les fonctionnalités",
      PROJECT: "projet",
      STATUS_READY: "Prêt",
      STATUS_COUNT_READY: "Prêt ({{count}})",
      DASHBOARD_REPORTS_TITLE: "Rapports",
      SAVE_USER_DETAILS: "Enregistrer les informations utilisateur",
      SECONDARY_CONTACT_LABEL: "Second contact du Bâtiment",
      SITE_NOT_FOUND: "Aucun site trouvé",
      SITE: "Sites",
      TIMELINE_TITLE: "Calendrier",
      NEW_SITE: "rejoindre ou créer {{entity}}.",
      UNKNOWN_DEVICE_TYPE: "Inconnu",
      EDIT_USER_DETAILS: "Détails de l'utilisateur",
      USERS_TITLE: "Utilisateurs",
      VIEW_LOGBOOK: "Voir les événements",
      USERS_VIEWER: "Utilisateur",
      USERS_VIEWER_S: "Utilisateurs",
      NOT_ACCESS: "Vous n'avez pas encore accès à {{entity}} ",
      STARRED_ORGANIZATIONS: "Afficher uniquement les favoris",
    },
    EVENT_DETAILS: {
      UNKNOWN: "--",
      ACKNOWLEDGE: "Acquitter",
      ACKNOWLEDGED: "Acquitté",
      ACKNOWLEDGE_BY: "Acquitté par  {{user}} à",
      ACKNOWLWEGED_ERROR: "L'acquittement n'a pas pu être communiqué au périphérique final à {{errorDate}}. Si ce problème persiste, veuillez contacter notre équipe d'assistance au {{phoneno}} ou par e-mail",
      DEVICE_MODEL: "Modèle",
      DEVICE_SERIAL: "Numéro de série",
      ERROR_LOADING_DEVICE_DETAILS: "Erreur de chargement des détails des appareils",
      ERROR_LOADING_EVENT_DETAILS: "Echec du chargement d'information sur l'événement ",
      TITLE: "Détails de l'événement",
      ALARM_STATUS: "Initial Alarm Status",
      LAST_DATA_RECEIVED: "Dernière mise à jour d'alarme reçue",
      NO_DEVICE_DETAILS: "Aucune information sur l'appareil",
      NO_EVENT_DETAILS: "Aucune information sur l'événement",
      PLEASE_RETRY: "Recommencer s'il vous plaît",
      SENDING: "Sending…",
      SET_POINT: "Régler (le seuil)",
      THRESHOLD: "Seuil",
      TIME_ALARM_SENT: "Heure de l'alarme initiale",
      EMPTY_VALUE: "nan",
    },
    DEVICE_DETAILS: {
      EMPTY_VALUE: "--'",
      USERS_ADMINS: "Administrateur",
      USERS_VIEWER: "Utilisateur",
      FIRMWARE_AVAILABLE_GUIDE_LINK: "(Guide)",
      USERS_COUNTS: "{{adminCount}} administrateur, {{viewerCount}} utilisateur",
      FIRMWARE_AVAILABLE_GUIDE: "{{availableVersion}} Disponible",
      LUMINARY_ATSD: "ATSD #",
      CONNECTION_TYPE_BLE: "BLE",
      BLOCK: "Bloqué",
      BUILDING: "Bâtiment",
      CONNECTION_TYPE_CELLULAR: "Cellulaire",
      LUMINARY_CIRCUIT: "Circuit",
      LUMINARY_CIRCUIT_HASH: "Circuit #",
      CITY: "Ville",
      CONNECTED: "Connecté",
      CONNECTION_TYPE: "Type de connexion",
      DATE_COMMISSIONED: "Date de mise en service",
      DEVICE_MODEL: "Modèle",
      DEVICE_SERIAL: "Numéro de série",
      DURATION_TEST: "Test d'autonomie",
      DURATION_TEST_DURATION: "Durée du test d'autonomie",
      ERROR: "Erreur",
      CHANNEL_DATA_ERROR: "Erreur de chargement des données data",
      DEVICE_CONNECTION_ERROR: "Erreur de chargement des données de connexion",
      DEVICE_DETAILS_ERROR: "Erreur de chargement des détails des appareils",
      CONNECTION_TYPE_ETHERNET: "Ethernet",
      EVENT: "Evénement",
      FIRMWARE_VERSION: "Version du logiciel",
      FUNCTIONAL_TEST: "Test fonctionnel",
      DELAY_FUNCTIONAL_TEST: "Retard du test fonctionnel",
      GATEWAY_ADDRESS: "Adresse de la gateway",
      GATEWAY_NAME: "Nom de la gateway",
      GATEWAY_COUNT: "Gateway de {{systemCount}} système",
      GATEWAY_COUNT_SYSTEMS: "Gateway de {{systemCount}} systèmes",
      GATEWAYS: "Gateways",
      HOSTNAME: "Nom de domaine",
      LUMINARY_DETAIL_INFO: "Info",
      IPADDRESS: "Adresse IP",
      LAST_DATA: "Dernières données",
      LUMINAIRE: "Luminaire",
      LUMINARY_ADDRESS: "Adresse du luminaire",
      LUMINAIRES: "Luminaires",
      LUMINARIES_COUNTS: "Luminaires",
      MAC_ADDRESS: "Mac Address",
      CONNECTION_TYPE_MODBUS: "Modbus",
      LUMINARY_MODEL: "Modèle",
      CONNECTION_TYPE_MQTT: "MQTT",
      LUMINARY_NAME: "Nom",
      NAME: "Nom",
      NETWORK_DETAILS: "Informations réseau",
      NEXT: "Suivant",
      LUMINARY_DISCRIPTION: "Prochain test d'autonomie : ",
      NEXT_DURATION_TEST: "Prochain test d'autonomie : ",
      NEXT_FUNCTIONAL_TEST: "Prochain test fonctionnel :",
      NO_CHANNEL_DATA: "Aucune donnée de canal",
      NO_DEVICE_CONNECTION: "Aucune donnée de la connexion du système",
      NO_DEVICE_DETAILS: "Aucune donnée du système",
      OFFLINE: "Hors ligne",
      ONLINE: "En ligne",
      ORGANIZATION: "Organisation",
      PART_NUMBER: "Référence",
      PREVIOUS: "Précédent",
      PREVIOUS_DURATION_TEST: "Dernier test d'autonomie : ",
      PREVIOUS_FUNCTIONAL_TEST: "Dernier test fonctionnel",
      READY: "Prêt",
      CONNECTION_TYPE_RS485: "RS485",
      SERIAL_NUMBER: "Numéro de série",
      GATEWAY_SHOW_ALL: "Voir tout",
      STATUS: "Etats",
      LUMINARY_STATUS: "Etats",
      SUBNET_MASK: "Subnet Mask",
      SYSTEM: "Système",
      SYSTEMS: "Système",
      LUMINARY_DETAIL_TEST: "Tests",
      TYPE: "Type",
      LUMINARY_TYPE: "Type",
      UPDATE_REQUIRED: "Mise à jour nécessaire",
      USERS: "Utilisateurs",
      WARNING: "Attention",
      CONNECTION_TYPE_WIFI: "Wi-Fi",
      CONNECTION_TYPE_WIREPASS: "Wirepas",
      UNASSIGNED: "Non assigné",
    },
    FORMS: {
      OPTIONAL: "Optionnel",
      BUILDING_COMPANY_ADDRESS: "Adresse - Ligne 1",
      BUILDING_COMPANY_ADDRESS2: "Adresse - Ligne 2",
      BUILDING_DETAILS: "Information du bâtiment ",
      CITY: "Ville",
      USER_ADDRESS_1: "Adresse de la société - Ligne 1",
      USER_ADDRESS_2: "Adresse de la société - Ligne 2",
      COMPANY_NAME: "Nom de société",
      CONFIRM_PASSWORD: "Confirmer nouveau mot de passe",
      COUNTRY: "Pays*",
      HELPING_TEXT: "Les champs (*) sont obligatoires",
      FIRST_NAME: "Prénom",
      LAST_NAME: "Nom",
      LOCAL_PRIMARY_EMAIL: "E-mail du principal contact local",
      LOCAL_PRIMARY_CONTACT_INFO: "Informations du principal contact local ",
      LOCAL_SECONDARY_EMAIL: "E-mail d'un second contact local",
      LOCAL_SECONDARY_CONTACT_INFO: "Information d'un second contact local ",
      ORGANIZATION_ADDRESS_HEADER: "Adresse de l'organisation ",
      COMPANY_ADDRESS: "Adresse de l'organisation - ligne 1",
      ADDRESS_LINE_2: "Adresse de l'organisation - ligne 2",
      ORGANIZATION_DETAILS_HEADER: "Détails de l'organisation",
      PASSWORD: "Mot de passe",
      PASS_MATCH_ERROR: "Les mots de passe doivent être identiques",
      PHONE_NUMBER: "Numéro de téléphone",
      INVALID_CUSTOMER_NUMBER: "Veuillez entrer un numéro client valide",
      INVALID_EMAIL_ERROR: "Veuillez entrer une adresse e-mail valide",
      INVALID_DETAILS: "Veuillez entrer des paramètres d'entrée valide",
      INVALID_PHONE_NUMBER: "Entrer un numéro de téléphone valide",
      INVALID_POSTAL_CODE: "Entrer un code postal valide",
      INVALID_SLA_NUMBER: "Veuillez entrer un numéro de contrat de service d'au moins 8 caractères.",
      POSTAL_CODE: "Code postal",
      PRIMARY_CONTACT_INFORMATION: "Information du principal contact",
      RESET_PASSWORD: "réinitialisation du mot de passe",
      TOGGLE_PASSWORD_VISIBILITY: "visibilité du mot de passe",
      VERIFICATION_CODE: "Code de vérification",
      SLA_EXPIRATION_DATE_INVALID:'La date d’expiration du SLA ne peut pas être située dans le passé. Veuillez fournir une date valide.',
    },
    DUALSMART: {
      DS_LUMINARIES_FOUND: "74 luminaires trouvés",
      DS_CIRCUIT_READY: "Circuit prêt",
      DS_COMMUNICATION_FAILURE: "défaut communication",
      DS_DIMM_ERROR: "Erreur Dimm",
      DS_OVERCURRENT: "Surintensité",
      DS_SAVE_UPDATE_BUTTON: "Sauvegarde",
      DS_SYNC_DATE_AND_TIME: "Synchronisation du calendrier des tests",
      DS_CONNECTED_POWER_INFO: "10W / 15VA",
      DS_SYSTEM_COMPONENTS_COUNT: "7 composants",
      DS_LUMINARIES_FOUND_TITLE: "74 luminaires trouvés",
      DS_AE_ERROR: "Erreur évacuation dynamique",
      DS_ALL_STATUSES: "Tous les status",
      DS_ALL_TESTS_PASSED: "tous les tests réussis",
      DS_ESTIMED_GUIDELINE_ONE: "~ 10 year at 20°C",
      DS_ESTIMED_GUIDELINE_THREE: "~ 5 year at 30°C",
      DS_ESTIMED_GUIDELINE_TWO: "~ 7 year at 25°C",
      DS_BATTERY_CHARGER: "Batterie / chargeur",
      DS_BATTERY_CHARGE: "Charge de la batterie",
      DS_BATTERY_OPERATION: "Fonctionnement sur batterie",
      DS_BLOCK_DEVICE: "Bloquer l'appareil",
      DS_BLOCK_DEVICE_INFO: "Bloquer l'appareil bloque le passage en mode secours et éteint les circuits",
      DS_BLOCK_SUCCESS: "Système bloqué avec succés",
      DS_BULB_ERROR_AC: "Bulb Error AC",
      DS_BULB_ERROR_DC: "Bulb Error AC",
      DS_CAPACITY: "Capacité",
      DS_CHARGER_LEVEL: "Niveau de charge",
      DS_CHARGING_ERROR: "Défaut de charge",
      DS_FUNCTIONAL_GUIDELINE: "Choisissez un délai compris entre 20 et 800 secondes pour que le système communique puis préchauffe les les luminaires avant un test d'autonomie.",
      DS_CIRCUIT: "Circuit",
      DS_CIRCUIT_FAILURE: "Défaut circuit",
      DS_CIRCUITS_INFO_HEADER: "Informations Circuit",
      DS_CIRCUITS_NO: "Circuit No.",
      DS_CIRCUITS: "Cirduits",
      DS_COMPONENTS_DETAIL: "Détails du composant",
      DS_CONNECTED_POWER: "Puissance connectée :",
      DS_CRYSTALWAY: "CrystalWay 20m",
      DS_DAYS_BETWEEN_THE_TEST: "Jours entre les tests",
      DS_DC1: "DC1",
      DS_DC2: "DC2",
      DS_KEEP_DEEP_DISCHARGE: "Protection contre les décharges profondes activée",
      DS_DELAY_ON_MAINS_RETURN: "Temporisation retour veille",
      DS_ESTIMED_GUIDELINE: "Dépendant de la température ambiante et des conditions du site :",
      DS_DESCRIPTION: "Description",
      DS_END_DURATION_TEST_POPUP: "Confirmer l'arrêt du test d'autonomie ?",
      DS_DOUBLE_ADDRESS: "Double adressage",
      DS_DT_FORERUN: "État du contact sec",
      DS_DEVICE_COMMISSIONING: "Mise en Service",
      DS_PASSWORD: "Mot de passe DualSmart",
      DS_DURATION: "Autonomie",
      DS_DURATION_TEST: "Test d'autonomie",
      DS_DURATION_TEST_SCHEDULE: "Calendrier du test d'autonomie",
      DS_EDIT_BATTERY_DETAILS: "Editer les détails de la batterie",
      DS_DASH_EMPTY: "Vide",
      DS_ESTIMED_LIFESPAN: "Durée de vie estimée",
      DS_EXIT_SIGN: "Evacuation",
      DS_FAN_OPERATING_HOURS: "Heures de fonctionnement du ventilateur",
      DS_FOUND: "Trouvé",
      DS_FT_FORERUN: "Prétest TF",
      DS_FUNCTIONAL: "Fonctionnel",
      DS_FUNCTIONAL_LEAD_AND_TIME: "Functional Lead Time",
      DS_FUNCTION_TEST: "Test fonctionnel",
      DS_FUNCTIONAL_TEST_SCHEDULE: "Calendrier du test fonctionnel",
      DS_SYNC_DATE_AND_TIME_SUBTITLE: "G-North” Main Office",
      DS_CIRCUITS_DESCRIPTION: "Aidez-nous avec la description",
      DS_SEARCH_LUM_INFO_TEXT: "Si vous avez uniquement besoin de trouver de nouveaux luminaires, veuillez utiliser l'option 'Rechercher les nouveaux luminaires'.",
      DS_SEARCH_LUMS_POPUP_SUB_TITLE: "Si vous avez uniquement besoin de trouver de nouveaux luminaires, veuillez utiliser l'option 'Rechercher les nouveaux luminaires'.",
      DS_INSTALLATION_DATE: "Date d'installation",
      DS_INTERVAL: "Intervalle",
      DS_CIRCUITS_INFO: "Key Needed. Restricted Area ! Registration required .",
      DS_LAST_DURATION_TEST_TIME: "Heure du dernier test d'autonomie",
      DS_LIGHTANDBUILDING: "Light & Building 2024",
      DS_LUMINAIRE_FAILURE: "Défaut luminaire",
      DS_LUMINAIRE_NAME: "Nom du lumianire",
      DS_LUMINARIES: "Luminaires",
      DS_MAINS_OPERATION: "Fonctionnement sur secteur",
      DS_NEXT_TEST_DATE: "Date du prochain test",
      DS_NO_OPERATION_POSSIBLE: "Aucune opération possible",
      DS_NORMAL_OPERATION: "Normal Operation",
      DS_OPERATION_FAILED: "Operation Failed",
      DS_OVERTEMPERATURE: "Surtempérature",
      DS_OVERVOLTAGE: "Surtension",
      DS_PREV: "Précédent",
      DS_READY_ON: "Prêt à l'emploi",
      DS_RELEASE_DEVICE: "Débloquer l'appareil",
      DS_RELEASE_DEVICE_INFO: "Release Device Switches back to operation.",
      DS_RELASE_SUCCESS: "Release was performed successfully",
      DS_SEARCH_LUM_WARNING_TEXT: "Retrieving data. Wait a few seconds and try to cut or copy again.",
      DS_CIRCUIT_LUMINARIES_DESC: "Retrieving data. Wait a few seconds and try to cut or copy again.",
      DS_SEARCH_COMPLETE: "Recherche terminée",
      DS_SEARCH_GUIDLINE_ONE_TEXT: "Rechercher tous les luminaires adressés",
      DS_SEARCH_ALL_LUMINARIES: "Rechercher tous les luminaires",
      DS_SEARCH_LUM_TEXT: "Rechercher tous les luminaires va créer une nouvelle configuration",
      DS_SEARCH_LUMS_POPUP_TITLE: "Démarrer la recherche va effacer les luminaires en défaut de la configuration",
      DS_SEARCH_GUIDLINE_TWO_TEXT: "Vous devrez utiliser la fonction « Rechercher tous les luminaires » et activer votre TLAT+ pour voir vos luminaires ici.",
      DS_STARTS: "Starts",
      DS_SUMMARY_FT: "Résumé du test fonctionnel",
      DS_SYSTEM_COMPONENTS: "Composants du système",
      DS_SYNC_DATE_AND_TIME_TITLE: "Date et heure des test",
      DS_TEST_INTERVAL: "Interval de test",
      DS_TEST_LENGTH: "Durée du test",
      DS_VIEW_CIRCUITS: "Afficher par circuit",
      DS_W: "W:",
      DS_SEARCH_FOR_NEW_LUMINARIES: "Rechercher les nouveaux luminaires",
      DS_SEARCH_LUM_NEW_TEXT: "Rechercher les nouveaux luminaires ajoutera les nouveaux luminaires à la configuration actuelle",
      DS_SEARCH_NEW_LUM_GUIDLINE_ONE_TEXT: "This will automatically assign addresses to new or replaced luminaries to your lighting configuration.",
      DS_SEARCH_NEW_LUM_INFO_TEXT: "Si vous souhaitez seulement ajouter de nouveaux luminaires, utilisez la fonction 'Rechercher les nouveaux luminaires'",
      DS_NEW: "Nouveau",
      DS_MODULE_TYPE: "Type de module",
      DS_SEARCH_IO_MODULES: "Rechercher les modules I-bus",
      DS_SEARCH_FOR_IO_MODULES: "Rechercher les modules I-bus",
      DS_SEARCH_IO_MODULES_TEXT: "Rechercher les modules I-bus créera une nouvelle configuration",
      DS_SEARCH_GUIDELINE_IO_MODULES_TEXT: "Rechercher tous les modules sur le bus pour ensuite les configurer et les programmer",
      DS_SEARCH_IO_MODULES_BTN_TEXT: "Rechercher les modules I-bus",
      DS_SEARCH_IO_MODULES_POPUP_TITLE: "Si les modules ne sont pas connectés correctement, cela modifiera définitivement la configuration actuelle.",
      DS_SEARCH_IO_MODULES_POPUP_SUB_TITLE: "Veuillez vous assurer que toutes les cablages sont correctes avant de lancer la recherche.",
      DS_PHYSICAL_MODULES: "Modules physiques",
      DS_SUBSCRIBED_MODULES: "Souscrire",
      DS_MODULES_FOUND: "Modules trouvés",
      DS_MODULE_NUMBER: "Module N°",
      DS_SEARCHING: "Recherche en cours...",
      DS_TIMER: "Timer",
      DS_SET_TIMER: "Réglage du Timer",
      DS_SUBMIT_CHANGES: "Submit Changes",
      DS_TIMER_1: "Timer 1",
      DS_TIMER_2: "Timer 2",
      DS_TIME_TITLE: "Titre du Timer (optionnel)",
      DS_DAYS: "Jours de la semaine et heure",
      DS_ACTIVE_DAYS: "Sélectionnez les jours de la semaine où vous souhaitez que ___ soit actif.",
      DS_START_TIME: "Heure de début",
      DS_END_TIME: "Heure de fin",
      DS_SET_RELAY_BUZZER: "Définir le buzzer du relais",
      DS_RESET_TO_DEFAULT_VALUES: "Réinitialiser aux valeurs par défaut",
      DS_RESET_TO_DEFAULT_VALUES_INFO: "Sélectionnez la sortie pour attribuer ses fonctions. Appuyez sur « Enregistrer » lorsque vous avez terminé.",
      DS_SEARCH_NEW_LUMINARIES_INFO: "Utilisez ces résultats pour créer et partager une nouvelle configuration d'éclairage en cliquant sur « Exporter ».",
      DS_RELAY_NO: "Relais N°",
      DS_RESET_RELAY_BUZZER_POPUP_TITLE: "Veuillez confirmer",
      DS_RESET_RELAY_ONE_TO_DEFAULT: "Réinitialiser le relais 1 aux valeurs par défaut",
      DS_RESET_RELAY_BUZZER_TO_DEFAULT: "Réinitialiser le relais et le buzzer aux valeurs par défaut",
      DS_UNSAVED_CHANGES_POPUP_TITLE: "Il y a des modifications non enregistrées",
      DS_UNSAVED_CHANGES_POPUP_SUB_TITLE: "Vous n'avez pas enregistré vos modifications sur le relais 1. Souhaitez-vous enregistrer ?",
      DS_DISCARED_CHANGES: "Ignorer les modifications",
      DS_CIRCUIT_TITLE: "L'état des luminaires commandés par ce bouton reviendra à leur réglage d'origine apres le prochain changement ",
      DS_EMPTY: "Vide / en défaut",
      LUM_TITLE: "Clicquer sur les luminaires pour les allumer et les éteindre",
      ALL_OFF: "Tout éteint",
      DS_RESET_TITLE: "Réinitialiser aux valeurs par défaut",
      DS_CONTROL_LUM: "Controler les luminaires",
      DS_LUM_BUTTON: "Eteindre et allumer les luminaires",
      DS_APPLY: "Appliquer",
      DS_SET_IO_BUS_MODULES_TITLE: "Définir les modules I-Bus",
      DS_NEXT_MODULE: "Prochain Module",
      DS_MODULE_DETAILS: "Détails du Module",
      DS_MODULE_NAME: "Nom du Module",
      DS_LOCAL_ONLY: "Seulement local",
      DS_PUBLISH: "Publier",
      DS_SUBSCRIBE: "Souscrire",
      DS_ACU_BUS_ID: "ACU Bus ID",
      DS_BUS_MODULE: "Bus Module",
      DS_INPUTS: "Entrées",
      DS_CONFIGURED_MODULE_TITLE: "Aucune entrée de surveillance de phase n'est actuellement configurée sur ce module.",
      DS_CONFIGURE_INPUTS: "Configurer les entrées",
      DS_BLOCK: "Bloquer",
      DS_RELEASE: "Débloquer",
      DS_BLOCK_GUIDLINE: "Cela bloque la fonction d'éclairage de secours et coupe les circuits.",
      DS_RUN_TESTS: "Démarrer les tests",
      DS_LUMINARIE_CONTROLS: "Contrôle des luminaires",
      DS_LUMINARIE_CONTROLS_GUIDELINE: "Ces fonctions vous permettent d’ignorer la programmation configurée du luminaire jusqu’à ce qu’elle soit désactivée.",
      DS_ALL_LUMINARIES_ON: "Allumer tous les luminaires",
      DS_INACTIVE: "Inactif",
      DS_MAINTAINED_LUMINARIES_OFF: "Eteindre tous les luminaires maintenus",
      DS_NON_MAINTAINED_LUMINARIES_OFF: "Allumer tous les luminaires non-maintenus",
      DS_RESET_SYSTEM_STATE: "Réinitialiser l'état du système",
      DS_MANUAL_RESET: "Réinitialisation manuelle",
      DS_RESET_ISO_FAILURE: "Réinitialiser le défaut ISO",
      DS_RECHARGE_DEEP_DISCHARGE_PROTECTION: "Réinitialiser protection décharge profonde",
      DS_SIMULATE_MAINS_FAILURE: "Simuler un défaut secteur",
      DS_SEARCH_ISO_FAILURE: "Recherche défaut ISO",
      DS_SET_I_BUS_MODULES: "Définir les modules I-Bus",
      DS_COMMISSIONING: "Mise en service",
      DS_DATE_AND_TIME: "Date et Heure",
      DS_RESET_SERVICE_CENTER: "Réinitialiser le compteur de service",
      DS_ALERT_LEVEL: "Seuil d'alerte",
      DS_DRY_CONTACT: "Contact sec",
      DS_BUILDING_MANAGMENT_SYSTEM: "Gestion technique de bâtiment (GTB)",
      DS_MODBUS: "Modbus",
      MANAGE_INTERNET_CONNECTION: "Gérer la connexion Internet",
      INTERNET_CONNECTION: "Connexion Internet",
      SYSTEM_LOCATION: "Emplacement du système",
      SUB_LOCATION: "Sous-emplacement",
      TIME_PERIOD_NINTY_DAYS: "Tous les 90 jours",
      DS_SEARCH_ISO_FAILURE_TITLE: "Recherche défaut ISO",
      DS_SEARCH_ISO_FAILURE_BODY: "Veuillez cliquer sur 'Rechercher' pour identifier les modules concernés apres un message de défaut ISO",
      DS_SEARCH: "Rechercher",
      DS_MAIN_FAILURE_MSG: "Cela simulera le fonctionnement DC et durera environ 5 secondes avant de revenir au fonctionnement normal.",
      DS_START: "Démarrer",
      DS_DEEP_DISCHARGE: "Acquittement de la protection contre les décharges profondes",
      DS_DEEP_DISCHARGE_MSG: "En sélectionnant « Reconnaître », vous vérifiez que vous avez vu les batteries du système entrer dans un état de décharge profonde.",
      DS_ACKNOWLEDGE: "Acquitter",
      DS_RESET_ISO: "Réinitialiser le défaut d'isolation ?",
      DS_RESET_ISO_MSG: "Cela réinitialisera le message de défaut d'isolation.\n\nSi l'erreur ISO se reproduit et qu'aucune cause ne peut être identifiée après avoir vérifié les circuits finaux, veuillez contacter le service.",
      DS_RESET: "Réinitialiser",
      DS_MANUAL_RESET_TITLE: "Effectuer une réinitialisation manuelle ?",
      DS_MANUAL_RESET_MSG: "Tous les luminaires anti-panique vont revenir à leur programmation initiale",
      DS_BLOCK_TITLE: "Bloquer le système et mettre fin au test d'autonomie ?",
      DS_CONFIRM_BLOCK: "Confirmer le blocage",
      DS_BLOCK_TEXT: "La batterie aura besoin d'au moins 24 heures pour se recharger avant d'effectuer un autre test d'autonomie.",
      TRIGGERS: "Déclencheurs",
      DEFAULT_TRIGGERS: "Déclencheurs par défaut",
      OPTIONAL_TRIGGERS: "Déclencheurs facultatifs",
      EMPTY_TRIGGERS_LINE: "Aucun déclencheur facultatif n’a été configuré.",
      ADD_TRIGGER: "Ajouter un déclencheur",
      SELECT_TRIGGERS: "Selectionner les déclencheurs",
      ASSIGN: "Assigner",
      ASSIGN_TRIGGERS: "Assigners les déclencheurs",
      SELECTED: "Selectionné",
      INSTALLED: "Installé",
      SCENARIO: "Scénario",
      MAINTAINED_MODE: "Mode maintenu",
      NON_MAINTAINED_MODE: "Mode non-maintenu",
      LUMINARIES_COUNT: "10 luminaires sur 10",
      DS_INPUT_OF_PHASE_MONITOR: "Input of a Phase Monitor",
      DS_CIRCUIT_TRIGGERS_SUCCESSFULLY_LINE: "Circuit No.1 Triggers were successfully configured.",
      DS_SET_OTHER_CIRCUIT_TRIGGERS: "Set Other Circuit Triggers",
      DS_ASSIGN_TRIGGERS_GUIDELINE_TWO: "Please Confirm Each Trigger for Circuit 1.",
      DS_NON_MAINTAINED_LIGHT: "Non-Maintained Light",
      DS_MAINTAINED_LIGHT: "Maintained Light",
      DS_INSTALLED_LUMINARIES: "Installed Luminaires",
      DS_TIMER_SCHEDULE: "Weekday Schedule",
      DS_DEEP_DISCHARGE_TITLE: "Deep Discharge",
      DS_STIMULATE_MAIN_FAILURE: "Stimulate Main Failure",
      DS_ASSIGN_DEFAULT_TRIGGERS_GUIDELINE: "Tap or drag over luminaires to assign them to this trigger.",
    },
    LOGBOOK_DETAILS: {
      LABEL_EVENT_AGO: "depuis",
      PART_NUMBER: " PN: ",
      SERIAL_NUMBER: " SN: ",
      ADD_COMMENTS: "+ Ajouter un Système",
      REQUIRES_FIRMWARE_UPDATE: "1 Mise à jour du logiciel recommandée",
      ACKNOWLEDGE: "Acquitter",
      ACKNOWLEDGED: "Acquitté",
      ACKNOWLEDGED_BY: "Acquitté par",
      PREVIOUS_ACKNOWLEDGED_BY: "Acquitté par :",
      ACTIVE: "Actif",
      ACTIVE_ACKNOWLEDGED: "Actif. Acquitté",
      AT: "à",
      LABEL_CLOSED: "Fermer",
      CLOSED: "Fermer",
      COMMENTS: "Commentaires",
      DESCRIPTION: "Description",
      DEVICE: "Elément",
      EDIT: "Editer",
      EVENT: "Evénement",
      LABEL_EVENT_BEGAN: "Début de l'événement",
      LABEL_EVENT_CLOSE: "Fin de l'événement",
      LOGBOOKDETAIL: "Détails du journal",
      NO_PREVIOUS_LOG_TITLE: "Aucun événement précédent pour ce système.",
      PARTNO: "Réf: ",
      PREVIOUS_LOG_ITEM: "Evènement précédent du système",
      PREVIOUS_LOG_TITLE: "Evènement précédent du système",
      SERIALNO: "SN: ",
      STATUS: "Etats",
      SYSTEM: "Système",
      TIMESTAMP: "Horodatage",
      SYSTEM_TYPE: "Type: ",
    },
    ORGANIZATION_MANAGE: {
      BUILDING_COUNT: "Bâtiment",
      BUILDING_COUNT_S: "Bâtiments",
      BUILDINGS_TITLE: "Bâtiments",
      ADMIN_COUNT: "Administrateur de l'organisation",
      ADMIN_COUNT_S: "Administrateurs de l'organisation",
      VIEWER_COUNT: "Utilisateur de l'organisation",
      VIEWER_COUNT_S: "Utilisateurs de l'organisation",
      SYSTEM_COUNT: "Système",
      SYSTEM_COUNT_S: "Systèmes",
      ADD_NEW_BUILDING: "+ Ajouter un nouveau Bâtiment",
      CREATE_ORGANIZATION: "+ Créer une organisation",
      ERROR_ADD_NEW_SYSTEM_ACTION: "Ajouter un nouveau Système",
      ALL: "Tous",
      SORT_TYPE_NAME: "Alphabets",
      SORT_TYPE_CITY: "Ville",
      COMMENTS_HELPER: "Commentaires pour aider le technicien de maintenance à localiser le site",
      ORGANIZATION_NAME_HELPER: "Lettres, chiffres et tirets uniquement",
      MARK_AS_FAV_API_FAILURE_MESSAGE: "Marquer comme échec",
      ERROR_NO_BUILDING_FOUND: "Aucun bâtiment trouvé",
      ERROR_NO_GATEWAY_FOUND: "Aucune Gateway trouvée ",
      ERROR_NO_LOGS_TITLE: "Pas d'événement sur cette période",
      ERROR_NO_LUMINARY_FOUND: "Aucun luminaire trouvé",
      ERROR_NO_ORG_FOUND: "Aucune organisation trouvée",
      ERROR_NO_REPORT_FOUND: "Aucun rapport trouvé",
      ERROR_NO_SYSTEM_FOUND: "Aucun système trouvé",
      ERROR_NO_USER_FOUND: "Aucun utilisateur trouvé",
      SORT_TYPE_NUMBER_OF_SYSTEMS: "Nombre de Systèmes",
      QUIT_EDIT_USER_DETAILS: "Quitter la modification",
      QUIT_TITLE: "Quitter la modification “{{orgName}}”?",
      ERROR_READ_INSTALLATION_MANUALS_ACTION: "Lire le manuel d'installation",
      SEARCH: "Rechercher",
      SEARCH_BUILDINGS: "Rechercher",
      SEARCH_BUILDINGS_DASHBOARD: "Rechercher des bâtiments",
      SEARCH_SYSTEM_DASHBOARD: "Rechercher des systèmes",
      SORT_BY_HEADER: "TRIER PAR",
      STARRED_ORGANIZATIONS: "Organisations favorites",
      VIEW_ORGANIZATION: "Afficher l'organisation",
      ERROR_NO_DEVICE_TITLE: "Vous n'avez pas encore de systèmes dans cette organisation.",
      ERROR_NO_DEVICE_DESCRIPTION: "Vous pourrez superviser l'état de vos éclairages de sécurité une fois que vous aurez commencé à ajouter des systèmes à cette organisation.",
      QUIT_MESSAGE: "Les modifications en cours seront perdues",
    },
    ADD_BUILDING: {
      BLDG_ORG_CONTACT: "Contacts",
      INVERTED_COMMAS_S: "’s",
      BLDG_ORG_CONTACTS: "contactes",
      BLDG_ADDITIONAL_DESC: "Vous pouvez ajouter des commentaires supplémentaires (facultatif) pour aider votre équipe de maintenance",
      BLDG_ADD_DESCRIPTION: "Descriptions supplémentaires",
      BLDG_ADDRESS: "Adresse",
      BLDG_CREATTION: "Bâtiment créé",
      BUILD_CREATE_SUCESS: "Bâtiment créé avec succès",
      BLDG_HEADER: "Créer un nouveau bâtiment pour ",
      BLDG_LOCATION_MESSAGE: "Faites glisser la carte pour placer l'épingle sur le bâtiment. Cela permettra aux techniciens de maintenance d'arriver plus rapidement sur site à l'avenir.",
      BLDG_EDIT_BTN: "Modifier les informations du contact",
      BLDG_NAMELENGTH: "Ex : Bureau principal, Entrepôt, …",
      BLDG_INITILIZING_NETWORK: "Initialisation du réseau…",
      BLDG_INSIDE: "Intérieur",
      BLDG_LOCAL_CONTACT: "Contact local du bâtiment",
      BLDG_CONTACT_TITLE: "Détails du contact local du bâtiment",
      BLDG_LOCATE_ON_MAP: "Localiser sur la carte au lieu de ",
      BLDG_LOCATION: "Emplacement",
      BLDG_ADDTIONAL_DETAILS: "Vous pouvez ajouter quelques détails supplémentaires pour terminer la configuration de ",
      ORG_CREATE_SUCESS: "Organisation créée avec succès",
      BLDG_ADDITION_ERROR: "Veuillez entrer une adresse valide",
      BLDG_PRIMARYCONTACT: "Contact principal",
      BLDG_PRIMARY_LOCAL_CONTACT: "Principal contact local",
      BLDG_SECONDARYCONTACT: "Secondary Contact (Optional)",
      BLDG_SECONDARY_LOCAL_CONTACT: "Second contact local",
      BLDG_NO_POSTAL_ADDRESS: "Choisir cette option si l'adresse postale n'est pas connue",
      BLDG_CREATED_MESSAGE: "Création réussie",
      BLDG_ORGANIZATION_FOR: "Utiliser l'organisation",
      BLDG_ADD_CONTACT: "À qui les électriciens/mainteneurs doivent-ils s'adresser à propos du « Bureau principal » ?",
      SAVE_CHANGES_MSG: "Vos changements ont été enregistrés",
    },
    USER_SETTINGS: {
      AUTOMATIC_LOGOUT_SUBTITLE: "Désactivez l'expiration automatique de la session après 15 minutes",
      ALARMS: ".",
      PHONE_NO_TEXT: "Un numéro de téléphone est nécessaire pour recevoir les notifications",
      ACCOUNT: "Compte",
      ACCOUNT_CREATED_DATE: "Compte créé le",
      ADDRESS: "Adresse",
      ALARMS_TITLE: "Alertes",
      APPLICATION_THEME_COLOR: "Couleur de thème GUI",
      AUTOMATIC_LOGOUT: "Déconnexion automatique",
      UNITS_DESCRIPTION: "British Imperial Units",
      BUILDING_NAME: "Nom du bâtiment",
      TIMEZONE_DESCRIPTION: "Heure d'Europe centrale (GMT-1:00)",
      CHANGE_PASSWORD: "Changement du mot de passe",
      COMPANY_ADDRESS: "Adresse de la société",
      COMPANY_NAME: "Nom de société",
      COMPANY_PHONE_NUMBER: "Numéro de téléphone de la société ",
      DATE_FORMAT: "Format de la date",
      DATEFORMAT_DESCRIPTION: "JJ/MM/AAAA",
      DELETE_ACCOUNT: "Supprimer le compte",
      STATUS_UPDATES: "Le système est en mode repos, en mode secouru, une maintenance est nécessaire  ",
      AUTOMATIC_LOGOUT_TITLE: "Désactiver la déconnexion automatique",
      EMAIL: "Adresse e-mail",
      EMAIL_NOTIFICATION: "notification par e-mail",
      TEXT_NOTIFICATION_TITLE: "Activer les notifications par SMS",
      ENABLED: "Activé",
      TEXT_NOTIFICATION_BODY: "L'activation des notifications par SMS peut entraîner des frais supplémentaires auprès du fournisseur de services cellulaires. Ces frais ne sont pas inclus dans le contrat de service Eaton.",
      LANGUAGE_DESCRIPTION: "Anglais - UK",
      HEALTH_REPORT_TITLE: "Carnet de santé",
      LANGUAGE: "Langue",
      HEALTH_REPORT: "Mensuel",
      PRODUCT_UPDATES: "Mises à jour logiciel",
      NOTIFICATIONS_HEADER: "Notifications",
      APP_THEME_DESCRIPTION: "Thème de l'interface graphique",
      ORGANIZATION: "Organisation",
      ORGANIZATION_NAME: "Nom de l'organisation",
      PASSWORD: "Mot de passe",
      PHONE_NUMBER: "Numéro de téléphone",
      PREFERENCES_HEADER: "Préférences",
      PRODUCT_UPDATES_TITLE: "Mise à jour des produits",
      STATUS_UPDATES_TITLE: "Changement d'état",
      TEXT_NOTIFICATION: "Notifications par SMS",
      AUTOMATIC_LOGOUT_BODY: "Cela désactivera une fonctionnalité de sécurité standard incluse dans l'application pour empêcher l'accès à une session abandonnée. Veuillez noter que l'utilisateur sera responsable de la protection des accès non autorisés à ce système et ne devra jamais laisser cette application ouverte sans surveillance.",
      TIME_ZONE: "Fuseau horaire",
      UNITS: "Unités",
      ORG_ADDRESS: "Utiliser l'adresse de l'organisation",
      ORG_CONTACT_DETAILS: "Utiliser les contacts de l'organisation",
      EDIT_USER_DETAILS: "Détails de l'utilisateur",
      NAME: "Nom d'utilisateur",
      USER_NAME: "Nom d'utilisateur",
      WARNING: "Attention",
    },
    EDITUSER: {
      PROMOTE_CONFIRMATION: "Si tel était votre intention, veuillez valider votre modification.",
      DEMOTE_CONFIRMATION: "Si tel était votre intention, veuillez apporter les modifications dans la section Rôle/Emplacement de ce formulaire.",
      DEMOTE: "Vous avez modifié l'accès/rôle de cet administrateur d'organisation. Si ce n'est pas votre intention, sélectionnez Annuler.",
      PROMOTE: "Vous avez promu cet utilisateur comme administrateur de l'organisation. Si ce n'est pas votre intention, sélectionnez Annuler.",
    },
    ADDUSER: {
      INVITEUSER: "Inviter un utilisateur",
      TITLE: "Ajouter un utilisateur",
      ASTERIKSINFO: "Les champs marqués (*) sont des champs obligatoires et nécessaires pour inviter un utilisateur.",
      INVITESUCCESS: "Invitation réussie !",
      SELECTROLE: "Sélectionnez un rôle pour les emplacements",
      ACCOUNTCREATED: "L'invitation à créer un compte a été lancée avec succès.",
      EMAILRECEIVE: "L'utilisateur recevra une invitation par e-mail pour créer son compte d'ici 5 à 10 minutes. Vous ne pourrez pas voir leur statut tant qu'ils n'auront pas accepté l'invitation.",
      ADDUSERINFORMATION: "Informations sur le compte de l'utilisateur",
    },
    GATEWAY: {
      MANAGE_CELLULAR_CONNECTION: "Gérer la connexion cellulaire",
      TRANSFER_SYSTEM: {
        TRANSFER_SYSTEM_SELECTION_PAGE_TITLE: "{{gatewayName}} Gateway Systems ({{systemCount}})",
        TRANSFER_SYSTEM_USERCONFIRMATION_TITLE: "{{gatewayName}} système  va être transféré aux utilisateurs suivants :",
        ADD_ANOTHER_USER: "Ajouter un autre utilsateur",
        ADMIN_RIGHT_REQUIRED: "Droits d'administrateur requis pour inviter un utilisateur",
        REGISTER_USER_REQUIRED: "Au moins un utilisateur enregistré requis pour le transfert",
        EMAIL_ID_DUPLICATE: "E-mail en doublon ou non valide",
        ADMIN_RIGHT_REQUIRED_FOR_SYSTEM_TRANSFER: "Admin rights required to transfer user entities",
        DUPLICATE_ENTITY_ID: "Duplica ou entrée invalide",
        TRANSFER_SYSTEM_EXISTING_USER: "Utilisateur existant",
        INVALID_ENTITY_TYPE: "Type invalide, veuillez saisir un type valide",
        TRANSFER_SYSTEM_LOGED_IN_USER_MSG: "Utilisateur invalide, veuillez ajouter un autre utilisateur",
        INVITE_AS_COLLEAGUE: "Inviter comme collègue",
        TRANSFER_SYSTEM_NEW_USER: "Nouveaux utilisateurs",
        TRANSFER_SYSTEM_ADD_EXISTING_USER: "Veuillez ajouter au moins un utilisateur existant pour transférer des entités.",
        INVALID_EMAIL_ADDRESS: "Veuillez entrer une adresse e-mail valide",
        INVALID_GATEWAY_ID: "Veuillez entrer un identifiant de gateway valide",
        TRANSFER_SYSTEM_INFO: "Veuillez sélectionner un utilisateur existant ou en ajouter un nouveau.",
        TRANSFER_SYSTEM_SYSTEM_SELECTION_HEADER: "Veuillez selectionner les systèmes à transférer.",
        NO_DATA_FOUND: "Données introuvables",
        TRANSFER_SYSTEM_SYSTEM_TRANSFER: "Transfert du système",
        TRANSFER_SYSTEM_CONFIRMATION_NEW_USER: "Cet utilisateur ne peut pas surveiller les systèmes tant qu'il n'a pas accepté une invitation envoyée à l'adresse e-mail fournie.",
        TRANSFER_SYSTEM_PRIVILEGES: "Transfert des droits du système",
        TRANSFER_TO: "Transférer vers",
        TRANSFER_USER_ALREADY_ADDED: "Utlisateur déjà ajouté",
        TRANSFER_SYSTEM_ALREADY_ADDED_USER: "Utlisateur déjà ajouté, Veuillez ajouter un autre utilisateur",
        TRANSFER_SYSTEM_USER_SELECTION_HEADER: "A qui souhaitez vous transférer ce système ?",
      },
      ENTRY_PASSWORD_TITLE: "Accès à",
      ADD_GATEWAYS: "Ajouter un équipement",
      ADDING_GATEWAY_TITLE: "Ajouter '{{gatewayName}}' à {{orgName}}",
      CONNECT_TO_ELSYSTEM_FAILED_UREACHABLE_MSG: "Le système connecté n'est pas accessible",
      RESET_GATEWAY_PASSWORD_SUCCESS: "Mot de passe de la gateway mis à jour avec succés",
      BLE: {
        ERROR: {
          INVALID_IP_AADRESS: "Adresse IP invalide",
          CHANGE_FAILURE_MESSAGE: "Une erreur s'est produite lors de la modification des paramètres",
          NOT_FETCHING_SYSTEM_DETAILS: "Not able to fetch system details",
          BUILDING_ALREADY_EXISTS: "Nom de bâtiment déjà existant",
          RESET_PASSWORD_FAILED: "Reset password request failed. Please try again",
          INVALID_CONNECTION_STRING: "Problème de connexion",
          INVALID_UUID: "UUID invalide",
          ORGANIZATION_ALREADY_EXISTS: "Nom d'organisation déjà existant",
          REQUEST_FAILED_TITLE: "Echec de la demande",
          REQUEST_FAILED_GATEWAY_ADD: "Echec de l'ajout d'une gateway",
          REQUEST_FAILED_MESSAGE: "Echec de la demande. Veuillez attendre quelques minutes avant de réessayer.",
          UNAUTHORIZED_ACCESS: "Accès non autorisé. Veuillez vous connecter à nouveau",
          INVALID_DEVICE: "Invalid Device",
        },
        DEVICE_STATUS: {
          ALARM: "Alarme",
          BLOCKED: "Bloqué",
          NOT_PROVISIONED: "Non fourni",
          OFFLINE: "Hors ligne",
          ONLINE: "En ligne",
          PROVISIONED: "Fourni",
          READY: "Prêt",
          READY_FOR_COMMISSION: "Prête pour la mise en service",
          WARNING: "Attention",
        },
        DEVICE_TYPE: {
          ATPLUS_CONTROLLER: "Télécommande TLU AT+",
          COMMON_GATEWAY: "Gateway commune",
          DGS: "DualGuard-S",
          LPSMART: "DualSmart-S",
          WIRELESS_GATEWAY: "Controleur sans fil",
        },
        SET_PASSWORD_STATUS: {
          INVALID_LENGTH: "Longueur incorrecte",
          INVALID_PASSWORD: "Mauvais mot de passe",
          PASSWORD_CHANGE_FAILURE: "Echec du changement de mot de passe",
          PASSWORD_CHANGE_SUCCESS: "Mot de passe changé avec succès",
          PASSWORD_COMPARISON_FAIL: "Échec de la comparaison des mots de passe",
        },
        DEFAULT_LOGIN_STATUS: {
          LOGIN_DEFAULT_SUCCESS: "Connexion par défaut réussie",
          LOGIN_FAILURE: "Echec de connexion",
          LOGIN_MAX_ATTEMPTS_EXCEEDED: "nombre de tentative de connexion dépassé",
          LOGIN_SUCCESS_ADMIN: "Connecté en tant que 'Admin'",
          LOGIN_SUCCESS_SERVICE: "Connecté en tant que 'Service'",
          LOGIN_SUCCESS_USER: "Connecté en tant que 'Utilisateur'",
          LOGOUT: "Déconnexion",
        },
        NULL_VALUE: "Pas de réponse",
        TURN_ON_BLE_MSG: "Activez le Bluetooth pour permettre à « Building Safety Manager » d'accéder aux systèmes locaux.",
      },
      INVALID_SYSTEM_NUMBER: "Numéro de système invalide",
      INVALID_USER_ACCESS_SYSTEM_UNLINK: "Utilisateur invalide, vous n'avez pas les droits pour déconnecter un système",
      GATEWAY_DISCONNECT_ALERT: "Êtes-vous sûr de vouloir vous déconnecter ?",
      DISCARD_CHANGES_ALERT: "Etes-vous sûr de vouloir ignorer les modifications ?",
      UNLINKEXISTING_SYSTEM_ALERT: "Êtes-vous sûr de vouloir déconnecter le système existant ?",
      GATEWAY_UNREGISTER_ALERT: "Êtes-vous sûr de vouloir désinscrire la gateway ?",
      AUTOMATICALLY_CONFIG_IP: "Configuration automatique de l'adresse IP",
      BACKUP_CONNECTION: "Connexion de secours",
      TIMELINE_VIEW: {
        BUILDING: "Bâtiment",
        OPTINAL: "Optionnel",
        ORGANIZATION: "Organisation",
        SELECT_BUILDING: "Sélectionner un bâtiment",
        SELECT_ORGANIZATION: "Sélectionner une organisation",
        SUB_LOCATION: "Sous-emplacement",
      },
      BLUETOOTH_SCAN_ERROR: "Vous ne trouvez pas votre gateway ? Vérifier",
      CARRIER_NAME: "Nom du support",
      CELLULAR: "Cellulaire",
      CELLULAR_PROCESS_FAILED_MSG: "Echec de la configuration cellulaire, veuillez réessayer.",
      CELLULAR_PROCESS_SUCCESS_MSG: "Configuration cellulaire réussie",
      CELLULAR_CONNECTION: "Configuration cellulaire",
      NO_BLUETOOTH_SUPPORT: "Bluetooth non pris en charge sur cet appareil",
      CHANGE_CONNECTION_METHOD: "Changer le mode de connexion",
      CHECK_FOR_UPDATES: "Vérifier les mises à jour",
      CONFIGURE_WI_FI_CONNECTION: "Configurer la connexion Ethernet",
      CONFIGURE_CELLEULAR_CONNECTION: "Configurer la connexion cellulaire",
      COMMISSION_NEW_GATEWAY: "Connecter à système d'éclairage de sécurité Eaton.",
      CONNECT_TO_ELSYSTEM_FAILED_MSG: "Erreur de connection au système, veuillez réessayer.",
      CONNECTED: "Connecté",
      CONNECTED_TO: "connecté à",
      CONNECTION_METHOD: "Méthode de connexion",
      GATEWAY_CONNECTION_PRIORITY: "Priorité de connexion",
      CONNECTIONS: "Connexions",
      GATEWAY_ADD_BUILDING_VALUE: "Créer un nouveau bâtiment",
      GATEWAY_ADD_ORGANIZATION_VALUE: "Create a new organization",
      SET_DATE_TIME_SUCCESS: "La date et l'heure ont été enregistré avec succès",
      DEFAULT_PASSWORD_ADMIN_CHANGED: "DEFAULT_PASSWORD_ADMIN_CHANGED",
      DEFAULT_PASSWORD_USER_CHANGED: "DEFAULT_PASSWORD_USER_CHANGED",
      DISCONNECTED: "L'appareil est déconnecté ou ne parvient pas à se connecter, veuillez réessayer",
      DISCONNECTED_SUCCESS: "Appareil déconnecté avec succès",
      GATEWAY_UNREGISTER_SUCCESS: "Système effacé avec succès",
      GATEWAY_UNREGISTER_FAILURE: "Système effacé avec succès",
      DGS_NOT_COMMUNICATING: "DG-S ne communiquant pas",
      DGS_UNREACHABLE: "DG-S inaccessible",
      DHCP: "DHCP",
      DISABLE_WIFI_RATIO_STATUS: "Désactiver le wifi pour Gateway",
      DNS: "DNS",
      CONNECT_TO_ELSYSTEM_SUCCESS_MSG: "EL system linked successfully.",
      ENABLE_LOCATION_FOR_MAP: "Activez pour trouver votre emplacement actuel, activez-le pour charger la carte",
      ENABLE_WIFI_RATIO_STATUS: "Activer le wifi pour Gateway",
      WIFI_CREDENTIALS_MESSAGE: "Le mot de passe doit être valide",
      ENTER_WIFI_CREDENTIALS: "Entrer les identifiants Wi-Fi",
      RESET_GATEWAY_PASSWORD: "Êtes-vous sûr de vouloir réinitialiser le mot de passe de la Gateway ?",
      INVALID_EPAS_SERIAL_NUMBER: "Numéro de série EPAS invalide",
      ETHERNET: "Ethernet",
      ETHERNET_CONFIGURATION: "Configuration Ethernet",
      ETHERNET_PROCESS_FAILED_MSG: "Echec de la configuration internet, veuillez réessayer.",
      ETHERNET_CONNECTION: "Connexion Ethernet",
      FAIL_LINK_SYSTEM: "Impossible de connecterle système à la gateway",
      FAIL_UNLINK_SYSTEM: "Impossible de déconnecterle système à la gateway",
      FAIL_UNREGISTER_GATEWAY: "Impossible de désinscrire la gateway",
      FINISH: "Terminer",
      CHANGE_PASSWORD_INFO: "Pour des raisons de sécurité, merci de changer le mot de passe",
      GATEWAY_ACTIVATED: "Gateway activée",
      GATEWAY_ADDED: "Gateway ajoutée",
      GATEWAY_COMMISSIONING: "Mise en service de la gateway",
      GATEWAY_CONNECTED: "Gateway connectée",
      GATEWAY_DETAILS: "Informations de la gateway",
      GATEWAY_NAME: "Nom de la gateway *",
      GATEWAY_PASSWORD: "Mot de passe de la gateway",
      GATEWAY_PASSWORD_INCORRECT: "Mot de passe de la gateway incorrect",
      GATEWAY_NAME_DESCRIPTION: "Personnaliser le nom et la description de la gateway",
      SUCCESSFULLY_ADDED: "a été ajouté avec succès.",
      INCOMPLETE_SETUP: "Configuration incomplète",
      INTERNET_SETTINGS: "Configuration internet",
      INVALID_GUID: "ID du système invalide, veuillez saisir un GUID valid",
      FACTORY_RESET: "Réinitialisation d'usine",
      FACTORY_RESET_SUCCESS: "Réinitialisation d'usine exécutée avec succès",
      FACTORY_RESET_FAILURE: "Echec de la réinitialisation d'usine, veuillez recommencer.",
      GATEWAY_FACTORY_RESET_ALERT: "Confirmer la reinitialisation aux paramètres d'usine ?",
      LAST_CONNECTED: "Dernière connexion : 12:34:56 12 May 2024",
      CELLULAR_CONNECTION_TYPE_LTE: "LTE",
      CELLULAR_CONNECTION_TYPE_NON_LTE: "NON-LTE",
      INVALID_LATITUDE: "Latitude invalide, veuillez saisir une latitude valide",
      INVALID_LONGITUDE: "longitude invalide, veuillez saisir une longitude valide",
      INVALID_USER: "Utilisateur invalide",
      SUBLOCATION_NAME_LENGTH: "Lettres, tirets et chiffres uniquement",
      LINK_NEW_DEVICE: "Connecter un nouvel équipement",
      LINK_NEW_SYSTEM: "Connecter un nouveau système",
      LINK_SYSTEM: "Connecter un système",
      LINK_ANOTHER_SYSTEM: "Connecter un autre système ",
      LINK_MORE_SYSTEM: "Connecter plus de systèmes",
      LINK_SYSTEM_MSG: "Connection  du système à '{{gatewayName}}' en cours",
      LINK_SYSTEMS: "Connecter les systèmes",
      LOCATION: "Emplacement",
      DENIED_LOCATION_PERMISSION: "Localisation refusée, activez-la pour rechercher les équipements Bluetooth à proximité",
      DENIED_LOCATION_PERMISSION_FOR_MAP: "Localisation refusée, activez-la pour charger la carte",
      CONNECTION_TYPE_VALUE: "LTE/WCDMA/GSM",
      MANAGE_ETHERNET_CONNECTION: "Gérer la connexion Ethernet",
      MANAGE_WIFI_CONNECTION: "Gérer la connexion Wi-Fi",
      MANUALLY_CONFIG_IP: "Entrer mannuellement l'adresse IP",
      SET_NAME_DESCRIPTION_SUCCESS: "Nom et description créés avec succès",
      NO_DEVICE_FOUND: "Aucun équipement trouvé",
      UNLINK_SYSTEM_ERROR: "Aucune gateway pour se désinscrire ",
      NO_GATEWAY_NAME_FOUND: "Aucun nom trouvé",
      NO_SYSTEM_YET: "Aucun système pour l'instant",
      WIFI_NETWORK_NOT_AVAILABLE: "Aucun réseau WiFi disponible",
      DHCP_OFF: "Off",
      DHCP_ON: "On",
      NO_SYSTEM_YET_DESCRIPTION: "Une fois que vous aurez connecté vos systèmes à cette gateway, vous verrez une liste de systèmes ici. Vous pouvez compléter le descriptif.",
      ONLINE_TEXT: "en ligne.",
      ORGANIZATION_TREE: "Structure de l'organisation",
      OTHER_NETWORK: "Autre réseau…",
      BLUETOOTH_INSTRUCTIONS: "nos instructions",
      CHOOSE_GATEWAY_CONNECTION_METHOD: "Veuillez selectionner votre méthode de connexion de la Gateway",
      CONFIRM_SELECTION: "Veuillez confirmer la sélection",
      ENTER_ADMIN_CHANGE_PASSWORD: "Merci de saisir le nouveau mot de passe administrateur ",
      ENTRY_GATEWAY_CHANGED_PASSORD: "Veuillez saisir le nouveau mot de passe de la gateway",
      ENTRY_GATEWAY_DEFAULT_PASSORD: "Veuillez saisir le mot de passe actuel de la gateway",
      ENTRY_PASSWORD: "Merci de saisir le mot de passe de la gateway pour",
      PLEASE_ENTER_NEW_PASSWORD_AGAIN_RECONNECT: "Saisir le nouveau mot de passe pour vous reconnecter à la gateway",
      MOVE_CAMERA_OVER_QR_CODE: "Veuillez déplacer votre caméra\n sur le code QR",
      INVALID_BUILDING_ID: "Veuillez entrer un ID bâtiment valide",
      INVALID_DEVICE_ID: "Veuillez entrer un ID système valide",
      INVALID_DEVICE_NAME: "Veuillez entrer un nom système valide",
      INVALID_DEVICE_STATUS: "Veuillez entrer un statut système valide",
      INVALID_DEVICE_TYPE: "Veuillez entrer un type de système valide",
      INVALID_PROJECT_ID: "Veuillez entrer un identifiant de projet valide",
      INVALID_SYSTEM_ID: "Veuillez entrer un identifiant système valide",
      PLEASE_WAIT: "Veuillez attendre",
      PRIMARY_CONNECTION: "Connexion principale",
      GATEWAY_PRIORITY: "Priorité",
      PROXY: "Proxy (optionel)",
      PULL_REFRESH_TEXT: "Déroulez pour actualiser",
      QUATERNARY_CONNECTION: "Quaternary Connection",
      REMOVE_CONNECTION: "Supprimer la connexion",
      REMOVE_PRIMARY_CONNECTION: "Supprimer la connexion principale",
      REQUIRE_GATEWAY_CONNECTED_TO_INTERNET: "Nécessite une gateway connectée à Internet",
      SAVE_TO_CLOUD: "Enregistrer sur le cloud",
      SAVE_WI_FI_CREDENTIALS: "Sauvegarder les identifiants Wi-Fi pour d'autres appareils",
      SCAN_QR_CODE: "Scanner un QR code",
      QR_CODE_SCAN: "Scanner un QR code",
      SCANNING_FOR_NEAR_BY_BLE: "Recherche des Bluetooth à proximité",
      SCANNING_FOR_NEAR_BY_WIFI: "Recherche des Wi-Fi à proximité",
      SEARCH_ALL_LUMINARIES: "Rechercher tous les luminaires",
      SEARCH_VIA_BLUETOOTH: "Rechercher avec le Bluetooth",
      SEARCHING_FOR_NEARBY_WIFI: "Recherche des Wi-Fi",
      SEARCH_VIA_BLUETOOTH_EllIPSE: "Recherche via Bluetooth",
      SECONDARY_CONNECTION: "Connexion secondaire",
      SELECT_PRIMARY_CONNECTION: "Sélectionner la méthode de connexion principale",
      SERIAL_NUMBER_NULL_OR_EMPTY: "Le numéro de série ne peut etre nul ou vide",
      SET_DATE_TIME_CONFIG: "Réglage de la date et de l'heure incorrect ",
      SET_DATE_TIME_FAILURE: "Echec du réglage de la date et de l'heure",
      SET_NAME_DESCRIPTION_INCORRECT_CONFIG: "Nom et description incorrects",
      SET_NAME_DESCRIPTION_FAILURE: "Echec de la création du nom et de la description",
      SET_EL_SYSTEM_PORT: "Configurer le port du système",
      SET_GATEWAY_PORT: "Configurer le port de la gateway",
      STATIC: "Static",
      STATUS: "Etats",
      STEP_THREE_OF_THREE: "Etape 3 sur 3",
      SYNC_DATE_TIME: "Synchroniser la date et l'heure",
      SYSTEM_UNLINK_SUCCESS: "Système déconnecté avec succès",
      SYSTEM_UNLINKED: "Système déconnecté ",
      SYSTEMS: "Système",
      UNLINK_SYSTEM_SUCCESS: "Les systèmes ont été désenregistrés avec succès.",
      TERTIARY_CONNECTION: "Connexion tertiaire",
      GATEWAY_NOT_EXISTS: "Gateway inconnue",
      ADDING_GATEWAY_SUBTITITLE: "Cette opération peut durer 2mn.",
      UNLINK_ANOTHER_SYSTEM: "Déconnecter un autre système ",
      UNLINK_EXISTING_SYSTEM: "Déconnecter un système existant",
      UNLINK_EXIST_SYSTEM: "Désinscrire la gateway",
      USE_WI_FI_PASSWORD: "Utiliser le mot de passe Wi-Fi",
      USE_WPA2_KEY: "Utiliser la clé WPA2",
      SCANNING_FAILED_TRY_AGAIN: "Vérification du système...",
      CARRIER_NAME_VALUE: "Vodafone NL 3G",
      WI_FI: "Wi-Fi",
      WIFI_PROCESS_FAILED_MSG: "Erreur de la configuration WiFi, Veuillez recommencer.",
      GATEWAY_WIFI_CONFIG: "Configuration Wi-Fi",
      WI_FI_NETWORK: "Réseau Wi-Fi",
      WI_FI_NETWORK_NAME: "Nom du Wi-Fi",
      WI_FI_PASSWORD: "Mot de passe Wi-Fi",
      GATEWY_INTERNET_SETTING_OPTIONAL_FIELD_MSG: "Vous pouvez ignorer n'importe laquelle de ces étapes, mais elles devront être complétées ultérieurement pour terminer la configuration.",
      VERIFYING_DEVICE: "nan",
      GATEWAY_OF: "Gateway de ",
      LOCATION_PERMISSION_TITLE: "Autorisation de localisation",
      LOCATION_REQUIRED_MESSAGE: "Cette application a besoin d'accéder à votre position",
      PERMISSION_DENIED_TITLE: "Autorisation refusée",
      PERMISSION_DENIED_MULTIPLE_MSG: "Vous avez refusé l'autorisation de localisation à plusieurs reprises. L'application pourrait ne pas fonctionner correctement sans cela.",
    },
    ERROR_MESSAGES: {
      GENERIC_ERROR_CONTACT_DETAILS: "ou appeler le ",
      GENERIC_ERROR: "Une erreur inconnue s'est produite. Si ce problème persiste, veuillez contacter le support Eaton au",
      9003: "L'opération demandée ne peut pas être réalisée, veuillez contacter votre administrateur",
      2002: "Le lien d’inscription de l’utilisateur est expiré ou déjà utilisé.",
      2018: "Nous ne sommes pas en mesure de finaliser votre inscription. Votre organisation a dépassé le nombre d'utilisateurs autorisés à votre abonnement. Pour étendre vos droits, veuillez contacter votre support CST par e-mail à l'adresse suivante ",
    },
    FORGOT_PASSWORD: {
      INSTRUCTIONS: "Veuillez saisir l'e-mail associé à ce compte. <br/><br/>' + 'Si cet e-mail possède un compte auprès de Building Safety Management System, vous recevrez une réponse dans un délai de <3> jour ouvrable</3>.<br/><br/>' +'Pour toutes urgences, veuillez appeler votre support'",
      ERROR: "Impossible de réinitialiser votre mot de passe pour le moment.",
      RESET_CODE_ERROR: "Votre code de réinitialisation est erroné.",
    },
    ACCOUNT_SETTING: {
      UPDATES_AVAILABLE: "Mises à jour disponibles",
      ACCOUNT_CREATED: "Compte créé",
      ACCOUNT_PREFERENCES: "Préférences d'utilisateur",
      ADDRESS_LINE2: "Adresse - Line 2 (Optionnelle)",
      ALARAM: "Alertes",
      ALL_BUILDINGS: "Tous les Bâtiments",
      ALL_SYSTEM_FIRMWARE: "Toutes les mises à jours des systèmes",
      ALL_SYSTEMS: "Tous les Systèmes",
      APPLICATION_THEME_COLOR: "Couleur de thème GUI",
      AUTOMATIC_LOG_OUT: "Déconnexion automatique",
      AUTOMATIC_UPDATES: "Mises à jour automatique",
      AUTOMATIC_UPDATES_INFO: "Mise à jour automatique du logiciel du système",
      CHANGE_PASSWORD: "Changement du mot de passe",
      STORAGE_FIRMWAREFILE_INFO: "Choisissez comment stocker les fichiers des mises à jour sur votre appareil",
      CITY: "Ville",
      COMPANY_ADDRESS: "Adresse de la société",
      COMP_ADDRESS: "Adresse de la société*",
      COMPANY_DETAILS: "Détails de la société",
      COMPANY_NAME: "Nom de société",
      COMP_NAME: "Nom de société*",
      COMPANY_PHONE_NUMBER: "Numéro de téléphone de la société ",
      CODE: "Code pays",
      DATE_FORMAT: "Format de la date",
      DELETE_ACCOUNT: "Supprimer le compte",
      DELETE_CUSTOMER: "Supprimer le client",
      DEVICE_BLOCKING: "Le système est en mode repos, en mode secouru, une maintenance est nécessaire  ",
      EDIT_CUSTOMER_INFO: "Modifier les informations du client",
      EMAIL: "E-mail",
      EMAIL_ADDRESS: "Adresse e-mail",
      REQUIRED_FIELD: "Les champs marqués (*) sont obligatoires pour continuer.",
      FIRMWARE_FILE: "Fichiers des mise à jour",
      FIRMWARE_UPDATES: "Mise a jour du logiciel.",
      FIRST_NAME: "Prénom",
      FT_DT_FAIL: "Echec des tests, déconnexion, panne batterie, panne de lampe, etc.",
      HEALTH_REPORT: "Carnet de santé",
      LANGUAGE: "Langue",
      LAST_NAME: "Nom",
      MONTHLY_REPORTING: "Mensuel",
      NEW_RELEASE_FIRMWARE_UPDATE: "Mises à jour logiciel",
      NOTIFICATIONS: "Notifications",
      ORGAN_UPDATED_SUCCESSFULLY: "Organisation mise à jour avec succès",
      PASSWORD: "Mot de passe",
      PERSONAL_DETAILS: "Détails personnels",
      PHONE_NUMBER: "Numéro de téléphone",
      VALID_FIRSTNAME_ERROR: "Saisir un prénom",
      POSTAL_CODE: "Code postal",
      PREFERENCES: "Préférences",
      PRIMARY_CONTACT: "Contact principal",
      PRIMARY_CONTACT_EMAIL_ADDRESS: "E-mail du principal contact",
      PRIMARY_CONTACT_PHONE_NUMBER: "Téléphone du principal contact",
      PRIMARY_EMAIL_ADDRESS: "E-mail principal",
      PRIMARY_PHONE_NUMBER: "Numéro de téléphone principal",
      PRODUCT_UPDATE: "Mise à jour des produits",
      SAVE_CHANGES: "Sauvegarder les changements",
      SECONDARY_CONTACT: "Second contact ",
      SECONDARY_CONTACT_EMAIL_ADDRESS: "E-mail du second contact",
      SECONDARY_CONTACT_PHONE_NUMBER: "e-mail du second contact",
      SECONDARY_EMAIL_ADDRESS: "Seconde adresse e-mail",
      SECONDARY_PHONE_NUMBER: "Second numéro de téléphone",
      SLA_DETAILS: "Détail du contrat",
      STATUS_UPDATE: "Changement d'état",
      STORAGE: "Mémorisation",
      TIME_ZONE: "Fuseau horaire",
      UNITS: "Unités",
      USER_NAME: "Nom d'utilisateur",
      USER_UPDATED_SUCCESSFULLY: "Utilisateur mis à jour avec succès",
      VERSION: "Version",
    },
    CHANGE_PASSWORD: {
      EMAIL_CONFIRM_MESSAGE: "Un e-mail de confirmation a été envoyé à {{email}} ",
      PROBLEM_OCCURRED: "Un problème est survenu :",
      PASSWORD: "Changement du mot de passe",
      OLD_PASSWORD: "Ancien mot de passe",
      PASSWORD_CHANGED: "Mot de passe réinitialisé",
      PASSWORD_INFO: "Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial ",
      ERROR_MESSAGR: "Vos informations ne correspondent pas à nos données. Veuillez saisir à nouveau vos informations pour réessayer.",
      SUCCESS_MESSAGE: "Mot de passe modifié avec succès. Reconnexion svp.",
    },
    SYSTEM_DETAIL: {
      GUIDE: "(Guide)",
      USERS_DATA: "5 admins, 1 utilisateur",
      ACTIVATE_CONNECTIONS: "Activer la connexion au cloud",
      ADD_ANOTHER_CELLULAR: "Ajouter une connexion cellulaire de secours",
      ADD_SLA: "Ajouter un contrat de service",
      SYSTEM_DETAILS_TITLE: "Détails du Système",
      ALL_CONTACTS: "Tous les contacts",
      ALL_PEOPLE_IN_THIS_BUILDING: "Toutes les personnes de ce bâtiment",
      ALL_SYSTEMS_IN_THIS_BUILDING: "Tous les systèmes de ce bâtiment",
      BOTH_PORTS_CANNOT_BE_ON_SAME_SUBNET: "Les 2 ports ne peuvent avoir le même masque Subnet",
      BUILDING: "Bâtiment",
      BUILDING_INFO: "Information sur le bâtiment",
      BUILDING_TYPE: "Type de bâtiment",
      BUYER: "Acheteur",
      CONNECTION_TYPE: "Type de connexion",
      CONTACT_PERSON_Onsite: "Personne à contacter sur site",
      CONTACT_TYPE: "Type de contact",
      CUSTOMER_DETAILS: "Détails client",
      CUSTOMER_INFO: "Informations client",
      CUSTOMER_NUMBER: "Numéro client",
      DATE_COMMISSIONED: "Date de mise en service",
      DISABLE: "Désactiver",
      DURATION_TEST: "Test d'autonomie",
      DURATION_TEST_FAILED: "Défaut test d'autonomie",
      DURATION_TEST_PASSED: "Test d'autonomie réussi",
      ENABLED: "Activé",
      ENTER_VALID_GATEWAY_ADDRESS: "Entrer une adresse de gateway valide",
      ENTER_VALID_IP_ADDRESS: "Entrer une adresse IP valide",
      ENTER_VALID_SUBNET_MASK: "Entrer un subnet valide",
      ETHERNET_PORT_ONE: "Ethernet Port A",
      ETHERNET_PORT_TWO: "Ethernet Port B",
      EXPIRY_DATE: "Date d'expiration ",
      FIRMWARE_VERSION: "Version du logiciel",
      FOR_BUILDINGS: "Pour les bâtiments",
      FOR_SYSTEMS: "Pour les systèmes",
      FUNCTIONAL_TEST: "Test fonctionnel",
      FUNCTIONAL_TEST_FAILED: "Echec du test fonctionnel",
      FUNCTIONAL_TEST_PASSED: "Test fonctionnel réussi",
      GATEWAY: "Gateway",
      GATEWAY_ADDRESS: "Adresse de la gateway",
      GATEWAY_ADDRESS_REQUIRED: "Adresse de la gateway*",
      GATEWAY_NAME: "Nom de la gateway",
      SYSTEM_UNLINKED_GATEWAY: "a été déconnecté depuis",
      SYSTEM_LINKED_GATEWAY: "a été connecté à",
      HOST_NAME: "Nom de domaine",
      INTIALIZE_CONNECTION: "Initialiser la connexion",
      INTIALIZE_NETWORK: "Initialisation du réseau…",
      IP_ADDRESS: "Adresse IP",
      IP_ADDRESS_REQUIRED: "Adresse IP*",
      IP_GATEWAY_NOT_IN_SAME_SUBNET: "IP et gateway ne sont pas dans le même masque Subnet",
      ETHERNET_CONNECTED: "est maintenant connecté à Internet",
      SYSTEM_LINKING_TITLE: "Est-ce le système que vous souhaitez connecter ?",
      SYSTEM_UNLINKING_TITLE: "Est-ce le système que vous souhaitez déconnecter ?",
      SYSTEM_LINK_TITLE: "Connecter un système à ",
      LUMINAIRES: "Luminaires",
      LUMINARIES_LIST: "Liste des luminaires",
      MAC_ADDRESS: "Mac Address",
      MAIN_CONTACT: "Contact principal",
      NETWORK_INFORMATION: "Informations sur le réseau",
      NO_SYSTEM_FOUND: "Aucun système",
      OFFICE: "Bureau",
      ORGANIZATION: "Organisation",
      OTHER_STEPS: "Autres étapes, si nécessaire",
      OTHER_SYSTEMS: "Autres systèmes",
      PART_NUMBER: "Référence",
      SETPORT_SYSTEM_DETAILS_DESCRIPTION: "Veuillez terminer la configuration Ethernet.",
      SYSTEM_DETAILS_DESCRIPTION: "Veuillez trouver ces détails sur l'écran HMI devotre système d'éclairage de sécurité.",
      PORT_NUMBER: "Port Number*",
      PREV: "Précédent",
      PROJECT_LEAD: "Chef de projet",
      REGISTER_ACTIVATE_GATEWAY: "Enregistrer et activer la gateway",
      SERIALNO_VALUE: "Numéro de série",
      SERIAL_NUMBER: "Numéro de série",
      SETPORT_SYSTEM_LINK_TITLE: "Configurer le port pour connecter un système à",
      SLA_NUMBER_TITLE: "SLA#",
      SLAVE_NUMBER: "Slave Number*",
      SUBNET_MASK: "Subnet Mask",
      SUBNET_MASK_REQUIRED: "Subnet Mask*",
      SYN_DATE_TIME: "Synchroniser la date et l'heure",
      SYSTEM: "Système",
      SYSTEM_NAME: "Nom du système",
      SYSTEM_TYPE: "Type de système",
      TECHNICAL_PERSON: "Technicien",
      STATUS_DESCRIPTION: "Description longue. Vous pouvez décrire l'emplacement de l'appareil et à qui parler.",
      TOTAL_SLA: "Total SLA's",
      TRANSFER_ORGANIZATION: "Transférer cette organisation et ses systèmes \n vers un autre utilisateur (facultatif)",
      SYSTEM_UNLINK_TITLE: "Déconnecter le système depuis ",
      UPDATE_REQUIRED: "Mise à jour nécessaire",
      UPLOAD_DETAILS: "Télécharger les détails via Bluetooth",
      FIRMWARE_VERSION_VALUE: "v12.34-56",
      VERSION_AVAILABLE: "v123.457 disponible",
      VIEW_ALL: "Tout voir",
      VIEW_ALL_LUMINARIES: "Afficher tous les luminaires",
      PARTNO_VALUE: "xx xx xx xx",
      ETHERNET_SUBTITLE: "Vous pouvez désormais accéder à votre passerelle via le Web.",
    },
    USER_INVITATION_MANAGEMENT: {
      YOU: "Vous",
      INVITATION_MESSAGE_UPDATE_COUNT: "{{updatedInvitationCount}} invitations on été {{inviteStatus}} avec succés, {{rejectedInvitationCount}} invitations n'ont pas été {{inviteStatus}}.",
      SUCCESS_COUNT: "{{updatedInvitationCount}} invitations were {{inviteStatus}} successfully.",
      INVITE_ACCEPT: "Accepter",
      INVITE_ACCEPT_INVITATION: "Accepter l'invitation",
      INVITE_ACCEPTED: "accepté",
      INVITE_ACCEPTED_BUTTON: "accepté",
      INVITE_ACCEPT_INVITATION_DESCRIPTION_1: "Accepter cette invitation ajoutera les systèmes suivant à votre compte",
      ADMIN_APPROVE: "Approuver la demande",
      COMPANY: "Société",
      DECLINE_INVITATION: "Décliner l'invitation",
      TABLE_HEADER_DENIED_TITLE: "Déclinées  ({{count}})",
      INVITE_DENIED: "décliné",
      INVITE_DENY: "Refuser",
      ADMIN_DECLINE_INVITATION: "Décliner la demande",
      TABLE_HEADER_EXPIRED_AT: "Expirées",
      TABLE_HEADER_EXPIRED_TITLE: "Invitations expirées ({{count}})",
      INVITE_HISTORY: "Historique des invitations",
      INVITATION_SUCCESS_MESSAGE: "Invitation réussie !",
      JOIN: "Rejoindre",
      JOINALL: "Rejoindre tous",
      LEAVE_ORGANIZATION: "Quitter cette organisation",
      TABLE_HEADER_NAME: "Nom",
      INVITE_PENDING: "en attente",
      INVITE_REQUEST_ACCESS_DESCRIPTION_2: "Merci de demander l'accès à l'administrateur pour l'ajouter à votre compte",
      TABLE_HEADER_INVITE_DATE: "Reçu",
      REMOVE_USER: "Supprimer l'utilisateur",
      INVITE_REQUEST: "demande",
      INVITE_REQUEST_ACCESS: "Demande d'accès",
      REQUESTEDBY: "Demandé par",
      INSTRUCTIONS: "Veuillez accepter ou décliner l'invitation à ajouter des systèmes à votre compte. ",
      ADMIN_INSTRUCTIONS: "Approuver ou décliner la demande d'accès",
      SENTBY: "Envoyé par",
      TABLE_HEADER_INVITE_BY: "Envoyé par",
      TABLE_HEADER_TOTAL_ACCEPTED_NO_DATA_FOUND: "aucune invitation acceptée",
      TABLE_HEADER_DENIED_NO_DATA_FOUND: "Aucune invitation déclinée",
      TABLE_HEADER_EXPIRED_NO_DATA_FOUND: "Aucune invitation expirée",
      INVITATION_TEXT: "Une invitation est nécessaire pour rejoindre un système",
      INVITE_REQUEST_ACCESS_DESCRIPTION_1: "L'invitation a expiré et ne peut être ajoutée à votre compte",
      TABLE_HEADER_TOTAL_COUNT: "Invitations Acceptées",
      TABLE_HEADER_TOTAL_ACCEPTED_COUNT: "Invitations Acceptées",
      SUCCESS_MESSAGE: "Invitation de l'utilisateur réussi {{count}}",
      ERROR_MESSAGE: "La mise à jour de l'invitation a échoué",
      VIEW_ALL_ACCESS_RIGHTS: "Voir tous les droits d'accès ",
      TITLE: "Bienvenue, {{username}}",
      SUBTITLE: "Vous avez été invité a rejoindre les organisations suivantes :",
      ADMIN_SUBTITLE: "Vous avez demandé l'accès à des invitations expirées",
    },
    INACTIVITY: {
      LOGOUT: ", vous allez être déconnecté",
      INACTIVE_LOGOUT: "Déconnexion pour inactivité",
      TITLE: "Session expirée",
      WARNING: "Cette fenêtre est inactive depuis 15mn, vous allez être déconnecté sans aucune action d'ici ",
    },
    ATPLUS: {
      ATPLUS_FAILURE_COUNT: "{{count}} Défaut",
      ATPLUS_DATETIME_TWENTYFOUR_HOUR: "24 heures",
      ATPLUS_TWO_MINUTE_AGO: "depuis 2 mn",
      ATPLUS_ACTIVATION: "Activation",
      ADMIN: "Administrateur",
      ADMINVIEWER: "Administrateur & Utilisateur",
      ATPLUS_ADMIN_ACCESS: "Accès Admin",
      ATPLUS_ALERT_LEVEL: "Seuil d'alerte",
      ATPLUS_ALERTSETTING_HEADER: "Réglage du seuil d'alarme",
      ATPLUS_SEARCHLUMINAIRE_WARNING: "Tous les luminaires se verront attribuer une nouvelle adresse et une nouvelle configuration sera créée.",
      ATPLUS_LOGIN_FAILURE: "Une erreur s'est produite lors de la modification des paramètres",
      ATPLUS_DATETIME_AUTO_DATE_TIME_HEADER: "Date et heure automatiques",
      ATPLUS_AUTOMATIC_RESET: "Mode repos automatique",
      ATPLUS_AUTOMATIC_REST_MODE_MSG: "Le mode repos automatique mettra les luminaires au repos sir le secteur local est arrêté",
      ATPLUS_BATTERYFAILURE: "Défaut batterie",
      ATPLUS_CHANGE_ADMIN_PASSWORD: "Changer le mot de passe administrateur",
      ATPLUS_CHANGE_PASSWORD_FAILURE: "Erreur lors du cgangement de mot de passe",
      ATPLUS_CHANGE_PASSWORD_SUCCESS: "Changement de mot de passe réussi",
      ATPLUS_CHANGE_SERVICE_PARTNER_PASSWORD: "Changer le mot de passe Service",
      ATPLUS_CHANGE_PASSWORD: "Changer le mot de passe du système",
      SYSTEMPASSWORD: "Changer les mots de passe du système",
      ATPLUS_CHANGE_VIEWER_PASSWORD: "Changer le mot de passe utilisateur",
      ATPLUS_SYNC_TEST_SCHEDULE_INFO: "Cliquez sur « Synchroniser le calendrier des tests » pour synchroniser à partir de maintenant  les tests fonctionnels dans 7 jours et les tests d'autonomie dans 10 semaines.",
      ATPLUS_INFO_TAG_CLOSED: "Fermer",
      ATPLUS_COMISSIONING: "Mise en service",
      ATPLUS_COMMANDS: "Commandes",
      ATPLUS_COMMSFAILURE: "défaut de communication",
      ATPLUS_CONFIGRATION_CREATED: "Configuration créée",
      ATPLUS_CONFIGRATION_DETAIL: "Détails de la configuration",
      ATPLUS_CONFIGRATION_NAME: "Nom de la configuration",
      ATPLUS_CREATE_CONFIGRATION: "Créer la configuration",
      ATPLUS_DATETIME_CURRENT_TIME: "Date et heure actuelles",
      ATPLUS_DEVICE_DATETIME_CURRENT_TIME: "Date et heure du mobile",
      ATPLUS_CURRENT_TEST_SCHEDULE: "Calendrier des tests actuel",
      ATPLUS_REFRESH_LUMINAIRES: "Nous récupérons actuellement le statut le plus récent de tous les luminaires du bâtiment {{atplus}}.",
      ATPLUS_DATETIME: "Date et Heure",
      ATPLUS_DRYCONTACT_DEFAULT_SETTINGS: "Paramètres par défaut",
      ATPLUS_DETAILS: "Informations",
      ATPLUS_DRY_CONTACT: "Contact sec",
      ATPLUS_DRYCONTACT_INPUT: "entrée à contact sec",
      ATPLUS_ALERTSETTING_DRY_CONTACT_INPUT: "Entée contact sec activée",
      ATPLUS_DRYCONTACT_OUTPUT: "Sortie à contact sec",
      ATPLUS_ALERTSETTING_DRY_CONTACT_OUTPUT: "Sortie contact sec activée",
      ATPLUS_DRYCONTACT_HEADER: "Réglages du contact sec",
      ATPLUS_DRYCONTACT_STATUS: "Dry Contact Status",
      ATPLUS_DURATION_TEST_TITLE: "Test d'autonomie",
      ATPLUS_DTSUCCESS: "Test d'autonomie effectué",
      ATPLUS_DTFAILEDTITLE: "Défaut test d'autonomie",
      ATPLUS_DTINPROGRESS: "Test d'autonomie en cours",
      SERVICE: "Eaton Service",
      ATPLUS_ELSYSTEM_SYSTEM_TIME: "EL System Local Time",
      ATPLUS_EMC_HEADER: "Test EMC",
      ATPLUS_EMERGENCY: "Mode secours",
      ATPLUS_FW_UPDATE_EROOR: "Echec de la mise à jour du logiciel, veuillez réessayer.",
      ATPLUS_EXPORT_CONFIG: "Exporter une configuration",
      ATPLUS_EXPORT_CONFIGRATION_FAIL: "Echec de l'export de la configuration",
      ATPLUS_FAILED_LUMINAIRE: "Luminaires en défaut",
      ATPLUS_DRYCONTACT_FAILURE_THRESHOLD: "Défauts consécutifs au-delà du seuil",
      ATPLUS_FAILURETYPE: "Type de défaut",
      ATPLUS_FILE_ALREADY_EXIST_TITLE: "Le fichier existe déjà",
      ATPLUS_FILE_ALREADY_EXIST_MSG: "Nom de fichier déjà utilisé, veuillez saisir un autre nom.",
      ATPLUS_FIRMWARE_FILE_FOR: "logiciel version",
      ATPLUS_FIRMWARE_UPDATE: "Mise à jour du logiciel",
      ATPLUS_FW_UPDATE_COMPLETE: "Mise à jour du logiciel réussie",
      ATPLUS_FW_UPDATE_INPROGRESS: "Mise à jour du logiciel en cours…",
      ATPLUS_FUNCTIONAL_TEST_TITLE: "Test fonctionnel \n",
      ATPLUS_FTSUCCESS: "Test fonctionnel effectué",
      ATPLUS_FTINPROGRESS: "Test fonctionnel en cours",
      CHANGEPASSWORDFOR: "Vous devez modifier votre mot de passe",
      ATPLUS_IMPORT_CONFIG: "Importer une configuration",
      ATPLUS_INEMERGENCYMODE: "En mode secours",
      ATPLUS_INFAILURE: "En défaut",
      ATPLUS_INRESTMODE: "En mode repos",
      ATPLUS_INTESTMODE: "En Test",
      ATPLUS_REFRESH_INCOMPLETE: "Données incomplètes",
      ATPLUS_CHANGE_PASSWORD_INCORRECT_PASSWORD: "Mot de passe incorrect",
      ATPLUS_DRYCONTACT_STATUS_INPUT: "Entrée",
      ATPLUS_EXPORT_CONFIGRATION_INVALID_NAME: "Nom de la configuration incorrect",
      GWCDATPLUSHEADER: "S'agit-il du système d'éclairage de sécurité que vous mettez en service ?",
      ATPLUS_LAMPFAILURE: "Défaut luminaire",
      ATPLUS_CONFIGRATION_FILE_TITLE_INFO: "Lettres, tirets, underscores et chiffres uniquement",
      ATPLUS_ALERTSETTING_LOCAL_MAIN_FAILURE: "Perte du secteur",
      ATPLUS_LOAST_CONNECTION: "Perte de la connexion, veuillez réessayer.",
      ATPLUS_ALERTSETTING_LUMINAIRE_ALERTS: "Alertes des luminaires",
      ATPLUS_DTFAILEDDESCRIPTION: "Défaut luminaire",
      ATPLUS_LUMINAIRE: "Luminaires",
      ATPLUS_FOUNDLUMINAIRE: "Luminaires trouvés...",
      ATPLUS_FOUNDWERELUMINAIRE: "luminaires ont été trouvés.",
      ATPLUS_MAIN_OFFICE: "Main Office",
      ATPLUS_DRYCONTACT_SUBHEADER: "Main Office",
      ATPLUS_MANAGE_CONFIG: "Gérer les configurations",
      ATPLUS_LOGIN_MAX_ATTEMPTS_EXCEEDED: "Le nombre maximum de tentatives a été atteint",
      ATPLUS_MINIMIZE: "Réduire",
      ATPLUS_MULTIPLE_FAILURES: "Multiples défauts",
      ATPLUS_FOUNDNEWLUMINAIRE: "nouveaux luminaires ont été trouvés.",
      ATPLUS_NEW_TEST_SCHEDULE: "Nouveau calendrier de test",
      ATPLUS_DATETIME_SELECTED_TZ_PLACEHOLDER: "New York",
      ATPLUS_NEXT_SCHEDULES_DTTEST: "Prochain test d'autonomie :",
      ATPLUS_NEXT_SCHEDULES_FTTEST: "Prochain test fonctionnel :",
      ATPLUS_NO_FW_FILES: "Pas de logiciel pour la version ",
      ATPLUS_NO_LUMINAIRES_INSTALLED: "Aucun luminaire installé",
      ATPLUS_NO_LUMINAIRES_YET: "Pas encore de luminaires",
      ATPLUS_DRYCONTACT_RADIO_CLOSED: "Normalement Fermé - NF",
      ATPLUS_DRYCONTACT_RADIO_OPEN: "Normalement Ouvert - NO",
      ATPLUS_NEWLUMINAIRES_HEADLINE: "Recherchez uniquement les luminaires récemment ajoutés ou remplacés dans le « Bureau principal » pour leur attribuer automatiquement des adresses.",
      ATPLUS_OPENSNACK: "Ouvert",
      ATPLUS_OPERATIONS: "Opérations",
      ATPLUS_ALERTSETTING_OTHER_ALERTS: "Autres alertes",
      ATPLUS_DRYCONTACT_STATUS_OUTPUT: "Sortie",
      ATPLUS_DRYCONTACT_OUTPUT_DEFAULT_SETTINGS: "Réglages par défaut des sorties",
      ATPLUS_ALERTSETTING_CHOOSE_NUMBER: "Veuillez choisir entre 1 et 10 luminaires",
      ATPLUS_ADDITIONALPASSWORDINSTRUCTION: "Merci de créer les nouveaux mots de passe des Utilisateurs, Administrateurs et Services pour se connecter à la télécommande",
      ATPLUS_DONT_MOVE: "Veuillez ne pas vous éloigner.",
      ATPLUS_DOWNLOAD_FW_FILES: "retélécharger le fichier et réessayer",
      ATPLUS_BLE_DISCONNECT_SUBTITILE: "Veuillez vous assurer que vous êtes à portée du Bluetooth et réessayer.",
      ATPLUS_RELOGINPASSWORDINSTRUCTION: "Merci de saisir le mot de pour vous reconnecter au",
      ATPLUS_RELOGIN_MESSAGE: "Veuillez saisir le nouveau mot de passe pour vous reconnecter à la gateway.",
      ATPLUS_ENTRY_MESSAGE: "Veuillez entrer vos identifiants pour changer les mots de passe",
      ATPLUS_EXPORT_CONFIGRATION_INFO: "euillez nommer le fichier pour enregistrer et exporter votre configuration la plus récente.",
      ATPLUS_STAY_WHERE: "Veuillez ne pas bouger",
      ATPLUS_ALERTSETTING_NOTIFY: "Veuillez utiliser les paramètres suivants pour personnaliser vos préférences d'alerte.",
      ATPLUS_REFRESH_COMPLETE: "Rafraichissement terminé",
      ATPLUS_REFRESHLUMINAIRE_TEST: "Rafraichir l'état des luminaires",
      ATPLUS_REFRESH_LUMINAIRES_PAGETITLE: "Rafraichir l'état des luminaires",
      ATPLUS_REFRESH_LUMINAIRES_PROGRESS: "Rafraichissement de l'état des luminaires en cours...",
      ATPLUS_USE_RELIGHT: "Le rallumage n’est disponible que pour les luminaires actuellement en mode repos.",
      ATPLUS_DRYCONTACT_INPUT_RELIGHT: "Rallumer JOUR",
      ATPLUS_RELIGHT_SUCCESS: "L'allumage a été activé avec succès",
      ATPLUS_RESTMODE: "Mode repos",
      ATPLUS_REST_SUCCESS: "Le mode repos a été activé avec succès",
      ATPLUS_DURATION_TEST: "Démarrer le test d'autonomie",
      ATPLUS_FUNCTIONAL_TEST: "Démarrer le test fonctionnel",
      ATPLUS_SEARCHALL_LUMINAIRE: "Rechercher tous les luminaires",
      ATPLUS_SEARCH_ALL_LUMINAIRES: "Rechercher tous les luminaires",
      ATPLUS_SEARCH_NEWLUMINAIRES: "Rechercher les nouveaux luminaires",
      ATPLUS_SEARCHNEW_LUMINAIRE: "Recherche des nouveaux luminaires terminée",
      ATPLUS_SERVICE_ACCESS: "Accès Service",
      ATPLUS_SERVICEPARTNERPASSWORD: "Partenaire de maintenance",
      ATPLUS_CHANGEPASSWORD_INSTRUCTION: "Créer des mots de passe pour les utilisateurs avec",
      ATPLUS_ADDITIONALPASSWORD: "Créer des mots de passe supplémentaires",
      ATPLUS_DATE_TIME: "Régler la date et l’heure",
      ATPLUS_DATETIME_HEADER: "Régler la date et l’heure",
      ATPLUS_ALERTSETTING_LUMINAIRE_ALERTS_INFO: "Choisir le nombre de luminaires en défaut déclenchant le seuil d'alarme.",
      ATPLUS_SHOW_CONNECTED_LUMINAIRE: "Afficher les luminaires connectés",
      ATPLUS_SHOW_REGISTERED_LUMINAIRE: "Afficher les luminaires enregistrés",
      SIGN_IN: "Se connecter",
      SIGN_IN_AS: "Se connecter en tant que ",
      ATPLUS_RELIGHT: "Allumage générale",
      ATPLUS_REST: "Extinction générale- Repos",
      ATPLUS_STARTSEARCH: "Démarrer la recherche",
      ATPLUS_START_TEST: "Démarrer le test ",
      ATPLUS_VISIBILITY_TEST: "Démarrer Visibilité +",
      ATPLUS_DRYCONTACT_INPUT_VISIBILITY: "Démarrer Visibilité +",
      ATPLUS_STARTDT: "lancement de test d'autonomie",
      ATPLUS_STARTFT: "lancement de test fonctionnel",
      ATPLUS_STARTDTSNACK: "arrêter le test ",
      ATPLUS_EMC: "Passer au test CEM",
      ATPLUS_SYNCH_TEST: "Synchronisation du calendrier des tests",
      ATPLUS_SYNC_TEST_SCHEDULE: "Synchronisation du calendrier des tests",
      ATPLUS_SYNC_TEST_SCHEDULED_INFO: "Synchroniser l'heure des tests pour tous les luminaires du batiment ",
      TELECOMMANDPASSWORD: "Mot de passe de la télécommande",
      ATPLUS_TEST: "Test",
      ATPLUS_SYNC_TEST_TIME: "Heure du test:",
      ATPLUS_DRYCONTACT_INPUT_READONLY: "L’entrée à contact sec est en lecture seule, mais peut être configurée manuellement sur le micro commutateur.",
      ATPLUS_DRYCONTACT_STATUS_READONLY: "L’état est lecture seule, mais peut être configurée manuellement sur le micro commutateur.",
      ATPLUS_LUMINAIRE_INSTALL: "Cette action fera clignoter tous les luminaires de l' installation.",
      ATPLUS_SHOW_CONNECTED_LUMINAIRE_INFO: "Cette action fera clignoter tout luminaire correctement câblé et connecté à la télécommande.",
      ATPLUS_SEARCHLUMINAIRE_HEADLINE: "Recherchez tous les luminaires « Bureau principal » pour leur attribuer automatiquement des adresses.Cette action modifiera définitivement l'adresse des luminaires précédemment installés et des nouveaux luminaires pour créer une nouvelle configuration. Cette action ne peut pas être annulée.",
      ATPLUS_SHOW_REGISTERED_LUMINAIRE_INFO: "Cette action ne fera clignoter que les luminaires inclus dans la configuration.",
      ATPLUSSYNC_TEST_SCHEDULED_TIME_INFO: "Cet action synchronisera le TF dans 1 semaine et le TA dans 10 semaines exactement",
      ATPLUS_AUTOMATIC_REST_MODE_INFO: "Cette information est en lecture seule, la configuration doit être réalisée manuellement sur la télécommande",
      ATPLUS_LOAST_CONNECTION_INFO: "Le mobile doit rester à porté de la télécommande et connecté durant le test fonctionnel",
      ATPLUS_DEVICE_RANGE: "Le mobile doit rester à porté de la télécommande pour cette opérationqui peut prendre quelques minutes",
      ATPLUS_TOMANYLUMINAIREHEADER: "Ce système ne peut prendre en charge que 64 luminaires. Seuls les 64 premiers appareils détectés feront partie de votre configuration.Veuillez utiliser la fonction « Rechercher des luminaires enregistrés » dans la fenêtre de mise en service pour rechercher les luminaires non inclus dans votre configuration.",
      ATPLUS_CURRENT_LUMINAIRES_CONFIGURATION: "Cela va remplacer la configuration actuelle des luminaires",
      ATPLUS_TIMEREMAINS: "temps estimé,",
      ATPLUS_TIMEREMAINING: "temps restant",
      ATPLUS_DATETIME_TIMEZONE: "Fuseau horaire",
      ATPLUS_TIMEZONE_HEADER: "Fuseau horaire",
      TLU_ATPLUS_PASSWORD_INCORRECT: "Mot de passe TLU AT+ incorrect",
      ATPLUS_TOMANYLUMINAIRE: "Trop de luminaires ont été trouvés",
      ATPLUS_FIRMWARE_UPDATING: "Mise à jour du logiciel...",
      ATPLUS_USE_REST: "Utilisez le mode repos pour les luminaires qui ne sont plus alimentatés par le secteur.",
      ATPLUS_FINISHSEARCH: "Utilisez ces résultats pour créer et partager une nouvelle configuration à l’aide de l’option « Exporter ».",
      ATPLUS_DATETIME_AUTO_DATE_TIME_SUBTEXT: "Utilisez la date, l’heure et le fuseau horaire de votre appareil mobile pour vous synchroniser automatiquement avec le système TLAT+. Désactivez cette fonction pour régler manuellement la date, l’heure et le fuseau horaire.",
      ATPLUS_VIEWLOGBOOK: "Voir les événements",
      VIEWER: "Utilisateur",
      ATPLUS_VIEWER_ACCESS: "Accès Utilisateur",
      ATPLUS_LOGIN_MAX_ATTEMPTS_EXCEEDED_BODY: "Vous pouvez réessayer de vous connecter à ceci dans ",
      ATPLUS_DT_DIALOG: "Vous risquez de perdre des données si vous vous éloignez trop de la télécommande AT+ pendant le test d'autonomie. Temps restant estimé, 01 :00 :00",
      ATPLUS_FT_DIALOG: "Ne pas s'éloigner de la télécommande durant le test fonctionnel Temps estimé : 00:02:00",
      ATPLUS_NO_LUMINAIRES_YET_DESCRIPTION: "Vous devrez utiliser la fonction « Rechercher tous les luminaires » et activer votre TLAT+ pour voir vos luminaires ici.",
      ATPLUS_CONFIGRATION_CREATED_MESSAGE: "Votre configuration a été créer avec succés",
      ATPLUS_RELOGIN_SUCCESS_MESSAGE: "Vos nouveaux mots de passe ont été enregistrés.",
      SAVE_TO_CLOUD: "Enregistrer sur le cloud",
      EXPORT: "Exporter",
      ATPLUS_IMPORT_CONFIG_SETTING_DEVICE_NAME: "Entrer le nom de lappareil",
      TPLUS_IMPORT_CONFIG_LOADING_LUM_CONFIGRATION: "Chargement de la configuration des luminaires...",
      ATPLUS_IMPORT_CONFIG_SETTING_DRY_CONTACT_CONFIGURATION: "Définition de la configuration des contacts secs",
      ATPLUS_IMPORT_CONFIG_PROGRESS_ALERT_TITLE: "Arrêter et perdre les changements en cours ?",
      TPLUS_IMPORT_CONFIG_PROGRESS_ALERT_SUBTITLE: "Quitter cet écran annulera le processus d'importation et vous devrez recommencer.",
    },
    USERLIST: {
      DELETECONFIRMATION: "Le compte {{email}} et toutes ses données seront définitivement supprimés. La suppression de ce compte n'affectera pas l'organisation, les équipements ou toute donnée associée.",
      ACCOUNT_DELETE: "compte effacé",
      ALL_LOCATION: "Tous les emplacements",
      DELETEACCOUNT: "Voulez-vous vraiment supprimer?",
      COL_EMAIL: "E-mail",
      COL_LOCATION: "Emplacement",
      COL_ROLE: "Rôle",
      COL_STATUS: "Etats",
      DELETE_SUCCESS: "Le compte utilisateur pour {{email}} a été effacé avec succès",
      COL_USERNAME: "Nom d'utilisateur",
    },
    REPORTS: {
      ISSUES: "{{issueCount}} problème",
      MONTHLY_REPORT: "Rapport mensuel",
      NO_ISSUES: "Aucun problème sur cette période",
      REPORT_DOWNLOAD_FAILED: "Le téléchargement du rapport a échoué",
      REPORT_DOWNLOAD_SUCCESSFULLY: "Le rapport a été téléchargé avec succès",
    },
    MAP: {
      MAP_COORDINATES: "{{latitude}} N, {{longitude}} E",
      BLOCKED: "Bloqué",
      MAP_COORDINATES_LABEL: "Coordonnées",
      MAP_ADDRESS_DISPLAY: "Afficher l'adresse sur la carte",
      MAP_DRAG_MARKER: "Faites glisser sur la carte pour placer l'épingle",
      ERROR: "Erreur",
      OFFLINE: "Hors ligne",
      READY: "Prêt",
      MAP_ENABLE_ON_COUNTRY: "Sélectionner un pays pour activer la carte",
      SYSTEM: "Système",
      SYSTEMS: "Système",
      WARNING: "Attention",
    },
    MAINTENANCE_DETAILS: {
      ADD_DOC: "+ ajouter un document",
      ADD_NOTES: "+ ajouter une note",
      ADD_DOCUMENT_BUTTON: "+ ajouter un document ou une photo",
      NEW_MAINTENANCE: "+ Nouvelle demande de maintenance",
      CREATE_MAINTAINENCE_BUTTON: "Assigner et notifier",
      TECHNICIAN_INPUT_LABEL: "Assigner",
      STATUS_COMPLETED: "Complété",
      CUSTOMER_CONTACT: "Contacts clients",
      DATE_CREATED: "Date enregistrée",
      DELETE_REQ: "Requète de suppression ",
      COMMENTS_LABEL: "Description (Optionnelle)",
      DOCUMENTATION: "Documentation",
      STATUS_DUE: "Due",
      EMERGENCY: "Mode secours",
      STATUS_EXPIRED: "Expirées",
      HIGH: "Haut",
      IN_DUE: "Dans {{differenceInDays}} jours",
      UPLOAD_DOCUMENT_ERROR_MESSAGE_INVALID_TYPE: "Format non pris en charge. PDF, JPEG, et PNG uniquement.",
      SUPPORT_TYPE_INPUT_LABEL: "Issue Type",
      LIFECYCLE_MAINTAINANCE: "Maintenance du cycle de vie",
      LIFECYCLE_MAINTENANCE: "Maintenance du cycle de vie",
      LOCATION: "Emplacement(s)",
      LOW: "Faible",
      MAINTENANCE_HISTORY: "Historique de maintenance",
      MAINTENANCE_REQUEST: "Demande de maintenance",
      MEDIUM: "Moyen",
      NO_CUSTOMER_LINKED: "Aucun client associé",
      NO_DOC_ADDED: "No Documentation Added",
      NO_MAINTENACE_FOUND: "Aucune maintenance trouvée",
      NO_NOTES_ADDED: "Aucune note ajoutée",
      NOTES: "Notes",
      UPLOAD_DOCUMENT_ERROR_MESSAGE_INVALID_FILE_SIZE: "Le fichier dépasse la limite autotisée de 2MB",
      PAST_DUE: "En retard",
      PRIORITY: "Priorité",
      STATUS_REJECTED: "Rejected",
      REOPEN_SERVICE_TICKET: "réouvrir un tcket service",
      REQUEST_PRIORITY_INPUT_LABEL: "Request Priority",
      REQ_MAINTENANCE: "Demande de maintenance",
      REQUESTED_MAINTAINANCE: "Demande de maintenance",
      SCHEDULED: "Planifiée",
      STATUS_SCHEDULED: "Planifiée",
      SCH_SERVICE_DATE: "Date d'intervention planifiée",
      SEARCH: "Search by ticket number",
      SERVICE_DATE: "Date de l'intervention",
      SERVICE_TICKET_NO: "Numéro du ticket d'intervention",
      SERVICE_TYPE: "Type de service",
      SUPPORT_TECHNICIAN: "Technicien support",
      SYS_FAILURE: "Défaut systéme",
      SYSTEM_FAILURE: "Défaut systéme",
      TICKET_CLOSED: "Ticket clos",
      TICKET_NO: "Numéro du ticket",
      STATUS: "Status des tickets : tous",
      TODAY: "Aujourd'hui",
      UPLOAD_DOCUMENT_ERROR_MESSAGE_MAX_3_FILES: "3 fichiers maximum",
      EDIT_SERVICE_TICKET: "Modifier le ticket de service",
      MAINTENANCE_OPERATIONS: "Opérations de maintenances",
      SCHEDULED_MAINTENANCE: "Maintenances planifiées",
      RECENT_MAINTENANCE: "Maintenances récentes",
      CLOSE_SERVICE_TICKET: "Clôturer le ticket de service",
      CLOSE_TICKET: "Ticket de service",
      DESCRIBE_ACTION: "Décrire les actions entreprises pour résoudre le problème",
      TICKET_RESOLUTION: "Résolution du ticket",
      CLOSED_BY: "Clôturé par ",
    },
    ASSETS_DETAILS: {
      ADD_BUILDINGS: "+ Ajouter un Bâtiment",
      ADD_SYSTEMS: "+ Ajouter un Système",
      NEW_MAINTAINENCE: "+ Nouvelle demande de maintenance",
      BUILDINGS: "Bâtiments",
      CUSTOMER_MAINTAINENCE_HEADER: "Détails client",
      CUSTOMER_INFO_HEADER: "Informations client",
      DOWNLOAD_AS: "Télécharger en …",
      FIRMWARE: "Logiciel",
      GATEWAYS: "Gateways",
      LINK_TO_CUSTOMER_BUTTON: "Associé à un client",
      MAIN_CONTACT: "Contact principal",
      RIGHT_MAINTAINENCE_HEADER: "Détails de la maintenance",
      MOST_RECENT_STATUS: "Les plus récents",
      NOTIFY_USERS_MAINTAINENCE_HEADER: "Notifier les utilisateurs :",
      SUBHEADER_TEXT_MAINTAINENCE: "Veuillez consulter les informations, désigner un technicien et inclure tous les détails utiles pour résoudre le problème.",
      PREVIOUS_SYSTEM_STATUS: "Etat précedent du système",
      CREATE_MAINTAINENCE_HEADER: "Planifier une nouvelle  intervention",
      ADD_BUILDING_ASSET: "Sélectionner une organisation pour créer un bâtiment",
      SELECT_ORGANIZATION_LABEL: "Sélectionner une organisation",
      SHOW_FAVORITE_BUILDINGS: "Afficher uniquement les bâtiments favoris",
      SHOW_FAVORITE_GATEWAYS: "Afficher uniquement les gateways favorites",
      SHOW_FAVORITE_SYSTEMS: "Afficher uniquement les systèmes favoris",
      SLA_SUBTITLE: "SLA#",
      LEFT_MAINTAINENCE_HEADER: "Détails du Système",
      SYSTEM_NAME_TYPE_SUBTITLE: "Nom et type du système",
      SYSTEMS: "Système",
      HELPER_TEXT_MAINTAINENCE: "Cette demande alertera  lescontacts principaux et secondaires de {{customerName}} . Il comprendra par défaut des informations pertinentes sur l'emplacement les équipements, l'alarme et l'événement.",
      NO_CUSTOMER_TEXT: "Ce système n'est pas associé à un client",
    },
    COMMON: {
      SYNC_NOW_TIME: "'=--min ago",
      REQUIRES_FIRMWARE_UPDATE: "1 Mise à jour du logiciel recommandée",
      NOTIFICATION_REPORT_MESSAGE_BODY: "Un nouveau rapport mensuel pour : \n {{bodytext}} est disponible",
      ACCESS: "Accès",
      ACTIVE: "Actif",
      ALERT: "Alerte !",
      DOWNLOAD_PERMISSION: "Autoriser la sauvegarde du fichier localement sur votre appareil ",
      GO_BACK: "Retour",
      NOTIFICATION_EVENT_OCCURED: "{{logType}}s'est produit sur le système suivant",
      ASSETS: "Systèmes",
      BACK: "Retour",
      BLOCKED: "Bloqué",
      CANCEL: "Annuler",
      CANNOT_REFRESH_TOKEN: "Impossible d'actualiser le token",
      CLOSE: "Fermer",
      SET_ETHERNET: "Configurer le port",
      CONFIRM: "Confirmer",
      CONNECT: "Connecter",
      CONNECTION: "Connection",
      CSV: "CSV",
      DASHBOARD: "Page d'accueil",
      DESCRIPTION: "Description",
      DISCARD: "Annuler",
      DISCARD_UNSAVED_CHANGE: "Annuler les changements non sauvegardés",
      DONE: "Terminée",
      DOWNLOAD: "Télécharger",
      DOWNLOAD_FAILED: "erreur téléchargement, veuillez réessayer",
      COMPANY: "Eaton",
      EDIT: "Editer",
      APP_NAME: "Building Safety Manager",
      EMPTY: "Vide",
      INTERVAL_SEVENDAYS: "Tous les 7 jours",
      INTERVAL_90DAYS: "Tous les 90 jours",
      EXCEL: "Excel File",
      EXIT: "Sortir",
      EXTEND: "Etendre",
      DOWNLOAD_SUCCESS: "Le fichier a été téléchargé avec succès",
      ANOMALY: "En anomalie",
      INACTIVE: "Inactif",
      INCOMPLETE: "Incomplet",
      INFO: "Info",
      INTERVAL: "Intervalle",
      KEEP_EDITING: "Continuer à modifier",
      LAST_RETREIVED: "Dernière tentative",
      LAST_RETRIEVED: "Dernière tentative, il y a -- min ",
      LINK: "connecter",
      LOCATE: "Localiser",
      LOGBOOK: "Journal",
      LOGGED_IN_SUCCESSFULLY: "Connection réussie",
      NOTIFICATION_NEW_REPORT_AVAILABLE: "Nouveau rapport disponible",
      NEWUSER: "Nouvel utilisateur",
      NEXT: "Suivant",
      NODATA: "Aucune donnée",
      OFF: "Off",
      OFFLINE: "Hors ligne",
      OK: "Ok",
      ON: "On",
      PASSWORD: "Mot de passe",
      PDF: "PDF",
      SELECT_PREFERRED_LANGUAGE: "Veuillez selectionner votre préférence de langue",
      POWERED_BY_BRIGHTLAYER: "Powered by Brightlayer",
      READY: "Prêt",
      RECOONECTTOGATWAY: "Se reconnecter à la gateway",
      REFRESH: "Rafraichir",
      REMOVE: "Supprimer",
      RETURNTODASHBOARD: "Retour à la page d'accueil",
      NAVIGATION_HEADER: "Safety Management System",
      SAVE: "Enregistrer",
      REFRESH_TOKEN_ERROR: "Le token de sécurité ne peut être nul ou vide",
      SESSION_ALERT_TITLE: "Session expirée",
      SET: "Configurer",
      SETTINGS: "Réglages",
      VIEW_ALL_SYSTEM: "Voir tout",
      SKIP: "Ignorer",
      STOP: "Arrêter",
      PERMISSION_ERROR: "La sauvegarde n'a pas été autorisée",
      SYNC: "Sync",
      SYNCH_OFFLINE_DATABASE: "Synchroniser la base de données hors ligne",
      SYSTEM: "Système",
      SESSION_ALERT_SUBTITLE: "Cette fenêtre est inactive depuis 15mn, vous allez être déconnecté dans {{extendTime}}",
      TIME: "Heure",
      TRYAGAIN: "Réessayer",
      DOWNLOAD_FAIL: "Echec du téléchargement, recommencer",
      UNAUTHORIZED_ACCESS: "Accès non-autorisé",
      UNLINK: "Déconnecter",
      USERS: "Utilisateurs",
      VIEW: "Voir",
      WELCOME: "Bienvenue !",
      YES: "Oui",
      EMPTY_SYSTEM_TITLE: "Vous n'avez pas encore de systèmes dans cette organisation.",
      EMPTY_SYSTEM_SUBTITLE: "Vous pourrez superviser l'état de vos éclairages de sécurité une fois que vous aurez commencé à ajouter des systèmes à cette organisation.",
      ERROR_OCCURED: "Une erreur s'est produite dans",
      OOPS: "Oups!'",
      THERE_IS_ERROR: "Uil y a une erreur",
      NEW_VERSION_AVAILABLE: "Nouvelle version de l'application disponible dans",
      UNDER_MAINTENANCE: "L'application est actuellement en maintenance.",
      UNDER_MAINTENANCE_TITLE: "Maintenance en cours sur l'App",
      UPDATE_AVAILABLE_TITLE: "Mise à jour disponible",
      UPDATE_REQUIRED_TITLE: "Mise à jour requise",
      UPDATE_NOW: "Mettre à jour maintenant",
      ASK_ME_LATER: "Demandez-moi plus tard",
      OKTA_LOGOUT_ALERT: "Êtes-vous sûr de vouloir vous déconnecter d'Okta ?",
      OKTA_LOGIN_BUTTON: "Sign In with Okta",
      CANNOT_PROCCED_LOGIN: "You are an unauthorized user and cannot proceed with login.",
    },
    PASSWORD_REQUIREMENTS: {
      LENGTH: "8-16 caractères",
      LOWER: "Une minuscule",
      NUMBERS: "Un chiffre",
      SPECIAL: "un caractère spécial",
      UPPER: "Une majuscule",
    },
    AUTH_TRANSLATION: {
      FORGOT_PASSWORD: {
        LINK_SENT_ALT: "Un lien pour réinitialiser votre mot de passe a été envoyé à  <b>{{email}}</b>.",
        LINK_SENT: "Un lien pour réinitialiser votre mot de passe a été envoyé à  <b>{{email}}</b>.",
        ERROR: "Impossible de réinitialiser votre mot de passe pour le moment.",
        INSTRUCTIONS_ALT: "Saisir l'e-mail associé à votre compte",
        INSTRUCTIONS: "Saisir l'e-mail associé à votre compte. Si cet e-mail est déjà associé à un compte Eaton, vous recevrez une réponse sous  <b>one business day</b>.",
        RESET_CODE_ERROR: "Votre code de réinitialisation est erroné.",
      },
      MESSAGES: {
        USER_PHONE_NUMBER_INVALID: "Le numéro de téléphone n'est pas valide",
        CONGRATS: "Félicitations!",
        CONTACT: "Contacter le support Eaton",
        EMAIL_SENT: "E-mail envoyé",
        ERROR: "Erreur!",
        FAILURE: "Défaut",
        INVALID_VERIFICATION_CODE: "Le code de vérification n'est pas valide ou supérieur aux 6 caractères autorisés",
        LOADING: "Chargement …",
        GENERIC_ERROR_MESSAGE: "Merci de contacter votre administrateur si la requête ne peut aboutir.",
        EMAIL_ENTRY_ERROR: "Veuillez entrer un e-mail valide",
        USER_EMAIL_INVALID: "Entrer une adresse e-mail valide",
        REQUEST_ERROR: "Désolé, votre demande n'a pas abouti.",
        SUCCESS: "Succès",
        WELCOME: "Bienvenue",
        LOGIN_MESSAGE: "Vous êtes connecté",
      },
      CHANGE_PASSWORD: {
        PROBLEM_OCCURRED: "Un problème est survenu :",
        CANCEL: "Annuler",
        PASSWORD: "Changement du mot de passe",
        CONFIRM_NEW_PASSWORD: "Confirmer nouveau mot de passe",
        OLD_PASSWORD: "Ancien mot de passe",
        PASSWORD_CHANGED: "Mot de passe réinitialisé",
        PASSWORD_INFO: "Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial ",
        UPDATE: "Mettre à jour",
        EMAIL_CONFIRM_MESSAGE: "Un email de confirmation a été envoyé à  <b>{{email}}</b>.",
        ERROR_MESSAGE: "Vos informations ne correspondent pas à nos données. Veuillez saisir à nouveau vos informations pour réessayer.",
        SUCCESS_MESSAGE: "Mot de passe modifié avec succès. Reconnexion svp.",
      },
      SELF_REGISTRATION: {
        VERIFY_EMAIL: {
          MESSAGE: "Un code de vérification a été envoyé sur votre boite e-mail. Cliquer sur le lien ou saisir le code. Validité de 30mn.",
          VERIFICATION_CODE_PROMPT: "Code non-reçu ?",
          RESEND: "Renvoyer l'e-mail de vérification",
          VERIFICATION: "Code de vérification",
        },
        ALREADY_REGISTERED_USER: "Utilisateur déjà enregistré",
        INSTRUCTIONS: "Pour créer un compte Eaton, Veuillez entrer les informations requises ci-dessous. Vous devrez vérifier votre adresse e-mail pour continuer.",
      },
      REGISTRATION: {
        STEPS: {
          COMPLETE: "Compte créé !",
          ACCOUNT_DETAILS: "Détails de l'utilisateur",
          COMPANY_ADDRESS: "Adresse de la société",
          COMPANY_DETAILS: "Détails de la société",
          CREATE_ACCOUNT: "Créer un compte",
          PASSWORD: "Créer un mot de passe",
          LICENSE: "License Agreement",
          VERIFY_EMAIL: "Vérifier l'e-mail",
        },
        INSTRUCTIONS: {
          ACCOUNT_DETAILS: "Saisir vos informations pour finaliser la création de votre compte",
          COMPANY_DETAILS: "Ces données sont utilisées pour aider Eaton à vous fournir le support pour votre installation d'éclairage de sécurité. Si vous êtes à l'initiative de la création de l'organisation, merci d'entrer les informations relatives à votre société et non celle de votre client. ",
          COMPANY_ADDRESS: "Ces données sont utilisées pour aider Eaton à vous fournir le support pour votre installation d'éclairage de sécurité. Si vous êtes à l'initiative de la création de l'organisation, merci d'entrer les informations relatives à votre société et non celle de votre client. ",
        },
        EULA: {
          AGREE_TERMS: "I have read and agree to the Terms & Conditions",
          LOADING: "Loading End User License Agreement...",
        },
        UNKNOWN_EMAIL: "E-mail inconnu",
        UNKNOWN_ORGANIZATION: "Organisation inconnue",
        FAILURE_MESSAGE: "Nous n'avons pas pu finaliser votre inscription. Appuyez sur Continuer ci-dessous pour terminer.",
        SUCCESS_MESSAGE_ALT: "Votre compte a été créé avec succès avec l'e-mail <1>{{email}}</1>. Vous avez été ajouté à l'organisation  <3>{{organization}}</3>.Merci de continuer ci-dessous pour finir.  ",
        SUCCESS_MESSAGE: "Your account has been successfully {{state}} with the email {{email}}.\n\nYou have been added to {{organization}} .',",
        SUCCESS_EXISTING: "Votre compte a été créé avec succès, merci de vous reconnecter avec votre e-mail et votre mot de passe  ",
        SUCCESS_SYNCED_MESSAGE: "Votre compte BSMS a été synchronisé avec succès avec les informations BrightLayer Cloud associées à l'e-mail <b>{{email}}</b>.",
        SUCCESS_FINISH: "Please click 'Finish' to login and access your account.",
        SUCCESS_DASHBOARD: "Please click 'Go To My Dashboard' to continue your account.",
      },
      ACTIONS: {
        BACK: "Retour",
        CANCEL: "Annuler",
        CHANGE_LANGUAGE: "Modifier la langue",
        CLICK_BUTTON: "Cliquer sur le bouton",
        UPDATE_REDUX: "Click the button to update the redux store value!",
        CONTINUE: "continuer",
        DONE: "Terminée",
        FINISH: "Terminer",
        GO_HOME: "Go to Home",
        GO_DASHBOARD: "Aller à la page d'accueil",
        GO_TEST: "Go to Test Page",
        LOG_IN: "connexion",
        LOG_OUT: "Déconnexion",
        NEXT: "Suivant",
        OKAY: "OK",
        REMEMBER: "Se souvenir de moi",
        RESEND: "Renvoyer",
        SEND_AGAIN: "Renvoyer",
        SEND_EMAIL: "Envoyer l'e-mail",
        CREATE_ACCOUNT: "S'enregistrer",
        UPDATE: "Mettre à jour",
      },
      FORMS: {
        COMPANY_NAME: "Nom de société",
        CONFIRM_PASSWORD: "Confirmer nouveau mot de passe",
        COUNTRY: "Pays",
        CURRENT_PASSWORD_ERROR: "Le mot de passe doit être valide",
        FIRST_NAME: "Prénom",
        RESET_PASSWORD: "Mot de passe oublié",
        LAST_NAME: "Nom",
        ORGANAIZATION_ADRESS: "Adresse de l'organisation - ligne 1*",
        PASSWORD: "Mot de passe",
        PASS_MATCH_ERROR: "Les mots de passe doivent être identiques",
        PHONE_NUMBER: "Numéro de téléphone",
        TOGGLE_PASSWORD_VISIBILITY: "visibilité du mot de passe",
      },
      LABELS: {
        CURRENT_PASSWORD: "Ancien mot de passe",
        EMAIL: "Adresse e-mail",
        FORGOT_PASSWORD: "Mot de passe oublié ?",
        NEED_ACCOUNT: "besoin d'un compte ?",
        NEW_PASSWORD: "Nouveau mot de passe",
        OPTIONAL: "Optionnel",
        PASSWORD: "Mot de passe",
        USERNAME: "Nom d'utilisateur",
      },
      LOGIN: {
        INCORRECT_CREDENTIALS: "E-mail utilisateur ou mot de passe incorrect",
        GENERIC_ERROR: "Votre demande ne peut être traitée pour le moment.",
        INVALID_CREDENTIALS: "Le nom d'utilisateur ou le mot de passe est incorrect",
      },
      GATEWAY_COMMISSION_STEP: {
        STEP_NUMBER: "Etape {{stepNo}} sur 4",
      },
    },
    REGISTRATION: {
      STEPS_ERROR_MESSAGE: {
        USER_PHONE_NUMBER_INVALID: "Le numéro de téléphone n'est pas valide",
        USER_ALREADY_EXIST_MESSAGE: "Utilisateur déjà enregistré",
        USER_INVALID_ENTRY: "Ces valeurs ne peuvent être nulles ou vides ",
        GENERIC_ERROR_MESSAGE: "Merci de contacter votre administrateur si la requête ne peut aboutir.",
        USER_EMAIL_INVALID: "Entrer une adresse e-mail valide",
        VERIFICATION_CODE_EXPIRED_MESSAGE: "Le code a expiré. Cliquer sur le bouton pour en recevoir un nouveau.",
      },
      STEPS_MESSAGE: {
        CREATE_ACCOUNT_VERIFY_EMAIL: "Un code de vérification a été envoyé sur votre boite e-mail. Cliquer sur le lien ou saisir le code. Validité de 30mn.",
        CREATE_ACCOUNT_USER_DETAILS: "Saisir vos informations pour finaliser la création de votre compte",
        CREATE_ACCOUNT_COMPANY_DETAILS: "Ces données sont utilisées pour aider Eaton à vous fournir le support pour votre installation d'éclairage de sécurité. Si vous êtes à l'initiative de la création de l'organisation, merci d'entrer les informations relatives à votre société et non celle de votre client. ",
        CREATE_ACCOUNT_ENTER_EMAIL: "Pour créer votre compte pour Building Safety Management, veuillez saisir les informations nécessaires. Une vérification de votre adresse e-mail est nécessaire.  ",
      },
      STEPS: {
        COMPLETE_OTHER_ADOPTER: "Compte confirmé !",
        COMPLETE: "Compte créé !",
        ACCOUNT_DETAILS: "Détails de l'utilisateur",
        COMPANY_ADDRESS: "Adresse de la société",
        COMPANY_DETAILS: "Détails de la société",
        CREATE_ACCOUNT: "Créer un compte",
        PASSWORD: "Créer un mot de passe",
        FORGOT_PASSWORD: "Mot de passe oublié",
        VERIFY_EMAIL: "Vérifier l'e-mail",
      },
      EULA: "Veuillez selectionner votre préférence de langue",
      FAILURE_MESSAGE: "Nous n'avons pas pu finaliser votre inscription. Appuyez sur Continuer ci-dessous pour continuer vers la suite Eaton Building Safety Management System ",
      SUCCESS_MESSAGE: "Votre compte a été créé avec succès avec l'e-mail {{email}}.",
      SUCCESS_MESSAGE_OTHER_ADOPTER: "Votre compte a été synchronisé avec succès à Brightlayer avec l'e-mail <2>{{email}}</2>.",
    },
    VERIFY_EMAIL: {
      EMAIL_INFO: "Un code de vérification a été envoyé sur votre boite e-mail. Cliquer sur le lien ou saisir le code. Validité de 30mn.",
    },
    USER_INVITATION_REGISTRATION: {
      ACCEPT: "Accepter",
      DENY: "Refuser",
      INSTRUCTIONS: "Si cet e-mail est associé à un compte chez Eaton, vous recevrez une réponse dans un délai de <3> jour ouvrable</3>.<br/><br/>Pour des problèmes urgents liés au compte, veuillez appeler",
      TITLE: "Safety Management System - Invitation",
      INVITATION_VALIDATION_ERROR: "Votre code d'invitation est erroné",
    },
    DASHBORAD: {
      ACCEPT_BUTTON: "Accepter l'invitation",
      ACCEPT_HEADER: "Accepter l'invitation ?",
      ACCEPTED_INVITATION: "Invitations acceptées",
      ACCEPT_TITLE: "Accepter l'invitation ajoutera l'élément suivant à votre compte :",
      ADD_NEW_ORGANIZATION: "Ajouter une nouvelle Organisation",
      ADD_ANOTHER_ORGANIZATION: "Ajouter une autre Organisation",
      ALL_BUILDINGS: "Tous les Bâtiments",
      ALL_CITIES: "Toutes les villes",
      ALL_COMPANIES: "Toutes les sociétés",
      ALL_COUNTRIES: "Tous les pays",
      ALL_CUSTOMER_ID: "Tous les clients",
      ALL_GATEWAYS: "Toutes les Gateways",
      ALL_ORGANIZATIONS: "Toutes les Organisations",
      ALL_STATUSES: "Tous les états",
      ALL_STATUS: "Tous les états",
      ALL_TYPES: "Tous les types",
      BLOCKED: "Bloqué",
      BUILD_DETAILS: "Information du bâtiment ",
      BUILDINGS: "Bâtiments",
      CUSTOMERS: "Clients",
      DECLINE: "Décliner",
      DECLINED_INVITATION: "Invitations déclinées",
      DISPLAY_ADDRESS_ON_MAP: "Afficher l'adresse sur la carte",
      MAP_DRAG_TITLE: "Faites glisser sur la carte pour placer l'épingle",
      REQUEST_ACCESS_SENT: "Demande d'accès envoyée à l'utilisateur par e-mail",
      ERROR: "Erreur",
      EVENT: "Evénement",
      EXPIRED_INVITATION: "Invitations expirées",
      EXPORT_REPORT: "Exporter le Rapport",
      GATEWAY: "Gateways",
      IN_ANOMALY: "En anomalie",
      INFORMATION: "Information",
      INVITATION_HISTORY: "Historique des invitations",
      ISSUES: "Problèmes",
      JOIN: "Rejoindre",
      LIST_VIEW: "Liste",
      MAP_VIEW: "Carte",
      MONTHLY_REPORT: "Rapport mensuel",
      NO_ISSUES_DURING_THIS_PERIOD: "Aucun problème sur cette période",
      ORG_DETAILS: "Détails de l'organisation",
      PENDING_INVITATION: "Invitations en attente",
      CHOOSE_REPORT_YOU_WOULD_LIKE_TO_SAVE_SHARE: "Sélectionner le rapport à partager ou sauvegarder",
      PLEASE_MAKE_A_SELECTIONS: "Veuillez faire une sélection",
      REQUEST_SUBTITLE: "Veuillez demander l'accès à la personne qui a envoyé l'invitation pour ajouter cet élément à votre compte.",
      INVITATION_BODY: "Veuillez sélectionner les éléments à ajouter à votre compte ou décliner une invitation.",
      READY: "Prêt",
      NO_CONTENT: "Remplacer cette page par votre propre contenu",
      REPORTS: "Rapports",
      REQUEST_BUTTON: "Demande d'accès",
      REQUEST_HEADER: "Demander l'accès",
      CHECK_INVITATIONS: "Recherche des invitations…",
      SELECT_ALL: "Sélectionner tout",
      SELECTIONS_CONFIRMED: "Sélection confirmée",
      SERVICE_MANAGERS: "Service managers",
      SYSTEMS: "Système",
      TECHNICIANS: "Techniciens",
      EMPTY_ACCEPTED_INVITATION: "aucune invitation acceptée",
      EMPTY_DECLINED_INVITATION: "Aucune invitation déclinée",
      EMPTY_EXPIRED_INVITATION: "Aucune invitation expirée",
      EMPTY_REQUESTED_INVITATION: "Aucune invitation demandée",
      REQUEST_TITLE: "L'invitation a expiré et ne peut être ajoutée à votre compte",
      TOTAL_ACCEPTED_INVITATIONS: "Total des invitations acceptées",
      TOTAL_LUMINAIRES: "Nombre de luminaires",
      TOTAL_SYSTEMS: "Nombre de systèmes",
      UPDATING_PROFILE: "Mise à jour de votre compte…",
      VIEW_ALL: "Tout voir",
      VIEW_ALL_ACCESS_RIGHTS: "Voir tous les droits d'accès ",
      VIEW_ALL_BUIDINGS: "Voir tous les Bâtiments",
      VIEW_ALL_SYSTEM: "Afficher tous les systèmes",
      WARNING: "Attention",
      INVITATION_SUB_HEADING: "Vous avez été invité a rejoindre les organisations suivantes :",
      ACCEPT_SUBTITLE: "nan",
      REPORTS_BUILDINGS: "Bâtiments",
      REPORTS_SYSTEMS: "Systèmes",
    },
    TRANSFER_BUILDINGS: {
      PASSWORD: "Mot de passe du compte",
      ERRORMESSAGE: "Login limits exceeded. please try again after sometime",
      HEADER: "Transférer définitivement tous les bâtiments {{buildingCount}}, y compris toutes les  X gateways et les {{systemCount}} systèmes rattachés, vers une autre organisation. Vous devez être administrateur des deux organisations pour effectuer l’action.",
      ACTION: "Merci de saisir votre mot de passe pour confirmer l'action ",
      TRANSFER_TO: "Transférer vers...",
    },
    MERGE_ASSETS: {
      PASSWORD: "Mot de passe du compte",
      MERGE_DELETE: "Regrouper puis supprimer",
      MERGE_ASSETS_TITLE: "Transférer les systèmes dans un autre bâtiment",
      SUCCESS: "Transfert réussi",
      ACTION: "Merci de saisir votre mot de passe pour confirmer l'action ",
      HEADER: "Transférez toutes les {{gatewayCount}} gateways et les {{systemCount}} systèmes  rattachés vers un autre bâtiment et supprimez définitivement l'actuel.",
      TRANSFER_TO: "Transférer vers...",
    },
    DRAWER: {
      ACCOUNTPREFERENCES: "Préférences d'utilisateur",
      RIGHTS_RESERVED: "All Rights Reserved",
      ASSETS: "Systèmes",
      COPYRIGHT: "Copyright © Eaton",
      DASHBOARD: "Page d'accueil",
      OVERVIEW: "Page d'accueil",
      DEVICES: "appareils",
      HELP: "Aide",
      HELPANDFEEDBACK: "Aide et commentaires",
      LEGAL: "Légal",
      LOCATIONS: "Emplacements",
      LOGBOOK: "Journal",
      MAINTENANCE: "Maintenance",
      MANAGELOCATION: "Gérer les emplacements et sites ",
      MANAGEUSER: "Gérer les utilisateurs",
      ORGSETTINGS: "Mes organisations",
      MYPROFILE: "Mon profil",
      EMERGENCY_LIGHT: "Powered by Brightlayer",
      REPORTS: "Rapports",
      ELDIGITAL: "Safety Management System",
      SELECT_ACCOUNT: "Sélectionner un compte",
      SETTINGS: "Réglages",
      TIMELINE: "Calendrier",
      USERS: "Utilisateurs",
    },
    SETTINGS: {
      TITLE: "Préférences d'utilisateur",
      SUBTITLE: "niveau Administrateur",
    },
    NAVIGATION_DRAWER: {
      ACCOUNT_PREFERENCES: "Préférences d'utilisateur",
      ALL_RIGHTS_RESERVED: "All Rights Reserved",
      CHANGE_PASSWORD: "Changement du mot de passe",
      COPYRIGHT: "Copyright",
      HELP_AND_FEEDBACK: "Aide et commentaire",
      LEGAL: "Légal",
      LOGOUT: "Déconnexion",
      PROJECT_SETTINGS: "Mes organisations",
      REPORTS: "Rapports",
      USERS: "Utilisateurs",
    },
    USER_MENU: {
      ACCOUNT_SETTING: "Réglage du compte",
      CONTACT_US: "Nous contacter",
      LOG_OUT: "Déconnexion",
    },
    USER_DETAIL: {
      ACTIVE: "Actif",
      COMPANY_ADRESS: "Adresse de la société",
      COMPANY_DETAIL: "Détails de la société",
      COMPANY_NAME: "Nom de société",
      COMPANY_PHONE_NUMBER: "Numéro de téléphone de la société ",
      EMAIL_ID: "Adresse e-mail",
      INACTIVE: "Inactif",
      PERSONAL_ADRESS: "Fonction",
      LEAVE_ORGANISATION: "Quitter cette organisation",
      JOB_TITLE: "Adresse personnelle",
      PERSONAL_PHONE_NUMBER: "Numéro de téléphone personnel",
      PHONE_NUMBER: "Numéro de téléphone",
      REMOVE_USER: "Supprimer l'utilisateur",
      USER_NAME: "Nom d'utilisateur",
    },
    FILTER: {
      ACTIVE_LOGS_ONLY: "Evénements actifs uniquement",
      ALL: "Tous",
      ALL_LOG_TYPES: "Tous les événements ",
      CUSTOM: "Personnaliser",
      PAST_TWELVE_MONTHS: "Les 12 derniers mois",
      PAST_SIX_MONTHS: "Les 6 derniers mois",
      SINCE_THE_BEGINNING_OF_YEAR: "Depuis le {{currentyear}}",
    },
    LOGBOOK: {
      LOGBOOK_ACTIVELOG: "Evénements actifs uniquement",
      LOGBOOK_ALL: "Tous",
      LOGBOOK_ALLLOG: "Tous les événements ",
      CUSTOM_LOGBOOK_RANGE: "Plage de journal personnalisée",
      CUSTOM: "Personnaliser",
      END_DATE: "Date de fin",
      LOGBOOK_GROUPBY: "Grouper par :",
      PAST_TWELVE_MONTHS: "Les 12 derniers mois",
      PAST_SIX_MONTHS: "Les 6 derniers mois",
      FILTER_DOWNLOAD_TEXT: "Veuillez selectionner un filtre pour télécharger le journal.",
      LOGBOOK_SHOW: "Afficher :",
      SINCE_THE_BEGINNING_OF_YEAR: "Depuis le {{currentyear}}",
      START_DATE: "Date du début",
      LOGBOOK_TIME_RANGE: "Période :",
    },
    BUILDING_DETAILS: {
      ADD_BUILDING: "Ajouter un Bâtiment à",
      ALL_SYSTEMS: "Tous les Systèmes",
      VIEW_ALL_GATEWAYS: "Voir toutes les gateways",
    },
    ADD_ORGANIZATION: {
      ORG_ADD_DESCRIPTION: "Ajoutez des commentaires supplémentaires (facultatifs) pour aider votre équipe de maintenance.",
      ADDITIONAL_DESC: "Description supplémentaire",
      ORG_ADD_BLDG_BELONGS: "appartient.",
      CREATE_ORGANIZATION_TITLE: "Créer une Organisation",
      ORG_SUBTITLE: "Créer une organisation permet de regrouper des bâtiments et de coordonner la supervision des systèmes d'éclairage de sécurité",
      DETAILS_FOR: "Informations pour ",
      ORG_FOR: "pour",
      INVALID_CHARACTER: "Caractère non valide",
      ORG_NAMELENGTH: "Lettres, tirets, underscores et chiffres uniquement",
      ORG_ADD_BLDG: "Now let’s add the building where the ",
      ORGANIZATION_CREATED: "Organization Created",
      VALID_EMAIL_ERROR: "Veuillez entrer un e-mail valide",
      ORG_PRIMARYCONTACT: "Contact principal",
      ORG_PROVIDED_DETAILS: "Veuillez fournir des détails si nécessaire",
      ORG_SECONDARYCONTACT: "Secondary Contact (Optional)",
      ORG_SKIPBUTTON: "Ignorer pour l'instant",
      ORG_SKIPMESSAGE: "Ignorer et saisir des coordonnées pour chaque bâtiment plus tard.",
      ORG_SUCCESS: "Successfully created organization",
      ALPHABET_INPUT: "Utiliser des lettres",
      ALPHANUMERIC_INPUT: "Utiliser des lettres et des chiffres",
      ALPHANUMERIC_DASH_UNDERSCORE_INPUT: "Utiliser des lettres, des chiffres, tirets et underscores",
      ORG_HELPTEXT: "Cela permet aux services d'Eaton ou votre mainteneur, une intervention rapide.",
      ORGANIZATION_DETAILS: "À qui les électriciens/mainteneur doivent-ils s'adresser pour",
    },
    MANAGE_USERS: {
      ADVANCED_INVITE_USER_ORGANIZATION_SELECTION: "L'ajouter aux Organisations :",
      INVITE_USER_ORGANIZATION_LABEL: "Ajouter aux Organisations :",
      USER_ROLE_ADMIN: "Administrateur",
      ADVANCED_INVITE_ADMIN: "Administrateur :",
      ADVANCED_INVITE: "Invitation avancée",
      INVITE_USER_ADVANCE: "Avancé",
      INVITE_USER_AS: "Comme",
      ADVANCED_INVITE_USER_SELECTION: "Sélectionner un utilisateur existant ou nouveau : ",
      INVITE_USER_EMAIL_NAME: "E-mail*",
      INVITE_USER_EMAIL: "E-mail*",
      INVITE_USER_EMAIL_USER_NAME: "E-mail ou nom de l'utilisateur",
      INVITE_USER_SUBTITLE: "Accorder un accès à un utilisateur existant ou nouveau",
      ADVANCED_INVITE_SUBTITLE: "Accorder l’accès à des organisations, des bâtiments et des systèmes spécifiques.",
      ADVANCED_INVITE_ADMIN_DESCRIPTION: "Accorder l'accès sélectionné pour tous les systèmes.",
      ADVANCED_INVITE_LIMITED_DESCRIPTION: "Accorder des accès restreints afin qu'un utilisateur ne voit que les informations de base relatives à ce qui est essentiel à son rôle. Ceci est utile lorsque qu'une personne n'a besoin de surveiller qu'un seul système dans un bâtiment.",
      ADVANCED_INVITE_VIEWER_DESCRIPTION: "Accorder des droits restreints d'utilisateur au niveau choisi.",
      INVITATION_FAIL_HEADER: "Invitation expirée",
      INVITE_USER_AS_COLLEAGUE: "Inviter comme collègue",
      INVITE_USER: "Inviter un utilisateur",
      INVITE_SUCCESS_MESSAGE_SINGLE: "Invité {{email}} à {{organization}}.",
      INVITE_SUCCESS_MESSAGE_MULTIPLE: "Invité {{email}} à {{organizationCount}} organisations.",
      USER_ROLE_LIMITED: "Restreint",
      ADVANCED_INVITE_LIMITED: "Limité :",
      MY_DETAILS_HEADER: "Mes informations",
      OTHER_USER_LIST_HEADER: "Autres utilisateurs",
      ADVANCED_INVITE_PLEASE_ASSIGN_ROLES: "Merci d'assigner le niveau d'accès pour {{email}} aux organisations, bâtiments et systèmes. ",
      REMOVE_USER: "Supprimer {{name}} de '{{title}}' ?",
      INVITATION_REQUEST_ACTION: "Demander une nouvelle invitation",
      INVITE_USER_ORGANIZATION_SELECT_ALL: "Sélectionner tout",
      INVITE_USER_SEND_INVITE: "Inviter",
      INVITATION_FAIL_BODY: "Ce lien a expiré. Veuillez contacter l'administrateur de ce système pour recevoir une nouvelle invitation par e-mail.",
      USERS_IN_HEADER: "Utilisateurs dans ",
      USER_ROLE_VIEWER: "Utilisateur",
      ADVANCED_INVITE_VIEWER: "Utilisateur:",
    },
    ORGANIZATION_DETAILS: {
      ADDRESS: "Adresse",
      BUILDING_DETAILS: "Information du bâtiment ",
      DELETE_FOREVER: "Supprimer définitivement",
      EDIT_BUILDING: "Editer le bâtiment",
      EDIT_ORGANIZATION: "Modifier l'organisation",
      EMAIL_ADDRESS: "Adresse e-mail",
      MERGE_ASSETS: "Transférer les systèmes dans un autre bâtiment",
      ORGANIZATION: "Organisation",
      ORGANIZATION_DETAILS: "Détails de l'organisation",
      PHONE_NUMBER: "Numéro de téléphone",
      PRIMARY_CONTACT: "Contact principal",
      PRIMARY_CONTACT_EMAIL_ADDRESS: "E-mail du principal contact",
      PRIMARY_CONTACT_PHONE_NUMBER: "Téléphone du principal contact",
      SECONDARY_CONTACT: "Second contact ",
      SECONDARY_CONTACT_EMAIL_ADDRESS: "E-mail du second contact",
      SECONDARY_CONTACT_PHONE_NUMBER: "e-mail du second contact",
      TRANSFER_BUILDINGS: "Transférer les bâtiments dans une autre organisation ",
      USERS: "Utilisateurs",
    },
    COMMON_INPUT: {
      COMMON_ADDRESS: "Adresse",
      COMMON_ADDRESSLINE2: "Adresse - Line 2 (Optionnelle)",
      COMMON_BUILDINGNAME: "Nom du bâtiment",
      COMMON_CITY: "Ville",
      COMMON_COUNTRY: "Pays",
      COMMON_OPTIONALDESC: "Description (Optionnelle)",
      COMMON_EMAIL_ADDRESS: "Adresse e-mail",
      COMMON_NAME: "Nom de l'organisation",
      COMMON_PHONENUMBER: "Numéro de téléphone",
      VALID_ADDRESS: "Veuillez entrer une adresse valide",
      VALID_PHONE_ERROR: "Veuillez entrer un numéro de téléphone valide",
      VALID_POSTAL_CODE: "Veuillez entrer un code postal valide",
      VALID_FIRSTNAME_ERROR: "Veuillez entrer un prénom valide",
      VALID_LASTNAME_ERROR: "Veuillez entrer un nom valide",
      VALID_VERIFICATION_CODE: "Veuillez entrer un code de vérification valide",
      COMMON_POSTALCODE: "Code postal",
      VALID_CITY_ERROR: "Veuillez entrer une ville valide",
      VALID_COMPANY_ERROR: "Veuillez entrer une société valide",
    },
    TOAST_MSG: {
      CREATE_CUSTOMER_ERROR_CODE_9069: "Adresse non valide ou excédant 250 caractères",
      CREATE_CUSTOMER_ERROR_CODE_9070: "Adresse non valide ou excédant 250 caractères",
      INVITE_TECHNICIAN_ERROR_CODE_9130: "Droits d'administrateur requis pour inviter un utilisateur",
      INVITE_USER_ERROR_CODE_9130: "Droits d'administrateur requis pour inviter un utilisateur",
      TOAST_ERROR_MERGE_ASSET_9213: "Droits d'administrateur requis pour rassembler des systèmes",
      TOAST_SUCCESS_BUILDING_CREATE: "Bâtiment créé avec succès",
      BUILDING_LIST_API_FAILURE: "Echec de l'entrée du bâtiment",
      BUILDING_TRANSFER_FAILURE: "Erreur lors du transfert du bâtiment",
      BUILDING_TRANSFER_SUCCESS: "Bâtiment transféré avec succès",
      TOAST_SUCCESS_BUILDING_UPDATE: "Bâtiment mis à jour avec succès",
      INVITE_USER_ERROR_CODE_9143: "ne peut réduire les droits de l'utilisateur",
      CREATE_CUSTOMER_ERROR_CODE_9072: "La ville n'est pas pas valide ou dépassant les 50 caractères autorisés ",
      CREATE_CUSTOMER_ERROR_CODE_9164: "Le commentaire n'est pas valide ou supérieur aux 255 caractères autorisés",
      CREATE_CUSTOMER_ERROR_CODE_9040: "L'indicatif pays n'est pas valide",
      CREATE_CUSTOMER_ERROR_CODE_9170: "Nom de société déjà utilisé",
      CREATE_CUSTOMER_ERROR_CODE_9023: "Le numéro n'est pas valide ou le nombre de numérà n'est pas compris entre 8 et 10",
      CREATE_CUSTOMER_ERROR_CODE_9073: "L'indicatif pays n'est pas valide ou le nombre de caractéres est supérieur à 4",
      CREATE_CUSTOMER_ERROR_CODE_9068: "Le nom du pays n'est pas valide ou le nombre de caractéres est supérieur à 50",
      CREATE_MAINTAINANCE_API_FAILURE: "défaut de l'API 'création d une maintenance'",
      TOAST_SUCCESS_CUSTOMER_CREATED: "Client créé avec succés",
      CUSTOMER_DETAIL_SUCCESS: "Données client extraite avec succès ",
      TOAST_ERROR_GET_CUSTOMER_INFO: "Echec de la récupération des données client",
      CREATE_CUSTOMER_ERROR_CODE_9225: "Numéro de client déjà disponible.",
      CREATE_CUSTOMER_ERROR_CODE_9172: "Le numéro de client n'est pas valide ou doit comporter entre 8 et 16 caractères.",
      CREATE_MAINTAINANCE_ERROR_CODE_9078: "La description n'est pas valide ou supérieur aux 255 caractères autorisés",
      DEVICE_LIST_API_FAILURE: "Echec de l'entrée du système",
      ACKNOWLEDGE_LOG_SUCCESS: "Evénement acquitté avec succès",
      CREATE_CUSTOMER_ERROR_CODE_9043: "E-mail non valide ou supérieur à 75 caractéres",
      INVITE_TECHNICIAN_ERROR_CODE_9043: "E-mail non valide ou supérieur à 75 caractéres",
      INVITE_USER_ERROR_CODE_9043: "E-mail non valide ou supérieur à 75 caractéres",
      INVITE_TECHNICIAN_ERROR_CODE_9136: "Entrée invalide ou une incompatibilité entre le type d'entité et le type de rôle",
      INVITE_USER_ERROR_CODE_9136: "Entrée invalide ou une incompatibilité entre le type d'entité et le type de rôle",
      INVITE_TECHNICIAN_ERROR_CODE_9127: "Duplica ou entrée invalide",
      INVITE_USER_ERROR_CODE_9127: "Duplica ou entrée invalide",
      TOAST_ERROR_BUILDING_UPDATE: "erreur de la mise à jour du bâtiment",
      CREATE_MAINTAINANCE_ERROR_CODE_9219: "nom de fichier invalide ou dépassant la limite de 50 caratères",
      TOAST_ERROR_GET_PASS_DETAILS: "Echec de l'API PAAS details",
      REMOVE_ACCESS_ERROR_CODE_3057: "Identifiant invalide, veuillez saisir un ID valide",
      REMOVE_ACCESS_ERROR_CODE_3059: "Type invalide, veuillez saisir un type valide",
      TOAST_ERROR_LINK_CUSTOMER_9203: "Type de contrat de service non valide.",
      REMOVE_ACCESS_ERROR_CODE_2007: "Utilisateur invalide, veuillez saisir un GUID valid",
      INVITE_TECHNICIAN_ERROR_CODE_9159: "Invite and invited by user should not be same",
      INVITE_USER_ERROR_CODE_9159: "Invite and invited by user should not be same",
      UPDATE_LOG_COMMENTS_SUCCESS: "Commentaire mis à jour avec succès",
      ACKNOWLEDGE_LOG_API_FAILURE: "Echec de l'acquittement du défaut ",
      CREATE_MAINTAINANCE_SUCCESS: "Activité de maintenance créée avec succès",
      TOAST_ERROR_MERGE_ASSET_9214: "Pas de système à transférer",
      CREATE_MAINTAINANCE_ERROR_CODE_9221: "3 documents maximum",
      TOAST_SUCCESS_ORG_CREATE: "Organisation créée avec succès",
      TOAST_ERROR_ORG_UPDATE: "erreur de la mise à jour de l'organisation",
      TOAST_SUCCESS_ORG_UPDATE: "Organisation mise à jour avec succès",
      CREATE_CUSTOMER_ERROR_CODE_9074: "Numéro invalide ou non-compris entre 8 et 10 chiffres",
      TOAST_ERROR_MESSAGE: "Merci de saisir tous les informations correctement avant d'envoyer",
      CREATE_MAINTAINANCE_ERROR_CODE_9220: "Veuillez saisir un identifiant Technicien valide",
      TOAST_ERROR_MERGE_ASSET_9048: "Veuillez entrer un ID bâtiment valide",
      CREATE_CUSTOMER_ERROR_CODE_9167: "Veuillez entrer un type de contact valide",
      CREATE_MAINTAINANCE_ERROR_CODE_9185: "Veuillez entrer un ID client valide",
      TOAST_ERROR_MERGE_ASSET_9210: "Please provide valid destination building id.",
      CREATE_MAINTAINANCE_ERROR_CODE_9217: "Veuillez entrer un lien valide",
      CREATE_CUSTOMER_ERROR_CODE_9163: "Erreur de nom ou dépassant les 24 caractères autorisés",
      CREATE_MAINTAINANCE_ERROR_CODE_9215: "Veuillez entrer un type valide.",
      CREATE_MAINTAINANCE_ERROR_CODE_9228: "Veuillez entrer une date de service valide.",
      CREATE_MAINTAINANCE_ERROR_CODE_9216: "Veuillez entrer un type de service valide.",
      CREATE_MAINTAINANCE_ERROR_CODE_9050: "Veuillez entrer un identifiant système valide",
      CREATE_CUSTOMER_ERROR_CODE_9071: "Code postal invalide ou non compris entre 4 et 10 caractères",
      CREATE_CUSTOMER_ERROR_CODE_9165: "E-mail du principal contact invalide ou dépassant les 255 caractères autorisés",
      CREATE_CUSTOMER_ERROR_CODE_9175: "Code postal du principal contact invalide ou dépassant les 6 caractères autorisés",
      CREATE_CUSTOMER_ERROR_CODE_9150: "Nom du principal contact invalide ou dépassant les 50 caractères autorisés",
      CREATE_CUSTOMER_ERROR_CODE_9151: "Prénom du principal contact invalide ou dépassant les 50 caractères autorisés",
      CREATE_CUSTOMER_ERROR_CODE_9173: "Numéro de téléphone principal invalide ou non-compris entre 8 et 10 caractères.",
      REMOVE_ACCESS_SUCCESS: "La suppression de l'utilisateur a été effectuée avec succès",
      TOAST_ERROR_MERGE_ASSET_9004: "Données introuvables",
      CREATE_CUSTOMER_ERROR_CODE_9166: "Commentaire du second contact invalide ou dépassant les 255 caractères autorisés",
      CREATE_CUSTOMER_ERROR_CODE_9076: "Second code pays non valide ou supérieur aux 4 caractères autorisés",
      CREATE_CUSTOMER_ERROR_CODE_9176: "Second code pays non valide ou supérieur aux 4 caractères autorisés",
      CREATE_CUSTOMER_ERROR_CODE_9077: "Seconde adresse e-mail non valide ou supérieur aux 75 caractères autorisés",
      CREATE_CUSTOMER_ERROR_CODE_9152: "Second prénom non valide ou dépassant les 50 caractères autorisés",
      CREATE_CUSTOMER_ERROR_CODE_9153: "Second nom non valide ou dépassant les 50 caractères autorisés",
      CREATE_CUSTOMER_ERROR_CODE_9147: "Second numéro de téléphone non valide ou non compris entre 8 et 10 caractères",
      CREATE_CUSTOMER_ERROR_CODE_9174: "Secondary phone2 number is invalid or length should be between 8 and 10.",
      CREATE_MAINTAINANCE_ERROR_CODE_9009: "Le token de sécurité ne peut être nul ou vide",
      CREATE_MAINTAINANCE_ERROR_CODE_9222: "Seul un service manager peut créer une intervention",
      CREATE_MAINTAINANCE_ERROR_CODE_9162: "SLA Number is not valid or length should be between 6 and 12 characters",
      INVITE_TECHNICIAN_ERROR_CODE_9158: "Some entities have limited role from invitedby roles",
      INVITE_USER_ERROR_CODE_9158: "Some entities have limited role from invitedby roles.",
      INVITE_TECHNICIAN_ERROR_CODE_9160: "Some entities have limited role in invite request",
      INVITE_USER_ERROR_CODE_9160: "Some entities have limited role in invite request.",
      TOAST_ERROR_MERGE_ASSET_9211: "Source and Destination building Ids are same",
      TOAST_ERROR_MERGE_ASSET_9212: "Source building and Destination building are not under same Organization",
      TOAST_ERROR_LINK_CUSTOMER_9204: "Système déjà lié à un contrat de service",
      INVITE_USER_ERROR_CODE_9223: "L'utilisateur actuel ne peut envoyer une invitation qu'à un utilisateur normal.",
      INVITE_USER_ERROR_CODE_9224: "L'utilisateur actuel ne peut pas envoyer une invitation à un service manager ou PAAS manager.",
      CREATE_MAINTAINANCE_ERROR_CODE_9218: "Le document fait plus de 2 Mo ou n'est pas au format PDF, JPEG ou PNG ",
      REMOVE_ACCESS_ERROR_CODE_9001: "Accès non-autorisé",
      CREATE_CUSTOMER_ERROR_CODE_9001: "Accès non-autorisé",
      CREATE_MAINTAINANCE_ERROR_CODE_9001: "Accès non-autorisé",
      CREATE_CUSTOMER_ERROR_CODE_9000: "Accés à cette fonctionnalité non autorisé",
      UPDATE_LOG_COMMENTS_API_FAILURE: "erreur de la mise à jour du commentaire",
      REMOVE_ACCESS_ERROR_CODE_3060: "Utilisateur déjà ajouté dans la liste. Veuillez ajouter un autre utilisateur.",
      TOAST_ERROR_UPDATE_USER_DETAILS: "erreur de la mise à jour de l'utilisateur",
      INVITE_USER_ERROR_CODE_9142: "L'utilisateur a été invité pour le même rôle.",
      INVITE_TECHNICIAN_SUCCESS: "Invitation envoyée avec succès",
      TOAST_ERROR_MERGE_ASSET_9044: "Le mot de passe n'est pas valide ou sa longueur doit être comprise entre 8 et 16 caractères",
      INVITE_TECHNICIAN_ERROR_CODE_9190: "Le type de rôle n'est pas une entrée valide",
      INVITE_USER_ERROR_CODE_9190: "Le type de rôle n'est pas une entrée valide",
      TOAST_SUCCESS_UPDATE_USER_DETAILS: "Utilisateur mis à jour avec succès",
      UPDATE_MAINTAINANCE_SUCCESS: "Opération de maintenance modifiée avec succés",
      UPDATE_MAINTAINANCE_ERROR_CODE_9001: "Accès non-autorisé",
      UPDATE_MAINTAINANCE_ERROR_CODE_9009: "Le token de sécurité ne peut être nul ou vide",
      UPDATE_MAINTAINANCE_ERROR_CODE_9078: "Description non valide ou dépassant les 255 caractères autorisés",
      UPDATE_MAINTAINANCE_ERROR_CODE_9222: "es droits de Service Manager ou de Technicien sont requis pour effectuer cette opération",
      UPDATE_MAINTAINANCE_ERROR_CODE_9227: "Veuillez fournir un identifiant de maintenance valide",
      UPDATE_MAINTAINANCE_ERROR_CODE_9245: "L'activité de maintenance a actuellement le même statut que celui demandé pour la mise à jour",
      UPDATE_MAINTAINANCE_ERROR_CODE_9246: "Limite de réouverture des opérations de maintenance atteinte",
      UPDATE_MAINTAINANCE_API_FAILURE: "Erreur de API de maintenance",
      TICKET_CLOSED_SUCCESS: "Ticket de serives clos avec succés",
    },
    MY_ORGANIZATION_DETAILS: {
      ADDRESS_Line1: "Adresse - Ligne 1",
      ADMINS: "Administrateurs",
      DELETE_FOREVER: "Supprimer définitivement",
      DESCRIPTION: "Description (Optionnelle)",
      EDIT_BUILDING: "Editer le bâtiment",
      EDIT_ORGANIZATION: "Modifier l'organisation",
      NAME_DESCRIPTION: "Nom et description",
      SAVE_ORGANIZATION: "Enregistrer l'organisation",
      TRANSFER_BULDING_ANOTHER_ORGANIZATION: "Transférer les bâtiments dans une autre organisation ",
      VIEWER: "Utilisateurs",
    },
    INVITE_USER: {
      ADMIN: "Administrateur",
      ADVANCE_INVITATION: "Invitation avancée",
      ADVANCE_INVITE: "Avancé…",
      ADVANCE_INVITE_CLOSE: "Etes-vous sûr de vouloir quitter cette invitation ?",
      AS_ADMIN: "Comme administrateur",
      AS_VIEWER: "Comme utilisateur",
      BUILDING_ACCESS: "Accés au bâtiment",
      ENTER_USER_EMAIL: "E-mail ou nom de l'utilisateur",
      GRANT_ACCESS_HEADER: "Accordez l’accès aux utilisateurs nouveaux ou existants.",
      ADVANCE_DES_CONTENT: "Accordez l’accès aux utilisateurs pour des organisations, bâtiments ou systèmes spécifiques.",
      GRANT_ADMIN_ACCESS: "Accorder l'accès sélectionné pour tous les systèmes.",
      GRANT_VIEW_ACCESS: "Accorder des droits restreints d'utilisateur au niveau choisi.",
      SEND_INVITE_FAILURE: "Echec de l'envoi de l'invitation",
      SEND_INVITE_SUCCESS: "Invitation envoyée avec succès",
      INVITATION_ACCEPTED: "Invitation acceptée avec succès",
      INVITATION_DENIED: "Invitation déclinée avec succès",
      INVITE_AND_ASSIGN: "Inviter et attribuer",
      INVITE_AS_COLLEGUE: "Inviter comme collègue",
      INVITE_USER: "Inviter un utilisateur",
      LIMITED: "Restreint",
      GRANT_LIMITED_ACCESS: "Accés restreint, ceci est utile pour un utilisateur qui n'a besoin de surveiller qu'un seul système dans un bâtiment.",
      NOBUILDING_AVAILABLE: "Aucun bâtiment",
      NOSYSTEMS_AVAILABLE: "Aucun système",
      ORGANIZATION_ACCESS: "Accés à l'organisation",
      SELECT_ORGANIZATION: "Sélectionner une organisation",
      PRIVILEGES: "Droits",
      RECEIVED: "Reçu",
      SEND_INVITE: "Inviter",
      SENT_BY: "Envoyé par",
      SYSTEM_ACCESS: "Accés système",
      VIEWER: "Utilisateur",
      ERROR_MESSAGE: "Votre demande a échouée",
    },
    DISCLAMIER: {
      OVERVIEW: "Le nombre d'alarmes représente toutes les alarmes actives",
      TIMELINE: "Les alarmes affichées représentent toutes les alarmes des 90 derniers jours",
    },
    LUMINARY_DETAILS: {
      ALL_ATSD: "Tous ATSD",
      ALL_CIRCUIT: "Tous les circuits",
      ALL_STATUSES: "Tous les états",
      ATSD: "ATSD #",
      CIRCUIT: "Circuit",
      LUMINAIRE_ADDRESS: "Adresse du luminaire",
      MODEL: "Modèle",
      TYPE: "Type",
    },
    SEARCH: {
      ALL_BUILDINGS: "Tous les Bâtiments",
      ALL_GATEWAYS: "Toutes les Gateways",
      ALL_ORGANIZATIONS: "Toutes les Organisations",
      ALL_STATUSES: "Tous les états",
      ALL_SYSTEMS: "Tous les Systèmes",
      ALL_TYPES: "Tous les types",
    },
    CUSTOMER_DETAILS: {
      ALL_USERS: "Tous les utilisateurs",
      DELETE_SLA: "Êtes-vous sûr de vouloir supprimer le contrat de service ?",
      BACK_UP_PHONE: "Connexon de secours cellulaire",
      BUYER_HEADER: "Acheteur",
      BUYER: "Acheteur",
      CONTACT_TYPE: "Type de contact",
      CONTACT_PERSON_ON_SITE: "Contact local",
      CUSTOMER_HEADER_SUBTITLE: "Client",
      CUSTOMER_ID: "Numéro client",
      CUSTOMER_NAME: "Nom du client",
      CUSTOMER_DETAILS_HEADER: "Nom et adresse du client",
      CUSTOMER_NAME_LABEL: "Numéro ou nom client",
      CUSTOMER_NUMBER: "Numéro client",
      DELETE_CUSTOMER: "Supprimer le client",
      LUMINARY_INFO: "Est-ce que le contrat inclus les luminaires ?",
      EDIT_CUSTOMER_INFO: "Modifier les informations du client",
      CREATE_CUSTOMER_HEADER: "Modifier les informations du client",
      EDIT_PLATFORM_INFO: "Modifier les informations sur la plateforme",
      EDIT_SLA_DETAILS: "Modifier les détails du contrat de maintenance",
      EXPIRES: "Expire",
      IMAGES: "Si vous avez uniquement besoin de trouver de nouveaux luminaires ou remplacés, veuillez utiliser l'option 'Rechercher les nouveaux luminaires'.",
      INCLUDE_LUM_MONITORING: "inclure la supervision des luminaires",
      SLA_DETAILS_TEXT: "Luminaires inclus",
      CALENDAR_HELPER_TEXT: "jj/mm/aaaa",
      MOBILE_NUMBER: "Téléphone mobile",
      LUMINARY_MONITORING_NO: "Non",
      NO_CUSTOMER_FOUND: "Aucun client trouvé",
      NO_SERVICE_MANAGER_FOUND: "Aucun service manager trouvé",
      NO_TECHNICIAN_FOUND: "Aucun technicien trouvé",
      CUSTOMER_NUMBER_HELPER_TEXT: "Chiffres uniquement",
      SLA_NUMBER_HELPER_TEXT: "Chiffres uniquement",
      OFFICE_NUMBER: "Téléphone du bureau",
      IMAGE_REQUIREMENTS: "PNG, JPG (100x100px), 2 MB Max.",
      PHONE_NUMBERS_HEADER: "Téléphone primaire et secondaire",
      PRIMARY_CONTACT_INFO: "Informations du principal contact",
      PROJECT_LEAD: "Chef de projet",
      REMOVE_IMAGE: "Effacer l'image",
      SECONDARY_CONTACT_INFO: "Informations du second contact",
      FILE_RESOLUTION_ERROR: "Le fichier sélectionné ne correspond pas à la résolution acceptée",
      FILE_SIZE_ERROR: "Selected file size exceeds maximum allowed file size",
      SERVICE_MANAGER: "Service manager",
      SLA_CUSTOMER_NUMBER: "Numéro du contrat",
      SLA_DETAILS_HEADER: "Détail du contrat",
      SLA_EXPIRATION_DATE: "Expiration du contrat",
      TECH_LEAD_HEADER: "Responsable technique",
      TECHNICAL_PERSON: "responsable technique",
      UPLOAD_AVATAR_LOGO: "Charger le logo",
      LUMINARY_MONITORING_YES: "Oui",
    },
    USER_MANAGEMENT: {
      ALL_USERS: "Tous les utilisateurs",
      INVITE_USER: "Inviter un utilisateur",
      YOUR_DETAILS: "Vos informations",
    },
    USERDETAILS: {
      DELETE_USER_SUBHEADER: "Toutes vos données utilisateur seront définitivement perdues",
      RESET_PASSWORD_ERROR: "Impossible de réinitialiser votre mot de passe pour le moment.",
      DELETE_USER_BUTTON_TEXT: "Supprimer définitivement le compte",
      DELETE_USER_FAILURE_MSG: "Echec de la suppression, veuillez réessayer.",
      DELETE_USER_POP_UP_HEADER: "Supprimer le compte définitivement “{{email}}”?",
      RESET_PASSWORD_SUCCESS: "E-mail de réinitialisation du compte envoyé avec succès ",
      EDIT_SUCCESS: "Utilisateur mis à jour avec succès",
      DELETE_USER_SUCCESS_MSG: "Votre compte a été supprimé avec succés",
    },
    ATPLUS_LOGBOOK_EVENTNAME: {
      MAXIMUM_LOGIN_ATTEMPTS_EXCEDDED: "Nombre de tentatives autorisées dépassé",
      BATTERY_CHARGE_FAILURE: "Défaut de charge batterie détécté",
      BATTERY_DURATION_FAILURE: "Défaut test d'autonomie détecté",
      TLAT_PLUS_BATTERY_FAILURE: "Défaut batterie",
      TLAT_PLUS_BATTERY_LOW: "Batterie faible",
      COMMUNICATION_FAILURE: "Défaut de communication détecté",
      DRY_CONTACT_INPUT: "Entée contact sec activée",
      DRY_CONTACT_OUTPUT: "Sortie contact sec activée",
      MANUAL_DURATION_TEST: "Début du test d'autonomie",
      MANUAL_DURATION_TEST_CANCELED: "Test d'autonomie stoppé",
      FACTORY_RESET: "Réinitialisation d'usine",
      FAILURE_THRESHOLD_EXCEEDED: "Défauts consécutifs au-delà du seuil",
      FIRMWARE_UPDATE_FAILURE: "La mise à jour a échoué",
      FIRMWARE_UPDATE_SUCCESS: "Mise à jour du logiciel réussie",
      MANUAL_FUNCTION_TEST: "Test fonctionnel en cours",
      MODBUS_COMMUNICATION_FAILURE: "Echec de la mise en service de la gateway",
      UART_COMMUNICATION_FAILURE: "Défaut interne",
      LAMP_FAILURE: "Défaut luminaire détecté",
      LOCAL_MAIN_FAILURE: "Perte du secteur",
      PASSWORD_CHANGE: "Changement de mot de passe",
      PASSWORD_RESET: "Réinitialisation du mot de passe",
      MANUAL_RELIGHT_COMMAND: "Allumage activé",
      MANUAL_REST_COMMAND: "Repos activé",
      SEARCH_ALL_LAMP_COMPLETED: "Recherche de tous les luminaires terminée",
      SEARCH_NEW_LAMP_COMPLETED: "Rechercher les nouveaux luminaires",
      MANUAL_SYNCHRONIZE_TEST_TIME_COMMAND: "Synchroniser  l'heure des tests",
      ACTIVATION: "Activation du systéme réussie",
      SYSTEM_POWER_DOWN: "Perte secteur du systéme",
      SYSTEM_POWER_UP: "Retour secteur du système",
      DATALINE_FAILURE: "défaut de la ligne de communication TLU AT+",
      MANUAL_START_VISIBILITY_PLUS: "Visibilté + en cours",
      MANUAL_STOP_VISIBILITY_PLUS: "Visibilté + stoppée",
    },
    MY_ORGANIZATION: {
      ALPHABET: "Alphabets",
      CITY: "Ville",
      NUMBER_SYSTEM: "Nombre de Systèmes",
    },
    FIRMWARE_UPDATE: {
      FIRMWARE_UPDATE_ALERT_TITILE: "Êtes-vous sûr de vouloir quitter ?",
      CURRENT_VERSION: "Version actuelle",
      FIRMWARE_ALREADY_UPDATED: "Logiciel téléchargé",
      FIRMWARE_UPDATE_FAILED_TITLE: "La mise à jour a échoué",
      FIRMWARE_PROGRESS_MSG: "Mise à jour du logiciel en cours…",
      FIRMWARE_UPDATE_ALERT_SUB_TITILE: "La mise à jour du logiciel est en cours. Voulez vous aller à la page d'accueil",
      FIRMWARE_UPDATE_FAILED: "La mise à jour a échoué. Merci de recommencer",
      FIRMWARE_VERSION_UPDATED: "La mise à jour du firmware a réussi",
      FIRMWARE_ALREADY_UPDATED_TEXT: "Logiciel à jour",
      FIRMWARE_NOT_AVAILABLE: "La version du logiciel n'a pas été récupérée correctement. Veuillez vous déconnecter de l'application mobile et vous reconnecter pour réessayer.",
      LATEST_VERSION: "Version la plus récente",
      LOST_BLUETOOTH_CONNECTION: "Perte de la connexion Bluetooth",
      ENTRY_PASSWORD: "Merci de saisir le mot de passe du système pour ",
      RELEASED_ON: "Sortie le",
      SELECT_FIRMWARE_VERSION: "Sélectionner une version du logiciel",
      SELECT_A_VERSION: "Sélectionner une version",
      UPDATE_TO_LATEST: "Mettre à jour pour la version la plus récente",
      UPLOAD_FILE: "Télécharger le fichier",
      FIRMWARE_UPDATE_TITILE: "Nous recommandons une mise à jour du système",
      FIRMWARE_UPDATEPOPUP_MSG: "Vous n’êtes plus connecté à ",
    },
    ASSETS: {
      ASSETS_DETAILS: "Détails des Systèmes",
    },
    SYSTEM_TYPE: {
      ATPLUS: "AT+",
      CGLINE: "CGLine+",
      DGS: "DualGuard-S",
      LPSMART: "DualSmart-S",
      LPSTAR: "LPStar",
      WIRELESS: "Wireless",
      ZBS: "ZB-S",
    },
    DEVICE_TYPE_VALUE: {
      AT: "AT+",
      ATS: "AT-S+",
      CGLINE: "CGline",
      DGS: "DualGuard-S",
      LPSMART: "DualSmart-S",
      LPSTAR: "LP-STAR",
      WIRELESS: "Wireless",
      ZBS: "ZB-S",
    },
    CONTROLLER_TYPE_VALUE: {
      AT: "AT+",
      CGLINE_PLUS: "CGLine+",
      DGS: "DualGuard-S",
      LP_SMART: "DUALSMART-S",
      LP_STAR: "LP-STAR",
      WIRELESS: "Wireless",
      ZBS: "ZB-S",
    },
    ACTIONS: {
      BACK: "Retour",
      CANCEL: "Annuler",
      CHANGE_LANGUAGE: "Modifier la langue",
      CLICK_BUTTON: "Cliquer sur le bouton",
      UPDATE_REDUX: "Click the button to update the redux store value!",
      CONFIRM: "Confirmer",
      CONTINUE: "continuer",
      DELETE: "Supprimer",
      RESEND_VERIFICATION_EMAIL_LABEL: "E-mail non reçu ?",
      DISABLE: "Désactiver",
      DONE: "Terminée",
      DOWNLOAD_DATA: "Download Data",
      EDIT: "Editer",
      ENABLE: "Activer",
      EXTEND: "Etendre",
      FINISH: "Terminer",
      GO_HOME: "Go to Home",
      GO_TO_DASHBOARD: "Aller à la page d'accueil",
      GO_TEST: "Go to Test Page",
      LOG_IN: "connexion",
      LOG_OUT: "Déconnexion",
      NEXT: "Suivant",
      OKAY: "Ok",
      QUIT: "Quitter",
      REMEMBER: "Se souvenir de moi",
      RETRY: "Recommencer",
      SAVE: "Enregistrer",
      SAVE_ADD_ANOTHER: "Enregistrer puis ajouter un autre",
      SEND: "Envoyer",
      RESEND: "Renvoyer",
      RESEND_VERIFICATION_EMAIL: "Renvoyer",
      SUBMIT: "Envoyer",
      SUBMITCHANGES: "Submit Changes",
      TRANSFER: "Transférer",
      UPDATE: "Mettre à jour",
    },
    NETWORK: {
      BAD_REQUEST_MESSAGE: "Mauvaise demande",
      INTERNAL_SERVER_ERROR_MESSAGE: "Erreur interne du serveur",
      NO_DATA_FOUND: "Donnée introuvable",
      REQUEST_NOT_FOUND_MESSAGE: "Introuvable",
      ERROR_INTERNET_CONNECTION: "Il semble que vous n'ayez plus de connexion Internet et veuillez réessayer.",
      SERVICE_UNAVAILABLE: "Service introuvable",
      ERROR_NO_RESPONSE: "Une erreur s'est produite",
      ERROR_MESSAGE_SENDING_REQUEST: "Désolé, votre demande n'a pas abouti.",
      ERROR_INVALID_REQUEST: "Désolé, votre demande n'est pas valide.",
      NETWORK_ALERT_SUBTITILE: "page d'accueil indisponible car vous n'êtes plus connecté à Internet",
      ERROR_NOT_FOUND: "La ressource est introuvable.",
      ERROR_ALERT_TITLTE: "connexion à la page d'accueil impossible",
      UNAUTHORISED_REQUEST_MESSAGE: "Non autorisé : l'autorisation a été refusée pour cette demande",
      ERROR_RESTRICTED_USER_ACCESS: "L'utilisateur n'a pas accès à cette ressource.",
    },
    CONNECTION_TYPE_VALUE: {
      BLE: "BLE",
      BLUETOOTH: "Bluetooth",
      CELLULAR: "Cellulaire",
      ETHERNET: "Ethernet",
      MODBUS: "Modbus",
      MQTT: "MQTT",
      RS485: "RS486",
      WIFI: "Wi-Fi",
      WIREPAS: "Wirepas",
    },
    STATUS: {
      STATUS_BLOCK: "Bloqué",
      BLOCKED: "Bloqué",
      STATUS_EMPTY: "Vide",
      STATUS_ERROR: "Erreur",
      ERROR: "Erreur",
      EVENT: "Evénement",
      DT: "in DT",
      STATUS_DT: "Test en cours",
      INTEST: "Test en cours",
      STATUS_OFFLINE: "Hors ligne",
      STATUS_READY: "Prêt",
      READY: "Prêt",
      TOTAL_LUMINAIRES: "Nombre de luminaires",
      TOTAL_SYSTEM: "Nombre de systèmes",
      STATUS_WARNING: "Attention",
      WARNING: "Attention",
    },
    UNSUPPORTED_BROWSER: {
      NOT_SUPPORTED: "Navigateur non pris en charge",
      INCOMPATIBLE_BROWSER: "Cette application Web n'est pas compatible avec votre navigateur actuel.",
      RECOMMENDED_BROWSER: "Nous vous recommandons de mettre à jour votre navigateur internet pour utiliser cet outil ",
    },
    ENTITY_TYPE: {
      BUILDING: "Bâtiment",
      DEVICE: "Elément",
      DEVICE_TYPE: "Type d'élément",
      GATEWAY: "Gateway",
      LUMINARY: "Luminaire",
      MASTER_ROLE: "Master Role",
      ORGANIZATION: "Organisation",
      ORGANIZATION_STATISTIC_DETAIL: "Organization Statistical Detail",
      PLATFORM: "Plateforme",
      SYSTEM: "Système",
      USER: "Utilisateur",
    },
    LOGSOURSE_DEVICE_TYPE: {
      BUILDING: "Bâtiment",
      DEVICE: "Elément",
      DEVICETYPE: "Type d'équipement",
      GATEWAY: "Gateway",
      LUMINARY: "Luminaire",
      MASTERROLE: "MasterRole",
      ORGANIZATION: "Organisation",
      ORGANIZATIONSTATISTICALDETAIL: "OrganizationStatisticalDetail",
      SYSTEM: "Système",
      USER: "Utilisateur",
    },
    TECHNICIAN_DETAILS: {
      BUILD_ADMIN: "Administrateur du batiment",
      BUILD_VIEWER: "Utilisateur du bâtiment",
      CUST_VIEWER: "Utilisateur client",
      INDIVIDUAL_DETAILS: "Détails individuel",
      LIMT_ACCESS: "Accés restreint",
      ORG_ADMIN: "Administrateur de l'organisation",
      ORG_VIEWER: "Utilisateur de l'organisation",
      PASS_SERVICE_MANAGER: "Pass Service Manager",
      PROJ_OWNER: "Responsable projet",
      REMOVE_USER: "Supprimer l'utilisateur",
      SERVICE_MANAGER: "Service manager",
      SYSTM_ADMIN: "Adminitrateur système",
      SYSTM_VIEWER: "Utilisateur système",
      TECHNICIAN: "Technicien",
      USER_DETAILS: "Détails de l'utilisateur",
    },
    TRANSFER_BUILDING_TO_ORGANIZATION: {
      TRANSFER_SUCCESS: "Bâtiment transféré avec succèsvers une autre organisation",
      CONFIRM_PASSWORD: "Confirmer nouveau mot de passe",
      INSTRUCTIONS: "Transfert définitif des {{buildingCount}} bâtiments incluant les {{systemCount}} systèmes et {{gatewayCount}} gateways.",
      ORG_SELECT: "Veuillez selectionner une organisation valide",
      INSTRUCTION_TWO: "Veuillez saisir votre mot de passe administrateur de l'organisation pour confirmer l'action.",
      TRANSFER: "Transférer",
      HEADER_TITLE: "Transférer les bâtiments dans une autre organisation ",
      DROPDOWN_LABEL: "Transférer vers...",
    },
    CHANGE_PASSWORD_SCREEN: {
      CHANGE_PASSWORD: "Changement du mot de passe",
      CONFIRM_PASSWORD: "Confirmer nouveau mot de passe",
      NEW_PASSWORD: "Nouveau mot de passe",
    },
    MESSAGES: {
      CONGRATS: "Félicitations!",
      CONTACT: "Contacter le support Eaton",
      EMAIL_SENT: "E-mail envoyé",
      ERROR: "Erreur!",
      FAILURE: "Défaut",
      LOADING: "Chargement …",
      EMAIL_ENTRY_ERROR: "Veuillez entrer un e-mail valide",
      SUCCESS: "Succès",
      WELCOME: "Bienvenue !",
      LOGIN_MESSAGE: "Vous êtes connecté",
    },
    LANDING_SCREEN: {
      CONNECT_NOW: "Connecter maintenant",
      CONNECT_TO_SYSTEM: "Connecter aux Systèmes",
      CONTINUE_TO_DASHBOARD: "Continuer vers la page d'accueil",
      DASHBOARD_UNAVAILABLE: "Page d'accueil indisponible",
      NO_INTERNET_CONNECTION: "Pas de connexion Internet",
      VIEW_DASHBOARD: "Afficher le tableau de bord",
      IN_PROGRESS: "En cours…",
    },
    USERS: {
      CREATE_NEW_CUSTOMER: "Créer un nouveau client",
      EDIT_PLATFORM_HEADER: "Modifier la plateforme",
      EDIT_PLATFORM_SUCCESS_200: "Plateforme mise à jour avec succés",
      LOADER_HELPER_TEXT: "Veuillez attendre",
      MANAGE_PLATFORM_ERROR_CODE_3047: "Le gestionnaire de service principal n'est pas enregistré sur BSMS. Impossible de créer une plateforme",
    },
    LABELS: {
      CSV: "CSV",
      EMAIL: "Adresse e-mail",
      EXCEL: "Excel File",
      FORGOT_PASSWORD: "Mot de passe oublié ?",
      GROUP_BY: "Grouper par :",
      ITEMS_PER_PAGE: "Entrées par page:",
      LOGGING_IN: "Se connecter à",
      NEED_ACCOUNT: "besoin d'un compte ?",
      NEW_PASSWORD: "Nouveau mot de passe",
      PASSWORD: "Mot de passe",
      PDF: "PDF",
      SHOW: "Voir:",
      SIGN_UP: "S'enregistrer",
      TIME_RANGE: "Période :",
      TO_BE_IMPLEMENTED: "To be implemented",
      VIEW_ALL_EVENTS: "Voir tous les {{count}} événements",
      VIEW_BUILDING_DETAILS: "nan",
    },
    AUTO_LOGOUT_DISABLED: {
      AUTO_LOGOUT_DISABLED: "Désactiver",
    },
    TIMELINE_EXPORT: {
      DOWNLOAD_TITLE: "Télécharger le calendrier des données",
      DOWNLOAD_SUBTITLE_SITE: "Sélectionner la période de l'historique des alarmes que vous souhaitez exporter.",
      DOWNLOAD_SUBTITLE_TIMELINE: "Sélectionner l'emplacement et la période de l'historique des alarmes que vous souhaitez exporter.",
    },
    AUTO_LOGOUT_ENABLED: {
      AUTO_LOGOUT_ENABLED: "Activée (recommandé)",
    },
    LEAGAL: {
      END_USER_AGREEMENT: "Accord de l'utilisateur final",
      LICENCE_AGREEMENT: "Contrat de licence de l'utilisateur final",
      LEAGAL: "Légal",
      MANUALS: "Manuels",
      OPEN_SOURCE: "Open Source Licences",
      INSTRUCTION_EATON_BSMS_GATEWAY: "Manuel pour Eaton BSMS Gateway",
      QUICK_START_GUIDE: "Guide de démarrage rapide",
      REMOTE_MONITORING_GUIDE: "Guide de la suppervision",
      TERMS_CONDITIONS: "Termes et Conditions",
    },
    LEGAL: {
      EULA: "Contrat de licence de l'utilisateur final",
      TITLE: "Légal",
      OPENSOURCELICENSES: "Open Source Licences",
      TERMSANDCONDITIONS: "Termes et Conditions",
    },
    EVENT_TIMELINE: {
      ERROR_LOADING_MORE_EVENTS: "Echec de téléchargement d'événements supplémentaires",
      LOAD_MORE_EVENTS: "Télécharger {{count}} d'événements supplémentaires",
    },
    ASSETS_SCREEN: {
      FAVORITE_LIMIT_REACHED: "Limite de favoris atteinte",
      NOBUILDINGFOUND: "Aucun bâtiment trouvé",
      NOGATEWAYFOUND: "Aucune gateway trouvée",
      NOORGANIZATIONFOUND: "Aucune organisation trouvée",
      NOREPORTFOUND: "Aucun rapport trouvé",
      NOSYSTEMS: "Aucun système trouvé",
      OTHERS: "Autres",
      SHOW_ONLY_STARRED_BUILDING: "Afficher uniquement les bâtiments favoris",
      STARRED: "Favoris",
      MARK_AS_FAV_API_SUCCESS: "Ajouté aux favoris avec succés",
      REMOVE_MARK_AS_FAV_API_SUCCESS: "Retiré des favoris avec succés",
      MARK_AS_FAV_API_FAILURE_MESSAGE: "Veuillez supprimer certains de vos favoris existants avant d'en ajouter de nouveaux, car la limite maximale est de 10.",
    },
    ADDLOCATION: {
      ASTERIKSINFO: "Les champs marqués (*) sont des champs obligatoires {{entity}}.",
      ADDLOCATIONINFORMATION: "Information sur l'emplacement",
    },
    HEADER: {
      FORGOT_PASSWORD: "Mot de passe oublié",
    },
    LOGIN: {
      INVALID_CREDENTIALS: "Le nom d'utilisateur ou le mot de passe est incorrect",
      GENERIC_ERROR: "Votre demande ne peut être traitée pour le moment.",
    },
    TABS: {
      LIST_VIEW: "Liste",
      SUMMARY_VIEW: "Vue récapitulative",
    },
    NAVIGATION_TABS: {
      LOGBOOK: "Journal",
      STATUS: "Etats",
    },
    HELPANDFEEDBACK: {
      MANUALS: "Manuels",
      TITLE: "Manuel pour Eaton BSMS Gateway",
    },
    OPERATING_SYSTEM_THEME: {
      OPERATING_SYSTEM_THEME: "Thème de l'interface graphique",
    },
    EULA: {
      OTHER_LANGUAGE: "Autres langues",
    },
    LOCATIONS: {
      SITES: "Sites",
    },
    COUNTER: "La valeur est : {{count}}",
    RESPONSE: {
      USER_REGISTERED: "L'utilisateur peut être enregistré",
    },
    PASSWORD_RESET: {
      SUCCESS_MESSAGE: "Mot de passe modifié avec succès",
      FAILURE_MESSAGE: "Réinitialisation du mot de passe impossible. Veuillez réessayer ultérieurement",
    },
    ATPLUS_DETAIL_SCREEN: {
      ATPLUS_DEVICE_UNREGISTERED: "Cet appareil n'est pas enregistré",
      ATPLUS_DEVICE_NEEDCOMMISSIONIG: "Cet appareil n'est pas enregistré sur le cloud et doit être remis en service",
    },
  },
};
export default resources;
