// eslint-disable-next-line
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Card, Button, ListSubheader, TextField, Divider, Dialog, DialogTitle, DialogContent, CircularProgress, MobileStepper, IconButton } from '@mui/material';
import * as PXBColors from '@brightlayer-ui/colors';
import { Autocomplete, Snackbar, Typography } from '@mui/material';
import { COLOR_GRAY } from '../../constants/color-codes';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import Box from '@mui/material/Box';
import { InputBase, MenuItem, Select } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Checkbox } from '@mui/material';
import { useOrganizationList } from '../../data/DataProviders/OrganizationListProvider';
import { ItemList } from '../../types/logbook-type';
import { CheckBox, CheckBoxOutlineBlank, Close, HelpOutline } from '@mui/icons-material';
import { EmptyState } from '@brightlayer-ui/react-components';
import { useDispatch, useSelector } from 'react-redux';
import { Thunks } from '../../actions';
import { Selectors } from '../../selectors';
import { EntityType, UserInviteEntityModel, UserInviteRequest } from '../../types';
import { RESPONSE_STATUS_COLOR } from '../../components/ResponseHandling/ResponseHandlingAlert';
import { ENTITY_TYPE, userBaseRoles, USER_BASE_ROLE_TYPE, USER_ROLE_TYPE, getEntityType } from '../enum/ENUM';
import { UsersActions } from '../../actions/actions/users-actions';
import { validEmail } from '../../lib/form-validator';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DEFAULT_STATE, ORG_EMAIL_MAX_LENGTH } from '../../constants';
import { EntityAccessDetailEntity, RenderTree, UserAdvanceAccessDetail } from '../../types/manage-users';
import SendIcon from '@mui/icons-material/Send';
import { InviteUserAdvanceDialogSecondPage } from './InviteUserAdvanceDialogSecondPage';
import { getUserRoleType, useAuthState } from '../../components/AuthContextProvider';
import { OrganizationThunks } from '../../actions/thunks/organization-thunks';
import { OrganizationList } from '../../types/organization';
import { NodeDetails } from '../../components/CustomTree/CustomTreeContent';


type Props = {
    entityId: string;
    entityType: number;
    entityName: string;
    OnItemSelected?: Function;
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        borderBottom: '0px solid #ced4da',
        fontSize: 16,
        color: COLOR_GRAY,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 4,
        },
    },
}));

export const getEntityRole = (type: number, entityBaseRole: number): number => {
    if (entityBaseRole === null) {
        return entityBaseRole = parseInt(DEFAULT_STATE); // User unselect access rights
    }
    else if (type === ENTITY_TYPE.ORGANIZATION)
        return entityBaseRole === USER_BASE_ROLE_TYPE.ADMIN ? USER_ROLE_TYPE.EL_ORG_ADMIN : USER_ROLE_TYPE.EL_ORG_VIEWER;
    else if (type === ENTITY_TYPE.BUILDING)
        return entityBaseRole === USER_BASE_ROLE_TYPE.ADMIN ? USER_ROLE_TYPE.EF_BLDG_ADMIN_ORG_VIEWER : USER_ROLE_TYPE.EL_BLDG_VIEWER;
    else if (type === ENTITY_TYPE.SYSTEM)
        return entityBaseRole === USER_BASE_ROLE_TYPE.ADMIN ? USER_ROLE_TYPE.EF_SYSTEM_ADMIN_GATEWAY_VIEWER : USER_ROLE_TYPE.EF_SYSTEM_VIEWER;
    else
        return entityBaseRole === USER_BASE_ROLE_TYPE.ADMIN ? USER_ROLE_TYPE.EL_ORG_ADMIN : USER_ROLE_TYPE.EL_ORG_VIEWER;
};

export const InviteUser: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const dispatch = useDispatch();
    const organization = useSelector(Selectors.Organization.organization);
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const usersSelector = useSelector(Selectors.Users.users);
    const [email, setEmail] = useState<string>('');
    const [emailAdvance, setEmailAdvance] = useState<string>('');
    const [isInviteAsColleague, setInviteAsColleague] = useState(false);
    const useOrganizationDataList = useOrganizationList();
    const [organizationListData, setOrganizationListData] = React.useState<ItemList>([]);
    const [organizationCheckList, setOrganizationCheckList] = React.useState<ItemList>([{ id: props.entityId, name: props.entityName }]);
    const [openAdvanceDialog, setOpenAdvanceDialog] = React.useState(false);
    const orgSelectAllId = '-1';
    const inviteAdvanceUserFirstPage = 0;
    const inviteAdvanceUserSecondPage = 1;
    const [alertSuccessMessage, setAlertSuccessMessage] = useState<string>('');
    const [alertErrorMessage, setAlertErrorMessage] = useState<string>('');
    const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false);
    const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
    const [canSendInvite, setCanSentInvite] = useState(false);
    const [userAccessDetailData, setUserAccessDetailData] = React.useState<UserAdvanceAccessDetail>();

    const [renderTreeData, setRenderTreeData] = React.useState<RenderTree[]>([]);
    const [userInviteEntityModel, setUserInviteEntityModel] = React.useState<UserInviteEntityModel>();
    const [userInviteEntityModelArray, setUserInviteEntityModelArray] = React.useState<UserInviteEntityModel[]>([]);
    //Advance Invite array
    const [userInviteEntityModelRequestArray, setUserInviteEntityModelRequestArray] = React.useState<UserInviteEntityModel[]>([]);

    const [organizationList, setOrganizationList] = useState<OrganizationList>([]);
    const [firstCall, setFirstCall] = React.useState(true);

    const [userRoleSelection, setUserRoleSelection] = React.useState(userBaseRoles[0]);

    // Stepper state
    const [nextEnabled, setNextEnabled] = useState(true);
    const [isUserAdvanceInviteCallStatus, setUserAdvanceInviteCallStatus] = useState(true);
    const [nextLabel, setNextLabel] = useState<string>(t('ACTIONS.NEXT'));
    const backLabel = t('ACTIONS.BACK');
    const [activeStep, setActiveStep] = React.useState(0);
    const authState = useAuthState();
    const usertype = getUserRoleType(authState) || '';

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if (activeStep === inviteAdvanceUserSecondPage)
            onInviteUserClick();
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setUserInviteEntityModelRequestArray([]);
        setUserInviteEntityModelArray([]);
        setNextEnabled(true);
    };

    const selectionUserRoleHandleChange = (event: { target: { value: string } }) => {
        const selectedUserRole = userBaseRoles.filter((role) => role.description === event.target.value)[0];
        setUserRoleSelection(selectedUserRole);
    };

    const getOrganizationWithAdminAccess = (list: OrganizationList): ItemList => {
        list = list.filter((x) => x.isAdmin === true);
        return list.map((data, organization) => {
            return (
                {
                    id: data.projectId,
                    name: data.name,
                }
            );
        })
    };

    useEffect(() => {
        if (firstCall)
            dispatch(OrganizationThunks.getOrganizationDetailsList(''));
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!organization.orgListLoading && organization.orgListSuccess) {
            setOrganizationList(organization.orgList.detail);
            const orgListWithAdminAccess = getOrganizationWithAdminAccess(organization.orgList.detail);
            const orgList = [{ id: orgSelectAllId, name: t('MANAGE_USERS.INVITE_USER_ORGANIZATION_SELECT_ALL') }].concat(orgListWithAdminAccess);
            setOrganizationListData(orgList);

        } else if (!organization.orgListLoading && organization.orgListFail) {
            setOrganizationListData([]);
        }
        setFirstCall(false);
    }, [organization.orgListLoading, organization.orgListSuccess, organization.orgListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    //TODO- later getProjectlist value used to update orglist for invite(pending due to isAdmin flag not available)
    // useEffect(() => {
    //     const orgList = [{ id: orgSelectAllId, name: t('MANAGE_USERS.INVITE_USER_ORGANIZATION_SELECT_ALL') }].concat(useOrganizationDataList.organizationList);
    //     setOrganizationListData(orgList);
    // }, [useOrganizationDataList, useOrganizationDataList.organizationList]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!usersSelector.inviteUserLoading && usersSelector.inviteUserSuccess) {
            var successMessage = '';
            const userEmail = email.length > 0 ? email : emailAdvance;
            if (organizationCheckList.length === 1) {
                const organizationName = organizationCheckList.filter((organization) => organization.id === orgSelectAllId).length > 0 ? t('DASHBOARD.ALL_ORGANIZATION') : organizationCheckList[0].name;
                successMessage = t('MANAGE_USERS.INVITE_SUCCESS_MESSAGE_SINGLE', { replace: { email: userEmail, organization: organizationName } });
            } else {
                successMessage = t('MANAGE_USERS.INVITE_SUCCESS_MESSAGE_MULTIPLE', { replace: { email: userEmail, organizationCount: organizationCheckList.length } });
            }
            usersSelector.inviteUseData.successDetail.length === 0 ? handleInviteUserResponse(false, '', true, usersSelector.inviteUseData.failureDetail[0].errorCode.toString()) : handleInviteUserResponse(true, successMessage, false, '')
            dispatch(UsersActions.inviteUserUnmount());
        } else if (!usersSelector.inviteUserLoading && usersSelector.inviteUserFail) {
            handleInviteUserResponse(false, '', true, usersSelector.inviteUsererrorCode)
            dispatch(UsersActions.inviteUserUnmount());
        }
    }, [usersSelector.inviteUserLoading, usersSelector.inviteUserSuccess, usersSelector.inviteUserFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (validEmail(email))
            setCanSentInvite(true);
        else
            setCanSentInvite(false);
        if (openAdvanceDialog) {
            setOrganizationCheckList([]);
        }
        else {
            setOrganizationCheckList([{ id: props.entityId, name: props.entityName }])
        }
    }, [email, openAdvanceDialog]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (validEmail(emailAdvance) && organizationCheckList.length > 0) {
            setNextEnabled(true);
        }
        else {
            setNextEnabled(false);
        }
    }, [organizationCheckList, emailAdvance]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!usersSelector.removeAccessLoading && usersSelector.removeAccessSuccess) {
            setAlertSuccessMessage(t('TOAST_MSG.REMOVE_ACCESS_SUCCESS'));
            setDisplaySuccessMessage(true);
        } else if (!usersSelector.removeAccessLoading && usersSelector.removeAccessFail) {
            handleRemoveAccessResponse();
            setDisplayErrorMessage(true);
        }
        dispatch(UsersActions.removeAccessUnmounted());
    }, [usersSelector.removeAccessLoading, usersSelector.removeAccessSuccess, usersSelector.removeAccessFail]); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        setNextLabel(activeStep === inviteAdvanceUserFirstPage ? t('ACTIONS.NEXT') : t('ACTIONS.SEND'));
        if (activeStep === inviteAdvanceUserSecondPage) {
            const selectAll = organizationCheckList.filter((org) => org.id === orgSelectAllId);
            var selectedOrganizations: string[] = [];
            if (selectAll.length > 0) {
                const allOrganizations: string[] = organizationListData.map((organization) => organization.id);
                selectedOrganizations = allOrganizations.filter((organization) => organization !== orgSelectAllId);
            } else {
                selectedOrganizations = organizationCheckList.map((organization) => organization.id);
            }
            dispatch(Thunks.Users.getUserAdvanceAccessDetail(emailAdvance, selectedOrganizations));
            setNextEnabled(false);
        }

    }, [activeStep]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!usersSelector.getuserAdvanceAccessLoading && usersSelector.getuserAdvanceAccessSuccess) {
            setUserAccessDetailData(usersSelector.getuserAdvanceAccess);
            setUserAdvanceInviteCallStatus(true);
        }
        else if (usersSelector.getuserAdvanceAccessFail && !usersSelector.getuserAdvanceAccessLoading) {
            setUserAdvanceInviteCallStatus(false);
            dispatch(UsersActions.inviteUserUnmount());
        }
        else {
            setUserAdvanceInviteCallStatus(false);
            dispatch(UsersActions.inviteUserUnmount());
        }
    }, [usersSelector.getuserAdvanceAccessFail, usersSelector.getuserAdvanceAccessLoading, usersSelector.getuserAdvanceAccessSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (userInviteEntityModel) {
            //To remove the array with id avail
            if (userInviteEntityModelArray.length > 0 && (userInviteEntityModel.roleType === DEFAULT_STATE)) {
                setUserInviteEntityModelArray(userInviteEntityModelArray.filter(item => item.entityId != userInviteEntityModel.entityId));
                setNextEnabled(true);
            }
            //To update value already present in array
            else if (userInviteEntityModelArray.length > 0 && (userInviteEntityModelArray.filter((x) => x.entityId === userInviteEntityModel?.entityId)).length > 0) {
                userInviteEntityModelArray.filter((x) => x.entityId === userInviteEntityModel?.entityId)[0].roleType = userInviteEntityModel.roleType;
                setUserInviteEntityModelArray(userInviteEntityModelArray);
            }
            //To add when new entry
            else {
                setUserInviteEntityModelArray([...userInviteEntityModelArray, userInviteEntityModel]);
                setNextEnabled(true);
            }
        }
    }, [userInviteEntityModel]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleInviteUserErrorResponse = (errorMessage: string): void => {
        if (errorMessage !== '') {
            if (errorMessage === '9127')
                setAlertErrorMessage(t('TOAST_MSG.INVITE_USER_ERROR_CODE_9127'));
            else if (errorMessage === '9043')
                setAlertErrorMessage(t('TOAST_MSG.INVITE_USER_ERROR_CODE_9043'));
            else if (errorMessage === '9136')
                setAlertErrorMessage(t('TOAST_MSG.INVITE_USER_ERROR_CODE_9136'));
            else if (errorMessage === '9130')
                setAlertErrorMessage(t('TOAST_MSG.INVITE_USER_ERROR_CODE_9130'));
            else if (errorMessage === '9158')
                setAlertErrorMessage(t('TOAST_MSG.INVITE_USER_ERROR_CODE_9158'));
            else if (errorMessage === '9159')
                setAlertErrorMessage(t('TOAST_MSG.INVITE_USER_ERROR_CODE_9159'));
            else if (errorMessage === '9160')
                setAlertErrorMessage(t('TOAST_MSG.INVITE_USER_ERROR_CODE_9160'));
            else if (errorMessage === '9190')
                setAlertErrorMessage(t('TOAST_MSG.INVITE_USER_ERROR_CODE_9190'));
            else if (errorMessage === '9142')
                setAlertErrorMessage(t('TOAST_MSG.INVITE_USER_ERROR_CODE_9142'));
            else if (errorMessage === '9143')
                setAlertErrorMessage(t('TOAST_MSG.INVITE_USER_ERROR_CODE_9143'));
            else if (errorMessage === '9224')
                setAlertErrorMessage(t('TOAST_MSG.INVITE_USER_ERROR_CODE_9224'));
            else if (errorMessage === '9223')
                setAlertErrorMessage(t('TOAST_MSG.INVITE_USER_ERROR_CODE_9223'));
            else
                setAlertErrorMessage(t('REGISTRATION.STEPS_ERROR_MESSAGE.GENERIC_ERROR_MESSAGE'));
        }
    };

    const handleInviteUserResponse = (successState: boolean, successMessage: string, errorState: boolean, errorMessage: string) => {
        setOpenAdvanceDialog(false); // ToDo - confirm if we want to close advance dialog after response
        setAlertSuccessMessage(successMessage);
        setDisplaySuccessMessage(successState);
        handleInviteUserErrorResponse(errorMessage);
        setDisplayErrorMessage(errorState);
        setActiveStep(0);
    };

    const handleInviteAsColleague = (
        event: React.ChangeEvent<HTMLElement>,
        checked: boolean,
    ) => {
        setInviteAsColleague(checked);
    };

    function onHelpClick(): void {

    }

    const getAdvanceAccessData = (emailId: string, inviteAsColleague: boolean): UserInviteRequest => {
        const realData: RenderTree[] = JSON.parse(JSON.stringify(renderTreeData));
        const treeData: RenderTree[] = [...renderTreeData];

        let orgList: UserInviteEntityModel[] = [];
        let buildingList: UserInviteEntityModel[] = [];
        let systemList: UserInviteEntityModel[] = [];

        const extractedEntityList = userInviteEntityModelArray.reduce((entityType, item: UserInviteEntityModel, arrayIndex) => {
            if (item.entityType === ENTITY_TYPE.ORGANIZATION.toString()) {
                orgList.push(item);
            }
            else if (item.entityType === ENTITY_TYPE.BUILDING.toString()) {
                buildingList.push(item);
            }
            else if (item.entityType === ENTITY_TYPE.SYSTEM.toString()) {
                systemList.push(item);
            }
            return entityType;
        }, []);

        //create array according to Entity type- Org, Building, System
        const sortedDataList: UserInviteEntityModel[] = [];

        function updateSortedDataList(newData: UserInviteEntityModel, entityType?: string) {
            const index = sortedDataList.findIndex(item => item.entityId === newData.entityId);
            if (index === -1) {
                // If the item does not exist, add it to the list
                sortedDataList.push(newData);
            } else {
                // If the item exists, update it
                sortedDataList[index] = newData;
            }
        }

        renderTreeData.map((org: RenderTree) => {
            // //org start
            orgList.map((element: UserInviteEntityModel) => {


                const orgArray: string[] = [];
                //Org filter and set selection - Org Admin or Org Viewer + Building Admin + System Admin only
                if (org.id === element.entityId) {
                    if (element.roleType === USER_ROLE_TYPE.EL_ORG_ADMIN.toString()) {

                        sortedDataList.push(element);
                        orgArray.push(element.entityId);
                        const indexOfOrgToBeRemoved = treeData.indexOf(org);
                        const orgSelectedTreeData = treeData.splice(indexOfOrgToBeRemoved, 1);
                        const buildingArray: string[] = [];
                        buildingList.map((element: UserInviteEntityModel) => {
                            if (orgSelectedTreeData[0].children?.map((building) => {
                                building.id === element.entityId
                            })) {
                                buildingArray.push(element.entityId);
                            }
                        });
                        buildingList = buildingList.filter(item => !orgArray.includes(item.entityId));

                        const systemArray: string[] = [];
                        systemList.map((element: UserInviteEntityModel) => {
                            if (orgSelectedTreeData[0].children?.map((building) => {
                                building.children?.map((system) => {
                                    system.id === element.entityId
                                })
                            })) {
                                systemArray.push(element.entityId);
                            }

                        });
                        systemList = systemList.filter(item => !systemArray.includes(item.entityId));
                    }
                    else if (element.roleType === USER_ROLE_TYPE.EL_ORG_VIEWER.toString()) {

                        sortedDataList.push(element);

                        const indexSelectedOrg = orgList.indexOf(element);
                        orgList.splice(indexSelectedOrg, 1);

                        const indexOfOrgToBeRemoved = treeData.indexOf(org);
                        const orgSelectedTreeData = treeData.splice(indexOfOrgToBeRemoved, 1);


                        // Building for Org Viewer start - Building Admin or Building Viewer + System Admin only
                        const buildingArray: string[] = [];
                        buildingList.map((element: UserInviteEntityModel) => {
                            orgSelectedTreeData[0].children?.map((building) => {
                                if (building.id === element.entityId && building.role == USER_ROLE_TYPE.EF_BLDG_ADMIN_ORG_VIEWER.toString()) {
                                    updateSortedDataList(element);
                                    buildingArray.push(element.entityId);

                                    const systemSelectedInBuildingArray: string[] = [];
                                    building.children?.map((system) => {
                                        if (system.parentId === element.entityId) {
                                            systemSelectedInBuildingArray.push(system.id);
                                        }
                                    });
                                    systemList = systemList.filter(item => !systemSelectedInBuildingArray.includes(item.entityId));
                                }
                                else if (building.id === element.entityId && building.role == USER_ROLE_TYPE.EL_BLDG_VIEWER.toString()) {
                                    //Remove building by pushing to array as same role of parent
                                    buildingArray.push(element.entityId);
                                    //Check for child system access
                                    const systemSelectedInBuildingArray: string[] = [];

                                    systemList.map((element: UserInviteEntityModel) => {
                                        building.children?.map((itemSystem) => {
                                            if (itemSystem.id === element.entityId && itemSystem.parentId === element.buildingId && itemSystem.role === USER_ROLE_TYPE.EF_SYSTEM_ADMIN_GATEWAY_VIEWER.toString()) {
                                                element.roleType = itemSystem.role;
                                                updateSortedDataList(element);
                                                systemSelectedInBuildingArray.push(element.entityId);
                                            }
                                            else if (itemSystem.id === element.entityId && itemSystem.parentId === element.buildingId && itemSystem.role === USER_ROLE_TYPE.EF_SYSTEM_VIEWER.toString()) {
                                                systemSelectedInBuildingArray.push(element.entityId);
                                            }
                                        });
                                    });
                                    systemList = systemList.filter(item => !systemSelectedInBuildingArray.includes(item.entityId));
                                }
                            });
                        });
                        buildingList = buildingList.filter(item => !buildingArray.includes(item.entityId));
                        // Building for Org Viewer end
                    }
                }
            });
            // //org end


            // Building start - Building Admin or Building Viewer + System Admin only
            const buildingArray: string[] = [];
            buildingList.map((element: UserInviteEntityModel) => {
                if (org.children?.map((building: RenderTree) => {
                    (building.id === element.entityId) && (building.role == USER_ROLE_TYPE.EF_BLDG_ADMIN_ORG_VIEWER.toString())
                })) {
                    updateSortedDataList(element);
                    buildingArray.push(element.entityId);
                    const systemSelectedInBuildingArray: string[] = [];
                    org.children?.map((building) => {  //Check for child building access
                        if (building.id === element.buildingId) {
                            building.children?.map((system) => {
                                if (system.parentId === element.entityId) {
                                    systemSelectedInBuildingArray.push(system.id);
                                }
                            });
                        }
                    });
                    systemList = systemList.filter(item => !systemSelectedInBuildingArray.includes(item.entityId));
                }
            });
            realData.map((rData: RenderTree) => {
                buildingList.map((element: UserInviteEntityModel) => {
                    if (org.children?.map((building: RenderTree) => {
                        (building.id === element.entityId) && (building.role == USER_ROLE_TYPE.EL_BLDG_VIEWER.toString())
                    })) {
                        updateSortedDataList(element);
                        buildingArray.push(element.entityId);
                        //Check for child system access

                        const buildingSelectedTreeData = rData.children?.find((building) => building.id === element.entityId);
                        const systemSelectedInBuildingArray: string[] = [];

                        systemList.map((element) => {
                            buildingSelectedTreeData?.children?.map((itemSystem) => {
                                if ((itemSystem.parentId === element.buildingId) && (itemSystem.id === element.entityId) && (itemSystem.role === USER_ROLE_TYPE.EF_SYSTEM_ADMIN_GATEWAY_VIEWER.toString())) {
                                    element.roleType = itemSystem.role;
                                    updateSortedDataList(element);
                                    systemSelectedInBuildingArray.push(element.entityId);
                                }
                                else if ((itemSystem.parentId === element.buildingId) && (itemSystem.role === USER_ROLE_TYPE.EF_SYSTEM_VIEWER.toString() || itemSystem.role === USER_ROLE_TYPE.EL_BLDG_VIEWER.toString())) {
                                    systemSelectedInBuildingArray.push(element.entityId);
                                }
                            });
                        });
                        systemList = systemList.filter(item => !systemSelectedInBuildingArray.includes(item.entityId));
                    }
                });

            });
            buildingList = buildingList.filter(item => !buildingArray.includes(item.entityId));
            // Building end


            // Start -  System filter and set selection - System Admin or System Viewer only
            const systemArray: string[] = [];
            systemList.map((itemSystem: UserInviteEntityModel) => {
                if (org.children?.map((building: RenderTree) => {
                    building.children?.map((system) => {
                        system.id === itemSystem.entityId
                    })
                })) {
                    systemArray.push(itemSystem.entityId);
                    sortedDataList.push(itemSystem);
                }
            });
            systemList = systemList.filter(item => !systemArray.includes(item.entityId));
            // end
        });
        setUserInviteEntityModelRequestArray(sortedDataList);
        setRenderTreeData(treeData);

        return {
            emailId: emailAdvance,
            inviteAsColleague: isInviteAsColleague,
            inviteEntities: sortedDataList
        }

    }

    function onInviteUserClick(): void {
        var request = undefined;
        if (openAdvanceDialog) {
            request = getAdvanceAccessData(emailAdvance, isInviteAsColleague);
        } else {
            request = getUserInviteRequest(email, isInviteAsColleague, [props.entityId], props.entityType, getEntityRole(props.entityType, userRoleSelection.key));
        }

        if (request !== undefined) {
            setDisplaySuccessMessage(false);
            dispatch(Thunks.Users.inviteUser(request.emailId, request.inviteAsColleague, request.inviteEntities));
        }
    }

    function getUserInviteRequest(email: string, inviteAsColleague: boolean, orgIdList: string[], entityType: number, roleType: number): UserInviteRequest {
        const entityList: UserInviteEntityModel[] = orgIdList.map((org) => {
            return {
                entityId: org,
                entityType: '' + entityType,
                grantAccessToBuilding: false,
                roleType: '' + roleType,
            }
        });

        return {
            emailId: email,
            inviteAsColleague: inviteAsColleague,
            inviteEntities: entityList
        }
    };

    function onAdvanceOptionsClick(): void {
        //ToDo - Handle Advance Invite User Settings
        setOpenAdvanceDialog(true);
        resetUserSelections();
    };

    const onCancelAdvanceInvite = (): void => {
        setActiveStep(inviteAdvanceUserFirstPage);
        setOpenAdvanceDialog(false);
        resetUserSelections();
    };


    const onItemSelectedInTree = (data: NodeDetails, renderTreeData: RenderTree[]): void => {
        setRenderTreeData(renderTreeData);
        const entity: UserInviteEntityModel = {
            entityId: data.itemId,
            entityType: data.entityType.toString(),
            grantAccessToBuilding: false,
            roleType: getEntityRole(parseInt(data.entityType), parseInt(data.newRole)).toString(),
            organizationId: data.parentOrgId,
            buildingId: data.parentBuildingId
        }
        setUserInviteEntityModel(entity);
    };

    const resetUserSelections = () => {
        setInviteAsColleague(false);
        setOrganizationCheckList([{ id: props.entityId, name: props.entityName }]);
        setEmail('');
        setEmailAdvance('');
        setUserRoleSelection(userBaseRoles[0]);
        setUserInviteEntityModelArray([]);
        setUserInviteEntityModelRequestArray([]);
    };
    const handleRemoveAccessResponse = (): void => {
        if (usersSelector.removeAccessErrorMessage !== '') {
            if (usersSelector.removeAccessErrorMessage === '2007')
                setAlertErrorMessage(t('TOAST_MSG.REMOVE_ACCESS_ERROR_CODE_2007'));
            else if (usersSelector.removeAccessErrorMessage === '3057')
                setAlertErrorMessage(t('TOAST_MSG.REMOVE_ACCESS_ERROR_CODE_3057'));
            else if (usersSelector.removeAccessErrorMessage === '9001')
                setAlertErrorMessage(t('TOAST_MSG.REMOVE_ACCESS_ERROR_CODE_9001'));
            else if (usersSelector.removeAccessErrorMessage === '3059')
                setAlertErrorMessage(t('TOAST_MSG.REMOVE_ACCESS_ERROR_CODE_3059'));
            else if (usersSelector.removeAccessErrorMessage === '3060')
                setAlertErrorMessage(t('TOAST_MSG.REMOVE_ACCESS_ERROR_CODE_3060'));
            else
                setAlertErrorMessage(t('REGISTRATION.STEPS_ERROR_MESSAGE.GENERIC_ERROR_MESSAGE'));
        }
    };
    const renderStepComponent = (step: number) => {
        switch (step) {
            case 0:
                return (
                    <div >
                        <IconButton style={{
                            textAlign: 'right',
                            float: 'right',
                            margin: '8px 16px'
                        }}
                            data-testid="close-advance-button"
                            onClick={onCancelAdvanceInvite}>
                            <Close style={{ width: 24, height: 24, }} fontSize="small" />
                        </IconButton>

                        <DialogTitle id="invite-user-dialog-header" data-testid="invite-user-dialog-header" sx={{ padding: '32px 24px 0px 24px', mb: 2, alignSelf: "stretch" }}>{t('MANAGE_USERS.ADVANCED_INVITE')}</DialogTitle>
                        <Typography
                            id="invite-user-dialog-header-subtitle"
                            data-testid="invite-user-dialog-header-subtitle"
                            style={{ alignSelf: 'left', color: COLOR_GRAY, fontSize: 16, paddingLeft: 24, paddingBottom: 16 }} variant="body2">
                            {t('MANAGE_USERS.ADVANCED_INVITE_SUBTITLE')}
                        </Typography>
                        <Divider />
                        <DialogContent sx={{ padding: 3 }}>
                            <div style={{ minWidth: md ? 700 : 0 }}>
                                <Typography
                                    id="invite-user-advance-user-select"
                                    data-testid="invite-user-advance-user-select"
                                    style={{ alignSelf: 'left', marginBottom: 16 }} variant="subtitle1">
                                    {t('MANAGE_USERS.ADVANCED_INVITE_USER_SELECTION')}
                                </Typography>
                                <div style={{
                                    display: 'flex',
                                    height: 50,
                                }}>

                                    <TextField
                                        id="invite-user-email-advance"
                                        data-testid="invite-user-email-advance-label"
                                        inputProps={{ 'data-testid': 'invite-user-email-advance', maxLength: ORG_EMAIL_MAX_LENGTH }}
                                        label={t('MANAGE_USERS.INVITE_USER_EMAIL_NAME')}
                                        variant="filled"
                                        value={emailAdvance}
                                        onChange={(evt: ChangeEvent<HTMLInputElement>): void => setEmailAdvance(evt.target.value)}
                                        style={{
                                            alignSelf: 'center', width: md ? '65%' : '58%'
                                        }} />

                                    <div style={{
                                        display: 'flex', width: md ? '35%' : '41%', marginLeft: 'auto', marginTop: 'auto', paddingLeft: 15,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                        <Checkbox id="invite-user-as-colleague-check"
                                            onChange={handleInviteAsColleague}
                                            checked={isInviteAsColleague} />

                                        <Typography
                                            id="invite-user-as-colleague-check-lable"
                                            data-testid="invite-user-as-colleague-check-lable"
                                            style={{ alignSelf: 'center', color: COLOR_GRAY, fontSize: 16 }} variant="body2">
                                            {t('MANAGE_USERS.INVITE_USER_AS_COLLEAGUE')}
                                        </Typography>

                                        <HelpOutline style={{ cursor: 'pointer', color: COLOR_GRAY, opacity: 0.30, marginLeft: 10, alignSelf: 'center' }} onClick={onHelpClick} />
                                    </div>
                                </div>
                                <Typography
                                    id="invite-user-advance-user-organization-select"
                                    data-testid="invite-user-advance-user-organization-select"
                                    style={{ alignSelf: 'left', marginBottom: 16, marginTop: 16 }} variant="subtitle1">
                                    {t('MANAGE_USERS.ADVANCED_INVITE_USER_ORGANIZATION_SELECTION')}
                                </Typography>

                                {organization.orgListLoading && <EmptyState style={{ flex: 1, height: '100%', backgroundColor: PXBColors.white[50] }}
                                    icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}

                                {!organization.orgListLoading && <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginTop: 20
                                }}>
                                    <div style={{ width: '100%', }}>
                                        <Autocomplete
                                            multiple
                                            id="invite-user-select-org"
                                            data-testid="autocomplete-org"
                                            sx={{
                                                "& .MuiChip-label": { fontSize: 16 }
                                            }}
                                            style={{ backgroundColor: '#f7f8f8' }}
                                            options={organizationListData}
                                            disableCloseOnSelect
                                            value={organizationCheckList}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            getOptionLabel={(option) => option.id === orgSelectAllId ? t('DASHBOARD.ALL_ORGANIZATION') : option.name}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    <div data-testid="select-option" style={{ flex: '1 1 0px', overflow: 'auto', fontSize: md ? 16 : 10 }}>
                                                        <Checkbox
                                                            data-testid="checkbox-autocomplete"
                                                            icon={<CheckBoxOutlineBlank />}
                                                            checkedIcon={<CheckBox />}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                            onChange={() => { }}
                                                        />
                                                        {option.name}
                                                        {option.id === orgSelectAllId && <Divider />}
                                                    </div>
                                                </li>
                                            )}
                                            onChange={(event, newValue, reason) => {
                                                if (
                                                    event.type === 'keydown' &&
                                                    (event as React.KeyboardEvent).key === 'Backspace' &&
                                                    reason === 'removeOption'
                                                ) { return; }
                                                let selectAllOption = newValue.filter(x => x.id === orgSelectAllId);
                                                if (newValue.includes(selectAllOption[0])) {
                                                    setOrganizationCheckList(newValue.filter(x => x.id === orgSelectAllId) as ItemList);
                                                }
                                                else {
                                                    setOrganizationCheckList(newValue as ItemList);
                                                }
                                            }}

                                            renderInput={(params) => (
                                                <TextField
                                                    data-testid="invite-user-auto-org-text"
                                                    {...params}
                                                    label={t('MANAGE_USERS.INVITE_USER_ORGANIZATION_LABEL')}
                                                    variant="filled"
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                }
                            </div>
                        </DialogContent>
                    </div>
                )
            case 1:
                return (
                    <InviteUserAdvanceDialogSecondPage emailAdvance={emailAdvance}
                        userAccessDetailData={userAccessDetailData ? userAccessDetailData : {
                            inviteByUserEntityAccessDetail: [],
                            inviteUserEntityAccessDetail: []
                        }}
                        onCancelAdvance={onCancelAdvanceInvite}
                        isAdvanceTreeDataAvailable={isUserAdvanceInviteCallStatus}
                        onItemSelected={onItemSelectedInTree}
                        updateTreeData={renderTreeData}
                    ></InviteUserAdvanceDialogSecondPage>
                )
            default:
                return null;
        }
    };

    return (
        <>
            <Card style={{ flex: '1 1 0px', overflow: 'hidden' }}
                id="invite-user-container"
                data-testid="invite-user-container">

                <ListSubheader
                    id="invite-user-header-container"
                    style={{
                        display: 'flex',
                        height: 45,
                        marginTop: 10,
                        marginBottom: 20,
                    }}
                >
                    <div style={{
                        width: '75%',
                    }}>
                        <Typography
                            variant="body2"
                            style={{
                                fontSize: 20,
                                fontWeight: 600,
                                color: COLOR_GRAY,
                            }}
                            id="invite-user-title"
                            data-testid="invite-user-title"
                        >
                            {t('MANAGE_USERS.INVITE_USER')}
                        </Typography>
                        <Typography
                            variant="body2"
                            style={{
                                fontSize: 16,
                                color: COLOR_GRAY,
                            }}
                            id="invite-user-subtitle"
                            data-testid="invite-user-subtitle"
                        >
                            {t('MANAGE_USERS.INVITE_USER_SUBTITLE')}
                        </Typography>
                    </div>

                    <Box sx={{ width: md ? '25%' : '0', typography: 'body1' }} style={{ textAlign: 'right' }}>
                        <Button
                            id="invite-user-advance-btn"
                            data-testid="invite-user-advance-btn"
                            style={{
                                flexDirection: 'row',
                                color: PXBColors.blue[500],
                                textTransform: 'none'
                            }}
                            onClick={(): void => onAdvanceOptionsClick()}
                        >
                            {t('MANAGE_USERS.INVITE_USER_ADVANCE')}
                        </Button>
                    </Box>
                </ListSubheader>

                {usersSelector.inviteUserLoading && <EmptyState style={{ flex: 1, height: '100%', backgroundColor: PXBColors.white[50] }}
                    icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}

                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 50,
                    paddingLeft: 15,
                    paddingRight: 15,
                }}>

                    <TextField
                        id="invite-user-email"
                        data-testid="invite-user-email"
                        label={t('MANAGE_USERS.INVITE_USER_EMAIL_USER_NAME')}
                        variant="filled"
                        inputProps={{ 'data-testid': 'invite-user-email', maxLength: ORG_EMAIL_MAX_LENGTH }}
                        value={email}
                        onChange={(evt: ChangeEvent<HTMLInputElement>): void => setEmail(evt.target.value)}
                        style={{
                            alignSelf: 'center', width: '65%'
                        }} />

                    <div style={{
                        backgroundColor: '#f7f8f8',
                        width: '35%',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <div style={{
                            display: 'flex',
                            height: 55,

                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>

                            <Divider style={{ width: 1, height: '80%' }} />

                            <Typography
                                style={{ alignSelf: 'center', fontSize: 16, color: COLOR_GRAY, marginLeft: 10 }} variant="body2">
                                {t('MANAGE_USERS.INVITE_USER_AS')}
                            </Typography>

                            <Select
                                style={{ width: '80%', backgroundColor: PXBColors.white[200], height: 50, fontSize: 16 }}
                                labelId="invite-user-role-select"
                                id="invite-user-role-select"
                                data-testid="invite-user-role-select"
                                value={userRoleSelection.description}
                                onChange={selectionUserRoleHandleChange}
                                input={<BootstrapInput />}
                            >
                                {userBaseRoles.map((option) => (
                                    <MenuItem
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'left', fontSize: 16,
                                            lineHeight: 1.5, fontWeight: 400,
                                            overflow: 'hidden',
                                            boxSizing: 'border-box',
                                            whiteSpace: 'nowrap',
                                            minHeight: 48,
                                            paddingTop: 6,
                                            paddingBottom: 6,
                                            width: 'auto',
                                            paddingLeft: 16,
                                            paddingRight: 16,
                                            textAlign: 'center', color: COLOR_GRAY
                                        }}
                                        key={option.key} value={option.description}>
                                        {option.description}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        <Divider style={{ width: '100%' }} />
                    </div>
                </div>

                <ListSubheader
                    style={{
                        display: 'flex',
                        marginTop: 20,
                        marginBottom: 20,
                        marginLeft: -10,
                    }}
                >
                    <div style={{ width: '75%' }}>
                        {usertype !== USER_ROLE_TYPE.TECHNICIAN &&
                            <div style={{ display: 'flex', marginLeft: 'auto', marginTop: 'auto' }}>
                                <Checkbox id="invite-user-as-colleague-check"
                                    data-testid="invite-user-as-colleague-check"
                                    checked={isInviteAsColleague}
                                    onChange={handleInviteAsColleague} />

                                <Typography
                                    id="invite-user-as-colleague-check-lable"
                                    data-testid="invite-user-as-colleague-check-lable"
                                    style={{ alignSelf: 'center', color: COLOR_GRAY, fontSize: 16 }} variant="body2">
                                    {t('MANAGE_USERS.INVITE_USER_AS_COLLEAGUE')}
                                </Typography>

                                <HelpOutline style={{ cursor: 'pointer', color: COLOR_GRAY, opacity: 0.30, marginLeft: 10, alignSelf: 'center' }} onClick={onHelpClick} />
                            </div>
                        }
                    </div>

                    <Box sx={{ width: md ? '25%' : '100%', typography: 'body1' }} style={{ textAlign: 'right' }}>
                        <Button
                            id="invite-user-send-invite-button"
                            data-testid="invite-user-send-invite-button"
                            style={{
                                paddingLeft: 16, paddingRight: 16, paddingTop: 8.5, paddingBottom: 8.5,
                                fontSize: 16,
                                flexDirection: 'row',
                                backgroundColor: canSendInvite ? PXBColors.blue[500] : PXBColors.blue[50],
                                color: canSendInvite ? PXBColors.white[50] : PXBColors.blue[200],
                                marginLeft: 16,
                                textTransform: 'none'
                            }}
                            type="submit"
                            color="primary"
                            disabled={!canSendInvite}
                            onClick={(): void => onInviteUserClick()}
                        >
                            {t('MANAGE_USERS.INVITE_USER_SEND_INVITE')}
                        </Button>
                    </Box>
                </ListSubheader>

            </Card>

            <Dialog id="invite-user-dialog" data-testid="invite-user-dialog"
                maxWidth="md" open={openAdvanceDialog}
                sx={{ maxHeight: 900, overflow: 'hidden' }}
            >
                {renderStepComponent(activeStep)}

                <Divider />
                {!usersSelector.inviteUserLoading &&
                <MobileStepper
                    variant="dots"
                    steps={2}
                    position="static"
                    activeStep={activeStep}
                    sx={{ maxWidth: "auto", backgroundColor: PXBColors.white[50], flexGrow: 1, height: 84, padding: 3 }}
                    nextButton={
                        <Button
                            style={{
                                minWidth: 120, backgroundColor: nextEnabled ? PXBColors.blue[500] : PXBColors.blue[50],
                                color: nextEnabled ? PXBColors.white[50] : PXBColors.blue[200], textTransform: 'none',
                                padding: 16,
                                paddingTop: 6,
                                paddingBottom: 6,
                                borderRadius: 4,
                            }}
                            startIcon={activeStep === inviteAdvanceUserSecondPage ? <SendIcon /> : ''}
                            disabled={!nextEnabled}
                            variant={'contained'}
                            color={'primary'}
                            onClick={handleNext}
                            data-testid="invite-user-advance-next-button"
                        >
                            {nextLabel}
                        </Button>
                    }
                    backButton={
                        <Button
                            style={{
                                minWidth: 120,
                                visibility: activeStep === inviteAdvanceUserFirstPage ? 'hidden' : 'visible',
                                textTransform: 'none',
                                border: '1px solid  #727E84',
                                borderColor: PXBColors.blue[500],
                                borderRadius: 4,
                            }}
                            variant="outlined"
                            color={'primary'}
                            onClick={handleBack}
                            data-testid="invite-user-advance-back-button"
                        >
                            {backLabel}
                        </Button>
                    }
                />
                 }

            </Dialog >

            {/* //ToDo - Handle from Generic component
            {displaySuccessMessage && <ResponseHandlingAlert displayState={true} title={''} subtitle={alertSuccessMessage} displayAction={false} actionTitle={''} color={RESPONSE_STATUS_COLOR.STATUS_SUCCESS} />}
            {displayErrorMessage && <ResponseHandlingAlert displayState={true} title={''} subtitle={alertErrorMessage} displayAction={true} actionTitle={t('ACTIONS.RETRY')} color={RESPONSE_STATUS_COLOR.STATUS_FAIL} OnActionClick={() => { }} />}
            */}

            {
                displayErrorMessage &&
                <Snackbar
                    open={true}
                    onClose={() => setDisplayErrorMessage(false)}
                    autoHideDuration={5000}
                    title={''}
                    message={alertErrorMessage}
                    action={
                        <Button color="inherit" size="small" onClick={() => setDisplayErrorMessage(false)}>
                            {t('ACTIONS.OKAY')}
                        </Button>
                    }
                    ContentProps={{ sx: { background: PXBColors.red[500] } }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    sx={{ bottom: { xs: 90, sm: 0 } }}
                    style={{ height: 200, width: 200, flexGrow: 0 }}
                />
            }
            {
                displaySuccessMessage &&
                <Snackbar
                    open={true}
                    onClose={() => setDisplaySuccessMessage(false)}
                    autoHideDuration={5000}
                    title={''}
                    message={alertSuccessMessage}
                    action={
                        <Button color="inherit" size="small" onClick={() => setDisplaySuccessMessage(false)}>
                            {t('ACTIONS.OKAY')}
                        </Button>
                    }
                    ContentProps={{ sx: { background: PXBColors.black[900] } }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    sx={{ bottom: { xs: 90, sm: 0 } }}
                    style={{ height: 200, width: 200, flexGrow: 0 }}
                />
            }

        </>
    );
};
