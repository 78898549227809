// eslint-disable-next-line
import React, { useEffect, useRef, useState } from 'react';
import { DialogContent, DialogTitle, Divider, FormControl, IconButton, TextField, Typography } from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import * as PXBColors from '@brightlayer-ui/colors';
import { ALPHA_NUMBERIC, CUSTOMER_SLA_LIMIT } from '../../constants';
import { vw_600 } from '../../constants/dimentions';
import { Close } from '@mui/icons-material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { validSlaNumber } from '../../lib/form-validator';
import moment from 'moment';
import { getDate, getNextYearDate } from '../enum/DateTimeFormat';
import { formatDate } from '../../lib/date-time-formatter';

type Props = {
    closeClick: Function;
    openDialogFlag: boolean;
    deviceId: string;
    projectId: string;
    onSlaDetailsUpdate: Function;

};
export const LinkCustomerDialogSecondPage: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const [slaNumber, setSlaNumber] = useState('');
    const [slaExpiryDateFlag, setSlaExpiryDateFlag] = React.useState<boolean>(true);
    const [selectLumMonitoring, setSelectLumMonitoring] = React.useState(true);
    const [slaExpiryDate, setSlaExpiryDate] = React.useState<Date | null>();
    var slaExpiryDateFormat = getDate(slaExpiryDate?.toString()); //as per API
    var slaExpiryDateFormatFlag = false;

    const onSlaNumberChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || ALPHA_NUMBERIC.test(e.target.value)) {
            setSlaNumber(e.target.value);
            onSlaDetailsUpdate(validSlaNumber(e.target.value)? e.target.value : '', slaExpiryDateFormat ? slaExpiryDateFormat : '', selectLumMonitoring, slaExpiryDateFlag);
        }

    };
    const handleLuminaryRadioButtonTrue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectLumMonitoring(false);
        onSlaDetailsUpdate(slaNumber, slaExpiryDateFormat ? slaExpiryDateFormat : '', false, false);
    };

    const handleLuminaryRadioButtonFalse = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectLumMonitoring(true);
        onSlaDetailsUpdate(slaNumber, slaExpiryDateFormat ? slaExpiryDateFormat : '', true, false);
    };

    function closeSelectOrgDialog(): void {
        props.closeClick({});
    }
    const onCancel = () => {
        closeSelectOrgDialog();
        setSlaNumber('');

    }
    const onSlaDetailsUpdate = (slaNumber: string, slaExpiryDateFormat: string, selectLumMonitoring: boolean, slaExpiryDateFlag: boolean) => {
        if (props.onSlaDetailsUpdate) {
            props.onSlaDetailsUpdate(slaNumber, slaExpiryDateFormat, selectLumMonitoring, slaExpiryDateFlag);
        }

    }

    useEffect(() => {
        const nextYearDate = getNextYearDate();
        setSlaExpiryDate(nextYearDate);
        setSlaExpiryDateFlag(false);
    }, [])

    return (
        <div data-testid="advance-page-container"
            style={{ width: vw_600 }}
        >

            <DialogContent >
                <>
                    <IconButton style={{
                        textAlign: 'right',
                        float: 'right',
                    }}
                        data-testid="close-link-customer-second-dialoog"
                        onClick={(): void => onCancel()}>
                        <Close style={{ width: 24, height: 24, }} fontSize="small" />
                    </IconButton>
                    <div>
                        <DialogTitle data-testid='dialogHeader' style={{ fontFamily: 'Open Sans', fontSize: 16, fontWeight: 600, color: PXBColors.black[500], paddingLeft: 0 }}>{t('ASSETS_DETAILS.LINK_TO_CUSTOMER_DIALOG_SECOND_HEADER')}
                        </DialogTitle>
                    </div>


                    <Divider></Divider>

                    <TextField
                        style={{ marginTop: 14, width: '100%', fontSize: 16, color: PXBColors.black[500] }}
                        id="slaCustomerNumber"
                        data-testid='slaCustomerNumberLabelId'
                        variant={'filled'}
                        label={t('CUSTOMER_DETAILS.SLA_CUSTOMER_NUMBER')}
                        error={slaNumber.length > 0 && !validSlaNumber(slaNumber)}
                        helperText={slaNumber.length > 0 && !validSlaNumber(slaNumber) ? (t('FORMS.INVALID_SLA_NUMBER')) : ''}
                        fullWidth
                        required
                        value={slaNumber}
                        onChange={(e): void => onSlaNumberChange(e)}
                        inputProps={{ 'data-testid': 'slaCustomerNumberLabelId2', maxLength: CUSTOMER_SLA_LIMIT }}
                    />

                    <div data-testid='slaCustomerNumberhelperTextId'
                        style={{ height: 17, width: '100%', color: PXBColors.gray[500] }}>
                        <small className="form-text" style={{ width: '85%', float: 'left', paddingLeft: '2%' }}>
                            {t('CUSTOMER_DETAILS.SLA_NUMBER_HELPER_TEXT')}</small>
                        <Typography data-testid='customerSLAcharLimitId' style={{ width: 'auto', float: 'right', fontSize: 12, color: PXBColors.gray[500] }}>
                            {slaNumber.length}/{CUSTOMER_SLA_LIMIT}
                        </Typography>
                    </div>
                    <div data-testid='calendarDivId' style={{ display: 'flex', width: '100%', flexDirection: 'column', marginTop: 24, marginBottom: 24 }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                minDate={new Date()}
                                value={slaExpiryDate}
                                onChange={(newValue) => {
                                    setSlaExpiryDate(newValue)
                                    const dateIsValid = moment(newValue, "YYYY-MM-DD", true). isValid() && moment().diff(moment(newValue, "DD/MM/YYYY")) < 0;
                                    dateIsValid ? slaExpiryDateFormatFlag = false : slaExpiryDateFormatFlag = true ;
                                    onSlaDetailsUpdate(slaNumber, moment(newValue).format('YYYY-MM-DD'), selectLumMonitoring, slaExpiryDateFormatFlag);
                                }
                                }
                                slotProps={{
                                    textField: {
                                        required: true,
                                        helperText: t('CUSTOMER_DETAILS.CALENDAR_HELPER_TEXT'),
                                        variant: 'filled',
                                        label: t('CUSTOMER_DETAILS.SLA_EXPIRATION_DATE')
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </div>
                    <FormControl>
                        <Typography style={{ fontSize: 16, fontWeight: 600 }} id="radio-buttons-group-label" data-testid='radio-buttons-group-labelId'>{t('CUSTOMER_DETAILS.LUMINARY_INFO')}</Typography>
                        <RadioGroup
                            data-testid='radioButtonGroupId'
                            aria-labelledby="radio-buttons-group-label"
                            defaultValue="false"
                            name="radio-buttons-group"
                        >
                            <FormControlLabel style={{ marginLeft: 0, marginTop: 10, marginBottom: 10 }} data-testid='trueRadioButton' control={<Radio data-testid='handlelumTrue' onChange={handleLuminaryRadioButtonTrue} checked={selectLumMonitoring === false} value={false} />} label={t('CUSTOMER_DETAILS.LUMINARY_MONITORING_YES')} />
                            <FormControlLabel style={{ marginLeft: 0, marginBottom: 10 }} data-testid='falseRadioButton' control={<Radio data-testid='handlelumFalse' onChange={handleLuminaryRadioButtonFalse} checked={selectLumMonitoring === true} value={true} />} label={t('CUSTOMER_DETAILS.LUMINARY_MONITORING_NO')} />

                        </RadioGroup>
                    </FormControl>
                </>
            </DialogContent>
        </div>
    );
};

