import * as Endpoints from '../endpoints';
import { GenericNotOkResponse } from '../types';

export const login = Endpoints.invokeSecurityLogin;
export const logoff = Endpoints.invokeSecurityLogoff;
export const refresh = Endpoints.invokeSecurityRefresh;
export const changePassword = Endpoints.invokeSecurityChangePassword;
export const resetPassword = Endpoints.invokeSecurityResetPassword;
export const resetPasswordRequest = Endpoints.invokeSecurityResetPasswordRequest;
export const validateResetPasswordRequest = Endpoints.invokeSecurityResetPasswordRequestValidation;
export const getFeatureFlags = Endpoints.invokeFeatureFlag;
export const getUserRole = Endpoints.invokeUserRole;
export const getUserRoleOperation = Endpoints.invokeUserRoleOperation;

export const getDeviceMasterData = Endpoints.invokeDeviceMaster;

export const getOrganizations = Endpoints.invokeOrganizationList;
export const getProjects = Endpoints.invokeProjectList;
export const updateOrg = Endpoints.invokeOrgUpdateRequest;
export const updateCustomer = Endpoints.invokeCustomerUpdateRequest;
export const upsertBuilding = Endpoints.invokeBuildingUpsertRequest;
export const getOrgDetails = Endpoints.invokeOrgDetailRequest;
export const getBuildingDetails = Endpoints.invokeBuildingDetailRequest;

export const getUserSettings = Endpoints.invokeUserSettings;
export const getCustomerDetail = Endpoints.invokeCustomerDetail;
export const getCustomerList = Endpoints.invokeCustomerList;
export const getCustomerListV2 = Endpoints.invokeCustomerListV2;
export const setUserSettings = Endpoints.invokeUserSetSettings;

export const getSignalRConnection = Endpoints.invokeSignalRConnection;

export const getInvitationDetails = Endpoints.invokeGetUserInvitationDetails;
export const getUserInvitationHistory = Endpoints.invokeGetUserInvitationHistory;
export const getAdminApprovalHistory = Endpoints.invokeGetAdminApprovalHistory;
export const upsertUserAccessProfile = Endpoints.invokeUpsertUserAccessProfile;
export const registerUser = Endpoints.invokeUserRegistration;
export const registerSelfUser = Endpoints.invokeUserSelfRegistration;
export const requestUserRegistration = Endpoints.invokeUserRegistrationRequest;
export const requestUserRegistrationVerificationCode = Endpoints.invokeUserRegistrationVerificationCodeRequest;
export const requestUserRegistrationVerifyCode = Endpoints.invokeUserRegistrationVerifyCodeRequest;
export const validateUserRegistrationRequest = Endpoints.invokeUserRegistrationRequestValidation;
export const validateEmailRegistrationRequest = Endpoints.invokeUserRegistrationRequestEmailValidation;
export const getCountryListRegistrationRequest = Endpoints.invokeUserRegistrationRequestCountryList;
export const userRegistrationRole = Endpoints.invokeUserRegistrationRole;
export const userList = Endpoints.invokeUserList;
export const userDelete = Endpoints.invokeUserDelete;
export const updateUserRole = Endpoints.invokeUpdateRole;
export const updateUserNotification = Endpoints.invokeUpdateUser;
export const updateUserInvitation = Endpoints.invokeUpdateUserInvitation;
export const updateInvitationRequest = Endpoints.invokeUpdateInvitationRequest;
export const sendInvitationAccessRequest = Endpoints.invokeSendInvitationAccessRequest;

export const getSystemLogTimeline = Endpoints.invokeSystemLogTimeLine;
export const getPreviousLogTimeline = Endpoints.invokePreviousLogTimeLine;

export const requestBuilingList = Endpoints.invokeProjectBuildingListRequest;
export const requestDeviceList = Endpoints.invokeDeviceListRequest;
export const requestLuminaryList = Endpoints.invokeLuminaryListRequest;
export const requestGatewayDetailsList = Endpoints.invokeGatewayDetailsListRequest;
export const requestSLAList = Endpoints.invokeSLAListRequest;
export const requestSLACustomerList = Endpoints.invokeSLACustomerListRequest;
export const requestUserList = Endpoints.invokeProjectUserListRequest;
export const requestTechniciansList = Endpoints.invokeTechniciansList;
export const requestPlatformList = Endpoints.invokePlatformList;
export const addPlatform = Endpoints.invokeAddPlatform;
export const addServiceManager = Endpoints.invokeAddServiceManager;
export const addServicePartnerCustomer = Endpoints.invokeAddServicePartnerCustomerManagerRequest;
export const requestServiceManagersList = Endpoints.invokeServiceManagersList;
export const requestServicePartnerCustomerListList = Endpoints.invokeServicePartnerCustomerListRequest;
export const requestServicePartnerCustomerDetailList = Endpoints.invokeServicePartnerCustomerDetailListRequest;
export const requestServiceManagerCustomerDetailList = Endpoints.invokeServiceManagerCustomerDetailListRequest;
export const inviteUser = Endpoints.invokeInviteUserRequest;
export const getStatisticSummary = Endpoints.invokeStatisticSummaryRequest;
export const transferBuilding = Endpoints.invokeTransferBuildingRequest;
export const upsertLogComments = Endpoints.invokeUpsertLogCommentsRequest;
export const acknowledgeLog = Endpoints.invokeLogAcknowledgeRequest;
export const downloadLogbook = Endpoints.invokeLogbookDownloadReport;
export const getHealthReportList = Endpoints.invokeHealthReport;
export const downloadHealthReport = Endpoints.invokeDownloadHealthReport;
export const userAdvanceAccessDetail = Endpoints.invokeUserAdvanceAccess;
export const updateMarkAsFav = Endpoints.invokeMarkAsFavRequest;
export const getServiceOrganizations = Endpoints.invokeServiceOrganizationList;
export const linkCustomer = Endpoints.invokeLinkCustomerRequest;
export const deleteUser =  Endpoints.invokeDeleteUser;
export const getServicePartnerDetail = Endpoints.invokeServicePartnerDetail;
export const removeAccess = Endpoints.invokeRemoveAccess;
export const mergeAssetBuilding = Endpoints.invokeMergeBuildingAssets;
export const getPlatformSystemInfo = Endpoints.invokePlatformSystemInfo;
export const getPlatformDetail = Endpoints.invokePlatformDetail;
export const removeServiceUser = Endpoints.invokeRemoveServiceUser;
export const removeMarkAsFav = Endpoints.invokeRemoveMarkAsFav;
export const getMaintenanceDetail = Endpoints.invokeMaintenanceDetail;
export const createMaintainanceRequest = Endpoints.invokeCreateMaintainaceRequest;
export const addNotesRequest = Endpoints.invokeAddNotesRequest;
export const updateMaintenanceRequest = Endpoints.invokeUpdateMaintenanceRequest;
export const downloadDocument = Endpoints.invokeDownloadDocument;
export const getMaintenanceLogs = Endpoints.invokeMaintenanceLogs;


export const extractResponseNotOkCode = (response: GenericNotOkResponse): number => {
    if ('errorCode' in response) {
        return response.errorCode;
    }
    return response.statusCode;
};

export const extractResponseNotOkMessage = (response: GenericNotOkResponse): string => {
    if ('errorCode' in response) {
        return response.errorDescription;
    }
    return response.message;
};
export const extractErrorCodeWithMessage = (response: GenericNotOkResponse): string =>
    `${extractResponseNotOkCode(response)} ${'/'} ${extractResponseNotOkMessage(response)}`;
